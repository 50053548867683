<template lang="pug">
.flex.items-center.cursor-pointer(@click="changeMode('edit')" v-if="mode === 'read'")
    .flex.items-center(v-if="task.workspaceMember")
        user-avatar(:user="task.workspaceMember")
        .ml-2(v-if="showFullName") {{ task.workspaceMember.fullName }}
    div(v-else) Не указано
div(v-if="mode === 'edit'")
    workspace-members-select(
        :modelValue="task.workspaceMember"
        @blur="update"
    )
</template>

<script>
import {ref} from 'vue';
import WorkspaceMembersSelect from '@/components/Workspace/Member/Select';
import SelectSuspense from '@/components/Generic/SelectSuspense';
export default {
    name: 'TaskPerformerChanger',
    components: {
        WorkspaceMembersSelect,
        SelectSuspense,
    },
    props: {
        task: Object,
        showFullName: Boolean
    },
    emits: ['update'],
    setup(props, {emit}) {
        const mode = ref('read');
        const changeMode = (newMode) => {
            mode.value = newMode;
        };
        const update = (workspaceMember) => {
            const newWorkspaceMemberId = workspaceMember?.id;
            emit('update', newWorkspaceMemberId);
            changeMode('read');
        };
        return {
            mode,
            update,
            changeMode
        };
    }
};
</script>

<style scoped>

</style>