<template lang="pug">
card-title Основная информация
.create-contact-form.mt-2
    input-with-error(:error="form.title.error")
        template(#input)
            a-input(placeholder="Наименование" v-model:value="form.title.value" )
    input-with-error(:error="form.type.error")
        template(#input)
            a-select(
                v-model:value="form.type.value"
                style="width: 100%"
                placeholder="Тип"
                allowClear
            )
                a-select-option(
                    v-for="type in contactsType"
                    :key="type"
                    :value="type"
                )
                    | {{ contactsTypeMap[type] }}
    input-with-error(:error="form.contactGroupId.error")
        template(#input)
            a-select(
                v-model:value="form.contactGroupId.value"
                style="width: 100%"
                placeholder="Группа"
                show-search
                :filter-option="selectFilterFunc"
                allowClear
            )
                a-select-option(
                    v-for="group in directories.contactGroups.list"
                    :key="group.id"
                    :value="group.id"
                    :label="group.title"
                )
                    | {{ group.title }}
                a-select-option.cursor-pointer(disabled @click="() => setModalVisibility('contactGroups', true)") Добавить
                template(#notFoundContent)
                    add-directory(:onClick="() => setModalVisibility('contactGroups', true)")
    input-with-error(:error="form.description.error")
        template(#input)
            a-textarea(placeholder="Описание" v-model:value="form.description.value" :autoSize="{minRows: 2}")
    input-with-error(:error="form.address.error")
        template(#input)
            a-input(placeholder="Страна, город" v-model:value="form.address.value" )
div(v-if="form.type.value === contactsType.entity")
    card-title.mt-3 Контактное лицо
    .create-contact-form.mt-3
        input-with-error(:error="form.contactPerson.error")
            template(#input)
                a-input(placeholder="Контактное лицо" v-model:value="form.contactPerson.value" )
        input-with-error(:error="form.email.error")
            template(#input)
                a-input(placeholder="Еmail" v-model:value="form.email.value" )
        input-with-error(:error="form.phone.error")
            template(#input)
                a-input(placeholder="Phone" v-model:value="form.phone.value" )
        input-with-error(:error="form.telegram.error")
            template(#input)
                a-input(placeholder="Telegram" v-model:value="form.telegram.value" )
        input-with-error(:error="form.skype.error")
            template(#input)
                a-input(placeholder="Скайп" v-model:value="form.skype.value" )
div(v-if="showMarketingFields")
    card-title.mt-3 Финансы
    .create-contact-form.mt-2
        .flex.items-center
            input-with-error(:error="form.hourlyRate.error")
                template(#input)
                    a-input-number(placeholder="Часовая ставка" v-model:value="form.hourlyRate.value" :min="0" style="width: 100%")
            currency-select.ml-2(v-model="form.currency.value" style="width: 100%")
div
    card-title.mt-3 Доп информация
    .create-contact-form.mt-3
        input-with-error(:error="form.director.error")
            template(#input)
                a-input(placeholder="ФИО руководителя" v-model:value="form.director.value")
        input-with-error(:error="form.directorPosition.error")
            template(#input)
                a-input(placeholder="Должность руководителя" v-model:value="form.directorPosition.value")
        input-with-error(:error="form.entityCode.error")
            template(#input)
                a-input-number(placeholder="ИНН" v-model:value="form.entityCode.value" style="width: 100%")
        input-with-error(:error="form.registrationReasonCode.error")
            template(#input)
                a-input-number(placeholder="КПП" v-model:value="form.registrationReasonCode.value" style="width: 100%")
        input-with-error(:error="form.legalAddress.error")
            template(#input)
                a-input(placeholder="Юр. адрес" v-model:value="form.legalAddress.value")
div(v-if="showMarketingFields")
    card-title.mt-3 Прочее
    .create-contact-form.mt-2
        input-with-error(:error="form.specialMarkId.error")
            template(#input)
                a-select(
                    v-model:value="form.specialMarkId.value"
                    style="width: 100%"
                    placeholder="Особая метка"
                    show-search
                    :filter-option="selectFilterFunc"
                    allowClear
                )
                    a-select-option(
                        v-for="contactSpecialMark in directories.contactSpecialMarks.list"
                        :key="contactSpecialMark.id"
                        :value="contactSpecialMark.id"
                        :label="contactSpecialMark.title"
                    )
                        | {{ contactSpecialMark.title }}
                    a-select-option.cursor-pointer(disabled @click="() => setModalVisibility('contactSpecialMarks', true)") Добавить
                    template(#notFoundContent)
                        add-directory(:onClick="() => setModalVisibility('contactSpecialMarks', true)")
        input-with-error(:error="form.mark.error")
            template(#input)
                a-textarea(placeholder="Примечание" v-model:value="form.mark.value" :autoSize="{minRows: 2}")
        input-with-error(:error="form.loyaltyId.error")
            template(#input)
                a-select(
                    v-model:value="form.loyaltyId.value"
                    style="width: 100%"
                    placeholder="Лояльность"
                    show-search
                    :filter-option="selectFilterFunc"
                    allowClear
                )
                    a-select-option(
                        v-for="loyalty in directories.loyalty.list"
                        :key="loyalty.id"
                        :value="loyalty.id"
                        :label="loyalty.title"
                    )
                        | {{ loyalty.title }}
                    a-select-option.cursor-pointer(disabled @click="() => setModalVisibility('loyalty', true)") Добавить
                    template(#notFoundContent)
                        add-directory(:onClick="() => setModalVisibility('loyalty', true)")
        input-with-error(:error="form.salesId.error" v-if="showMarketingFields")
            template(#input)
                a-select(
                    v-model:value="form.salesId.value"
                    style="width: 100%"
                    placeholder="Сейлз"
                    show-search
                    :filter-option="false"
                    @search="onMembersSearch"
                    allowClear
                )
                    a-select-option(
                        v-for="member in members.list"
                        :key="member.id"
                        :value="member.id"
                        :label="member.fullName"
                    )
                        | {{ member.fullName }}
        input-with-error(:error="form.leadSourceId.error" v-if="showMarketingFields")
            template(#input)
                a-select(
                    v-model:value="form.leadSourceId.value"
                    style="width: 100%"
                    placeholder="Источник лида"
                    show-search
                    :filter-option="selectFilterFunc"
                    allowClear
                )
                    a-select-option(
                        v-for="leadSource in directories.leadSources.list"
                        :key="leadSource.id"
                        :value="leadSource.id"
                        :label="leadSource.title"
                    )
                        | {{ leadSource.title }}
                    a-select-option.cursor-pointer(disabled @click="() => setModalVisibility('leadSources', true)") Добавить
                    template(#notFoundContent)
                        add-directory(:onClick="() => setModalVisibility('leadSources', true)")
a-button.mt-3(@click="createContactWrapper" type="primary" :loading="loading" :disabled="disabled") {{ mode === 'edit' ? 'Сохранить' : 'Создать' }}
a-modal(
    v-model:visible="additionalModals[currentModal].showModal"
    :title="additionalModals?.[currentModal]?.title"
    :footer="null"
    @cancel="setModalVisibility(currentModal, false)"
    :destroyOnClose="true"
)
    component(
        v-if="currentModal"
        :is="additionalModals[currentModal].component"
        @ok="onDirectoryAdded"
        :directories="directories"
        :workspaceId="workspaceId"
    )



</template>

<script>
import {computed, reactive, ref} from 'vue';
import {useFormErrors} from '@/composition/errors';
import {notification} from 'ant-design-vue';
import {useContact} from '@/composition/contact';
import CurrencySelect from '@/components/Generic/CurrencySelect';
import CardTitle from '@/components/Generic/Typography/CardTitle';
import {contactsType, contactsTypeMap} from '@/components/Contacts/constants';
import {useDirectory} from '@/composition/directory';
import {selectFilterFunc} from '@/modules/utils';
import {useWorkspace} from '@/composition/workspace';
import CreateContactGroupForm from '@/components/Directories/Contacts/Groups/CreateForm';
import AddDirectory from '@/components/Generic/AddDirectory/AddDirectory';
import CreateLoyaltyForm from '@/components/Directories/Contacts/Loyalty/CreateForm';
import CreateLeadSourceForm from '@/components/Directories/Contacts/LeadSources/CreateForm';
import CreateContactSpecialMark from '@/components/Directories/Contacts/SpecialMarks/CreateForm';

export default {
    name: 'CreateContactForm',
    components: {
        CreateContactSpecialMark,
        CreateLeadSourceForm,
        CreateLoyaltyForm, AddDirectory, CreateContactGroupForm, CardTitle, CurrencySelect},
    props: {
        workspaceId: [String, Number],
        mode: String,
        defaultValue: Object,
    },
    emits: ['ok', 'update'],
    setup(props, {emit}) {
        const loading = ref(false);
        const currentModal = ref('contactGroups');
        const additionalModals = reactive({
            contactGroups: {
                showModal: false,
                component: 'create-contact-group-form',
                title: 'Добавить группу'
            },
            loyalty: {
                showModal: false,
                component: 'create-loyalty-form',
                title: 'Добавить лояльность'
            },
            leadSources: {
                showModal: false,
                component: 'create-lead-source-form',
                title: 'Добавить источник лида'
            },
            contactSpecialMarks: {
                showModal: false,
                component: 'create-contact-special-mark',
                title: 'Добавить особую метку'
            },
        });
        const setModalVisibility = (type, bool) => {
            currentModal.value = type;
            additionalModals[type].showModal = bool;
            if (!bool) {
                currentModal.value = 'contactGroups';
            }
        };
        const onDirectoryAdded = async () => {
            setModalVisibility(currentModal.value, false);
            await getDirectories(props.workspaceId, 'contacts');
        };
        const form = reactive({
            title: {
                value: props.defaultValue.title,
                error: ''
            },
            type: {
                value: props.defaultValue.type,
                error: ''
            },
            contactGroupId: {
                value: props.defaultValue.contactGroupId,
                error: ''
            },
            address: {
                value: props.defaultValue.address,
                error: ''
            },
            contactPerson: {
                value: props.defaultValue.contactPerson,
                error: ''
            },
            email: {
                value: props.defaultValue.email,
                error: ''
            },
            phone: {
                value: props.defaultValue.phone,
                error: ''
            },
            telegram: {
                value: props.defaultValue.telegram,
                error: ''
            },
            description: {
                value: props.defaultValue.description,
                error: ''
            },
            skype: {
                value: props.defaultValue.skype,
                error: undefined
            },
            leadSourceId: {
                value: props.defaultValue.leadSourceId,
                error: undefined
            },
            salesId: {
                value: props.defaultValue.salesId,
                error: undefined
            },
            salesModel: {
                value: props.defaultValue.salesModel,
                error: undefined
            },
            hourlyRate: {
                value: props.defaultValue.hourlyRate,
                error: undefined
            },
            currency: {
                value: props.defaultValue.currency,
                error: undefined
            },
            mark: {
                value: props.defaultValue.mark,
                error: undefined
            },
            loyaltyId: {
                value: props.defaultValue.loyaltyId,
                error: undefined
            },
            specialMarkId: {
                value: props.defaultValue.specialMarkId,
                error: undefined
            },
            director: {
                value: props.defaultValue?.director,
                error: undefined
            },
            directorPosition: {
                value: props.defaultValue?.directorPosition
            },
            entityCode: {
                value: props.defaultValue?.entityCode,
                error: undefined
            },
            registrationReasonCode: {
                value: props.defaultValue?.registrationReasonCode,
                error: undefined
            },
            legalAddress: {
                value: props.defaultValue?.legalAddress,
                error: undefined
            },
        });
        const clearErrors = () => {
            form.title.error = undefined;
            form.type.error = undefined;
            form.contactGroupId.error = undefined;
            form.address.error = undefined;
            form.contactPerson.error = undefined;
            form.email.error = undefined;
            form.phone.error = undefined;
            form.telegram.error = undefined;
            form.description.error = undefined;
            form.skype.error = undefined;
            form.leadSourceId.error = undefined;
            form.salesId.error = undefined;
            form.salesModel.error = undefined;
            form.hourlyRate.error = undefined;
            form.currency.error = undefined;
            form.mark.error = undefined;
            form.loyaltyId.error = undefined;
            form.specialMarkId.error = undefined;
            form.director.error = undefined;
            form.directorPosition.error = undefined;
            form.entityCode.error = undefined;
            form.registrationReasonCode.error = undefined;
            form.legalAddress.error = undefined;
        };
        const {init} = useFormErrors();
        const {createContact, showMarketingFields} = useContact(loading);
        const createContactWrapper = async () => {
            const contactData = {
                title: form.title.value,
                type: form.type.value,
                contactGroupId: form.contactGroupId.value,
                address: form.address.value,
                contactPerson: form.contactPerson.value,
                email: form.email.value,
                phone: form.phone.value,
                telegram: form.telegram.value,
                description: form.description.value,
                skype: form.skype.value,
                leadSourceId: form.leadSourceId.value,
                salesId: form.salesId.value,
                salesModel: form.salesModel.value,
                hourlyRate: form.hourlyRate.value,
                currency: form.currency.value,
                mark: form.mark.value,
                loyaltyId: form.loyaltyId.value,
                specialMarkId: form.specialMarkId.value,
                workspaceId: props.workspaceId,
                director: form.director.value,
                directorPosition: form.directorPosition.value,
                entityCode: form.entityCode.value ? String(form.entityCode.value) : undefined,
                registrationReasonCode: form.registrationReasonCode.value ? String(form.registrationReasonCode.value): undefined,
                legalAddress: form.legalAddress.value,
            };
            if (props.mode === 'edit') {
                emit('update', {...contactData, id: props.defaultValue.id});
            } else {
                const {ok} = await init(form, async () => {
                    return createContact(props.workspaceId, contactData);
                }, clearErrors);
                if (ok) {
                    notification.success({
                        description: 'Контакт создан',
                        message: 'Успех!'
                    });
                    emit('ok');
                }
            }
        };
        const disabled = computed(() => {
            return !form.title.value;
        });

        const {getMembers, members, setFilter} = useWorkspace(loading);
        const onMembersSearch = async(searchStr) => {
            setFilter({search: searchStr});
            await getMembers(props.workspaceId);
        };
        const {directories, getDirectories} = useDirectory(loading);
        return {
            disabled,
            loading,
            createContactWrapper,
            form,
            contactsType,
            contactsTypeMap,
            directories,
            selectFilterFunc,
            members,
            onMembersSearch,
            showMarketingFields: computed(() => showMarketingFields(form.contactGroupId.value, directories)),
            currentModal,
            additionalModals,
            setModalVisibility,
            onDirectoryAdded
        };
    }
};
</script>

<style lang="scss" scoped>
.create-contact-form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
}
</style>