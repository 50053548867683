<template lang="pug">
.period-inline-changer
    .period-inline-changer__title(@click.stop="setPopupVisibility(true)")
        span {{ getPeriod(period) }}
    a-popover(
        :visible="showPopup"
        placement="bottomLeft"
    )
        template(#content)
            .period-inline-changer__popup
                simple-month-period-picker(
                    v-model:period="newPeriod"
                    placeholder="Период"
                )
                .period-inline-changer__popup-btns
                    a-button(@click="onSave" type="primary" :loading="loading") Сохранить
                    a-button.ml-3(@click="onDecline" ) Отменить
</template>

<script>
export default {
    name: 'PeriodInlineChanger'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
/*===============INIT AREA===================*/
import {ref, watch} from 'vue';
import CustomSelectV2 from '@/components/Generic/CustomSelectV2';
import {useServiceV2} from '@/composition/service-v2';
import {useWorkspace} from '@/composition/workspace';
import {useLoading} from '@/composition/loading-state';
import {useDate} from '@/composition/date';
import SimpleMonthPeriodPicker from '@/components/Generic/SimpleMonthPeriodPicker';

const props = defineProps({
    period: {
        type: String,
    },
    func: {
        type: Function
    }
});
const emit = defineEmits(['done']);
const {loading, withLoader} = useLoading();
const {loading: fetchLoading, withLoader: wrapFetchAsync} = useLoading();
/*===============PERIODS AREA===================*/
const {formatMonth} = useDate();

const newPeriod = ref(props.period);
const {currentWorkspaceId} = useWorkspace();
const {getPeriods, periods} = useServiceV2(loading);
const getPeriod = (period) => {
    if (!period) return 'Не указано';
    return formatMonth(period);
};

const onSave = async () => {
    await withLoader(async () => {
        const period = newPeriod.value ? newPeriod.value : null;
        await props.func(period);
    });
    setPopupVisibility(false);
};
const onDecline = () => {
    newPeriod.value = props.period;
    setPopupVisibility(false);
};
/*===============POPUP AREA===================*/
const showPopup = ref(false);
const setPopupVisibility = async (bool) => {
    showPopup.value = bool;
    if (bool) {
        await wrapFetchAsync(async () => {
            await getPeriods();
        });
    }
};
</script>

<style lang="scss" scoped>
.period-inline-changer {
    &__title {
        min-height: 32px;
        min-width: 40px;
        display: flex;
        align-items: center;
        width: fit-content;
        & span {
            text-decoration: underline;
            text-decoration-style: dotted;
            text-decoration-thickness: from-font;
            cursor: url("~@/assets/img/edit.png") 4 12, auto;
        }
    }
    &__popup {
        width: 240px;
        &-btns {
            margin-top: 12px;
        }
    }
}
</style>
