<template lang="pug">
workspace-members-select(
    :modelValue="filter.workspaceMembers"
    @update:modelValue="onChange($event)"
    mode="multiple"
    :options="{height: 500}"
    :first-item="{id: -1}"
)
    template(#first-item)
        div Без исполнителя {{ stats.unallocatedActiveTasksCount }} / {{ stats.unallocatedAllTasksCount }}
    template(#title="{record}")
        div {{ record.fullName }} {{ record.activeTasksCount }} / {{ record.allTasksCount }}
</template>

<script>
export default {
    name: 'PerformersTasksList'
};
</script>
<script setup>
/*======================IMPORT AREA=====================*/

import WorkspaceMembersSelect from '@/components/Workspace/Member/Select';
import {useKanban} from '@/composition/kanban';

/*======================INITIALIZATION AREA=====================*/

const props = defineProps({
    filter: {
        type: Object,
    }
});

const emit = defineEmits(['update']);

const {stats} = useKanban();

/*======================CHANGES HANDLERS AREA=====================*/

const onChange = (workspaceMembersArray) => {
    emit('update', workspaceMembersArray);
};

</script>
<style scoped>

</style>