<template lang="pug">
.salary-list-filter
    simple-month-period-picker(
        :period="filter.period"
        @update:period="onChange"
        placeholder="Выберите период"
        :clearable="false"
    )
    simple-range-picker.ml-3(
        :range="[filter.from, filter.to]"
        @update:range="onDateChanged"
        :only-for-period="filter.period"
        :clearable="false"
    )
    .salary-list-filter__settings(@click="setModalVisibility(true)")
        icon(type="Setting")
a-modal(
    v-model:visible="showModal"
    title="Настройки виджета"
    :footer="null"
    @cancel="setModalVisibility(false)"
    :destroyOnClose="true"
)
    workspace-members-checklist(widget-type="salary" @done="onDone")
</template>

<script>
export default {
    name: 'SalaryListFilter'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/

/*===============INIT AREA===================*/

import {computed, ref} from 'vue';
import WorkspaceMembersChecklist from '@/components/Workspace/Checklist';
import {useCreateOrUpdateModal} from '@/composition/create-update-modal';
import SimpleRangePicker from '@/components/Generic/SimpleRangePicker';
import SimpleMonthPeriodPicker from '@/components/Generic/SimpleMonthPeriodPicker';
const props = defineProps({
    filter: {
        type: Object
    },
    filterOptions: {
        type: Array,
    }
});
const emit = defineEmits(['update:filter', 'fetch']);
const {setModalVisibility, showModal} = useCreateOrUpdateModal();
/*===============HANDLERS AREA===================*/

const onChange = e => {
    emit('update:filter', {
        ...props.filter,
        period: e
    });
};

const onDateChanged = (range) => {
    const [from, to] = range;
    emit('update:filter', {
        ...props.filter,
        from,
        to,
    });
};

const onDone = e => {
    setModalVisibility(false);
    emit('fetch');
};
</script>

<style lang="scss">
.salary-list-filter {
    display: flex;
    align-items: center;
    max-width: 500px;
    &__settings {
        margin-left: 16px;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
            transform: rotateZ(45deg);
        }
    }
}
</style>