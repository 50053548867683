import { createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "resource-management-page" }
const _hoisted_2 = { class: "flex items-center mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode($setup["ResourceManagementChartSettings"], {
          settings: $setup.chartSettings,
          "onUpdate:settings": [
            _cache[0] || (_cache[0] = ($event: any) => (($setup.chartSettings) = $event)),
            $setup.fetchData
          ]
        }, null, 8, ["settings"]),
        _createElementVNode("div", {
          class: "ml-2 cursor-pointer",
          onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.setModalVisibility(true)))
        }, [
          _createVNode(_component_icon, { type: "Setting" })
        ])
      ]),
      ($setup.members.length)
        ? (_openBlock(), _createBlock($setup["ResourceManagementChart"], {
            key: 0,
            class: "mt-3",
            "workspace-members": $setup.members,
            items: $setup.timeLinesList.list,
            settings: $setup.chartSettings,
            onAdd: $setup.onAdd,
            onUpdate: $setup.onUpdate,
            onDelete: $setup.onDelete
          }, null, 8, ["workspace-members", "items", "settings"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_a_modal, {
      visible: $setup.showModal,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => (($setup.showModal) = $event)),
      title: "Настройки виджета",
      footer: null,
      onCancel: _cache[3] || (_cache[3] = ($event: any) => ($setup.setModalVisibility(false))),
      destroyOnClose: true
    }, {
      default: _withCtx(() => [
        _createVNode($setup["WorkspaceMembersChecklist"], {
          "widget-type": "resource_management",
          onDone: $setup.onWidgetSettingsDone
        })
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}