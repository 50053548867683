<template lang="pug">
async-page
    task-card
</template>

<script>
import TaskCard from '@/components/Tasks/TaskCard';
export default {
    name: 'CreateTaskPage',
    components: {
        TaskCard
    }
};
</script>

<style scoped>

</style>