<template lang="pug">
.member-payment-methods
    payment-methods-form(v-model:paymentMethods="paymentMethods" :directories="directories")
</template>

<script>
import PaymentMethodsForm from '@/components/Workspace/InviteUserForm/PaymentMethodsForm';
import {ref, watch} from 'vue';
export default {
    name: 'MemberPaymentMethods',
    components: {PaymentMethodsForm},
    props: {
        directories: Object,
        member: Object
    },
    emits: ['update'],
    setup(props, {emit}) {
        const paymentMethods = ref({
            value: props.member.paymentMethods
        });
        watch(paymentMethods, () => {
            emit('update', {
                paymentMethods: paymentMethods.value.value
            });
        });
        return {
            paymentMethods
        };
    }
};
</script>

<style lang="scss" scoped>
.member-payment-methods {
    max-width: 500px;
}
</style>