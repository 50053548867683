export const projectColumns = [
    {
        title: 'Наименование',
        dataIndex: 'title',
        key: 'title',
        slots: {customRender: 'title'},
        width: '40%'
    },
    {
        title: 'Проект интеграции',
        slots: {customRender: 'integrationProject'},
        width: '40%'
    },
    {
        title: 'Статусы',
        slots: {customRender: 'integrationStatus'},
        width: '20%'
    },
];