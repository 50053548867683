<template lang="pug">
card-title Пароль
.invite-user-form.mt-2
    div Ссылка для установки пароля будет отправлена пользователю вместе с приглашением в рабочее пространство
</template>

<script>
export default {
    name: 'PasswordForm'
};
</script>

<style scoped>

</style>