<template lang='pug'>
.hourly-reports
    a-input.mb-2.hourly-reports__title-input(v-if="reportTitle && mode=='edit' && !props.uuid" v-model:value="reportTitle" @blur="onTitleChanged" )
    typography-title.mb-2(v-if="reportTitle && mode=='create' && !props.uuid" style="white-space: pre-wrap" @click="mode='edit'") {{ reportTitle }}
    typography-title.mb-2(v-if="reportTitle && props.uuid" style="white-space: pre-wrap") {{ reportTitle }}
    .mb-2(v-if="reportTitle && props.uuid" style="white-space: pre-wrap") {{ reportSubTitle }}
    hourly-reports-filter(
        @start="onStart"
        :loading="loading"
        @save="onSave"
        @update="onUpdate"
        @share="onShare"
        v-if="!props.uuid"
        :from-card="!!props.id"
    )
    hourly-reports-chart.mt-4
    .hourly-reports__total.my-3 Всего: {{ totalHours }} ч.
    hourly-reports-tables.mt-3(
        v-model:currentGroup="currentGroup"
        :groups="groups"
        @update:currentGroup="onGroupChanged"
        :uuid="uuid"
    )
</template>

<script>
export default {
    name: 'HourlyReports'
};
</script>
<script setup>
/*======================IMPORT AREA=====================*/

import {computed, ref} from 'vue';
import HourlyReportsFilter from '@/components/Analytics/HourlyReports/Filter';
import HourlyReportsChart from '@/components/Analytics/HourlyReports/Chart';
import HourlyReportsTables from '@/components/Analytics/HourlyReports/Tables';
import {useWorkspace} from '@/composition/workspace';
import {useContact} from '@/composition/contact';
import {useService} from '@/composition/service';
import {useReport} from '@/composition/reports';
import {truncateNumber} from '@/modules/utils';
import {useCopy} from '@/composition/copy';
import TypographyTitle from '@/components/Generic/Typography/Title';
import {useRoute, useRouter} from 'vue-router';
import {notification} from 'ant-design-vue';
import {useAuth} from '@/composition/auth';
import {useRole} from '@/composition/role';
import {useDate} from '@/composition/date';
import Big from 'big.js';
import {toFixedNumber} from '@/modules/utils/v2.ts';

/*======================INITIALIZATION AREA=====================*/
const props = defineProps({
    uuid: {
        type: String
    },
    id: {
        type: [String, Number]
    }
});

const loading = ref(false);
const router = useRouter();
const route = useRoute();
const {getMembers, currentWorkspaceId, currentWorkspaceTz} = useWorkspace(loading);
const {getContacts} = useContact(loading);
const {getServices} = useService(loading);
const {msToDate} = useDate();
const {user} = useAuth();
const {sections, getDefaults} = useRole(user.value);
const {hide_clients} = getDefaults(sections.reports.name, sections.reports.filters.name);
const mode = ref('create');
const {
    getTables,
    getChartData,
    saveReport,
    getSavedReportByUUID,
    getSavedReportById,
    chartData,
    currentGroup,
    groups,
    reportTitle,
    reportSubTitle,
    savedReport,
    updateReport,
    processRouteQueryParams,
} = useReport(loading);
const {copy} = useCopy();

/*======================FETCHING DATA AREA=====================*/
processRouteQueryParams(route);
const onStart = async (options) => {
    options = options || {};
    if (options.onlyTables) {
        await getTables(currentWorkspaceId.value, currentWorkspaceTz.value);
    } else {
        await getChartData(currentWorkspaceId.value, currentWorkspaceTz.value);
        await getTables(currentWorkspaceId.value, currentWorkspaceTz.value);
    }
};

const onGroupChanged = async () => {
    if (props.uuid) {
        await getSavedReportByUUID(currentWorkspaceId.value, props.uuid);
    } else {
        await onStart({onlyTables: true});
    }
};

const onTitleChanged = async _ => {
    await onUpdate();
    mode.value = 'create';
};

const onSave = async () => {
    const title = prompt('Введите название отчета');
    const {ok, result} = await saveReport({
        workspaceId: currentWorkspaceId.value,
        title,
        type: 'hours'
    }, currentWorkspaceTz.value);
    if (ok) {
        // const reportUrl = `${window.location.origin}/public/reports/hourly/${result.uuid}`;
        // copy(reportUrl);
        router.push(`/${currentWorkspaceId.value}/reports/saved/${result.id}`);
        notification.success({
            message: 'Отчет успешно сохранен',
        });
        // window.open(reportUrl, '_blank');
    }
};
const onUpdate = async () => {
    await updateReport({
        title: reportTitle.value,
        type: 'hours',
        workspaceId: currentWorkspaceId.value,
    }, props.id, currentWorkspaceTz.value);
    if (props.id) {
        await getSavedReportById(currentWorkspaceId.value, props.id);
    }
};

const onShare = () => {
    const reportUrl = `${window.location.origin}/public/reports/hourly/${savedReport.value.uuid}`;
    copy(reportUrl, {
        message: 'Публичная ссылка на отчет скопирована в буфер обмена'
    });
    // window.open(reportUrl, '_blank');
};

const initialFetchData = async () => {
    if (props.uuid) {
        await getSavedReportByUUID(currentWorkspaceId.value, props.uuid);
    } else {
        if (props.id) {
            await getSavedReportById(currentWorkspaceId.value, props.id);
        }
        if (!hide_clients) {
            await getContacts(currentWorkspaceId.value);
        }
        await getMembers(currentWorkspaceId.value);
        await getServices(currentWorkspaceId.value);
        await onStart();
    }

};
await initialFetchData();

/*===================COMPUTED AREA============================*/
const totalHours = computed(() => {
    const total = chartData.value.series.reduce((prev, curr) => {
        const totalBySeries = curr.data.reduce((acc, current) => {
            return acc.plus(current);
        }, Big(0));
        return prev.plus(totalBySeries);
    }, Big(0));
    return toFixedNumber(total);
});
</script>
<style lang='scss' scoped>
.hourly-reports {
    width: 98%;

    &__total {
        font-weight: bold;
    }
    &__title-input {
        font-size: 32px;
        font-weight: 500;
        color: #3c495a;
    }
}
</style>
