<template lang="pug">
.transaction-service-item
    a-input(
        v-model:value="item.title"
        @change="onChange('title', $event.target.value)"
        :disabled="disabled"
        placeholder="Основание"
    )
    price-input.ml-3(
        :price="item.amount"
        placeholder="Сумма"
        @update:price="onChange('amount', $event)"
        style="width: 100%"
    )
    currency-select.ml-3(
        v-model="currency" :disabled="true"
    )
    .transaction-service-item__delete.ml-3(@click="deleteItem" v-if="!disabled")
        icon(type="Delete")
</template>

<script>
import constants from '@/components/Workspace/InviteUserForm/constants';
import CurrencySelect from '@/components/Generic/CurrencySelect';
import PriceInput from '@/components/Generic/PriceInput';
export default {
    name: 'TransactionPositionItem',
    components: {PriceInput, CurrencySelect},
    props: {
        item: Object,
        disabled: {
            type: Boolean
        },
        currency: {
            type: String,
        }
    },
    emits: ['change', 'delete'],
    setup(props, {emit}) {
        const onChange = (key, value) => {
            const payload = {
                id: props.item.id,
                amount: props.item.amount,
                title: props.item.title,
                currency: props.item.currency || props.currency,
                salaryId: props.item.salaryId
            };
            payload[key] = value;
            emit('change', payload);
        };
        const deleteItem = () => {
            emit('delete', props.item.id);
        };
        return {
            constants,
            onChange,
            deleteItem,
        };
    }
};
</script>

<style lang="scss" scoped>
.transaction-service-item {
    display: flex;
    align-items: center;
    &__delete {
        cursor: pointer;
    }
}
</style>