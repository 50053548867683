import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d73fe73"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profit-and-loss-page" }
const _hoisted_2 = { class: "profit-and-loss-page__filter mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["SimpleMonthPeriodPicker"], {
        period: $setup.filter.period,
        "onUpdate:period": $setup.onPeriodChanged,
        placeholder: "Период",
        clearable: false
      }, null, 8, ["period"])
    ]),
    _createVNode($setup["PaymentPeriodCreateFormFinanceStats"], {
      class: "mt-3",
      "is-plan": false,
      plan: $setup.profitAndLoss.plan,
      balance: $setup.profitAndLoss.balance,
      fact: $setup.profitAndLoss.fact
    }, null, 8, ["plan", "balance", "fact"])
  ]))
}