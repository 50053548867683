import {httpInstance} from '@/modules/http_factory';
import moment from 'moment-timezone';
import constants from '@/constants';

const formFilter = (filter={}, tz=constants.tz) => {
    let from;
    let to;
    if (filter.period) {
        const utcOffset = moment.utc().tz(tz).format('Z');
        from = moment(filter.period).startOf('month').tz(tz).toISOString(true);
        to = moment(filter.period).endOf('month').tz(tz).toISOString(true);
    }
    if (filter.range && (filter.range[0] || filter.range[1])) {
        const [rangeFrom, rangeTo] = filter.range;
        if (rangeFrom) {
            from = moment(rangeFrom).tz(tz).toISOString(true);
        }
        if (rangeTo) {
            to = moment(rangeTo).endOf('day').tz(tz).toISOString(true);
        }
    }
    return {
        from,
        to,
        serviceId: filter.serviceId ? filter.serviceId : filter?.service?.id,
        workspaceMemberId: filter.workspaceMember?.id,
        taskId: filter.task?.id,
        wasEdited: filter.wasEdited && filter.wasEdited.id !== undefined ? !!filter.wasEdited.id : undefined,
        paymentStatus: filter?.paymentStatus?.id
    };
};
const createTimeLog = async (workspaceId, timeLog) => {
    const response = await httpInstance(workspaceId).post('/time-logs', {
        workspaceMemberId: timeLog.workspaceMemberId,
        taskId: timeLog.taskId,
        comment: timeLog.comment,
        workspaceId: timeLog.workspaceId,
        startDate: timeLog.startDate,
        endDate: timeLog.endDate,
        wasEdited: timeLog.wasEdited,
    });
    return response.data;
};
const getTimeLogById = async(workspaceId, id) => {
    const response = await httpInstance(workspaceId).get(`/time-logs/${id}`);
    return response.data;
};

const getTimeLogsByServiceId = async(workspaceId, serviceId, {paymentPeriodId, billType}) => {
    const response = await httpInstance(workspaceId).get(`/time-logs/service/${serviceId}`, {
        params: {
            paymentPeriodId,
            billType,
        }
    });
    return response.data;
};

const getDailyTotal = async(workspaceId) => {
    const response = await httpInstance(workspaceId).get('/time-logs/total/daily');
    return response.data;
};

const updateTimeLog = async(workspaceId, id, updateData) => {
    const response = await httpInstance(workspaceId).patch(`/time-logs/${id}`, updateData);
    return response.data;
};
const deleteTimeLog = async(workspaceId, id) => {
    const response = await httpInstance(workspaceId).delete(`/time-logs/${id}`);
    return response.data;
};
const getTimeLogs = async (workspaceId, pagination, filter={}, tz) => {
    const response = await httpInstance(workspaceId).get('/time-logs', {
        params: {
            ...pagination,
            ...formFilter(filter, tz),
            workspaceId
        }
    });
    return response.data;
};


export default {
    getTimeLogsByServiceId,
    getDailyTotal,
    createTimeLog,
    getTimeLogById,
    updateTimeLog,
    deleteTimeLog,
    getTimeLogs,
};