<template lang="pug">
v-select.custom-select-v2(
    :options="options"
    :label="label"
    :clearable="clearable"
    :disabled="disabled"
    :multiple="multiple"
    :placeholder="placeholder"
    :searchable="searchable"
    :taggable="taggable"
    :modelValue="modelValue"
    @update:modelValue="onChange"
    @search="onSearch"
    :filterable="filterable"
    :reduce="reduce"
    :style="style"
    :loading="loading"
)
</template>

<script>
export default {
    name: 'CustomSelectV2',
    errorCaptured(err, vm, info) {
        return false;
    }
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
/*===============INIT AREA===================*/
const props = defineProps({
    modelValue: {
        type: [Object, null]
    },
    options: {
        type: Array,
        default() {
            return [];
        }
    },
    label: {
        type: String,
        default: 'title'
    },
    clearable: {
        type: Boolean,
        default: true
    },
    disabled: {
        type: Boolean,
        default: false
    },
    loading: {
        type: Boolean,
        default: false
    },
    multiple: {
        type: Boolean,
        default: false
    },
    placeholder: {
        type: String,
        default: ''
    },
    searchable: {
        type: Boolean,
        default: true
    },
    filterable: {
        type: Boolean,
        default: false
    },
    taggable: {
        type: Boolean,
        default: false
    },
    reduce: {
        type: Function,
        default: option => option,
    },
    style: {
        type: Object
    }
});
const emit = defineEmits(['update:modelValue', 'search']);
/*===============HANDLERS AREA===================*/
const onSearch = (e) => {
    emit('search', e);
};
const onChange = (e) => {
    emit('update:modelValue', e);
};

</script>

<style lang="scss">
.vs__dropdown-option {
    &.vs__dropdown-option--selected {
        background: #a6e;
        color: #ffffff;
    }
    white-space: normal
}
</style>