import { createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "funds-flow-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["IncomingsAndOutgoingsFilter"], {
      filter: $setup.filter,
      "onUpdate:filter": $setup.onFilterChanged
    }, null, 8, ["filter"]),
    _createVNode($setup["CustomTable"], {
      columns: $setup.columns,
      "data-source": $setup.fundsFlow
    }, _createSlots({
      name: _withCtx(({record}) => [
        _createVNode($setup["IncomingsAndOutgoingsSubCategoriesList"], {
          subCategories: record.subCategories,
          caption: record.name
        }, null, 8, ["subCategories", "caption"])
      ]),
      sum: _withCtx(({record}) => [
        _createVNode($setup["IncomingsAndOutgoingsValuesList"], {
          subCategories: record.sum.items,
          total: record.sum.total,
          unit: record.sum.unit,
          year: $setup.filter.year,
          "workspace-id": $setup.currentWorkspaceId,
          month: null,
          quarter: null,
          type: record.type
        }, null, 8, ["subCategories", "total", "unit", "year", "workspace-id", "type"])
      ]),
      _: 2
    }, [
      _renderList($setup.periodColumns, (column) => {
        return {
          name: column.slots.customRender,
          fn: _withCtx(({record}) => [
            _createVNode($setup["IncomingsAndOutgoingsValuesList"], {
              subCategories: record[column.slots.customRender].items,
              total: record[column.slots.customRender].total,
              unit: record[column.slots.customRender].unit,
              month: $setup.filter.groupBy?.value === 'month' ? column.slots.customRender : null,
              quarter: $setup.filter.groupBy?.value === 'quarter' ? column.slots.customRender : null,
              year: $setup.filter.year,
              "workspace-id": $setup.currentWorkspaceId,
              type: record.type
            }, null, 8, ["subCategories", "total", "unit", "month", "quarter", "year", "workspace-id", "type"])
          ])
        }
      })
    ]), 1032, ["columns", "data-source"])
  ]))
}