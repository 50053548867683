<template lang="pug">
.service-filter
    .service-filter__row
        a-input.mr-3(
            placeholder="Поиск по названию услуги"
            :value="filter.search"
            style="width: 40%"
            @change="onChange('search', $event.target.value)"
        )
        date-only-picker.mr-3(
            :date="filter.deadline"
            placeholder="Дедлайн до"
            style="width: 100%"
            format="DD.MM.YYYY"
            @update:date="onChange('deadline', $event)"
        )
        custom-select-v2.mr-3(
            :model-value="filter.contacts"
            placeholder="Контакт"
            :style="{width: '100%'}"
            @update:modelValue="onChange('contacts', $event)"
            :options="clients.list"
            searchable
            @search="onContactsSearch"
            multiple
        )
        custom-select-v2.mr-3(
            :model-value="filter.performers"
            placeholder="Исполнители"
            :style="{width: '100%'}"
            @update:modelValue="onChange('performers', $event)"
            :options="members.list"
            searchable
            @search="onMembersSearch"
            multiple
            label="fullName"
        )
        .service-filter__rest
            a-popover(
                v-model:visible="showRestFilterItems"
                trigger="click"
                placement="bottomRight"
                overlayClassName="service-filter__popover"
            )
                template(#content)
                    .service-filter__popup
                        custom-select-v2.mr-3(
                            :model-value="filter.directors"
                            placeholder="Руководитель"
                            :style="{width: '100%'}"
                            @update:modelValue="onChange('directors', $event)"
                            :options="members.list"
                            searchable
                            @search="onMembersSearch"
                            multiple
                            label="fullName"
                        )
                        custom-select-v2.mr-3(
                            :model-value="filter.serviceGroup"
                            placeholder="Группа"
                            :style="{width: '100%'}"
                            @update:modelValue="onChange('serviceGroup', $event)"
                            :options="directories.serviceGroups.list"
                            :searchable="false"
                        )
                .service-filter__marker(v-if="filter.directors.length || filter.serviceGroup")
                icon(type="ArrowDown")
    .service-filter__row.mt-3
        a-tabs(:activeKey="filter.paymentPeriodStatus" @change="onChange('paymentPeriodStatus', $event)" type="card")
            a-tab-pane(
                :key="ServicePaymentPeriodStatus.oncoming"
                :tab="`${ServicePaymentPeriodStatusMap.oncoming} (${paymentPeriodsCount.oncoming})`"
            )
            a-tab-pane(
                :key="ServicePaymentPeriodStatus.active"
                :tab="`${ServicePaymentPeriodStatusMap.active} (${paymentPeriodsCount.active})`"
            )
            a-tab-pane(
                :key="ServicePaymentPeriodStatus.finished"
                :tab="`${ServicePaymentPeriodStatusMap.finished} (${paymentPeriodsCount.finished})`"
            )
            a-tab-pane(
                :key="ServicePaymentPeriodStatus.archive"
                :tab="`${ServicePaymentPeriodStatusMap.archive}`"
            )
</template>

<script>
import {ref, onMounted} from 'vue';
import {useDirectory} from '@/composition/directory';
import {useWorkspace} from '@/composition/workspace';
import {memberStatus, memberStatusMap} from '@/components/Workspace/Member/constants';
import {debounce, selectFilterFunc} from '@/modules/utils';
import {useContact} from '@/composition/contact';
import SelectSuspense from '../../Generic/SelectSuspense';
import ContactsSelect from '../../Contacts/Select/Contacts';
import WorkspaceMembersSelect from '../../Workspace/Member/Select';
import CustomSelectV2 from '@/components/Generic/CustomSelectV2';
import {useContactV2} from '@/composition/contact-v2';
import {useWorkspaceV2} from '@/composition/workspace-v2';
import {
    ServicePaymentPeriodStatus,
    ServicePaymentPeriodStatusMap,
} from '@/components/Services/PaymentPeriod/constants';
import DateOnlyPicker from '@/components/Generic/DateOnlyPicker';
export default {
    name: 'ServiceFilter',
    components: {DateOnlyPicker, CustomSelectV2, WorkspaceMembersSelect, ContactsSelect, SelectSuspense},
    props: {
        filter: Object,
        paymentPeriodsCount: Object,
    },
    emits: ['update:filter'],
    async setup(props, {emit}) {
        const showRestFilterItems = ref(false);
        const loading = ref(false);
        const {currentWorkspaceId} = useWorkspace();
        const {directories} = useDirectory(loading);
        const onChange = (key, value) => {
            emit('update:filter', {
                ...props.filter,
                [key]: value
            });
        };
        /*======================CONTACTS AREA=======================*/
        const {getClients, clients} = useContactV2();
        const onContactsSearch = (q) => {
            debounce(async () => {
                await getClients(currentWorkspaceId.value, q, true);
            }, 500)();
        };
        onMounted(async () => {
            await getClients(currentWorkspaceId.value, undefined, true);
        });
        /*=====================MEMBERS AREA=======================*/
        const {members, getMembers, setFilter: setMembersFilter} = useWorkspaceV2();
        const onMembersSearch = (q) => {
            setMembersFilter({search: q});
            debounce(() => getMembers(currentWorkspaceId.value), 500)();
        };
        onMounted(async () => {
            await getMembers(currentWorkspaceId.value);
        });
        return {
            onMembersSearch,
            clients,
            members,
            getClients,
            onContactsSearch,
            directories,
            memberStatus,
            memberStatusMap,
            selectFilterFunc,
            showRestFilterItems,
            onChange,
            ServicePaymentPeriodStatus,
            ServicePaymentPeriodStatusMap
        };
    }
};
</script>

<style lang="scss">
.service-filter {
    padding: 0 20px 0 0;
    &__row {
        display: flex;
        align-items: center;
    }
    &__rest {
        position: relative;
        cursor: pointer;
    }
    &__marker {
        position: absolute;
        width: 8px;
        height: 8px;
        background: #FF4D4F;
        border-radius: 50%;
        right: -8px;
        top: -8px;
    }
    &__popup {
        display: flex;
        align-items: center;
    }
    &__popover {
        width: 700px;
        height: 120px;
    }
}
</style>
