<template lang="pug">
integration-layout
    async-page
        integration-members-list
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import IntegrationLayout from '@/pages/Settings/Integrations/Layout';
import IntegrationMembersList from '@/components/Integrations/MembersList';
export default {
    name: 'IntegrationMembersPage',
    components: {IntegrationMembersList, IntegrationLayout, AsyncPage},
};
</script>

<style scoped>

</style>