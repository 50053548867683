<template lang="pug">
.integration-auth
    card-title Пользователи
    integration-members-list-mapping(
        :members="workspaceMembersByIntegration"
        v-if="currentWorkspace"
        :users="users.list"
        @search="onSearch"
        @sync="onSync"
    )
</template>

<script>
import CardTitle from '@/components/Generic/Typography/CardTitle';
import {ref} from 'vue';
import {useIntegration} from '@/composition/integration';
import {useRoute} from 'vue-router';
import {useWorkspace} from '@/composition/workspace';
import WorkspaceMembersList from '@/components/Workspace/MembersList';
import IntegrationMembersListMapping from '@/components/Integrations/MembersList/IntegrationMembersListMapping';
export default {
    name: 'IntegrationMembersList',
    components: {IntegrationMembersListMapping, WorkspaceMembersList, CardTitle},
    async setup() {
        const loading = ref(false);
        const route = useRoute();
        const {
            integration,
            getIntegration,
            getUsers,
            users,
            getIntegrationWorkspaceMembers,
            workspaceMembersByIntegration,
            getSteps
        } = useIntegration(loading);
        const {currentWorkspaceId, currentWorkspace} = useWorkspace(loading);
        const onSearch = async (q) => {
            await getUsers(currentWorkspaceId.value, route.params.integrationId, q);
        };
        const onSync = async () => {
            await getIntegrationWorkspaceMembers(currentWorkspaceId.value, route.params.integrationId);
            await getSteps(currentWorkspaceId.value, route.params.integrationId);
        };
        await getIntegration(currentWorkspaceId.value, route.params.integrationId);
        await getUsers(currentWorkspaceId.value, route.params.integrationId);
        await getIntegrationWorkspaceMembers(currentWorkspaceId.value, route.params.integrationId);
        return {
            integration,
            currentWorkspace,
            users,
            onSearch,
            workspaceMembersByIntegration,
            onSync,
        };
    }
};
</script>

<style lang="scss" scoped>
.integration-auth {
    &__form {
        width: 400px;
        margin: 20px 0;
    }
}
</style>