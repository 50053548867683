<template lang="pug">
.bills-summary
    .bills-summary__item
        card-title.mr-3(size="small") Получено
        a-progress(:percent="getPercent(summary.received, summary.estimatedReceived)" style="width: 30%")
        div {{ formatCurrency(summary.received) }} из {{ formatCurrency(summary.estimatedReceived) }} (Остаток {{ formatCurrency(summary.estimatedReceived - summary.received) }} )
</template>

<script>
import CardTitle from '@/components/Generic/Typography/CardTitle';
import {formatCurrency, getPercent} from '@/modules/utils';
export default {
    name: 'BillsSummary',
    components: {CardTitle},
    props: {
        summary: {
            type: Object,
            default: () => ({paid: 0, total: 0})
        }
    },
    setup() {
        return {
            getPercent,
            formatCurrency
        };
    }
};
</script>

<style lang="scss" scoped>
.bills-summary {
    display: grid;
    grid-template-columns: 600px 600px;
    grid-gap: 15px;
    &__item {
        display: flex;
        align-items: center;
        padding: 15px;
        border: 1px solid #e6e6e6;
        max-width: 600px;
    }
}
</style>