export const columns = [
    {
        title: '#',
        dataIndex: 'id',
        key: 'id',
        slots: {customRender: 'id'},
        width: '8%'
    },
    {
        title: 'Номер',
        dataIndex: 'number',
        key: 'number',
        slots: {customRender: 'number'},
        width: '14%'
    },
    {
        title: 'Дата',
        dataIndex: 'date',
        key: 'date',
        slots: {customRender: 'date'},
        width: '10%'
    },
    {
        title: 'Плательщик',
        slots: {customRender: 'customer'},
        width: '15%'
    },
    {
        title: 'Получатель',
        slots: {customRender: 'performer'},
        width: '15%'
    },
    {
        title: 'Оплачено',
        slots: {customRender: 'cost'},
        width: '20%'
    },
    {
        title: 'Оплатить до',
        slots: {customRender: 'paymentDates'},
        width: '15%'
    },
    {
        title: 'Статус',
        slots: {customRender: 'status'},
        width: '15%'
    },
    {
        title: 'Pdf',
        slots: {customRender: 'pdf'},
        width: '15%'
    },
    {
        title: 'Cумма транзакций',
        slots: {customRender: 'transactions'},
        width: '10%'
    },
    {
        title: '',
        slots: {customRender: 'action'},
    },
];

export const BillType = {
    incoming: 'incoming',
    outgoing: 'outgoing'
};

export const BillTypeMap = {
    incoming: 'Пополнение',
    outgoing: 'Списание'
};

export const BillPaymentStatusKey = {
    not_paid: 'not_paid',
    partially_paid: 'partially_paid'
};
