<template lang="pug">
.number-inline-changer
    .number-inline-changer__title(@click.stop="setPopupVisibility(true)")
        slot(name="title")
            span {{ number }}
    a-popover(
        :visible="showPopup"
        placement="bottomLeft"
    )
        template(#content)
            .number-inline-changer__popup
                a-input-number(
                    :placeholder="placeholder"
                    v-model:value="newNumber"
                )
                .number-inline-changer__popup-btns
                    a-button(@click="onSave" type="primary" :loading="loading") Сохранить
                    a-button.ml-3(@click="onDecline") Отменить
</template>

<script>
export default {
    name: 'NumberInlineChanger'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
/*===============INIT AREA===================*/
import {ref, watch} from 'vue';
import {useLoading} from '@/composition/loading-state';
import {useDate} from '@/composition/date';

const props = defineProps({
    number: {
        type: Number,
    },
    func: {
        type: Function
    },
    placeholder: {
        type: String,
    }
});
const emit = defineEmits(['done']);
const {loading, withLoader} = useLoading();
const {loading: fetchLoading, withLoader: wrapFetchAsync} = useLoading();
const {isOutdated, formatDate} = useDate();
/*===============SERVICE AREA===================*/
const newNumber = ref(props.number);

watch(() => props.number, () => {
    newNumber.value = props.number;
}, {deep: true});
const onSave = async () => {
    await withLoader(async () => {
        const number = newNumber.value ? newNumber.value : null;
        await props.func(number);
    });
    setPopupVisibility(false);
};
const onDecline = () => {
    newNumber.value = props.number;
    setPopupVisibility(false);
};
/*===============POPUP AREA===================*/
const showPopup = ref(false);
const setPopupVisibility = async (bool) => {
    showPopup.value = bool;
};
</script>

<style lang="scss">
.number-inline-changer {
    &__title {
        display: flex;
        flex-direction: column;
        width: fit-content;
        & span {
            text-decoration: underline;
            text-decoration-style: dotted;
            text-decoration-thickness: from-font;
            cursor: url("~@/assets/img/edit.png") 4 12, auto;
        }
    }
    &__popup {
        width: 240px;
        &-btns {
            margin-top: 12px;
        }
    }
}
.ant-calendar-picker-container {
    z-index: 1062;
}
</style>
