import {createApp, ref} from 'vue';
import Dialog from '../components/Generic/Dialog/index.vue';
export default {
    install(app, options) {
        const dialogContainer =  document.createElement('div');
        dialogContainer.setAttribute('id', 'dialog');
        document.body.appendChild(dialogContainer);
        const dialogApp = createApp(Dialog);
        dialogApp.mount('#dialog');
    }
};