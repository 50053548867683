<template lang="pug">
.bill-service
    .bill-service__service
        services-select(
            :modelValue="billService.serviceId"
            @update:modelValue="onChange('serviceId', $event)"
            :additionalFilter="additionalServiceFilter"
        )
        .ml-3(@click="deleteItem")
            icon(type="Delete")
        .flex.items-center.ml-3(v-if="billService.serviceId")
            custom-select-v2(
                placeholder="Выберите период"
                :modelValue="billService.paymentPeriod"
                @update:modelValue="onChange('paymentPeriod', $event)"
                :options="paymentPeriods"
                :style="{width: '230px'}"
                :clearable="false"
            )
    bill-service-item.mt-3.ml-3(
        v-for="item in billService.items"
        :key="item.id"
        :billServiceItem="item"
        @update="onUpdate"
        @delete="onDelete"
        :directories="directories"
        :canDelete="billService.items.length > 1"
    )
    .flex.items-center
        a-button.mt-3.ml-3(type="primary" @click="addNew") {{ billService.items.length === 0 ? 'Добавить' : 'Еще' }}
        .ml-3 Итого: {{ total }}
    a-divider
</template>

<script>
import {useService} from '@/composition/service';
import {ref, computed, onMounted} from 'vue';
import {useWorkspace} from '@/composition/workspace';
import BillServiceItem from '@/components/Bills/Services/ServiceItem';
import Icon from '@/components/Generic/Typography/Icon';
import ServicesSelect from '@/components/Services/Select';
import {servicePaymentType} from '@/components/Services/constants';
import CustomSelect from '@/components/Generic/CustomSelect/index';
import CustomSelectV2 from '@/components/Generic/CustomSelectV2/index';
export default {
    name: 'BillService',
    components: {CustomSelectV2, CustomSelect, Icon, BillServiceItem, ServicesSelect},
    props: {
        billService: Object,
        directories: Object,
        additionalServiceFilter: Object,
    },
    emits: ['update', 'delete', 'log-data'],
    setup(props, {emit}) {
        const {currentWorkspaceId} = useWorkspace();
        const {getServices, services, setFilter: setServiceFilter, getService} = useService();
        const paymentPeriods = ref([]);
        const defaultItem = () => ({
            id: Symbol(Date.now()),
            title: undefined,
            amount: undefined,
            unitId: undefined,
            sum: undefined,
        });
        const onServiceChanged = async (id) => {
            if (!id) return;
            const {ok, result: service} = await getService(id, currentWorkspaceId.value);
            if (ok && service) {
                paymentPeriods.value = service.paymentPeriods;
            }
        };
        const onPaymentPeriodChanged = (paymentPeriod) => {
            if (!paymentPeriod) return false;
            setLogData(paymentPeriod.id);
            return true;
        };
        const onChange = (key, value) => {
            if (key === 'serviceId') {
                onServiceChanged(value);
                emit('update', {
                    ...props.billService,
                    serviceId: value,
                    paymentPeriod: null,
                });
                return true;
            }
            if (key === 'paymentPeriod') {
                onPaymentPeriodChanged(value);
            }
            emit('update', {
                ...props.billService,
                [key]: value,
            });
            return true;
        };
        const onUpdate = (billServiceItem) => {
            const billService = props.billService;
            const billServiceItems = billService.items || [];
            onChange('items', billServiceItems.map(x => {
                if (x.id == billServiceItem.id) {
                    return {
                        ...x,
                        ...billServiceItem
                    };
                }
                return x;
            }));
        };
        const deleteItem = () => {
            emit('delete', props.billService.id);
        };
        const onDelete = (id) => {
            onChange('items', props.billService.items.filter(x => x.id != id));
        };
        const onSearch = async (q) => {
            setServiceFilter({search: q});
            await getServices(currentWorkspaceId.value);
        };
        const addNew = () => {
            onChange('items', [...props.billService.items, defaultItem()]);
        };

        const setLogData = (paymentPeriodId) => {
            props.billService.serviceId && emit('log-data', {
                paymentPeriodId,
                serviceId: props.billService.serviceId
            });
        };
        const total = computed(() => {
            return props.billService.items.reduce((prev, curr) => {
                return prev + (curr.amount || 0) * (curr.sum || 0);
            }, 0);
        });
        onMounted(() => {
            onServiceChanged(props.billService.serviceId);
        });
        return {
            addNew,
            total,
            onDelete,
            onSearch,
            services,
            onChange,
            onUpdate,
            deleteItem,
            setLogData,
            servicePaymentType,
            paymentPeriods,
        };
    }
};
</script>

<style lang="scss" scoped>
.bill-service__service {
    display: flex;
    align-items: center;
}
</style>
