<template lang="pug">
.integration-auth
    card-title Проекты
    a-table(:columns="projectColumns" :data-source="projects.list" :pagination="false")
        template(#integrationProject="{text, record}")
            div(v-if="record")
                .input__label(v-if="isChangingDisabled(record)") Проект связан с другой интеграцией
                a-select.w-full(
                    placeholder="Проект интеграции"
                    @change="(e) => onProjectChanged(e, record)"
                    :filter-option="false"
                    @search="onSearch"
                    show-search
                    :default-value="getDefaultValue(record)"
                    :disabled="isChangingDisabled(record)"
                )
                    a-select-option(
                        v-for="project in integrationProjects.list"
                        :key="project.id"
                    ) {{ project.name }}
        template(#integrationStatus="{text, record}")
            div(v-if="record && getDefaultValue(record) && !isChangingDisabled(record)")
                a-button(type="primary" @click="setModalVisibility(true, record)") Синхронизировать статусы
    a-button.mt-3(@click="next" v-if="projects.next" :loading="loading") Показать еще
    a-modal(
        v-model:visible="showModal"
        title="Статусы"
        :footer="null"
        @cancel="setModalVisibility(false, undefined)"
        :destroyOnClose="true"
        width="100%"
        style="top: 0"
    )
        integration-statuses-list(
            :workspaceId="currentWorkspaceId"
            :project="currentProject"
            :integrationId="integrationId"
        )
</template>

<script>
import CardTitle from '@/components/Generic/Typography/CardTitle';
import ProjectsTable from '@/components/Projects/Table';
import {projectColumns} from './constants';
import {ref} from 'vue';
import {useProject} from '@/composition/project';
import {useWorkspace} from '@/composition/workspace';
import EditableCell from '@/components/Generic/EditableCell';
import {useIntegration} from '@/composition/integration';
import {useRoute} from 'vue-router';
import IntegrationStatusesList from '@/components/Integrations/StatusesList';
export default {
    name: 'IntegrationProjects',
    components: {IntegrationStatusesList, EditableCell, ProjectsTable, CardTitle},
    async setup() {
        const loading = ref(false);
        const route = useRoute();
        const {projects, getProjects, pagination} = useProject(loading);
        const {
            getProjects: getIntegrationProjects,
            projects: integrationProjects,
            syncProject,
            getSteps,
        } = useIntegration(loading);
        const {currentWorkspaceId} = useWorkspace(loading);
        await getProjects(currentWorkspaceId.value);
        await getIntegrationProjects(currentWorkspaceId.value, route.params.integrationId);
        const next = async () => {
            pagination.value.offset += pagination.value.limit;
            await getProjects(currentWorkspaceId.value, {fromPagination: true});
        };
        const onProjectChanged = async(integrationProjectId, project) => {
            pagination.value.offset = 0;
            const integrationProject = integrationProjects.value.list.find(x => x.id === integrationProjectId);
            await syncProject(currentWorkspaceId.value, route.params.integrationId, integrationProjectId, project.id, integrationProject.name);
            await getProjects(currentWorkspaceId.value);
            await getSteps(currentWorkspaceId.value, route.params.integrationId);
        };
        const onSearch = async (q) => {
            await getIntegrationProjects(currentWorkspaceId.value, route.params.integrationId, q);
        };
        const getDefaultValue = (project) => {
            return project?.integrationProject?.name;
        };
        const isChangingDisabled = (project) => {
            if (!project.integrationProject) return false;
            return project.integrationProject?.integrationId != route.params.integrationId;
        };
        const showModal = ref(false);
        const currentProject = ref({});
        const setModalVisibility = (bool, value) => {
            showModal.value = bool;
            currentProject.value = value;
        };
        return {
            projectColumns,
            projects,
            loading,
            next,
            onProjectChanged,
            onSearch,
            integrationProjects,
            getDefaultValue,
            setModalVisibility,
            showModal,
            currentProject,
            currentWorkspaceId,
            integrationId: route.params.integrationId,
            isChangingDisabled
        };
    }
};
</script>

<style lang="scss" scoped>
.integration-auth {
    &__form {
        width: 400px;
        margin: 20px 0;
    }
}
</style>