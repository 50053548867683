import {httpInstance} from '@/modules/http_factory';
const getProjects = async (workspaceId, pagination, search) => {
    const response = await httpInstance(workspaceId).get('/projects', {
        params: {
            ...pagination,
            workspaceId,
            search,
        }
    });
    return response.data;
};
const createProject = async (project, workspaceId) => {
    const response = await httpInstance(workspaceId).post('/projects', {
        ...project,
        workspaceId
    });
    return response.data;
};
const getProject = async (id, workspaceId) => {
    const response = await httpInstance(workspaceId).get(`/projects/${id}`);
    return response.data;
};
const updateProject = async (id, updateData, workspaceId) => {
    const response = await httpInstance(workspaceId).patch(`/projects/${id}`, updateData);
    return response.data;
};
const deleteProject = async (id, workspaceId) => {
    const response = await httpInstance(workspaceId).delete(`/projects/${id}`);
    return response.data;
};

export default {
    getProjects,
    createProject,
    getProject,
    updateProject,
    deleteProject,
};