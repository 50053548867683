import {isUndefined, omitByTs} from '@/modules/utils/v2';
import {TimeLineFormInterface} from '@/modules/resource-management/form/time-line-form.interface';
import {CreateTimeLineDto} from '@/modules/resource-management/dto/create-time-line.dto';
import {UpdateTimeLineDto} from '@/modules/resource-management/dto/update-time-line.dto';
import {GetTimeLinesDto} from '@/modules/resource-management/dto/get-time-lines.dto';
import {TimeLineSettingsInterface} from '@/modules/resource-management/filters/time-line-settings.interface';
import moment from 'moment';

export class TimeLineMapper {
    static toCreateDto(form: TimeLineFormInterface): CreateTimeLineDto {
        return {
            serviceId: form.service && form.service.id,
            workspaceMemberId: form.workspaceMemberId,
            index: form.index,
            type: form.type,
            start: form.start,
            end: form.end,
            color: form.color,
            hoursAmount: form.hoursAmount,
            description: form.description
        };
    }

    static toUpdateDto(form: TimeLineFormInterface): UpdateTimeLineDto {
        const updateDto: UpdateTimeLineDto = omitByTs<UpdateTimeLineDto>({
            serviceId: form.service && form.service.id,
            workspaceMemberId: form.workspaceMemberId,
            index: form.index,
            type: form.type,
            start: form.start,
            end: form.end,
            color: form.color,
            hoursAmount: form.hoursAmount,
            description: form.description
        }, (value: any) => !isUndefined(value));
        return updateDto;
    }

    static formFilter(timeLinesFilter: TimeLineSettingsInterface): GetTimeLinesDto {
        const base = moment(timeLinesFilter.period);
        const start = base.clone().startOf('month').format('YYYY-MM-DD');
        const end = base.clone().endOf('month').format('YYYY-MM-DD');
        return {
            start,
            end,
        };
    }
}