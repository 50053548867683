<template lang="pug">
.salary-payments-list-table
    custom-table(
        :columns="columns"
        :data-source="data"
        :loading="loading"
        :onRowClick="edit"
        :collapse="true"
        childrenKey="listMembers"
        :children-columns="childrenColumns"
        :hide-children-columns="true"
        :default-children-open="true"
    )
        template(#fullName="{record}")
            div {{ record.fullName }}
        template(#paymentProgress="{record}")
            div {{ formatCurrency(record.total) }} руб.
            status-progress(
                :percent="record.paidPercent"
                :tooltip-text="`Оплачено ${record.paid} из ${record.total} руб.`"
            )
        template(#link="{record}")
            .base-link(@click="() => createNewTransaction(record)") Провести транзакцию
        template(#identifier="{record}")
            div {{ record.number }}
        template(#period="{record}")
            div {{ renderPeriod(record.period)}}
        template(#dates="{record}")
            div {{ formatDate(record.dateFrom) }} - {{ formatDate(record.dateTo) }}
        template(#payDateTo="{record}")
            div(:class="[isOutdated(record.payDateTo) ? 'danger' : '']") {{ formatDate(record.payDateTo) }}
        template(#paid="{record}")
            div
                div {{ formatCurrency(record.total) }} руб.
                status-progress(
                    :percent="record.paidPercent"
                    :tooltip-text="`Оплачено ${record.paid} из ${record.total} руб.`"
                )
        template(#actions="{record}")
            delete-with-confirm(:delete-func="() => deleteRow(record.id)")
    a-button.mt-3(@click="next" v-if="hasNext" :loading="paginationLoading") Показать еще
a-drawer(
    :visible="showModal"
    title="Добавить транзакцию"
    @close="setModalVisibility(false)"
    :closable="true"
    width="40%"
    :footer="null"
    :destroyOnClose="true"
)
    create-transaction-form(
        :workspaceId="currentWorkspaceId"
        @ok="onTransactionCreated"
        :salary-list-id="salaryListId"
        :default-value="editingElement"
    )
</template>

<script>
export default {
    name: 'SalaryPaymentsListTable',
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
import {ref} from 'vue';
import CustomTable from '@/components/Generic/CustomTable';
import {columns, childrenColumns} from '@/components/SalaryPaymentsList/constants';
import DeleteWithConfirm from '@/components/Generic/DeleteWithConfirm';
import {useDate} from '@/composition/date';
import StatusProgress from '@/components/Generic/StatusProgress';
import {formatCurrency} from '@/modules/utils';
import CreateTransactionForm from '@/components/Transactions/CreateForm';
import {useCreateOrUpdateModal} from '@/composition/create-update-modal';
import {useWorkspace} from '@/composition/workspace';
/*===============INIT AREA===================*/
const props = defineProps({
    data: {
        type: Array,
        required: true,
    },
    loading: {
        type: Boolean,
    },
    paginationLoading: {
        type: Boolean,
    },
    hasNext: {
        type: Boolean,
    },
});
const emit = defineEmits(['edit', 'delete', 'next', 'fetch']);
const {formatMonth, formatDate, isOutdated} = useDate();
/*===============EMITTERS AREA===================*/
const edit = (row) => {
    emit('edit', row);
};
const deleteRow = (id) => {
    emit('delete', id);
};
const next = () => {
    emit('next');
};
/*===============RENDERERS AREA===================*/
const renderPeriod = (period) => {
    return formatMonth(period);
};
/*===============TRANSACTIONS AREA===================*/
const {currentWorkspaceId} = useWorkspace();
const {setModalVisibility, showModal, currentMode, edit: onEdit, editingValue: editingElement} = useCreateOrUpdateModal();
const onTransactionCreated = () => {
    editingElement.value = {};
    emit('fetch');
    setModalVisibility(false);
};
const createNewTransaction = (record) => {
    editingElement.value = {
        salaryListId: record.salaryPaymentsListId,
        contractorId: record.contactId
    };
    setModalVisibility(true);
};
</script>

<style lang="scss">
</style>