<template lang="pug">
async-page
    bills-table
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import BillsTable from '@/components/Bills/Table';
export default {
    name: 'BillsPage',
    components: {BillsTable, AsyncPage}
};
</script>

<style scoped>

</style>