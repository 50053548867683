export const childrenColumns = [
    {
        title: 'ФИО',
        slots: {customRender: 'fullName'},
        width: '25%'
    },
    {
        title: '',
        slots: {customRender: 'paymentProgress'},
        width: '20%'
    },
    {
        title: '',
        slots: {customRender: 'link'},
        width: '20%'
    },
];
export const columns = [
    {
        title: 'Идентификатор',
        slots: {customRender: 'identifier'},
        width: '25%'
    },
    {
        title: 'Платежный период',
        slots: {customRender: 'period'},
        width: '20%'
    },
    {
        title: 'Период расчета',
        slots: {customRender: 'dates'},
        width: '25%'
    },
    {
        title: 'Оплачено',
        slots: {customRender: 'paid'},
        width: '35%'
    },
    {
        title: 'Оплатить до',
        slots: {customRender: 'payDateTo'},
        width: '35%'
    },
    {
        title: '',
        slots: {customRender: 'actions'},
        width: '10%'
    },
];

export const salaryPositionColumns = [
    {
        title: 'Услуга',
        slots: {customRender: 'service'},
        width: '45%'
    },
    {
        title: 'Тип услуги',
        slots: {customRender: 'servicePaymentType'},
        width: '25%'
    },
    {
        title: 'Кол-во часов',
        slots: {customRender: 'hoursAmount'},
        width: '25%'
    },
    {
        title: 'Часовая ставка',
        slots: {customRender: 'hourlyRate'},
        width: '25%'
    },
    {
        title: 'Стоимость',
        slots: {customRender: 'total'},
        width: '30%'
    },
    {
        title: '',
        slots: {customRender: 'actions'},
        width: '5%'
    },
];

export const getForm = (defaultValue, getMonthFromNow, getDateFromNow) => {
    return {
        members: {
            value: defaultValue.members || [],
            error: undefined,
        },
        dateFrom: {
            value: defaultValue.dateFrom,
            error: undefined,
        },
        dateTo: {
            value: defaultValue.dateTo,
            error: undefined,
        },
        period: {
            value: defaultValue.period || {
                label: getMonthFromNow().format('MMMM, YYYY'),
                value: getMonthFromNow().format('YYYY-MM-01')
            },
            error: undefined,
        },
        payDateTo: {
            value: defaultValue.payDateTo || getDateFromNow(3),
            error: undefined,
        },
        listMembers: {
            value: defaultValue.listMembers || [],
            error: undefined
        }
    };
};

export const statusesMap = {
    not_paid: 'Не оплачено',
    in_progress: 'Частично оплачено',
    paid: 'Оплачено',
};