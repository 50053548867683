import {ref} from 'vue';
import {apiRequest} from '@/modules/error_handler';
import convertModule from '@/modules/convert';

const stats = ref({
    rub: 0,
    usd: 74.577,
    euro: 89.6788,
    pounds: 103.6695,
});

export function useConvert() {
    const convertToRub = (amount, from) => {
        if (!amount) return 0;
        if (!from) return 0;
        const rate = stats.value?.[from];
        return rate * amount;
    };
    const getCurrencyStats = async () => {
        return apiRequest(async () => {
            stats.value = await convertModule.getCurrencyStats();
            return stats.value;
        });
    };
    return {
        convertToRub,
        getCurrencyStats
    };
}