<template lang="pug">
.member-professional-data
    card-title Профессиональные данные
    .member-professional-data__column.mt-2
        .flex.items-center Группа
        editable-cell(
            :initialValue="member.groupId"
            @update="(e) => onUpdate('groupId', e)"
            :title="getGroupText()"
            type="select"
            :options="directories.groups.list"
        )
    .member-professional-data__column
        .flex.items-center Предпочтительная загрузка в месяц, ч
        editable-cell(:initialValue="member.hoursPayload" @update="(e) => onUpdate('hoursPayload', e)" :title="getHoursPayloadText()")
    .member-professional-data__column
        .flex.items-center Навыки
        editable-cell(
            :initialValue="member.skills.map(x => x.skill.id)"
            @update="(e) => onUpdate('skills', e)"
            type="select_multiple"
            :title="getSkillsText()"
            :options="directories.skills.list"
        )
    .member-professional-data__column
        .flex.items-center Грейд
        editable-cell(
            :initialValue="member.gradeId"
            @update="(e) => onUpdate('gradeId', e)"
            :title="getGradeText()"
            type="select"
            :options="directories.grades.list"
        )
</template>

<script>
import EditableCell from '@/components/Generic/EditableCell';
import constants from '@/constants';
import CardTitle from '@/components/Generic/Typography/CardTitle';
export default {
    name: 'MemberProfessionalData',
    components: {CardTitle, EditableCell},
    props: {
        member: Object,
        directories: Object,
    },
    emits: ['update'],
    async setup(props, {emit}) {
        const getSkillsText = () => {
            if (!props.member.skills || !Array.isArray(props.member.skills) || !props.member.skills.length) {
                return 'Не указано';
            }
            return props.member?.skills.map(x => x.skill.title).join(', ');
        };
        const getGradeText = () => {
            return props.member?.grade?.title || 'Не указано';
        };
        const getGroupText = () => {
            return props.member?.group?.title || 'Не указано';
        };
        const getHoursPayloadText = () => {
            return props.member?.hoursPayload || 'Не указано';
        };
        const onUpdate = (key, value) => {
            emit('update', {[key]: value});
        };
        return {
            getSkillsText,
            getGradeText,
            getGroupText,
            constants,
            onUpdate,
            getHoursPayloadText,
        };
    }
};
</script>

<style  lang="scss" scoped>
.member-professional-data {
    &__column {
        margin-bottom: 30px;
        display: grid;
        grid-template-columns: 200px 400px;
        grid-gap: 15px;
    }
}
</style>