<template lang="pug">
.page.service
    card-title {{ bill.number }} от {{ formatDate(bill.date) }}
    .service__main-info
        a-button(@click="editBill(bill)" type="primary") Редактировать
    a-tabs(v-model:activeKey="activeKey" @change="onChange")
        a-tab-pane(v-for="tab in tabs" :key="tab.key" :tab="tab.tab")
    slot
a-modal(
    v-model:visible="showModal"
    title="Редактировать счет"
    :footer="null"
    @cancel="setModalVisibility(false)"
    :destroyOnClose="true"
    width="50%"
)
    create-bill-form(
        :workspaceId="currentWorkspaceId"
        @update="putUpdate"
        :mode="currentMode"
        :default-value="editingElement"
    )
</template>

<script>
import {onMounted, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useWorkspace} from '@/composition/workspace';
import PageTitle from '@/components/Generic/PageTitle';
import {useDate} from '@/composition/date';
import {useCreateOrUpdateModal} from '@/composition/create-update-modal';
import {useBill} from '@/composition/bill';
import CreateBillForm from '@/components/Bills/CreateForm';
import CardTitle from '@/components/Generic/Typography/CardTitle';
export default {
    name: 'BillLayout',
    components: {CardTitle, CreateBillForm, PageTitle},
    setup() {
        const {
            currentMode,
            editingValue: editingElement,
            showModal,
            setModalVisibility,
            edit
        } = useCreateOrUpdateModal();
        const activeKey = ref(undefined);
        const {currentWorkspaceId} = useWorkspace();
        const {bill, getBillById, updateBill} = useBill();
        const router = useRouter();
        const route = useRoute();
        const tabs = [
            {
                key: 'common',
                tab: 'Основная информация'
            },
            {
                key: 'transactions',
                tab: 'Транзакции'
            },
        ];
        const onChange = (val) => {
            router.push(`/${currentWorkspaceId.value}/bills/${route.params.billId}/${val}`);
        };
        const putUpdate = async (data) => {
            const {id, ...updateData} = data;
            await updateBill(currentWorkspaceId.value, id, updateData);
            await getBillById(route.params.billId, currentWorkspaceId.value);
            setModalVisibility(false);
        };
        const editBill = (bill) => {
            edit({
                ...bill,
                bankAccountId: bill.bankAccount
            });
        };
        onMounted(async() => {
            const paths = route.path.split('/');
            activeKey.value = paths[paths.length - 1];
            await getBillById(route.params.billId, currentWorkspaceId.value);
        });
        return {
            tabs,
            showModal,
            activeKey,
            currentMode,
            editingElement,

            bill,
            onChange,
            putUpdate,
            editBill,
            setModalVisibility,
            currentWorkspaceId,
            ...useDate()
        };
    }
};
</script>

<style lang="scss" scoped>
.service {
    &__main-info {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 16px;
        margin-top: 16px;
    }
}
</style>