<template lang="pug">
.header
    .header__status
        .header__timing
            .flex.items-center
                .font-bold.whitespace-nowrap.mr-2 Рабочий день
                .text-gray-400.font-bold {{ secondsToHumanReadable(dailyTotal + aggregator) }}
            .header__task
                //task-logging(
                //    v-if="user.activeTask"
                //    :id="user.activeTask.id"
                //    :is-active="true"
                //    :totalTimingInSeconds="user.activeTask.totalTimingInSeconds"
                //    :activeLog="user.activeTask.activeLog"
                //    @stop="onStop"
                //)
                .ml-5.cursor-pointer(v-if="user.activeTask" @click="redirectToTask")
                    .font-bold.header__title {{ user.activeTask.fullTitle }}
                    .text-gray-400.font-bold.header__title {{ user.activeTask?.service?.title }}
        div
            .header-profile.flex.items-center
                router-link(:to="renderProfilePath()")
                    user-avatar(:user="user" placement="leftTop")
                router-link.ml-4(to="/logout")
                    icon(type="Logout")
    .header__page-title.flex.items-center.justify-between.h-full(v-if="isPageTitleVisible")
        page-title
</template>

<script>
import {useAuth} from '@/composition/auth';
import {useWorkspace} from '@/composition/workspace';
import WorkspaceTitle from '@/components/Generic/Typography/WorkspaceTitle';
import PageTitle from '@/components/Generic/PageTitle';
import TaskLogging from '@/components/Tasks/TaskLogging';
import {useClock} from '@/composition/clock';
import {watch, ref, computed} from 'vue';
import {useTimelog} from '@/composition/timelog';
import {secondsToHumanReadable} from '@/modules/utils';
import {useCreateOrUpdateModal} from '@/composition/create-update-modal';
import moment from 'moment';
import {useKanban} from '@/composition/kanban';
import {useDate} from '@/composition/date';
import constants from '@/constants';
import CreateTimeLogForm from '@/components/TimeLogs/CreateForm';
import {useRoute, useRouter} from 'vue-router';
import {useLayout} from '@/composition/layout';
const defaultTz = constants.tz.default;
export default {
    name: 'TheHeader',
    components: {CreateTimeLogForm, TaskLogging, PageTitle, WorkspaceTitle},
    setup() {
        const loading = ref(false);
        const {user, me} = useAuth(loading);
        const router = useRouter();
        const route = useRoute();
        const {sidebarWidth} = useLayout();
        const {currentWorkspaceId, currentWorkspaceTz} = useWorkspace(loading);
        const disableTitleForRoutes = [
            `/${currentWorkspaceId.value}/tasks`
        ];
        const isPageTitleVisible = computed(() => {
            return disableTitleForRoutes.findIndex(x => x === route.path) === -1;
        });
        const {
            dailyTotal,
            getDailyTotal,
            setUncommittedLog,
            setUncommittedLogModalVisibility,
        } = useTimelog(loading);
        const {stopTask, getTasks, activeTask} = useKanban(loading);
        const {getHoursAndMinutesFromMs} = useDate();
        const renderProfilePath = () => {
            return `/${currentWorkspaceId.value}/profile/profile`;
        };
        const {aggregator, setActive} = useClock('dailyTotal');
        setActive(!!user.value.activeTask, user.value?.activeTask?.activeLog?.startDate);
        watch(() => user.value.activeTask, () => {
            setActive(!!user.value.activeTask, user.value?.activeTask?.activeLog?.startDate);
        });
        const stopActiveLog = async (stoppingTimeLog) => {
            setUncommittedLogModalVisibility(true);
            activeTask.value = {
                id: null
            };
            setUncommittedLog({
                ...stoppingTimeLog,
                startDate: moment.utc(stoppingTimeLog.startDate).tz(currentWorkspaceTz.value || defaultTz),
                startTime: {
                    hours: moment.utc(stoppingTimeLog.startDate).tz(currentWorkspaceTz.value || defaultTz).get('hours'),
                    minutes: moment.utc(stoppingTimeLog.startDate).tz(currentWorkspaceTz.value || defaultTz).get('minutes'),
                    seconds: moment.utc(stoppingTimeLog.startDate).tz(currentWorkspaceTz.value || defaultTz).get('seconds'),
                },
                duration: getHoursAndMinutesFromMs(stoppingTimeLog.durationInMs)
            });
            await getTasks(currentWorkspaceId.value);
            await getDailyTotal(currentWorkspaceId.value);
            await me(currentWorkspaceId.value);
        };
        // const handleUncommittedLog = async () => {
        //     if (user.value.uncommittedLog) {
        //         await stopActiveLog(user.value.uncommittedLog);
        //     }
        // };
        // handleUncommittedLog();
        // watch(() => user.value.uncommittedLog, (curr, prev) => {
        //     if (prev?.id != curr?.id) {
        //         handleUncommittedLog();
        //     }
        // });
        const onStop = async ({id, comment}) => {
            const {ok, result: timeLog} = await stopTask(id, {comment}, currentWorkspaceId.value);
            if (ok) {
                await stopActiveLog(timeLog);
            }
        };
        const redirectToTask = () => {
            const userActiveTaskId = user.value?.activeTask?.id;
            return userActiveTaskId && router.push(`/${currentWorkspaceId.value}/tasks/${user.value?.activeTask?.id}`);
        };
        return {
            sidebarWidth,
            redirectToTask,
            user,
            aggregator,
            currentWorkspaceId,
            renderProfilePath,
            dailyTotal,
            secondsToHumanReadable,
            onStop,
            loading,
            isPageTitleVisible,
        };
    }
};
</script>

<style lang="scss" scoped>
.header {
    width: calc(100% - 15px);
    background: transparent;
    padding: 10px 0;
    &__status {
        width: calc(100vw - v-bind(sidebarWidth) - 25px);
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        z-index: 2;
        top: 0;
        background: #FFFFFF;
    }
    &__task {
        display: flex;
        align-items: center;
        width: 300px;
        margin-left: 24px;
    }
    &__title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 200px;
    }
    &__timing {
        display: flex;
        align-items: center;
    }
    &__page-title {
        //margin-top: 50px;
    }
}
</style>
