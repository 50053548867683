<template lang="pug">
.salary-list-widget.widget-card
    .widget-card__text.font-bold
        .inline-flex(v-if="planText") {{ planText }}
        .inline-flex.mx-1(v-if="planText && factText") /
        .inline-flex(v-if="factText") {{ factText }}
        .inline-flex.mx-1(v-if="factText && diffText") /
        .inline-flex(v-if="diffText") {{ diffText }}
        .inline-flex(v-if="unitText") , {{ unitText }}
    .widget-card__num.mt-3
        .inline-flex(v-if="planText") {{ formatCurrency(plan || 0) }}
        .inline-flex.mx-1(v-if="planText && factText") /
        .inline-flex(v-if="factText") {{ formatCurrency(fact || 0) }}
        .inline-flex.mx-1(v-if="factText && diffText") /
        .inline-flex(v-if="diffText") {{ formatCurrency(diff || 0) }}
</template>

<script>
export default {
    name: 'SalaryListWidget'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
import {formatCurrency} from '@/modules/utils';
import Skeleton from '@/components/Generic/Skeleton';
/*===============INIT AREA===================*/
const props = defineProps({
    planText: {
        type: String,
        required: false,
    },
    factText: {
        type: String,
        required: false,
    },
    diffText: {
        type: String,
        required: false,
    },
    unitText: {
        type: String,
        required: true,
    },
    plan: {
        type: Number,
        required: true,
    },
    fact: {
        type: Number,
        required: true,
    },
    diff: {
        type: Number,
        required: true,
    },
    loading: {
        type: Boolean
    }
});
</script>

<style lang="scss">
.salary-list-widget {
    height: 130px;
    width: 100%;
}
</style>