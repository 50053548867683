<template lang="pug">
.salary-payments-list-filter
    a-input-number(:min="2020" :max="2100" :value="filter.year" @change="(e) => onChange('year', e)" placeholder="Год")
</template>

<script>
export default {
    name: 'SalaryPaymentsListFilter',
    props: {
        filter: Object,
    },
    emits: ['update:filter'],
    setup(props, {emit}) {
        const onChange = (key, value) => {
            emit('update:filter', {
                ...props.filter,
                [key]: value
            });
        };
        return {
            onChange
        };
    }
};
</script>

<style lang="scss" scoped>
.salary-payments-list-filter {
    display: flex;
}
</style>
