import {httpInstance} from '@/modules/http_factory';
import {arrayToString} from '../utils';

const createBill = async (workspaceId, bill) => {
    const response = await httpInstance(workspaceId).post('/bills', bill);
    return response.data;
};
const getBillById = async(id, workspaceId) => {
    const response = await httpInstance(workspaceId).get(`/bills/${id}`);
    return response.data;
};
const formFilter = (workspaceId, filter) => {
    return {
        workspaceId,
        paymentStatuses: filter.paymentStatuses && Array.isArray(filter.paymentStatuses) && filter.paymentStatuses.length ? arrayToString(filter.paymentStatuses, true) : undefined,
        statuses: filter.statuses && Array.isArray(filter.statuses) && filter.statuses.length ? filter.statuses.join(',') : undefined,
        dateStatuses: filter.dateStatuses && Array.isArray(filter.dateStatuses) && filter.dateStatuses.length ? filter.dateStatuses.join(',') : undefined,
        state: filter.state,
        search: filter.search,
        status: filter.status,
        contractorId: filter.contractorId,
        contactGroupId: filter.contactGroupId === 'all' ? undefined : filter.contactGroupId,
        serviceId: filter.serviceId
    };
};
const getSummary = async(workspaceId, filter) => {
    const response = await httpInstance(workspaceId).get('/bills/summary', {
        params: {
            ...formFilter(workspaceId, filter)
        }
    });
    return response.data;
};

const getTabs = async(workspaceId, filter) => {
    const response = await httpInstance(workspaceId).get('/bills/tabs', {
        params: {
            ...formFilter(workspaceId, filter)
        }
    });
    return response.data;
};
const getDefaultNumber = async(workspaceId) => {
    const response = await httpInstance(workspaceId).get('/bills/default-number');
    return String(response.data);
};

const updateBill = async(workspaceId, id, updateData) => {
    const response = await httpInstance(workspaceId).patch(`/bills/${id}`, updateData);
    return response.data;
};
const getRawBill = async(id) => {
    const response = await httpInstance().get(`/bills/${id}/template`, {
        responseType: 'arraybuffer'
    });
    return response.data;
};
const deleteBill = async(workspaceId, id) => {
    const response = await httpInstance(workspaceId).delete(`/bills/${id}`);
    return response.data;
};
const getBills = async (workspaceId, pagination, filter={}) => {
    const response = await httpInstance(workspaceId).get('/bills', {
        params: {
            ...pagination,
            ...formFilter(workspaceId, filter)
        }
    });
    return response.data;
};

export default {
    getTabs,
    getSummary,
    createBill,
    getBillById,
    updateBill,
    deleteBill,
    getBills,
    getDefaultNumber,
    getRawBill,
};