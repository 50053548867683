<template lang="pug">
service-layout
    async-page
        service-time-logs
</template>

<script>
import ServiceLayout from '@/pages/Services/Service/Layout';
import AsyncPage from '@/layouts/async-page';
import ServiceTimeLogs from '@/components/Services/TimeLogs/index';
export default {
    name: 'ServiceTimeLogsPage',
    components: {ServiceTimeLogs, AsyncPage, ServiceLayout}
};
</script>

<style scoped>

</style>
