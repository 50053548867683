<template lang="pug">
.flex.items-center
    back-btn.mr-4(v-if="showBackBtn")
    typography-title {{ routeName }}
</template>

<script>
import {useRoute} from 'vue-router';
import {ref, watch} from 'vue';
import BackBtn from '@/components/Generic/BackBtn';
export default {
    name: 'PageTitle',
    components: {
        BackBtn
    },
    setup() {
        const route = useRoute();
        const title = ref(route.meta.name);
        const showBackBtn = ref(route.meta.backButton);
        watch(() => route.path, () => {
            title.value = route.meta.name;
            showBackBtn.value = route.meta.backButton;
        });
        return {
            routeName: title,
            showBackBtn
        };
    }
};
</script>

<style scoped>

</style>