<template lang="pug">
.saved-report-card
    hourly-reports(:id="route.params.reportId")
</template>

<script>
export default {
    name: 'SavedReportCard'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/

import HourlyReports from '@/components/Analytics/HourlyReports';
import {useRoute} from 'vue-router';

/*===============ROUTING AREA===================*/

const route = useRoute();

</script>

<style lang="scss">
</style>