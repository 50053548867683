import {ValidationError} from '@/modules/cast/validation.error';

const incomingSchema = {
    billId: {
        type: 'number',
        nullable: true,
        optional: true
    },
    type: {
        type: 'string',
        optional: true
    },
    contractorId: {
        type: 'number',
        optional: true
    },
    toAccountId: {
        type: 'number',
        optional: true
    },
    amount: {
        type: 'number',
        nullable: true,
        optional: true
    },
    services: {
        type: 'array',
        nullable: true,
        optional: true
    },
    operationId: {
        type: 'number',
        optional: true
    },
    workspaceId: {
        type: 'number',
        optional: true
    },
    period: {
        type: 'string',
        optional: true
    },
    date: {
        type: 'string',
        optional: true
    },
    description: {
        type: 'string',
        nullable: true,
        optional: true
    },
    salaryListId: {
        type: 'number',
        nullable: true,
        optional: true
    },
    positions: {
        type: 'array',
        nullable: true,
    },
    isScheduled: {
        type: 'boolean',
        optional: true
    },
    isRepeatable: {
        type: 'boolean',
        optional: true
    },
    lastRepeatDate: {
        type: 'string',
        nullable: true,
        optional: true
    },
    periodicity: {
        type: 'string',
        nullable: true,
        optional: true
    },
};

const withdrawalSchema = {
    billId: {
        type: 'number',
        nullable: true,
        optional: true
    },
    type: {
        type: 'string',
        optional: true
    },
    contractorId: {
        type: 'number',
        optional: true
    },
    fromAccountId: {
        type: 'number',
        optional: true
    },
    amount: {
        type: 'number',
        nullable: true,
        optional: true
    },
    services: {
        type: 'array',
        nullable: true,
        optional: true
    },
    operationId: {
        type: 'number',
        optional: true
    },
    workspaceId: {
        type: 'number',
        optional: true
    },
    period: {
        type: 'string',
        optional: true
    },
    date: {
        type: 'string',
        optional: true
    },
    description: {
        type: 'string',
        nullable: true,
        optional: true
    },
    salaryListId: {
        type: 'number',
        nullable: true,
        optional: true
    },
    positions: {
        type: 'array',
        nullable: true,
    },
    isScheduled: {
        type: 'boolean',
        optional: true
    },
    isRepeatable: {
        type: 'boolean',
        optional: true
    },
    lastRepeatDate: {
        type: 'string',
        nullable: true,
        optional: true
    },
    periodicity: {
        type: 'string',
        nullable: true,
        optional: true
    },
};

const transferSchema = {
    type: {
        type: 'string',
        optional: true
    },
    fromAccountId: {
        type: 'number',
        optional: true
    },
    toAccountId: {
        type: 'number',
        optional: true
    },
    workspaceId: {
        type: 'number',
        optional: true
    },
    fromAmount: {
        type: 'number',
        optional: true
    },
    toAmount: {
        type: 'number',
        optional: true
    },
    period: {
        type: 'string',
        optional: true
    },
    date: {
        type: 'string',
        optional: true
    },
    description: {
        type: 'string',
        nullable: true,
        optional: true
    },
};
export const updateTransactionDto = (transaction, validateFunction) => {
    if (!transaction.type) {
        throw new ValidationError('type', 'required');
    }
    switch (transaction.type) {
        case 'incoming':
            const createIncomingDto = {
                billId: transaction.billId,
                type: transaction.type,
                contractorId: transaction.contractorId,
                toAccountId: transaction.toAccountId,
                amount: transaction.amount,
                operationId: transaction.operationId,
                period: transaction.period,
                date: transaction.date,
                description: transaction.description,
                services: transaction.services,
                salaryListId: transaction.salaryListId,
                positions: transaction.positions,
                workspaceId: Number(transaction.workspaceId),
                isScheduled: transaction.isScheduled,
                isRepeatable: transaction.isRepeatable,
                lastRepeatDate: transaction.lastRepeatDate,
                periodicity: transaction.periodicity,
            };
            for (const key in createIncomingDto) {
                const [ok, err] = validateFunction(key, incomingSchema[key], createIncomingDto[key]);
                if (!ok) {
                    throw new ValidationError(key, err);
                }
            }
            return createIncomingDto;
        case 'withdrawal':
            const createWithdrawalDto = {
                billId: transaction.billId,
                type: transaction.type,
                contractorId: transaction.contractorId,
                fromAccountId: transaction.fromAccountId,
                amount: transaction.amount,
                operationId: transaction.operationId,
                period: transaction.period,
                date: transaction.date,
                description: transaction.description,
                services: transaction.services,
                salaryListId: transaction.salaryListId,
                positions: transaction.positions,
                workspaceId: Number(transaction.workspaceId),
                isScheduled: transaction.isScheduled,
                isRepeatable: transaction.isRepeatable,
                lastRepeatDate: transaction.lastRepeatDate,
                periodicity: transaction.periodicity,
            };
            for (const key in createWithdrawalDto) {
                const [ok, err] = validateFunction(key, withdrawalSchema[key], createWithdrawalDto[key]);
                if (!ok) {
                    throw new ValidationError(key, err);
                }
            }
            return createWithdrawalDto;
        case 'transfer':
            const createTransferDto = {
                type: transaction.type,
                fromAccountId: transaction.fromAccountId,
                toAccountId: transaction.toAccountId,
                fromAmount: transaction.fromAmount,
                toAmount: transaction.toAmount,
                period: transaction.period,
                date: transaction.date,
                description: transaction.description,
                workspaceId: Number(transaction.workspaceId),
            };
            for (const key in createTransferDto) {
                const [ok, err] = validateFunction(key, transferSchema[key], createTransferDto[key]);
                if (!ok) {
                    throw new ValidationError(key, err);
                }
            }
            return createTransferDto;
    }
};