export const columns = [
    {
        title: 'Название',
        dataIndex: 'title',
        key: 'title',
        slots: {customRender: 'title'},
        width: '20%'
    },
    {
        title: 'Тип',
        slots: {customRender: 'type'},
        width: '20%'

    },
    {
        title: 'Часовая ставка',
        dataIndex: 'hourlyRate',
        key: 'hourlyRate',
        width: '15%'
    },
    {
        title: 'Страна, город',
        dataIndex: 'address',
        key: 'address',
        width: '15%'
    },
    {
        title: 'Контактное лицо',
        dataIndex: 'contactPerson',
        key: 'contactPerson',
        width: '15%'
    },
    {
        title: 'Лояльность',
        slots: {customRender: 'loyalty'},
        width: '15%'
    },
    {
        title: '',
        slots: {customRender: 'action'},
    },
];

export const contactsType = {
    individual: 'individual',
    entity: 'entity'
};
export const contactsTypeMap = {
    individual: 'Физлицо',
    entity: 'Юрлицо'
};

export const contactStatus = {
    active: 'active',
    archived: 'archived'
};

export const contactStatusMap = {
    active: 'Активные',
    archived: 'Архив',
};