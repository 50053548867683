import {httpInstance} from '@/modules/http_factory';
import moment from 'moment';
import {Document} from '@/modules/document/cast';
import {arrayToString} from '../utils';

const createDocument = async (workspaceId, document) => {
    const response = await httpInstance(workspaceId).post('/documents', {
        ...document,
        date: moment(document.date).format('YYYY-MM-DD'),
        deadline: moment(document.deadline).format('YYYY-MM-DD'),
    });
    return response.data;
};
const getDefaultNumber = async(workspaceId) => {
    const response = await httpInstance(workspaceId).get('/documents/default-number');
    return String(response.data);
};

const getDocumentById = async(workspaceId, id) => {
    const response = await httpInstance(workspaceId).get(`/documents/${id}`);
    const doc = new Document(response.data);
    return doc.toObject();
};

const updateDocument = async(workspaceId, id, updateData) => {
    const response = await httpInstance(workspaceId).patch(`/documents/${id}`, {
        ...updateData,
        date: updateData.date ? moment(updateData.date).format('YYYY-MM-DD') : undefined,
        deadline: updateData.deadline ? moment(updateData.deadline).format('YYYY-MM-DD') : undefined,
    });
    return response.data;
};
const deleteDocument = async(workspaceId, id) => {
    const response = await httpInstance(workspaceId).delete(`/documents/${id}`);
    return response.data;
};
const getTemplate = async(id) => {
    const response = await httpInstance().get(`/documents/${id}/template`, {
        responseType: 'arraybuffer'
    });
    return response.data;
};
const formFilter = (workspaceId, filter) => {
    return {
        workspaceId,
        search: filter.search,
        customerId: filter.customerId ? filter.customerId : filter.customer ? filter.customer.id : undefined,
        statuses: filter.statuses && Array.isArray(filter.statuses) && filter.statuses.length ? arrayToString(filter.statuses, true) : undefined,
        documentTypes: filter.documentTypes && Array.isArray(filter.documentTypes) && filter.documentTypes.length ? arrayToString(filter.documentTypes, true) : undefined,
        serviceId: filter.serviceId,
        workspaceMemberId: filter.workspaceMemberId,
        deadline: filter.deadline ? moment(filter.deadline).toISOString() : undefined,
        contactGroupId: filter.contactGroupId && filter.contactGroupId !== 'all' ? filter.contactGroupId : undefined
    };
};
const getDocuments = async (workspaceId, pagination, filter={}) => {
    const response = await httpInstance(workspaceId).get('/documents', {
        params: {
            ...pagination,
            ...formFilter(workspaceId, filter)
        }
    });
    return response.data;
};

const getTabs = async (workspaceId, filter={}) => {
    const response = await httpInstance(workspaceId).get('/documents/tabs', {
        params: {
            ...formFilter(workspaceId, filter)
        }
    });
    return response.data;
};


export default {
    getTabs,
    getDocuments,
    updateDocument,
    createDocument,
    deleteDocument,
    getDocumentById,
    getDefaultNumber,
    getTemplate,
};