export const columns = [
    {
        title: 'Дата',
        dataIndex: 'date',
        key: 'date',
        slots: {customRender: 'date'},
        width: '10%'
    },
    {
        title: 'Период начисления',
        dataIndex: 'period',
        key: 'period',
        slots: {customRender: 'period'},
        width: '10%'
    },
    {
        title: 'Стоимость',
        dataIndex: 'amount',
        key: 'amount',
        slots: {customRender: 'amount'},
        width: '30%'
    },
    {
        title: 'Операция',
        slots: {customRender: 'operation'},
        width: '10%'
    },
    {
        title: 'Контрагент',
        slots: {customRender: 'contractor'},
        width: '12%'
    },
    {
        title: 'Счет',
        slots: {customRender: 'bankAccount'},
        width: '10%'
    },
    {
        title: '',
        slots: {customRender: 'action'},
        width: '15%'
    },
];
export const serviceTransactionColumns = [
    {
        title: 'Дата',
        dataIndex: 'date',
        key: 'date',
        slots: {customRender: 'date'},
        width: '15%'
    },
    {
        title: 'Стоимость',
        dataIndex: 'amount',
        key: 'amount',
        slots: {customRender: 'amount'},
        width: '15%'
    },
    {
        title: 'Контрагент',
        slots: {customRender: 'contractor'},
        width: '15%'
    },
    {
        title: 'Описание',
        dataIndex: 'description',
        key: 'description',
        slots: {customRender: 'description'},
        width: '10%'
    },
    {
        title: 'Счет',
        slots: {customRender: 'bankAccount'},
        width: '10%'
    },
    {
        title: '',
        slots: {customRender: 'action'},
    },
];

export const transactionType = {
    withdrawal: 'withdrawal',
    incoming: 'incoming',
    transfer: 'transfer',
};

export const transactionTypeMap = {
    withdrawal: 'Списание',
    incoming: 'Пополнение',
    transfer: 'Перевод',
};

export const transactionSource = {
    with_bill: 'with_bill',
    with_salary_payments_list: 'with_salary_payments_list',
    other: 'other'
};

export const transactionSourceMap = {
    with_bill: 'Оплата по счету',
    with_salary_payments_list: 'Выплата по зарплатному листу',
    other: 'Другое'
};

export const transactionServiceSource = {
    with_service: 'with_service',
    without_service: 'without_service'
};

export const transactionServiceSourceMap = {
    with_service: 'Оплата по услуге',
    without_service: 'Не связывать с услугой'
};

export const transactionPeriodicity = {
    week: 'week',
    month: 'month',
    two_month: 'two_month',
    quarter: 'quarter',
    half_year: 'half_year',
};
export const transactionPeriodicityMap = {
    week: 'Каждую неделю',
    month: 'Каждый месяц',
    two_month: 'Каждые два месяца',
    quarter: 'Каждый квартал',
    half_year: 'Каждые полгода',
};