<template lang="pug">
.task-card-check-list(v-if="checklist && checklist.length")
    card-title Чеклист
    .task-card-check-list__element(
        v-for="element in checklist"
        :key="element.id"
        :class="{'task-card-check-list__element--completed': element.completed}"
    )
        .task-card-check-list__element-checkbox
            a-checkbox(:checked="element.completed" @change="completeElement(element.id, $event.target.checked)")
        .task-card-check-list__element-title
            a-input(
                v-model:value="element.title"
                @blur="updateElement(element.id, {title: $event.target.value})"
                style="width: 100%; border: none"
                placeholder="Наименование..."
            )
        .task-card-check-list__element-remove
            a-button(@click="removeElement(element.id)") Удалить
    a-button(type="primary" @click="createElement") Еще
a-button(type="primary" @click="createChecklist" v-else) Добавить чеклист
</template>

<script>
export default {
    name: 'TaskCardCheckList'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
import {ref} from 'vue';
import CardTitle from '@/components/Generic/Typography/CardTitle';
import {useKanban} from '@/composition/kanban';
import {useWorkspace} from '@/composition/workspace';
/*===============INIT AREA===================*/
const loading = ref(false);

const {
    createTaskChecklistElement,
    updateTaskChecklistElement,
    deleteTaskChecklistElement,
    getTask,
    task,
} = useKanban(loading);
const checklist = ref(task.value.checklist);
const {currentWorkspaceId} = useWorkspace();


/*===============CRUD METHODS AREA===================*/
const defaultChecklistElement = () => ({
    id: Symbol(Date.now()),
    title: '',
    completed: false,
    taskId: task.value.id,
});
const createChecklist = () => {
    checklist.value = [];
    createElement();
};
const createElement = async () => {
    await createTaskChecklistElement(currentWorkspaceId.value, defaultChecklistElement());
    await getTask(task.value.id, currentWorkspaceId.value);
    checklist.value = task.value.checklist;
};
const updateElement = async (id, updateData) => {
    await updateTaskChecklistElement(currentWorkspaceId.value, id, updateData);
    await getTask(task.value.id, currentWorkspaceId.value);
    checklist.value = task.value.checklist;
};
const removeElement = async (id) => {
    await deleteTaskChecklistElement(currentWorkspaceId.value, id);
    await getTask(task.value.id, currentWorkspaceId.value);
    checklist.value = task.value.checklist;
};
const completeElement = async (id, newValue) => {
    await updateElement(id, {completed: newValue});
};

</script>

<style lang="scss">
.task-card-check-list {
    width: 100%;
    &__element {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 16px;
        &--completed {
            input {
                color: #5e6c84;
                text-decoration: line-through;
            }
        }
        &-checkbox {
            margin-right: 10px;
        }
        &-title {
            width: 100%;
            margin-right: 10px;
        }
    }
}
</style>
