<template lang="pug">
.statuses-list
    card-title Статусы
    .kanban.statuses-list__wrapper(:style="getColumns()")
        .kanban__item.statuses-list__column(v-for="([key, data]) in statuses")
            .flex.items-center.kanban__title
                .mr-3 {{ statusMap[key] }}
            draggable(
                class="column"
                :list="data"
                group="kanban"
                item-key="id"
                ghost-class="ghost"
                @change="(e) => updateStatus(e, key)"
            )
                template(#item="{element}")
                    .status {{ element }}
</template>

<script>
import CardTitle from '@/components/Generic/Typography/CardTitle';
import {ref} from 'vue';
import {useIntegration} from '@/composition/integration';
import draggable from 'vuedraggable';
export default {
    name: 'IntegrationStatusesList',
    components: {CardTitle, draggable},
    props: {
        workspaceId: Number,
        integrationId: [Number, String],
        project: Object,
    },
    async setup(props) {
        const loading = ref(false);
        const {
            getStatuses,
            statuses,
            statusMap,
            syncStatus,
            getSteps,
        } = useIntegration(loading);
        await getStatuses(
            props.workspaceId,
            props.integrationId,
            props?.project?.integrationProject?.mappingId,
            props.project.id
        );
        const getColumns = () => {
            const columnsCount = Object.keys(statuses.value).length;
            return `grid-template-columns: repeat(${columnsCount}, 400px);`;
        };
        const updateStatus = async (e, key) => {
            if (e.added) {
                const payload = {
                    title: e.added.element,
                    statusId: key,
                    integrationId: props.integrationId,
                    workspaceId: props.workspaceId,
                    projectId: props.project.id
                };
                await syncStatus(payload);
                await getStatuses(
                    props.workspaceId,
                    props.integrationId,
                    props?.project?.integrationProject?.mappingId,
                    props.project.id
                );
                await getSteps(props.workspaceId, props.integrationId);
            }
        };
        return {
            loading,
            getColumns,
            statuses,
            statusMap,
            updateStatus,
        };
    }
};
</script>

<style lang="scss" scoped>
.integration-auth {
    &__form {
        width: 400px;
        margin: 20px 0;
    }
}
.statuses-list {
    &__column {
        height: 80vh;
    }
    &__wrapper {
        max-width: 100vw
    }
}
.status {
    padding: 4px 8px;
    border-radius: 100px;
    background: #ffffff;
    display: inline-block;
    margin-right: 10px;
    border: 1px solid #e6e6e6;
    cursor: grab;
}
.column {
    min-height: 80%;
}
</style>