<template lang="pug">
.transaction-services
    .transaction-service__item(
        v-for="transactionService in transactionServices.value"
    )
        transaction-service-item.mt-2(
            :item="transactionService"
            @change="(e) => onItemChanged(e, transactionService)"
            @delete="(e) => onItemDelete(e)"
            :disabled="disabled"
            :additionalServiceFilter="additionalServiceFilter"
            :currency="currency"
        )
    a-button.mt-2(@click="addItem" type="primary" v-if="!disabled") Добавить

</template>

<script>
import TransactionServiceItem from './Item';
export default {
    name: 'TransactionServicesList',
    components: {TransactionServiceItem},
    props: {
        transactionServices: Object,
        disabled: {
            type: Boolean
        },
        additionalServiceFilter: {
            type: Object,
        },
        currency: {
            type: String,
        }
    },
    emits: ['update:paymentDates', 'update:transactionServices'],
    setup(props, {emit}) {
        const paymentDateItem = () => ({
            id: Symbol(Date.now()),
            serviceId: undefined,
            paymentPeriod: undefined,
            amount: undefined,
        });
        const addItem = () => {
            emit('update:transactionServices', {
                error: undefined,
                value: [...props.transactionServices.value, paymentDateItem()]
            });
        };
        const onItemChanged = (newVal, oldVal) => {
            emit('update:transactionServices', {
                error: undefined,
                value: props.transactionServices.value.map(x => {
                    if (x.id === oldVal.id) {
                        return newVal;
                    }
                    return x;
                })
            });
        };
        const onItemDelete = (id) => {
            emit('update:transactionServices', {
                error: undefined,
                value: props.transactionServices.value.filter(x => x.id != id)
            });
        };
        return {
            onItemChanged,
            onItemDelete,
            addItem,
        };
    }
};
</script>

<style scoped>

</style>
