import {ref} from 'vue';
import {apiRequest} from '@/modules/error_handler';
import reportsModule from '@/modules/reports';
import moment from 'moment-timezone';
moment.locale('ru');
import constants from '@/constants';
import {useWorkspace} from '@/composition/workspace';
import {parseArrayFromString} from '@/modules/utils';

const pagination = ref({
    limit: 50,
    offset: 0,
});

const savedReports = ref({
    list: [],
    next: false,
    total: 0,
});

const timeFilter = {
    today: {
        key: 'today',
        title: 'Сегодня',
        from: moment().startOf('day').format('YYYY-MM-DD'),
        to: moment().endOf('day').format('YYYY-MM-DD'),
    },
    yesterday: {
        key: 'yesterday',
        title: 'Вчера',
        from: moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD'),
        to: moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD'),
    },
    currentWeek: {
        key: 'currentWeek',
        title: 'Текущая неделя',
        from: moment().startOf('week').startOf('day').format('YYYY-MM-DD'),
        to: moment().endOf('week').endOf('day').format('YYYY-MM-DD'),
    },
    currentMonth: {
        key: 'currentMonth',
        title: 'Текущий месяц',
        from: moment().startOf('month').startOf('day').format('YYYY-MM-DD'),
        to: moment().endOf('month').endOf('day').format('YYYY-MM-DD'),
    },
};
const chartData = ref({
    xaxis: [],
    series: []
});
const tables = ref([]);
const filter = ref({
    chartGroupBy: 'serviceId',
    performers: [],
    contacts: [],
    services: [],
    from: moment().startOf('month').startOf('day').format('YYYY-MM-DD'),
    to: moment().endOf('month').endOf('day').format('YYYY-MM-DD'),
});

const groups = ref({
    serviceId: {
        key: 'serviceId',
        title: 'по услуге'
    },
    workspaceMemberId: {
        key: 'workspaceMemberId',
        title: 'по пользователю'
    },
    taskId: {
        key: 'taskId',
        title: 'по задачам'
    },
    startDate: {
        key: 'startDate',
        title: 'по рабочим дням'
    },
});

const fastGroups = {
    serviceId: [groups.value.serviceId.key, groups.value.workspaceMemberId.key, groups.value.taskId.key],
    startDate: [groups.value.startDate.key, groups.value.workspaceMemberId.key, groups.value.serviceId.key, groups.value.taskId.key],
    workspaceMemberId: [groups.value.workspaceMemberId.key, groups.value.serviceId.key, groups.value.taskId.key],
    taskId: [groups.value.serviceId.key, groups.value.taskId.key, groups.value.workspaceMemberId.key],
};

const currentGroup = ref(fastGroups.serviceId);
const activeFastGroup = ref('serviceId');

export function useReport(loading={value: false}) {
    const reportTitle = ref('');
    const reportSubTitle = ref('');
    const savedReport = ref({});
    const getSavedReportsList = async (workspaceId, meta={}) => {
        return apiRequest(async() => {
            const result = await reportsModule.getSavedReportsList(workspaceId, {
                limit: pagination.value.limit,
                offset: pagination.value.offset,
            }, filter.value);
            if (meta.fromPagination) {
                savedReports.value.list = savedReports.value.list.concat(result.list);
                savedReports.value.next = result.next;
            } else {
                savedReports.value = result;
            }
            return savedReports.value;
        }, loading);
    };
    const getChartData = async (workspaceId, tz) => {
        return apiRequest(async() => {
            const response = await reportsModule.getChartData(workspaceId, filter.value, tz);
            chartData.value = response;
            return response;
        }, loading);
    };
    const saveReport = async (payload, tz) => {
        return apiRequest(async() => {
            const response = await reportsModule.saveReport(payload, filter.value, tz);
            return response;
        }, loading);
    };
    const deleteSavedReport = async (workspaceId, id) => {
        return apiRequest(async() => {
            const response = await reportsModule.deleteSavedReport(workspaceId, id);
            return response;
        }, loading);
    };
    const updateReport = async (payload, id, tz) => {
        return apiRequest(async() => {
            const response = await reportsModule.updateReport(payload, id, filter.value, tz);
            return response;
        }, loading);
    };
    const getSavedReportByUUID = async (workspaceId, uuid) => {
        return apiRequest(async() => {
            const response = await reportsModule.getSavedReportByUUID(workspaceId, uuid, currentGroup.value);
            chartData.value = response.chartData;
            tables.value = response.groupData;
            reportTitle.value = response.reportTitle;
            reportSubTitle.value = response.reportSubTitle;
            return response;
        }, loading);
    };
    const getSavedReportById = async (workspaceId, id) => {
        return apiRequest(async() => {
            const response = await reportsModule.getSavedReportById(workspaceId, id);
            savedReport.value = response;
            filter.value = response.filter;
            reportTitle.value = response.title;
            return response;
        }, loading);
    };
    const getTables = async (workspaceId, tz) => {
        return apiRequest(async() => {
            const response = await reportsModule.getTables(workspaceId, filter.value, currentGroup.value, tz);
            tables.value = response;
            return response;
        }, loading);
    };
    const setFilter = (data) => {
        filter.value = {
            chartGroupBy: data.chartGroupBy,
            performers: data.performers,
            contacts: data.contacts,
            services: data.services,
            from: data.from,
            to: data.to
        };
    };
    const setDateFilter = (key) => {
        setFilter({
            ...filter.value,
            from: timeFilter[key].from,
            to: timeFilter[key].to,
        });
    };
    const processRouteQueryParams = (route) => {
        const query = route.query || {};
        if (query.dayFilter) {
            setDateFilter(query.dayFilter);
        }
        if (query.performers) {
            const performers = parseArrayFromString(query.performers, true);
            setFilter({
                ...filter.value,
                performers
            });
        }
        if (query.services) {
            const services = parseArrayFromString(query.services, true);
            setFilter({
                ...filter.value,
                services
            });
        }
        if (query.contacts) {
            const contacts = parseArrayFromString(query.contacts, true);
            setFilter({
                ...filter.value,
                contacts
            });
        }
        if (query.from && query.to) {
            setFilter({
                ...filter.value,
                from: query.from,
                to: query.to,
            });
        }
    };
    return {
        processRouteQueryParams,
        setDateFilter,
        getChartData,
        getTables,
        filter,
        timeFilter,
        setFilter,
        saveReport,
        getSavedReportByUUID,
        getSavedReportById,
        activeFastGroup,
        chartData,
        savedReport,
        reportTitle,
        tables,
        groups,
        currentGroup,
        reportSubTitle,
        fastGroups,
        savedReports,
        pagination,
        getSavedReportsList,
        updateReport,
        deleteSavedReport
    };
}
