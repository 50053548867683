export enum CalendarSlotType {
    service = 'service',
    bill= 'bill',
    document= 'document',
    other= 'other',
}

export enum CalendarSlotTypeMap {
    service= 'Услуги',
    bill= 'Счета',
    document= 'Документы',
    other= 'Прочее'
}