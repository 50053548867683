import {notification} from 'ant-design-vue';
import {ValidationError} from '@/modules/cast/validation.error';
import {
    ApiRequestErrorInterface,
    ApiRequestResultInterface
} from '@/core/common/interface/api-request-result.interface';
import {AxiosError} from 'axios';
// const isDev = process.env.NODE_ENV === 'development'
export const apiRequest = async <T> (func: () => any, loader={value: false}): Promise<ApiRequestResultInterface<T>> => {
    loader.value = true;
    try {
        const result = await func();
        loader.value = false;
        return {ok: true, errorMessage: null, fieldErrors: [], result};
    } catch (e: any) {
        loader.value = false;
        const errorObj = handleError(e);
        // isDev ? console.error(e) : null
        console.error(e);
        if (errorObj.errorMessage) {
            notification.error({
                description: errorObj.errorMessage,
                message: 'Ошибка!'
            });
        }
        return {
            result: null,
            ...errorObj,
        };
    }
};

function handleError(e: AxiosError | ValidationError): ApiRequestErrorInterface {
    if (e instanceof ValidationError) {
        return {
            ok: false,
            errorMessage: null,
            fieldErrors: [
                {
                    property: e.property,
                    errors: [e.message]
                }
            ]
        };
    }
    const err = e?.response?.data?.message;
    if (err) {
        return Array.isArray(err) ? {ok: false, errorMessage: null, fieldErrors: err} : {ok: false, errorMessage: err, fieldErrors: []};
    }
    return {ok: false, errorMessage: e.message, fieldErrors: []};
}