<template lang="pug">
documents-table(:serviceId="route.params.serviceId")
</template>

<script>
import DocumentsTable from '@/components/Documents/Table';
import {useRoute} from 'vue-router';
export default {
    name: 'ServiceDocuments',
    components: {DocumentsTable},
    setup() {
        const route = useRoute();
        return {
            route
        };
    }
};
</script>

<style scoped>

</style>