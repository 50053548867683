<template lang="pug">
.page.select-workspace-page(v-if="init")
    typography-title.login-page__title Выберите рабочее пространство
    .select-workspace-card.mt-3(v-if="user && user.workspaces && user.workspaces.length")
        .select-workspace-card__item(
            v-for="workspaceMember in user.workspaces"
            :key="workspaceMember.id"
            @click="redirect(workspaceMember)"
        )
            workspace-title(:workspaceMember="workspaceMember" :user="user" theme="white")
    router-link.mt-3(to="/create-workspace") Создать свое рабочее пространство
</template>

<script>
import {useAuth} from '@/composition/auth';
import {useRouter} from 'vue-router';
import {onMounted, ref} from 'vue';
import WorkspaceTitle from '@/components/Generic/Typography/WorkspaceTitle';
export default {
    name: 'SelectWorkspace',
    components: {WorkspaceTitle},
    setup() {
        const router = useRouter();
        const {user} = useAuth();
        const init = ref(false);
        onMounted(() => {
            init.value = false;
            if (user.value.workspaces) {
                if (user.value.workspaces.length === 0) {
                    router.push('/create-workspace');
                }
                if (user.value.workspaces.length === 1) {
                    const workspaceId = user.value.workspaces[0]?.workspace?.id;
                    if (workspaceId) {
                        router.push(`/${workspaceId}/tasks`);
                    }
                }
            }
            init.value = true;
        });

        const redirect = (workspaceMember) => {
            const workspaceId = workspaceMember?.workspace?.id;
            if (workspaceId) {
                router.push(`/${workspaceId}/tasks`);
            }
        };
        return {
            user,
            redirect,
            init,
        };
    }
};
</script>

<style lang="scss" scoped>
.select-workspace {
    &-page {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-height: 100vh;
        height: 100%;
    }
    &-card {
        width: 420px;
        &__item {
            border: 1px solid #E4E6EA;
            padding: 12px 16px;
            border-radius: 8px;
            cursor: pointer;
            transition: all 0.3s;
            margin-top: 16px;
            &:hover {
                border: 1px solid $primaryColor;
            }
        }

    }
}
</style>
