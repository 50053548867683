<template lang="pug">
card-title Профессиональные данные
.invite-user-form.mt-2
    .flex
        input-with-error(:error="groupId.error")
            template(#input)
                a-select(
                    :value="groupId.value"
                    style="width: 100%"
                    placeholder="Группа"
                    show-search
                    :filter-option="selectFilterFunc"
                    @change="onChange('groupId', $event)"
                    allowClear
                )
                    a-select-option(
                        v-for="group in directories.groups.list"
                        :key="group.id"
                        :value="group.id"
                        :label="group.title"
                    )
                        | {{ group.title }}
                    a-select-option.cursor-pointer(disabled @click="() => $emit('open-modal', 'groups')") Добавить
                    template(#notFoundContent)
                        add-directory(:onClick="() => $emit('open-modal', 'groups')")
        input-with-error.ml-3(:error="hoursPayload.error")
            template(#input)
                a-input-number(placeholder="Предпочтительная нагрузка" v-model:value="hoursPayload.value" :min="0" style="width: 100%" @change="onChange('hoursPayload', $event)")
    input-with-error(:error="skills.error")
        template(#input)
            a-select(
                v-model:value="skills.value"
                style="width: 100%"
                placeholder="Навыки"
                show-search
                :filter-option="selectFilterFunc"
                mode="multiple"
                @change="onChange('skills', $event)"
                allowClear
            )
                a-select-option(
                    v-for="skill in directories.skills.list"
                    :key="skill.id"
                    :value="skill.id"
                    :label="skill.title"
                )
                    | {{ skill.title }}
                a-select-option.cursor-pointer(disabled @click="() => $emit('open-modal', 'skills')") Добавить
                template(#notFoundContent)
                    add-directory(:onClick="() => $emit('open-modal', 'skills')")
    input-with-error(:error="gradeId.error")
        template(#input)
            a-select(
                v-model:value="gradeId.value"
                style="width: 100%"
                placeholder="Грейд"
                show-search
                :filter-option="selectFilterFunc"
                @change="onChange('gradeId', $event)"
                allowClear
            )
                a-select-option(
                    v-for="grade in directories.grades.list"
                    :key="grade.id"
                    :value="grade.id"
                    :label="grade.title"
                )
                    | {{ grade.title }}
                a-select-option.cursor-pointer(disabled @click="() => $emit('open-modal', 'grades')") Добавить
                template(#notFoundContent)
                    add-directory(:onClick="() => $emit('open-modal', 'grades')")
</template>

<script>
import {selectFilterFunc} from '@/modules/utils';
import AddDirectory from '@/components/Generic/AddDirectory/AddDirectory';

export default {
    name: 'ProfessionalDataForm',
    components: {AddDirectory},
    props: {
        groupId: Object,
        gradeId: Object,
        skills: Object,
        hoursPayload: Object,
        directories: Object,
    },
    emits: [
        'update:groupId',
        'update:gradeId',
        'update:skills',
        'update:hoursPayload',
        'open-modal'
    ],
    setup(props, {emit}) {
        const onChange = (key, value) => {
            emit(`update:${key}`, {value, error: undefined});
        };
        return {
            onChange,
            selectFilterFunc
        };
    }
};
</script>

<style scoped>

</style>