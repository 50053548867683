<template lang="pug">
.add-service-form
    input-with-error(:error="form.title.error")
        template(#input)
            a-input(placeholder="Наименование" v-model:value="form.title.value")
    a-button.mt-5.mb-2(
        type="primary"
        block
        @click="createWrapper"
        :loading="loading"
        :disabled="disabled"
    ) Создать
</template>

<script>
import {computed, reactive, ref} from 'vue';
import {useFormErrors} from '@/composition/errors';
import {notification} from 'ant-design-vue';
import {selectFilterFunc} from '@/modules/utils';
import {useCrud} from '@/composition/crud';
export default {
    name: 'CreateUnitForm',
    props: {
        workspaceId: Number,
    },
    emits: ['ok'],
    setup(props, {emit}) {
        const loading = ref(false);

        const form = reactive({
            title: {
                value: '',
                error: ''
            },
        });
        const clients = [];
        const {createEntity} = useCrud('units', loading);
        const clearErrors = () => {
            form.title.error = '';
        };
        const {init} = useFormErrors();

        const createWrapper = async () => {
            const {ok} = await init(form, async () => {
                return createEntity({
                    title: form.title.value,
                }, props.workspaceId);
            }, clearErrors);
            if (ok) {
                notification.success({
                    description: 'Единица измерения успешно создан',
                    message: 'Успех!'
                });
                emit('ok');
            }
        };

        const disabled = computed(() => {
            return !form.title.value;
        });
        return {
            createWrapper,
            disabled,
            form,
            loading,
            selectFilterFunc,
            clients
        };
    }
};
</script>

<style lang="scss" scoped>

</style>