import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74282554"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "regular-transactions-summary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["CardTitle"], null, {
      default: _withCtx(() => [
        _createTextVNode("Общая стоимость: - " + _toDisplayString($setup.formatCurrency($props.summary)) + " руб.", 1)
      ]),
      _: 1
    })
  ]))
}