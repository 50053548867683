<template lang="pug">
.status-item-wrapper(v-click-away="() => setEditing(false)")
    .status-item.mt-2(@click="setEditing(true)" v-show="!editMode") {{ status.title }}
    a-input.status-item.mt-2(
        v-show="editMode"
        v-model:value="status.title"
        ref="editInput"
        @blur="(e) => updateTaskStatus({title: e.target.value}, status.id)"
    )
    .status-item__delete.mt-2(@click="deleteTaskStatus" v-if="!status.isArchive")
        icon.ml-3(type="Delete")
    a-checkbox.ml-3(
        v-show="editMode"
        v-model:checked="status.isCurrentlyActual"
        @change="(e) => updateTaskStatus({isCurrentlyActual: e.target.checked}, status.id)"
    ) Учитывать в подсчете актуальных задач
</template>

<script>
import {nextTick, ref} from 'vue';
export default {
    name: 'StatusItem',
    props: {
        status
    },
    emits: ['update', 'delete'],
    setup(props, {emit}) {
        const editMode = ref(false);
        const editInput = ref(null);
        const setEditing = (bool) => {
            editMode.value = bool;
            nextTick(() => {
                editInput.value.focus();
            });
        };
        const updateTaskStatus = (updateData, id) => {
            emit('update', {...updateData, id});
        };
        const deleteTaskStatus = () => {
            emit('delete', props.status.id);
        };
        return {
            editMode,
            setEditing,
            editInput,
            updateTaskStatus,
            deleteTaskStatus,
        };
    }
};
</script>

<style lang="scss" scoped>
.status-item {
    padding: 0 8px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #e6e6e6;
    cursor: pointer;
    display: flex;
    align-items: center;
    width:  220px;
    min-height: 20px;
    user-select: none;
    font-size: 16px;
    font-weight: 500;
    color: #1f2533;
    height: 42px;
    &__delete {
        cursor: pointer;
    }
    &-wrapper {
        display: flex;
        align-items: center;
    }
}
</style>