<template lang="pug">
.service-finish(v-if="!service.isFinished")
    card-title Выручка
    .flex.justify-between.mt-2
        .service-finish__finance-block
            card-title(size="small") Фактическая
            .flex.items-center
                .service-finish__num.mr-2 {{ formatCurrency(finishData.totalCost) }} {{constants.currencyMap[finishData.currency]}}.
                div(@click="openEditFieldModal({value: finishData.totalCost, key: 'totalCost'})")
                    icon(type="EditField")
    card-title.mt-3 Расходы
    .service-finish__expenses
        .service-finish__expenses-header
            .service-finish__expenses-title
            .service-finish__expenses-reality Фактические
        .service-finish__expenses-body
            div(v-for="performer in finishData.performers")
                .service-finish__expenses-header
                    .service-finish__expenses-title {{ performer?.workspaceMember?.fullName }}
                    .service-finish__expenses-reality.flex.items-center
                        div {{formatCurrency(performer.totalCost)}} {{ constants.currencyMap[performer.currency] }}.
                        div(@click="openEditFieldModal({id: performer.id, value: performer.totalCost, key: 'performers'})")
                            icon(type="EditField")
    .service-finish__action.mt-3.flex.justify-end
        a-button(type="primary" @click="finishServiceWrapper" :loading="loading") Подтвердить
.service-return(v-else)
    service-change-status(
        :directories="directories"
        :serviceId="service.id"
        :workspaceId="service.workspaceId"
        @ok="onStatusChanged"
    )
a-modal(
    v-model:visible="showEditFieldModal"
    title="Изменить"
    :footer="null"
    @cancel="closeEditFieldModal"
    :destroyOnClose="true"
)
    .flex.flex-col
        a-input-number(v-model:value="editingValue.value" placeholder="Введите число" style="width: 100%")
        a-button.mt-3(type="primary" @click="saveEditingData") Сохранить
</template>

<script>
import CardTitle from '@/components/Generic/Typography/CardTitle';
import {formatCurrency} from '@/modules/utils';
import {ref} from 'vue';
import {useService} from '@/composition/service';
import ServiceChangeStatus from '@/components/Services/ChangeStatus';
import constants from '@/constants';
export default {
    name: 'ServiceFinish',
    components: {ServiceChangeStatus, CardTitle},
    props: {
        service: Object,
        directories: Object
    },
    emits: ['ok'],
    setup(props, {emit}) {
        const loading = ref(false);
        const {finishService} = useService(loading);
        const showEditFieldModal = ref(false);
        const editingValue = ref({
            id: undefined,
            key: undefined,
            value: undefined
        });
        const openEditFieldModal = (initialValue) => {
            showEditFieldModal.value = true;
            editingValue.value = initialValue;
        };
        const closeEditFieldModal = () => {
            showEditFieldModal.value = false;
            editingValue.value = {
                id: undefined,
                key: undefined,
                value: undefined,
            };
        };
        const saveEditingData = () => {
            if (!editingValue.value.id) {
                onUpdate(editingValue.value.key, editingValue.value.value);
            } else {
                onUpdate(editingValue.value.key, finishData.value.performers.map(x => {
                    if (x.id === editingValue.value.id) {
                        x.finishCost = editingValue.value.value;
                    }
                    return x;
                }));
            }
            closeEditFieldModal();

        };
        const finishData = ref({
            id: props.service.id,
            totalCost: props.service.totalCost || 0,
            performers: props.service.performers,
            currency: props.service?.bankAccount?.currency
        });
        const onUpdate = (key, value) => {
            finishData.value[key] = value;
        };
        const finishServiceWrapper = async () => {
            await finishService(finishData.value, props.service.workspaceId);
            emit('ok');
        };
        const onStatusChanged = () => emit('ok');
        return {
            constants,
            loading,
            finishData,
            editingValue,
            showEditFieldModal,
            onUpdate,
            finishService,
            formatCurrency,
            onStatusChanged,
            saveEditingData,
            openEditFieldModal,
            closeEditFieldModal,
            finishServiceWrapper,
        };
    }
};
</script>

<style lang="scss" scoped>
.service-finish {
    &__num {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: rgba(0, 0, 0, 0.85);
    }
    &__expenses {
        &-header {
            display: flex;
            align-items: center;
        }
        &-expectation {
            width: 200px;
        }
        &-title {
            width: 200px;
        }
        &-reality {
            width: 200px;
            margin-left: 40px;
        }
    }
}
</style>
