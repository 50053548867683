export const columns = [
    {
        title: 'Наименование',
        dataIndex: 'title',
        key: 'title',
        slots: {customRender: 'title'},
        width: '20%'
    },
    {
        title: 'Валюта',
        dataIndex: 'currency',
        key: 'currency',
        slots: {customRender: 'currency'},
        width: '10%'
    },
    {
        title: 'Комиссия',
        dataIndex: 'tax',
        key: 'tax',
        slots: {customRender: 'tax'},
        width: '20%'
    },
    {
        title: 'Компания',
        dataIndex: 'companyId',
        key: 'companyId',
        slots: {customRender: 'companyId'},
        width: '30%'
    },
    {
        title: 'Реквизиты',
        dataIndex: 'paymentDetails',
        key: 'paymentDetails',
        width: '30%'
    },
    {
        title: '',
        slots: {customRender: 'action'},
    },
];

export const defaultContactGroup = {
    lead: 'lead',
    client: 'client'
};