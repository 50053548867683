<template lang="pug">
.currency-select
    a-select(allowClear placeholder="Валюта" :value="localVal" @change="onChange" style="width: 100%" :disabled="disabled")
        a-select-option(
            v-for="currency in constants.currency"
            :key="currency"
        )
            | {{ constants.currencyMap[currency] }}
</template>

<script>
import {ref, watch} from 'vue';
import constants from '@/constants';
export default {
    name: 'CurrencySelect',
    props: {
        modelValue: String,
        disabled: Boolean,
    },
    emits: ['update:modelValue', 'change'],
    setup(props, {emit}) {
        const localVal = ref(props.modelValue);
        watch(() => props.modelValue, () => {
            localVal.value = props.modelValue;
        });
        const onChange = (e) => {
            emit('change', e);
            emit('update:modelValue', e);
        };
        return {
            localVal,
            constants,
            onChange
        };
    }
};
</script>

<style scoped>

</style>