import {isNullOrUndefined, isUndefined, typeOfB} from '@/modules/utils';

const strict = process.env.VUE_APP_STRICT;

export class Cast {
    static validate(entity, key, schemaElement, element) {
        if (!schemaElement) return [false, 'required'];
        if (!schemaElement.nullable && typeOfB(element) !== schemaElement.type) {
            const warning = `${entity} has invalid value ${key}: ${JSON.stringify(element)} should be ${schemaElement.type}`;
            if (strict) {
                throw new Error(warning);
            } else {
                console.warn(warning);
            }
            return [false, warning];
        }
        if (!schemaElement.nullable && isNullOrUndefined(element)) {
            const warning = `${entity} has null or undefined value, but it's not nullable ${key}: ${element}`;
            if (strict) {
                throw new Error(warning);
            } else {
                console.warn(warning);
            }
            return [false, warning];
        }
        if (!isNullOrUndefined(schemaElement.optional) && !schemaElement.optional && isUndefined(element)) {
            const warning = `${entity} has undefined value, but it's not optional ${key}: ${element}`;
            if (strict) {
                throw new Error(warning);
            } else {
                console.warn(warning);
            }
            return [false, warning];
        }
        return [true, null];
    }
    toObject(schema) {
        return Object.keys(schema).reduce((aggregator, curr) => {
            aggregator[curr] = this[curr];
            return aggregator;
        }, {});
    }
    static is(doc, schema) {
        return Object.keys(schema).every(item => doc.hasOwnProperty(item) && Document.validate(item, schema[item], doc[item])[0]);
    }
}