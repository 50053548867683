<template lang="pug">
time-logs-table(:serviceId="route.params.serviceId")
</template>

<script>
import TimeLogsTable from '@/components/TimeLogs/Table/index';
import {useRoute} from 'vue-router';
export default {
    name: 'ServiceTimeLogs',
    components: {TimeLogsTable},
    setup() {
        const route = useRoute();
        return {
            route
        };
    }
};
</script>

<style scoped>

</style>
