<template lang="pug">
.incomings-and-outgoings-page
    incomings-and-outgoings-filter(:filter="filter" @update:filter="onFilterChanged")
    custom-table(
        :columns="columns"
        :data-source="stats"
    )
        template(#name="{record}")
            incomings-and-outgoings-sub-categories-list(
                :subCategories="record.subCategories"
                :caption="record.name"
            )
        template(
            v-for="column in periodColumns"
            v-slot:[column.slots.customRender]="{record}"
        )
            incomings-and-outgoings-values-list(
                :subCategories="record[column.slots.customRender].items"
                :total="record[column.slots.customRender].total"
                :unit="record[column.slots.customRender].unit"
                :month="filter.groupBy?.value === 'month' ? column.slots.customRender : null"
                :quarter="filter.groupBy?.value === 'quarter' ? column.slots.customRender : null"
                :year="filter.year"
                :workspace-id="currentWorkspaceId"
                :type="record.type"
            )
        template(#sum="{record}")
            incomings-and-outgoings-values-list(
                :subCategories="record.sum.items"
                :total="record.sum.total"
                :unit="record.sum.unit"
                :year="filter.year"
                :workspace-id="currentWorkspaceId"
                :month="null"
                :quarter="null"
                :type="record.type"
            )
    incomings-and-outgoings-chart(:chart-data="chartData" :loading="listLoading")
</template>

<script>
import CustomTable from '@/components/Generic/CustomTable/index';
import IncomingsAndOutgoingsSubCategoriesList
    from '@/components/Analytics/IncomingsAndOutgoings/SubCategoriesList/index';
import IncomingsAndOutgoingsValuesList from '@/components/Analytics/IncomingsAndOutgoings/ValuesList/index';
import IncomingsAndOutgoingsFilter from '@/components/Analytics/IncomingsAndOutgoings/Filter/index';
export default {
    name: 'IncomingsAndOutgoingsPage',
    components: {
        IncomingsAndOutgoingsFilter,
        IncomingsAndOutgoingsValuesList, IncomingsAndOutgoingsSubCategoriesList, CustomTable},
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
import {onMounted, reactive, ref, watch} from 'vue';
import {useWorkspace} from '@/composition/workspace';
import {useRoute, useRouter} from 'vue-router';
import {useDate} from '@/composition/date';
import {useFinance} from '@/composition/finance';
import {getColumns} from '@/components/Analytics/IncomingsAndOutgoings/constants';
import IncomingsAndOutgoingsChart from '@/components/Analytics/IncomingsAndOutgoings/Chart';
/*===============INIT AREA===================*/

const statsLoading = ref(false);
const listLoading = ref(false);
const router = useRouter();
const route = useRoute();
const {getMonthFromNow, formatMonth, getCurrentYear} = useDate();
const {getStats, stats, setFilter, filter, chartData} = useFinance();
const {currentWorkspaceId} = useWorkspace();
const columns = ref([]);
const periodColumns = ref([]);

const processGroup = (groupBy) => {
    columns.value = getColumns(groupBy);
    periodColumns.value = columns.value.filter(x => x.isPeriod);
};

/*===============FILTERS AREA===================*/
const onFilterChanged = (filter) => {
    setFilter(filter);
    processGroup(filter.groupBy.value);
    getStats();
};
/*===============FETCH DATA===================*/
onMounted(async () => {
    setFilter({
        ...filter,
        year: getCurrentYear()
    });
    processGroup(filter.groupBy.value);
    await getStats();
});
</script>

<style lang="scss">
.incomings-and-outgoings-page {
    &__widgets {
        margin-top: 24px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 16px;
    }
}
</style>
