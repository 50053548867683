<template lang="pug">
.filter
    performers-tasks-list.mr-3(:filter="filter" @update="onChange('workspaceMembers', $event)" v-if="!hide_workspace_members")
    custom-select-v2.mr-3(
        :modelValue="filter.task"
        placeholder="Задача"
        :style="{width: '100%'}"
        :options="tasksList.list"
        searchable
        @search="onTasksSearch"
        @update:modelValue="onChange('task', $event)"
        label="fullTitle"
    )
    custom-select-v2.mr-3(
        :modelValue="filter.services"
        placeholder="Услуга"
        :style="{width: '100%'}"
        :options="services.list"
        searchable
        @search="onServicesSearch"
        @update:modelValue="onChange('services', $event)"
        multiple
    )
    a-date-picker(placeholder="Дедлайн до" v-model:value="filter.deadline" @update:value="onChange('deadline', $event)" style="width: 100%" format="DD.MM.YYYY")
</template>

<script>
import WorkspaceMembersSelect from '@/components/Workspace/Member/Select';
import TasksSelect from '@/components/Tasks/Select';
import SelectSuspense from '@/components/Generic/SelectSuspense';
import ServicesSelect from '@/components/Services/Select';
import PerformersTasksList from '@/components/Tasks/PerformersTasksList';
import {useAuth} from '@/composition/auth';
import {useRole} from '@/composition/role';
import {useKanban} from '@/composition/kanban';
import {debounce} from '@/modules/utils';
import {onMounted, ref} from 'vue';
import {useWorkspace} from '@/composition/workspace';
import CustomSelectV2 from '@/components/Generic/CustomSelectV2';
import {useServiceV2} from '@/composition/service-v2';
export default {
    name: 'TimeLogFilter',
    components: {
        CustomSelectV2,
        PerformersTasksList, ServicesSelect, SelectSuspense, TasksSelect, WorkspaceMembersSelect},
    props: {
        filter: Object,
    },
    emits: ['update:filter', 'ok'],
    setup(props, {emit}) {
        const {user} = useAuth();
        const {sections, getDefaults} = useRole(user.value);
        const {hide_workspace_members} = getDefaults(sections.tasks.name, sections.tasks.filters.name);
        const onChange = (key, value) => {
            emit('update:filter', {
                ...props.filter,
                [key]: value
            });
        };
        /*=====================TASKS AREA=======================*/
        const {currentWorkspaceId} = useWorkspace();
        const {getTasksList, tasksList} = useKanban();
        const onTasksSearch = (q) => {
            debounce(() => getTasksList(currentWorkspaceId.value, {search: q}), 500)();
        };
        onMounted(async () => {
            await getTasksList(currentWorkspaceId.value, {});
        });
        /*==================SERVICES AREA========================*/
        const {getServicesSimple, services, setFilter: setServicesFilter} = useServiceV2();
        const onServicesSearch = (q) => {
            setServicesFilter({search: q});
            debounce(async () => {
                await getServicesSimple(currentWorkspaceId.value, {});
            }, 800)();
        };
        onMounted(async () => {
            await getServicesSimple(currentWorkspaceId.value, {});
        });
        const test = ref(null);
        return {
            test,
            services,
            onServicesSearch,
            tasksList,
            onTasksSearch,
            onChange,
            hide_workspace_members,
        };
    }
};
</script>

<style lang="scss" scoped>
.filter {
    width: 100%;
    display: flex;
    align-items: center;
}
</style>