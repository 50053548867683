import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "autocomplete" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_auto_complete = _resolveComponent("a-auto-complete")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_auto_complete, {
      value: $props.value.label,
      onChange: $setup.onChange,
      onSelect: $setup.onSelect,
      filterOption: false,
      onSearch: $setup.onSearch,
      placeholder: $props.placeholder,
      style: {"width":"100%"},
      "onUpdate:value": $setup.onSelect
    }, {
      dataSource: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (option) => {
          return (_openBlock(), _createBlock(_component_a_select_option, {
            key: option.id,
            value: option.id,
            label: option.title
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(option.title), 1)
            ]),
            _: 2
          }, 1032, ["value", "label"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value", "placeholder"])
  ]))
}