<template lang="pug">
.contacts-table
    a-button.mb-2(type="primary" @click="setModalVisibility(true)") Добавить
    contacts-filter(@filter="onFilterChanged" :tabs="tabs")
    custom-table(:columns="columns" :data-source="contacts.list" :onRowClick="redirectToCard")
        template(#title="{text, record}")
            router-link.contacts-table__title(v-if="record" :to="`/${currentWorkspaceId}/contacts/${record.id}/common`" @click.stop)
                div {{record.title}}
        template(#contactGroup="{text, record}")
            div(v-if="record") {{ record.contactGroup ? record.contactGroup.title : 'Не указана' }}
        template(#leadSource="{text, record}")
            div(v-if="record") {{ record.leadSource ? record.leadSource.title : 'Не указана' }}
        template(#loyalty="{text, record}")
            div(v-if="record") {{ record.loyalty ? record.loyalty.title : 'Не указана' }}
        template(#sales="{text, record}")
            div(v-if="record") {{ record.sales ? record.sales.fullName : 'Не указан' }}
        template(#type="{text, record}")
            div(v-if="record") {{ contactsTypeMap[record.type] }}
        template(#currency="{text, record}")
            div(v-if="record") {{ currencyMap[record.currency] }}
        template(#action="{record}")
            a-dropdown(:trigger="['click']" @click.stop)
                .dots-wrapper
                    icon.cursor-pointer(type="Dots")
                template(#overlay)
                    a-menu
                        a-menu-item(key="1")
                            delete-with-confirm(:deleteFunc="() => onDelete(record.id)" btnText="Удалить")
                        a-menu-item(key="2")
                            a-popconfirm(
                                placement="left"
                                v-if="!record.isArchive"
                                title="Вы уверены, что хотите архивировать контакт?"
                                ok-text="Да"
                                cancel-text="Нет"
                                @confirm="onUpdate(record.id, 'isArchive', true)"
                            )
                                div В архив
                            a-popconfirm(
                                placement="left"
                                v-else
                                title="Вы уверены, что хотите вернуть контакт из архива?"
                                ok-text="Да"
                                cancel-text="Нет"
                                @confirm="onUpdate(record.id, 'isArchive', false)"
                            )
                                div В актуальное
                        a-menu-item(key="3")
                            div(@click="edit(record)") Редактировать
    a-button.mt-3(@click="next" v-if="contacts.next" :loading="loading") Показать еще
    a-modal(
        v-model:visible="showModal"
        title="Добавить контакт"
        :footer="null"
        @cancel="setModalVisibility(false)"
        :destroyOnClose="true"
        style="top: 0"
        width="40%"
    )
        create-contact-form(
            :workspaceId="currentWorkspaceId"
            @ok="onCreate"
            @update="putUpdate"
            :mode="currentMode"
            :default-value="editingElement"
        )
</template>

<script>
import {columns, contactsTypeMap} from '../constants';
import {ref} from 'vue';
import constants from '@/constants';
import {useWorkspace} from '@/composition/workspace';
import EditableCell from '@/components/Generic/EditableCell';
import Icon from '@/components/Generic/Typography/Icon';
import MembersFilter from '@/components/Workspace/Member/Filter';
import InviteUserForm from '@/components/Workspace/InviteUserForm';
import {useContact} from '@/composition/contact';
import CreateContactForm from '@/components/Contacts/CreateForm';
import ContactsFilter from '@/components/Contacts/Filter';
import CustomTable from '@/components/Generic/CustomTable';
import {useCreateOrUpdateModal} from '@/composition/create-update-modal';
import {useRouter} from 'vue-router';
import {useDirectory} from '@/composition/directory';
export default {
    name: 'ContactsTable',
    components: {CustomTable, ContactsFilter, CreateContactForm, InviteUserForm, MembersFilter, Icon, EditableCell},
    async setup() {
        const loading = ref(false);
        const {
            currentMode,
            editingValue: editingElement,
            showModal,
            setModalVisibility,
            edit
        } = useCreateOrUpdateModal();
        const router = useRouter();
        const {currentWorkspaceId, getMembers} = useWorkspace(loading);
        const {getDirectories} = useDirectory(loading);
        const {getContacts, deleteContact, updateContact, pagination, setFilter, contacts, tabs, getTabs} = useContact(loading, true, true);
        await getMembers(currentWorkspaceId.value);
        await getDirectories(currentWorkspaceId.value, 'contacts');
        const onDelete = async (id) => {
            pagination.value.offset = 0;
            await deleteContact(currentWorkspaceId.value, id);
            await getContacts(currentWorkspaceId.value);
            await getTabs(currentWorkspaceId.value);
        };
        const onUpdate = async (id, key, value) => {
            const {ok} = await updateContact(currentWorkspaceId.value, id, {[key]: value});
            if (ok) {
                pagination.value.offset = 0;
                await getContacts(currentWorkspaceId.value);
                await getTabs(currentWorkspaceId.value);
            }
        };
        const putUpdate = async (data) => {
            const {id, ...updateData} = data;
            await updateContact(currentWorkspaceId.value, id, updateData);
            setModalVisibility(false);
            await getContacts(currentWorkspaceId.value);
            await getTabs(currentWorkspaceId.value);
        };
        const next = async () => {
            pagination.value.offset += pagination.value.limit;
            await getContacts(currentWorkspaceId.value, {fromPagination: true});
            await getTabs(currentWorkspaceId.value);
        };
        const getSkillsText = (record) => {
            return record.skills.map(x => x.skill.title).join(', ');
        };
        const onFilterChanged = async(filter) => {
            pagination.value.offset = 0;
            setFilter(filter);
            await getContacts(currentWorkspaceId.value, {fromPagination: false});
            await getTabs(currentWorkspaceId.value);
        };
        const onCreate = async() => {
            pagination.value.offset = 0;
            await getContacts(currentWorkspaceId.value, {fromPagination: false});
            await getTabs(currentWorkspaceId.value);
            setModalVisibility(false);
        };
        const redirectToCard = (item) => {
            router.push(`/${currentWorkspaceId.value}/contacts/${item.id}/common`);
        };
        return {
            tabs,
            loading,
            columns,
            contacts,
            showModal,
            currentMode,
            editingElement,
            contactsTypeMap,
            currentWorkspaceId,
            currencyMap: constants.currencyMap,
            next,
            edit,
            onCreate,
            onDelete,
            onUpdate,
            putUpdate,
            getSkillsText,
            redirectToCard,
            onFilterChanged,
            setModalVisibility,
        };
    }
};
</script>

<style lang="scss" scoped>

</style>