<template lang="pug">
settings-layout
    async-page
        card-title Статусы задач
        tasks-status-manager.mt-2
</template>

<script>
import SettingsLayout from '@/pages/Settings/Layout';
import TasksStatusManager from '@/components/Settings/Tasks/StatusManager';
import AsyncPage from '@/layouts/async-page';
import CardTitle from '@/components/Generic/Typography/CardTitle';
export default {
    name: 'TaskSettings',
    components: {CardTitle, AsyncPage, TasksStatusManager, SettingsLayout}
};
</script>

<style scoped>

</style>