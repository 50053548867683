<template lang="pug">
div
    account-form(v-model:fullName="form.fullName" v-model:email="form.email")
    a-divider
    password-form
    a-divider
    role-form(v-model:roleId="form.roleId" :directories="directories" @openModal="(type) => setModalVisibility(type, true)")
    a-divider
    profile-form(
        v-model:telegram="form.telegram"
        v-model:skype="form.skype"
        v-model:phone="form.phone"
        v-model:avatar="form.avatar"
    )
    a-divider
    professional-data-form(
        v-model:gradeId="form.gradeId"
        v-model:groupId="form.groupId"
        v-model:skills="form.skills"
        v-model:hoursPayload="form.hoursPayload"
        :directories="directories"
        @openModal="(type) => setModalVisibility(type, true)"
    )
    a-divider
    finance-form(v-model:hourlyRate="form.hourlyRate" v-model:currency="form.currency")
    a-divider
    payment-dates-form(v-model:paymentDates="form.paymentDates")
    a-divider
    payment-methods-form(v-model:paymentMethods="form.paymentMethods" :directories="directories" @openModal="(type) => setModalVisibility(type, true)")
    a-divider
    others-form(
        v-model:hrSourceId="form.hrSourceId"
        v-model:mark="form.mark"
        v-model:specialMarkId="form.specialMarkId"
        :directories="directories"
        @openModal="(type) => setModalVisibility(type, true)"
    )
    a-button.mt-5.mb-2(
        type="primary"
        @click="inviteUserWrapper"
        :loading="loading"
        :disabled="disabled"
    ) {{ mode === 'edit' ? 'Сохранить' : 'Создать аккаунт и выслать приглашение' }}
    a-modal(
        v-model:visible="additionalModals[currentModal].showModal"
        :title="additionalModals?.[currentModal]?.title"
        :footer="null"
        @cancel="setModalVisibility(currentModal, false)"
        :destroyOnClose="true"
    )
        component(
            v-if="currentModal"
            :is="additionalModals[currentModal].component"
            @ok="onDirectoryAdded"
            :directories="directories"
            :workspaceId="workspaceId"
        )
</template>

<script>
import {computed, onMounted, reactive, ref} from 'vue';
import {useFormErrors} from '@/composition/errors';
import {useWorkspace} from '@/composition/workspace';
import {notification} from 'ant-design-vue';
import {useDirectory} from '@/composition/directory';
import {selectFilterFunc} from '@/modules/utils';
import CardTitle from '@/components/Generic/Typography/CardTitle';
import CurrencySelect from '@/components/Generic/CurrencySelect';
import constants from '@/components/Workspace/InviteUserForm/constants';
import AccountForm from '@/components/Workspace/InviteUserForm/AccountForm';
import PasswordForm from '@/components/Workspace/InviteUserForm/PasswordForm';
import RoleForm from '@/components/Workspace/InviteUserForm/RoleForm';
import ProfileForm from '@/components/Workspace/InviteUserForm/ProfileForm';
import ProfessionalDataForm from '@/components/Workspace/InviteUserForm/ProfessinalDataForm';
import FinanceForm from '@/components/Workspace/InviteUserForm/FinanceForm';
import PaymentDatesForm from '@/components/Workspace/InviteUserForm/PaymentDatesForm';
import OthersForm from '@/components/Workspace/InviteUserForm/OthersForm';
import WorkBasisForm from '@/components/Workspace/InviteUserForm/MemberDocumentsForm';
import PaymentMethodsForm from '@/components/Workspace/InviteUserForm/PaymentMethodsForm';
import MemberDocumentsForm from '@/components/Workspace/InviteUserForm/MemberDocumentsForm';
import CreateRoleForm from '@/components/Directories/Members/Roles/CreateForm';
import CreateGroupForm from '@/components/Directories/Members/Groups/CreateForm';
import CreateGradeForm from '@/components/Directories/Members/Grade/CreateForm';
import CreateSkillForm from '@/components/Directories/Members/Skills/CreateForm';
import CreatePaymentMethodForm from '@/components/Directories/Members/PaymentMethods/CreateForm';
import CreateSpecialMarkForm from '@/components/Directories/Members/SpecialMarks/CreateForm';
import CreateHrSourceForm from '@/components/Directories/Members/HrSources/CreateForm';
import InputWithError from '@/components/Generic/InputWithError';
export default {
    name: 'InviteUserForm',
    components: {
        InputWithError,
        CreateHrSourceForm,
        CreateSpecialMarkForm,
        CreatePaymentMethodForm,
        CreateSkillForm,
        CreateGradeForm,
        CreateGroupForm,
        CreateRoleForm,
        MemberDocumentsForm,
        PaymentMethodsForm,
        WorkBasisForm,
        OthersForm,
        PaymentDatesForm,
        FinanceForm,
        ProfessionalDataForm,
        ProfileForm,
        RoleForm,
        PasswordForm,
        AccountForm,
        CurrencySelect,
        CardTitle
    },
    props: {
        workspaceId: Number,
        mode: String,
        defaultValue: Object,
    },
    emits: ['ok', 'update'],
    setup(props, {emit}) {
        const loading = ref(false);
        const currentModal = ref('roles');
        const additionalModals = reactive({
            roles: {
                showModal: false,
                component: 'create-role-form',
                title: 'Добавить роль'
            },
            groups: {
                showModal: false,
                component: 'create-group-form',
                title: 'Добавить группу'
            },
            grades: {
                showModal: false,
                component: 'create-grade-form',
                title: 'Добавить грейд'
            },
            skills: {
                showModal: false,
                component: 'create-skill-form',
                title: 'Добавить навык'
            },
            paymentMethods: {
                showModal: false,
                component: 'create-payment-method-form',
                title: 'Добавить способ оплаты'
            },
            specialMarks: {
                showModal: false,
                component: 'create-special-mark-form',
                title: 'Добавить особую метку'
            },
            hrSources: {
                showModal: false,
                component: 'create-hr-source-form',
                title: 'Добавить HR источник'
            }
        });
        const setModalVisibility = (type, bool) => {
            currentModal.value = type;
            additionalModals[type].showModal = bool;
            if (!bool) {
                currentModal.value = 'roles';
            }
        };
        const form = reactive({
            email: {
                value: props.defaultValue?.user?.email,
                error: '',
                disabled: props.mode === 'edit'
            },
            fullName: {
                value: props.defaultValue?.fullName,
                error: ''
            },
            roleId: {
                value: props.defaultValue?.roleId,
                error: ''
            },
            telegram: {
                value: props.defaultValue?.telegram,
                error: ''
            },
            phone: {
                value: props.defaultValue?.phone,
                error: ''
            },
            skype: {
                value: props.defaultValue?.skype,
                error: ''
            },
            gradeId: {
                value: props.defaultValue?.gradeId,
                error: ''
            },
            hourlyRate: {
                value: props.defaultValue?.hourlyRate,
                error: ''
            },
            currency: {
                value: props.defaultValue?.currency,
                error: ''
            },
            hoursPayload: {
                value: props.defaultValue?.hoursPayload,
                error: ''
            },
            specialMarkId: {
                value: props.defaultValue?.specialMarkId,
                error: ''
            },
            hrSourceId: {
                value: props.defaultValue?.hrSourceId,
                error: ''
            },
            mark: {
                value: props.defaultValue?.mark,
                error: ''
            },
            groupId: {
                value: props.defaultValue?.groupId,
                error: ''
            },
            paymentMethods: {
                value: props.defaultValue?.paymentMethods || [],
                error: ''
            },
            skills: {
                value: props.defaultValue?.skills && Array.isArray(props.defaultValue?.skills) ? props.defaultValue?.skills.map(x => x.skillId) : [],
                error: ''
            },
            paymentDates: {
                value: {
                    count: props.defaultValue?.paymentDates?.count,
                    dayNumberOne: props.defaultValue?.paymentDates?.dayNumberOne,
                    dayNumberTwo: props.defaultValue?.paymentDates?.dayNumberTwo
                },
                error: ''
            },
            avatar: {
                value: props.defaultValue?.avatar,
                error: ''
            },
        });
        const {inviteUser} = useWorkspace(loading);
        const {directories, getDirectories} = useDirectory(loading);
        const clearErrors = () => {
            form.email.error = undefined;
            form.fullName.error = undefined;
            form.roleId.error = undefined;
            form.telegram.error = undefined;
            form.phone.error = undefined;
            form.skype.error = undefined;
            form.gradeId.error = undefined;
            form.hourlyRate.error = undefined;
            form.currency.error = undefined;
            form.hoursPayload.error = undefined;
            form.specialMarkId.error = undefined;
            form.hrSourceId.error = undefined;
            form.mark.error = undefined;
            form.groupId.error = undefined;
            form.paymentMethods.error = undefined;
            form.skills.error = undefined;
            form.paymentDates.error = undefined;
            form.avatar.error = undefined;
        };
        const {init} = useFormErrors();

        const inviteUserWrapper = async () => {
            const memberData = {
                email: form.email.value,
                fullName: form.fullName.value,
                workspaceId: props.workspaceId,
                telegram: form.telegram.value,
                phone: form.phone.value,
                skype: form.skype.value,
                gradeId: form.gradeId.value,
                hourlyRate: form.hourlyRate.value,
                currency: form.currency.value,
                hoursPayload: form.hoursPayload.value,
                specialMarkId: form.specialMarkId.value,
                hrSourceId: form.hrSourceId.value,
                mark: form.mark.value,
                groupId: form.groupId.value,
                paymentMethods: form.paymentMethods.value,
                paymentDates: form.paymentDates.value,
                skills: form.skills.value,
                avatar: form.avatar.value,
                roleId: form.roleId.value,
            };
            if (props.mode === 'edit') {
                emit('update', {...memberData, id: props.defaultValue?.id, email: undefined});
            } else {
                const {ok} = await init(form, async () => {
                    return inviteUser(memberData);
                }, clearErrors);
                if (ok) {
                    notification.success({
                        description: 'Приглашение успешно выслано',
                        message: 'Успех!'
                    });
                    emit('ok');
                }
            }
        };
        const disabled = computed(() => {
            return !form.email.value;
        });
        const onDirectoryAdded = async () => {
            setModalVisibility(currentModal.value, false);
            await getDirectories(props.workspaceId, 'members,contacts');
        };
        onMounted(async () => {
            await getDirectories(props.workspaceId, 'members,contacts');
        });
        return {
            inviteUserWrapper,
            disabled,
            form,
            loading,
            directories,
            selectFilterFunc,
            constants,
            currentModal,
            additionalModals,
            setModalVisibility,
            onDirectoryAdded,
        };
    }
};
</script>

<style lang="scss">
.invite-user-form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
}
</style>