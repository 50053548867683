<template lang="pug">
.workspace-member-others
    card-title Прочее
    .workspace-member-others__column
        .flex.items-center Особая метка
        editable-cell(
            :initialValue="member.specialMarkId"
            @update="(e) => onUpdate('specialMarkId', e)"
            :title="getSpecialMarkText()"
            :options="directories.specialMarks.list"
            type="select"
        )
    .workspace-member-others__column.mt-3
        .flex.items-center Примечание
        editable-cell(
            :initialValue="member.mark"
            @update="(e) => onUpdate('mark', e)"
            :title="getMarkText()"
            type="textarea"
        )
    .workspace-member-others__column
        .flex.items-center HR источник
        editable-cell(
            :initialValue="member.hrSourceId"
            @update="(e) => onUpdate('hrSourceId', e)"
            :title="getHrSourceText()"
            :options="directories.hrSources.list"
            type="select"
        )
</template>

<script>
import EditableCell from '@/components/Generic/EditableCell';
import CardTitle from '@/components/Generic/Typography/CardTitle';
export default {
    name: 'MemberOthersData',
    components: {CardTitle, EditableCell},
    props: {
        member: Object,
        directories: Object,
    },
    emits: ['update'],
    async setup(props, {emit}) {
        const getSpecialMarkText = () => {
            return props.member?.specialMark?.title || 'Не указано';
        };
        const getHrSourceText = () => {
            return props.member?.hrSource?.title || 'Не указано';
        };
        const getMarkText = () => {
            return props.member?.mark || 'Не указано';
        };
        const onUpdate = (key, value) => {
            emit('update', {[key]: value});
        };
        return {
            getSpecialMarkText,
            getHrSourceText,
            onUpdate,
            getMarkText,
        };
    }
};
</script>

<style  lang="scss" scoped>
.workspace-member-others {
    &__column {
        margin-bottom: 30px;
        display: grid;
        grid-template-columns: 200px 400px;
        grid-gap: 15px;
    }
}
</style>