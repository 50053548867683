<template lang="pug">
.contacts
    a-input.mr-3(
        placeholder="Поиск по номеру и дате документа"
        v-model:value="filter.search"
        style="width: 50%"
    )
    services-select(
        v-if="!serviceId"
        v-model="filter.serviceId"
    )
    custom-select-v2.mx-2(
        v-model="filter.customer"
        placeholder="Клиент"
        style="width:100%"
        :options="clients.list"
        @search="onContactsSearch"
    )
    custom-select.mr-3(
        v-model="filter.documentTypes"
        title="Тип документа"
        :options="directories.documentTypes.list"
        mode="multiple"
    )
    custom-select.mr-3(
        v-model="filter.statuses"
        title="Статус"
        :options="directories.documentStatuses.list"
        mode="multiple"
    )
    a-date-picker(v-model:value="filter.deadline" placeholder="Крайний срок исполнения" style="width: 50%" format="DD.MM.YYYY")
a-tabs(v-model:activeKey="filter.contactGroupId" v-if="!workspaceMemberId")
    a-tab-pane(v-for="tab in tabs" :key="tab.key" :tab="`${tab.title} (${tab.count})`")
</template>

<script>
import {onMounted, reactive, ref, watch} from 'vue';
import {useDirectory} from '@/composition/directory';
import {isUndefined, omitBy, selectFilterFunc} from '@/modules/utils';
import {contactStatus, contactStatusMap} from '@/components/Contacts/constants';
import {useRoute, useRouter} from 'vue-router';
import ServicesSelect from '@/components/Services/Select';
import CustomSelectV2 from '@/components/Generic/CustomSelectV2';
import CustomSelect from '../../Generic/CustomSelect';
import {useWorkspace} from '@/composition/workspace';
import {useContactV2} from '@/composition/contact-v2';
export default {
    name: 'DocumentFilter',
    components: {
        CustomSelectV2,
        ServicesSelect,
        CustomSelect
    },
    props: {
        serviceId: [String, Number],
        workspaceMemberId: [String, Number],
        tabs: {
            type: Array,
            default: () => ([])
        }
    },
    emits: ['filter'],
    async setup(props, {emit}) {
        const loading = ref(false);
        const router = useRouter();
        const route = useRoute();
        const {directories} = useDirectory(loading);
        const filter = reactive({
            serviceId: undefined,
            workspaceMemberId: undefined,
            search: '',
            statuses: [],
            documentTypes: [],
            deadline: undefined,
            contactGroupId: undefined,
            customer: null,
        });
        watch(filter, () => {
            router.push({
                query: omitBy({
                    contactGroupId: filter.contactGroupId,
                    customerId: filter.customer ? filter.customer.id : undefined
                }, (val) => !isUndefined(val))
            });
            emit('filter', filter);
        });
        onMounted(() => {
            const contactGroupId = route.query.contactGroupId;
            const customerId = route.query.customerId;
            filter.customer = customerId ? {id: Number(customerId), title: ''} : null;
            filter.contactGroupId = contactGroupId && contactGroupId !== 'all' ? Number(contactGroupId) : 'all';
            filter.statuses = directories.value.documentStatuses.list.filter(x => x.key !== 'archive');
            filter.serviceId = props.serviceId ? Number(props.serviceId) : undefined;
            filter.workspaceMemberId = props.workspaceMemberId ? Number(props.workspaceMemberId) : undefined;
        });
        /*===============WORKSPACE AREA===================*/
        const {currentWorkspaceId} = useWorkspace();
        /*===============CONTACTS AREA===================*/
        const {
            clients,
            getClients,
        } = useContactV2();
        const onContactsSearch = (q) => {
            getClients(currentWorkspaceId.value, q, false);
        };
        onMounted(async () => {
            await getClients(currentWorkspaceId.value, undefined, false);
        });
        return {
            clients,
            filter,
            directories,
            contactStatus,
            contactStatusMap,
            selectFilterFunc,
            onContactsSearch,
        };
    }
};
</script>

<style lang="scss" scoped>
.contacts {
    display: flex;
    align-items: center;
}
</style>