import {ref} from 'vue';
import {apiRequest} from '@/modules/error_handler';
import resourceManagementModule from '@/modules/resource-management';
import {ListOf} from '@/core/common/interface/list-of.interface';
import {TimeLineInterface} from '@/modules/resource-management/entities/time-line.interface';
import {TimeLineFormInterface} from '@/modules/resource-management/form/time-line-form.interface';
import {ApiRequestResultInterface} from '@/core/common/interface/api-request-result.interface';
import {TimeLineWorkspaceMemberInterface} from '@/modules/resource-management/entities/time-line-workspace-member.interface';
import {OkResponse} from '@/core/common/interface/ok-response.interface';
import {TimeLineSettingsInterface} from '@/modules/resource-management/filters/time-line-settings.interface';

export function useResourceManagement() {
    const timeLinesList = ref<ListOf<TimeLineInterface>>({
        list: [],
        next: false,
        total: 0
    });
    const members = ref<TimeLineWorkspaceMemberInterface[]>([]);
    const getTimeLines = async (params: TimeLineSettingsInterface): Promise<ApiRequestResultInterface<ListOf<TimeLineInterface>>> => {
        return apiRequest(async () => {
            timeLinesList.value = await resourceManagementModule.getTimeLines(params);
            return timeLinesList.value;
        });
    };
    const getTimeLineMembers = async (params: TimeLineSettingsInterface): Promise<ApiRequestResultInterface<TimeLineWorkspaceMemberInterface[]>> => {
        return apiRequest(async () => {
            members.value = await resourceManagementModule.getTimeLineMembers(params);
            return members.value;
        });
    };
    const updateTimeLine = async (id: number, params: TimeLineFormInterface): Promise<ApiRequestResultInterface<TimeLineInterface>> => {
        return apiRequest(async () => {
            return resourceManagementModule.updateTimeLine(id, params);
        });
    };
    const removeTimeLine = async (id: number): Promise<ApiRequestResultInterface<OkResponse>> => {
        return apiRequest(async () => {
            return resourceManagementModule.deleteTimeLine(id);
        });
    };
    const createTimeLine = async (data: TimeLineFormInterface): Promise<ApiRequestResultInterface<TimeLineInterface>> => {
        return apiRequest(async () => {
            return resourceManagementModule.createTimeLine(data);
        });
    };
    return {
        members,
        timeLinesList,
        getTimeLines,
        updateTimeLine,
        removeTimeLine,
        createTimeLine,
        getTimeLineMembers,
    };
}
