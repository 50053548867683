<template lang="pug">
.salary-payments-list-members
    salary-payments-list-member-item(
        v-for="listItem in listMembers"
        :memberSalary="listItem"
        @positions-added="onPositionAdded"
        @position-deleted="onPositionDeleted"
        @member-deleted="onMemberDeleted"
        @position-field-updated="onPositionFieldUpdated"
        :from="from"
        :to="to"
    )
    a-divider
    card-title Итого: {{ totalByCurrency }}
</template>

<script>

export default {
    name: 'SalaryPaymentsListMembers'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
import SalaryPaymentsListMemberItem from './MemberItem';
import {computed} from 'vue';
import Big from 'big.js';
import {formatCurrency} from '@/modules/utils';
import constants from '@/constants';
import CardTitle from '@/components/Generic/Typography/CardTitle';
/*===============INIT AREA===================*/
const props = defineProps({
    listMembers: {
        type: Array,
    },
    from: {
        type: String,
    },
    to: {
        type: String,
    }
});
const emit = defineEmits(['positions-added', 'position-deleted', 'member-deleted', 'position-field-updated']);
const onPositionAdded = (data) => {
    emit('positions-added', data);
};
const onPositionDeleted = ({id, workspaceMemberId}) => {
    emit('position-deleted', {id, workspaceMemberId});
};
const onMemberDeleted = (id) => {
    emit('member-deleted', id);
};
const onPositionFieldUpdated = (payload) => {
    emit('position-field-updated', payload);
};
const totalByCurrency = computed(() => {
    const currMap = {};
    const listMembers = props.listMembers || [];
    for (const member of listMembers) {
        const positions = member.positions || [];
        for (const position of positions) {
            currMap[position.currency] = Big(position.total).plus(currMap[position.currency] || 0);
        }
    }
    return Object.keys(currMap).map(curr => `${formatCurrency(currMap[curr].toFixed(2))} ${constants.currencyMap[curr]}`).join(', ');
});
</script>

<style lang="scss">
</style>