<template lang="pug">
.member-finance
    card-title О контакте
    .editable-card__column.mt-2
        .flex.items-center Наименование
        editable-cell(:initialValue="contact.title" @update="(e) => onUpdate('title', e)")
    .editable-card__column.mt-2
        .flex.items-center Тип
        editable-cell(
            :initialValue="contact.type"
            @update="(e) => onUpdate('type', e)"
            :title="getTypeText()"
            :options="getTypeOptions()"
            type="select"
        )
    .editable-card__column.mt-2
        .flex.items-center Группа
        editable-cell(
            :initialValue="contact.contactGroupId"
            @update="(e) => onUpdate('contactGroupId', e)"
            :title="getGroupText()"
            type="select"
            :options="directories.contactGroups.list"
        )
    .editable-card__column.mt-2
        .flex.items-center Описание
        editable-cell(
            :initialValue="contact.description"
            @update="(e) => onUpdate('description', e)"
            type="textarea"
        )
    .editable-card__column.mt-2
        .flex.items-center Страна, город
        editable-cell(
            :initialValue="contact.address"
            @update="(e) => onUpdate('address', e)"
        )
</template>

<script>
import CardTitle from '@/components/Generic/Typography/CardTitle';
import constants from '@/constants';
import EditableCell from '@/components/Generic/EditableCell';
import {contactsType, contactsTypeMap} from '@/components/Contacts/constants';
export default {
    name: 'ContactAbout',
    components: {EditableCell, CardTitle},
    props: {
        contact: Object,
        directories: Object,
    },
    emits: ['update'],
    setup(props, {emit}) {
        const getTypeText = () => {
            return contactsTypeMap[props.contact?.type] || 'Не указано';
        };
        const getGroupText = () => {
            return props.contact?.contactGroup?.title || 'Не указано';
        };
        const getTypeOptions = () => {
            return Object.keys(contactsType).map(x => ({
                id: x,
                title: contactsTypeMap[x]
            }));
        };
        const onUpdate = (key, value) => {
            emit('update', {[key]: value});
        };
        return {
            getTypeText,
            getTypeOptions,
            constants,
            onUpdate,
            getGroupText,
            contactsTypeMap
        };
    }
};
</script>

<style lang="scss" scoped>

</style>