<template lang="pug">
documents-table(:workspaceMemberId="route.params.workspaceMemberId")
</template>

<script>
import DocumentsTable from '@/components/Documents/Table';
import {useRoute} from 'vue-router';
export default {
    name: 'MemberDocuments',
    components: {DocumentsTable},
    setup() {
        const route = useRoute();
        return {
            route
        };
    }
};
</script>

<style scoped>

</style>