<template lang="pug">
.page.login-page
    .login-page-left
        typography-title.login-page__title Welcome to your productivity origin story
        .login-page-form
            input-with-error(:error="form.fullName.error")
                template(#input)
                    a-input(placeholder="ФИО" v-model:value="form.fullName.value" @pressEnter="registerWrapper")
            input-with-error(:error="form.email.error")
                template(#input)
                    a-input.mt-3(placeholder="email" v-model:value="form.email.value" @pressEnter="registerWrapper")
            input-with-error(:error="form.password.error")
                template(#input)
                    a-input.mt-3(placeholder="password" v-model:value="form.password.value" type="password" @pressEnter="registerWrapper")
            a-checkbox.mt-3(v-model:checked="terms") Я принимаю условия пользовательского соглашения
            a-button.mt-5.mb-2(
                type="primary"
                block
                @click="registerWrapper"
                :loading="loading"
                :disabled="disabled"
            ) Зарегистрироваться
            router-link(to="/login") У меня уже есть аккаунт
    .login-page-right
        laptop
</template>

<script>

import {ref, computed, reactive} from 'vue';
import {useAuth} from '@/composition/auth';
import {useFormErrors} from '@/composition/errors';
import InputWithError from '@/components/Generic/InputWithError';
import {useRouter} from 'vue-router';
import Laptop from '@/assets/img/super-with-laptop.svg';
import socketModule from '@/modules/socket';
export default {
    name: 'Register',
    components: {InputWithError, Laptop},
    setup() {
        const router = useRouter();
        const loading = ref(false);
        const terms = ref(false);
        const form = reactive({
            fullName: {
                value: '',
                error: ''
            },
            email: {
                value: '',
                error: ''
            },
            password: {
                value: '',
                error: ''
            }
        });
        const {register} = useAuth(loading);
        const clearErrors = () => {
            form.email.error = '';
            form.password.error = '';
            form.fullName.error = '';
        };
        const {init} = useFormErrors();

        const registerWrapper = async () => {
            const {ok} = await init(form, async () => {
                const registerData = {
                    fullName: form.fullName.value,
                    email: form.email.value,
                    password: form.password.value
                };
                return register(registerData);
            }, clearErrors);
            if (ok) {
                socketModule.auth();
                await router.push('/');
            }
        };

        const disabled = computed(() => {
            return !form.email.value || !form.password.value || !form.fullName.value || !terms.value;
        });
        return {
            register,
            form,
            loading,
            disabled,
            registerWrapper,
            terms
        };
    }
};
</script>

<style scoped lang="scss">

</style>