<template lang="pug">
#chart(ref="chartRef")
</template>

<script>
import ApexCharts from 'apexcharts';
import {onMounted, ref, watch} from 'vue';
import {getMaxOfArray} from '@/modules/utils';

export default {
    name: 'ColumnChart',
    props: {
        data: [Array, Object],
        loading: Boolean,
        seriesName: {
            type: String,
            default: ''
        },
        stacked: Boolean,
        type: {
            type: String,
            default: 'bar'
        }
    },
    setup(props) {
        const options = ref({
            chart: {
                type: props.type,
                stacked: props.stacked,
                background: '#f8f8f9',
                height: 300,
                fontFamily: '\'Roboto\', sans-serif'
            },
            series: [{
                name: props.seriesName,
                data: []
            }],
            stroke: {
                curve: 'smooth'
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                categories: [],
            },
            colors: ['#F3B415', '#F27036', '#663F59', '#6A6E94', '#4E88B4', '#00A7C6', '#18D8D8', '#A9D794',
                '#46AF78', '#A93F55', '#8C5E58', '#2176FF', '#33A1FD', '#7A918D', '#BAFF29'
            ],
            plotOptions: {
                bar: {
                    horizontal: false,
                }
            },
            noData: {
                text: 'Нет данных...',
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: undefined,
                    fontSize: '14px',
                    fontFamily: undefined
                }
            }
        });
        const chart = ref(null);
        const chartRef = ref(null);
        const updateData = () => {
            const xaxis = props.data.xaxis;
            const series = props.data.series;
            chart.value.updateOptions({
                series: series,
                xaxis: {
                    categories: xaxis,
                },
            });
            options.value.xaxis.categories = xaxis;
        };
        watch(() => props.data, () => {
            updateData();
        }, {deep: true});
        onMounted(() => {
            chart.value = new ApexCharts(chartRef.value, options.value);
            chart.value.render();
            updateData();
        });
        return {
            chartRef
        };
    }
};
</script>

<style scoped>

</style>
