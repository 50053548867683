export const savedReportsColumns = [
    {
        title: '#',
        dataIndex: 'id',
        key: 'id',
        width: '2%'
    },
    {
        title: 'Наименование',
        dataIndex: 'title',
        key: 'title',
        slots: {customRender: 'title'},
        width: '25%'
    },
    {
        title: 'Период',
        slots: {customRender: 'period'},
        width: '20%'
    },
    {
        title: 'Клиенты',
        slots: {customRender: 'clients'},
        width: '20%'
    },
    {
        title: 'Услуги',
        slots: {customRender: 'services'},
        width: '20%'
    },
    {
        title: 'Дата создания',
        dataIndex: 'createdAt',
        key: 'createdAt',
        slots: {customRender: 'createdAt'},
        width: '7%'
    },
    {
        title: '',
        slots: {customRender: 'action'},
        width: '4%'
    },
];