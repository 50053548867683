// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import constants from '@/constants';
import numeral from 'numeral';
import {v4 as uuidv4, validate as isValidUUID} from 'uuid';
import Big from 'big.js';

export const selectFilterFunc = (input, option) => {
    try {
        return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    } catch (error) {
        console.log(error);
    }
};
export const getTitlesFromArray = (arr, accessor) => {
    if (!accessor) accessor = 'title';
    return arr.map(x => x[accessor]).join(',');
};
export const getTitle = (refObject, propertyName, accessor) => {
    if (!accessor) accessor = 'title';
    if (accessor && Array.isArray(accessor)) {
        for (const accessorElement of accessor) {
            if (refObject?.[propertyName]?.[accessorElement]) {
                return refObject?.[propertyName]?.[accessorElement];
            }
        }
    }
    if (!propertyName) {
        return refObject?.[accessor];
    }
    return refObject?.[propertyName]?.[accessor];
};

export const truncateNumber = (number, decimals = 0) => {
    return Number(number).toFixed(decimals);
};

export const getCurrencyText = (currency) => {
    return constants.currencyMap[currency] || 'Не указано';
};

export const getCurrencyOptions = () => {
    return Object.keys(constants.currency).map(x => ({
        id: x,
        title: constants.currencyMap[x]
    }));
};

export const lastChars = (count = 0, str) => {
    if (!str) return '';
    return `**** ${str.substr(str.length - count)}`;
};

export const firstChars = (count = 0, str) => {
    if (!str) return '';
    return `${str.substr(0, count)} ...`;
};

export const debounce = (callback, wait) => {
    let timeoutId = null;
    return (...args) => {
        window.clearTimeout(timeoutId);
        timeoutId = window.setTimeout(() => {
            // eslint-disable-next-line prefer-spread
            callback.apply(null, args);
        }, wait);
    };
};

export const getPercent = (div = 0, total = 1) => {
    if (Number(div) === 0 && (Number(total) === 1 || Number(total) == 0)) return '100';
    if (total === 0 || !total) total = 1;
    return Number((Number(div) * 100) / Number(total)).toFixed(0);
};

export const randomInRange = (minimum, maximum) => Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;

export const recursiveMod = (num) => {
    if (num < 4) return num;
    return recursiveMod(num % 2);
};

export const formatCurrency = (num) => {
    const ruCurrency = Intl.NumberFormat('ru-RU');
    return ruCurrency.format(num);
};

export const arrayToStringTs = <T>(arr, nested, accessor = 'id'): string => {
    if(!arr) return '';
    if (nested) return arr.map<T>(x => x[accessor]).join(',');
    return arr.join(',');
};

export const parseArrayFromString = (str, nested, accessor = 'id', mapper = Number) => {
    if (nested) {
        return str.split(',').map(id => ({[accessor]: mapper(id)}));
    }
    return str.split(',').map(Number);
};


export function omitByTs<T>(obj: T, predicate: (value: any) => boolean): T {
    const result = {} as T;
    for (const key in obj) {
        const value = obj[key];
        if (predicate(value)) {
            result[key] = value;
        }
    }
    return result;
}

export const groupBy = (xs, key) => {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const getMaxOfArray = (numArray) => {
    return Math.max.apply(null, numArray);
};

export const secondsToHumanReadable = (seconds = 0) => {
    return numeral(seconds).format('00:00:00');
};

export const sleep = (ms) => {
    return new Promise((res) => {
        setTimeout(() => {
            res();
        }, ms);
    });
};

export const declOfNum = (n, forms) => {
    n = Math.abs(n) % 100;
    const n1 = n % 10;
    if (n > 10 && n < 20) {
        return forms[2];
    }
    if (n1 > 1 && n1 < 5) {
        return forms[1];
    }
    if (n1 == 1) {
        return forms[0];
    }
    return forms[2];
};

export function base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

export const typeOfB = (value: any) => Object.prototype.toString.call(value).split(']')[0].split(' ')[1].toLowerCase();
export const isNullOrUndefined = (value: any) => isUndefined(value) || isNull(value);
export const isUndefined = (value: any) => value === undefined;
export const isNull = (value: any) => value === null;
export const truncateText = (str: string, maxChars = 50) => {
    if (!str) return '';
    const len = str.length;
    if (len > maxChars) {
        const firstNChars = Math.ceil(maxChars * 0.3);
        const lastNChars = Math.ceil(maxChars * 0.2);
        return str.slice(0, firstNChars) + '...' + str.slice(-lastNChars);
    }
    return str;
};

export const toLabelValue = (arrOrObject, value = 'id', label = 'title') => {
    if (Array.isArray(arrOrObject)) {
        if (arrOrObject.length === 0) return [];
        return arrOrObject.map(x => ({...x, label: x[label], value: x[value]}));
    }
    return {
        ...arrOrObject,
        [value]: arrOrObject[value],
        [label]: arrOrObject[label],
    };
};
export const fromLabelValue = (arrOrObject, value = 'id', label = 'title') => {
    if (Array.isArray(arrOrObject)) {
        return arrOrObject.map(x => ({...arrOrObject, [label]: x.label, [value]: x.value}));
    }
    return {
        ...arrOrObject,
        [label]: arrOrObject.label,
        [value]: arrOrObject.value,
    };
};

export const isUUID = (s) => {
    return isValidUUID(s);
};

export const generateUUID = () => {
    return uuidv4();
};

export const range = (start, end) => {
    const result = [];
    for (let i = start; i <= end; i++) {
        result.push(i);
    }
    return result;
};

export const isPrimitiveArray = (arr) => {
    if (!arr.length) return true;
    const first = arr[0];
    if (isNullOrUndefined(first)) return true;
    return typeof first !== 'object';
};

export const toFixedNumber = (num: number | Big, dp=2): number => {
    return Big(Big(num).toFixed(dp, 0)).toNumber();
};

export function safePromise(promise): Promise<[any, any]> {
    return promise.then(data => {
        return [null, data];
    })
        .catch(err => [err]);
}
