import {httpInstance} from '@/modules/http_factory';

const convertToRub = async (from, amount) => {
    const response = await httpInstance().get('/convert', {
        params: {
            from,
            amount,
        }
    });
    return response.data;
};
const getCurrencyStats = async () => {
    const response = await httpInstance().get('/convert/stats');
    return response.data;
};
export default {
    convertToRub,
    getCurrencyStats
};