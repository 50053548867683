<template lang="pug">
.page.login-page
    .login-page-left
        typography-title.login-page__title Создайте свое рабочее пространство
        .login-page-form
            input-with-error(:error="form.title.error")
                template(#input)
                    a-input(placeholder="Наименование" v-model:value="form.title.value")
            input-with-error(:error="form.description.error")
                template(#input)
                    a-input.mt-3(placeholder="Описание" v-model:value="form.description.value")
            a-button.mt-5.mb-2(
                type="primary"
                block
                @click="createWorkspaceWrapper"
                :loading="loading"
                :disabled="disabled"
            ) Создать
    .login-page-right
        laptop
</template>

<script>

import {ref, computed, reactive} from 'vue';
import {useFormErrors} from '@/composition/errors';
import InputWithError from '@/components/Generic/InputWithError';
import {useRouter} from 'vue-router';
import Laptop from '@/assets/img/super-with-laptop.svg';
import {useWorkspace} from '@/composition/workspace';
export default {
    name: 'Login',
    components: {InputWithError, Laptop},
    setup() {
        const router = useRouter();

        const loading = ref(false);

        const form = reactive({
            title: {
                value: '',
                error: ''
            },
            description: {
                value: '',
                error: ''
            }
        });
        const {createWorkspace} = useWorkspace(loading);
        const clearErrors = () => {
            form.title.error = '';
            form.description.error = '';
        };
        const {init} = useFormErrors();

        const createWorkspaceWrapper = async () => {
            const {ok} = await init(form, async () => {
                return createWorkspace(form.title.value, form.description.value);
            }, clearErrors);
            if (ok) {
                await router.push('/');
            }
        };

        const disabled = computed(() => {
            return !form.title.value;
        });
        return {
            createWorkspace,
            form,
            loading,
            disabled,
            createWorkspaceWrapper
        };
    }
};
</script>

<style scoped lang="scss">
.login-page {
    display: flex;

    &__title {
        max-width: 400px;
        text-align: center;
    }

    &-form {
        width: 280px;
        margin: 30px 0;
    }

    &-right {
        min-height: 100vh;
        height: 100%;
        width: 50%;
        background: $greyColor;
        display: flex;
        align-items: center;
        justify-content: center;
        @media(max-width: $screenMdMin) {
            display: none;
        }
    }

    &-left {
        min-height: 100vh;
        height: 100%;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @media(max-width: $screenMdMin) {
            width: 100%;
        }
    }
}
.errors {
    border-color: #42b983 !important;
}
</style>