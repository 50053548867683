<template lang="pug">
.transactions-table
    a-button.mb-2(type="primary" @click="setModalVisibility(true)") Добавить
    time-log-filter(
        v-model:filter="filter"
    )
    custom-table(
        :columns="columns"
        :data-source="timeLogs.list"
        :onRowClick="editTimeLog"
    )
        template(#createdAt="{record}")
            .flex.items-center
                div {{formatDate(record.createdAt)}}
                .ml-3(v-if="record.isPaid" style="color: green") ☑
        template(#workspaceMember="{record}")
            div(v-if="record")
                user-avatar(:user="record.workspaceMember")
        template(#startDate="{record}")
            div {{ formatDateTime(record.startDate) }}
        template(#endDate="{record}")
            div {{ formatDateTime(record.endDate) }}
        template(#task="{record}")
            div {{ record?.task?.fullTitle }}
        template(#paymentStatus="{record}")
            div(:class="[record.isPaid ? 'success' : 'danger']") {{ record.isPaid ? 'Оплачено' : 'Не оплачено' }}
        template(#service="{record}")
            services-inline-changer(
                v-if="!record.isPaid"
                :service="record.service"
                :func="async (serviceId) => await onServiceChanged(record.id, serviceId)"
            )
            div(v-else) {{ getTitle(record, 'service', ['title']) }}
        template(#type="{record}")
            div {{ record?.wasEdited ? 'Ручное' : 'Авто' }}
        template(#action="{text, record}")
            a-dropdown(:trigger="['click']" @click.stop)
                .dots-wrapper
                    icon.cursor-pointer(type="Dots")
                template(#overlay)
                    a-menu
                        a-menu-item(key="1")
                            delete-with-confirm(:deleteFunc="() => onDelete(record.id)" btnText="Удалить")
                        a-menu-item(key="2")
                            div(@click="editTimeLog(record)") Редактировать
    a-button.mt-3(@click="next" v-if="timeLogs.next" :loading="loading") Показать еще
    a-modal(
        v-model:visible="showModal"
        title="Добавить лог времени"
        :footer="null"
        @cancel="setModalVisibility(false)"
        :destroyOnClose="true"
    )
        create-time-log-form(
            :workspaceId="currentWorkspaceId"
            @ok="onCreate"
            @update="onUpdate"
            :mode="currentMode"
            :default-value="editingElement"
        )
</template>

<script>
import {ref, watch} from 'vue';
import {useWorkspace} from '@/composition/workspace';
import EditableCell from '@/components/Generic/EditableCell';
import Icon from '@/components/Generic/Typography/Icon';
import {useDate} from '@/composition/date';
import CustomTable from '@/components/Generic/CustomTable';
import {useCreateOrUpdateModal} from '@/composition/create-update-modal';
import {useTimelog} from '@/composition/timelog';
import {timeLogColumns} from '@/components/TimeLogs/constants';
import CreateTimeLogForm from '@/components/TimeLogs/CreateForm';
import moment from 'moment';
import TimeLogFilter from '@/components/TimeLogs/Filter';
import constants from '@/constants';
import {useKanban} from '@/composition/kanban';
import {useService} from '@/composition/service';
import {getTitle} from '@/modules/utils';
import ServicesInlineChanger from '@/components/Services/InlineChanger';
const defaultTz = constants.tz.default;
export default {
    name: 'TimeLogsTable',
    components: {ServicesInlineChanger, TimeLogFilter, CreateTimeLogForm, CustomTable, Icon, EditableCell},
    props: {
        serviceId: [Number, String]
    },
    async setup(props) {
        const {
            currentMode,
            editingValue: editingElement,
            showModal,
            setModalVisibility,
            edit
        } = useCreateOrUpdateModal();
        const {
            showModal: showFilterModal,
            setModalVisibility: setFilterModalVisibility,
        } = useCreateOrUpdateModal();
        const loading = ref(false);
        const {currentWorkspaceId, currentWorkspaceTz, getMembers} = useWorkspace(loading);
        const {getDailyTotal, pagination, deleteTimeLog, getTimeLogs, updateTimeLog, timeLogs, setFilter, filter, localUpdate} = useTimelog(loading);
        const {getTasksList} = useKanban(loading);

        if (props.serviceId) {
            setFilter({
                ...filter.value,
                serviceId: props.serviceId,
            });
        }
        watch(filter, () => {
            onFilterChanged(filter.value);
        });
        const {formatDate, formatDateTime, getHoursAndMinutesFromMs} = useDate();
        const onDelete = async (id) => {
            pagination.value.offset = 0;
            await deleteTimeLog(currentWorkspaceId.value, id);
            await getTimeLogs(currentWorkspaceId.value, {}, currentWorkspaceTz.value);
            await getDailyTotal(currentWorkspaceId.value);
        };
        const onUpdate = async (timeLog) => {
            const {id, ...updateData} = timeLog;
            const {ok} = await updateTimeLog(currentWorkspaceId.value, id, {
                ...updateData,
            });
            if (ok) {
                pagination.value.offset = 0;
                await getTimeLogs(currentWorkspaceId.value, {}, currentWorkspaceTz.value);
                await getDailyTotal(currentWorkspaceId.value);
                setModalVisibility(false);
            }
        };
        const next = async () => {
            pagination.value.offset += pagination.value.limit;
            await getTimeLogs(currentWorkspaceId.value, {fromPagination: true}, currentWorkspaceTz.value);
        };
        const onFilterChanged = async(filter) => {
            pagination.value.offset = 0;
            setFilter(filter);
            setFilterModalVisibility(false);
            await getTimeLogs(currentWorkspaceId.value, {fromPagination: false}, currentWorkspaceTz.value);
        };
        const onCreate = async() => {
            pagination.value.offset = 0;
            setModalVisibility(false);
            await getTimeLogs(currentWorkspaceId.value, {fromPagination: false}, currentWorkspaceTz.value);
            await getDailyTotal(currentWorkspaceId.value);
        };
        const editTimeLog = (timeLog) => {
            edit({
                ...timeLog,
                startDate: moment.utc(timeLog.startDate).tz(currentWorkspaceTz.value || defaultTz),
                startTime: {
                    hours: moment.utc(timeLog.startDate).tz(currentWorkspaceTz.value || defaultTz).get('hours'),
                    minutes: moment.utc(timeLog.startDate).tz(currentWorkspaceTz.value || defaultTz).get('minutes'),
                    seconds: moment.utc(timeLog.startDate).tz(currentWorkspaceTz.value || defaultTz).get('seconds'),
                },
                duration: getHoursAndMinutesFromMs(timeLog.durationInMs)
            });
        };
        const onServiceChanged = async (timeLogId, serviceId) => {
            const {ok, result} = await updateTimeLog(currentWorkspaceId.value, timeLogId, {serviceId: serviceId});
            if (ok && result) {
                localUpdate(timeLogId, result);
            }
        };
        return {
            filter,
            editTimeLog,
            onServiceChanged,
            timeLogs,
            loading,
            setModalVisibility,
            showModal,
            onCreate,
            currentWorkspaceId,
            next,
            onDelete,
            onUpdate,
            columns: timeLogColumns,
            onFilterChanged,
            formatDate,
            formatDateTime,
            editingElement,
            currentMode,
            showFilterModal,
            setFilterModalVisibility,
            getTitle
        };
    }
};
</script>

<style lang="scss" scoped>
.transactions-table {
    &__amount {
        font-weight: 600;
    }
}
</style>
