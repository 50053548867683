<template lang="pug">
settings-directories-companies-layout
    async-page
        bank-account-table
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import BankAccountTable from '@/components/Directories/Bills/BankAccount/Table';
import SettingsDirectoriesCompaniesLayout from '@/pages/Settings/Companies/Layout/index';
export default {
    name: 'BankAccountsPage',
    components: {SettingsDirectoriesCompaniesLayout, BankAccountTable, AsyncPage}
};
</script>

<style scoped>

</style>
