<template lang="pug">
a-select(
    v-model:value="workStatusId"
    style="width: 100%"
    placeholder="Статус"
    show-search
    :filter-option="selectFilterFunc"
)
    a-select-option(
        v-for="workStatus in directories.serviceWorkStatuses.list"
        :key="workStatus.id"
        :value="workStatus.id"
        :label="workStatus.title"
    )
        | {{ workStatus.title }}
.mt-3.flex.justify-end
    a-button(type="primary" @click="returnServiceWrapper" :loading="loading" :disabled="!workStatusId") Подтвердить
</template>

<script>
import {ref} from 'vue';
import {useService} from '@/composition/service';

export default {
    name: 'ServiceChangeStatus',
    props: {
        directories: {
            type: Object
        },
        workspaceId: {
            type: [Number, String]
        },
        serviceId: {
            type: Number,
        }
    },
    emits: ['ok'],
    setup(props, {emit}) {
        const loading = ref(false);
        const workStatusId = ref(undefined);
        const {returnService} = useService(loading);
        const returnServiceWrapper = async () => {
            await returnService({
                serviceId: props.serviceId,
                workStatusId: workStatusId.value
            }, props.workspaceId);
            emit('ok');
        };
        return {
            workStatusId,
            returnServiceWrapper,
        };
    }
};
</script>

<style scoped>

</style>