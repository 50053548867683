import {reactive, ref} from 'vue';
const visible = ref(false);
const val = ref(null);
const resolvePromise = ref(null);
const rejectPromise = ref(null);
const defaultOpts = () => ({
    type: 'confirm',
    title: 'Выберите действия',
    description: 'Нажмите "Да", чтобы подтвердить',
    options: null,
});
const options = reactive(defaultOpts());
export function useDialog() {
    const show = (opts) => {
        visible.value = true;
        options.title = opts.title || options.title;
        options.description = opts.description || options.description;
        options.type = opts.type || options.type;
        options.options = opts.options || options.options;
        if (options.type === 'radio' && options.options && options.options.length) {
            val.value = options.options[0].id;
        }
        return new Promise((resolve, reject) => {
            resolvePromise.value = resolve;
            rejectPromise.value = reject;
        });
    };
    const setDefault = () => {
        val.value = null;
        const opts = defaultOpts();
        options.title = opts.title;
        options.description = opts.description;
        options.type = opts.type;
        options.options = opts.options;
    };
    const ok = () => {
        visible.value = false;
        resolvePromise.value(val.value);
        setDefault();
    };
    const cancel = () => {
        visible.value = false;
        rejectPromise.value('Cancelled');
        setDefault();
    };
    return {
        show,
        cancel,
        ok,
        visible,
        val,
        options,
    };
}