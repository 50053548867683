import {reactive, ref} from 'vue';
import {ListOf} from '@/core/common/interface/list-of.interface';
import {RegularTransactionInterface} from '@/modules/transaction/entities/regular-transaction.interface';
import {PaginationInterface} from '@/core/common/interface/pagination.interface';
import {ApiRequestResultInterface} from '@/core/common/interface/api-request-result.interface';
import {apiRequest} from '@/modules/error_handler';
import regularTransactionModule from '@/modules/transaction/regular-transaction';
import {RegularTransactionForm} from '@/modules/transaction/form/regular-transaction.form';
import {OkResponse} from '@/core/common/interface/ok-response.interface';

export function useRegularTransaction() {
    const regularTransactions = reactive<ListOf<RegularTransactionInterface>>({
        list: [],
        next: false,
        total: 0,
    });
    const summary = ref(0);
    const pagination = reactive<PaginationInterface>({
        limit: 100,
        offset: 0
    });
    const getRegularTransactions = async (pagination: PaginationInterface, fromPagination=false): Promise<ApiRequestResultInterface<ListOf<RegularTransactionInterface>>> => {
        return apiRequest<ListOf<RegularTransactionInterface>>(async () => {
            const response = await regularTransactionModule.getRegularTransactions(pagination);
            if (fromPagination) {
                regularTransactions.list = regularTransactions.list.concat(response.list);
            } else {
                regularTransactions.list = response.list;
            }
            regularTransactions.total = response.total;
            regularTransactions.next = response.next;
            return regularTransactions;
        });
    };
    const createRegularTransaction = async (form: RegularTransactionForm): Promise<ApiRequestResultInterface<RegularTransactionInterface>> => {
        return apiRequest<RegularTransactionInterface>(async () => {
            return regularTransactionModule.createRegularTransaction(form);
        });
    };
    const updateRegularTransaction = async (id: number, form: RegularTransactionForm): Promise<ApiRequestResultInterface<RegularTransactionInterface>> => {
        return apiRequest<RegularTransactionInterface>(async () => {
            return regularTransactionModule.updateRegularTransaction(id, form);
        });
    };
    const deleteRegularTransaction = async (id: number): Promise<ApiRequestResultInterface<OkResponse>> => {
        return apiRequest<OkResponse>(async () => {
            return regularTransactionModule.deleteRegularTransaction(id);
        });
    };
    const getSummary = async (): Promise<ApiRequestResultInterface<{total: number}>> => {
        return apiRequest<{total: number}>(async () => {
            const response = await regularTransactionModule.getSummary();
            summary.value = response.total;
            return response;
        });
    };
    return {
        summary,
        pagination,
        regularTransactions,
        getSummary,
        getRegularTransactions,
        createRegularTransaction,
        updateRegularTransaction,
        deleteRegularTransaction,
    };
}
