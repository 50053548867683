<template lang="pug">
.page.service
    bread-crumbs.mb-3(:routes="breadcrumbs")
    card-title {{ service.fullTitle }}
    .service__main-info
        div
            div Клиент: {{ service?.contact?.title }}
            .mt-2 Проект: {{ service?.project?.title }}
        div
            div Руководитель: {{ service.director?.fullName }}
            .mt-2 Исполнители: {{ renderPerformers() }}
        div
            div Старт работ: {{ formatDate(service.startDate) }}
            .mt-2(:class="[isServiceOutdated(service) ? 'danger' : '']") Дедлайн: {{ formatDate(service.deadline) }}
        div
            .flex.items-center
                a-button.block(@click="editService(service)" type="primary") Редактировать
                delete-with-confirm.ml-2(:deleteFunc="() => deleteServiceWrapper()")
    a-tabs(v-model:activeKey="activeKey" @change="onChange")
        a-tab-pane(v-for="tab in tabs" :key="tab.key" :tab="tab.tab")
    slot(:service="service")
a-modal(
    v-model:visible="showModal"
    title="Создать услугу"
    :footer="null"
    @cancel="setModalVisibility(false)"
    :destroyOnClose="true"
    width="50%"
)
    create-service-form(
        :workspaceId="currentWorkspaceId"
        @update="putUpdate"
        :mode="currentMode"
        :default-value="editingElement"
    )
</template>

<script>
import {computed, onMounted, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useWorkspace} from '@/composition/workspace';
import PageTitle from '@/components/Generic/PageTitle';
import {useService} from '@/composition/service';
import {useDate} from '@/composition/date';
import {useCreateOrUpdateModal} from '@/composition/create-update-modal';
import CreateServiceForm from '@/components/Services/CreateForm';
import ServiceFinish from '@/components/Services/Finish';
import {useDirectory} from '@/composition/directory';
import ServiceChangeStatus from '@/components/Services/ChangeStatus';
import DeleteWithConfirm from '@/components/Generic/DeleteWithConfirm';
import CardTitle from '@/components/Generic/Typography/CardTitle';
import {serviceWorkStatusKey} from '@/components/Services/constants';
import BackBtn from '@/components/Generic/BackBtn/index';
import {useContact} from '@/composition/contact';
import {useProject} from '@/composition/project';
import {ServiceInterface} from '@/modules/services/entities/service.interface';
import BreadCrumbs from '@/components/Generic/BreadCrumbs';
export default {
    name: 'ServiceLayout',
    components: {
        BreadCrumbs,
        BackBtn, CardTitle, DeleteWithConfirm, ServiceChangeStatus, ServiceFinish, CreateServiceForm, PageTitle},
    setup() {
        const {
            currentMode,
            editingValue: editingElement,
            showModal,
            setModalVisibility,
            edit
        } = useCreateOrUpdateModal();
        const activeKey = ref(undefined);
        const {currentWorkspaceId, getMembers} = useWorkspace();
        const {getDirectories, directories} = useDirectory();
        const {getClients} = useContact();
        const {getProjects} = useProject();
        const {
            getService,
            service,
            isServiceOutdated,
            updateService,
            setArchive: setAsArchive,
            deleteService,
            serviceWorkStatuses,
            setServiceWorkStatuses,
        } = useService();
        const router = useRouter();
        const route = useRoute();
        const tabs = [
            {
                key: 'payment-periods',
                tab: 'Платежные периоды'
            },
            {
                key: 'performers',
                tab: 'Пользователи'
            },
            {
                key: 'documents',
                tab: 'Документы'
            },
            {
                key: 'bills',
                tab: 'Счета'
            },
            {
                key: 'transactions',
                tab: 'Транзакции'
            },
            {
                key: 'time-logs',
                tab: 'Записи времени'
            },
        ];
        const onChange = (val) => {
            router.push(`/${currentWorkspaceId.value}/services/${route.params.serviceId}/${val}`);
        };
        const renderPerformers = () => {
            if (!service.value.performers) return null;
            return service.value.performers.map(performer => performer?.workspaceMember?.fullName).join(', ');
        };
        const putUpdate = async (data) => {
            const {id, ...updateData} = data;
            const {ok} = await updateService(id, updateData, currentWorkspaceId.value);
            ok && await getService(route.params.serviceId, currentWorkspaceId.value);
            ok && setModalVisibility(false);
        };
        const setArchive = async (isArchive) => {
            await setAsArchive(currentWorkspaceId.value, route.params.serviceId, isArchive);
            await getService(route.params.serviceId, currentWorkspaceId.value);
        };
        const showFinishModal = ref(false);
        const setFinishModalVisibility = (bool) => {
            showFinishModal.value = bool;
        };
        const editService = (data) => {
            edit({
                ...data,
                performers: data.performers.map(x => ({
                    ...x,
                    fullName: x?.workspaceMember?.fullName,
                    paymentDates: {
                        dayNumberOne: x.paymentDayNumberOne,
                        dayNumberTwo: x.paymentDayNumberTwo,
                        count: x.paymentCount,
                    }
                })),
                documents: data.documents.map(x => ({
                    ...x,
                    ...(x?.document || {})
                }))
            });
        };
        const onServiceFinished = async () => {
            await getService(route.params.serviceId, currentWorkspaceId.value);
            setFinishModalVisibility(false);
        };
        const onServiceStatusChanged = async () => {
            await getService(route.params.serviceId, currentWorkspaceId.value);
            setChangeStatusModalVisibility(null, false);
        };
        const onServiceWorkStatusChanged = async (e) => {
            const workStatusId = e.target.value;
            const workStatus = serviceWorkStatuses.value.list.find(x => x.id === workStatusId);
            if (workStatus.key === serviceWorkStatusKey.archive) {
                await setArchive(true);
            } else if (workStatus.key === serviceWorkStatusKey.ended) {
                await setFinishModalVisibility(true);
            } else {
                await updateService(route.params.serviceId, {workStatusId}, currentWorkspaceId.value);
            }
            await getService(route.params.serviceId, currentWorkspaceId.value);
        };
        const showChangeStatusModal = ref(false);
        const changeStatusObject = ref({});
        const setChangeStatusModalVisibility = (service, bool) => {
            showChangeStatusModal.value = bool;
            changeStatusObject.value = service;
            if (!bool) {
                changeStatusObject.value = {};
            }
        };
        const deleteServiceWrapper = async () => {
            await deleteService(route.params.serviceId, currentWorkspaceId.value);
            await router.push(`/${currentWorkspaceId.value}/services`);
        };
        const updatePaymentAgreement = async (e) => {
            const isPaymentAgreed = e.target.checked;
            await updateService(route.params.serviceId, {isPaymentAgreed}, currentWorkspaceId.value);
            await getService(route.params.serviceId, currentWorkspaceId.value);
        };

        const breadcrumbs = computed(() => {
            const currentTab = tabs.find(x => x.key === activeKey.value);
            const routesList = [
                {path: 'services/', title: 'Услуги'},
                {path: `services/${service.value.id}/payment-periods`, title: service.value.fullTitle},
                {path: `services/${service.value.id}/${activeKey.value}`, title: currentTab?.tab},
            ];
            return routesList;
        });
        onMounted(async() => {
            const paths = route.path.split('/');
            activeKey.value = paths[paths.length - 1];
            await getService(route.params.serviceId, currentWorkspaceId.value);
            await getDirectories(currentWorkspaceId.value, 'service');
            await getMembers(currentWorkspaceId.value);
            await getClients(currentWorkspaceId.value);
            await getProjects(currentWorkspaceId.value);
            setServiceWorkStatuses(directories.value.serviceWorkStatuses);
        });
        return {
            tabs,
            service,
            showModal,
            activeKey,
            breadcrumbs,
            currentMode,
            directories,
            editingElement,
            showFinishModal,
            changeStatusObject,
            serviceWorkStatusKey,
            showChangeStatusModal,
            serviceWorkStatuses,
            onChange,
            putUpdate,
            setArchive,
            editService,
            renderPerformers,
            onServiceFinished,
            isServiceOutdated,
            setModalVisibility,
            currentWorkspaceId,
            deleteServiceWrapper,
            updatePaymentAgreement,
            onServiceStatusChanged,
            setFinishModalVisibility,
            onServiceWorkStatusChanged,
            setChangeStatusModalVisibility,
            ...useDate()
        };
    }
};
</script>

<style lang="scss" scoped>
.service {
    &__main-info {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 16px;
        margin-top: 16px;
    }
}
</style>
