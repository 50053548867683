<template lang="pug">
settings-directories-others-layout
    async-page
        operations-table
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import OperationsTable from '@/components/Directories/Bills/Operation/Table';
import SettingsDirectoriesOthersLayout from '@/pages/Settings/Directories/Others/Layout/index';
export default {
    name: 'OperationsPage',
    components: {SettingsDirectoriesOthersLayout, OperationsTable, AsyncPage}
};
</script>

<style scoped>

</style>
