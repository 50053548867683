import {apiRequest} from '@/modules/error_handler';
import contactModule from '@/modules/contact';
import {ref} from 'vue';
import {defaultContactGroup} from '@/components/Directories/Contacts/Groups/constants';

export function useContactV2(loading={value: false}) {

    const pagination = ref({
        limit: 100,
        offset: 0,
    });
    const contacts = ref({
        list: [],
        next: '',
        prevPage: '',
        total: '',
        totalPages: '',
    });

    const clients = ref({
        list: [],
        next: '',
        total: '',
    });

    const companies = ref({
        list: [],
        next: '',
        total: '',
    });
    const contact = ref({});
    const filter = ref({});

    const createContact = async (workspaceId, contactData) => {
        return apiRequest(async() => {
            await contactModule.createContact(workspaceId, contactData);
        }, loading);
    };
    const getClients = async(workspaceId, search, withCompanies) => {
        return apiRequest(async() => {
            clients.value = await contactModule.getClients(workspaceId, search, withCompanies);
            return clients.value;
        });
    };
    const getCompanies = async(workspaceId, search) => {
        return apiRequest(async() => {
            companies.value = await contactModule.getCompanies(workspaceId, search);
            return companies.value;
        });
    };
    const getContactById = async(workspaceId, id) => {
        return apiRequest(async() => {
            contact.value = await contactModule.getContactById(workspaceId, id);
            return contact.value;
        }, loading);
    };

    const updateContact = async(workspaceId, id, updateData) => {
        return apiRequest(async() => {
            return contactModule.updateContact(workspaceId, id, updateData);
        });
    };
    const deleteContact = async(workspaceId, id) => {
        return apiRequest(async() => {
            return contactModule.deleteContact(workspaceId, id);
        });
    };
    const getContacts = async (workspaceId, meta={}) => {
        return apiRequest(async() => {
            const result = await contactModule.getContacts(workspaceId, {
                limit: pagination.value.limit,
                offset: pagination.value.offset,
            }, {...filter.value});
            if (meta.fromPagination) {
                contacts.value.list = contacts.value.list.concat(result.list);
                contacts.value.next = result.next;
            } else {
                contacts.value = result;
            }
            return contacts.value;
        }, loading);
    };
    const setFilter = (filterData) => {
        filter.value = filterData;
    };

    return {
        filter,
        contact,
        clients,
        contacts,
        companies,
        pagination,
        setFilter,
        getClients,
        getContacts,
        getCompanies,
        updateContact,
        deleteContact,
        createContact,
        getContactById,
    };
}