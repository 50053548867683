import {ref} from 'vue';
import {apiRequest} from '@/modules/error_handler';
import directoriesModule from '@/modules/directories';
const directories = ref({
    grades: {
        list: [],
        total: undefined,
        next: undefined
    },
    groups: {
        list: [],
        total: undefined,
        next: undefined
    },
    hrSources: {
        list: [],
        total: undefined,
        next: undefined
    },
    paymentMethods: {
        list: [],
        total: undefined,
        next: undefined
    },
    roles: {
        list: [],
        total: undefined,
        next: undefined
    },
    skills: {
        list: [],
        total: undefined,
        next: undefined
    },
    specialMarks:  {
        list: [],
        total: undefined,
        next: undefined
    },
    contactGroups: {
        list: [],
        total: undefined,
        next: undefined
    },
    leadSources: {
        list: [],
        total: undefined,
        next: undefined
    },
    loyalty: {
        list: [],
        total: undefined,
        next: undefined
    },
    contactSpecialMarks: {
        list: [],
        total: undefined,
        next: undefined
    },
    serviceSpecialMarks: {
        list: [],
        total: undefined,
        next: undefined
    },
    documentTypes: {
        list: [],
        total: undefined,
        next: undefined
    },
    documentStatuses: {
        list: [],
        total: undefined,
        next: undefined
    },
    documentTransports: {
        list: [],
        total: undefined,
        next: undefined
    },
    companies: {
        list: [],
        total: undefined,
        next: undefined
    },
    bankAccounts: {
        list: [],
        total: undefined,
        next: undefined
    },
    billOperations: {
        list: [],
        total: undefined,
        next: undefined
    },
    serviceGroups: {
        list: [],
        total: undefined,
        next: undefined
    },
    serviceWorkStatuses: {
        list: [],
        total: undefined,
        next: undefined
    },
    billStatuses: {
        list: [],
        total: undefined,
        next: undefined
    },
    billDateStatuses: {
        list: [],
        total: undefined,
        next: undefined
    },
    billPaymentStatuses: {
        list: [],
        total: undefined,
        next: undefined
    },
    units: {
        list: [],
        total: undefined,
        next: undefined
    },
});
export function useDirectory(loading={value: false}) {
    const getDirectories = (workspaceId, folder) => {
        return apiRequest(async() => {
            const response = await directoriesModule.getDirectories(workspaceId, folder);
            for (const responseKey in response) {
                directories.value[responseKey] = response[responseKey];
            }
        }, loading);
    };
    return {
        directories,
        getDirectories
    };
}
