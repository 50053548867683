import moment from 'moment';
import {httpInstance} from '@/modules/http_factory';
import {arrayToString} from '@/modules/utils';

const getTasks = async (workspaceId, filter={}) => {
    const response = await httpInstance(workspaceId).get(`/tasks/workspace/${workspaceId}`, {
        params: {
            services: filter.services && Array.isArray(filter.services) && filter.services.length ? arrayToString(filter.services, true) : undefined,
            workspaceMembers: filter.workspaceMembers && Array.isArray(filter.workspaceMembers) && filter.workspaceMembers.length ? arrayToString(filter.workspaceMembers, true) : undefined,
            deadline: filter.deadline ? moment(filter.deadline).toISOString() : undefined,
            taskId: filter?.task?.id,
            delays: filter.delays && Array.isArray(filter.delays) && filter.delays.length ? filter.delays.join(',') : undefined,
        }
    });
    return response.data;
};

const getArchivedTasks = async (workspaceId, filter={}) => {
    const response = await httpInstance(workspaceId).get('/tasks/archived', {
        params: {
            workspaceId,
            limit: filter.limit,
            offset: filter.offset,
            services: filter.services && Array.isArray(filter.services) && filter.services.length ? arrayToString(filter.services, true) : undefined,
            workspaceMembers: filter.workspaceMembers && Array.isArray(filter.workspaceMembers) && filter.workspaceMembers.length ? arrayToString(filter.workspaceMembers, true) : undefined,
            deadline: filter.deadline ? moment(filter.deadline).toISOString() : undefined,
            taskId: filter?.task?.id,
            delays: filter.delays && Array.isArray(filter.delays) && filter.delays.length ? filter.delays.join(',') : undefined,
        }
    });
    return response.data;
};

const getTask = async (taskId, workspaceId) => {
    const response = await httpInstance(workspaceId).get(`/tasks/${taskId}`, );
    return response.data;
};
const getStats = async (workspaceId) => {
    const response = await httpInstance(workspaceId).get('/tasks/stats');
    return response.data;
};
const getTasksList = async (workspaceId, filter={}, pagination={}) => {
    const response = await httpInstance(workspaceId).get('/tasks', {
        params: {
            ...filter,
            ...pagination,
            workspaceId,
        }
    });
    return response.data;
};
const addTask = async (task) => {
    const response = await httpInstance(task.workspaceId).post('/tasks', task);
    return response.data;
};
const updateTask = async (taskId, updateData) => {
    const response = await httpInstance(updateData.workspaceId).patch(`/tasks/${taskId}`, updateData);
    return response.data;
};
const deleteTaskById = async (taskId, workspaceId) => {
    const response = await httpInstance(workspaceId).delete(`/tasks/${taskId}`);
    return response.data;
};
const updateOrders = async(ordersArr, workspaceId) => {
    const response = await httpInstance(workspaceId).post('/tasks/orders', {
        orders: ordersArr,
    });
    return response.data;
};
const getTaskStatuses = async(workspaceId) => {
    const response = await httpInstance(workspaceId).get(`/tasks/statuses/workspace/${workspaceId}`);
    return response.data;
};
const updateTaskStatus = async(workspaceId, id, updateData) => {
    const response = await httpInstance(workspaceId).patch(`/tasks/statuses/${id}`, updateData);
    return response.data;
};
const createTaskStatus = async(workspaceId, taskStatus) => {
    const response = await httpInstance(workspaceId).post('/tasks/statuses/', taskStatus);
    return response.data;
};
const deleteTaskStatus = async(workspaceId, id) => {
    const response = await httpInstance(workspaceId).delete(`/tasks/statuses/${id}`);
    return response.data;
};

const stopTask = async(id, payload, workspaceId) => {
    const response = await httpInstance(workspaceId).post(`/tasks/${id}/stop`, payload);
    return response.data;
};


const startTask = async(id, workspaceId) => {
    const response = await httpInstance(workspaceId).post(`/tasks/${id}/start`);
    return response.data;
};

const updateTaskStatusOrders = async(workspaceId, ordersArr) => {
    const response = await httpInstance(workspaceId).post('/tasks/statuses/orders', {
        orders: ordersArr,
    });
    return response.data;
};
const createTaskChecklistElement = async (workspaceId, checklistElement) => {
    const response = await httpInstance(workspaceId).post('/task-checklists', {
        ...checklistElement
    });
    return response.data;
};
const updateTaskChecklistElement = async (workspaceId, checklistElementId, updateData) => {
    const response = await httpInstance(workspaceId).patch(`/task-checklists/${checklistElementId}`, {
        ...updateData
    });
    return response.data;
};
const deleteTaskChecklistElement = async (workspaceId, checklistElementId) => {
    const response = await httpInstance(workspaceId).delete(`/task-checklists/${checklistElementId}`);
    return response.data;
};
export default {
    createTaskChecklistElement,
    updateTaskChecklistElement,
    deleteTaskChecklistElement,
    startTask,
    stopTask,
    getTask,
    getArchivedTasks,
    getStats,
    getTasks,
    getTasksList,
    addTask,
    updateTask,
    updateOrders,
    deleteTaskById,
    getTaskStatuses,
    updateTaskStatus,
    updateTaskStatusOrders,
    createTaskStatus,
    deleteTaskStatus,
};