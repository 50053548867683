<template lang="pug">
settings-directories-members-layout
    async-page
        skills-table
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import SkillsTable from '@/components/Directories/Members/Skills/Table';
import SettingsDirectoriesMembersLayout from '@/pages/Settings/Directories/Members/Layout';
export default {
    name: 'Skills',
    components: {SettingsDirectoriesMembersLayout, SkillsTable, AsyncPage}
};
</script>

<style scoped>

</style>