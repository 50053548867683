<template lang="pug">
.workspace-members-list.flex.items-center.w-full
    .workspace-members-list__item.w-full(
        v-for="member in members"
        :key="member.id"
    )
        members-list-item(:member="member")
            template(#footer)
                .flex.items-center.justify-between.mt-3
                    a-select.w-full(
                        placeholder="Пользователь"
                        @change="(e) => onUserChanged(e, member)"
                        :filter-option="false"
                        @search="onSearch"
                        show-search
                        :default-value="getDefaultValue(member)"
                    )
                        a-select-option(
                            v-for="user in users"
                            :key="user.id"
                        ) {{ user.name }}
</template>

<script>
import {useAuth} from '@/composition/auth';
import {useWorkspace} from '@/composition/workspace';
import {useIntegration} from '@/composition/integration';
import {useRoute} from 'vue-router';
import MembersListItem from '@/components/Workspace/MembersList/ListItem';

export default {
    name: 'IntegrationMembersListMapping',
    components: {MembersListItem},
    props: {
        members: Array,
        users: Array,
    },
    emits: ['search', 'sync'],
    setup(props, {emit}) {
        const {user} = useAuth();
        const route = useRoute();
        const {currentWorkspaceId} = useWorkspace();
        const {syncUser} = useIntegration();
        const isMe = (member) => {
            return user.value.id === member.id;
        };
        const getMemberStatusText = (member) => {
            if (!member.inviteAccepted) {
                return 'Участник еще не принял приглашение';
            }
            if (member.accessAllowed) {
                return 'Ок';
            }
            return 'Доступ запрещен';
        };
        const getMemberStatus = (member) => {
            if (!member.inviteAccepted) {
                // ожидаем входа участника
                return 'Finger';
            }
            if (member.accessAllowed) {
                return 'Ok';
            }
            return 'Minus';
        };
        const getDefaultValue = (member) => {
            return member?.integrationMember?.name;
        };
        const onUserChanged = async (mappingId, workspaceMember) => {
            const integrationId = route.params.integrationId;
            await syncUser(currentWorkspaceId.value, integrationId, workspaceMember.id, mappingId);
            emit('sync');
        };
        const onSearch = (q) => {
            emit('search', q);
        };
        return {
            isMe,
            getMemberStatus,
            getMemberStatusText,
            onUserChanged,
            onSearch,
            getDefaultValue
        };
    }
};
</script>

<style lang="scss" scoped>
.integration__user {

}
</style>