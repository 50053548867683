<template lang="pug">
.transaction-source-select
    a-radio-group(:value="source" @change="(e) => onChange(e)")
        a-radio(
            :value="transactionSource.with_bill"
        ) {{ transactionSourceMap.with_bill }}
        a-radio(
            :value="transactionSource.with_salary_payments_list"
        ) {{ transactionSourceMap.with_salary_payments_list }}
        a-radio(
            :value="transactionSource.other"
        ) {{ transactionSourceMap.other }}
</template>

<script>
export default {
    name: 'TransactionSourceSelect'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
import {transactionSource, transactionSourceMap} from '@/components/Transactions/constants';
/*===============INIT AREA===================*/
const props = defineProps({
    source: {
        type: String,
    }
});
const emit = defineEmits(['update:source']);
/*===============HANDLERS AREA===================*/
const onChange = (event) => {
    emit('update:source', event.target.value);
};
</script>

<style lang="scss">
</style>