<template lang="pug">
.uploader(@click="trigger")
    input.uploader-input(type="file" ref="file" name="file" @change="onFileChanged" )
    slot
    a-progress(:percent="loadingValue" :show-info="false" v-if="showProgress")
</template>

<script>
import {ref} from 'vue';
import {useUploader} from '@/composition/uploader';
export default {
    name: 'Uploader',
    props: {
        targetTypes: Array,
        isPrivate: Boolean
    },
    emits: ['upload'],
    setup(props, {emit}) {
        const loading = ref(false);
        const showProgress = ref(false);
        const loadingValue = ref(0);
        const file = ref(undefined);
        const {upload} = useUploader(loading);
        const progressHandler = (e) => {
            showProgress.value = true;
            loadingValue.value = Math.floor(Number(e.loaded) / Number(e.total) * 100);
            if (e.loaded >= e.total) {
                loadingValue.value = 100;
                setTimeout(() => {
                    showProgress.value = false;
                }, 1000);
            }
        };
        const onFileChanged = async(e) => {
            const file = e.target.files[0];
            const {ok, result} = await upload(file, props.targetTypes, progressHandler, props.isPrivate);
            if (ok) {
                emit('upload', {key: result});
            }
        };
        const trigger = () => {
            file.value.click();
        };

        return {
            onFileChanged,
            trigger,
            file,
            loadingValue,
            showProgress
        };
    }
};
</script>

<style lang="scss" scoped>
.uploader {
    cursor: pointer;
    &-input {
        display: none;
    }
}
</style>