import {httpInstance} from '@/modules/http_factory';
import {arrayToString} from '@/modules/utils';
import moment from 'moment';
import constants from '@/constants';

const formFilter = (filter, tz=constants.tz.default) => {
    let from;
    let to;
    const utcOffset = moment.utc().tz(tz).format('Z');
    if (filter.from.length === 10) {
        from = moment
            .utc(filter.from)
            .startOf('day')
            .tz(tz)
            .toISOString()
            .replace('Z', utcOffset);
    } else {
        from = filter.from;
    }
    if (filter.to.length === 10) {
        to = moment
            .utc(filter.to)
            .endOf('day')
            .tz(tz)
            .toISOString()
            .replace('Z', utcOffset);
    } else {
        to = filter.to;
    }
    return {
        performers: filter.performers && filter.performers.length ? arrayToString(filter.performers, true) : undefined,
        services: filter.services && filter.services.length ? arrayToString(filter.services, true) : undefined,
        contacts: filter.contacts && filter.contacts.length ? arrayToString(filter.contacts, true) : undefined,
        from: from,
        to: to,
        chartGroupBy: filter.chartGroupBy
    };
};


const saveReport = async (payload, filter, tz) => {
    const workspaceId = Number(payload.workspaceId);
    const response = await httpInstance(workspaceId).post('/reports', {
        title: payload.title,
        type: payload.type,
        filter: {
            ...formFilter(filter, tz),
            workspaceId,
        },
        workspaceId: workspaceId
    });
    return response.data;
};

const updateReport = async (payload, id, filter, tz) => {
    const workspaceId = Number(payload.workspaceId);
    const response = await httpInstance(workspaceId).patch(`/reports/${id}`, {
        title: payload.title,
        type:  payload.type,
        filter: {
            ...formFilter(filter, tz),
            workspaceId,
        },
        workspaceId: workspaceId
    });
    return response.data;
};

const getSavedReportByUUID = async (workspaceId, uuid, groupBy) => {
    const response = await httpInstance(workspaceId).get(`/reports/public/${uuid}`, {
        params: {
            groupBy: groupBy && groupBy.length ? arrayToString(groupBy) : undefined,
            workspaceId
        }
    });
    return response.data;
};
const getSavedReportById = async (workspaceId, id) => {
    const response = await httpInstance(workspaceId).get(`/reports/${id}`);
    return response.data;
};

const deleteSavedReport = async (workspaceId, id) => {
    const response = await httpInstance(workspaceId).delete(`/reports/${id}`);
    return response.data;
};

const getSavedReportsList = async (workspaceId, params, filter={}) => {
    const response = await httpInstance(workspaceId).get('/reports', {
        params: {
            ...params,
            workspaceId
        }
    });
    return response.data;
};

const getChartData = async (workspaceId, filter, tz) => {
    const response = await httpInstance(workspaceId).get('/reports/hourly/chart', {
        params: {
            ...formFilter(filter, tz),
            workspaceId
        }
    });
    return response.data;
};
const getTables = async (workspaceId, filter, groupBy, tz) => {
    const response = await httpInstance(workspaceId).get('/reports/hourly/tables', {
        params: {
            ...formFilter(filter, tz),
            workspaceId,
            groupBy: groupBy && groupBy.length ? arrayToString(groupBy) : undefined,
        }
    });
    return response.data;
};


export default {
    getChartData,
    getTables,
    saveReport,
    getSavedReportByUUID,
    getSavedReportById,
    getSavedReportsList,
    updateReport,
    deleteSavedReport
};