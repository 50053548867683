<template lang="pug">
.members-table
    a-button.mb-2(type="primary" @click="setModalVisibility(true)") Добавить
    members-filter.my-3(@filter="onFilterChanged" :tabs="tabs")
    custom-table(:columns="columns" :data-source="members.list" :onRowClick="redirectToCard")
        template(#fullName="{text, record}")
            router-link.members-table__title(v-if="record" :to="`/${currentWorkspaceId}/members/${record.id}/common`")
               div {{record.fullName}}
        template(#group="{text, record}")
            div(v-if="record") {{ record.group ? record.group.title : 'Не указана' }}
        template(#grade="{text, record}")
            div(v-if="record") {{ record.grade ? record.grade.title : 'Не указан' }}
        template(#skills="{text, record}")
            div(v-if="record") {{ getSkillsText(record) }}
        template(#status="{text, record}")
            div(v-if="record") {{ getMemberStatusText(record) }}
        template(#action="{record}")
            a-dropdown(:trigger="['click']" v-if="record.userId !== record.workspace.ownerId" @click.stop)
                .dots-wrapper
                    icon.cursor-pointer(type="Dots")
                template(#overlay)
                    a-menu
                        a-menu-item(key="0")
                            a-popconfirm(
                                v-if="record.accessAllowed"
                                title="Вы уверены, что хотите заблокировать пользователя?"
                                ok-text="Да"
                                cancel-text="Нет"
                                @confirm="onUpdate(record.id, 'accessAllowed', 0)"
                            )
                                div Заблокировать
                            a-popconfirm(
                                v-else
                                title="Вы уверены, что хотите разблокировать пользователя?"
                                ok-text="Да"
                                cancel-text="Нет"
                                @confirm="onUpdate(record.id, 'accessAllowed', 1)"
                            )
                                div Разблокировать
                        a-menu-item(key="1")
                            div(@click="edit(record)") Редактировать
                        a-menu-item(key="2")
                            delete-with-confirm(:deleteFunc="() => onDelete(record.id)" btnText="Удалить")
    a-button.mt-3(@click="next" v-if="members.next" :loading="loading") Показать еще
    a-modal(
        v-model:visible="showModal"
        title="Добавить пользователя"
        :footer="null"
        @cancel="setModalVisibility(false)"
        :destroyOnClose="true"
        style="top: 0"
        width="40%"
    )
        invite-user-form(
            :workspaceId="currentWorkspaceId"
            @update="putUpdate"
            :mode="currentMode"
            :default-value="editingElement"
            @ok="onInvite"
        )
</template>

<script>
import {columns} from '../constants';
import {ref} from 'vue';
import {useWorkspace} from '@/composition/workspace';
import EditableCell from '@/components/Generic/EditableCell';
import Icon from '@/components/Generic/Typography/Icon';
import MembersFilter from '@/components/Workspace/Member/Filter';
import InviteUserForm from '@/components/Workspace/InviteUserForm';
import {defaultRole} from '@/components/Directories/Members/Roles/constants';
import {useCreateOrUpdateModal} from '@/composition/create-update-modal';
import {useRouter} from 'vue-router';
import {useDirectory} from '@/composition/directory';
export default {
    name: 'MembersTable',
    components: {InviteUserForm, MembersFilter, Icon, EditableCell},
    async setup() {
        const {
            currentMode,
            editingValue: editingElement,
            showModal,
            setModalVisibility,
            edit
        } = useCreateOrUpdateModal();
        const router = useRouter();
        const loading = ref(false);
        const {
            tabs,
            members,
            pagination,
            currentWorkspaceId,
            setFilter,
            getMembers,
            getMemberStatusText,
            deleteWorkspaceMember,
            updateWorkspaceMember,
            getWorkspaceMembersTabs,
        } = useWorkspace(loading);
        const {getDirectories} = useDirectory(loading);
        await getDirectories(currentWorkspaceId.value);
        const onCreate = async () => {
            pagination.value.offset = 0;
            setModalVisibility(false);
            await getMembers(currentWorkspaceId.value);
            await getWorkspaceMembersTabs(currentWorkspaceId.value);
        };
        const onDelete = async (id) => {
            pagination.value.offset = 0;
            await deleteWorkspaceMember(id);
            await getMembers(currentWorkspaceId.value);
            await getWorkspaceMembersTabs(currentWorkspaceId.value);
        };
        const onUpdate = async (id, key, value) => {
            const {ok} = await updateWorkspaceMember(id, {[key]: value});
            if (ok) {
                pagination.value.offset = 0;
                await getMembers(currentWorkspaceId.value);
                await getWorkspaceMembersTabs(currentWorkspaceId.value);
            }
        };
        const putUpdate = async (data) => {
            const {id, ...updateData} = data;
            await updateWorkspaceMember(id, updateData);
            setModalVisibility(false);
            await getMembers(currentWorkspaceId.value);
            await getWorkspaceMembersTabs(currentWorkspaceId.value);
        };
        const next = async () => {
            pagination.value.offset += pagination.value.limit;
            await getMembers(currentWorkspaceId.value, {fromPagination: true});
            await getWorkspaceMembersTabs(currentWorkspaceId.value);
        };
        const getSkillsText = (record) => {
            return record.skills.map(x => x.skill.title).join(', ');
        };
        const onFilterChanged = async(filter) => {
            pagination.value.offset = 0;
            setFilter(filter);
            await getMembers(currentWorkspaceId.value, {fromPagination: false});
            await getWorkspaceMembersTabs(currentWorkspaceId.value);
        };
        const onInvite = async() => {
            pagination.value.offset = 0;
            await getMembers(currentWorkspaceId.value, {fromPagination: false});
            await getWorkspaceMembersTabs(currentWorkspaceId.value);
            setModalVisibility(false);
        };
        const redirectToCard = (item) => {
            router.push(`/${currentWorkspaceId.value}/members/${item.id}/common`);
        };
        return {
            tabs,
            members,
            loading,
            columns,
            showModal,
            currentMode,
            defaultRole,
            editingElement,
            currentWorkspaceId,
            next,
            edit,
            onCreate,
            onDelete,
            onUpdate,
            onInvite,
            putUpdate,
            getSkillsText,
            redirectToCard,
            onFilterChanged,
            setModalVisibility,
            getMemberStatusText,
        };
    }
};
</script>

<style lang="scss" scoped>

</style>
