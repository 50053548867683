<template lang="pug">
.task-card
    .task-card__main
        .flex.items-center.justify-between
            .flex.items-center.w-full
                .task-card__title(v-if="mode !== 'edit'") {{ task.title }}
                a-input.task-card__title(v-if="mode === 'edit'" v-model:value="task.title")
                .ml-3(v-if="task.integrationTask && task.integrationTask.src")
                    a(:href="task.integrationTask.src" target="_blank")
                        icon(type="ExternalLink")
            a-button(type="primary" @click="changeMode('edit')" v-if="mode !== 'edit'") Редактировать
        .task-card__status-field
            tasks-status-inline-changer(
                :status="task.status"
                :func="async (statusId) => await updateField('statusId', statusId)"
            )
                template(#title)
                    a-tag.cursor-pointer {{ task?.status?.title }}
            .ml-4 Создана: {{ formatDateTime(task.createdAt) }}
            .ml-4 Обновлена: {{ formatDateTime(task.updatedAt) }}
        custom-markdown.markdown.task-card__description(
            v-if="mode !== 'edit'"
            :source="task.description"
        )
        a-textarea.task-card__description(
            v-if="mode === 'edit'"
            v-model:value="task.description"
            :auto-size="{ minRows: 10 }"
        )
        .flex.justify-between.mt-4(v-if="mode === 'edit'")
            .flex.items-center
                a-button(@click="saveTask" type="primary" :loading="loading") Сохранить
                a-button.ml-3(@click="cancelEditing" :loading="loading") Отменить
            a-popconfirm(
                title="Вы уверены, что хотите удалить задачу?"
                ok-text="Да"
                cancel-text="Нет"
                @confirm="deleteTask"
            )
                a-button(type="danger" :loading="loading") Удалить
        task-card-check-list.mt-3(:task="task")
    .task-card__sidebar
        .task-card__sidebar-block
            card-title Исполнитель
            performer-inline-changer(
                :workspace-member="task.workspaceMember"
                show-full-name
                :func="async (workspaceMemberId) => await updateField('workspaceMemberId', workspaceMemberId)"
            )
        .task-card__sidebar-block
            number-inline-changer(
                :number="task.timing"
                :func="async(timing) => await updateField('timing', timing)"
                placeholder="Тайминг"
            )
                template(#title)
                    card-title Тайминг
                    .cursor-pointer
                        div {{ (task.totalTiming || 0) }} из {{ (task.timing || 0) }}ч.
                        status-progress(
                            :percent="getPercent(task.totalTiming, task.timing)"
                        )
        .task-card__sidebar-block
            card-title Дедлайн
            date-inline-changer(
                :date="task.deadline"
                :func="async (deadline) => await updateField('deadline', deadline)"
                placeholder="Дедлайн"
            )
        .task-card__sidebar-block
            card-title Проект
            div {{ task?.project?.title || 'Не указано'}}
        .task-card__sidebar-block
            card-title Услуга
            services-inline-changer(
                :service="task.service"
                :func="async (serviceId) => await updateField('serviceId', serviceId)"
            )
</template>
<script>
export default {
    name: 'TaskCard'
};
</script>
<script setup>

/*======================IMPORT AREA=====================*/

import {useRoute, useRouter} from 'vue-router';
import {useKanban} from '@/composition/kanban';
import {ref} from 'vue';
import {useWorkspace} from '@/composition/workspace';
import {useDate} from '@/composition/date';
import {getPercent} from '@/modules/utils';
import StatusProgress from '@/components/Generic/StatusProgress';
import SelectSuspense from '@/components/Generic/SelectSuspense';
import TaskStatusesSelect from '@/components/Tasks/StatusSelect';
import {useProject} from '@/composition/project';
import {useService} from '@/composition/service';
import CustomMarkdown from '@/components/Generic/Markdown';
import TaskCardCheckList from '@/components/Tasks/TaskCard/Checklist';
import ServicesInlineChanger from '@/components/Services/InlineChanger';
import TasksPerformerInlineChanger from '@/components/Tasks/PerformerInlineChanger';
import DateInlineChanger from '@/components/Generic/InlineChanger/DateInlineChanger';
import NumberInlineChanger from '@/components/Generic/InlineChanger/NumberInlineChanger';
import TasksStatusInlineChanger from '@/components/Tasks/StatusInlineChanger';
import PerformerInlineChanger from '@/components/Tasks/PerformerInlineChanger';

/*======================INITIALIZATION AREA=====================*/

const loading = ref(false);
const route = useRoute();
const router = useRouter();
const taskId = route.params.taskId;
const {getTask, task, updateTask, deleteTaskById, getTaskStatuses} = useKanban(loading);
const {currentWorkspaceId, getMembers} = useWorkspace(loading);
const {getProjects} = useProject(loading);
const {getServices} = useService(loading);
const {formatDateTime} = useDate();

/*======================FETCHING DATA AREA=====================*/

const initialFetchData = async () => {
    await getTaskStatuses(currentWorkspaceId.value);
    await getMembers(currentWorkspaceId.value);
    await getProjects(currentWorkspaceId.value);
    await getServices(currentWorkspaceId.value);
    await getTask(taskId, currentWorkspaceId.value);
};
await initialFetchData();

/*======================MODE VISIBILITY AREA=====================*/

const mode = ref('read');
const fields = ref({
    workspaceMemberId: 'read',
    deadline: 'read',
    timing: 'read',
    statusId: 'read',
    projectId: 'read',
    serviceId: 'read',
});
const changeMode = (newMode, field) => {
    if (field) {
        fields.value[field] = newMode;
    } else {
        mode.value = newMode;
    }
};

/*======================HANDLERS AREA=====================*/
const onMarkdownClick = (e) => {
    console.log(e);
};
/*======================CRUD METHODS AREA=====================*/

const saveTask = async () => {
    await updateTask(taskId, {
        title: task.value.title,
        description: task.value.description,
        workspaceId: currentWorkspaceId.value,
    });
    await getTask(taskId, currentWorkspaceId.value);
    changeMode('read');
};
const cancelEditing = async () => {
    await getTask(taskId, currentWorkspaceId.value);
    changeMode('read');
};
const updateField = async (field, value) => {
    await updateTask(taskId, {
        [field]: value === undefined ? null : value,
        workspaceId: currentWorkspaceId.value,
    });
    await getTask(taskId, currentWorkspaceId.value);
    changeMode('read', field);
};
const deleteTask = async () => {
    await deleteTaskById(taskId, currentWorkspaceId.value);
    await router.push(`/${currentWorkspaceId.value}/tasks`);
};
</script>

<style lang="scss" scoped>
.task-card {
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-gap: 16px;

    &__title {
        font-size: 32px;
        font-weight: 500;
        color: #3c495a;
    }

    &__sidebar {
        background: #fafafa;
        height: 100%;
        min-height: 100vh;
        padding: 24px;
        position: fixed;
        width: 300px;
        right: 0;
    }

    &__sidebar-block {
        margin-bottom: 24px;
    }

    &__status-field {
        margin-top: 12px;
        display: flex;
        align-items: center;
    }

    &__description {
        margin-top: 24px;
        white-space: pre-wrap;
    }
}
</style>