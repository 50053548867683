<template lang="pug">
.incomings-and-outgoings-values-list
    incomings-and-outgoings-value-link(
        :quarter="quarter"
        :month="month"
        :workspace-id="workspaceId"
        :sub-category-id="null"
        :total="total"
        :unit="unit"
        :year="year"
        :type="type"
    )
    .incomings-and-outgoings-values-list__items(v-for="subCategory in subCategories")
        incomings-and-outgoings-value-link(
            :quarter="quarter"
            :month="month"
            :workspace-id="workspaceId"
            :sub-category-id="subCategory.id"
            :total="subCategory.value"
            :unit="subCategory.unit"
            :year="year"
            :key="subCategory.id"
            :type="type"
        )
</template>

<script>
import constants from '@/constants';
import {formatCurrency, isNullOrUndefined} from '@/modules/utils';
import {useDate} from '@/composition/date';
import {monthsMap, quartersMap} from '@/components/Analytics/IncomingsAndOutgoings/constants';
import IncomingsAndOutgoingsValueLink from '@/components/Analytics/IncomingsAndOutgoings/ValuesList/Link';
export default {
    name: 'IncomingsAndOutgoingsValuesList',
    components: {IncomingsAndOutgoingsValueLink},
    props: {
        subCategories: {
            type: Array,
        },
        total: {
            type: Number
        },
        unit: {
            type: String
        },
        month: {
            type: String,
        },
        quarter: {
            type: String,
        },
        type: {
            type: String,
        },
        year: {
            type: Number,
        },
        workspaceId: {
            type: [Number, String],
        }
    },
};
</script>

<style lang="scss" scoped>
.incomings-and-outgoings-values-list {
    font-weight: 600;
}
</style>
