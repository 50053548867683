<template lang="pug">
.flex.mb-3
    a-button.mr-2(@click="showMyTasks" type="primary" v-if="!hide_my_tasks") Только мои задачи
    a-button.mr-2(@click="setModalVisibilityWrapper(true)" type="primary") Добавить задачу
    tasks-filter(
        v-model:filter="filter"
    )
.kanban.mt-3.tasks_kanban(:style="columns" ref="kanbanContainer")
    .kanban__item(v-for="([key, data]) in tasks" :class="{'archive-column': isArchiveColumnCollapsed(key)}")
        .flex.items-center.kanban__title
            .mr-3(@click="collapseColumn(key)") {{ statusMap[key] }}
            .kanban__add-task(@click="setModalVisibilityWrapper(true, key)" v-if="!isColumnArchive(key)")
                icon(type="Plus")
        draggable(
            v-if="!isArchiveColumnCollapsed(key)"
            class="column"
            :list="data"
            group="kanban"
            item-key="id"
            ghost-class="ghost"
            @change="(e) => updateStatus(e, key)"
        )
            template(#item="{element}")
                task-item(
                    :id="element.id"
                    :title="element.fullTitle"
                    :service="element.service",
                    :assignee="element.workspaceMember"
                    :workspaceId="element.workspaceId"
                    :deadline="element.deadline"
                    :integrationTask="element.integrationTask"
                    :timing="element.timing"
                    :status-id="element.statusId"
                    :active-log="element.activeLog"
                    :total-timing="element.totalTiming"
                    :total-timing-in-seconds="element.totalTimingInSeconds"
                    :is-active="element.isActive"
                    :is-my-task="element.workspaceMemberId === user.workspaceMemberId"
                    @edit="goToCard(element)"
                    @update="onTaskUpdated(element.id, $event)"
                    @start="onStart"
                    @stop="onStop"
                    :loading="taskLoading[element.id]"
                    :statuses="taskStatuses"
                )
        a-button(v-if="archivedTasksList.next && !isArchiveColumnCollapsed(key) && isColumnArchive(key)" @click="getNextArchived") Еще
    a-modal(
        v-model:visible="showAddModal"
        title="Создать задачу"
        :footer="null"
        @cancel="setModalVisibilityWrapper(false, undefined)"
        :destroyOnClose="true"
    )
        add-task-form(
            :workspaceId="currentWorkspaceId"
            @ok="onTaskAdded"
            :status="selectedStatus"
            @update="putUpdate"
            :mode="currentMode"
            :default-value="editingValue"
        )
</template>
<script>
export default {
    name: 'Kanban',
    inheritAttrs: false,
    customOptions: {}
};
</script>
<script setup>
/*======================IMPORT AREA=====================*/
import TaskItem from '@/components/Tasks/TaskItem';
import {useAuth} from '@/composition/auth';
import draggable from 'vuedraggable';
import {nextTick, onBeforeUnmount, reactive, ref, watch, computed} from 'vue';
import {useKanban} from '@/composition/kanban';
import {useWorkspace} from '@/composition/workspace';
import AddTaskForm from '@/components/Tasks/AddTaskForm';
import TasksFilter from '@/components/Tasks/Filter';
import {useCreateOrUpdateModal} from '@/composition/create-update-modal';
import {useRoute, useRouter} from 'vue-router';
import {useService} from '@/composition/service';
import {useProject} from '@/composition/project';
import {useTimelog} from '@/composition/timelog';
import CreateTimeLogForm from '@/components/TimeLogs/CreateForm';
import moment from 'moment';
import {useDate} from '@/composition/date';
import constants from '@/constants';
import {useRole} from '@/composition/role';
import {useLayout} from '@/composition/layout';
const defaultTz = constants.tz.default;

/*======================INITIALIZATION AREA=====================*/
const {user} = useAuth();
const router = useRouter();
const route = useRoute();
const loading = ref(false);
const taskLoading = reactive({});
const kanbanContainer = ref(null);
const {sections, getDefaults} = useRole(user.value);
const {hide_my_tasks, actual_services} = getDefaults(sections.tasks.name, sections.tasks.filters.name);
const {
    setModalVisibility,
    showModal: showAddModal,
    edit,
    currentMode,
    editingValue
} = useCreateOrUpdateModal();

const {
    currentWorkspaceId,
    getWorkspaceById,
    currentWorkspace,
    getMembers,
    currentWorkspaceTz
} = useWorkspace(loading);
const {getServices, setFilter: setServiceFilter} = useService(loading);
const {getProjects} = useProject(loading);
const {me} = useAuth(loading);
const {getHoursAndMinutesFromMs} = useDate();
const {sidebarWidth} = useLayout();
const collapsed = ref(true);

const {
    tasks,
    archivedTasksList,
    getArchivedTasks,
    taskStatuses,
    pagination,
    getTasks,
    getStats,
    getTaskStatuses,
    getTasksList,
    updateTask,
    updateOrders,
    statusMap,
    filter,
    fromQueryParams,
    toQueryParams,
    startTask,
    stopTask,
    activeTask
} = useKanban(loading);

const {
    getDailyTotal,
    updateTimeLog,
    deleteTimeLog,
    setUncommittedLog,
    setUncommittedLogModalVisibility,
    nextLoggingTaskId,
    setNextLoggingTaskId,
} = useTimelog(loading);

/*======================INITIALIZE FILTERS AREA=====================*/

filter.value = fromQueryParams(route.query);
const init = ref(false);
watch(filter, () => {
    if (init.value) {
        onFilterChanged();
    }
});

/*======================FETCHING DATA AREA=====================*/
const getArchivedTasksWrapper = async (fromPagination = false) => {
    if (!fromPagination) pagination.value.offset = 0;
    if (!collapsed.value) {
        await getArchivedTasks(currentWorkspaceId.value, {fromPagination});
        const archiveStatus = getArchiveStatus();
        const index = tasks.value.findIndex(x => x[0] === archiveStatus.id);
        if (index === -1) return false;
        tasks.value[index] = [archiveStatus.id, archivedTasksList.value.list];
    }
};

const initialFetchData = async () => {
    const workspaceMemberId = user.value?.workspaceMemberId;
    if (workspaceMemberId) {
        filter.value = {
            ...filter.value,
            workspaceMembers: [{id: workspaceMemberId}]
        };
    }
    if (actual_services) {
        setServiceFilter({
            onlyActual: true,
        });
    }
    await getTasks(currentWorkspaceId.value);
    await getWorkspaceById(currentWorkspaceId.value);
    await getMembers(currentWorkspaceId.value, {
        includeTasks: 1
    });
    await getTasksList(currentWorkspaceId.value);
    await getTaskStatuses(currentWorkspaceId.value);
    await getProjects(currentWorkspaceId.value);
    await getStats(currentWorkspaceId.value);
    await getArchivedTasksWrapper();
    init.value = true;
};
await initialFetchData();


const getNextArchived = async () => {
    pagination.value.offset += pagination.value.limit;
    await getArchivedTasksWrapper(true);
};

/*======================KANBAN GRID GENERATOR AREA=====================*/

const columns = computed(() => {
    let layout = 'grid-template-columns:';
    console.log(tasks.value);
    for (const col of tasks.value) {
        const [statusId] = col;
        console.log(statusId);
        if (isColumnArchive(statusId) && collapsed.value) {
            layout += ' 50px';
        } else {
            layout += ' 400px';
        }
    }
    return layout;
});

/*======================ADD TASK MODAL VISIBILITY AREA=====================*/

const selectedStatus = ref(undefined);
const setModalVisibilityWrapper = (bool, statusId) => {
    if (!statusId) {
        const inProgressStatus = taskStatuses.value.find(x => x.isCurrentlyActual);
        statusId = inProgressStatus?.id;
    }
    setModalVisibility(bool);
    selectedStatus.value = taskStatuses.value.find(x => x.id == statusId);
};
const onTaskAdded = () => {
    setModalVisibilityWrapper(false, undefined);
    getTasks(currentWorkspaceId.value);
    getStats(currentWorkspaceId.value);
    getArchivedTasksWrapper();
};

/*======================FILTERING AREA=====================*/

const onFilterChanged = async () => {
    await getTasks(currentWorkspaceId.value);
    getArchivedTasksWrapper();
    await router.push({query: toQueryParams(filter.value)});
};
const showMyTasks = async () => {
    const workspaces = user.value?.workspaces;
    if (!workspaces) return;
    const me = workspaces.find(x => x.workspaceId === Number(currentWorkspaceId.value));
    if (me) {
        filter.value = {
            ...filter.value,
            workspaceMembers: [{id: me.id}]
        };
        await onFilterChanged();
        getArchivedTasksWrapper();
    }
};

const getArchiveStatus = () => {
    return taskStatuses.value.find(x => x.isArchive);
};


const collapseColumn = (key) => {
    if (isColumnArchive(key)) {
        collapsed.value = !collapsed.value;
    }
};

const isColumnArchive = (statusId) => {
    const status = taskStatuses.value.find(x => x.id === statusId);
    return !!(status && status.isArchive);

};

const isArchiveColumnCollapsed = (statusId) => {
    return !!(collapsed.value && isColumnArchive(statusId));

};

watch(collapsed, () => {
    if (!collapsed.value) {
        nextTick(() => {
            kanbanContainer.value.scrollLeft = kanbanContainer.value.scrollWidth;
            getArchivedTasksWrapper();
        });
    }
});

/*======================UPDATING AREA=====================*/

const updateStatus = async (e, statusId) => {
    const taskColumn = tasks.value.find(x => x[0] === statusId);
    if (!taskColumn) return;
    const [, data] = taskColumn;
    const ordersArr = data.map((x, index) => ({id: x.id, order: index}));
    if (e.added) {
        await updateTask(e.added.element.id, {statusId, workspaceId: currentWorkspaceId.value});
    }
    if (e.moved || e.added) {
        await updateOrders(ordersArr, currentWorkspaceId.value);
    }
    await getMembers(currentWorkspaceId.value);
    await getStats(currentWorkspaceId.value);
    getArchivedTasksWrapper();
};
const putUpdate = async (updateData) => {
    await updateTask(editingValue.value.id, {
        ...updateData,
        workspaceId: currentWorkspaceId.value,
    });
    await setModalVisibilityWrapper(false, undefined);
    await getTasks(currentWorkspaceId.value);
    await getMembers(currentWorkspaceId.value);
    await getStats(currentWorkspaceId.value);
    getArchivedTasksWrapper();
};

const onTaskUpdated = async (id, updateData) => {
    await updateTask(id, {
        ...updateData,
        workspaceId: currentWorkspaceId.value
    });
    await getTasks(currentWorkspaceId.value);
    await getMembers(currentWorkspaceId.value);
    await getStats(currentWorkspaceId.value);
    getArchivedTasksWrapper();
};
const onStart = async (id) => {
    taskLoading[id] = true;
    if (user.value?.activeTask?.id) {
        await onStop({id: user.value?.activeTask?.id});
        setNextLoggingTaskId(id);
        taskLoading[id] = false;
        return;
    }
    setNextLoggingTaskId(null);
    await startTask(id, currentWorkspaceId.value);
    await getTasks(currentWorkspaceId.value);
    taskLoading[id] = false;
    await getStats(currentWorkspaceId.value);
    await me(currentWorkspaceId.value);
    getArchivedTasksWrapper();
};
const onStop = async ({id, comment}) => {
    taskLoading[id] = true;
    const {ok, result: timeLog} = await stopTask(id, {comment}, currentWorkspaceId.value);
    taskLoading[id] = false;
    if (ok) {
        setUncommittedLogModalVisibility(true);
        setUncommittedLog({
            ...timeLog,
            startDate: moment.utc(timeLog.startDate).tz(currentWorkspaceTz.value || defaultTz),
            startTime: {
                hours: moment.utc(timeLog.startDate).tz(currentWorkspaceTz.value || defaultTz).get('hours'),
                minutes: moment.utc(timeLog.startDate).tz(currentWorkspaceTz.value || defaultTz).get('minutes'),
                seconds: moment.utc(timeLog.startDate).tz(currentWorkspaceTz.value || defaultTz).get('seconds'),
            },
            duration: getHoursAndMinutesFromMs(timeLog.durationInMs)
        });
        await getStats(currentWorkspaceId.value);
        await getTasks(currentWorkspaceId.value);
        await getDailyTotal(currentWorkspaceId.value);
        await me(currentWorkspaceId.value);
        getArchivedTasksWrapper();
    }
};
/*=====================TIME LOGS AREA==========================*/
const onTimeLogUpdate = async (updateData) => {
    const {id, ...payload} = updateData;
    const {ok} = await updateTimeLog(currentWorkspaceId.value, id, payload);
    if (ok) {
        // setEditTimeLogModalVisibility(false);
        await getStats(currentWorkspaceId.value);
        await getTasks(currentWorkspaceId.value);
        await getDailyTotal(currentWorkspaceId.value);
        await me(currentWorkspaceId.value);
        getArchivedTasksWrapper();
    }
};

const onTimeLogContinue = async (timeLog) => {
    const {ok} = await startTask(timeLog.taskId, currentWorkspaceId.value);
    if (ok) {
        // setEditTimeLogModalVisibility(false);
        await getStats(currentWorkspaceId.value);
        await getTasks(currentWorkspaceId.value);
        await getDailyTotal(currentWorkspaceId.value);
        await me(currentWorkspaceId.value);
    }
};

const onTimeLogDelete = async (timeLog) => {
    const {ok} = await deleteTimeLog(currentWorkspaceId.value, timeLog.id);
    if (ok) {
        // setEditTimeLogModalVisibility(false);
        await getStats(currentWorkspaceId.value);
        await getTasks(currentWorkspaceId.value);
        await getDailyTotal(currentWorkspaceId.value);
        await me(currentWorkspaceId.value);
    }
};
/*======================ROUTING AREA=====================*/

const goToCard = async (element) => {
    await router.push(`/${element.workspaceId}/tasks/${element.id}`);
};

/*======================LIFECYCLE HOOKS AREA=====================*/
onBeforeUnmount(() => {
    setServiceFilter({});
});
</script>

<style lang="scss" scoped>
.column {
    display: block;
    min-height: 80%;
}

.archive-column {
    width: 50px;

    .kanban__title {
        transform: rotateZ(90deg);
        cursor: pointer;
    }
}
</style>
