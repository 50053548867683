<template lang="pug">
.page.workspace-wrapper
    a-tabs(v-model:activeKey="activeKey" @change="onChange")
        a-tab-pane(v-for="tab in tabs" :key="tab.key" :tab="tab.tab")
    slot
</template>

<script>
import {onMounted, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useWorkspace} from '@/composition/workspace';
import PageTitle from '@/components/Generic/PageTitle';

export default {
    name: 'MemberLayout',
    components: {PageTitle},
    setup() {
        const activeKey = ref(undefined);
        const {currentWorkspaceId, getWorkspaceById} = useWorkspace();
        const router = useRouter();
        const route = useRoute();
        const tabs = [
            {
                key: 'common',
                tab: 'Основная информация'
            },
            {
                key: 'documents',
                tab: 'Документы'
            },
        ];
        const onChange = (val) => {
            router.push(`/${currentWorkspaceId.value}/members/${route.params.workspaceMemberId}/${val}`);
        };
        onMounted(async() => {
            const paths = route.path.split('/');
            activeKey.value = paths[paths.length - 1];
            await getWorkspaceById(route.params.id);
        });
        return {
            activeKey,
            onChange,
            tabs,
        };
    }
};
</script>

<style scoped>

</style>