import TypographyTitle from '@/components/Generic/Typography/Title';
import Icon from '@/components/Generic/Typography/Icon';
import UserAvatar from '@/components/Generic/Typography/Avatar';
import CardTitle from '@/components/Generic/Typography/CardTitle';
import InputWithError from '@/components/Generic/InputWithError';
import DeleteWithConfirm from '@/components/Generic/DeleteWithConfirm';
import Gravatar from '@/components/Generic/Gravatar';
import CustomTable from '@/components/Generic/CustomTable';
export const useComponents = (app) => {
    app.component('CustomTable', CustomTable);
    app.component('UserAvatar', UserAvatar);
    app.component('TypographyTitle', TypographyTitle);
    app.component('InputWithError', InputWithError);
    app.component('DeleteWithConfirm', DeleteWithConfirm);
    app.component('Icon', Icon);
    app.component('Gravatar', Gravatar);
    app.component('CardTitle', CardTitle);
};