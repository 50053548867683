<template lang="pug">
#chart(ref="chartRef")
</template>

<script>
import ApexCharts from 'apexcharts';
import {ref, onMounted, watch} from 'vue';
import {formatCurrency} from '@/modules/utils';

export default {
    name: 'LineChart',
    props: {
        data: Array,
        loading: Boolean,
        seriesName: {
            type: String,
            default: '',
        },
        dashPosition: {
            type: Number,
            required: false,
        },
    },
    setup(props) {
        const options = ref({
            chart: {
                type: 'line',
                background: '#f8f8f9',
                height: 300,
                fontFamily: '\'Roboto\', sans-serif',
            },
            stroke: {
                curve: 'smooth',
                colors: ['#5b49a5'],
                width: 3,
            },
            grid: {
                borderColor: '#f0f0f2',
                borderWidth: 10,
                xaxis: {
                    lines: {
                        show: false,
                    },
                },
            },
            series: [{
                name: props.seriesName,
                data: [],
            }],
            yaxis: {
                labels: {
                    formatter: function(value) {
                        return formatCurrency(value);
                    },
                },
            },
            xaxis: {
                categories: [],
                type: 'datetime',
            },
            forecastDataPoints: {
                count: props.dashPosition || 0,
            },
            noData: {
                text: 'Нет данных...',
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: undefined,
                    fontSize: '14px',
                    fontFamily: undefined,
                },
            },
        });
        const chart = ref(null);
        const chartRef = ref(null);
        const updateData = () => {
            const xaxis = props.data.map(x => x.date);
            chart.value.updateOptions({
                series: [{
                    name: props.seriesName,
                    data: props.data.map(x => x.value),
                }],
                xaxis: {
                    categories: xaxis,
                    type: 'datetime',
                    labels: {
                        datetimeFormatter: {
                            year: 'yyyy',
                            month: 'MM.yyyy',
                            day: 'dd.MM.yyyy',
                            hour: 'HH:mm',
                        },
                    },
                },

            });
            options.value.xaxis.categories = xaxis;
        };
        watch(() => props.data, () => {
            updateData();
        }, {deep: true});
        onMounted(() => {
            chart.value = new ApexCharts(chartRef.value, options.value);
            chart.value.render();
            updateData();
        });
        return {
            chartRef,
        };
    },
};
</script>

<style scoped>

</style>
