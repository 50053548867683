<template lang="pug">
.bills-select.w-full
    a-select(
        :value="modelValue"
        style="width: 100%"
        :placeholder="placeholder"
        :filter-option="false"
        @change="onChange($event)"
        @search="onSearch($event)"
        show-search
        :mode="mode"
    )
        a-select-option(
            v-for="bill in bills.list"
            :key="bill.id"
            :value="bill.id"
        )
            | {{ bill.number }} от {{ formatDate(bill.date) }} - {{ getBillTitle(bill) }} - {{ bill?.cost }}
</template>

<script>
import {useWorkspace} from '@/composition/workspace';
import {debounce} from '@/modules/utils';
import {useBill} from '@/composition/bill';
import {useDate} from '@/composition/date';
import {onMounted, watch} from 'vue';
export default {
    name: 'BillsSelect',
    props: {
        modelValue: [Number, String],
        placeholder: String,
        mode: {
            type: String,
            default: ''
        }
    },
    emits: ['update:modelValue', 'init', 'update:fullObject'],
    setup(props, {emit}) {
        const {currentWorkspaceId} = useWorkspace();
        const {bills, getBills, setFilter} = useBill();
        const {formatDate} = useDate();
        watch(() => props.modelValue, () => {
            if (props.modelValue) {
                onChange(props.modelValue);
            }
        });
        onMounted(() => {
            emit('init');
        });
        const onSearch = async (q) => {
            setFilter({search: q});
            debounce(() => getBills(currentWorkspaceId.value, q, props.withCompanies), 500)();
        };
        const onChange = (id) => {
            emit('update:modelValue', id);
            emit('update:fullObject', bills.value.list.find(x => x.id === id));
        };
        const getBillTitle = (bill) => {
            if (bill?.customer?.contactGroup?.key === 'company' && bill?.performer?.contactGroup?.key !== 'company') {
                return bill?.performer?.title;

            } else if (bill?.performer?.contactGroup?.key === 'company' && bill?.customer?.contactGroup?.key !== 'company') {
                return bill?.customer?.title;
            }
        };
        return {
            bills,
            onSearch,
            onChange,
            formatDate,
            getBillTitle,
        };
    }
};
</script>

<style scoped>

</style>