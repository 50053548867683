<template lang="pug">
settings-directories-layout
    .directories-layout
        a-tabs(v-model:activeKey="activeKey" @change="onChange" tabPosition="left" type="card")
            a-tab-pane(v-for="tab in tabs" :key="tab.key" :tab="tab.tab")
        slot
</template>

<script>
import SettingsLayout from '@/pages/Settings/Layout';
import {onMounted, ref} from 'vue';
import {useWorkspace} from '@/composition/workspace';
import {useRoute, useRouter} from 'vue-router';
import SettingsDirectoriesLayout from '@/pages/Settings/Directories/Layout';
export default {
    name: 'SettingsDirectoriesMembersLayout',
    components: {SettingsDirectoriesLayout, SettingsLayout},
    setup() {
        const activeKey = ref(undefined);
        const {currentWorkspaceId} = useWorkspace();
        const router = useRouter();
        const route = useRoute();
        const tabs = [
            {
                key: 'skills',
                tab: 'Навыки'
            },
            {
                key: 'roles',
                tab: 'Роли'
            },
            {
                key: 'grades',
                tab: 'Грейды'
            },
            {
                key: 'groups',
                tab: 'Группы'
            },
            {
                key: 'hr-sources',
                tab: 'HR источники'
            },
            {
                key: 'payment-methods',
                tab: 'Способы оплаты'
            },
            {
                key: 'special-marks',
                tab: 'Особые метки'
            },
        ];
        const onChange = (val) => {
            router.push(`/${currentWorkspaceId.value}/settings/directories/members/${val}`);
        };
        onMounted(async () => {
            tabs.forEach(x => {
                if (route.path.indexOf(x.key) !== -1) {
                    activeKey.value = x.key;
                }
            });
        });
        return {
            onChange,
            tabs,
            activeKey,
        };
    }
};
</script>

<style lang="scss" scoped>
.directories-layout {
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-gap: 20px;
}
</style>