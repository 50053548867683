<template lang="pug">
.member-profile
    card-title Профиль
    .member-profile__body.mt-2
        uploader(@upload="onUpload" :targetTypes="['png', 'jpg', 'jpeg']")
            .member-profile__avatar
                a-avatar(:src="member.avatarPic" :size="64") {{ member.fullName ? member.fullName.slice(0, 1) : member.user.email.slice(0, 1) }}
        .member-profile__contacts.ml-5
            .member-profile__contact
                .flex.items-center Телеграм
                editable-cell(:initialValue="member.telegram" @update="(e) => onUpdate('telegram', e)" :title="getTelegramText()" :loading="loading")
            .member-profile__contact
                .flex.items-center Skype
                editable-cell(:initialValue="member.skype" @update="(e) => onUpdate('skype', e)" :title="getSkypeText()" :loading="loading")
            .member-profile__contact
                .flex.items-center Телефон
                editable-cell(:initialValue="member.phone" @update="(e) => onUpdate('phone', e)" :title="getPhoneText()" :loading="loading")
</template>

<script>
import CardTitle from '@/components/Generic/Typography/CardTitle';
import EditableCell from '@/components/Generic/EditableCell';
import Uploader from '@/components/Generic/Uploader';
export default {
    name: 'MemberProfile',
    components: {Uploader, EditableCell, CardTitle},
    props: {
        member: Object,
        loading: Boolean
    },
    emits: ['update'],
    setup(props, {emit}) {
        const onUpdate = (key, value) => {
            emit('update', {[key]: value});
        };
        const getTelegramText = () => props.member?.telegram || 'Не указано';
        const getSkypeText = () => props.member?.skype || 'Не указано';
        const getPhoneText = () => props.member?.phone || 'Не указано';
        const onUpload = async (data) => {
            emit('update', {avatar: data.key});
        };
        return {
            onUpdate,
            getTelegramText,
            getSkypeText,
            getPhoneText,
            onUpload,
        };
    }
};
</script>

<style lang="scss" scoped>
.member-profile {
    &__contact {
        display: grid;
        grid-template-columns: 100px 400px;
        grid-gap: 15px;
    }
    &__body {
        display: flex;
        align-items: center;
    }
}
</style>