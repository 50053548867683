export const columns = [
    {
        title: 'Номер и дата',
        key: 'number',
        slots: {customRender: 'number'},
        width: '34%',
    },
    {
        title: 'Стоимость, руб.',
        key: 'amount',
        slots: {customRender: 'amount'},
        width: '33%',
        summarize: true,
    },
    {
        title: 'Исполнить до',
        key: 'date',
        slots: {customRender: 'date'},
        width: '20%'
    },
    {
        title: 'Статус',
        key: 'status',
        slots: {customRender: 'status'},
        width: '20%'
    },
];