import {Cast} from '@/modules/cast';

const schema = {
    id: {
        type: 'number',
    },
    customer: {
        type: 'object',
    },
    performer: {
        type: 'object',
    },
    documentTypeId: {
        type: 'number',
    },
    services: {
        type: 'array',
    },
    customerId: {
        type: 'number',
    },
    performerId: {
        type: 'number',
    },
    sum: {
        type: 'number',
        nullable: true
    },
    currency: {
        type: 'string',
        nullable: true
    },
    documentUrl: {
        type: 'string',
        nullable: true
    },
    transportId: {
        type: 'number',
        nullable: true
    },
    directorId: {
        type: 'number',
    },
    baseDocument: {
        type: 'object',
        nullable: true
    },
    baseDocumentId: {
        type: 'number',
        nullable: true
    },
    workspaceId: {
        type: 'number',
    },
    deadline: {
        type: 'string',
    },
    statusId: {
        type: 'number',
    },
    number: {
        type: 'string',
    },
    createdAt: {
        type: 'string',
    },
    updatedAt: {
        type: 'string',
    },
    documentType: {
        type: 'object',
    },
    sourceType: {
        type: 'string',
    },
    documentStatus: {
        type: 'object',
    },
    documentTransport: {
        type: 'object',
    },
    director: {
        type: 'object',
    },
    isArchived: {
        type: 'boolean',
        nullable: true
    },
    date: {
        type: 'string',
    },
    priceDisabled: {
        type: 'boolean',
        nullable: true
    },
    createdBy: {
        type: 'object',
        nullable: true
    },
};

export class Document extends Cast {
    constructor(doc) {
        super();
        for (const key in schema) {
            Document.validate(key, schema[key], doc[key]);
            this[key] = doc[key];
        }
    }
    static validate(key, schemaElement, element) {
        return Cast.validate(Document.name, key, schemaElement, element);
    }
    toObject() {
        return super.toObject(schema);
    }
    static is(doc) {
        return Cast.is(doc, schema);
    }
}