<template lang="pug">
.member-finance
    card-title Финансы
    .member-finance__column.mt-2
        .flex.items-center Часовая ставка
        editable-cell(:initialValue="member.hourlyRate" @update="(e) => onUpdate('hourlyRate', e)" :title="getHourlyRateText()")
    .member-finance__column
        .flex.items-center Валюта
        editable-cell(
            :initialValue="member.currency"
            @update="(e) => onUpdate('currency', e)"
            :title="getCurrencyText()"
            :options="getCurrencyOptions()"
            type="select"
        )
</template>

<script>
import CardTitle from '@/components/Generic/Typography/CardTitle';
import constants from '@/constants';
import EditableCell from '@/components/Generic/EditableCell';
export default {
    name: 'MemberFinance',
    components: {EditableCell, CardTitle},
    props: {
        member: Object
    },
    emits: ['update'],
    setup(props, {emit}) {
        const getCurrencyText = () => {
            return constants.currencyMap[props.member?.currency] || 'Не указано';
        };
        const getHourlyRateText = () => {
            return props.member?.hourlyRate || 'Не указано';
        };
        const getCurrencyOptions = () => {
            return Object.keys(constants.currency).map(x => ({
                id: x,
                title: constants.currencyMap[x]
            }));
        };
        const onUpdate = (key, value) => {
            emit('update', {[key]: value});
        };
        return {
            getCurrencyText,
            getCurrencyOptions,
            constants,
            onUpdate,
            getHourlyRateText,
        };
    }
};
</script>

<style lang="scss" scoped>
.member-finance {
    &__column {
        margin-bottom: 30px;
        display: grid;
        grid-template-columns: 200px 400px;
        grid-gap: 15px;
    }
}
</style>