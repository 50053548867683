<template lang="pug">
.add-task-form
    input-with-error(:error="form.title.error")
        template(#input)
            a-input(placeholder="Наименование" v-model:value="form.title.value")
    input-with-error.mt-2(:error="form.clientId.error")
        template(#input)
            custom-select-v2(
                v-model="form.clientId.value"
                placeholder="Контакт"
                :style="{width: '100%'}"
                :options="clients.list"
                searchable
                @search="onContactsSearch"
            )
    input-with-error.mt-2(:error="form.slug.error")
        template(#input)
            a-input(placeholder="Идентификатор" v-model:value="form.slug.value")
    a-button.mt-5.mb-2(
        type="primary"
        block
        @click="createProjectWrapper"
        :loading="loading"
        :disabled="disabled"
    ) {{ mode === 'edit' ? 'Сохранить' : 'Создать' }}
</template>

<script>
import {computed, onMounted, reactive, ref} from 'vue';
import {useFormErrors} from '@/composition/errors';
import {notification} from 'ant-design-vue';
import {debounce, selectFilterFunc} from '@/modules/utils';
import {useProject} from '@/composition/project';
import SelectSuspense from '@/components/Generic/SelectSuspense';
import ContactsSelect from '@/components/Contacts/Select/Contacts';
import {useWorkspace} from '@/composition/workspace';
import {useContactV2} from '@/composition/contact-v2';
import CustomSelectV2 from '@/components/Generic/CustomSelectV2';
export default {
    name: 'CreateProjectForm',
    components: {CustomSelectV2, ContactsSelect, SelectSuspense},
    props: {
        workspaceId: Number,
        defaultValue: Object,
        mode: String
    },
    emits: ['ok', 'update'],
    setup(props, {emit}) {
        const loading = ref(false);

        const form = reactive({
            title: {
                value: props.defaultValue.title,
                error: ''
            },
            clientId: {
                value: props.defaultValue.client,
                error: ''
            },
            slug: {
                value: props.defaultValue.slug,
                error: undefined
            }
        });
        const {createProject} = useProject(loading);
        const clearErrors = () => {
            form.title.error = '';
            form.clientId.error = '';
            form.slug.error = undefined;
        };
        const {init} = useFormErrors();
        const createProjectWrapper = async () => {
            const projectData = {
                title: form.title.value,
                clientId: form.clientId.value?.id,
                slug: form.slug.value,
            };
            if (props.mode === 'edit') {
                emit('update', {...projectData, id: props.defaultValue.id});
            } else {
                const {ok} = await init(form, async () => {
                    return createProject(projectData, props.workspaceId);
                }, clearErrors);
                if (ok) {
                    notification.success({
                        description: 'Проект успешно создан',
                        message: 'Успех!'
                    });
                    emit('ok');
                }
            }
        };

        const disabled = computed(() => {
            return !form.title.value;
        });
        /*======================CONTACTS AREA=======================*/
        const {currentWorkspaceId} = useWorkspace();
        const {getClients, clients} = useContactV2();
        const onContactsSearch = (q) => {
            debounce(async () => {
                await getClients(currentWorkspaceId.value, q, true);
            }, 500)();
        };
        onMounted(async () => {
            await getClients(currentWorkspaceId.value, undefined, true);
        });
        return {
            clients,
            onContactsSearch,
            createProjectWrapper,
            disabled,
            form,
            loading,
            selectFilterFunc,
        };
    }
};
</script>

<style lang="scss" scoped>
.add-task-form {

}
</style>