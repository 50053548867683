import {AxiosResponse} from 'axios';
import {httpInstance} from '../http_factory';
import {ProfitAndLossMapper} from '@/modules/profit-and-loss/mappers/profit-and-loss.mapper';
import {ProfitAndLossFilter} from '@/modules/profit-and-loss/filters/profit-and-loss.filter';
import {ProfitAndLossInterface} from '@/modules/profit-and-loss/entities/profit-and-loss.interface';

const getProfitAndLoss = async (profitAndLossFilter: ProfitAndLossFilter): Promise<ProfitAndLossInterface> => {
    const response: AxiosResponse = await httpInstance().get('/profit-and-loss', {
        params: ProfitAndLossMapper.formFilter(profitAndLossFilter)
    });
    return response.data;
};
export default {
    getProfitAndLoss
};