import {computed, reactive, ref} from 'vue';
import paymentPeriodsModule from '@/modules/services/payment-periods';
import {ListOf} from '@/core/common/interface/list-of.interface';
import {ApiRequestResultInterface} from '@/core/common/interface/api-request-result.interface';
import {apiRequest} from '../../modules/error_handler';
import {OkResponse} from '@/core/common/interface/ok-response.interface';
import {
    ServicePaymentPeriodInterface,
    ServicePaymentPeriodPaymentData
} from '@/modules/services/entities/service-payment-period.interface';
import {ServicePaymentPeriodFilter} from '@/modules/services/filters/service-payment-period.filter';
import {ServicePaymentPeriodForm} from '@/modules/services/form/service-payment-period.form';
import {ServicePaymentPeriodStatus} from '@/components/Services/PaymentPeriod/constants';
import {
    ServicePaymentPeriodFinanceStatItemInterface,
    ServicePaymentPeriodFinanceStatsInterface
} from '@/modules/services/interface/service-payment-period-finance-stat-item.interface';
import {performerPaymentType, servicePaymentType} from '@/components/Services/constants';
import {ServicePaymentPeriodPerformerInterface} from '@/modules/services/entities/service-payment-period-performer.interface';
import Big from 'big.js';
import {ServicePaymentPeriodExpenseInterface} from '@/modules/services/entities/service-payment-period-expense.interface';
import {toFixedNumber} from '@/modules/utils/v2';
import {PaginationInterface} from '@/core/common/interface/pagination.interface';
import {ServicePaymentPeriodsCountInterface} from '@/modules/services/interface/service-payment-periods-count.interface';

export function useServicePaymentPeriod(serviceId: number | null) {
    const paymentPeriod = ref<ServicePaymentPeriodInterface | null>(null);
    const paymentPeriods = ref<ListOf<ServicePaymentPeriodInterface>>({
        list: [],
        next: false,
        total: 0
    });
    const paymentPeriodsCount = reactive<ServicePaymentPeriodsCountInterface>({
        oncoming: 0,
        finished: 0,
        archive: 0,
        active: 0
    });
    const stats = reactive<ServicePaymentPeriodFinanceStatsInterface>({
        plan: {
            total: 0,
            profit: 0,
            profitPercent: 0,
            tax: 0,
            taxPercent: 0,
            expense: 0,
            performersExpense: 0
        },
        fact: {
            total: 0,
            profit: 0,
            profitPercent: 0,
            tax: 0,
            taxPercent: 0,
            expense: 0,
            performersExpense: 0
        },
        balance: {
            total: 0,
            profit: 0,
            profitPercent: 0,
            tax: 0,
            taxPercent: 0,
            expense: 0,
            performersExpense: 0
        },
    });
    const filter = reactive<ServicePaymentPeriodFilter>({
        serviceId,
        paymentPeriodStatus: ServicePaymentPeriodStatus.active,
        performers: [],
        contacts: [],
        search: undefined,
        directors: [],
        deadline: undefined,
        from: undefined,
        serviceGroup: undefined
    });
    const pagination = reactive<PaginationInterface>({
        limit: 8,
        offset: 0,
    });
    const setFilter = (newFilter: ServicePaymentPeriodFilter) => {
        filter.paymentPeriodStatus = newFilter.paymentPeriodStatus;
        filter.performers = newFilter.performers;
        filter.contacts = newFilter.contacts;
        filter.search = newFilter.search;
        filter.directors = newFilter.directors;
        filter.deadline = newFilter.deadline;
        filter.from = newFilter.from;
        filter.serviceGroup = newFilter.serviceGroup;
    };
    const createPaymentPeriod = async (slotData: ServicePaymentPeriodForm): Promise<ApiRequestResultInterface<ServicePaymentPeriodInterface>> => {
        return apiRequest<ServicePaymentPeriodInterface>(async () => {
            return paymentPeriodsModule.createServicePaymentPeriod(slotData);
        });
    };
    const updatePaymentPeriod = async (id: number, updateData: ServicePaymentPeriodForm): Promise<ApiRequestResultInterface<ServicePaymentPeriodInterface>> => {
        return apiRequest<ServicePaymentPeriodInterface>(async () => {
            return paymentPeriodsModule.updateServicePaymentPeriod(id, updateData);
        });
    };
    const deletePaymentPeriod = async (id: number): Promise<ApiRequestResultInterface<OkResponse>> => {
        return apiRequest<OkResponse>(async () => {
            return paymentPeriodsModule.deleteServicePaymentPeriod(id);
        });
    };
    const getPaymentPeriods = async (fromPagination=false): Promise<ApiRequestResultInterface<ListOf<ServicePaymentPeriodInterface>>> => {
        return apiRequest<ListOf<ServicePaymentPeriodInterface>>(async () => {
            const response: ListOf<ServicePaymentPeriodInterface> =
                await paymentPeriodsModule.getServicePaymentPeriods(filter, pagination);
            if (fromPagination) {
                paymentPeriods.value.list = paymentPeriods.value.list.concat(response.list);
                paymentPeriods.value.next = response.next;
                paymentPeriods.value.total = response.total;
            } else {
                paymentPeriods.value = response;
            }
            return paymentPeriods.value;
        });
    };
    const getStats = async (): Promise<ApiRequestResultInterface<ServicePaymentPeriodFinanceStatsInterface>> => {
        return apiRequest<ServicePaymentPeriodFinanceStatsInterface>(async () => {
            const response = await paymentPeriodsModule.getStats(filter);
            if (Object.keys(response).length === 0) return;
            stats.fact = response.fact;
            stats.plan = response.plan;
            stats.balance = response.balance;
            return response;
        });
    };
    const getServicePaymentPeriodsCount = async (): Promise<ApiRequestResultInterface<ServicePaymentPeriodsCountInterface>> => {
        return apiRequest<ServicePaymentPeriodsCountInterface>(async () => {
            const response: ServicePaymentPeriodsCountInterface = await paymentPeriodsModule.getServicePaymentPeriodsCount(filter);
            paymentPeriodsCount.archive = response.archive;
            paymentPeriodsCount.active = response.active;
            paymentPeriodsCount.finished = response.finished;
            paymentPeriodsCount.oncoming = response.oncoming;
            return paymentPeriods.value;
        });
    };
    const getPaymentPeriod = async (id: number): Promise<ApiRequestResultInterface<ServicePaymentPeriodInterface>> => {
        return apiRequest<ServicePaymentPeriodInterface>(async () => {
            paymentPeriod.value = await paymentPeriodsModule.getServicePaymentPeriod(id);
            return paymentPeriod.value;
        });
    };
    const calcPlan = (performers: ServicePaymentPeriodPerformerInterface[], expensesList: ServicePaymentPeriodExpenseInterface[], isPlan: boolean, convertFunc: (a: number, b: string) => number, total: number, totalPlan: number, currency: string, taxPercent: number): ServicePaymentPeriodFinanceStatItemInterface => {
        const totalCost = toFixedNumber(convertFunc(isPlan ? total : totalPlan, currency));
        const performersWithoutPercent: ServicePaymentPeriodPerformerInterface[] =
            performers.filter(x => {
                return x.paymentType === servicePaymentType.fixed || x.paymentType === servicePaymentType.hourly;
            });
        const performersWithPercent: ServicePaymentPeriodPerformerInterface[] =
            performers.filter(x => x.paymentType === performerPaymentType.profit_percent || x.paymentType === performerPaymentType.earning_percent);

        const performersExpense: number = toFixedNumber(performersWithoutPercent.reduce((prev, curr) => {
            const utilization = isPlan ? curr.utilization : curr.utilizationPlan;
            const total = curr.paymentType === servicePaymentType.hourly ? (utilization || 0) * (curr.hourlyRate || 0) : isPlan ? curr.total : curr.totalPlan;
            return prev.plus(convertFunc((total || 0), (curr.currency || 'rub')));
        }, Big(0)).toNumber());

        const otherCosts: number = toFixedNumber(expensesList.reduce((prev, curr) => {
            return prev.plus(convertFunc((curr.amount || 0), (curr.currency || 'rub')));
        }, Big(0)).toNumber());

        const expense: number = toFixedNumber(Big(performersExpense).plus(otherCosts).toNumber());

        const tax: number = toFixedNumber(Big(totalCost || 0).times(taxPercent || 0).times(0.01).toNumber());

        const profit: number = toFixedNumber(Big(totalCost).minus(expense).minus(tax));

        const profitPercent: number = toFixedNumber(Big(profit).div(totalCost || -profit || 1).times(100), 0);
        const performersWithPercentExpense: number = toFixedNumber(performersWithPercent.reduce((prev, curr) => {
            if (curr.paymentType === performerPaymentType.earning_percent) {
                const amount: Big = Big(totalCost || 0).times(curr.percentRate || 0).times(0.01);
                return prev.plus(amount);
            }
            const amount: Big = Big(profit || 0).times(curr.percentRate || 0).times(0.01);
            return prev.plus(amount);
        },  Big(0)));
        const resultExpense: number = toFixedNumber(Big(performersExpense)
            .plus(otherCosts)
            .plus(performersWithPercentExpense)
        );
        const resultProfit: number = toFixedNumber(Big(totalCost).minus(resultExpense).minus(tax));
        const resultProfitPercent: number = toFixedNumber(Big(resultProfit).div(totalCost || -resultProfit || 1).times(100), 0);
        return {
            total: totalCost,
            expense: resultExpense,
            tax,
            taxPercent,
            profit: resultProfit,
            profitPercent: resultProfitPercent,
            performersExpense: toFixedNumber(Big(performersExpense).plus(performersWithPercentExpense)),
            cleanProfit: profit,
            cleanProfitPercent: profitPercent,
            cleanExpense: expense
        };
    };
    const calcFact = (paymentData: ServicePaymentPeriodPaymentData, taxPercent=0): ServicePaymentPeriodFinanceStatItemInterface => {
        const expense = paymentData.fact.out || 0;
        const total = paymentData.fact.in || 0;
        const tax = Big(Big(total).times(taxPercent || 0).times(0.01).toFixed(2)).toNumber();
        const profit = toFixedNumber(Big(total).minus(expense).minus(tax));
        const profitPercent = toFixedNumber(Big(profit).div(total || -profit || 1).times(100), 0);
        return {
            total: toFixedNumber(total),
            expense: toFixedNumber(expense),
            tax: toFixedNumber(tax),
            taxPercent,
            profit: toFixedNumber(profit),
            profitPercent: toFixedNumber(profitPercent),
        };
    };
    const calcBalance = (performers: ServicePaymentPeriodPerformerInterface[], paymentType: string, convertFunc: (a: number, b: string) => number, utilizationLogged=0, hourlyRate=0, totalAmount: number, currency='rub', taxPercent= 0): ServicePaymentPeriodFinanceStatItemInterface => {
        const totalInCurrency = toFixedNumber(paymentType === servicePaymentType.hourly ? Big(utilizationLogged || 0).times(hourlyRate || 0) : totalAmount);
        const total = convertFunc(totalInCurrency, currency);
        const performersWithoutPercent: ServicePaymentPeriodPerformerInterface[] =
            performers.filter(x => x.paymentType === performerPaymentType.fixed || x.paymentType === performerPaymentType.hourly);
        const performersWithPercent: ServicePaymentPeriodPerformerInterface[] =
            performers.filter(x => x.paymentType === performerPaymentType.profit_percent || x.paymentType === performerPaymentType.earning_percent);
        const expense = toFixedNumber(performersWithoutPercent.reduce((prev, curr) => {
            const total = toFixedNumber(curr.paymentType === servicePaymentType.hourly ? Big(curr.utilizationLogged || 0).times(curr.hourlyRate || 0) : (curr.total || 0));
            return prev.plus(convertFunc(total, (curr.currency || 'rub')));
        }, Big(0)));
        const tax = Big(Big(total).times(taxPercent).times(0.01).toFixed(2)).toNumber();
        const profit = toFixedNumber(Big(total).minus(expense).minus(tax));
        const profitPercent = toFixedNumber(Big(profit).div(total || -profit || 1).times(100), 0);
        const performersWithPercentExpense: number = toFixedNumber(performersWithPercent.reduce((prev, curr) => {
            if (curr.paymentType === performerPaymentType.earning_percent) {
                const amount: Big = Big(total || 0).times(curr.percentRate || 0).times(0.01);
                return prev.plus(amount);
            }
            const amount: Big = Big(profit || 0).times(curr.percentRate || 0).times(0.01);
            return prev.plus(amount);
        },  Big(0)));
        const resultExpense: number = toFixedNumber(Big(expense)
            .plus(performersWithPercentExpense)
        );
        const resultProfit: number = toFixedNumber(Big(total).minus(resultExpense).minus(tax));
        const resultProfitPercent: number = toFixedNumber(Big(resultProfit).div(total || -resultProfit || 1).times(100), 0);
        return {
            expense: resultExpense,
            total,
            tax,
            taxPercent,
            profit: resultProfit,
            profitPercent: resultProfitPercent,
            cleanProfit: profit,
            cleanProfitPercent: profitPercent,
            cleanExpense: expense
        };
    };
    return {
        getStats,
        stats,
        filter,
        pagination,
        paymentPeriod,
        paymentPeriods,
        paymentPeriodsCount,
        calcPlan,
        calcFact,
        setFilter,
        calcBalance,
        getPaymentPeriod,
        getPaymentPeriods,
        createPaymentPeriod,
        updatePaymentPeriod,
        deletePaymentPeriod,
        getServicePaymentPeriodsCount,
    };
}
