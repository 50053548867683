import {apiRequest} from '@/modules/error_handler';
import timelogModule from '@/modules/timelog';
import {ref} from 'vue';
const dailyTotal = ref(0);
const uncommittedLog = ref({});
const showUncommittedLogModal = ref(false);
const nextLoggingTaskId = ref(null);

export function useTimelog(loading={value: false}) {
    const pagination = ref({
        limit: 50,
        offset: 0,
    });
    const timeLogs = ref({
        list: [],
        next: '',
        prevPage: '',
        total: '',
        totalPages: '',
    });
    const timeLog = ref({});
    const filter = ref({
        range: []
    });
    const setUncommittedLog = timeLog => {
        uncommittedLog.value = timeLog;
    };
    const setUncommittedLogModalVisibility = (bool) => {
        showUncommittedLogModal.value = bool;
    };
    const createTimeLog = async (workspaceId, transactionData) => {
        return apiRequest(async() => {
            await timelogModule.createTimeLog(workspaceId, transactionData);
        }, loading);
    };
    const getTimeLogById = async(workspaceId, id) => {
        return apiRequest(async() => {
            timeLog.value = await timelogModule.getTimeLogById(workspaceId, id);
            return timeLog.value;
        }, loading);
    };
    const getDailyTotal = async(workspaceId,) => {
        return apiRequest(async() => {
            const response = await timelogModule.getDailyTotal(workspaceId);
            dailyTotal.value = response.seconds;
            return dailyTotal.value;
        }, loading);
    };

    const updateTimeLog = async(workspaceId, id, updateData) => {
        return apiRequest(async() => {
            return timelogModule.updateTimeLog(workspaceId, id, updateData);
        }, loading);
    };
    const deleteTimeLog = async(workspaceId, id) => {
        return apiRequest(async() => {
            return timelogModule.deleteTimeLog(workspaceId, id);
        }, loading);
    };
    const getTimeLogs = async (workspaceId, meta={}, tz) => {
        return apiRequest(async() => {
            const result = await timelogModule.getTimeLogs(workspaceId, {
                limit: pagination.value.limit,
                offset: pagination.value.offset,
            }, filter.value, tz);
            if (meta.fromPagination) {
                timeLogs.value.list = timeLogs.value.list.concat(result.list);
                timeLogs.value.next = result.next;
            } else {
                timeLogs.value = result;
            }
            return timeLogs.value;
        }, loading);
    };
    const setFilter = (filterData) => {
        filter.value = filterData;
    };
    const setNextLoggingTaskId = (id) => {
        nextLoggingTaskId.value = id;
    };
    const getTimeLogsByServiceId = async (workspaceId, serviceId, paymentPeriodId, billType) => {
        return apiRequest(async() => {
            return timelogModule.getTimeLogsByServiceId(workspaceId, serviceId, {
                paymentPeriodId,
                billType,
            });
        }, loading);
    };
    const localUpdate = (timeLogId, newData) => {
        const targetTimeLogIndex = timeLogs.value.list.findIndex(x => x.id === timeLogId);
        if (targetTimeLogIndex === -1) return false;
        timeLogs.value.list[targetTimeLogIndex] = newData;
        return true;
    };
    return {
        pagination,
        filter,
        timeLogs,
        localUpdate,
        createTimeLog,
        getTimeLogById,
        updateTimeLog,
        deleteTimeLog,
        getTimeLogs,
        setFilter,
        getDailyTotal,
        dailyTotal,
        uncommittedLog,
        showUncommittedLogModal,
        setUncommittedLog,
        setUncommittedLogModalVisibility,
        setNextLoggingTaskId,
        nextLoggingTaskId,
        getTimeLogsByServiceId,
    };
}
