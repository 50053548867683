<template lang="pug">
settings-directories-documents-layout
    async-page
        documents-statuses-table
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import SettingsDirectoriesDocumentsLayout from '@/pages/Settings/Directories/Documents/Layout';
import DocumentsStatusesTable from '@/components/Directories/Documents/Statuses/Table';

export default {
    name: 'DocumentStatusesPage',
    components: {
        DocumentsStatusesTable,
        SettingsDirectoriesDocumentsLayout,
        AsyncPage
    }
};
</script>

<style scoped>

</style>