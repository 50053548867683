<template lang="pug">
.saved-reports-table
    custom-table(
        :columns="savedReportsColumns"
        :data-source="savedReports.list"
        :onRowClick="(row) => redirectToCard(row.id)"
    )
        template(#title="{record}")
            div {{ record.title }}
        template(#period="{record}")
            div(v-if="record?.filter?.from && record?.filter?.to") {{ formatDate(record.filter.from) }} - {{ formatDate(record.filter.to) }}
        template(#clients="{record}")
            div(v-if="record?.filter?.contacts") {{ getTitlesFromArray(record?.filter?.contacts )}}
        template(#services="{record}")
            div(v-if="record?.filter?.services") {{ getTitlesFromArray(record?.filter?.services, 'fullTitle') }}
        template(#createdAt="{record}")
            div {{ formatDate(record.createdAt) }}
        template(#action="{record}")
            .flex.items-center
                .mr-3(@click.stop="copyLink(record.uuid)")
                    icon(type="Share")
                delete-with-confirm(:deleteFunc="() => onDelete(record.id)")
    a-button.mt-3(@click="next" v-if="savedReports.next" :loading="loading") Показать еще
</template>

<script>
export default {
    name: 'SavedReportsTable'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
import {ref} from 'vue';
import {savedReportsColumns} from '@/components/Analytics/SavedReports/constants';
import CustomTable from '@/components/Generic/CustomTable';
import {useReport} from '@/composition/reports';
import {useWorkspace} from '@/composition/workspace';
import {useDate} from '@/composition/date';
import DeleteWithConfirm from '@/components/Generic/DeleteWithConfirm';
import {useRouter} from 'vue-router';
import {useCopy} from '@/composition/copy';
import {getTitlesFromArray} from '@/modules/utils';
import Icon from '@/components/Generic/Typography/Icon';

/*===============INIT AREA===================*/
const loading = ref(false);
const {currentWorkspaceId} = useWorkspace(loading);
const {
    savedReports,
    getSavedReportsList,
    pagination,
    deleteSavedReport
} = useReport();
const {formatDate} = useDate();
const router = useRouter();
const {copy} = useCopy();

/*===============FETCHING DATA AREA===================*/

const fetchData = async (fromPagination=false) => {
    await getSavedReportsList(currentWorkspaceId.value, {fromPagination});
};
await fetchData(false);

const next = async () => {
    pagination.value.offset += pagination.value.limit;
    await fetchData(true);
};

/*===============CRUD METHODS AREA===================*/
const onDelete = async (id) => {
    await deleteSavedReport(currentWorkspaceId.value, id);
    await fetchData(false);
};
/*===============ROUTING METHODS AREA===================*/
const redirectToCard = (id) => {
    return router.push(`/${currentWorkspaceId.value}/reports/saved/${id}`);
};
/*===============ACTIONS METHODS AREA===================*/
const copyLink = (uuid) => {
    const reportUrl = `${window.location.origin}/public/reports/hourly/${uuid}`;
    copy(reportUrl, {
        message: 'Публичная ссылка на отчет скопирована в буфер обмена'
    });
};

</script>

<style lang="scss">
</style>