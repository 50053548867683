<template lang="pug">
.page.login-page
    .login-page-left(v-if="!error")
        typography-title.login-page__title Введите новый пароль
        .login-page-form
            input-with-error(:error="form.password.error")
                template(#input)
                    a-input.mt-3(placeholder="Пароль" v-model:value="form.password.value" type="password")
            input-with-error(:error="form.checkPassword.error")
                template(#input)
                    a-input.mt-3(placeholder="Повторите пароль" v-model:value="form.checkPassword.value" type="password")
            a-button.mt-5.mb-2(
                type="primary"
                block
                @click="restorePasswordWrapper"
                :loading="loading"
                :disabled="disabled"
            ) Войти
            router-link(to="/login") У меня уже есть аккаунт
    .login-page-left.text-3xl(v-else) Устаревшая ссылка <router-link to='/login'> Перейти на главную</router-link>
    .login-page-right
        laptop
</template>

<script>

import {ref, computed, reactive} from 'vue';
import {useAuth} from '@/composition/auth';
import {useFormErrors} from '@/composition/errors';
import InputWithError from '@/components/Generic/InputWithError';
import {useRoute, useRouter} from 'vue-router';
import Laptop from '@/assets/img/super-with-laptop.svg';
import {notification} from 'ant-design-vue';
export default {
    name: 'RestorePassword',
    components: {InputWithError, Laptop},
    async setup() {
        const router = useRouter();
        const route = useRoute();
        const loading = ref(false);
        const error = ref(null);
        const {checkConfirmation, restorePassword} = useAuth(loading);
        const confirmationId = route.params.confirmationId;
        const {result} = await checkConfirmation(confirmationId);
        if (!result.ok) error.value = true;
        const form = reactive({
            password: {
                value: '',
                error: ''
            },
            checkPassword: {
                value: '',
                error: ''
            },
        });
        const clearErrors = () => {
            form.checkPassword.error = '';
            form.password.error = '';
        };
        const {init} = useFormErrors();

        const restorePasswordWrapper = async () => {
            const {ok} = await init(form, async () => {
                return restorePassword({
                    password: form.password.value,
                    checkPassword: form.checkPassword.value,
                    confirmationId
                });
            }, clearErrors);
            if (ok) {
                notification.success({
                    message: 'Успех',
                    description: 'Пароль успешно сменен'
                });
                await router.push('/login');
            }
        };

        const disabled = computed(() => {
            return !form.password.value || !form.checkPassword.value;
        });
        return {
            form,
            loading,
            disabled,
            restorePasswordWrapper,
            error
        };
    }
};
</script>

<style scoped lang="scss">
.login-page {
    display: flex;

    &__title {
        max-width: 400px;
        text-align: center;
    }

    &-form {
        width: 280px;
        margin: 30px 0;
    }

    &-right {
        min-height: 100vh;
        height: 100%;
        width: 50%;
        background: $greyColor;
        display: flex;
        align-items: center;
        justify-content: center;
        @media(max-width: $screenMdMin) {
            display: none;
        }
    }

    &-left {
        min-height: 100vh;
        height: 100%;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @media(max-width: $screenMdMin) {
            width: 100%;
        }
    }
}

.errors {
    border-color: #42b983 !important;
}
</style>