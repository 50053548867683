<template lang="pug">
.salary-position-form
    card-title Введите наименование
    a-input.mt-2(v-model:value="form.title" placeholder="Наименование" style="width: 100%")
    a-input-number.mt-2(v-model:value="form.balance" placeholder="Сумма в рублях" style="width: 100%")
    a-button.mt-2(@click="onFormDone(form)" type="primary" :disabled="isDisabled" :loading="loading") Сохранить
</template>

<script>
export default {
    name: 'SalaryPositionForm'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
import {computed, reactive} from 'vue';
import CardTitle from '@/components/Generic/Typography/CardTitle';

/*===============INIT AREA===================*/
const props = defineProps({
    workspaceMember: {
        type: Object,
        required: true,
    },
    loading: {
        type: Boolean,
        required: true,
    }
});
const emit = defineEmits(['create']);
/*===============FORM AREA===================*/
const form = reactive({
    balance: null,
    title: null,
});
const onFormDone = (formData) => {
    emit('create', {
        title: formData.title,
        balance: formData.balance,
        workspaceMemberId: props.workspaceMember.id
    });
};
const isDisabled = computed(() => {
    return !form.balance;
});
</script>

<style lang="scss">
</style>