<template lang="pug">
.payment-dates-item
    a-date-picker(
        placeholder="Дата оплаты"
        :value="item.date"
        @change="onChange('date', $event)"
        style="width: 100%"
        format="DD.MM.YYYY"
    )
    a-input-number.ml-3(:value="item.sum" :min="0" placeholder="Сумма" @change="onChange('sum', $event)" style="width: 100%")
    currency-select.ml-3(:modelValue="item.currency" @change="onChange('currency', $event)" style="width: 100%")
    .payment-dates-item__delete.ml-3(@click="deleteItem")
        icon(type="Delete")
</template>

<script>
import constants from '@/components/Workspace/InviteUserForm/constants';
import CurrencySelect from '@/components/Generic/CurrencySelect';
export default {
    name: 'PaymentDateItem',
    components: {CurrencySelect},
    props: {
        item: Object
    },
    emits: ['change', 'delete'],
    setup(props, {emit}) {
        const onChange = (key, value) => {
            const payload = {
                id: props.item.id,
                date: props.item.date,
                sum: props.item.sum,
                currency: props.item.currency,
            };
            payload[key] = value;
            emit('change', payload);
        };
        const deleteItem = () => {
            emit('delete', props.item.id);
        };
        return {
            constants,
            onChange,
            deleteItem,
        };
    }
};
</script>

<style lang="scss" scoped>
.payment-dates-item {
    display: flex;
    align-items: center;
    &__delete {
        cursor: pointer;
    }
}
</style>