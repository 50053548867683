<template lang="pug">
.payment-dates
    .payment-date__item(
        v-for="paymentDateItem in paymentDates.value"
    )
        payment-date-item.mt-2(
            :item="paymentDateItem"
            @change="(e) => onItemChanged(e, paymentDateItem)"
            @delete="(e) => onItemDelete(e)"
        )
    a-button.mt-2(@click="addItem" type="primary") Добавить

</template>

<script>
import PaymentDateItem from './Item';
export default {
    name: 'PaymentDates',
    components: {PaymentDateItem},
    props: {
        paymentDates: Object
    },
    emits: ['update:paymentDates'],
    setup(props, {emit}) {
        const paymentDateItem = () => ({
            id: Symbol(Date.now()),
            date: undefined,
            sum: undefined,
            currency: undefined,
        });
        const addItem = () => {
            emit('update:paymentDates', {
                error: undefined,
                value: [...props.paymentDates.value, paymentDateItem()]
            });
        };
        const onItemChanged = (newVal, oldVal) => {
            emit('update:paymentDates', {
                error: undefined,
                value: props.paymentDates.value.map(x => {
                    if (x.id === oldVal.id) {
                        return newVal;
                    }
                    return x;
                })
            });
        };
        const onItemDelete = (id) => {
            emit('update:paymentDates', {
                error: undefined,
                value: props.paymentDates.value.filter(x => x.id != id)
            });
        };
        return {
            onItemChanged,
            onItemDelete,
            addItem,
        };
    }
};
</script>

<style scoped>

</style>