<template lang="pug">
.task-logging
    .task-logging__icon(v-if="!isActive && !loadingState" @click="start")
        icon(type="Play")
    .task-logging__icon.task-logging__active(v-else-if="isActive && !loadingState" @click="stop")
        icon(type="Stop")
    .task-logging__icon(v-else)
        icon(type="Pulse")
    .ml-2(v-if="isActive && !loadingState") {{ secondsToHumanReadable(aggregator) }}

</template>

<script>
export default {
    name: 'TaskLogging'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/

import {watch, ref, computed} from 'vue';
import {useClock} from '@/composition/clock';
import {secondsToHumanReadable} from '@/modules/utils';
import {useTimelog} from '@/composition/timelog';

/*===============INIT AREA===================*/
const localLoading = ref(false);
const props = defineProps({
    id: {
        type: Number,
    },
    isActive: {
        type: Boolean
    },
    loading: {
        type: Boolean
    },
    activeLog: {
        type: Object
    },
    totalTimingInSeconds: {
        type: Number
    }
});
const emit = defineEmits(['start', 'stop']);

/*===============HANDLERS AREA===================*/
const start = () => {
    emit('start', props.id);
};

const stop = () => {
    emit('stop', {id: props.id});
};

/*===============CLOCK AREA=======================*/
const {aggregator, setActive} = useClock(props.id);
const {nextLoggingTaskId} = useTimelog();
setActive(props.isActive, props.activeLog?.startDate);
watch(() => props.isActive, () => {
    setActive(props.isActive, props.activeLog?.startDate);
});
/*===============LOADING STATE AREA=======================*/
watch(() => nextLoggingTaskId.value, () => {
    localLoading.value = nextLoggingTaskId.value === props.id;
});
const loadingState = computed(() => localLoading.value || props.loading);
</script>

<style lang="scss">
.task-logging {
    cursor: pointer;
    display: flex;
    align-items: center;
    &__icon {
        width: 20px;
        height: 20px;
        &:hover {
            fill: #5a00c2;
        }
    }
    &__active {
        fill: #5a00c2;
    }
}
</style>