<template lang="pug">
.hourly-reports-chart
    chart-wrapper(:loading="loading")
        column-chart(:data="chartData" stacked)
</template>

<script>
export default {
    name: 'HourlyReportsChart'
};
</script>
<script setup>
/*======================IMPORT AREA=====================*/

import {ref} from 'vue';
import ColumnChart from '@/components/Generic/Charts/ColumnChart';
import ChartWrapper from '@/components/Generic/Charts/ChartWrapper';
import {useReport} from '@/composition/reports';

/*======================INITIALIZATION AREA=====================*/

const loading = ref(false);
const {chartData} = useReport(loading);

</script>
<style scoped>

</style>