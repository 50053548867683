<template lang="pug">
.unpaid-bills-widget.widget-card
    card-title Неоплаченные счета
    .unpaid-bills-widget__summary.my-4
        .unpaid-bills-widget__summary-item {{ formatCurrency(unpaidBillsData.amount) }} руб.
        .unpaid-bills-widget__summary-item |
        .unpaid-bills-widget__summary-item {{ unpaidBillsData.count }} {{ getSummaryText(unpaidBillsData.count) }}
    custom-table(:columns="unpaidBillsColumns" :data-source="unpaidBillsData.list")
        template(#customer="{record}")
            div {{ record.customer.title }}
        template(#service="{record}")
            div {{ renderFirstService(record) }}
        template(#cost="{record}")
            div {{ formatCurrency(record.cost - record.paid) }} руб.
        template(#paymentDates="{record}")
            div(:style="isOutdated(record.payDateTo) ? {color: 'red'} : {}") {{ formatDate(record.payDateTo) }}
</template>

<script>
export default {
    name: 'UnpaidBillsWidget'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
import {onMounted, ref} from 'vue';

import CardTitle from '@/components/Generic/Typography/CardTitle';
import {useWorkspace} from '@/composition/workspace';
import {useDashboard} from '@/composition/dashboard';
import CustomTable from '@/components/Generic/CustomTable';
import {unpaidBillsColumns} from '@/components/Dashboard/Widgets/UnpaidBills/constants';
import {declOfNum, formatCurrency} from '@/modules/utils';
import {useDate} from '@/composition/date';
/*===============INIT AREA===================*/

const loading = ref(false);
const {currentWorkspaceId} = useWorkspace();
const {getUnpaidBills, unpaidBillsData} = useDashboard(loading);
const {isOutdated, formatDate} = useDate();

/*===============FETCH DATA AREA===================*/

const fetchData = async () => {
    await getUnpaidBills(currentWorkspaceId.value);
};

/*===============RENDERERS AREA===================*/

const renderFirstService = (bill) => {
    const notFoundText = 'Не указано';
    const services = bill.services;
    if (!services || !services.length) return notFoundText;
    const service = services[0];
    return service?.title || notFoundText;
};

const getSummaryText = (count) => {
    return declOfNum(count, ['контрагент', 'контрагента', 'контрагентов']);
};

/*===============LIFECYCLE HOOKS AREA===================*/

onMounted(() => {
    fetchData();
});
</script>

<style lang="scss">
.unpaid-bills-widget {
    width: 100%;
    height: auto;
    min-height: 580px;
    &__summary {
        padding: 23px;
        background-color: #f2f2f2;
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: 700;
        &-item {
            margin-right: 24px;
        }
    }
}
</style>