<template lang="pug">
settings-directories-members-layout
    async-page
        grades-table
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import GradesTable from '@/components/Directories/Members/Grade/Table';
import SettingsDirectoriesMembersLayout from '@/pages/Settings/Directories/Members/Layout';
export default {
    name: 'GradesPage',
    components: {SettingsDirectoriesMembersLayout, GradesTable, AsyncPage}
};
</script>

<style scoped>

</style>