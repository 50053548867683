import io from 'socket.io-client';
import {VueCookieNext} from 'vue-cookie-next';
import {notification} from 'ant-design-vue';
import {useAuth} from '@/composition/auth';
import {useKanban} from '@/composition/kanban';
const token = VueCookieNext.getCookie('token');
const socket = io(process.env.VUE_APP_SOCKET_URL, {query: {token}, transports: ['websocket']} );
const {me} = useAuth();
const {getTasks} = useKanban();
const init = () => {
    initHandlers(socket);
    return socket;
};
const socketInstance = () => {
    return socket;
};
const subscribe = (event, handler) => {
    socket.on(event, handler);
};
function auth () {
    const token = VueCookieNext.getCookie('token');
    const socket = socketInstance();
    socket.emit('auth', {token});
}

function onIntegrationHook(data) {
    // notification.success({
    //     message: 'Из интеграции',
    //     description: data.message
    // });
    getTasks(data.workspaceId);
}

function onIntegrationProgress(data) {
    console.log({data});
}

function onConnect ()  {
    console.log('connected');
}

function onDisconnect() {
    console.log('disconnected');
}

function onReconnect(data) {
    console.log('reconnect', data);
}

function onError(data) {
    console.log('error',data);
}

function onWorkspaceMemberDisconnected(payload) {
    me(payload.workspaceId);
    getTasks(payload.workspaceId);
}
function onTaskUpdated(payload) {
    getTasks(payload.workspaceId);
}
const handlers = {
    integration_hook: onIntegrationHook,
    task_updated: onTaskUpdated,
    integration_progress: onIntegrationProgress,
    workspace_member_disconnected: onWorkspaceMemberDisconnected,
    connect: onConnect,
    disconnect: onDisconnect,
    reconnect: onReconnect,
    error: onError,
};

function initHandlers(socket) {
    for(let event in handlers) {
        socket.on(event, handlers[event]);
    }
}

export default {
    socketInstance,
    auth,
    init,
    subscribe,
};
