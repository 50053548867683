import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43695436"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "service-payment-period-create-form" }
const _hoisted_2 = { class: "my-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$setup.isEndForm)
      ? (_openBlock(), _createBlock($setup["PaymentPeriodCreateFormBase"], {
          key: 0,
          from: $setup.form.from,
          "onUpdate:from": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.from) = $event)),
          to: $setup.form.to,
          "onUpdate:to": _cache[1] || (_cache[1] = ($event: any) => (($setup.form.to) = $event)),
          service: $setup.form.service,
          "onUpdate:service": _cache[2] || (_cache[2] = ($event: any) => (($setup.form.service) = $event)),
          "allow-select-service": $props.allowSelectService
        }, null, 8, ["from", "to", "service", "allow-select-service"]))
      : _createCommentVNode("", true),
    _createVNode($setup["PaymentPeriodCreateFormClientIncoming"], {
      contact: $setup.contactObject,
      paymentType: $setup.form.paymentType,
      "onUpdate:paymentType": _cache[3] || (_cache[3] = ($event: any) => (($setup.form.paymentType) = $event)),
      hourlyRate: $setup.form.hourlyRate,
      "onUpdate:hourlyRate": _cache[4] || (_cache[4] = ($event: any) => (($setup.form.hourlyRate) = $event)),
      currency: $setup.form.currency,
      "onUpdate:currency": _cache[5] || (_cache[5] = ($event: any) => (($setup.form.currency) = $event)),
      utilization: $setup.form.utilization,
      "onUpdate:utilization": _cache[6] || (_cache[6] = ($event: any) => (($setup.form.utilization) = $event)),
      total: $setup.form.total,
      "onUpdate:total": _cache[7] || (_cache[7] = ($event: any) => (($setup.form.total) = $event)),
      onChange: $setup.recalculateTotal,
      isPlan: $setup.isPlan
    }, null, 8, ["contact", "paymentType", "hourlyRate", "currency", "utilization", "total", "isPlan"]),
    _createVNode($setup["PaymentPeriodCreateFormPerformers"], {
      performers: $setup.form.performers,
      "onUpdate:performers": [
        _cache[8] || (_cache[8] = ($event: any) => (($setup.form.performers) = $event)),
        $setup.recalculateTotal
      ],
      isPlan: $setup.isPlan,
      totalAmount: $setup.planPaymentData.performersExpense,
      totalUtilization: $setup.performerTotalUtilization,
      "payment-data": $setup.planPaymentData
    }, null, 8, ["performers", "isPlan", "totalAmount", "totalUtilization", "payment-data"]),
    _createVNode($setup["PaymentPeriodCreateFormTax"], {
      tax: $setup.form.tax,
      "onUpdate:tax": _cache[9] || (_cache[9] = ($event: any) => (($setup.form.tax) = $event)),
      total: $setup.planPaymentData.total
    }, null, 8, ["tax", "total"]),
    _createVNode($setup["PaymentPeriodCreateFormExpenses"], {
      expenses: $setup.form.expenses,
      "onUpdate:expenses": _cache[10] || (_cache[10] = ($event: any) => (($setup.form.expenses) = $event))
    }, null, 8, ["expenses"]),
    _createVNode($setup["PaymentPeriodCreateFormFinanceStats"], {
      isPlan: $setup.isPlan,
      plan: $setup.planPaymentData,
      fact: $setup.factPaymentData,
      balance: $setup.balancePaymentData
    }, null, 8, ["isPlan", "plan", "fact", "balance"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_textarea, {
        value: $setup.form.description,
        "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => (($setup.form.description) = $event)),
        placeholder: "Описание",
        style: {"width":"100%"},
        rows: 4
      }, null, 8, ["value"])
    ]),
    _createElementVNode("div", null, [
      (!$props.immediateUpdate)
        ? (_openBlock(), _createBlock(_component_a_button, {
            key: 0,
            onClick: _cache[12] || (_cache[12] = ($event: any) => ($setup.onFormDone($setup.form))),
            type: "primary",
            loading: $props.loading,
            disabled: $setup.isDisabled
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.isEndForm ? 'Завершить' : 'Сохранить'), 1)
            ]),
            _: 1
          }, 8, ["loading", "disabled"]))
        : _createCommentVNode("", true)
    ])
  ]))
}