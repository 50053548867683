<template lang="pug">
card-title Основная информация
.create-document-form.mt-2
    .create-document-form__group
        input-with-error(:error="form.documentTypeId.error")
            template(#input)
                a-select(
                    v-model:value="form.documentTypeId.value"
                    style="width: 100%"
                    placeholder="Тип документа"
                    show-search
                    :filter-option="selectFilterFunc"
                    allowClear
                    @change="onDocumentTypeChanged"
                )
                    a-select-option(
                        v-for="documentType in directories.documentTypes.list"
                        :key="documentType.id"
                        :value="documentType.id"
                        :label="documentType.title"
                    )
                        | {{ documentType.title }}
                    a-select-option.cursor-pointer(disabled @click="() => setModalVisibility('documentTypes', true)") Добавить
                    template(#notFoundContent)
                        add-directory(:onClick="() => setModalVisibility('documentTypes', true)")
        input-with-error(:error="form.customerId.error")
            template(#input)
                a-select(
                    v-model:value="form.customerId.value"
                    placeholder="Заказчик"
                    style="width:100%"
                    allowClear
                    show-search
                    :filter-option="false"
                    :label-in-value="false"
                    :options="labeledCustomersList"
                    @search="onCustomerSearch"
                    @change="onCustomerChanged"
                )
        input-with-error(:error="form.performerId.error")
            template(#input)
                a-select(
                    v-model:value="form.performerId.value"
                    placeholder="Исполнитель"
                    style="width:100%"
                    allowClear
                    show-search
                    :filter-option="false"
                    :label-in-value="false"
                    :options="labeledPerformersList"
                    @search="onPerformerSearch"
                )
        input-with-error(:error="form.number.error")
            template(#input)
                a-input(placeholder="Номер" v-model:value="form.number.value")
        input-with-error(:error="form.date.error")
            template(#input)
                a-date-picker(placeholder="Дата документа" v-model:value="form.date.value" style="width: 100%" format="DD.MM.YYYY")
        input-with-error(:error="form.deadline.error")
            template(#input)
                a-date-picker(v-model:value="form.deadline.value" placeholder="Исполнить до" style="width: 100%" format="DD.MM.YYYY")
        input-with-error(:error="form.baseDocumentId.error" v-if="currentTypeKey === DocumentType.completion_certificate")
            template(#input)
                a-select(
                    placeholder="Основание"
                    v-model:value="form.baseDocumentId.value"
                    :disabled="!form.customerId.value"
                    style="width: 100%"
                    show-search
                    :filter-option="false"
                    allowClear
                    @search="onDocumentsSearch"
                    label="number"
                )
                    a-select-option(
                        v-for="document in localDocuments.list"
                        :key="document.id"
                        :value="document.id"
                    ) {{ document.documentType?.title }} {{ document.number }} от {{ formatDate(document.date) }}
        input-with-error(:error="form.transportId.error")
            template(#input)
                a-select(
                    v-model:value="form.transportId.value"
                    style="width: 100%"
                    placeholder="Способ обмена"
                    show-search
                    :filter-option="selectFilterFunc"
                    allowClear
                )
                    a-select-option(
                        v-for="documentTransport in directories.documentTransports.list"
                        :key="documentTransport.id"
                        :value="documentTransport.id"
                        :label="documentTransport.title"
                    )
                        | {{ documentTransport.title }}
                    a-select-option.cursor-pointer(disabled @click="() => setModalVisibility('documentTransports', true)") Добавить
                    template(#notFoundContent)
                        add-directory(:onClick="() => setModalVisibility('documentTransports', true)")
        input-with-error(:error="form.directorId.error")
            template(#input)
                a-select(
                    v-model:value="form.directorId.value"
                    style="width: 100%"
                    placeholder="Ответственный"
                    show-search
                    :filter-option="false"
                    @search="(q) => onSearch('member', q)"
                    allowClear
                )
                    a-select-option(
                        v-for="member in members.list"
                        :key="member.id"
                        :value="member.id"
                    )
                        | {{ member.fullName }}
        input-with-error(:error="form.statusId.error")
            template(#input)
                a-select(
                    v-model:value="form.statusId.value"
                    style="width: 100%"
                    placeholder="Статус"
                    show-search
                    :filter-option="selectFilterFunc"
                    allowClear
                )
                    a-select-option(
                        v-for="documentStatus in directories.documentStatuses.list"
                        :key="documentStatus.id"
                        :value="documentStatus.id"
                        :label="documentStatus.title"
                    )
                        | {{ documentStatus.title }}
                    a-select-option.cursor-pointer(disabled @click="() => setModalVisibility('documentStatuses', true)") Добавить
                    template(#notFoundContent)
                        add-directory(:onClick="() => setModalVisibility('documentStatuses', true)")
    a-divider
    .create-document-form__source.mt-2
        a-radio-group(
            v-model:value="form.sourceType.value"
        )
            a-radio-button(
                v-if="currentTypeKey === DocumentType.completion_certificate"
                :key="DocumentSourceType.create_new"
                :value="DocumentSourceType.create_new"
            ) {{ DocumentSourceTypeMap[DocumentSourceType.create_new] }}
            a-radio-button(
                :key="DocumentSourceType.upload_new"
                :value="DocumentSourceType.upload_new"
            ) {{ DocumentSourceTypeMap[DocumentSourceType.upload_new] }}
            a-radio-button(
                :key="DocumentSourceType.url"
                :value="DocumentSourceType.url"
            ) {{ DocumentSourceTypeMap[DocumentSourceType.url] }}
        input-with-error(:error="form.documentUrl.error")
            template(#input)
                uploader(@upload="onUpload" v-if="form.sourceType.value == DocumentSourceType.upload_new")
                    a-button.mt-2 Загрузить документ
                .mt-3.flex.items-center(v-if="form.documentUrl.value")
                    .cursor-pointer(@click="onDocumentDownload") {{ form.documentUrl.value }}
                    .ml-3(@click="deleteDocumentUrl")
                        icon(type="Delete")
                a-input.mt-2(
                    v-if="form.sourceType.value === DocumentSourceType.url"
                    placeholder="URL"
                    v-model:value="form.documentUrl.value"
                    style="width: 100%"
                )
    a-divider
    .create-document-form__services(v-if="form.customerId.value")
        card-title Услуги
        input-with-error(:error="form.services.error")
            template(#input)
                document-services(
                    v-model:services="form.services"
                    :directories="directories"
                    :additionalServiceFilter="additionalServiceFilter"
                    @log-data="setLogData"
                    :hidePositions="form.sourceType.value == DocumentSourceType.upload_new"
                )
a-button.mt-3(@click="createDocumentWrapper" type="primary" :loading="loading" :disabled="disabled") {{ mode === 'create' ? 'Создать' : 'Сохранить' }}
a-modal(
    v-model:visible="additionalModals[currentModal].showModal"
    :title="additionalModals?.[currentModal]?.title"
    :footer="null"
    @cancel="setModalVisibility(currentModal, false)"
    :destroyOnClose="true"
)
    component(
        v-if="currentModal"
        :is="additionalModals[currentModal].component"
        @ok="onDirectoryAdded"
        :directories="directories"
        :workspaceId="workspaceId"
    )
</template>

<script>
import {computed, onMounted, reactive, ref} from 'vue';
import {useFormErrors} from '@/composition/errors';
import {notification} from 'ant-design-vue';
import CurrencySelect from '@/components/Generic/CurrencySelect';
import CardTitle from '@/components/Generic/Typography/CardTitle';
import {useDirectory} from '@/composition/directory';
import {selectFilterFunc, toLabelValue} from '@/modules/utils';
import InputWithError from '@/components/Generic/InputWithError';
import Uploader from '@/components/Generic/Uploader';
import {useDocument} from '@/composition/document';
import {useWorkspace} from '@/composition/workspace';
import CreateDocumentTypeForm from '@/components/Directories/Documents/Type/CreateForm';
import CreateDocumentTransportForm from '@/components/Directories/Documents/Transport/CreateForm';
import CreateDocumentStatusForm from '@/components/Directories/Documents/Statuses/CreateForm';
import AddDirectory from '@/components/Generic/AddDirectory/AddDirectory';
import SelectSuspense from '@/components/Generic/SelectSuspense';
import ContactsSelect from '@/components/Contacts/Select/Contacts';
import ServicesSelect from '@/components/Services/Select';
import {DocumentSourceType, DocumentSourceTypeMap, DocumentType} from '@/components/Documents/constants';
import {useDate} from '@/composition/date';
import {useAuth} from '@/composition/auth';
import {BillType} from '@/components/Bills/constants';
import {useTimelog} from '@/composition/timelog';
import DocumentServices from '@/components/Documents/Services';
import {useContactV2} from '@/composition/contact-v2';
import {useDownload} from '@/composition/download';
export default {
    name: 'CreateDocumentForm',
    components: {
        DocumentServices,
        ContactsSelect,
        SelectSuspense,
        AddDirectory,
        CreateDocumentStatusForm,
        CreateDocumentTransportForm,
        ServicesSelect,
        CreateDocumentTypeForm, Uploader, InputWithError, CardTitle, CurrencySelect},
    props: {
        workspaceId: [String, Number],
        mode: {
            type: String,
            validator: val => ['create', 'edit'].findIndex(x => x === val) !== -1,
            default: 'create'
        },
        document: {
            type: Object,
            default: () => ({})
        },
        hideService: Boolean,
        serviceId: [Number, String],
        paymentPeriod: {
            type: Object,
        }
    },
    emits: ['ok', 'update:document'],
    setup(props, {emit}) {
        const loading = ref(false);
        const currentModal = ref('documentTypes');
        const {getDateFromNow, getDateRangeWithOffset, formatDate} = useDate();
        const {download} = useDownload();
        const {getTimeLogsByServiceId} = useTimelog();
        const {user} = useAuth();
        const {init} = useFormErrors();
        const {createDocument, getDefaultNumber, getDocuments, localFilter: documentFilter, localDocuments, setLocalFilter: setDocumentFilter, downloadTemplate} = useDocument(loading, true);
        const {directories, getDirectories} = useDirectory(loading);
        const {getMembers, members, setFilter: setMemberFilter} = useWorkspace(loading);
        const additionalModals = reactive({
            documentTypes: {
                showModal: false,
                component: 'create-document-type-form',
                title: 'Добавить тип документа'
            },
            documentTransports: {
                showModal: false,
                component: 'create-document-transport-form',
                title: 'Добавить способ обмена'
            },
            documentStatuses: {
                showModal: false,
                component: 'create-document-status-form',
                title: 'Добавить статус'
            },
        });
        const setModalVisibility = (type, bool) => {
            currentModal.value = type;
            additionalModals[type].showModal = bool;
            if (!bool) {
                currentModal.value = 'documentTypes';
            }
        };
        /*================CONTACTS AREA======================*/
        const {
            clients: customers,
            getClients: getCustomers,
            getContactById,
        } = useContactV2(loading);
        const {
            companies: performers,
            getCompanies: getPerformers,
        } = useContactV2(loading);

        const labeledCustomersList = computed(() => {
            return toLabelValue(customers.value.list);
        });

        const onCustomerSearch = (q) => {
            getCustomers(props.workspaceId, q, false);
        };

        const labeledPerformersList = computed(() => {
            return toLabelValue(performers.value.list);
        });

        const onPerformerSearch = (q) => {
            getPerformers(props.workspaceId, q);
        };

        const onCustomerChanged = async (customerId) => {
            const contract = directories.value.documentTypes.list.find(x => x.key === DocumentType.contract);
            const filter = {
                customerId,
            };
            if (contract) {
                filter.documentTypes = [contract.id];
            }
            setDocumentFilter(filter);
            await getDocuments(props.workspaceId);
        };

        const onDirectoryAdded = async () => {
            setModalVisibility(currentModal.value, false);
            await getDirectories(props.workspaceId, 'documents');
        };
        const workspaceMemberId = user.value.workspaceMemberId;
        const form = reactive({
            documentTypeId: {
                value: props.document.documentTypeId,
                error: ''
            },
            baseDocumentId: {
                value: props.document.baseDocumentId,
                error: ''
            },
            number: {
                value: props.document.number,
                error: undefined
            },
            sourceType: {
                value: props.document.sourceType || DocumentSourceType.create_new,
                error: undefined
            },
            date: {
                value: props.document.date || getDateFromNow(),
                error: undefined
            },
            priceDisabled: {
                value: props.document.priceDisabled,
                error: undefined
            },
            services: {
                value: props.document.services || [],
                error: undefined
            },
            customerId: {
                value: props.document.customerId,
                error: undefined
            },
            performerId: {
                value:  props.document.performerId,
                error: undefined
            },
            sum: {
                value: props.document.sum,
                error: undefined
            },
            currency: {
                value: props.document.currency,
                error: undefined,
            },
            documentUrl: {
                value: props.document.documentUrl,
                error: undefined,
            },
            statusId: {
                value: props.document.statusId,
                error: undefined,
            },
            transportId: {
                value: props.document.transportId,
                error: undefined
            },
            deadline: {
                value: props.document.deadline || getDateFromNow(10),
                error: undefined,
            },
            directorId: {
                value: props.document.directorId || workspaceMemberId,
                error: undefined
            }
        });
        const clearErrors = () => {
            for (const formKey in form) {
                if (form[formKey]) {
                    form[formKey].error = undefined;
                }
            }
        };
        const currentTypeKey = computed(() => {
            const documentType = directories.value.documentTypes.list.find(x => x.id === form.documentTypeId.value);
            if (!documentType) return null;
            return documentType.key;
        });
        const createDocumentWrapper = async () => {
            const documentData = {
                documentTypeId: form.documentTypeId.value,
                baseDocumentId: form.baseDocumentId.value,
                number: form.number.value,
                services: form.services.value.map(x => ({
                    serviceId: x.serviceId,
                    paymentPeriodId: x.paymentPeriod ? x.paymentPeriod.id : undefined,
                    items: x.items
                })),
                customerId: form.customerId.value,
                performerId: form.performerId.value,
                date: form.date.value,
                priceDisabled: form.priceDisabled.value,
                sum: form.sum.value,
                currency: form.currency.value,
                documentUrl: form.documentUrl.value,
                statusId: form.statusId.value,
                transportId: form.transportId.value,
                deadline: form.deadline.value,
                directorId: form.directorId.value,
                workspaceId: props.workspaceId,
                sourceType: form.sourceType.value
            };
            if (props.mode === 'create') {
                const {ok, result} = await init(form, async () => {
                    return createDocument(props.workspaceId, documentData);
                }, clearErrors);
                if (ok) {
                    // result.sourceType === DocumentSourceType.create_new && downloadTemplate(result.id);
                    notification.success({
                        description: 'Документ создан',
                        message: 'Успех!'
                    });
                    emit('ok');
                }
            } else if (props.mode === 'edit') {
                return emit('update:document', {...documentData, id: props.document.id || Symbol(Date.now())});
            }
        };
        const disabled = computed(() => {
            const mainCondition = !form.documentTypeId.value ||
                   !form.deadline.value                      ||
                   !form.statusId.value                      ||
                   !form.customerId.value                    ||
                   !form.performerId.value                   ||
                   !form.transportId.value                   ||
                   !form.directorId.value                    ||
                   !form.services.value.length               ||
                   !form.services.value?.[0]?.serviceId;
            const createNewCondition = form.services.value?.[0]?.items.some(x => !x.title || !x.amount || !x.unitId || !x.sum);
            const uploadNewCondition = !form.documentUrl.value;
            if (currentTypeKey.value === DocumentType.completion_certificate) {
                return mainCondition && createNewCondition;
            }
            return mainCondition || uploadNewCondition;
        });

        const onSearch = async(type, q) => {
            switch (type) {
                case 'member':
                    setMemberFilter({search: q});
                    return getMembers(props.workspaceId, {});
            }
        };
        const onDocumentTypeChanged = (id) => {
            const sourceType = currentTypeKey.value === DocumentType.completion_certificate ? DocumentSourceType.create_new : DocumentSourceType.upload_new;
            form.sourceType.value = sourceType;
        };
        const onUpload = (data) => {
            form.documentUrl.value = data.key;
        };
        const deleteDocumentUrl = () => {
            form.documentUrl.value = undefined;
        };
        const setLogData = async (filter) => {
            const {ok, result} = await getTimeLogsByServiceId(props.workspaceId, filter.serviceId, filter.paymentPeriodId, BillType.incoming);
            if (ok && result.length) {
                form.services.value = form.services.value.map(x => {
                    if (x.serviceId === filter.serviceId) {
                        return {
                            ...x,
                            items: result
                        };
                    }
                    return x;
                });
            }
        };
        const additionalServiceFilter = computed(() => {
            const filter = {};
            filter.clients = [form.customerId.value];
            return filter;
        });
        const onDocumentsSearch = async (q) => {
            setDocumentFilter({...documentFilter.value, search: q});
            await getDocuments(props.workspaceId);
        };
        const onDocumentDownload = async () => {
            if (form.sourceType.value === DocumentSourceType.create_new) {
                await downloadTemplate(props.document.id);
            } else if (form.sourceType.value === DocumentSourceType.url) {
                window.open(form.documentUrl.value, '_blank');
            } else {
                const {ok, result} = await download(props.workspaceId, form.documentUrl.value);
                if (ok) {
                    window.open(result.url, '_blank');
                }
            }
        };
        onMounted(async () => {
            await getDirectories(props.workspaceId, 'contacts,documents,bills');
            await getMembers(props.workspaceId);
            await getDocuments(props.workspaceId);
            await getCustomers(props.workspaceId, undefined, false);
            await getPerformers(props.workspaceId);
            const {result} = await getDefaultNumber(props.workspaceId);
            if (result) {
                form.number.value = form.number.value || result;
            }
            if (props.mode !== 'edit') {
                const draftStatus = directories.value.documentStatuses.list.find(x => x.key === 'new');
                form.statusId.value = draftStatus?.id;
                const paymentPeriodContactId = props.paymentPeriod?.service?.contactId;
                if (paymentPeriodContactId) {
                    form.customerId.value = paymentPeriodContactId;
                    await onCustomerChanged(paymentPeriodContactId);
                    form.services.value = [{
                        id: Symbol(Date.now()),
                        items: [
                            {
                                id: Symbol(Date.now()),
                                title: undefined,
                                amount: undefined,
                                unitId: undefined,
                                sum: undefined,
                            }
                        ],
                        serviceId: props.paymentPeriod.service.id,
                        paymentPeriod: props.paymentPeriod,
                    }];
                    await setLogData({
                        from: props.paymentPeriod.from,
                        to: props.paymentPeriod.to,
                        serviceId: props.paymentPeriod.service.id,
                        paymentPeriodId: props.paymentPeriod.id,
                    });
                }
            } else {
                await onCustomerChanged(props.document.customerId);
            }
        });
        return {
            onDocumentDownload,
            labeledCustomersList,
            onCustomerSearch,
            labeledPerformersList,
            onPerformerSearch,
            onCustomerChanged,
            disabled,
            loading,
            createDocumentWrapper,
            currentTypeKey,
            form,
            directories,
            selectFilterFunc,
            onSearch,
            onUpload,
            formatDate,
            deleteDocumentUrl,
            members,
            currentModal,
            additionalModals,
            setModalVisibility,
            onDirectoryAdded,
            DocumentType,
            DocumentSourceType,
            DocumentSourceTypeMap,
            setLogData,
            additionalServiceFilter,
            localDocuments,
            onDocumentsSearch,
            onDocumentTypeChanged,
        };
    }
};
</script>

<style lang="scss" scoped>
.create-document-form {
    &__group {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 15px;
    }

}
</style>

