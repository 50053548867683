<template lang="pug">
settings-directories-others-layout
    async-page
        units-table
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import UnitsTable from '@/components/Directories/Bills/Unit/Table';
import SettingsDirectoriesOthersLayout from '@/pages/Settings/Directories/Others/Layout/index';
export default {
    name: 'UnitsPage',
    components: {SettingsDirectoriesOthersLayout, UnitsTable, AsyncPage}
};
</script>

<style scoped>

</style>
