<template lang="pug">
.transactions-table
    a-button.mb-2(type="primary" @click="setModalVisibility(true)") Добавить
    transaction-filter.mb-2(@filter="onFilterChanged" :periods="periods")
    custom-table(
        :columns="columns"
        :data-source="transactions.list"
        :onRowClick="editTransaction"
    )
        template(#date="{text, record}")
            div(v-if="record") {{ formatDate(record.date) }}
        template(#description="{text, record}")
            div(v-if="record") {{ record.description }}
        template(#amount="{text, record}")
            .transactions-table__amount(v-if="record" :class="[getColor(record)]") {{ `${getSign(record)} ${record.amount} ${getCurrency(record)}` }}
        template(#contractor="{text, record}")
            div(v-if="record") {{ getContractor(record) }}
        template(#bankAccount="{text, record}")
            div(v-if="record") {{ getBankAccountTitle(record) }}
        template(#services="{text, record}")
            div(v-if="record") {{ getServicesList(record) }}
        template(#action="{text, record}")
            delete-with-confirm(:deleteFunc="() => onDelete(record.id)")
    a-button.mt-3(@click="next" v-if="transactions.next" :loading="loading") Показать еще
    a-modal(
        v-model:visible="showModal"
        title="Добавить транзакцию"
        :footer="null"
        @cancel="setModalVisibility(false)"
        :destroyOnClose="true"
    )
        create-transaction-form(
            :workspaceId="currentWorkspaceId"
            @update="onUpdate"
            @ok="onCreate"
            :mode="currentMode"
            :default-value="editingElement"
        )
</template>

<script>
import {serviceTransactionColumns, transactionType} from '@/components/Transactions/constants';
import {ref} from 'vue';
import constants from '@/constants';
import {useWorkspace} from '@/composition/workspace';
import EditableCell from '@/components/Generic/EditableCell';
import Icon from '@/components/Generic/Typography/Icon';
import {useTransaction} from '@/composition/transaction';
import CreateTransactionForm from '@/components/Transactions/CreateForm';
import {useDate} from '@/composition/date';
import TransactionFilter from '@/components/Transactions/Filter';
import CustomTable from '@/components/Generic/CustomTable';
import {useCreateOrUpdateModal} from '@/composition/create-update-modal';
import {useRoute} from 'vue-router';
import {useServiceV2} from '@/composition/service-v2';
export default {
    name: 'ServiceTransactionsTable',
    components: {CustomTable, TransactionFilter, CreateTransactionForm, Icon, EditableCell},
    async setup() {
        const {
            currentMode,
            editingValue: editingElement,
            showModal,
            setModalVisibility,
            edit
        } = useCreateOrUpdateModal();
        const route = useRoute();
        const loading = ref(false);
        const {currentWorkspaceId} = useWorkspace(loading);
        const {periods, getPeriods} = useServiceV2();
        const {pagination, deleteServiceTransaction, getServiceTransactions, updateTransaction, transactions, setFilter} = useTransaction(loading);
        const {formatDate} = useDate();
        await getServiceTransactions(currentWorkspaceId.value, route.params.serviceId);
        const onDelete = async (id) => {
            pagination.value.offset = 0;
            await deleteServiceTransaction(currentWorkspaceId.value, id);
            await getServiceTransactions(currentWorkspaceId.value, route.params.serviceId);
        };
        const onUpdate = async (transaction) => {
            const updateData = {
                type: transaction.type,
                contractorModel: transaction.contractorModel,
                date: transaction.date,
                fromAccountId: transaction.fromAccountId,
                toAccountId: transaction.toAccountId,
                operationId: transaction.operationId,
                contractorId: transaction.contractorId,
                amount: transaction.amount,
                description: transaction.description,
                services: transaction.services,
                workspaceId: transaction.workspaceId,
            };
            const {ok} = await updateTransaction(currentWorkspaceId.value, editingElement.value.transactionId, updateData);
            if (ok) {
                pagination.value.offset = 0;
                await getServiceTransactions(currentWorkspaceId.value, route.params.serviceId);
                setModalVisibility(false);
            }
        };
        const onCreate = async () => {
            await getServiceTransactions(currentWorkspaceId.value, route.params.serviceId);
            setModalVisibility(false);
        };
        const editTransaction = (transaction) => {
            edit({
                ...transaction,
                amount: transaction.fullAmount,
                contractorId: transaction.contractorId
            });
        };
        const next = async () => {
            pagination.value.offset += pagination.value.limit;
            await getServiceTransactions(currentWorkspaceId.value, route.params.serviceId);
        };
        const onFilterChanged = async(filter) => {
            pagination.value.offset = 0;
            await getPeriods();
            setFilter(filter);
            await getServiceTransactions(currentWorkspaceId.value, route.params.serviceId);
        };
        const getSign = (transaction) => {
            switch (transaction.type) {
                case transactionType.incoming:
                    return '+';
                case transactionType.withdrawal:
                    return '-';
                default:
                    return '';
            }
        };
        const getColor = (transaction) => {
            switch (transaction.type) {
                case transactionType.incoming:
                    return 'success';
                case transactionType.withdrawal:
                    return 'danger';
                default:
                    return '';
            }
        };
        const getCurrency = (transaction) => {
            return constants.currencyMap[getBankAccount(transaction)?.currency];
        };
        const getContractor = (transaction) => {
            const contractor = transaction?.contractor?.title ? transaction?.contractor?.title : transaction?.contractor?.fullName;
            return contractor || 'Не указано';
        };
        const getBankAccount = (transaction) => {
            switch (transaction.type) {
                case transactionType.incoming:
                    return transaction.toAccount || {};
                case transactionType.withdrawal:
                    return transaction.fromAccount || {};
                case transactionType.transfer:
                    return {
                        currency: transaction.fromAccount.currency,
                        from: transaction.fromAccount || {},
                        to: transaction.toAccount || {}
                    };
            }
        };
        const getBankAccountTitle = (record) => {
            const bankAccount = getBankAccount(record);
            if (bankAccount.from && bankAccount.to) {
                return `${bankAccount.from.title} ⟶ ${bankAccount.to.title}`;
            }
            return bankAccount.title;
        };
        const getServicesList = (transaction) => {
            return transaction.services.map(service => {
                return service?.service?.title;
            }).join(', ');
        };
        return {
            transactions,
            loading,
            setModalVisibility,
            showModal,
            currentWorkspaceId,
            next,
            periods,
            onCreate,
            onDelete,
            onUpdate,
            columns: serviceTransactionColumns,
            onFilterChanged,
            currencyMap: constants.currencyMap,
            transactionType,
            getSign,
            getCurrency,
            getContractor,
            getBankAccountTitle,
            formatDate,
            getServicesList,
            editTransaction,
            editingElement,
            currentMode,
            getColor,
        };
    }
};
</script>

<style lang="scss" scoped>
.transactions-table {
    &__amount {
        font-weight: 600;
    }
}
</style>
