import {computed, ref} from 'vue';

export function useSelect(mode = 'single', props, emit) {
    const searchInput = ref(null);
    const show = ref(false);
    const showClear = ref(false);
    const selected = getSelectedValue(mode);
    const search = ref(null);
    const isSearching = ref(false);
    const emptySearch = 'Нет данных';
    const openMultiselect = () => {
        if (props.disabled) return;
        show.value = true;
        isSearching.value = true;
        if (searchInput.value) {
            searchInput.value.focus();
        }
    };
    const closeMultiselect = () => {
        console.log('closeMultiselect');
        if (show.value) {
            show.value = false;
            isSearching.value = false;
            search.value = null;
            emit('update:modelValue', selected.value);
            emit('blur', selected.value);
        }
    };
    const searchItems = computed(() => {
        if (props.serverSearch) return props.options;
        if (search.value !== null && search.value !== '') {
            const found = props.options.filter(x => {
                return x[props.accessor].toLowerCase().indexOf(search.value.toLowerCase()) !== -1;
            });
            return found;
        } else {
            return props.options;
        }
    });
    const getTitle = getGetTitleMethod(mode, selected, props.accessor, props.title);
    const init = getInitMethod(mode, selected, props);
    const isItemSelected = getIsItemSelectedMethod(mode, selected);
    const toggleSelect = getToggleSelectMethod(mode, selected, isSearching, search, isItemSelected, closeMultiselect);
    const clear = getClearMethod(mode, selected, emit);
    const onSearch = (e) => {
        if (props.serverSearch) {
            emit('search', e.target.value);
        }
    };
    return {
        showClear,
        show,
        selected,
        search,
        isSearching,
        emptySearch,
        onSearch,
        openMultiselect,
        closeMultiselect,
        clear,
        getTitle,
        searchItems,
        init,
        toggleSelect,
        isItemSelected,
        searchInput
    };
}

function getInitMethod(mode, selected, props) {
    switch (mode) {
        case 'single':
            return () => {
                if (props.modelValue && Object.keys(props.modelValue).length) {
                    const defaultValue = props.options.find(x => x.id === props.modelValue.id);
                    if (defaultValue) {
                        selected.value = defaultValue;
                    }
                } else {
                    selected.value = {};
                }
            };
        case 'multiple':
            return () => {
                if (!props.modelValue || !Array.isArray(props.modelValue)) return;
                const isAllSelected = props.modelValue.findIndex(x => x === '*') !== -1;
                if (isAllSelected) {
                    selected.value = [];
                    for (const x of props.options) {
                        selected.value.push(x);
                    }
                }
                if (props.modelValue && props.modelValue.length) {
                    selected.value = props.modelValue;
                }
            };
    }
}

function getToggleSelectMethod(mode, selected, isSearching, search, isItemSelected, closeMultiselect) {
    switch (mode) {
        case 'single':
            return (item) => {
                selected.value = item;
                isSearching.value = false;
                search.value = null;
                closeMultiselect();
            };
        case 'multiple':
            return (item) => {
                const selectIndex = selected.value.findIndex(x => x.id === item.id);
                if (selectIndex === -1 && !isItemSelected(item.id)) {
                    selected.value.push(item);
                } else {
                    selected.value = selected.value.filter(x => x.id !== item.id);
                }
                isSearching.value = false;
                search.value = null;
            };
    }
}

function getIsItemSelectedMethod(mode, selected) {
    switch (mode) {
        case 'single':
            return (id) => {
                return selected?.value?.id === id;
            };
        case 'multiple':
            return (id) => {
                return selected.value.findIndex(x => x.id === id) !== -1;
            };
    }
}

function getGetTitleMethod(mode, selected, accessor, title) {
    switch (mode) {
        case 'single':
            return () => selected.value && Object.keys(selected.value).length ? selected.value[accessor] : `${title}`;
        case 'multiple':
            return () => selected.value.length == 0 ? title : `${title} (${selected.value.length})`;
    }
}

function getSelectedValue(mode) {
    switch (mode) {
        case 'single':
            return ref({});
        case 'multiple':
            return ref([]);
    }
}
function getClearMethod(mode, selected, emit) {
    switch (mode) {
        case 'single':
            return () => {
                selected.value = {};
                emit('update:modelValue', selected.value);
            };
        case 'multiple':
            return () => {
                console.log('clear');
                selected.value = [];
                emit('update:modelValue', selected.value);
            };
    }
}
