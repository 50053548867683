<template lang="pug">
.create-transaction-form
    input-with-error(:error="form.type.error")
        template(#input)
            a-select(
                v-model:value="form.type.value"
                placeholder="Тип транзакции"
                style="width:100%"
                allowClear
            )
                a-select-option(
                    v-for="transactionTypeEl in transactionType"
                    :key="transactionTypeEl"
                    :value="transactionTypeEl"
                )
                    | {{ transactionTypeMap[transactionTypeEl] }}
    input-with-error(:error="form.contractorId.error" v-if="form.type.value !== transactionType.transfer")
        template(#input)
            a-select(
                v-model:value="form.contractorId.value"
                placeholder="Контрагент"
                style="width:100%"
                allowClear
                show-search
                :filter-option="false"
                :label-in-value="false"
                :options="labeledContactsList"
                @search="onContactsSearch"
            )

    .create-transaction-form(v-if="form.type.value")
        .flex
            input-with-error(:error="form.fromAccountId.error" v-if="form.type.value == transactionType.transfer || form.type.value == transactionType.withdrawal")
                template(#input)
                    a-select(
                        v-model:value="form.fromAccountId.value"
                        placeholder="Со счета"
                        style="width:100%"
                        :filter-option="selectFilterFunc"
                        show-search
                        allowClear
                        :disabled="transactionSource.with_bill === source && form.fromAccountId.value"
                    )
                        a-select-option(
                            v-for="bankAccount in directories.bankAccounts.list"
                            :key="bankAccount.id"
                            :value="bankAccount.id"
                            :label="bankAccount.title"
                        )
                            | {{ bankAccount.title }} ({{ bankAccount?.company?.title || 'Без компании' }})
                        a-select-option.cursor-pointer(disabled @click="() => setModalVisibility('bankAccounts', true)") Добавить
                        template(#notFoundContent)
                            add-directory(:onClick="() => setModalVisibility('bankAccounts', true)")
            input-with-error.ml-2(:error="form.fromAmount.error" v-if="form.type.value == transactionType.transfer")
                template(#input)
                    a-input-number(style="width: 100%" v-model:value="form.fromAmount.value" placeholder="Стоимость" :min="0")
            currency-select.ml-3(:model-value="fromAccount?.currency" :disabled="true" v-if="form.type.value == transactionType.transfer")
        .flex
            input-with-error(:error="form.toAccountId.error" v-if="form.type.value == transactionType.transfer || form.type.value == transactionType.incoming")
                template(#input)
                    a-select(
                        v-model:value="form.toAccountId.value"
                        placeholder="На счет"
                        style="width:100%"
                        :filter-option="selectFilterFunc"
                        show-search
                        allowClear
                        :disabled="transactionSource.with_bill === source && form.fromAccountId.value"
                    )
                        a-select-option(
                            v-for="bankAccount in directories.bankAccounts.list"
                            :key="bankAccount.id"
                            :value="bankAccount.id"
                            :label="bankAccount.title"
                        )
                            | {{ bankAccount.title }} ({{ bankAccount?.company?.title || 'Без компании' }})
                        a-select-option.cursor-pointer(disabled @click="() => setModalVisibility('bankAccounts', true)") Добавить
                        template(#notFoundContent)
                            add-directory(:onClick="() => setModalVisibility('bankAccounts', true)")
            input-with-error.ml-2(:error="form.toAmount.error" v-if="form.type.value == transactionType.transfer")
                template(#input)
                    a-input-number(
                        style="width: 100%"
                        v-model:value="form.toAmount.value"
                        placeholder="Стоимость"
                        :min="0"
                        :disabled="isTransferAccountCurrencyEqual"
                    )
            currency-select.ml-3(:model-value="toAccount?.currency" :disabled="true" v-if="form.type.value == transactionType.transfer")
        input-with-error(
            :error="form.services.error"
            v-if="form.type.value !== transactionType.transfer"
        )
            template(#input)
                div(v-if="form.contractorId.value")
                    card-title Услуги
                    transaction-services-list(
                        v-model:transactionServices="form.services"
                        :disabled="transactionSource.with_bill === source || transactionSource.with_salary_payments_list === source"
                        :additionalServiceFilter="additionalServiceFilter"
                        :currency="currency"
                    )
                .mt-3(v-if="source !== transactionSource.with_bill && form.contractorId.value")
                    card-title Прочее
                    transaction-positions-list(
                        v-model:positions="form.positions"
                        :disabled="transactionSource.with_bill === source || transactionSource.with_salary_payments_list === source"
                        :currency="currency"
                    )
        card-title(size="small" v-if="totalAmount").mt-2 {{ formatCurrency(totalAmount) }} {{ currencyMap[currency] }}
        input-with-error(:error="form.operationId.error" v-if="form.type.value !== transactionType.transfer")
            template(#input)
                a-select(
                    v-model:value="form.operationId.value"
                    placeholder="Статья операции"
                    style="width:100%"
                    :filter-option="selectFilterFunc"
                    show-search
                    allowClear
                )
                    a-select-option(
                        v-for="operation in directories.billOperations.list"
                        :key="operation.id"
                        :value="operation.id"
                        :label="operation.title"
                    )
                        | {{ operation.title }}
                    a-select-option.cursor-pointer(disabled @click="() => setModalVisibility('billOperations', true)") Добавить
                    template(#notFoundContent)
                        add-directory(:onClick="() => setModalVisibility('billOperations', true)")
        input-with-error(:error="form.period.error")
            template(#input)
                simple-month-period-picker(
                    v-model:period="form.period.value"
                    :disabled="source === transactionSource.with_salary_payments_list"
                )
        input-with-error(:error="form.date.error")
            template(#input)
                a-date-picker(v-model:value="form.date.value" placeholder="Дата операции" style="width: 100%" format="DD.MM.YYYY")
        input-with-error(
            :error="form.billId.error"
        )
            template(#input)
                bills-select(v-model="form.billId.value" placeholder="Счет" @init="onBillSelectInit")
        input-with-error(:error="form.isScheduled.error" v-if="form.type.value !== transactionType.transfer")
            template(#input)
                a-checkbox(
                    v-model:checked="form.isScheduled.value"
                    :disabled="isScheduledDisabled"
                ) Плановая
        input-with-error(:error="form.isRepeatable.error" v-if="form.type.value !== transactionType.transfer")
            template(#input)
                a-checkbox(v-model:checked="form.isRepeatable.value" :disabled="mode === 'edit'") Повторять транзакцию
        input-with-error(:error="form.periodicity.error" v-if="form.type.value !== transactionType.transfer && form.isRepeatable.value")
            template(#input)
                a-radio-group(
                    v-model:value="form.periodicity.value"
                    :disabled="mode === 'edit'"
                )
                    a-radio(
                        :value="transactionPeriodicity.month"
                    ) {{ transactionPeriodicityMap.month }}
        input-with-error(:error="form.lastRepeatDate.error" v-if="form.type.value !== transactionType.transfer && form.isRepeatable.value")
            template(#input)
                date-only-picker(v-model:date="form.lastRepeatDate.value" placeholder="Дата последней транзакции" :disabled="mode === 'edit'")
    .flex.items-center.mt-3
        a-button(
            @click="createTransactionWrapper"
            type="primary"
            :loading="loading"
            :disabled="disabled"
            style="width: 180px"
        ) {{ mode === 'edit' ? 'Сохранить' : 'Создать' }}
        delete-with-confirm.ml-3(
            :deleteFunc="() => deleteTransaction()"
            v-if="mode === 'edit'"
        )
    a-modal(
        v-model:visible="additionalModals[currentModal].showModal"
        :title="additionalModals?.[currentModal]?.title"
        :footer="null"
        @cancel="setModalVisibility(currentModal, false)"
        :destroyOnClose="true"
    )
        component(
            v-if="currentModal"
            :is="additionalModals[currentModal].component"
            @ok="onDirectoryAdded"
            :directories="directories"
            :workspaceId="workspaceId"
        )

</template>

<script>
import {computed, onMounted, reactive, ref, watch} from 'vue';
import {useFormErrors} from '@/composition/errors';
import {notification} from 'ant-design-vue';
import CurrencySelect from '@/components/Generic/CurrencySelect';
import CardTitle from '@/components/Generic/Typography/CardTitle';
import {useDirectory} from '@/composition/directory';
import {selectFilterFunc, toLabelValue} from '@/modules/utils';
import InputWithError from '@/components/Generic/InputWithError';
import {
    transactionPeriodicity, transactionPeriodicityMap,
    transactionSource,
    transactionSourceMap,
    transactionType,
    transactionTypeMap,
} from '@/components/Transactions/constants';
import {useTransaction} from '@/composition/transaction';
import TransactionServicesList from '@/components/Transactions/TransactionServicesList';
import moment from 'moment';
import CreateBankAccountForm from '@/components/Directories/Bills/BankAccount/CreateForm';
import CreateOperationForm from '@/components/Directories/Bills/Operation/CreateForm';
import AddDirectory from '@/components/Generic/AddDirectory/AddDirectory';
import BillsSelect from '@/components/Bills/Select';
import SelectSuspense from '@/components/Generic/SelectSuspense';
import ContactsSelect from '@/components/Contacts/Select/Contacts';
import TransactionSourceSelect from '@/components/Transactions/CreateForm/SourceSelect';
import TransactionPeriodSelect from '@/components/Transactions/CreateForm/PeriodSelect';
import {useDate} from '@/composition/date';
import {operationAction} from '@/components/Directories/Bills/Operation/constants';
import TransactionPositionsList from '@/components/Transactions/TransactionPositionsList';
import {useContactV2} from '@/composition/contact-v2';
import SimpleMonthPeriodPicker from '@/components/Generic/SimpleMonthPeriodPicker';
import DateOnlyPicker from '@/components/Generic/DateOnlyPicker';
import Big from 'big.js';
import {toFixedNumber, formatCurrency} from '@/modules/utils/v2';
import constants from '@/constants';
import DeleteWithConfirm from '@/components/Generic/DeleteWithConfirm/index';
export default {
    name: 'CreateTransactionForm',
    components: {
        DeleteWithConfirm,
        DateOnlyPicker,
        SimpleMonthPeriodPicker,
        TransactionPositionsList,
        TransactionPeriodSelect,
        TransactionSourceSelect,
        ContactsSelect,
        SelectSuspense,
        BillsSelect,
        AddDirectory,
        CreateOperationForm,
        CreateBankAccountForm, TransactionServicesList, InputWithError, CardTitle, CurrencySelect},
    props: {
        workspaceId: [String, Number],
        mode: String,
        defaultValue: Object,
        billId: [Number, String],
        salaryListId: Number,
    },
    emits: ['ok', 'update', 'delete'],
    setup(props, {emit}) {
        const loading = ref(false);
        const {toDateOnly, getMonthFromNow, dateInstance, inFuture} = useDate();
        const {
            contacts,
            filter: contactFilter,
            getContacts,
            setFilter: setContactFilter
        } = useContactV2(loading);
        const currentModal = ref('bankAccounts');
        const source = ref(transactionSource.other);
        const additionalModals = reactive({
            bankAccounts: {
                showModal: false,
                component: 'create-bank-account-form',
                title: 'Добавить счет'
            },
            billOperations: {
                showModal: false,
                component: 'create-operation-form',
                title: 'Добавить статью'
            }
        });
        const setModalVisibility = (type, bool) => {
            currentModal.value = type;
            additionalModals[type].showModal = bool;
            if (!bool) {
                currentModal.value = 'bankAccounts';
            }
        };
        const onDirectoryAdded = async () => {
            setModalVisibility(currentModal.value, false);
            await getDirectories(props.workspaceId, 'bills');
        };
        const form = reactive({
            type: {
                value: props.defaultValue.type,
                error: ''
            },
            period: {
                value: props.defaultValue.period || getMonthFromNow().format('YYYY-MM-01'),
                error: undefined
            },
            date: {
                value: props.defaultValue.date || moment(),
                error: undefined,
            },
            fromAccountId: {
                value: props.defaultValue.fromAccountId,
                error: undefined,
            },
            fromAmount: {
                value: props.defaultValue.fromAmount,
                error: undefined,
            },
            toAmount: {
                value: props.defaultValue.toAmount,
                error: undefined,
            },
            toAccountId: {
                value: props.defaultValue.toAccountId,
                error: undefined,
            },
            operationId: {
                value: props.defaultValue.operationId,
                error: undefined,
            },
            contractorId: {
                value: props.defaultValue.contractorId,
                error: undefined,
            },
            amount: {
                value: props.defaultValue.amount,
                error: undefined,
            },
            billId: {
                value: props.defaultValue.billId,
                error: undefined,
            },
            salaryListId: {
                value: props.defaultValue.salaryListId || props.salaryListId,
                error: undefined,
            },
            services: {
                value: props.defaultValue.services || [],
                error: undefined,
            },
            positions: {
                value: props.defaultValue.positions || [],
                error: undefined,
            },
            isScheduled: {
                value: props.defaultValue.isScheduled || false,
                error: undefined,
            },
            isRepeatable: {
                value: props.defaultValue.isRepeatable || false,
                error: undefined,
            },
            lastRepeatDate: {
                value: props.defaultValue.lastRepeatDate || undefined,
                error: undefined,
            },
            periodicity: {
                value: props.defaultValue.periodicity || undefined,
                error: undefined,
            },
        });
        const clearErrors = () => {
            for (const formKey in form) {
                if (form[formKey]) {
                    form[formKey].error = undefined;
                }
            }
        };
        const totalAmount = computed(() => {
            const totalByServices = form.services.value.reduce((prev, curr) => {
                return prev.plus(curr.amount || 0);
            }, Big(0));
            const totalByPositions = form.positions.value.reduce((prev, curr) => {
                return prev.plus(curr.amount || 0);
            }, Big(0));
            return toFixedNumber(totalByPositions.plus(totalByServices));
        });
        const additionalServiceFilter = computed(() => {
            const filter = {
                notArchived: true,
            };
            if (form.type.value === transactionType.incoming && form.contractorId.value) {
                filter.clients = [form.contractorId.value];
                return filter;
            }
            if (form.type.value === transactionType.withdrawal && form.contractorId.value) {
                const contactor = contacts.value.list.find(x => x.id === form.contractorId.value);
                if (!contactor) return filter;
                if (contactor.workspaceMemberId) {
                    filter.performers = [{id: contactor.workspaceMemberId}];
                    return filter;
                }
                if (contactor.companyId) return filter;
                filter.clients = [form.contractorId.value];
                return filter;
            }
            return filter;
        });
        const {createTransaction} = useTransaction(loading);
        const {init} = useFormErrors();
        const createTransactionWrapper = async () => {
            const transactionData = {
                type: form.type.value,
                date: toDateOnly(form.date.value),
                period: form.period.value,
                fromAccountId: form.fromAccountId.value,
                toAccountId: form.toAccountId.value,
                fromAmount: form.fromAmount.value,
                toAmount: form.toAmount.value,
                operationId: form.operationId.value,
                contractorId: form.contractorId.value,
                amount: form.amount.value,
                billId: form.billId.value,
                salaryListId: form.salaryListId.value,
                services: form.services.value.map(x => ({
                    amount: x.amount,
                    serviceId: x.serviceId,
                    paymentPeriodId: x.paymentPeriod ? x.paymentPeriod.id : undefined,
                })),
                positions: form.positions.value,
                workspaceId: props.workspaceId,
                isScheduled: form.isScheduled.value,
                isRepeatable: form.isRepeatable.value,
                lastRepeatDate: form.lastRepeatDate.value,
                periodicity: form.periodicity.value,
            };
            if (props.mode === 'edit') {
                loading.value = true;
                emit('update', {
                    ...transactionData,
                    id: props.defaultValue.id,
                    prevId: props?.defaultValue?.prevId,
                });
                //todo delete hack
                setTimeout(() => {
                    loading.value = false;
                }, 1500);
            } else {
                const {ok} = await init(form, async () => {
                    return createTransaction(props.workspaceId, transactionData);
                }, clearErrors);
                if (ok) {
                    notification.success({
                        description: 'Транзакция создана',
                        message: 'Успех!'
                    });
                    emit('ok');
                }
            }

        };
        const isScheduledDisabled = computed(() => {
            if (props.mode === 'edit') return false;
            return inFuture(form.date.value);
        });
        const fromAccount = computed(() => {
            return directories.value.bankAccounts.list.find(x => x.id === form.fromAccountId.value);
        });
        const toAccount = computed(() => {
            return directories.value.bankAccounts.list.find(x => x.id === form.toAccountId.value);
        });
        const currency = computed(() => {
            if (form.type.value === transactionType.incoming) {
                return toAccount.value?.currency;
            }
            if (form.type.value === transactionType.withdrawal) {
                return fromAccount.value?.currency;
            }
            return null;
        });
        const isTransferAccountCurrencyEqual = computed(() => {
            if (!fromAccount.value || !toAccount.value) return false;
            return fromAccount.value.currency === toAccount.value.currency;
        });
        const disabled = computed(() => {
            let repeatableCondition = false;
            if (form.isRepeatable.value) {
                repeatableCondition = !form.lastRepeatDate.value || !form.periodicity.value;
            }
            if (form.type.value === transactionType.withdrawal) {
                const baseCondition = !form.fromAccountId.value ||
                    !form.contractorId.value  ||
                    !form.operationId.value   ||
                    !form.period.value ||
                    !form.date.value;
                return baseCondition || repeatableCondition;
            }
            if (form.type.value === transactionType.incoming) {
                const baseCondition = !form.toAccountId.value ||
                    !form.contractorId.value  ||
                    !form.operationId.value   ||
                    !form.period.value ||
                    !form.date.value;
                return baseCondition || repeatableCondition;
            }
            if (form.type.value === transactionType.transfer) {
                return (
                    !form.fromAccountId.value ||
                    !form.toAccountId.value   ||
                    !form.fromAmount.value    ||
                    !form.toAmount.value      ||
                    !form.period.value        ||
                    !form.date.value
                );
            }
            return true;
        });
        watch(() => form.date.value, (newValue) => {
            const ms = dateInstance().utc(newValue);
            const now = dateInstance().utc();
            form.isScheduled.value = now < ms;
        });
        watch(() => form.isRepeatable.value, (newValue) => {
            if (newValue === true) {
                form.periodicity.value = transactionPeriodicity.month;
            } else {
                form.periodicity.value = undefined;
            }
            form.lastRepeatDate.value = undefined;
        });
        const {directories, getDirectories} = useDirectory(loading);
        const onBillSelectInit = () => {
            if (props.billId) {
                form.billId.value = Number(props.billId);
            }
        };
        const getOperationByTransactionType = (type) => {
            let action;
            switch (type) {
                case transactionType.withdrawal:
                    action = operationAction.outgo;
                    break;
                case transactionType.transfer:
                    action = operationAction.transfer;
                    break;
                case transactionType.incoming:
                    action = operationAction.incoming;
                    break;
            }
            return directories.value.billOperations.list.find(x => x.action === action);
        };
        /*==================CONTACTS AREA==============================*/
        const labeledContactsList = computed(() => {
            return toLabelValue(contacts.value.list);
        });
        const onContactsSearch = (q) => {
            setContactFilter({
                ...contactFilter.value,
                search: q,
            });
            getContacts(props.workspaceId);
        };

        watch(() => form.type.value, () => {
            form.toAccountId.value = undefined;
            form.fromAccountId.value = undefined;
            form.amount.value = undefined;
            const targetOperation = getOperationByTransactionType(form.type.value);
            form.operationId.value = targetOperation?.id;
        });
        watch(() => source.value, () => {
            form.billId.value = undefined;
            form.salaryListId.value = undefined;
            form.type.value = undefined;
            form.contractorId.value = undefined;
            form.services.value = [];
            form.positions.value = [];
        });
        watch(() => form.fromAmount.value, () => {
            if (isTransferAccountCurrencyEqual.value) {
                form.toAmount.value = form.fromAmount.value;
            }
        });
        watch(() => form.toAccountId.value, () => {
            if (isTransferAccountCurrencyEqual.value && form.type.value === transactionType.transfer) {
                form.toAmount.value = form.fromAmount.value;
            }
        });
        onMounted(async () => {
            await getContacts(props.workspaceId);
            await getDirectories(props.workspaceId, 'bills');
        });
        const deleteLoading = ref(false);
        const deleteTransaction = () => {
            deleteLoading.value = true;
            emit('delete', props.defaultValue, () => {
                deleteLoading.value = false;
            });
        };
        return {
            deleteLoading,
            deleteTransaction,
            form,
            source,
            loading,
            currency,
            disabled,
            toAccount,
            totalAmount,
            fromAccount,
            directories,
            currencyMap: constants.currencyMap,
            currentModal,
            transactionType,
            additionalModals,
            transactionSource,
            transactionTypeMap,
            isScheduledDisabled,
            transactionSourceMap,
            transactionPeriodicity,
            transactionPeriodicityMap,
            additionalServiceFilter,
            isTransferAccountCurrencyEqual,
            formatCurrency,
            onBillSelectInit,
            onContactsSearch,
            selectFilterFunc,
            onDirectoryAdded,
            setModalVisibility,
            labeledContactsList,
            createTransactionWrapper,
        };
    }
};
</script>

<style lang="scss" scoped>
.create-transaction-form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
}
</style>
