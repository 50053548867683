<template lang="pug">
.chart-wrapper(
    :style="{height: height ? `${height}px`: ''}"
)
    icon(type="Loader" v-if="loading")
    div(v-else)
        slot
</template>

<script>
import Icon from '@/components/Generic/Typography/Icon';
export default {
    name: 'ChartWrapper',
    components: {Icon},
    props: {
        loading: Boolean,
        height: Number,
    }
};
</script>

<style scoped>

</style>