import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input_number = _resolveComponent("a-input-number")!

  return (_openBlock(), _createBlock(_component_a_input_number, {
    class: "price-input",
    value: $props.price,
    min: 0,
    max: 100000000,
    formatter: $setup.formatCurrency,
    onChange: $setup.onChange,
    style: _normalizeStyle($props.style),
    placeholder: $props.placeholder,
    disabled: $props.disabled
  }, null, 8, ["value", "formatter", "style", "placeholder", "disabled"]))
}