<template lang="pug">
bill-layout
    async-page
        bill-transactions-table
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import BillLayout from '@/pages/Bills/Bill/Layout';
import BillTransactionsTable from '@/components/Bills/Transactions';
export default {
    name: 'BillTransactionsPage',
    components: {BillTransactionsTable, BillLayout, AsyncPage},
};
</script>

<style scoped>

</style>