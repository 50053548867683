<template lang="pug">
.date-only-picker
    a-date-picker(
        :value="date"
        format="DD.MM.YYYY"
        @change="onDateChanged"
        :placeholder="placeholder"
        style="width: 100%"
        :allowClear="clearable"
        :disabledDate="(momentInstance) => disabledDate(momentInstance, onlyForPeriod)"
        :disabled="disabled"
    )
</template>

<script>
export default {
    name: 'DateOnlyPicker'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
import {useDate} from '@/composition/date';
/*===============INIT AREA===================*/

const props = defineProps({
    date: {
        type: String,
    },
    placeholder: {
        type: String,
    },
    clearable: {
        type: Boolean,
        required: false,
        default: true,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
    onlyForPeriod: {
        type: String,
        required: false
    }
});
const emit = defineEmits(['update:date']);
const {toFormat, getMonthRangeFromPeriod, dateInstance} = useDate();
/*===============HANDLERS AREA===================*/
const onDateChanged = (dateInstance, dateString) => {
    if (!dateString) {
        emit('update:date', null);
    } else {
        emit('update:date', toFormat(dateString, 'DD.MM.YYYY', 'YYYY-MM-DD'));
    }
};
const disabledDate = (momentDate, period) => {
    if (!period) return false;
    const [from, to] = getMonthRangeFromPeriod(period);
    const fromDateInstance = dateInstance()(from);
    const toDateInstance = dateInstance()(to);
    return (momentDate.valueOf() < fromDateInstance.valueOf() || momentDate.valueOf() > toDateInstance.valueOf());
};
</script>

<style lang="scss">
</style>