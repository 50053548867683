import {computed, ref} from 'vue';
import {apiRequest} from '@/modules/error_handler';
import integrationModule from '@/modules/integration';

const steps = ref({
    projects: true,
    statuses: true,
    users: true,
});

export function useIntegration (loading={value: false}) {
    let integration = ref({
        provider: undefined,
        host: undefined,
        accessToken: undefined,
        id: undefined
    });
    const users = ref({
        list: [],
        next: '',
        prevPage: '',
        total: '',
        totalPages: '',
    });
    const statuses = ref({
        list: [],
        next: '',
        prevPage: '',
        total: '',
        totalPages: '',
    });
    const projects = ref({
        list: [],
        next: '',
        prevPage: '',
        total: '',
        totalPages: '',
    });

    const statusMapping = ref({});
    const workspaceMembersByIntegration = ref([]);
    const list = ref([]);
    const authorize = async(workspaceId, payload) => {
        return apiRequest(async() => {
            const createdIntegration = await integrationModule.authorize(workspaceId, {
                ...payload,
                workspaceId
            });
            return createdIntegration;
        }, loading);
    };
    const getIntegrationWorkspaceMembers = async (workspaceId, integrationId) => {
        return apiRequest(async() => {
            workspaceMembersByIntegration.value = await integrationModule.getIntegrationWorkspaceMembers(workspaceId, integrationId);
            return workspaceMembersByIntegration.value;
        });
    };
    const syncUser = async(workspaceId, integrationId, workspaceMemberId, mappingId) => {
        return apiRequest(async() => {
            return integrationModule.syncUser(workspaceId, integrationId, workspaceMemberId, mappingId);
        });
    };
    const syncProject = async(workspaceId, integrationId, integrationProjectId, projectId, name) => {
        return apiRequest(async() => {
            return integrationModule.syncProject(workspaceId, integrationId, integrationProjectId, projectId, name);
        });
    };
    const syncStatus = async(payload) => {
        return apiRequest(async() => {
            return integrationModule.syncStatus(payload);
        });
    };
    const startSync = async(workspaceId, integrationId) => {
        return apiRequest(async() => {
            return integrationModule.startSync(workspaceId, integrationId);
        }, loading);
    };
    const getIntegrationsList = async(workspaceId) => {
        return apiRequest(async() => {
            const integrations = await integrationModule.getList(workspaceId);
            list.value = integrations;
            return integrations;
        }, loading);
    };
    const getIntegration = async (workspaceId, integrationId) => {
        return apiRequest(async() => {
            const response = await integrationModule.getIntegration(workspaceId, integrationId);
            integration.value = response;
            return integration;
        }, loading);
    };
    const getUsers = async (workspaceId, integrationId, q) => {
        return apiRequest(async() => {
            users.value = await integrationModule.getUsers(workspaceId, integrationId, q);
            return users.value;
        }, loading);
    };
    const getSteps = async (workspaceId, integrationId, q) => {
        return apiRequest(async() => {
            const {users, projects, statuses} = await integrationModule.getSteps(workspaceId, integrationId, q);
            steps.value.users = users;
            steps.value.projects = projects;
            steps.value.statuses = statuses;
            return steps.value;
        }, loading);
    };
    const getProjects = async (workspaceId, integrationId, q, page) => {
        return apiRequest(async() => {
            projects.value = await integrationModule.getProjects(workspaceId, integrationId, q, page);
        }, loading);
    };
    const getStatuses = async (workspaceId, integrationId, integrationProjectId, projectId) => {
        return apiRequest(async() => {
            const {data, statusMap} = await integrationModule.getStatuses(workspaceId, integrationId, integrationProjectId, projectId);
            statuses.value = data;
            statusMapping.value = statusMap;
        }, loading);
    };
    const setIntegration = (integrationData) => {
        integration.value = {...integration.value, ...integrationData};
    };
    return {
        integration: computed(() => integration.value),
        getUsers,
        getProjects,
        getStatuses,
        setIntegration,
        authorize,
        getIntegrationsList,
        list,
        users,
        statuses,
        projects,
        steps,
        getSteps,
        getIntegration,
        syncUser,
        syncProject,
        syncStatus,
        startSync,
        getIntegrationWorkspaceMembers,
        workspaceMembersByIntegration,
        statusMap: statusMapping,
    };
}