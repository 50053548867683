<template lang="pug">
.services-inline-changer
    .services-inline-changer__title(@click.stop="setPopupVisibility(true)")
        span {{ service?.fullTitle || 'Не указано' }}
    a-popover(
        :visible="showPopup"
        placement="bottomLeft"
    )
        template(#content)
            .services-inline-changer__popup
                custom-select-v2(
                    v-model="newService"
                    placeholder="Услуга"
                    :style="{width: '100%'}"
                    :options="services.list"
                    searchable
                    label="fullTitle"
                    @search="onServicesSearch"
                    :loading="fetchLoading"
                )
                .services-inline-changer__popup-btns
                    a-button(@click="onSave" type="primary" :loading="loading") Сохранить
                    a-button.ml-3(@click="onDecline" type="danger") Отменить
</template>

<script>
export default {
    name: 'ServicesInlineChanger'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
/*===============INIT AREA===================*/
import {ref, onMounted, watch} from 'vue';
import CustomSelectV2 from '@/components/Generic/CustomSelectV2';
import {useServiceV2} from '@/composition/service-v2';
import {debounce} from '@/modules/utils';
import {useWorkspace} from '@/composition/workspace';
import {useLoading} from '@/composition/loading-state';

const props = defineProps({
    service: {
        type: Object,
    },
    func: {
        type: Function
    }
});
const emit = defineEmits(['done']);
const {loading, withLoader} = useLoading();
const {loading: fetchLoading, withLoader: wrapFetchAsync} = useLoading();
/*===============SERVICE AREA===================*/
const newService = ref(props.service);
const {currentWorkspaceId} = useWorkspace();
const {getServices, services, setFilter: setServicesFilter} = useServiceV2();
const onServicesSearch = (q) => {
    setServicesFilter({search: q});
    debounce(async () => {
        await wrapFetchAsync(async () => {
            await getServices(currentWorkspaceId.value, {});
        });
    }, 500)();
};
watch(() => props.service, () => {
    newService.value = props.service;
}, {deep: true});
const onSave = async () => {
    await withLoader(async () => {
        const serviceId = newService.value ? newService.value.id : null;
        await props.func(serviceId);
    });
    setPopupVisibility(false);
};
const onDecline = () => {
    newService.value = props.service;
    setPopupVisibility(false);
};
/*===============POPUP AREA===================*/
const showPopup = ref(false);
const setPopupVisibility = async (bool) => {
    showPopup.value = bool;
    if (bool) {
        await wrapFetchAsync(async () => {
            await getServices(currentWorkspaceId.value, {});
        });
    }
};
</script>

<style lang="scss" scoped>
.services-inline-changer {
    &__title {
        min-height: 32px;
        min-width: 40px;
        display: flex;
        align-items: center;
        width: fit-content;
        & span {
            text-decoration: underline;
            text-decoration-style: dotted;
            text-decoration-thickness: from-font;
            cursor: url("~@/assets/img/edit.png") 4 12, auto;
        }
    }
    &__popup {
        width: 440px;
        &-btns {
            margin-top: 12px;
        }
    }
}
</style>