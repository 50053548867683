<template lang="pug">
.user-avatar(v-if="user && user.id")
    a-tooltip(:placement="placement")
        template(#title)
            span {{ user.fullName || user.email }}
        a-avatar(:src="user.avatarPic" :style="generateStyle(user)") {{ generateLetter(user) }}
    div
        .ml-3(v-if="showName") {{ user.fullName || user.email }}
        .ml-3.text-gray-400.text-xs(v-if="showName && isMe") Это Вы
</template>

<script>
import constants from '@/constants';
import {recursiveMod} from '../../../../modules/utils';
export default {
    name: 'Avatar',
    props: {
        user: {
            type: Object
        },
        placement: {
            type: String,
            default: 'top'
        },
        showName: Boolean,
        isMe: Boolean
    },
    setup() {
        const generateLetter = (user) => user.fullName ? user.fullName.slice(0, 1) : user.email.slice(0, 1);
        const generateStyle = (user) => {
            const index = recursiveMod(user.fullName ? user.fullName.length : 0);
            const color = Object.values(constants.palette)[index];
            if (color) {
                return {background: `${color}4C`, color};
            }
            return {};
        };
        return {
            generateLetter,
            generateStyle,
        };
    }
};
</script>

<style lang="scss" scoped>
.user-avatar {
    cursor: pointer;
    display: flex;
    align-items: center;
}
</style>