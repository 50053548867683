<template lang="pug">
.member-account
    card-title Аккаунт
    .editable-card__column.mt-2
        .flex.items-center ФИО
        editable-cell(:initialValue="member.fullName" @update="(e) => onUpdate('fullName', e)")
    .editable-card__column.mt-2
        .flex.items-center Email
        div {{ member.user.email }}
    .editable-card__column.mt-2
        .flex.items-center Статус
        div {{ getMemberStatusText(member) }}
</template>

<script>
import CardTitle from '@/components/Generic/Typography/CardTitle';
import EditableCell from '@/components/Generic/EditableCell';
import {useWorkspace} from '@/composition/workspace';
export default {
    name: 'MemberAccount',
    components: {EditableCell, CardTitle},
    props: {
        member: {
            type: Object
        }
    },
    emits: ['update'],
    setup(props, {emit}) {
        const {getMemberStatusText} = useWorkspace();
        const onUpdate = (key, value) => {
            emit('update', {[key]: value});
        };
        return {
            onUpdate,
            getMemberStatusText
        };
    }
};
</script>

<style scoped>

</style>