import {BillType} from '@/components/Bills/constants';

export const billForm = (defaultValue={}, workspaceMember={}, getDateFromNow) => ({
    number: {
        value: defaultValue.number,
        error: undefined
    },
    date: {
        value: defaultValue.date || getDateFromNow(),
        error: undefined
    },
    services: {
        value: defaultValue.services || [],
        error: undefined
    },
    workspaceId: {
        value: defaultValue.workspaceId,
        error: undefined
    },
    bankAccountId: {
        value: defaultValue.bankAccountId,
        error: undefined
    },
    customerId: {
        value: defaultValue.customerId,
        error: undefined
    },
    performerId: {
        value: defaultValue.performerId,
        error: undefined
    },
    statusId: {
        value: defaultValue.statusId,
        error: undefined
    },
    directorId: {
        value: defaultValue.director || workspaceMember,
        error: undefined
    },
    payDateTo: {
        value: defaultValue.payDateTo || getDateFromNow(10),
        error: undefined
    },
    type: {
        value: defaultValue.type || BillType.incoming,
        error: undefined
    },
    currency: {
        value: defaultValue.currency,
        error: undefined
    },
    generateDocument: {
        value: defaultValue.generateDocument || true,
        error: undefined
    },
    documentUrl: {
        value: defaultValue.documentUrl,
        error: undefined
    },
});