<template lang="pug">
.workspace-settings-card
    card-title Основное
    .editable-card__column
        .flex.items-center Наименование
        editable-cell(
            :initialValue="form.title"
            @update="(e) => onChange('title', e)"
            :title="form.title"
        )
    .editable-card__column
        .flex.items-center Описание
        editable-cell(
            :initialValue="form.description"
            @update="(e) => onChange('description', e)"
            :title="form.description"
            type="text"
        )
    card-title Временная зона
    a-select.mt-2(
        :value="form.timezone"
        style="width: 100%"
        :filter-option="selectFilterFunc"
        show-search
        allow-clear
        @change="onChange('tz', $event)"
    )
        a-select-option(
            v-for="tz in timezones"
            :key="tz"
            :value="tz"
            :label="tz"
        ) {{ tz }}
</template>

<script>
import {reactive} from 'vue';
import {selectFilterFunc} from '@/modules/utils';
import {useWorkspace} from '@/composition/workspace';
import constants from '@/constants';
import moment from 'moment';
import CardTitle from '@/components/Generic/Typography/CardTitle';
import EditableCell from '@/components/Generic/EditableCell';
import {useAuth} from '@/composition/auth';
import {notification} from 'ant-design-vue';
export default {
    name: 'WorkspaceSettingsCard',
    components: {EditableCell, CardTitle},
    async setup() {
        const {currentWorkspaceId, getWorkspaceById, currentWorkspace, updateWorkspace} = useWorkspace();
        const {me} = useAuth();
        await getWorkspaceById(currentWorkspaceId.value);
        const form = reactive({
            timezone: currentWorkspace.value.tz || constants.tz.default,
            title: currentWorkspace.value.title,
            description: currentWorkspace.value.description
        });
        const timezones = moment.tz.names();
        const onChange = async (key, value) => {
            form[key] = value;
            const {ok} = await updateWorkspace(currentWorkspaceId.value, {[key]: value});
            if (ok) {
                await getWorkspaceById(currentWorkspaceId.value);
                await me(currentWorkspaceId.value);
                notification.success({
                    description: 'Сохранено',
                    message: 'Успех!'
                });
            }

        };
        return {
            selectFilterFunc,
            form,
            timezones,
            onChange,
        };
    }
};
</script>

<style lang="scss" scoped>
.workspace-settings-card {
    width: 400px;
}
</style>