<template lang="pug">

</template>

<script>
export default {
    name: 'Logout'
};
</script>

<style scoped>

</style>