<template lang="pug">
service-layout
    async-page
        service-performers-table
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import ServiceLayout from '@/pages/Services/Service/Layout';
import ServicePerformersTable from '@/components/Services/Performers/Table';
export default {
    name: 'ServiceSummary',
    components: {ServicePerformersTable, ServiceLayout, AsyncPage}
};
</script>

<style scoped>

</style>