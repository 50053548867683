<template lang="pug">
.finance-balance-page
    .finance-balance-page__companies
        finance-balance-company.mt-3(
            v-for="company in companies"
            :key="company.id"
            :title="company.title"
            :total-balance="company.totalBalance"
            :bank-accounts="company.bankAccounts"
            :balanceHistory="company.balanceHistory"
            :loading="loading"
        )
</template>

<script>
import {useFinance} from '@/composition/finance';
import {onMounted, ref} from 'vue';
import FinanceBalanceCompany from '@/components/FinanceBalance/Company';

export default {
    name: 'FinanceBalancePage',
    components: {FinanceBalanceCompany},
    setup() {
        const loading = ref(false);
        const {companies, getCompaniesBalances} = useFinance(loading);
        onMounted(async () => {
            await getCompaniesBalances();
        });
        return {
            loading,
            companies,
        };
    },
};
</script>

<style lang="scss" scoped>
.finance-balance-page {

}
</style>