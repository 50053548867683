<template lang="pug">
service-layout
    async-page
        service-documents
</template>

<script>
import ServiceDocuments from '@/components/Services/Documents';
import ServiceLayout from '@/pages/Services/Service/Layout';
import AsyncPage from '@/layouts/async-page';
export default {
    name: 'ServiceDocumentsPage',
    components: {AsyncPage, ServiceLayout, ServiceDocuments}
};
</script>

<style scoped>

</style>