<template lang="pug">
.contacts-table
    a-button.mb-2(type="primary" @click="setModalVisibility(true)") Добавить
    document-filter(@filter="onFilterChanged" :serviceId="serviceId" :workspaceMemberId="workspaceMemberId" :tabs="tabs")
    custom-table(:columns="columns" :data-source="documents.list" :onRowClick="editDocument")
        template(#type="{record}")
            div {{ record.fullTitle ? record.fullTitle : 'Не указано'}}
        template(#service="{record}")
            div(v-if="record") {{ getServicesText(record) }}
        template(#sum="{record}")
            div(v-if="record") {{ formatCurrency(record.sum) }} {{ currencyMap.rub }}
        template(#transport="{record}")
            editable-cell(
                :title="getTitle(record, 'documentTransport')"
                :initialValue="record.documentTransport?.id"
                type="select"
                empty-text="Не указано"
                @update="onUpdate(record.id, 'transportId', $event)"
                :options="directories.documentTransports.list"
            )
        template(#status="{record}")
            editable-cell(
                :title="getTitle(record, 'documentStatus')"
                :initialValue="record.documentStatus?.id"
                type="select"
                empty-text="Не указано"
                @update="onUpdate(record.id, 'statusId', $event)"
                :options="directories.documentStatuses.list"
            )
        template(#deadline="{record}")
            editable-cell(
                v-if="record"
                :title="formatDate(record.deadline)"
                :initialValue="record.deadline"
                type="date"
                empty-text="Не указано"
                @update="onUpdate(record.id, 'deadline', $event)"
                :className="isDocumentOutDated(record) ? 'danger' : ''"
            )
        template(#director="{record}")
            editable-cell(
                :title="getTitle(record, 'director', 'fullName')"
                :initialValue="record.directorId"
                type="select"
                empty-text="Не указано"
                @update="onUpdate(record.id, 'directorId', $event)"
                value-key="fullName"
                :options="members.list"
            )
        template(#customer="{record}")
            div {{ getTitle(record, 'customer') }}
        template(#action="{text, record}")
            .flex.items-center.justify-end
                a-button.mr-3(
                    @click.stop="downloadWrapper(record, record.documentUrl)"
                    v-if="record.documentUrl || record.sourceType === DocumentSourceType.create_new"
                ) Скачать
                a-dropdown(:trigger="['click']" @click.stop)
                    .dots-wrapper
                        icon.cursor-pointer(type="Dots")
                    template(#overlay)
                        a-menu
                            a-menu-item(key="0")
                                a-popconfirm(
                                    v-if="!record.isArchived"
                                    title="Вы уверены, что хотите архивировать документ?"
                                    ok-text="Да"
                                    cancel-text="Нет"
                                    @confirm="onUpdate(record.id, 'isArchived', true)"
                                )
                                    div Архив
                                a-popconfirm(
                                    v-else
                                    title="Вы уверены, что хотите вернуть документ в актуальное?"
                                    ok-text="Да"
                                    cancel-text="Нет"
                                    @confirm="onUpdate(record.id, 'isArchived', false)"
                                )
                                    div В актуальное
                            a-menu-item(key="1")
                                div(@click="duplicateDoc(record)") Дублировать
                            a-menu-item(key="2")
                                div(@click="editDocument(record)") Редактировать
                            a-menu-item(key="3")
                                div()
                                    delete-with-confirm(:deleteFunc="() => onDelete(record.id)" btnText="Удалить")
    a-button.mt-3(@click="next" v-if="documents.next" :loading="loading") Показать еще
a-modal(
    v-model:visible="showModal"
    title="Добавить документ"
    :footer="null"
    @cancel="setModalVisibility(false)"
    :destroyOnClose="true"
    style="top: 15px"
    width="80%"
)
    create-document-form(
        :hideService="!!serviceId"
        :serviceId="serviceId"
        :workspaceId="currentWorkspaceId"
        @ok="onCreate"
        @update:document="putUpdate"
        :mode="currentMode"
        :document="editingElement"
    )
</template>

<script>
import {columns, DocumentSourceType} from '../constants';
import {ref} from 'vue';
import constants from '@/constants';
import {useWorkspace} from '@/composition/workspace';
import EditableCell from '@/components/Generic/EditableCell';
import Icon from '@/components/Generic/Typography/Icon';
import CreateDocumentForm from '@/components/Documents/CreateForm';
import DocumentFilter from '@/components/Documents/Filter';
import {useDocument} from '@/composition/document';
import {useDate} from '@/composition/date';
import {useDownload} from '@/composition/download';
import CustomTable from '@/components/Generic/CustomTable';
import {useCreateOrUpdateModal} from '@/composition/create-update-modal';
import {useRouter} from 'vue-router';
import {useService} from '@/composition/service';
import {useDirectory} from '@/composition/directory';
import {formatCurrency} from '@/modules/utils';
import {getTitle} from '@/modules/utils';

export default {
    name: 'DocumentsTable',
    components: {CustomTable, DocumentFilter, CreateDocumentForm, Icon, EditableCell},
    props: {
        serviceId: [Number, String],
        workspaceMemberId: [Number, String]
    },
    async setup() {
        const loading = ref(false);
        const {
            currentMode,
            editingValue: editingElement,
            showModal,
            setModalVisibility,
            edit
        } = useCreateOrUpdateModal();
        const router = useRouter();
        const {currentWorkspaceId, getMembers, members} = useWorkspace(loading);
        const {
            tabs,
            documents,
            pagination,
            getTabs,
            setFilter,
            getDocuments,
            deleteDocument,
            updateDocument,
            isDocumentOutDated,
            downloadTemplate
        } = useDocument(loading);
        const {getServices} = useService(loading);
        const {getDirectories, directories} = useDirectory(loading);
        const {download} = useDownload(loading);
        await getDirectories(currentWorkspaceId.value);
        await getServices(currentWorkspaceId.value);
        await getMembers(currentWorkspaceId.value);
        const downloadWrapper = async(doc, key) => {
            if (doc.sourceType === DocumentSourceType.create_new) {
                await downloadTemplate(doc.id);
            } else if (doc.sourceType === DocumentSourceType.url) {
                window.open(doc.documentUrl, '_blank');
            } else {
                const {ok, result} = await download(currentWorkspaceId.value, key);
                if (ok) {
                    window.open(result.url, '_blank');
                }
            }
        };
        const putUpdate = async (data) => {
            const {id, ...updateData} = data;
            await updateDocument(currentWorkspaceId.value, id, updateData);
            setModalVisibility(false);
            await getDocuments(currentWorkspaceId.value);
        };
        const onDelete = async (id) => {
            pagination.value.offset = 0;
            await deleteDocument(currentWorkspaceId.value, id);
            await getDocuments(currentWorkspaceId.value);
            await getTabs(currentWorkspaceId.value);
        };
        const onUpdate = async (id, key, value) => {
            const {ok} = await updateDocument(currentWorkspaceId.value, id, {[key]: value});
            if (ok) {
                pagination.value.offset = 0;
                await getDocuments(currentWorkspaceId.value);
            }
        };
        const next = async () => {
            pagination.value.offset += pagination.value.limit;
            await getDocuments(currentWorkspaceId.value, {fromPagination: true});
        };
        const getServicesText = (record) => {
            return record.services.map(x => x?.service?.title).join(', ');
        };
        const onFilterChanged = async(filter) => {
            pagination.value.offset = 0;
            setFilter(filter);
            await getDocuments(currentWorkspaceId.value, {fromPagination: false});
            await getTabs(currentWorkspaceId.value);

        };
        const onCreate = async() => {
            pagination.value.offset = 0;
            await getDocuments(currentWorkspaceId.value, {fromPagination: false});
            setModalVisibility(false);
            await getTabs(currentWorkspaceId.value);

        };
        const redirectToCard = (item) => {
            router.push(`/${currentWorkspaceId.value}/documents/${item.id}`);
        };
        const editDocument = (record) => {
            edit({
                ...record,
            });
        };
        const duplicateDoc = doc => {
            editingElement.value = {
                ...doc,
            };
            currentMode.value = 'create';
            setModalVisibility(true);
        };
        return {
            tabs,
            loading,
            columns,
            members,
            showModal,
            documents,
            directories,
            currentMode,
            editingElement,
            currentWorkspaceId,
            DocumentSourceType,
            currencyMap: constants.currencyMap,
            edit,
            next,
            getTitle,
            onDelete,
            onCreate,
            onUpdate,
            putUpdate,
            editDocument,
            formatCurrency,
            redirectToCard,
            getServicesText,
            onFilterChanged,
            downloadWrapper,
            setModalVisibility,
            isDocumentOutDated,
            duplicateDoc,
            ...useDate(),
        };
    }
};
</script>

<style lang="scss" scoped>

</style>
