<template lang="pug">
member-layout
    async-page
        member-documents
</template>

<script>
import MemberLayout from '@/pages/Members/Member/Layout';
import AsyncPage from '@/layouts/async-page';
import MemberDocuments from '@/components/Workspace/Member/Documents';
export default {
    name: 'MemberDocumentsPage',
    components: {MemberDocuments, AsyncPage, MemberLayout}
};
</script>

<style scoped>

</style>