<template lang="pug">
settings-directories-services-layout
    async-page
        service-work-status-table
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import SettingsDirectoriesServicesLayout from '@/pages/Settings/Directories/Services/Layout';
import ServiceWorkStatusTable from '@/components/Directories/Services/WorkStatus/Table';

export default {
    name: 'ServiceWorkStatusPage',
    components: {
        ServiceWorkStatusTable,
        SettingsDirectoriesServicesLayout,
        AsyncPage
    }
};
</script>

<style scoped>

</style>