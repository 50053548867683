<template lang="pug">
.simple-month-period-picker
    a-month-picker(
        :value="period"
        format="MMMM YYYY"
        @change="onDateChanged"
        :placeholder="placeholder"
        style="width: 100%"
        :allowClear="clearable"
        :disabled="disabled"
    )
</template>

<script>
export default {
    name: 'SimpleMonthPeriodPicker'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
import {useDate} from '@/composition/date';
/*===============INIT AREA===================*/

const props = defineProps({
    period: {
        type: String,
    },
    placeholder: {
        type: String,
    },
    clearable: {
        type: Boolean,
        required: false,
        default: true,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
});
const emit = defineEmits(['update:period']);
const {toFormat, getMonthRangeFromPeriod, dateInstance} = useDate();
/*===============HANDLERS AREA===================*/
const onDateChanged = (dateInstance) => {
    if (!dateInstance) {
        emit('update:period', null);
    } else {
        emit('update:period', toFormat(dateInstance, 'DD.MM.YYYY', 'YYYY-MM-01'));
    }
};
</script>

<style lang="scss" scoped>
.simple-month-period-picker {
    max-width: 280px;
}
</style>