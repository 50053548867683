<template lang="pug">
.task-item(:class="{'task-item--in-progress': isActive}")
    .task-item__title.text-black.font-bold.flex.items-center.justify-between
        router-link(:to="getTaskRoute()" :class="{'task-item--active': isActive}") {{ title }}
        .flex.items-center
            div(v-if="integrationTask && integrationTask.src")
                a(:href="integrationTask.src" target="_blank")
                    icon(type="ExternalLink")
            div
                a-dropdown(:trigger="['click']" @click.stop)
                    div
                        icon.cursor-pointer(type="Dots")
                    template(#overlay)
                        a-menu
                            a-menu-item(v-for="status in availableStatuses" :key="status.id")
                                div(@click="onChangeStatus(status)") {{ status.title }}
    .task-item__service
        services-inline-changer(
            :service="service"
            :func="async (serviceId) => await onServiceChanged(serviceId)"
        )
    .task-item__footer
        .task-item__deadline.flex.items-center
            icon(type="Calendar")
            date-inline-changer(
                :date="deadline"
                :func="async (deadline) => await onDeadlineChanged(deadline)"
                placeholder="Дедлайн"
            )
            number-inline-changer.ml-4(
                :func="(timing) => onTimingChanged(timing)"
                :number="timing"
                placeholder="Тайминг"
            )
                template(#title)
                    .cursor-pointer(:class="{danger: isTimingOver()}") {{ totalTiming || 0 }} {{ timing ? `/ ${timing}` : '' }} ч.
            //task-logging.ml-4(
            //    :id="id"
            //    :is-active="isActive"
            //    :totalTimingInSeconds="totalTimingInSeconds"
            //    :activeLog="activeLog"
            //    @start="onStart"
            //    @stop="onStop"
            //    v-if="isMyTask"
            //    :loading="loading"
            //)
        performer-inline-changer(
            :workspace-member="assignee"
            :func="async (workspaceMemberId) => await onAssigneeChanged(workspaceMemberId)"
        )
</template>

<script>
import {useDate} from '@/composition/date';
import TaskPerformerChanger from '@/components/Tasks/PerformerChanger';
import TaskDeadlineChanger from '@/components/Tasks/DeadlineChanger';
import TaskLogging from '@/components/Tasks/TaskLogging';
import {secondsToHumanReadable} from '@/modules/utils';
import {computed, watch} from 'vue';
import DateInlineChanger from '@/components/Generic/InlineChanger/DateInlineChanger';
import PerformerInlineChanger from '@/components/Tasks/PerformerInlineChanger';
import ServicesInlineChanger from '@/components/Services/InlineChanger';
import NumberInlineChanger from '@/components/Generic/InlineChanger/NumberInlineChanger';
import Icon from '@/components/Generic/Typography/Icon';
export default {
    name: 'TaskItem',
    components: {
        Icon,
        NumberInlineChanger,
        ServicesInlineChanger,
        PerformerInlineChanger,
        DateInlineChanger,
        TaskLogging,
        TaskPerformerChanger,
        TaskDeadlineChanger,
    },
    props: {
        id: Number,
        title: String,
        description: String,
        workspaceId: Number,
        service: Object,
        deadline: String,
        assignee: Object,
        integrationTask: Object,
        totalTiming: Number,
        timing: Number,
        statusId: Number,
        isActive: Boolean,
        isMyTask: Boolean,
        totalTimingInSeconds: Number,
        activeLog: Object,
        loading: Boolean,
        statuses: Array,
    },
    emits: ['update', 'start', 'stop'],
    setup(props, {emit}) {
        const getTaskRoute = () => {
            return `/${props.workspaceId}/tasks/${props.id}`;
        };
        const onAssigneeChanged = (workspaceMemberId) => {
            emit('update', {workspaceMemberId});
        };
        const onDeadlineChanged = (deadline) => {
            emit('update', {deadline});
        };
        const onServiceChanged = (serviceId) => {
            emit('update', {serviceId});
        };
        const onTimingChanged = (timing) => {
            emit('update', {timing});
        };
        const isTimingOver = () => {
            if (!props.timing) return false;
            return props.totalTiming > props.timing;
        };
        const onStart = (id) => {
            emit('start', id);
        };
        const onStop = (payload) => {
            emit('stop', payload);
        };
        const onChangeStatus = (status) => {
            emit('update', {statusId: status.id, order: 0});
        };
        const availableStatuses = computed(() => {
            return props.statuses.filter(x => x.id !== props.statusId);
        });
        return {
            onChangeStatus,
            getTaskRoute,
            onAssigneeChanged,
            isTimingOver,
            onStart,
            onStop,
            onServiceChanged,
            onTimingChanged,
            onDeadlineChanged,
            availableStatuses,
            ...useDate()
        };
    }
};
</script>

<style lang="scss" scoped>
.task-item {
    padding: 6px 8px 2px;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(186, 186, 186, 0.5);;
    cursor: grab;
    display: block;
    margin-bottom: 8px;
    max-width: 400px;
    min-height: 20px;
    user-select: none;
    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0;
    }
    &--active {
        color: #5a00c2;
    }
    &--in-progress {
        box-shadow: 0px 0px 8px 6px rgba(90, 0, 194, 0.2);
    }
}
</style>
