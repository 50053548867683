<template lang="pug">
service-layout
    async-page
        service-bills
</template>

<script>
import ServiceBills from '@/components/Services/Bills';
import ServiceLayout from '@/pages/Services/Service/Layout';
import AsyncPage from '@/layouts/async-page';
export default {
    name: 'ServiceBillsPage',
    components: {AsyncPage, ServiceLayout, ServiceBills}
};
</script>

<style scoped>

</style>