<template lang="pug">
transition(name='modal')
    .modal-mask(v-if="modelValue" @click="closeOutside")
        .modal-wrapper
            .modal-container(@click="handleClick")
                card-title.mb-3 {{ title }}
                .modal-close(@click.stop="closeModal")
                    icon(type="Close")
                .modal-body
                    slot
</template>
<script>
export default {
    name: 'Modal',
    props: {
        modelValue: Boolean,
        title: String
    },
    emits: ['close'],
    setup(_, {emit}) {
        const closeModal = () => emit('close');
        const handleClick = (e) => {
            e.preventDefault();
            e.stopPropagation();
        };
        const closeOutside = (e) => {
            e.preventDefault();
            e.stopPropagation();
            closeModal();
        };
        return {closeModal, handleClick, closeOutside};
    }
};
</script>
<style lang="scss">
.modal-mask {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;
    overflow: auto;

}

.modal-wrapper {
    position: absolute;
    min-height: 100px;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    top: 5%;
    left: 0;
    right: 0;
    @media(max-width: 599px) {
        width: 98% !important;
        padding: 10px;
        left: 50%;
        margin-left: -49%;
    }

    .modal-close {
        position: absolute;
        right: -5px;
        top: -5px;
        cursor: pointer;

        img {
            width: 36px;
            height: 36px;
        }
    }
}

.modal-container {
    width: 639px;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 18px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
    position: relative;
    @media(max-width: 768px) {
        width: 96%;
    }
}


.modal-leave-active, .modal-enter {
    opacity: 0;
}

.modal-enter-from, .modal-leave-to {
    opacity: 0;
}


</style>
