<template lang="pug">
.contacts-select.w-full
    custom-select(
        :modelValue="modelValue"
        :title="placeholder"
        @update:modelValue="onChange($event)"
        :options="dataList"
        :mode="mode"
        server-search
        @search="onSearch"
        @blur="onBlur"
        :height="options.height"
    )
</template>

<script>
import {useWorkspace} from '@/composition/workspace';
import {debounce} from '@/modules/utils';
import {computed} from 'vue';
import CustomSelect from '@/components/Generic/CustomSelect';
import {useContact} from '@/composition/contact';

export default {
    name: 'ContactsSelectNew',
    components: {
        CustomSelect,
    },
    props: {
        modelValue: [Object, Array],
        placeholder: {
            type: String,
            default: 'Контакты'
        },
        mode: {
            type: String,
            default: 'single'
        },
        type: {
            type: String,
            default: 'contacts'
        },
        withCompanies: {
            type: Boolean,
        },
        options: {
            type: Object,
            default: () => ({})
        },
    },
    emits: ['update:modelValue', 'blur'],
    setup(props, {emit, slots}) {
        const {currentWorkspaceId} = useWorkspace();
        const {contacts, clients, getClients, getContacts, setFilter} = useContact();
        switch (props.type) {
            case 'clients':
                getClients(currentWorkspaceId.value, null, props.withCompanies);
                break;
            default:
                getContacts(currentWorkspaceId.value);
                break;
        }
        const dataList = computed(() => {
            switch (props.type) {
                case 'clients':
                    return clients.value.list;
                default:
                    return contacts.value.list;
            }
        });
        const onSearch = async (q) => {
            setFilter({search: q});
            switch (props.type) {
                case 'clients':
                    return debounce(() => getClients(currentWorkspaceId.value, q, props.withCompanies), 500)();
                default:
                    return debounce(() => getContacts(currentWorkspaceId.value), 500)();
            }
        };
        const onChange = (data) => {
            emit('update:modelValue', data);
        };
        const onBlur = (e) => {
            emit('blur', e);
        };
        const isSlotExists = (key) => {
            return !!slots[key];
        };

        return {
            contacts,
            onBlur,
            isSlotExists,
            dataList,
            clients,
            onSearch,
            onChange,
        };
    }
};
</script>

<style scoped>

</style>