import moment from 'moment-timezone';
import {httpInstance} from '@/modules/http_factory';
import {Transaction} from '@/modules/transaction/cast';
import constants from '@/constants';
import {arrayToString} from '@/modules/utils';

const formFilter = (workspaceId, filter, tz=constants.tz.default) => {
    let [dateFrom, dateTo] = filter.date ? filter.date : [undefined, undefined];
    const dateFilter = {
        dateFrom,
        dateTo,
    };
    return {
        workspaceId,
        period: filter.period && filter.period.length ? arrayToString(filter.period, true, 'value') : undefined,
        operationId: filter.operationId,
        contractorId: filter.contractorId,
        bankAccountId: filter.bankAccountId,
        billId: filter.billId,
        type: filter.type,
        isScheduled: filter.isScheduled,
        ...dateFilter,
    };
};
const createTransaction = async (workspaceId, transaction) => {
    const response = await httpInstance(workspaceId).post('/transactions', Transaction.createDto(transaction));
    return response.data;
};
const getTransactionById = async(workspaceId, id) => {
    const response = await httpInstance(workspaceId).get(`/transactions/${id}`);
    const transaction = new Transaction(response.data);
    return transaction.toObject();
};

const getBalances = async(workspaceId) => {
    const response = await httpInstance(workspaceId).get('/transactions/balances', {
        params: {
            workspaceId,
        }
    });
    return response.data;
};

const getBalanceHistory = async(workspaceId, filter) => {
    const response = await httpInstance(workspaceId).get('/transactions/balance-history', {
        params: {
            ...formFilter(workspaceId, filter)
        }
    });
    return response.data;
};
const getTransactionStats = async(workspaceId, filter) => {
    const response = await httpInstance(workspaceId).get('/transactions/stats', {
        params: {
            ...formFilter(workspaceId, filter)
        }
    });
    return response.data;
};

const updateTransaction = async(workspaceId, id, updateData) => {
    const response = await httpInstance(workspaceId).patch(`/transactions/${id}`, Transaction.updateDto(updateData));
    return response.data;
};
const deleteTransaction = async(workspaceId, id) => {
    const response = await httpInstance(workspaceId).delete(`/transactions/${id}`);
    return response.data;
};
const getTransactions = async (workspaceId, pagination, filter={}) => {
    const response = await httpInstance(workspaceId).get('/transactions', {
        params: {
            ...pagination,
            ...formFilter(workspaceId, filter)
        }
    });
    return {
        list: response.data.list.map(x => new Transaction(x).toObject()),
        total: response.data.total,
        next: response.data.next
    };
};

const getServiceTransactions = async (workspaceId, serviceId, pagination, filter={}) => {
    const [dateFrom, dateTo] = filter.date ? filter.date : [undefined, undefined];
    const response = await httpInstance(workspaceId).get('/transactions/service', {
        params: {
            ...pagination,
            serviceId,
            workspaceId,
            operationId: filter.operationId,
            contractorId: filter.contractorId,
            dateTo: dateTo ? moment(dateTo).toISOString() : undefined,
            dateFrom: dateFrom ? moment(dateFrom).toISOString() : undefined,
            bankAccountId: filter.bankAccountId
        }
    });
    return response.data;
};
const deleteServiceTransaction = async (workspaceId, serviceTransactionId) => {
    const response = await httpInstance(workspaceId).delete(`/transactions/service/${serviceTransactionId}`);
    return response.data;
};
const updateRepeatableTransactions = async (id, updateData) => {
    const response = await httpInstance().patch(`/transactions/repeatable/${id}`, Transaction.updateDto(updateData));
    return response.data;
};
const deleteRepeatableTransactions = async (id, type) => {
    const response = await httpInstance().delete(`/transactions/repeatable/${id}`, {
        params: {
            type
        }
    });
    return response.data;
};
export default {
    getBalanceHistory,
    createTransaction,
    getTransactionById,
    updateTransaction,
    deleteTransaction,
    getTransactions,
    getServiceTransactions,
    deleteServiceTransaction,
    getBalances,
    getTransactionStats,
    deleteRepeatableTransactions,
    updateRepeatableTransactions,
};
