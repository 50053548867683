<template lang="pug">
.widget-card
    .widget-card__text {{ title }}
    .widget-card__num.widget-card__num--black {{ !isFinished ? `~` : '' }} {{ formatCurrency(total) }} руб. {{ totalSuffix ? `(${totalSuffix}%)` : null }}
    status-progress(:percent="percent" :style="{width: '100%'}" v-if="percent")
    .widget-card__text(v-if="paid && !hidePaid") Выплачено: {{ formatCurrency(paid) }} руб.
    .widget-card__text(v-if="remainder && !hideRemainder") Остаток: {{ !isFinished ? `~` : '' }} {{ formatCurrency(remainder) }} руб.
</template>

<script>
import {computed} from 'vue';
import {formatCurrency} from '@/modules/utils';
import StatusProgress from '@/components/Generic/StatusProgress/index';

export default {
    name: 'ServicesFinanceWidget',
    components: {StatusProgress},
    props: {
        title: String,
        percent: [String, Number],
        total: Number,
        totalSuffix: String,
        paid: Number,
        hideRemainder: Boolean,
        hidePaid: Boolean,
        percentOfExpectation: {
            type: [Number, String],
            default: '0'
        },
        isFinished: Boolean,
    },
    setup(props) {
        const remainder = computed(() => (props.total || 0) - (props.paid || 0));
        const getDirection = () => {
            return Number(props.percentOfExpectation || 0) <= 0 ? -1 : 1;
        };
        const getExpectationClass = () => {
            const direction = getDirection();
            return direction > 0 ? 'success' : 'danger';
        };

        return {
            remainder,
            formatCurrency,
            getDirection,
            getExpectationClass
        };
    }
};
</script>

<style scoped>

</style>
