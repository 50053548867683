import {httpInstance} from '@/modules/http_factory';
import moment from 'moment';
const formFilter = (filter) => {
    return {
        from: moment().set('year', filter.year).startOf('year').format('YYYY-MM-DD'),
        to: moment().set('year', filter.year).endOf('year').format('YYYY-MM-DD'),
        contactId: filter.contactId,
        projectId: filter.projectId,
        serviceId: filter.serviceId,
        workspaceMemberId: filter.workspaceMemberId,
        isScheduled: filter.isScheduled === true ? undefined : false,
    };
};

const getStats = async (filter) => {
    const response = await httpInstance().get('/finance/stats', {
        params: {
            ...formFilter(filter)
        }
    });
    return response.data;
};

const getFundsFlow = async (filter) => {
    const response = await httpInstance().get('/finance/funds-flow', {
        params: {
            ...formFilter(filter)
        }
    });
    return response.data;
};
const getChartData = async (filter) => {
    const response = await httpInstance().get('/finance/chart', {
        params: {
            ...formFilter(filter)
        }
    });
    return response.data;
};

const getCompaniesBalances = async () => {
    const response = await httpInstance().get('/finance/balances', {
        params: {}
    });
    return response.data;
};
export default {
    getStats,
    getFundsFlow,
    getChartData,
    getCompaniesBalances,
};
