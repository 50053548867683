<template lang="pug">
.working-day-list-item
    .working-day-list-item__member {{ fullName }}
    .working-day-list-item__timing-wrapper
        .working-day-list-item__task-active(v-if="!!activeTask")
        .working-day-list-item__timing(
            :class="getTimingClass(aggregator + timingInSeconds)"
            @click="navigateToReports()"
    ) {{ secondsToHumanReadable(aggregator + timingInSeconds) }}
    .working-day-list-item__task-wrapper
        router-link.working-day-list-item__task(:to="getTaskRoute(activeTask?.id)") {{ getTitle(activeTask, null, 'fullTitle') }}
    .working-day-list-item__service {{ getTitle(activeTask, 'service', 'title') }}
</template>

<script>
export default {
    name: 'WorkingDayListItem'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
import {secondsToHumanReadable, getTitle} from '@/modules/utils';
import constants from '@/constants';
import {useClock} from '@/composition/clock';
import {defineProps, watch} from 'vue';
import {useWorkspace} from '@/composition/workspace';
import {useRouter} from 'vue-router';

/*===============INIT AREA===================*/
const {currentWorkspaceId} = useWorkspace();
const router = useRouter();
const props = defineProps({
    id: {
        type: Number,
    },
    timingInSeconds: {
        type: Number,
        default: 0
    },
    fullName: {
        type: String,
    },
    activeTask: {
        type: Object,
    },
    selectedDay: {
        type: String,
    },
});
const {aggregator, setActive} = useClock();
setActive(!!props.activeTask, props.activeTask?.activeLog?.startDate);
watch(() => props.activeTask, () => {
    setActive(!!props.activeTask, props.activeTask?.activeLog?.startDate);
});
/*===============ROUTING AREA===================*/
const navigateToReports = () => {
    router.push({
        path: `/${currentWorkspaceId.value}/reports/hourly/new`,
        query: {
            dayFilter: props.selectedDay,
            performers: props.id
        }
    });
};
/*===============RENDERERS AREA===================*/

const getTimingClass = (timingInSeconds) => {
    let timingConstant;
    switch (props.selectedDay) {
        case 'currentWeek':
            timingConstant = constants.averageWorkingWeekInSeconds;
            break;
        case 'currentMonth':
            timingConstant = constants.averageWorkingMonthInSeconds;
            break;
        default:
            timingConstant = constants.averageWorkingDayInSeconds;
            break;
    }
    if (timingInSeconds === 0) return 'danger';
    if (timingInSeconds > 0 && timingInSeconds < timingConstant) return 'ordinary';
    if (timingInSeconds >= timingConstant) return 'success';
    return '';
};
const getTaskRoute = (taskId) => {
    if (!taskId) return '';
    return `/${currentWorkspaceId.value}/tasks/${taskId}`;
};
</script>

<style lang="scss">
.working-day-list-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    &__member {
        width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: bold;
        white-space: nowrap;
    }
    &__timing {
        width: 80px;
        margin-left: 16px;
        cursor: pointer;
        &-wrapper {
            position: relative;
        }
    }
    &__task {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: underline;
        &-active {
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background: #5a00c2;
            position: absolute;
            left: 0px;
            top: 6px;
        }
        &-wrapper {
            display: flex;
            align-items: center;
            width: 120px;
            margin-left: 16px;
        }
    }
    &__service {
        width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 16px;
    }
}
</style>
