<template lang="pug">
.workspace-members-list
    .workspace-members-list__item(
        v-for="member in members"
        :key="member.id"
    )
        list-item(:member="member")
</template>

<script>
import ListItem from '@/components/Workspace/MembersList/ListItem';

export default {
    name: 'WorkspaceMembersList',
    components: {ListItem},
    props: {
        members: Array
    },
};
</script>

<style lang="scss" scoped>
.workspace-members-list {
    &__item {
        display: block;
    }
}
</style>