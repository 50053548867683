export const servicesColumns = [
    {
        title: 'Услуга',
        dataIndex: 'title',
        key: 'title',
        slots: {customRender: 'title'},
        width: '284px'
    },
];

export const performerPaymentType = {
    fixed: 'fixed',
    hourly: 'hourly',
    profit_percent: 'profit_percent',
    earning_percent: 'earning_percent',
    not_paid: 'not_paid',
};

export const servicePaymentType = {
    fixed: 'fixed',
    hourly: 'hourly',
    not_paid: 'not_paid',
};

export const servicePaymentTypeMap = {
    fixed: 'фикс-прайс',
    hourly: 'почасовка',
    not_paid: 'не оплачивается',
};

export const performerPaymentTypeMap = {
    fixed: 'фикс-прайс',
    hourly: 'почасовка',
    profit_percent: 'процент с прибыли',
    earning_percent: 'процент с выручки',
    not_paid: 'не оплачивается',
};

export const servicePerformersColumns = [
    {
        title: 'Пользователь',
        slots: {customRender: 'performer'},
        width: '100%'
    },
    // {
    //     title: 'Тип оплаты',
    //     dataIndex: 'paymentType',
    //     key: 'paymentType',
    //     slots: {customRender: 'paymentType'},
    //     width: '15%'
    // },
    // {
    //     title: 'Ставка',
    //     dataIndex: 'hourlyRate',
    //     key: 'hourlyRate',
    //     width: '5%'
    // },
    // {
    //     title: 'Тайминг',
    //     dataIndex: 'timing',
    //     key: 'timing',
    //     slots: {customRender: 'timing'},
    //     width: '20%'
    // },
    // {
    //     title: 'Оплачено',
    //     dataIndex: 'paid',
    //     key: 'paid',
    //     slots: {customRender: 'paid'},
    //     width: '20%'
    // },
    // {
    //     title: 'Док-оборот',
    //     slots: {customRender: 'documentFlow'},
    //     width: '15%'
    // },
    {
        title: '',
        slots: {customRender: 'action'},
    },
];

export const serviceWorkStatusKey = {
    new: 'new',
    in_progress: 'in_progress',
    ended: 'ended',
    archive: 'archive'
};

export const serviceDocumentStatus = {
    in_progress: 'in_progress',
    done: 'done'
};

export const serviceDocumentStatusMap = {
    in_progress: 'В работе',
    done: 'Завершен'
};
