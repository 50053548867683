<template lang="pug">
card-title Документы
.invite-user-form.mt-2
    div(v-for="document in documents.value" :key="document.id")
        .flex.align-items.justify-between
            .cursor-pointer(@click="edit(document)") {{ document.number }}
            .cursor-pointer(@click="deleteDocumentFromArr(document.id)")
                icon(type="Delete")
    div
        a-button.mt-2(@click="setDocumentModalVisibility(true)" type="primary") {{ documents.value.length ? 'Еще' : 'Добавить' }}

a-modal(
    v-model:visible="showAddDocumentModal"
    title="Добавить основание работы"
    :footer="null"
    @cancel="setDocumentModalVisibility(false)"
    width="30%"
    :style="{top: '20px'}"
    :destroy-on-close="true"
)
    create-document-form(
        :document="editingElement"
        @update:document="onDocumentAdded"
        mode="edit"
        :workspaceId="currentWorkspaceId"
    )
</template>

<script>
import CreateDocumentForm from '@/components/Documents/CreateForm';
import {useWorkspace} from '@/composition/workspace';
import {useCreateOrUpdateModal} from '@/composition/create-update-modal';

export default {
    name: 'MemberDocumentsForm',
    components: {CreateDocumentForm},
    props: {
        documents: Object,
        directories: Object,
    },
    emits: ['update:documents'],
    setup(props, {emit}) {
        const {
            currentMode,
            editingValue: editingElement,
            showModal: showAddDocumentModal,
            setModalVisibility: setDocumentModalVisibility,
            edit
        } = useCreateOrUpdateModal();
        const {currentWorkspaceId} = useWorkspace();
        const onDocumentAdded = (documentData) => {
            if (currentMode.value === 'edit') {
                emit('update:documents', {
                    error: undefined,
                    value: props.documents.value.map(x => {
                        if (x.id == documentData.id) {
                            return documentData;
                        }
                        return x;
                    })
                });
            } else {
                emit('update:documents', {
                    error: undefined,
                    value: [...props.documents.value, documentData]
                });
            }

            setDocumentModalVisibility(false);
        };
        const deleteDocumentFromArr = (id) => {
            const payload = props.documents.value.filter(x => x.id != id);
            emit('update:documents', {
                error: undefined,
                value: payload
            });
        };
        return {
            showAddDocumentModal,
            onDocumentAdded,
            deleteDocumentFromArr,
            setDocumentModalVisibility,
            edit,
            currentMode,
            editingElement,
            currentWorkspaceId
        };
    }
};
</script>

<style scoped>

</style>