import {selectFilterFunc} from '@/modules/utils';

export default {
    props: {
        value: {
            type: [String, Number, Array, Object, Boolean]
        },
        type: {
            type: String,
        },
        options: {
            type: Array
        },
        keyId: {
            type: [String, Number]
        },
        valueKey: {
            type: String,
        }
    },
    emits: ['input', 'done'],
    render(props) {
        const renderTargetElement = (value, type) => {
            switch (type) {
                case 'checkbox':
                    return <a-checkbox checked={value} onChange={onCheckboxChange}/>;
                case 'date':
                    return <a-date-picker value={value} onChange={onDateChange} format="DD.MM.YYYY"/>;
                case 'text':
                    return <a-input value={value} onChange={onChange} onBlur={onBlur}/>;
                case 'textarea':
                    return <a-textarea value={value} onChange={onChange} auto-size={{minRows: 1}} onBlur={onBlur}/>;
                case 'number':
                    return <a-input-number value={value} onChange={onChange} onBlur={onBlur} style={{width: '100%'}}/>;
                case 'select':
                    return <a-select
                        value={value}
                        onInput={onChange}
                        onSelect={onChange}
                        onChange={(e) => onChange(e)}
                        style={{width: '100%'}}
                        allowClear
                        showSearch
                        filterOption={selectFilterFunc}
                        v-click-away={onBlur}
                    >
                        {props.options && props.options.map((x) => (
                            <a-select-option value={x[props.keyId]}
                                key={x[props.keyId]}>{x[props.valueKey]}</a-select-option>
                        ))}
                    </a-select>;
                case 'select_multiple':
                    return <a-select
                        default-value={value}
                        onChange={onChange}
                        mode="multiple"
                        style={{width: '100%'}}
                        showSearch
                        filterOption={selectFilterFunc}
                        v-click-away={onBlur}
                    >
                        {props.options && props.options.map((x) => (
                            <a-select-option value={x[props.keyId]}
                                key={x[props.keyId]}>{x[props.valueKey]}</a-select-option>
                        ))}
                    </a-select>;
                default:
                    return null;
            }
        };
        const onChange = (e) => {
            if (!e && e !== false) {
                this.$emit('input', '');
                return;
            }
            if (e.target) {
                this.$emit('input', e.target.value);
            } else {
                this.$emit('input', e);
            }
        };
        const onBlur = (e) => {
            if (!e && e !== false) {
                this.$emit('done', '');
                return;
            }
            if (e.target) {
                this.$emit('done', e.target.value);
            } else {
                this.$emit('done', e);
            }
        };
        const onCheckboxChange = (e) => {
            onChange(e.target.checked);
            onBlur(e.target.checked);
        };
        const onDateChange = (e) => {
            onChange(e);
            onBlur(e);
        };
        return renderTargetElement(props.value, props.type);
    },
};
