<template lang="pug">
a-tooltip(v-if="tooltipText")
    template(#title)
        div {{ tooltipText }}
    component(:is="type" :class="[color ? color : '', size]")
component(:is="type" :class="[color ? color : '', size]" v-else)
</template>

<script>
import icons from '@/assets/icons';
export default {
    name: 'Icon',
    components: {
        ...icons
    },
    props: {
        type: String,
        color: String,
        tooltipText: String,
        size: {
            type: String,
            default: 'normal',
            validator: (val) => ['small', 'normal', 'large'].findIndex(x => x === val) !== -1
        }
    },
};
</script>

<style lang="scss">
.white path {
    fill: #ffffff;
}
.large {
    transform: scale(1.4);
}
</style>