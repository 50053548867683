import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66cddf83"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "calendar-events" }
const _hoisted_2 = { class: "calendar-events__list" }
const _hoisted_3 = ["onClick", "title"]
const _hoisted_4 = { class: "calendar-events__list" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_calendar = _resolveComponent("a-calendar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_calendar, {
      value: $props.selectedDay,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($props.selectedDay) = $event)),
      onSelect: $setup.onChange,
      onPanelChange: $setup.onPanelChange,
      mode: "month",
      locale: "ru"
    }, {
      dateCellRender: _withCtx(({current}) => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getDaySlots(current), (slot) => {
          return (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", {
              class: _normalizeClass(["calendar_events__item base-link", [$setup.getSlotClass(slot)]]),
              onClick: _withModifiers(($event: any) => ($setup.editSlot(slot, current)), ["stop","prevent"]),
              title: $setup.renderTimes(slot)
            }, _toDisplayString($setup.renderTitle(slot)), 11, _hoisted_3)
          ]))
        }), 256))
      ]),
      monthCellRender: _withCtx(({current}) => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getDaySlots(current), (slot) => {
          return (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", {
              class: "calendar_events__item base-link",
              onClick: _withModifiers(($event: any) => ($setup.editSlot(slot, current)), ["stop","prevent"])
            }, _toDisplayString(slot.title), 9, _hoisted_5)
          ]))
        }), 256))
      ]),
      headerRender: _withCtx(({value, onChange}) => [
        _createElementVNode("div", _hoisted_6, [
          _createVNode($setup["SimpleMonthPeriodPicker"], {
            period: $props.filter.date,
            clearable: false,
            "onUpdate:period": (date) => onChange(date)
          }, null, 8, ["period", "onUpdate:period"]),
          _createVNode($setup["CustomSelectV2"], {
            class: "ml-2",
            modelValue: $props.filter.types,
            placeholder: "Тип",
            multiple: true,
            style: {width: '220px'},
            options: Object.keys($setup.CalendarSlotTypeMap).map(x => ({id: x, title: $setup.CalendarSlotTypeMap[x]})),
            "onUpdate:modelValue": $setup.onTypeChanged
          }, null, 8, ["modelValue", "options"])
        ])
      ]),
      _: 1
    }, 8, ["value"])
  ]))
}