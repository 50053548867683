<template lang="pug">
.services-profitability-page
</template>

<script>
export default {
    name: 'ServicesProfitabilityPage'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
</script>

<style lang="scss">
</style>