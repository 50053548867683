import {ref, watch} from 'vue';
import moment from 'moment-timezone';

const globalClock = ref(0);
const globalInterval = ref(setInterval(() => {
    globalClock.value += 1;
}, 1000));

export function useClock() {
    const startDate = ref(null);
    const aggregator = ref(0);
    watch(() => globalClock.value, () => {
        if (!startDate.value) return;
        const end = moment.utc().valueOf();
        const diffInSeconds = (end - moment.utc(startDate.value).valueOf()) / 1000;
        aggregator.value = diffInSeconds;
    });
    const setActive = (bool, newStartDate) => {
        if (bool) startDate.value = newStartDate;
        else {
            startDate.value = null;
            aggregator.value = 0;
        }
    };
    return {
        aggregator,
        setActive,
        globalClock
    };
}
