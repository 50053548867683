import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-319b1462"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "resource-management-chart-settings" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["SimpleMonthPeriodPicker"], {
      period: $props.settings.period,
      "onUpdate:period": $setup.onChange
    }, null, 8, ["period"])
  ]))
}