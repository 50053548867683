<template lang="pug">
.status-manager
    draggable(
        class="column"
        :list="taskStatuses"
        group="statuses"
        item-key="id"
        ghost-class="ghost"
        @change="onChange"
    )
        template(#item="{element}")
            status-item(
                :status="element"
                @update="onUpdate"
                @delete="onDelete"
            )
    .status-manager__add.mt-2(@click="addTaskStatus")
        icon(type="Plus")
</template>

<script>
import {ref} from 'vue';
import {useKanban} from '@/composition/kanban';
import {useWorkspace} from '@/composition/workspace';
import StatusItem from '@/components/Settings/Tasks/StatusManager/StatusItem';
import draggable from 'vuedraggable';
export default {
    name: 'TasksStatusManager',
    components: {StatusItem, draggable},
    async setup() {
        const loading = ref(false);
        const {
            taskStatuses,
            getTaskStatuses,
            updateTaskStatus,
            updateTaskStatusOrders,
            createTaskStatus,
            deleteTaskStatus,
        } = useKanban(loading);
        const {currentWorkspaceId} = useWorkspace(loading);
        await getTaskStatuses(currentWorkspaceId.value);
        const onUpdate = async ({title, isCurrentlyActual, id}) => {
            if (id) {
                await updateTaskStatus(currentWorkspaceId.value, id, {title, isCurrentlyActual});
            } else {
                await createTaskStatus(currentWorkspaceId.value, {title, isCurrentlyActual});
                await getTaskStatuses(currentWorkspaceId.value);
                onChange();
            }
        };
        const onDelete = async (id) => {
            await deleteTaskStatus(currentWorkspaceId.value, id);
            await getTaskStatuses(currentWorkspaceId.value);
            onChange();
        };
        const onChange = () => {
            const ordersArr = taskStatuses.value.map((x, index) => ({id: x.id, order: index}));
            updateTaskStatusOrders(currentWorkspaceId.value, ordersArr);
        };
        const addTaskStatus = () => {
            taskStatuses.value.push({id: undefined, title: '', workpaceId: currentWorkspaceId.value});
        };
        return {
            taskStatuses,
            onUpdate,
            onChange,
            addTaskStatus,
            onDelete,
        };
    }
};
</script>

<style lang="scss" scoped>
.status-manager {
    &__add {
        cursor: pointer;
        display: inline-block;
    }
}
</style>