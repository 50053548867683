export function useFormErrors() {
    const init = async (form, func, clearFunc) => {
        clearFunc();
        const {ok, fieldErrors, result} = await func();
        if (fieldErrors.length) {
            for (const fieldError of fieldErrors) {
                const targetProp = fieldError.property;
                if (!targetProp) continue;
                const targetFormField = form[targetProp];
                if (!targetFormField) continue;
                if (!fieldError.errors && !Object.values(fieldError.errors).length) continue;
                form[targetProp].error = Object.values(fieldError.errors)[0];
            }
            return {ok, err: true, result};
        }
        return {ok, err: false, result};
    };
    return {init};
}