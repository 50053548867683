import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-457d7af9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "simple-range-picker" }
const _hoisted_2 = { class: "w-full relative" }
const _hoisted_3 = { class: "flex items-center w-full" }
const _hoisted_4 = { class: "flex items-center absolute" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["DateOnlyPicker"], {
          class: "mr-3",
          date: $setup.from,
          "onUpdate:date": _cache[0] || (_cache[0] = (newFrom) => $setup.onChange([newFrom, $setup.to])),
          placeholder: "От",
          clearable: $props.clearable,
          onlyForPeriod: $props.onlyForPeriod
        }, null, 8, ["date", "clearable", "onlyForPeriod"]),
        _createVNode($setup["DateOnlyPicker"], {
          class: "mr-3",
          date: $setup.to,
          "onUpdate:date": _cache[1] || (_cache[1] = (newTo) => $setup.onChange([$setup.from, newTo])),
          placeholder: "До",
          clearable: $props.clearable,
          onlyForPeriod: $props.onlyForPeriod
        }, null, 8, ["date", "clearable", "onlyForPeriod"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.timeFilter, (value, key) => {
          return _createElementVNode("div", {
            class: _normalizeClass(["simple-range-picker__date", {'simple-range-picker__date--active': key === $setup.currentDateFilter}]),
            key: key,
            onClick: ($event: any) => ($setup.setDateFilter(key))
          }, _toDisplayString(value.title), 11, _hoisted_5)
        }), 64))
      ])
    ])
  ]))
}