<template lang="pug">
.transaction-service-item
    .flex
        services-select(
            :modelValue="item.serviceId"
            @update:modelValue="onChange('serviceId', $event)"
            :disabled="disabled"
            :additional-filter="additionalServiceFilter"
            width="250px"
        )
    .flex.items-center.ml-3(v-if="item.serviceId")
        custom-select-v2(
            placeholder="Выберите период"
            :modelValue="item.paymentPeriod"
            @update:modelValue="onChange('paymentPeriod', $event)"
            :options="paymentPeriods"
            :style="{width: '230px'}"
            :clearable="false"
            :disabled="disabled"
        )
    price-input.ml-3(
        :price="item.amount"
        placeholder="Сумма"
        @update:price="onChange('amount', $event)"
        style="width: 100%"
    )
    currency-select.ml-3(v-model="currency" :disabled="true")
    .transaction-service-item__delete.ml-3(@click="deleteItem" v-if="!disabled")
        icon(type="Delete")
</template>

<script>
import {onMounted, ref} from 'vue';
import constants from '@/components/Workspace/InviteUserForm/constants';
import CurrencySelect from '@/components/Generic/CurrencySelect';
import ServicesSelect from '@/components/Services/Select';
import {useService} from '@/composition/service';
import {useWorkspace} from '@/composition/workspace';
import CustomSelectV2 from '@/components/Generic/CustomSelectV2/index';
import Autocomplete from '@/components/Generic/Autocomplete';
import {useServiceV2} from '@/composition/service-v2';
import PriceInput from '@/components/Generic/PriceInput';
export default {
    name: 'TransactionServiceItem',
    components: {PriceInput, Autocomplete, CustomSelectV2, CurrencySelect, ServicesSelect},
    props: {
        item: Object,
        disabled: {
            type: Boolean
        },
        additionalServiceFilter: {
            type: Object
        },
        currency: {
            type: String
        }
    },
    emits: ['change', 'delete'],
    setup(props, {emit}) {
        const {currentWorkspaceId} = useWorkspace();
        const {getServices, getService, services} = useServiceV2();
        const paymentPeriods = ref([]);
        const onChange = (key, value) => {
            const payload = {
                id: props.item.id,
                amount: props.item.amount,
                serviceId: props.item.serviceId,
                paymentPeriod: props.item.paymentPeriod,
            };
            payload[key] = value;
            if (key === 'serviceId') {
                payload['paymentPeriod'] = null;
                onServiceIdChanged(value);
            }
            emit('change', payload);
        };
        const deleteItem = () => {
            emit('delete', props.item.id);
        };
        const onServiceIdChanged = async (serviceId) => {
            const {ok, result: service} = await getService(serviceId, currentWorkspaceId.value);
            if (ok && service) {
                paymentPeriods.value = service.paymentPeriods;
            }
        };
        onMounted(async () => {
            await getServices(currentWorkspaceId.value);
            if (props.item.serviceId) {
                await onServiceIdChanged(props.item.serviceId);
            }
        });
        return {
            constants,
            paymentPeriods,
            onChange,
            deleteItem,
            services,
        };
    }
};
</script>

<style lang="scss" scoped>
.transaction-service-item {
    display: flex;
    align-items: center;
    &__delete {
        cursor: pointer;
    }
}
</style>
