import {httpInstance} from '@/modules/http_factory';
import {arrayToString} from '@/modules/utils';

const createContact = async (workspaceId, contact) => {
    const response = await httpInstance(workspaceId).post('/contacts', contact);
    return response.data;
};
const getContactById = async(workspaceId, id) => {
    const response = await httpInstance(workspaceId).get(`/contacts/${id}`);
    return response.data;
};


const updateContact = async(workspaceId, id, updateData) => {
    const response = await httpInstance(workspaceId).patch(`/contacts/${id}`, updateData);
    return response.data;
};
const deleteContact = async(workspaceId, id) => {
    const response = await httpInstance(workspaceId).delete(`/contacts/${id}`);
    return response.data;
};
const formFilter = (workspaceId, filter) => {
    return {
        workspaceMemberIds: filter.workspaceMemberIds && Array.isArray(filter.workspaceMemberIds) ? arrayToString(filter.workspaceMemberIds) : undefined,
        workspaceId,
        contactGroupId: filter.contactGroupId && filter.contactGroupId !== 'all' ? filter.contactGroupId : undefined,
        search: filter.search,
        status: filter.status,
        hideCompanies: filter.hideCompanies,
        hideMembers: filter.hideMembers,
    };
};
const getContacts = async (workspaceId, pagination, filter={}) => {
    const response = await httpInstance(workspaceId).get('/contacts', {
        params: {
            ...pagination,
            ...formFilter(workspaceId, filter)
        }
    });
    return response.data;
};

const getClients = async (workspaceId, search, withCompanies) => {
    const response = await httpInstance(workspaceId).get('/contacts/clients', {
        params: {
            search,
            workspaceId,
            withCompanies,
        }
    });
    return response.data;
};
const getCompanies = async (workspaceId, search) => {
    const response = await httpInstance(workspaceId).get('/contacts/companies', {
        params: {
            search,
            workspaceId,
        }
    });
    return response.data;
};
const getTabs = async (workspaceId, filter={}) => {
    const response = await httpInstance(workspaceId).get('/contacts/tabs', {
        params: {
            ...formFilter(workspaceId, filter)
        }
    });
    return response.data;
};

export default {
    getTabs,
    getClients,
    getContacts,
    getCompanies,
    createContact,
    updateContact,
    deleteContact,
    getContactById,
};