import {computed, reactive} from 'vue';

const state = reactive({
    directories: []
});

export const getters = () => ({
    directories: computed(() => state.directories),
});

const mutations = {
    setDirectories(state, data) {
        state.directories = data;
    }
};

export const commit = (method, data) => {
    if (mutations[method]) mutations[method](state, data);
};
