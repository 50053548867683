<template lang="pug">
.add-company-form
    input-with-error(:error="form.title.error")
        template(#input)
            a-input(placeholder="Наименование" v-model:value="form.title.value")
    input-with-error(:error="form.director.error")
        template(#input)
            a-input(placeholder="ФИО руководителя" v-model:value="form.director.value")
    input-with-error(:error="form.directorPosition.error")
        template(#input)
            a-input(placeholder="Должность руководителя" v-model:value="form.directorPosition.value")
    input-with-error(:error="form.entityCode.error")
        template(#input)
            a-input-number(placeholder="ИНН" v-model:value="form.entityCode.value" style="width: 100%")
    input-with-error(:error="form.registrationReasonCode.error")
        template(#input)
            a-input-number(placeholder="КПП" v-model:value="form.registrationReasonCode.value" style="width: 100%")
    input-with-error(:error="form.address.error")
        template(#input)
            a-input(placeholder="Юр. адрес" v-model:value="form.address.value")
    input-with-error(:error="form.logo.error")
        template(#input)
            uploader(@upload="(data) => onUpload('logo', data)")
                a-button(type="primary") Добавить лого
                .mt-2 {{ form.logo.value }}
    input-with-error(:error="form.signature.error")
        template(#input)
            uploader(@upload="(data) => onUpload('signature', data)")
                a-button(type="primary") Добавить подпись
                .mt-2 {{ form.signature.value }}
    input-with-error(:error="form.printing.error")
        template(#input)
            uploader(@upload="(data) => onUpload('printing', data)")
                a-button(type="primary") Добавить печать
                .mt-2 {{ form.printing.value }}
    a-button.mt-5.mb-2(
        type="primary"
        block
        @click="createWrapper"
        :loading="loading"
        :disabled="disabled"
    ) {{ mode === 'edit' ? 'Сохранить' : 'Создать' }}

</template>

<script>
import {computed, reactive, ref} from 'vue';
import {useFormErrors} from '@/composition/errors';
import {notification} from 'ant-design-vue';
import {selectFilterFunc} from '@/modules/utils';
import {useCrud} from '@/composition/crud';
import Uploader from '@/components/Generic/Uploader';
export default {
    name: 'CreateCompanyForm',
    components: {Uploader},
    props: {
        workspaceId: Number,
        defaultValue: Object,
        mode: String,
    },
    emits: ['ok', 'update'],
    setup(props, {emit}) {
        const loading = ref(false);

        const form = reactive({
            title: {
                value: props.defaultValue?.title,
                error: undefined
            },
            director: {
                value: props.defaultValue?.director,
                error: undefined,
            },
            logo: {
                value: props.defaultValue?.logo,
                error: undefined,
            },
            signature: {
                value: props.defaultValue?.signature,
                error: undefined,
            },
            printing: {
                value: props.defaultValue?.printing,
                error: undefined,
            },
            directorPosition: {
                value: props.defaultValue?.directorPosition,
                error: undefined,
            },
            entityCode: {
                value: props.defaultValue?.entityCode,
                error: undefined,
            },
            registrationReasonCode: {
                value: props.defaultValue?.registrationReasonCode,
                error: undefined,
            },
            address: {
                value: props.defaultValue?.address,
                error: undefined,
            },
        });
        const clients = [];
        const {createEntity} = useCrud('companies', loading);
        const clearErrors = () => {
            form.title.error = '';
            form.director.error = undefined;
            form.logo.error = undefined;
            form.signature.error = undefined;
            form.printing.error = undefined;
            form.directorPosition.error = undefined;
            form.entityCode.error = undefined;
            form.registrationReasonCode.error = undefined;
            form.address.error = undefined;
        };
        const {init} = useFormErrors();

        const createWrapper = async () => {
            const payload = {
                title: form.title.value,
                director: form.director.value,
                logo: form.logo.value,
                signature: form.signature.value,
                printing: form.printing.value,
                directorPosition: form.directorPosition.value,
                entityCode: form.entityCode.value ? String(form.entityCode.value) : undefined,
                registrationReasonCode: form.registrationReasonCode.value ? String(form.registrationReasonCode.value) : undefined,
                address: form.address.value,
            };
            if (props.mode === 'edit') {
                emit('update', {...payload, id: props.defaultValue.id});
            } else {
                const {ok} = await init(form, async () => {
                    return createEntity(payload, props.workspaceId);
                }, clearErrors);
                if (ok) {
                    notification.success({
                        description: 'Компания успешно создана',
                        message: 'Успех!'
                    });
                    emit('ok');
                }
            }
        };

        const disabled = computed(() => {
            return !form.title.value;
        });
        const onUpload = (key, data) => {
            form[key].value = data.key;
        };
        return {
            createWrapper,
            disabled,
            form,
            loading,
            selectFilterFunc,
            clients,
            onUpload,
        };
    }
};
</script>

<style lang="scss" scoped>
.add-company-form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
}
</style>