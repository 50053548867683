<template lang="pug">
.excluding-select
    a-select(
        :value="modelValue"
        :placeholder="placeholder"
        style="width: 100%"
        allowClear
        mode="tags"
        @change="onChange"
    )
        a-select-option(
            v-for="option in options"
            :key="option.key"
            :value="option.key"
        ) {{ option.title }}
</template>

<script>
export default {
    name: 'ExcludingSelect'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/

import {ref} from 'vue';

/*===============INITIALIZE AREA===================*/

const props = defineProps({
    modelValue: {
        type: Array,
    },
    options: {
        type: Array,
    },
    placeholder: {
        type: String,
        default: ''
    }
});
const emit = defineEmits(['update:modelValue']);

/*===============HANDLE CHANGES AREA===================*/

const onChange = (newArray) => {
    emit('update:modelValue', newArray);
};
</script>

<style lang="scss">
</style>