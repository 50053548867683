<template lang="pug">
.delete-with-confirm(@click="preventEvents")
    a-popconfirm(
        :title="title"
        ok-text="Да"
        cancel-text="Нет"
        @confirm="onConfirm"
    )
        a-button(type="danger" v-if="!btnText")
            template(#icon)
                .flex.items-center.justify-center
                    icon(type="Delete" color="white" )
        .delete-with-confirm__text(v-else) {{ btnText }}
</template>

<script>
export default {
    name: 'DeleteWithConfirm',
    props: {
        deleteFunc: {
            type: Function,
            required: true,
        },
        title: {
            type: String,
            default: 'Вы уверены, что хотите удалить?'
        },
        btnText: {
            type: String
        }
    },
    setup(props) {
        const onConfirm = (e) => {
            e.stopPropagation();
            props.deleteFunc();
        };
        const preventEvents = e => {
            e.stopPropagation();
            e.preventDefault();
        };
        return {onConfirm, preventEvents};
    }
};
</script>

<style lang="scss" scoped>
.delete-with-confirm {
    &__text {
        text-align: left;
    }
}
</style>