import {apiRequest} from '@/modules/error_handler';
import widgetModule from '@/modules/widget';
import {ref} from 'vue';

export function useWidget(widgetKey, loading={value: false}) {
    const widgetSettings = ref({});
    const updateSettings = async (settings) => {
        return apiRequest(async () => {
            return widgetModule.updateSettings(widgetKey, settings);
        }, loading);
    };
    const getPatchedSettings = (settings, members) => {
        settings = settings || {};
        const defaults = {};
        for (const valueElement of members) {
            defaults[valueElement.id] = settings[valueElement.id] === undefined || !!settings[valueElement.id];
        }
        return defaults;
    };
    const getSettings = async (members) => {
        return apiRequest(async () => {
            const response = await widgetModule.getSettings(widgetKey);
            widgetSettings.value = getPatchedSettings(response.settings, members);
            return widgetSettings;
        });
    };
    return {
        widgetSettings,

        getSettings,
        updateSettings,
    };
}