<template lang="pug">
.page.login-page
    .login-page-left
        typography-title.login-page__title Заполните личные данные
        .login-page-form
            input-with-error(:error="form.fullName.error")
                template(#input)
                    a-input(placeholder="ФИО" v-model:value="form.fullName.value")
            input-with-error(:error="form.password.error")
                template(#input)
                    a-input.mt-3(placeholder="Пароль" v-model:value="form.password.value" type="password")
            a-checkbox.mt-3(v-model:checked="terms") Я принимаю условия пользовательского соглашения
            a-button.mt-5.mb-2(
                type="primary"
                block
                @click="acceptInvitationWrapper"
                :loading="loading"
                :disabled="disabled"
            ) Войти
    .login-page-right
        laptop
</template>

<script>

import {ref, computed, reactive, onMounted} from 'vue';
import {useAuth} from '@/composition/auth';
import {useFormErrors} from '@/composition/errors';
import InputWithError from '@/components/Generic/InputWithError';
import {useRoute, useRouter} from 'vue-router';
import Laptop from '@/assets/img/super-with-laptop.svg';
import {notification} from 'ant-design-vue';
export default {
    name: 'InviteUser',
    components: {InputWithError, Laptop},
    setup() {
        const router = useRouter();
        const route = useRoute();
        const user = ref({});
        const loading = ref(false);
        const terms = ref(false);
        const form = reactive({
            fullName: {
                value: '',
                error: ''
            },
            password: {
                value: '',
                error: ''
            }
        });
        const {acceptInvitation, getUserByInvitationId} = useAuth(loading);
        const clearErrors = () => {
            form.password.error = '';
            form.fullName.error = '';
        };
        const {init} = useFormErrors();

        const acceptInvitationWrapper = async () => {
            const {ok} = await init(form, async () => {
                const payload = {
                    fullName: form.fullName.value,
                    password: form.password.value,
                    userId: user.value.id,
                    workspaceId: user.value.workspaceId
                };
                return acceptInvitation(payload);
            }, clearErrors);
            if (ok) {
                await router.push('/');
            }
        };

        const disabled = computed(() => {
            return !form.password.value || !form.fullName.value || !terms.value;
        });
        onMounted(async () => {
            const query = route.query.invitationId;
            if (query) {
                const {result} = await getUserByInvitationId(query);
                if (result.accountAlreadyExists) {
                    notification.success({
                        message: 'Успех!',
                        description: 'Приглашение принято!'
                    });
                    router.push('/');
                }
                user.value = result;
                form.fullName.value = user.value.fullName;
            }
        });
        return {
            form,
            loading,
            disabled,
            acceptInvitationWrapper,
            terms,
            user
        };
    }
};
</script>

<style scoped lang="scss">

</style>