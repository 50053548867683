<template lang="pug">
.page.login-page
    .login-page-left
        typography-title.login-page__title Welcome to your productivity origin story
        .login-page-form
            input-with-error(:error="form.email.error")
                template(#input)
                    a-input(placeholder="email" v-model:value="form.email.value" @pressEnter="loginWrapper")
            input-with-error(:error="form.password.error")
                template(#input)
                    a-input.mt-3(placeholder="password" v-model:value="form.password.value" type="password" @pressEnter="loginWrapper")
            a-button.mt-5.mb-2(
                type="primary"
                block
                @click="loginWrapper"
                :loading="loading"
                :disabled="disabled"
            ) Войти
            router-link(to="/register") Нет аккаунта?
            router-link.block(to="/restore-password-request") Забыли пароль?
    .login-page-right
        laptop
</template>

<script>

import {ref, computed, reactive} from 'vue';
import {useAuth} from '@/composition/auth';
import {useFormErrors} from '@/composition/errors';
import InputWithError from '@/components/Generic/InputWithError';
import {useRouter} from 'vue-router';
import Laptop from '@/assets/img/super-with-laptop.svg';
import socketModule from '@/modules/socket';
export default {
    name: 'Login',
    components: {InputWithError, Laptop},
    setup() {
        const router = useRouter();

        const loading = ref(false);

        const form = reactive({
            email: {
                value: '',
                error: ''
            },
            password: {
                value: '',
                error: ''
            }
        });
        const {login} = useAuth(loading);
        const clearErrors = () => {
            form.email.error = '';
            form.password.error = '';
        };
        const {init} = useFormErrors();

        const loginWrapper = async () => {
            const {ok} = await init(form, async () => {
                return login(form.email.value, form.password.value);
            }, clearErrors);
            if (ok) {
                socketModule.auth();
                await router.push('/');
            }
        };

        const disabled = computed(() => {
            return !form.email.value || !form.password.value;
        });
        return {
            login,
            form,
            loading,
            disabled,
            loginWrapper
        };
    }
};
</script>

<style scoped lang="scss">
.login-page {
    display: flex;

    &__title {
        max-width: 400px;
        text-align: center;
    }

    &-form {
        width: 280px;
        margin: 30px 0;
    }

    &-right {
        min-height: 100vh;
        height: 100%;
        width: 50%;
        background: $greyColor;
        display: flex;
        align-items: center;
        justify-content: center;
        @media(max-width: $screenMdMin) {
            display: none;
        }
    }

    &-left {
        min-height: 100vh;
        height: 100%;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @media(max-width: $screenMdMin) {
            width: 100%;
        }
    }
}
.errors {
    border-color: #42b983 !important;
}
</style>