<template lang="pug">
.transactions-table
    a-button.mb-2(type="primary" @click="setModalVisibility(true)") Добавить
    transaction-filter.mb-2(@filter="onFilterChanged" :billId="billId" :periods="periods")
    .transactions-table__chart
        chart-wrapper(:loading="loading" :height="300")
            line-chart(:data="balanceHistory" series-name="Баланс" :dashPosition="dashPosition")
        //.ml-3(class="w-2/4")
        //    suspense
        //        bank-account-balances(:update="refetch")
    .flex.items-center.mt-2
        card-title.mr-2(:class="[transactionStats.in > 0 ? 'success' : '']") Поступления: {{ formatCurrency(transactionStats.in) }} руб.
        card-title.mr-2(:class="[transactionStats.out > 0 ? 'danger' : '']") Списания: {{ formatCurrency(transactionStats.out) }} руб.
        card-title.mr-2(:class="[transactionStats.diff > 0 ? 'success' : 'danger']") Разница: {{ formatCurrency(transactionStats.diff) }} руб.
    custom-table.mt-3(
        :columns="columns"
        :data-source="transactions.list"
        :onRowClick="editTransaction"
    )
        template(#date="{text, record}")
            date-inline-changer(
                placeholder="Дата"
                :date="record.date"
                :func="async (date) => await onUpdate({...record, date})"
                :can-be-outdated="record.isScheduled"
                is-date-only
            )
        template(#amount="{text, record}")
            .transactions-table__amount(v-if="record" :class="[getColor(record)]")
                div {{ getAmountTitle(record) }}
                items-per-service(:services="record.services" :positions="record.positions" :currency="getCurrency(record)")
        template(#contractor="{text, record}")
            div(v-if="record") {{ getContractor(record) }}
        template(#period="{record}")
            period-inline-changer(
                :period="record.period"
                :func="async (period) => await onUpdate({...record, period})"
            )
        template(#bankAccount="{text, record}")
            div(v-if="record") {{ getBankAccountTitle(record) }}
        template(#operation="{record}")
            div(v-if="record") {{ getTitle(record, 'operation') }}
        template(#action="{text, record}")
            .flex.items-center.justify-end
                a-button.mr-3(
                    type="primary"
                    @click.stop="onUpdate({...record, isScheduled: false})"
                    v-if="record.isScheduled"
                ) Принять
                a-dropdown(:trigger="['click']" @click.stop)
                    .dots-wrapper
                        icon.cursor-pointer(type="Dots")
                    template(#overlay)
                        a-menu
                            a-menu-item(key="1")
                                delete-with-confirm(:deleteFunc="() => onDelete(record.id, record)" btnText="Удалить")
                            a-menu-item(key="2")
                                div(@click="editTransaction(record)") Редактировать
    a-button.mt-3(@click="next" v-if="transactions.next" :loading="loading") Показать еще
    a-drawer(
        :visible="showModal"
        :title="currentMode === 'create' ? 'Добавить транзакцию' : 'Редактировать транзакцию'"
        @close="setModalVisibility(false)"
        :closable="true"
        width="60%"
        :footer="null"
        :destroyOnClose="true"
    )
        create-transaction-form(
            :workspaceId="currentWorkspaceId"
            @ok="onCreate"
            @update="onUpdate"
            @delete="(transaction) => onDelete(transaction.id, transaction, () => setModalVisibility(false))"
            :mode="currentMode"
            :default-value="editingElement"
            :billId="billId"
        )
</template>

<script>
import {columns, transactionSource, transactionSourceMap, transactionType} from '../constants';
import {computed, ref} from 'vue';
import constants from '@/constants';
import {useWorkspace} from '@/composition/workspace';
import EditableCell from '@/components/Generic/EditableCell';
import Icon from '@/components/Generic/Typography/Icon';
import {useTransaction} from '@/composition/transaction';
import CreateTransactionForm from '@/components/Transactions/CreateForm';
import {useDate} from '@/composition/date';
import TransactionFilter from '@/components/Transactions/Filter';
import CustomTable from '@/components/Generic/CustomTable';
import {useCreateOrUpdateModal} from '@/composition/create-update-modal';
import ChartWrapper from '@/components/Generic/Charts/ChartWrapper';
import LineChart from '@/components/Generic/Charts/LineChart';
import BankAccountBalances from '@/components/Transactions/BankAccountBalances';
import Modal from '@/components/Generic/Modal';
import {useDirectory} from '@/composition/directory';
import {useBill} from '@/composition/bill';
import {useService} from '@/composition/service';
import {formatCurrency, getTitle, safePromise} from '@/modules/utils';
import ItemsPerService from '@/components/Transactions/ItemsPerService';
import {BillPaymentStatusKey} from '@/components/Bills/constants';
import CardTitle from '@/components/Generic/Typography/CardTitle';
import DateInlineChanger from '@/components/Generic/InlineChanger/DateInlineChanger';
import PeriodInlineChanger from '@/components/Generic/InlineChanger/PeriodInlineChanger';
import {useDialog} from '@/components/Generic/Dialog/dialog';
export default {
    name: 'TransactionsTable',
    components: {
        PeriodInlineChanger,
        DateInlineChanger,
        CardTitle,
        ItemsPerService,
        BankAccountBalances,
        LineChart,
        ChartWrapper,
        CustomTable,
        TransactionFilter,
        CreateTransactionForm,
        Icon,
        EditableCell,
        Modal
    },
    props: {
        billId: [String, Number]
    },
    async setup(props) {
        const {
            currentMode,
            editingValue: editingElement,
            showModal,
            setModalVisibility,
            edit
        } = useCreateOrUpdateModal();
        const loading = ref(false);
        const refetch = ref(false);
        const {show: showDialog} = useDialog();
        const onRefetch = () => {
            refetch.value = true;
            setTimeout(() => {
                refetch.value = false;
            }, 500);
        };
        const source = ref(transactionSource.with_bill);
        const {currentWorkspaceId, currentWorkspaceTz} = useWorkspace(loading);
        const {
            pagination,
            deleteTransaction,
            getTransactions,
            updateTransaction,
            transactions,
            setFilter,
            getBalanceHistory,
            balanceHistory,
            getTransactionStats,
            transactionStats,
            updateOneLocal,
            updateRepeatableTransactions,
            deleteRepeatableTransactions,
        } = useTransaction(loading);
        const {getDirectories, directories} = useDirectory(loading);
        const {getBills, setFilter: setBillsFilter} = useBill(loading);
        const {periods, getPeriods} = useService(loading);
        const {formatDate, inFuture} = useDate();
        const setDefaultBillsFilter = () => {
            const notPaidStatus = directories.value.billPaymentStatuses.list.find(x => x.key === BillPaymentStatusKey.not_paid);
            const partiallyPaidStatus = directories.value.billPaymentStatuses.list.find(x => x.key === BillPaymentStatusKey.partially_paid);
            if (notPaidStatus && partiallyPaidStatus) {
                setBillsFilter({
                    paymentStatuses: [partiallyPaidStatus.id, notPaidStatus.id]
                });
            }
        };
        await getDirectories(currentWorkspaceId.value, 'bills');
        setDefaultBillsFilter();
        await getBills(currentWorkspaceId.value);
        await getPeriods();

        const onDelete = async (id, transaction, done) => {
            pagination.value.offset = 0;
            let deleteType = 'only_one';
            if (transaction.isRepeatable || transaction.prevId) {
                const [err, dialogResult] = await safePromise(showDialog({
                    title: 'Удаление повторяющейся операции',
                    type: 'radio',
                    options: [
                        {
                            id: 'only_one', title: 'Удалить только эту операцию',
                        },
                        {
                            id: 'right', title: 'Эту и последующие операции',
                        },
                        {
                            id: 'all', title: 'Все операции в цепочке',
                        }
                    ]
                }));
                if (err || !dialogResult) {
                    done && done();
                    return;
                }
                deleteType = dialogResult;
            }
            if (deleteType === 'only_one') {
                await deleteTransaction(currentWorkspaceId.value, id);
            } else {
                await deleteRepeatableTransactions(id, deleteType);
            }
            await getTransactions(currentWorkspaceId.value, {}, currentWorkspaceTz.value);
            await getBalanceHistory(currentWorkspaceId.value);
            await getTransactionStats(currentWorkspaceId.value);
            onRefetch();
            done && done();
        };
        const onUpdate = async (transaction) => {
            const {id, ...updateData} = transaction;
            let updateType = 'only_one';
            if (transaction.isRepeatable || transaction.prevId) {
                const [err, dialogResult] = await safePromise(showDialog({
                    title: 'Изменение повторяющейся операции',
                    type: 'radio',
                    options: [
                        {
                            id: 'only_one', title: 'Изменить только эту операцию',
                        },
                        {
                            id: 'right', title: 'Эту и последующие операции',
                        }
                    ]
                }));
                if (err || !dialogResult) return;
                updateType = dialogResult;
            }
            if (updateType === 'only_one') {
                const {ok, result} = await updateTransaction(currentWorkspaceId.value, id, updateData);
                if (ok) {
                    updateOneLocal(result);
                    await getBalanceHistory(currentWorkspaceId.value);
                    await getTransactionStats(currentWorkspaceId.value);
                    setModalVisibility(false);
                    onRefetch();
                }
            } else if (updateType === 'right') {
                const {ok, result} = await updateRepeatableTransactions(id, updateData);
                if (ok) {
                    await getTransactions(currentWorkspaceId.value, {fromPagination: false}, currentWorkspaceTz.value);
                    await getBalanceHistory(currentWorkspaceId.value);
                    await getTransactionStats(currentWorkspaceId.value);
                    setModalVisibility(false);
                    onRefetch();
                }
            }

        };
        const next = async () => {
            pagination.value.offset += pagination.value.limit;
            await getTransactions(currentWorkspaceId.value, {fromPagination: true}, currentWorkspaceTz.value);
        };
        const onFilterChanged = async (filter) => {
            pagination.value.offset = 0;
            setFilter(filter);
            await getTransactions(currentWorkspaceId.value, {fromPagination: false}, currentWorkspaceTz.value);
            await getBalanceHistory(currentWorkspaceId.value);
            await getTransactionStats(currentWorkspaceId.value);
        };
        const onCreate = async () => {
            pagination.value.offset = 0;
            await getTransactions(currentWorkspaceId.value, {fromPagination: false}, currentWorkspaceTz.value);
            await getBalanceHistory(currentWorkspaceId.value);
            await getTransactionStats(currentWorkspaceId.value);
            onRefetch();
            setModalVisibility(false);
        };
        const dashPosition = computed(() => {
            const index = balanceHistory.value.map(x => x).reverse().findIndex(x => inFuture(x.date));
            return index - 1;
        });
        const getSign = (transaction) => {
            switch (transaction.type) {
                case transactionType.incoming:
                    return '+';
                case transactionType.withdrawal:
                    return '-';
                default:
                    return '';
            }
        };
        const getColor = (transaction) => {
            switch (transaction.type) {
                case transactionType.incoming:
                    return 'success';
                case transactionType.withdrawal:
                    return 'danger';
                default:
                    return '';
            }
        };
        const getCurrency = (transaction) => {
            const bankAccount = getBankAccount(transaction);
            const currency = constants.currencyMap[bankAccount?.currency];
            if (transaction.type === transactionType.transfer) {
                if (currency) return currency;
                return {
                    from: constants.currencyMap[bankAccount.fromCurrency],
                    to: constants.currencyMap[bankAccount.toCurrency],
                };
            }
            return currency;
        };
        const getContractor = (transaction) => {
            const contractor = transaction?.contractor?.title ? transaction?.contractor?.title : transaction?.contractor?.fullName;
            return contractor || 'Не указано';
        };
        const editTransaction = (transaction) => {
            const payload = {
                ...transaction,
                period: transaction.period
            };
            if (transaction.type === transactionType.transfer) {
                payload.fromAmount = transaction.amount;
                payload.fromAccountId = transaction.fromAccountId;
                payload.toAmount = transaction?.relatedTransaction?.amount;
                payload.toAccountId = transaction?.relatedTransaction?.toAccountId;
            }
            edit(payload);
        };
        const getBankAccount = (transaction) => {
            switch (transaction.type) {
                case transactionType.incoming:
                    return transaction.toAccount || {};
                case transactionType.withdrawal:
                    return transaction.fromAccount || {};
                case transactionType.transfer:
                    const fromCurrency = transaction?.fromAccount?.currency;
                    const toCurrency = transaction?.relatedTransaction?.toAccount?.currency;
                    return {
                        fromCurrency,
                        toCurrency,
                        currency: fromCurrency === toCurrency ? fromCurrency : null,
                        fromAmount: transaction.amount,
                        toAmount: transaction?.relatedTransaction?.amount,
                        from: transaction?.fromAccount || {},
                        to: transaction?.relatedTransaction?.toAccount || {}
                    };
            }
        };
        const getBankAccountTitle = (record) => {
            const bankAccount = getBankAccount(record);
            if (bankAccount.from && bankAccount.to) {
                return `${bankAccount.from.title} ⟶ ${bankAccount.to.title}`;
            }
            return bankAccount.title;
        };
        const getAmountTitle = (record) => {
            const bankAccount = getBankAccount(record);
            if (bankAccount.from && bankAccount.to) {
                const currency = getCurrency(record);
                let fromCurrency;
                let toCurrency;
                if (currency.from && currency.to) {
                    fromCurrency = currency.from;
                    toCurrency = currency.to;
                } else {
                    fromCurrency = toCurrency = currency;
                    return `${formatCurrency(bankAccount.fromAmount)} ${fromCurrency}`;
                }
                return `${formatCurrency(bankAccount.fromAmount)} ${fromCurrency} ⟶ ${formatCurrency(bankAccount.toAmount)} ${toCurrency}`;
            }
            return `${getSign(record)} ${formatCurrency(record.amount)} ${getCurrency(record)}`;
        };
        return {
            source,
            loading,
            periods,
            refetch,
            columns,
            showModal,
            currentMode,
            transactions,
            dashPosition,
            balanceHistory,
            editingElement,
            transactionType,
            transactionStats,
            transactionSource,
            transactionSourceMap,
            currencyMap: constants.currencyMap,
            edit,
            next,
            getSign,
            onCreate,
            getTitle,
            onDelete,
            onUpdate,
            getColor,
            formatDate,
            getCurrency,
            getContractor,
            getAmountTitle,
            formatCurrency,
            onFilterChanged,
            editTransaction,
            currentWorkspaceId,
            setModalVisibility,
            getBankAccountTitle,
        };
    }
};
</script>

<style lang="scss" scoped>
.transactions-table {
    &__amount {
        font-weight: 600;
    }
    &__chart {
        width: 98%
    }
}
</style>
