<template lang="pug">
.salary-payments-list-page
    a-button(type="primary" @click="setModalVisibility(true)") Добавить
    salary-payments-list-filter.mt-2(v-model:filter="salaryListFilter")
    salary-payments-list-table(
        :loading="loading"
        :paginationLoading="paginationLoading"
        :data="salaryPaymentsListList.list"
        @edit="onEdit"
        @delete="onDelete"
        @next="onNextPage"
        @fetch="onTableFetch"
        :has-next="salaryPaymentsListList.next"
    )
    a-modal(
        :visible="showModal"
        :title="currentMode === 'edit' ? 'Редактировать' : 'Добавить'"
        :footer="null"
        @cancel="setModalVisibility(false)"
        :destroyOnClose="true"
        style="top: 0"
        width="60%"
    )
        salary-payments-list-create-form(
            @create="onCreate"
            @update="onUpdate"
            :mode="currentMode"
            :default-value="editingValue"
            :loading="loading"
        )
</template>

<script>
import {onMounted, ref, watch} from 'vue';
import SalaryPaymentsListFilter from '@/components/SalaryPaymentsList/Filter';
import SalaryPaymentsListTable from '@/components/SalaryPaymentsList/Table';
import SalaryPaymentsListCreateForm from '@/components/SalaryPaymentsList/CreateForm';
import {useCreateOrUpdateModal} from '@/composition/create-update-modal';
import {useSalary} from '@/composition/salary-list';
import {useDate} from '@/composition/date';
import {toLabelValue} from '@/modules/utils';
import {notification} from 'ant-design-vue';
import {useWorkspace} from '@/composition/workspace';
import {useDirectory} from '@/composition/directory';

export default {
    name: 'SalaryPaymentsListPage',
    components: {SalaryPaymentsListCreateForm, SalaryPaymentsListTable, SalaryPaymentsListFilter},
    setup() {
        /*=======================INIT AREA=======================*/

        const {
            showModal,
            currentMode,
            editingValue,
            edit,
            setModalVisibility,
        } = useCreateOrUpdateModal();
        const {getCurrentYear, dateInstance, fromUtcToTz} = useDate();
        const {currentWorkspaceId} = useWorkspace();
        const {getDirectories} = useDirectory();
        const loading = ref(false);
        const paginationLoading = ref(false);
        const {
            salaryListFilter,
            salaryPaymentsListList,
            salaryPaymentsPagination,
            createSalaryPaymentsList,
            updateSalaryPaymentsList,
            deleteSalaryPaymentsList,
            getSalaryPaymentsListList,
        } = useSalary(loading);
        /*=======================WATCH FILTER AREA=======================*/
        watch(() => salaryListFilter.value, () => {
            salaryPaymentsPagination.value.offset = 0;
            getSalaryPaymentsListList();
        });
        /*=======================CRUD METHODS AREA=======================*/
        const onCreate = async (data) => {
            loading.value = true;
            const {ok} = await createSalaryPaymentsList(data);
            if (ok) {
                setModalVisibility(false);
                await getSalaryPaymentsListList();
            }
            loading.value = false;
        };
        const onUpdate = async (data) => {
            loading.value = true;
            const {id, ...updateData} = data;
            const {ok} = await updateSalaryPaymentsList(data.id, updateData);
            if (ok) {
                setModalVisibility(false);
                await getSalaryPaymentsListList();
                notification.success({
                    message: 'Успех!',
                    description: 'Сохранено'
                });
            }
            loading.value = false;
        };
        const onDelete = async (id) => {
            const {ok} = await deleteSalaryPaymentsList(id);
            if (ok) {
                await getSalaryPaymentsListList();
            }
        };
        const onEdit = (data) => {
            edit({
                ...data,
                period: {
                    value: data.period
                },
                members: toLabelValue(data.members, 'id', 'fullName'),
                dateFrom: fromUtcToTz(data.dateFrom).format('YYYY-MM-DD'),
                dateTo: fromUtcToTz(data.dateTo).format('YYYY-MM-DD'),
                payDateTo: fromUtcToTz(data.payDateTo).format('YYYY-MM-DD')
            });
        };
        const onNextPage = async () => {
            paginationLoading.value = true;
            salaryPaymentsPagination.value.offset += salaryPaymentsPagination.value.limit;
            await getSalaryPaymentsListList({fromPagination: true});
            paginationLoading.value = false;
        };
        const onTableFetch = async () => {
            await getSalaryPaymentsListList();
        };
        const processDefaultFilter = () => {
            salaryListFilter.value = {
                year: getCurrentYear(),
            };
        };
        onMounted(async () => {
            processDefaultFilter();
            await getSalaryPaymentsListList();
            await getDirectories(currentWorkspaceId.value, 'bills');
        });
        return {
            loading,
            showModal,
            currentMode,
            editingValue,
            salaryListFilter,
            paginationLoading,
            salaryPaymentsListList,
            onEdit,
            onUpdate,
            onCreate,
            onDelete,
            onNextPage,
            onTableFetch,
            setModalVisibility,
        };
    },
};
</script>

<style scoped>

</style>