import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "expensess" }
const _hoisted_2 = { class: "expenses__item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.expenses, (expense) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode($setup["ServicePaymentPeriodExpensesListItem"], {
          class: "mt-2",
          item: expense,
          onChange: (e) => $setup.onItemChanged(e, expense),
          onDelete: _cache[0] || (_cache[0] = (e) => $setup.onItemDelete(e))
        }, null, 8, ["item", "onChange"])
      ]))
    }), 256))
  ]))
}