<template lang="pug">
.services-radio-group
    .services-radio-group__title Услуга
    a-radio-group.services-radio-group__elements(:value="modelValue" @change="onChange")
        a-radio.services-radio-group__element(:value="undefined") Не выбрано
        a-radio.services-radio-group__element(
            v-for="service in services"
            :value="service.id"
            :key="service.id"
            :title="service.title"
        ) {{ service.title }}
</template>

<script>
export default {
    name: 'ServicesRadioGroup'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
/*===============INIT AREA===================*/
const props = defineProps({
    modelValue: {
        type: Number,
    },
    services: {
        type: Array
    }
});
const emits = defineEmits(['update:modelValue']);
/*===============HANDLE CHANGES AREA===================*/
const onChange = (event) => {
    emits('update:modelValue', event.target.value);
};

</script>

<style lang="scss">
.services-radio-group {
    &__element {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        margin-bottom: 7px;
    }
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
}
</style>