<template lang="pug">
async-page
    members-table
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import MembersTable from '@/components/Workspace/Member/Table';
export default {
    name: 'MembersPage',
    components: {MembersTable, AsyncPage}
};
</script>

<style scoped>

</style>