<template lang="pug">
.members-filter
    a-input.mr-3(
        placeholder="Поиск по ФИО, email, навыкам"
        v-model:value="filter.search"
    )
    a-select.mr-3(
        v-model:value="filter.groups"
        placeholder="Группа"
        mode="multiple"
        show-search
        :filter-option="selectFilterFunc"
        style="width: 100%"
        allowClear
    )
        a-select-option(
            v-for="group in directories.groups.list"
            :key="group.id"
            :value="group.id"
            :label="group.title"
        ) {{ group.title }}
    a-select.mr-3(
        v-model:value="filter.gradeId"
        placeholder="Грейд"
        show-search
        :filter-option="selectFilterFunc"
        style="width: 50%"
        allowClear
    )
        a-select-option(
            v-for="grade in directories.grades.list"
            :key="grade.id"
            :value="grade.id"
            :label="grade.title"
        )
            | {{ grade.title }}
    a-input-group.mr-3(compact)
        a-input-number(style="width: 50%" v-model:value="filter.rate.from" placeholder="Ставка от" :min="0")
        a-input-number(style="width: 50%" v-model:value="filter.rate.to" placeholder="Ставка до" :min="0")
    a-select.mr-3(
        v-model:value="filter.status"
        placeholder="Статус"
        style="width: 100%"
        allowClear
        mode="multiple"
    )
        a-select-option(
            :key="memberStatus.active"
            :value="memberStatus.active"
        )
            | {{ memberStatusMap.active }}
        a-select-option(
            :key="memberStatus.invited"
            :value="memberStatus.invited"
        )
            | {{ memberStatusMap.invited }}
        a-select-option(
            :key="memberStatus.blocked"
            :value="memberStatus.blocked"
        )
            | {{ memberStatusMap.blocked }}
a-tabs(v-model:activeKey="filter.roleId")
    a-tab-pane(v-for="tab in tabs" :key="tab.key" :tab="`${tab.title} (${tab.count})`")
</template>

<script>
import {onMounted, reactive, ref, watch} from 'vue';
import {useDirectory} from '@/composition/directory';
import {memberStatus, memberStatusMap} from '@/components/Workspace/Member/constants';
import {selectFilterFunc} from '@/modules/utils';
import {useRoute, useRouter} from 'vue-router';

export default {
    name: 'MembersFilter',
    props: {
        tabs: {
            type: Array,
            default: () => ([])
        }
    },
    emits: ['filter'],
    setup(props, {emit}) {
        const loading = ref(false);
        const router = useRouter();
        const route = useRoute();
        const filter = reactive({
            search: '',
            groups: [],
            gradeId: undefined,
            rate: {
                from: undefined,
                to: undefined,
            },
            status: [],
            roleId: undefined,
        });
        watch(filter, () => {
            router.push({
                query: {
                    roleId: filter.roleId
                }
            });
            emit('filter', filter);
        });
        onMounted(() => {
            const roleId = route.query.roleId;
            filter.status = [memberStatus.active, memberStatus.invited];
            filter.roleId = roleId && roleId !== 'all' ? Number(roleId) : 'all';
        });
        const {directories} = useDirectory(loading);
        return {
            filter,
            directories,
            memberStatus,
            memberStatusMap,
            selectFilterFunc
        };
    }
};
</script>

<style lang="scss" scoped>
.members-filter {
    display: flex;
    align-items: center;
}
</style>