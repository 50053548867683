export default {
    currency: {
        rub: 'rub',
        usd: 'usd',
        euro: 'euro',
        pounds: 'pounds'
    },
    currencyMap: {
        rub: '₽',
        usd: '$',
        euro: '€',
        pounds: '£'
    },
    tz: {
        default: 'Europe/Moscow'
    },
    palette: {
        red: '#FF4D4F',
        blue: '#1890FF',
        green: '#52C41A',
        yellow: '#FAAD14'
    },
    statusIconText: {
        deadline: 'Услуга просрочена',
        payment: {
            pending: 'Ожидание выплат',
            success: 'Выплаты проведены',
            error: 'Выплаты не проведены вовремя'
        },
        documentStatus: {
            in_progress: 'В работе',
            done: 'Завершен'
        }
    },
    averageWorkingDayInSeconds: 8 * 60 * 60,
    averageWorkingWeekInSeconds: 40 * 60 * 60,
    averageWorkingMonthInSeconds: 160 * 60 * 60,
};
