<template lang="pug">
bill-layout
    async-page
        bill-common-data
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import BillLayout from '@/pages/Bills/Bill/Layout';
import BillCommonData from '@/components/Bills/Common';
export default {
    name: 'BillCommonPage',
    components: {BillCommonData, BillLayout, AsyncPage}
};
</script>

<style scoped>

</style>