<template lang="pug">
hourly-reports-layout
    async-page
        saved-reports-table
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import SavedReportsTable from '@/components/Analytics/SavedReports/Table';
import HourlyReportsLayout from '@/pages/Reports/Layout';
export default {
    name: 'SavedReportsPage',
    components: {HourlyReportsLayout, SavedReportsTable, AsyncPage}
};
</script>

<style scoped>

</style>