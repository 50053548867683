<template lang="pug">
.services-select.w-full
    a-select(
        :value="modelValue"
        :style="{width}"
        :placeholder="placeholder"
        :filter-option="false"
        @change="onChange($event)"
        @search="onSearch($event)"
        show-search
        allow-clear
        :mode="mode"
        @blur="onBlur"
        :disabled="disabled"
    )
        a-select-option(
            v-for="service in services.list"
            :key="service.id"
            :value="service.id"
        )
            | {{ service.fullTitle }}
</template>

<script>
import {useWorkspace} from '@/composition/workspace';
import {debounce} from '@/modules/utils';
import {watch} from 'vue';
import {useService} from '@/composition/service';
export default {
    name: 'ServicesSelect',
    props: {
        modelValue: [Number, String],
        placeholder: {
            type: String,
            default: 'Услуга'
        },
        mode: {
            type: String,
            default: ''
        },
        additionalFilter: Object,
        disabled: {
            type: Boolean
        },
        width: {
            type: String,
            required: false,
            default: '100%'
        }
    },
    emits: ['update:modelValue', 'blur'],
    setup(props, {emit}) {
        const {currentWorkspaceId} = useWorkspace();
        const {services, getServices, setFilter, filter} = useService();
        watch(() => props.modelValue, () => {
            if (props.modelValue) {
                onChange(props.modelValue);
            }
        });
        const applyAdditionalFilter = () => {
            if (props.additionalFilter && Object.keys(props.additionalFilter).length) {
                getServices(currentWorkspaceId.value, {}, props.additionalFilter || {});
            }
        };
        applyAdditionalFilter();
        watch(() => props.additionalFilter, () => {
            applyAdditionalFilter();
        }, {deep: true});
        const onSearch = async (q) => {
            setFilter({...filter.value, search: q});
            debounce(() => getServices(currentWorkspaceId.value, {}, props.additionalFilter || {}), 500)();
        };
        const onChange = (id) => {
            emit('update:modelValue', id);
        };
        const onBlur = (e) => {
            emit('blur', e);
        };

        return {
            services,
            onBlur,
            onSearch,
            onChange,
        };
    }
};
</script>

<style scoped>

</style>