<template lang="pug">
.date-inline-changer
    .date-inline-changer__title(@click.stop="setPopupVisibility(true)")
        span.cursor-pointer(
            :class="{danger: canBeOutdated && isOutdated(date)}"
        ) {{ date ? formatDate(date): 'Не указано' }}
    a-popover(
        :visible="showPopup"
        placement="bottomLeft"
    )
        template(#content)
            .date-inline-changer__popup
                a-date-picker(
                    :placeholder="placeholder"
                    v-model:value="newDate"
                    format="DD.MM.YYYY"
                    v-if="!isDateOnly"
                )
                date-only-picker(
                    :placeholder="placeholder"
                    v-model:date="newDate"
                    v-else-if="isDateOnly"
                )
                .date-inline-changer__popup-btns
                    a-button(@click="onSave" type="primary" :loading="loading") Сохранить
                    a-button.ml-3(@click="onDecline") Отменить
</template>

<script>
export default {
    name: 'DateInlineChanger'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
/*===============INIT AREA===================*/
import {ref, watch} from 'vue';
import {useLoading} from '@/composition/loading-state';
import {useDate} from '@/composition/date';
import DateOnlyPicker from '@/components/Generic/DateOnlyPicker';

const props = defineProps({
    date: {
        type: String,
    },
    func: {
        type: Function
    },
    placeholder: {
        type: String,
    },
    canBeOutdated: {
        type: Boolean
    },
    isDateOnly: {
        type: Boolean,
        default: false,
    }
});
const emit = defineEmits(['done']);
const {loading, withLoader} = useLoading();
const {loading: fetchLoading, withLoader: wrapFetchAsync} = useLoading();
const {isOutdated, formatDate} = useDate();
/*===============SERVICE AREA===================*/
const newDate = ref(props.date);

watch(() => props.date, () => {
    newDate.value = props.date;
}, {deep: true});
const onSave = async () => {
    await withLoader(async () => {
        if (props.isDateOnly) {
            props.func(newDate.value);
        } else {
            const date = newDate.value ? newDate.value.toISOString() : null;
            await props.func(date);
        }
    });
    setPopupVisibility(false);
};
const onDecline = () => {
    newDate.value = props.date;
    setPopupVisibility(false);
};
/*===============POPUP AREA===================*/
const showPopup = ref(false);
const setPopupVisibility = async (bool) => {
    showPopup.value = bool;
};
</script>

<style lang="scss">
.date-inline-changer {
    &__title {
        min-height: 32px;
        min-width: 40px;
        display: flex;
        align-items: center;
        width: fit-content;
        & span {
            text-decoration: underline;
            text-decoration-style: dotted;
            text-decoration-thickness: from-font;
            cursor: url("~@/assets/img/edit.png") 4 12, auto;
        }
    }
    &__popup {
        width: 240px;
        &-btns {
            margin-top: 12px;
        }
    }
}
.ant-calendar-picker-container {
    z-index: 1062;
}
</style>
