import {httpInstance} from '@/modules/http_factory';
import moment from 'moment';

const formFilter = (filter) => {
    return {
        period: filter.period ? filter.period : undefined,
        from: filter.from,
        to: filter.to
    };
};
const getList = async(filter) => {
    const response = await httpInstance().get('/salary/list', {
        params: formFilter(filter)
    });
    return response.data;
};

const updateSalary = async(id, updateData) => {
    const response = await httpInstance().patch(`/salary/${id}`, {
        ...updateData,
    });
    return response.data;
};

const deleteSalary = async(id, updateData) => {
    const response = await httpInstance().delete(`/salary/${id}`, {
        ...updateData,
    });
    return response.data;
};

const createSalary = async(payload) => {
    const response = await httpInstance().post('/salary', {
        ...payload,
    });
    return response.data;
};

const getStats = async(filter) => {
    const response = await httpInstance().get('/salary/stats', {
        params: formFilter(filter)
    });
    return response.data;
};

const getMembersSalaryPositions = async (ids, from, to) => {
    const response = await httpInstance().get('/salary/members-positions', {
        params: {
            ids: ids.join(','),
            from,
            to
        }
    });
    return response.data;
};
const createSalaryPaymentsList = async (salaryPaymentsList) => {
    const response = await httpInstance().post('/salary/payments-list', {
        period: salaryPaymentsList.period,
        dateFrom: salaryPaymentsList.dateFrom,
        dateTo: salaryPaymentsList.dateTo,
        payDateTo: salaryPaymentsList.payDateTo,
        listMembers: salaryPaymentsList.listMembers,
    });
    return response.data;
};

const updateSalaryPaymentsList = async (id, updateData) => {
    const response = await httpInstance().patch(`/salary/payments-list/${id}`, {
        period: updateData.period,
        dateFrom: updateData.dateFrom,
        dateTo: updateData.dateTo,
        payDateTo: updateData.payDateTo,
        listMembers: updateData.listMembers,
    });
    return response.data;
};
const deleteSalaryPaymentsList = async (id) => {
    const response = await httpInstance().delete(`/salary/payments-list/${id}`);
    return response.data;
};
const getSalaryPaymentsListById = async (id) => {
    const response = await httpInstance().get(`/salary/payments-list/${id}`);
    return response.data;
};
const getSalaryPaymentsListList = async (filter) => {
    const response = await httpInstance().get('/salary/payments-list', {
        params: {
            from: moment().set('year', filter.year).startOf('year').format('YYYY-MM-DD'),
            to: moment().set('year', filter.year).endOf('year').format('YYYY-MM-DD'),
        }
    });
    return response.data;
};


export default {
    createSalary,
    updateSalary,
    deleteSalary,
    getList,
    getStats,
    createSalaryPaymentsList,
    updateSalaryPaymentsList,
    deleteSalaryPaymentsList,
    getSalaryPaymentsListById,
    getSalaryPaymentsListList,
    getMembersSalaryPositions,

};