import {httpInstance} from '@/modules/http_factory';

const getWorkingDay = async(workspaceId, dayKey) => {
    const response = await httpInstance(workspaceId).get('/dashboard/working-day', {
        params: {
            day: dayKey,
            workspaceId,
        }
    });
    return response.data;
};

const getUnpaidBills = async(workspaceId) => {
    const response = await httpInstance(workspaceId).get('/dashboard/unpaid-bills', {
        params: {
            workspaceId,
        }
    });
    return response.data;
};

const getDashboards = async(params) => {
    const response = await httpInstance().get('/dashboard', {
        params
    });
    return response.data;
};

const createDashboard = async(params) => {
    const response = await httpInstance().post('/dashboard', params);
    return response.data;
};

const updateDashboard = async(id, params) => {
    const response = await httpInstance().patch(`/dashboard/${id}`, params);
    return response.data;
};

const deleteDashboard = async(id, params) => {
    const response = await httpInstance().delete(`/dashboard/${id}`, {
        params
    });
    return response.data;
};

const getDashboard = async(id, params) => {
    const response = await httpInstance().get(`/dashboard/${id}`, {
        params
    });
    return response.data;
};

const updateDashboardSettings = async (workspaceId, widgetKey, settings) => {
    const response = await httpInstance(workspaceId).post('/dashboard/settings', {
        workspaceId, widgetKey, settings
    });
    return response.data;
};
const getDashboardSettings = async (workspaceId, widgetKey) => {
    const response = await httpInstance(workspaceId).get('/dashboard/settings', {
        params: {workspaceId, widgetKey}
    });
    return response.data;
};

export default {
    getDashboards,
    createDashboard,
    updateDashboard,
    deleteDashboard,
    getDashboard,
    getWorkingDay,
    getUnpaidBills,
    updateDashboardSettings,
    getDashboardSettings
};