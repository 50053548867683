export const projectColumns = [
    {
        title: 'Наименование',
        dataIndex: 'title',
        key: 'title',
        slots: {customRender: 'title'},
        width: '50%'
    },
    {
        title: 'Клиент',
        dataIndex: 'client',
        key: 'client',
        slots: {customRender: 'client'},
        width: '40%'
    },
    {
        title: 'Идентификатор',
        dataIndex: 'slug',
        key: 'slug',
        slots: {customRender: 'slug'},
        width: '9%'
    },
    {
        title: '',
        slots: {customRender: 'action'},
    },
];