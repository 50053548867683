<template lang="pug">
.operation-create-form
    input-with-error(:error="form.title.error")
        template(#input)
            a-input(placeholder="Наименование" v-model:value="form.title.value")
    input-with-error(:error="form.type.error")
        template(#input)
            a-select(
                v-model:value="form.type.value"
                style="width: 100%"
                placeholder="Тип"
                allowClear
            )
                a-select-option(
                    v-for="type in operationType"
                    :key="type"
                    :value="type"
                    :label="type"
                )
                    | {{ operationTypeMap[type] }}
    input-with-error(:error="form.action.error")
        template(#input)
            a-select(
                v-model:value="form.action.value"
                style="width: 100%"
                placeholder="Действие"
                allowClear
            )
                a-select-option(
                    v-for="action in operationAction"
                    :key="action"
                    :value="action"
                    :label="action"
                )
                    | {{ operationActionMap[action] }}
    a-button.mt-5.mb-2(
        type="primary"
        block
        @click="createWrapper"
        :loading="loading"
        :disabled="disabled"
    ) Создать
</template>

<script>
import {computed, reactive, ref} from 'vue';
import {useFormErrors} from '@/composition/errors';
import {notification} from 'ant-design-vue';
import {selectFilterFunc} from '@/modules/utils';
import {useCrud} from '@/composition/crud';
import {
    operationAction,
    operationActionMap,
    operationType,
    operationTypeMap
} from '@/components/Directories/Bills/Operation/constants';
export default {
    name: 'CreateOperationForm',
    props: {
        workspaceId: Number,
    },
    emits: ['ok'],
    setup(props, {emit}) {
        const loading = ref(false);

        const form = reactive({
            title: {
                value: '',
                error: ''
            },
            type: {
                value: undefined,
                error: ''
            },
            action: {
                value: undefined,
                error: ''
            },
        });
        const clients = [];
        const {createEntity} = useCrud('bill-operations', loading);
        const clearErrors = () => {
            form.title.error = undefined;
            form.type.error = undefined;
            form.action.error = undefined;
        };
        const {init} = useFormErrors();

        const createWrapper = async () => {
            const {ok} = await init(form, async () => {
                return createEntity({
                    title: form.title.value,
                    type: form.type.value,
                    action: form.action.value,
                }, props.workspaceId);
            }, clearErrors);
            if (ok) {
                notification.success({
                    description: 'Операция успешно создана',
                    message: 'Успех!'
                });
                emit('ok');
            }
        };

        const disabled = computed(() => {
            return !form.title.value || !form.type.value || !form.action.value;
        });
        return {
            createWrapper,
            disabled,
            form,
            loading,
            selectFilterFunc,
            clients,
            operationType,
            operationTypeMap,
            operationAction,
            operationActionMap,
        };
    }
};
</script>

<style lang="scss" scoped>
.operation-create-form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
}
</style>