<template lang="pug">
teleport(to="#overlay" v-if="showOverlay")
    .editable-cell__overlay(@click="save")
.editable-cell(@click="preventEvents" :class="{'editable-cell__active': editMode}" @keyup.enter="save")
    .editable-cell-text(
        @click="setEditing(true)"
        v-if="!editMode"
        :style="{whiteSpace: type === 'textarea' ? 'pre-wrap' : '', ...style}"
        :class="[className ? className : '']"
    ) {{ renderTitle() }}
    .flex.items-center.editable-cell__component(v-if="editMode && customComponent")
        component(:is="customComponent.component" v-bind="customComponent.props" @update:modelValue="(e) => update(e)")
    .flex.items-center.editable-cell__component(v-if="editMode && !customComponent")
        render-target-component(
            :value="val"
            ref="editInput"
            @input="(e) => update(e)"
            :type="type"
            :keyId="keyId"
            :valueKey="valueKey"
            :options="options"
            :width="width"
        )
</template>

<script>
import {nextTick, ref} from 'vue';
import renderTargetComponent from '@/components/Generic/EditableCell/renderTargetComponent';
import ContactsSelect from '@/components/Contacts/Select/Contacts';
import ServicesSelect from '@/components/Services/Select';
import WorkspaceMembersSelect from '@/components/Workspace/Member/Select';
import CurrencySelect from '@/components/Generic/CurrencySelect';
export default {
    name: 'EditableCell',
    components: {
        CurrencySelect,
        ContactsSelect,
        ServicesSelect,
        WorkspaceMembersSelect,
        renderTargetComponent,
    },
    props: {
        initialValue: undefined,
        keyId: {
            type: String,
            default: 'id'
        },
        valueKey: {
            type: String,
            default: 'title'
        },
        options: Array,
        type: {
            type: String,
            validator: x => ['text', 'number', 'select', 'date', 'checkbox', 'select_multiple', 'textarea'].indexOf(x) !== -1,
            default: 'text'
        },
        width: {
            type: String,
            default: '200px'
        },
        title: [String, Number],
        loading: Boolean,
        emptyText: String,
        className: String,
        customComponent: Object,
        style: {
            type: Object,
            default: () => ({})
        }
    },
    emits: ['update'],
    setup(props, {emit}) {
        const val = ref(props.initialValue);
        const showOverlay = ref(false);
        const editMode = ref(false);
        const editInput = ref(null);
        const preventEvents = (e) => {
            e.stopPropagation();
            e.preventDefault();
        };
        const setEditing = (bool) => {
            editMode.value = bool;
            showOverlay.value = bool;
            nextTick(() => {
                if (editInput.value && editInput.value.$el && editInput.value.$el.focus) {
                    try {
                        editInput.value.$el.focus();
                        editInput.value.$el.lastChild.firstChild.focus();
                        // eslint-disable-next-line no-empty
                    } catch {}
                }
            });
        };
        const update = (e) => {
            val.value = e;
        };
        const save = () => {
            emit('update', val.value);
            setEditing(false);
        };
        const renderTitle = () => {
            return props.title ? props.title : props.initialValue !== undefined && props.initialValue !== null && props.initialValue !== '' ? props.initialValue : props.emptyText;
        };
        return {
            setEditing,
            editMode,
            editInput,
            update,
            val,
            save,
            renderTitle,
            preventEvents,
            showOverlay,
        };
    }
};
</script>

<style lang="scss" scoped>
.editable-cell {
    position: relative;
    display: inline-flex;
    &-text {
        min-height: 32px;
        min-width: 40px;
        display: inline-flex;
        align-items: center;
        border-bottom: 1px dotted #000;
        cursor: url("~@/assets/img/edit.png") 4 12, auto;
    }
    &__overlay {
        background: rgba(0, 0, 0, 0.2);
        position: fixed;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        z-index: 1001;
    }
    &__active {
        z-index: 1002;
        width: 80%;
    }
    &__component {
        width: 80%;
    }
}
</style>