export const columns = [
    {
        title: 'Тип документа',
        dataIndex: 'documentTypeId',
        key: 'documentTypeId',
        slots: {customRender: 'type'},
        width: '10%'

    },
    {
        title: 'Номер и дата',
        dataIndex: 'number',
        key: 'number',
        width: '10%'
    },
    {
        title: 'Услуга',
        dataIndex: 'serviceId',
        key: 'serviceId',
        slots: {customRender: 'service'},
        width: '12%'
    },
    {
        title: 'Заказчик',
        dataIndex: 'customerId',
        key: 'customerId',
        slots: {customRender: 'customer'},
        width: '12%'
    },
    {
        title: 'Стоимость',
        dataIndex: 'sum',
        key: 'sum',
        slots: {customRender: 'sum'},
    },
    {
        title: 'Способ обмена',
        dataIndex: 'transportId',
        key: 'transportId',
        slots: {customRender: 'transport'},
        width: '10%'
    },
    {
        title: 'Статус',
        dataIndex: 'statusId',
        key: 'statusId',
        slots: {customRender: 'status'},
        width: '15%'
    },
    {
        title: 'Срок исполнения',
        dataIndex: 'deadline',
        key: 'deadline',
        slots: {customRender: 'deadline'},
        width: '15%'
    },
    {
        title: 'Ответственный',
        dataIndex: 'directorId',
        key: 'directorId',
        slots: {customRender: 'director'},
        width: '15%'
    },
    {
        title: '',
        slots: {customRender: 'action'},
    },
];

export const DocumentType = {
    completion_certificate: 'completion_certificate',
    contract: 'contract',
    reconciliation_act: 'reconciliation_act',
    supplementary_agreement: 'supplementary_agreement',
};

export const DocumentSourceType = {
    create_new: 'create_new',
    upload_new: 'upload_new',
    url: 'url',
};
export const DocumentSourceTypeMap = {
    create_new: 'Сформировать документ',
    upload_new: 'Загрузить готовый',
    url: 'Указать ссылку'
};
