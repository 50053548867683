import {httpInstance} from '@/modules/http_factory';

const authorize = async (workspaceId, data) => {
    const response = await httpInstance(workspaceId).post('/integrations', data);
    return response.data;
};

const syncUser = async(workspaceId, integrationId, workspaceMemberId, mappingId) => {
    const response = await httpInstance(workspaceId).post('/integrations/members', {
        workspaceId: Number(workspaceId),
        integrationId: Number(integrationId),
        workspaceMemberId: Number(workspaceMemberId),
        mappingId: String(mappingId)
    });
    return response.data;
};

const syncProject = async(workspaceId, integrationId, integrationProjectId, projectId, name) => {
    const response = await httpInstance(workspaceId).post('/integrations/projects', {
        workspaceId: Number(workspaceId),
        integrationId: Number(integrationId),
        mappingId: String(integrationProjectId),
        projectId: Number(projectId),
        name: name || String(integrationProjectId)
    });
    return response.data;
};
const syncStatus = async({workspaceId, title, integrationId, projectId, statusId}) => {
    if (statusId === -1) {
        const response = await httpInstance(workspaceId).delete('/integrations/statuses', {
            data: {
                workspaceId: Number(workspaceId),
                integrationId: Number(integrationId),
                statusId: Number(statusId),
                projectId: Number(projectId),
                title: title
            }
        });
        return response.data;
    } else {
        const response = await httpInstance(workspaceId).post('/integrations/statuses', {
            workspaceId: Number(workspaceId),
            integrationId: Number(integrationId),
            statusId: Number(statusId),
            projectId: Number(projectId),
            title: title
        });
        return response.data;
    }
};
const startSync = async(workspaceId, integrationId) => {
    const response = await httpInstance(workspaceId).post('/integrations/sync', {
        integrationId
    });
    return response.data;
};

const getUsers = async (workspaceId, integrationId, q) => {
    const response = await httpInstance(workspaceId).get(`/integrations/${integrationId}/users`, {
        params: {
            search: q
        }
    });
    return response.data;
};
const getSteps = async (workspaceId, integrationId) => {
    const response = await httpInstance(workspaceId).get(`/integrations/${integrationId}/steps`);
    return response.data;
};
const getProjects = async (workspaceId, integrationId, q, page) => {
    const response = await httpInstance(workspaceId).get(`/integrations/${integrationId}/projects`, {
        params: {
            search: q,
            page,
        }
    });
    return response.data;
};
const getStatuses = async (workspaceId, integrationId, integrationProjectId, projectId) => {
    const response = await httpInstance(workspaceId).get(`/integrations/${integrationId}/statuses`, {
        params: {
            integrationProjectId,
            projectId,
            workspaceId,
        }
    });
    return response.data;
};
const getIntegrationWorkspaceMembers = async(workspaceId, integrationId) => {
    const response = await httpInstance(workspaceId).get(`/integrations/${integrationId}/workspace/${workspaceId}/members/`);
    return response.data;
};
const getList = async (workspaceId) => {
    const response = await httpInstance(workspaceId).get(`/integrations/workspace/${workspaceId}`);
    return response.data;
};
const getIntegration = async (workspaceId, integrationId) => {
    const response = await httpInstance(workspaceId).get(`/integrations/${integrationId}`);
    return response.data;
};
export default {
    authorize,
    getUsers,
    getList,
    getIntegration,
    syncUser,
    syncProject,
    getIntegrationWorkspaceMembers,
    getStatuses,
    getProjects,
    getSteps,
    syncStatus,
    startSync
};