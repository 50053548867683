<template lang="pug">
async-page
    saved-report-card
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import SavedReportCard from '@/components/Analytics/SavedReports/Card';
export default {
    name: 'SavedReportCardPage',
    components: {SavedReportCard, AsyncPage}
};
</script>

<style scoped>

</style>