import {CalendarSlotFormInterface} from '@/modules/calendar/form/calendar-slot-form.interface';
import {CreateCalendarSlotDto} from '@/modules/calendar/dto/create-calendar-slot.dto';
import {UpdateCalendarSlotDto} from '@/modules/calendar/dto/update-calendar-slot.dto';
import {GetCalendarSlotsDto} from '@/modules/calendar/dto/get-calendar-slots.dto';
import {CalendarFilterInterface} from '@/modules/calendar/filters/calendar-filter.interface';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {arrayToStringTs, isUndefined, omitByTs} from '@/modules/utils/v2';
export class CalendarSlotMapper {
    static toCreateDto(form: CalendarSlotFormInterface): CreateCalendarSlotDto {
        return {
            title: form.title,
            contactId: form.contact && form.contact.id,
            serviceId: form.service && form.service.id,
            billId: form.bill && form.bill.id,
            documentId: form.document && form.document.id,
            description: form.description,
            type: form.type,
            date: form.date,
            isFinished: form.isFinished,
        };
    }
    static toUpdateDto(form: CalendarSlotFormInterface): UpdateCalendarSlotDto {
        const updateDto: UpdateCalendarSlotDto = omitByTs<UpdateCalendarSlotDto>({
            title: form.title || undefined,
            contactId: form?.contact?.id ,
            serviceId: form?.service?.id,
            billId: form?.bill?.id,
            documentId: form?.document?.id,
            description: form.description || undefined,
            type: form.type,
            date: form.date,
            isFinished: form.isFinished,
        }, (value: any) => !isUndefined(value));
        return updateDto;
    }
    static formFilter(calendarFilter: CalendarFilterInterface): GetCalendarSlotsDto {
        return {
            date: calendarFilter.date,
            mode: calendarFilter.mode,
            type: calendarFilter.types.length ? arrayToStringTs<{id: string, title: string}>(calendarFilter.types, true) : undefined,
        };
    }
}