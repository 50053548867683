<template lang="pug">
card-title Исполнители
.service-performers-list
    div(v-for="performer in performers.value" :key="performer.id")
        .flex.align-items.justify-between
            .cursor-pointer(@click="edit(performer)") {{ performer.fullName }}
            .cursor-pointer(@click="deletePerformer(performer.id)")
                icon(type="Delete")
    div
        a-button.mt-2(@click="setShowAddPerformerVisibility(true)" type="primary") {{ performers.value.length ? 'Еще' : 'Добавить' }}
    a-modal(
        v-model:visible="showAddPerformerModal"
        title="Добавить исполнителя"
        :footer="null"
        @cancel="setShowAddPerformerVisibility(false)"
        width="30%"
        :style="{top: '20px'}"
        :destroy-on-close="true"
    )
        service-performer(
            @add="onPerformerAdded"
            :mode="currentMode"
            :default-value="editingElement"
            :proceeds="proceeds"
            :profit="profit"
        )
</template>

<script>
import ServicePerformer from '@/components/Services/Performers/List/Item';
import {useCreateOrUpdateModal} from '@/composition/create-update-modal';

export default {
    name: 'ServicePerformersList',
    components: {ServicePerformer},
    props: {
        performers: Object,
        proceeds: {
            type: Number,
            default: 0
        },
        profit: {
            type: Number,
            default: 0,
        }
    },
    emits: ['update:performers'],
    setup(props, {emit}) {
        const {
            currentMode,
            editingValue: editingElement,
            showModal: showAddPerformerModal,
            setModalVisibility: setShowAddPerformerVisibility,
            edit
        } = useCreateOrUpdateModal();
        const onPerformerAdded = (performerData) => {
            if (currentMode.value === 'edit') {
                emit('update:performers', {
                    error: undefined,
                    value: props.performers.value.map(x => {
                        if (x.id == performerData.id) {
                            return performerData;
                        }
                        return x;
                    })
                });
            } else {
                emit('update:performers', {
                    error: undefined,
                    value: [...props.performers.value, performerData]
                });
            }

            setShowAddPerformerVisibility(false);
        };
        const deletePerformer = (id) => {
            const payload =  props.performers.value.filter(x => x.id != id);
            emit('update:performers', {
                error: undefined,
                value: payload
            });
        };
        return {
            currentMode,
            editingElement,
            showAddPerformerModal,
            onPerformerAdded,
            setShowAddPerformerVisibility,
            deletePerformer,
            edit
        };
    }
};
</script>

<style scoped>

</style>
