import {apiRequest} from '@/modules/error_handler';
import workspace from '@/modules/workspace';
import {computed, ref} from 'vue';
import {memberStatus, memberStatusMap} from '@/components/Workspace/Member/constants';
import {toLabelValue} from '@/modules/utils';

const currentWorkspaceId = ref(localStorage.getItem('workspaceId'));
const currentWorkspaceTz = ref(undefined);
const currentWorkspace = ref({});
const members = ref({
    list: [],
    next: '',
    prevPage: '',
    total: '',
    totalPages: '',
});
const pagination = ref({
    limit: 50,
    offset: 0,
});
export function useWorkspace(loading={value: false}) {

    const filter = ref({});
    const workspaceMember = ref({});
    const tabs = ref([]);
    const createWorkspace = async (title, description) => {
        return apiRequest(async() => {
            await workspace.createWorkspace(title, description);
        }, loading);
    };
    const updateWorkspace = async (id, updateData) => {
        return apiRequest(async() => {
            await workspace.updateWorkspace(id, updateData);
        }, loading);
    };
    const setCurrentWorkspaceId = (id, tz) => {
        currentWorkspaceId.value = id;
        currentWorkspaceTz.value = tz;
        localStorage.setItem('workspaceId', currentWorkspaceId.value);
    };
    const validateWorkspace = async (id) => {
        return apiRequest(async() => {
            const result = await workspace.validateWorkspace(id);
            return {ok: result.ok};
        }, loading);
    };
    const getWorkspaceById = async(id) => {
        return apiRequest(async() => {
            currentWorkspace.value = await workspace.getWorkspaceById(id);
        }, loading);
    };
    const ping = async() => {
        return apiRequest(async() => {
            return workspace.ping(currentWorkspaceId.value);
        }, loading);
    };
    const getWorkspaceMemberById = async(workspaceId, workspaceMemberId) => {
        return apiRequest(async() => {
            workspaceMember.value = await workspace.getWorkspaceMemberById(workspaceId, workspaceMemberId);
            return workspaceMember.value;
        });
    };
    const inviteUser = async(payload) => {
        return apiRequest(async() => {
            return workspace.inviteUser(payload);
        }, loading);
    };
    const updateWorkspaceMember = async(id, updateData) => {
        return apiRequest(async() => {
            return workspace.updateWorkspaceMember(currentWorkspaceId.value, id, updateData);
        });
    };
    const deleteWorkspaceMember = async(id) => {
        return apiRequest(async() => {
            return workspace.deleteWorkspaceMember(currentWorkspaceId.value, id);
        });
    };
    const getMembers = async (workspaceId, meta={}) => {
        return apiRequest(async() => {
            const result = await workspace.getWorkspaceMembers(workspaceId, {
                limit: pagination.value.limit,
                offset: pagination.value.offset,
            }, {
                ...filter.value,
                includeTasks: meta.includeTasks || undefined
            });
            if (meta.fromPagination) {
                members.value.list = members.value.list.concat(result.list);
                members.value.next = result.next;
            } else {
                members.value = result;
            }
            return members.value;
        }, loading);
    };
    const getWorkspaceMembersTabs = async (workspaceId) => {
        return apiRequest(async() => {
            tabs.value = await workspace.getWorkspaceMembersTabs(workspaceId, filter.value);
            return tabs.value;
        }, loading);
    };
    const setFilter = (filterData) => {
        filter.value = filterData;
    };
    const getMemberStatus = (member) => {
        if (!member.inviteAccepted && member.accessAllowed) {
            return memberStatus.invited;
        }
        if (member.accessAllowed) {
            return memberStatus.active;
        }
        return memberStatus.blocked;
    };
    const getMemberStatusText = (member) => {
        return memberStatusMap[getMemberStatus(member)];
    };
    return {
        tabs,
        members,
        pagination,
        workspaceMember,
        labeledMembersList: computed(() => {
            return toLabelValue(members.value.list, 'id', 'fullName');
        }),
        currentWorkspace: computed(() => currentWorkspace.value),
        currentWorkspaceTz: computed(() => currentWorkspaceTz.value),
        currentWorkspaceId: computed(() => currentWorkspaceId.value),
        ping,
        setFilter,
        inviteUser,
        getMembers,
        createWorkspace,
        updateWorkspace,
        getMemberStatus,
        getWorkspaceById,
        validateWorkspace,
        getMemberStatusText,
        updateWorkspaceMember,
        deleteWorkspaceMember,
        setCurrentWorkspaceId,
        getWorkspaceMemberById,
        getWorkspaceMembersTabs,
    };
}
