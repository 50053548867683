import axios from 'axios';
import {VueCookieNext} from 'vue-cookie-next';

export const httpInstance = (workspaceId, token = VueCookieNext.getCookie('token')) => {
    const axiosInstance = axios.create({
        headers: {
            Authorization: `Bearer ${token}`,
            'X-Workspace-Id': workspaceId || localStorage.getItem('workspaceId'),
        },
        baseURL: process.env.VUE_APP_API_URL,
    });
    axiosInstance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;
            const preventUrls = ['/auth/login', '/auth/signup'];
            const urlCondition = preventUrls.findIndex(x => x === originalConfig.url) === -1;
            if (urlCondition && err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;

                    try {
                        const rs = await axiosInstance.post('/auth/refresh', {
                            refreshToken: VueCookieNext.getCookie('refreshToken'),
                        });

                        const {ok, accessToken, refreshToken} = rs.data;
                        if (ok) {
                            VueCookieNext.setCookie('refreshToken', refreshToken, {
                                expire: '80d'
                            });
                            VueCookieNext.setCookie('token', accessToken);
                        } else {
                            VueCookieNext.removeCookie('refreshToken');
                            VueCookieNext.removeCookie('token');
                            window.location = `${window.location.origin}/login`;
                        }
                        originalConfig.headers['Authorization'] = `Bearer ${accessToken}`;
                        return axiosInstance(originalConfig);
                    } catch (_error) {
                        return Promise.reject(_error);
                    }
                }
            }
            return Promise.reject(err);
        },
    );
    return axiosInstance;
};
