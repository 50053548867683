<template lang="pug">
.incomings-and-outgoings-sub-categories-list
    .incomings-and-outgoings-sub-categories-list__title {{ caption }}
    .incomings-and-outgoings-sub-categories-list__items.ml-2(v-for="subCategory in subCategories")
        .incomings-and-outgoings-sub-categories-list__item(:title="subCategory.title") {{ subCategory.title }}
</template>

<script>
export default {
    name: 'IncomingsAndOutgoingsSubCategoriesList',
    props: {
        subCategories: {
            type: Array,
        },
        caption: {
            type: String,
        }
    },
    setup(props) {
        return {

        };
    }
};
</script>

<style lang="scss" scoped>
.incomings-and-outgoings-sub-categories-list {
    &__title {
        font-weight: 600;
    }
    &__item {
        margin-top: 8px;
        white-space: nowrap;
        width: 190px;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }
}
</style>
