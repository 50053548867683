<template lang="pug">
profile-layout
    .profile-form
        input-with-error.mt-3(:error="form.oldPassword.error")
            template(#label)
                .input__label Текущий пароль
            template(#input)
                a-input(placeholder="Текущий пароль" v-model:value="form.oldPassword.value" type="password")
        input-with-error.mt-3(:error="form.password.error")
            template(#label)
                .input__label Новый пароль
            template(#input)
                a-input(placeholder="Новый пароль" v-model:value="form.password.value" type="password")
        input-with-error.mt-3(:error="form.checkPassword.error")
            template(#label)
                .input__label Подтвердите пароль
            template(#input)
                a-input(placeholder="Подтвердите пароль" v-model:value="form.checkPassword.value" type="password")
        a-button.mt-5.mb-2(
            type="primary"
            @click="changePasswordWrapper"
            :loading="loading"
            :disabled="disabled"
        ) Сохранить
</template>

<script>
import ProfileLayout from '@/pages/Profile/Layout';
import {useAuth} from '@/composition/auth';
import {computed, reactive, ref} from 'vue';
import {useFormErrors} from '@/composition/errors';
import {notification} from 'ant-design-vue';
import {useWorkspace} from '@/composition/workspace';

export default {
    name: 'Profile',
    components: {ProfileLayout},
    setup() {
        const loading = ref(false);
        const {user, changePassword, me} = useAuth(loading);
        const {currentWorkspaceId} = useWorkspace(loading);
        const form = reactive({
            oldPassword: {
                value:  '',
                error: ''
            },
            password: {
                value: '',
                error: ''
            },
            checkPassword: {
                value: '',
                error: ''
            }
        });
        const clearErrors = () => {
            form.oldPassword.error = '';
            form.password.error = '';
            form.checkPassword.error = '';
        };
        const {init} = useFormErrors();

        const changePasswordWrapper = async () => {
            const {ok} = await init(form, async () => {
                return changePassword(
                    form.oldPassword.value,
                    form.password.value,
                    form.checkPassword.value
                );
            }, clearErrors);
            if (ok) {
                await me(currentWorkspaceId.value);
                notification.success({
                    message: 'Успех!',
                    description: 'Успешная смена пароля'
                });
            }
        };
        const disabled = computed(() => {
            return !form.oldPassword.value || !form.checkPassword.value || !form.password.value;
        });
        return {
            user,
            form,
            disabled,
            loading,
            changePasswordWrapper,
        };
    }
};
</script>

<style lang="scss" scoped>
.profile {
    &-form {
        max-width: 400px;
    }
}
</style>