export const errMap = {
    required: 'Это поле обязательно',
    invalidType: 'Это поле имеет некорректный тип',
};

export class ValidationError extends Error {
    constructor(property, message) {
        super();
        this.property = property;
        this.message = errMap[message] || message;
    }
}