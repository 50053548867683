<template lang="pug">
async-page
    dashboard-card
</template>

<script>
import PageTitle from '@/components/Generic/PageTitle';
import DashboardCard from '@/components/Dashboard/Card';
import AsyncPage from '@/layouts/async-page';

export default {
    name: 'Dashboard',
    components: {AsyncPage, DashboardCard, PageTitle},

};
</script>

<style scoped>

</style>