<template lang="pug">
async-page
    kanban
</template>

<script>
import Kanban from '@/components/Tasks/Kanban';
import AsyncPage from '@/layouts/async-page';
export default {
    name: 'Tasks',
    components: {AsyncPage, Kanban}
};
</script>

<style scoped>

</style>