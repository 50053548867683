<template lang="pug">
.member-payment-dates
    payment-dates-form.mt-2(v-model:paymentDates="paymentDates")
</template>

<script>
import PaymentDatesForm from '@/components/Workspace/InviteUserForm/PaymentDatesForm';
import CardTitle from '@/components/Generic/Typography/CardTitle';
import {ref, watch} from 'vue';
import constants from '@/components/Workspace/InviteUserForm/constants';
export default {
    name: 'MemberPaymentDates',
    components: {CardTitle, PaymentDatesForm},
    props: {
        member: Object
    },
    emits: ['update'],
    setup(props, {emit}) {
        const paymentDatesProp = ref(props.member.paymentDates);
        const paymentDates = ref({
            value: paymentDatesProp.value ? paymentDatesProp.value : {
                count: undefined,
                dayNumberOne: undefined,
                dayNumberTwo: undefined
            }
        });
        watch(paymentDates, () => {
            if (paymentDates.value.value.count === constants.month.once && paymentDates.value.value.dayNumberOne) {
                emit('update', {paymentDates: paymentDates.value.value});
            }
            if (paymentDates.value.value.count === constants.month.twice && paymentDates.value.value.dayNumberOne && paymentDates.value.value.dayNumberTwo) {
                emit('update', {paymentDates: paymentDates.value.value});
            }
        });
        return {
            paymentDates
        };
    }
};
</script>

<style lang="scss" scoped>
.member-payment-dates {
    max-width: 500px;
}
</style>