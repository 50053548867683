<template lang="pug">
#overlay
component(:is="layout" v-if="isRouteInit")
    router-view
icon.flex.items-center.justify-center.h-screen(type="Loader" v-else)
a-modal(
    :visible="showUncommittedLogModal"
    :title="uncommittedLog?.task?.title"
    :footer="null"
    :destroyOnClose="true"
    :closable="false"
    @cancel="null"
)
    create-time-log-form(
        :workspaceId="currentWorkspaceId"
        @update="onTimeLogUpdate"
        @continue="onTimeLogContinue"
        @delete="onTimeLogDelete"
        :mode="'edit'"
        :default-value="uncommittedLog"
        :is-finish="true"
    )
</template>

<script>

import {useRoute, useRouter} from 'vue-router';
import {onErrorCaptured, watch, computed, ref} from 'vue';
import {useWorkspace} from '@/composition/workspace';
import {useAuth} from '@/composition/auth';
import {useClock} from '@/composition/clock';
import CreateTimeLogForm from '@/components/TimeLogs/CreateForm/index';
import {useTimelog} from '@/composition/timelog';
import {useKanban} from '@/composition/kanban';
import {notification} from 'ant-design-vue';
import MainLayout from '@/layouts/main-layout';
import {commit, getters} from '@/store';

export default {
    name: 'App',
    components: {CreateTimeLogForm, MainLayout},
    setup() {
        const route = useRoute();
        const router = useRouter();
        const {globalClock} = useClock();
        const {setCurrentWorkspaceId, ping, currentWorkspaceId} = useWorkspace();
        const {user, me} = useAuth();
        const assignedToWorkspace = ref(null);
        const isRouteInit = computed(() => {
            if (assignedToWorkspace.value) {
                return !!currentWorkspaceId.value;
            }
            return assignedToWorkspace.value !== null;
        });
        const {
            uncommittedLog,
            showUncommittedLogModal,
            setUncommittedLogModalVisibility,
            updateTimeLog,
            getDailyTotal,
            deleteTimeLog,
            nextLoggingTaskId,
            setNextLoggingTaskId,
        } = useTimelog();
        const {getTasks, startTask} = useKanban();
        /*=====================TIME LOGS AREA==========================*/
        const onTimeLogUpdate = async (updateData) => {
            const {id, ...payload} = updateData;
            const {ok} = await updateTimeLog(currentWorkspaceId.value, id, payload);
            if (ok) {
                setUncommittedLogModalVisibility(false);
                if (nextLoggingTaskId.value) {
                    await startTask(nextLoggingTaskId.value, currentWorkspaceId.value);
                }
                setNextLoggingTaskId(null);
                await getDailyTotal(currentWorkspaceId.value);
                await me(currentWorkspaceId.value);
                await getTasks(currentWorkspaceId.value);
                notification.success({
                    message: 'Запись времени сохранена',
                });
            }
        };
        const onTimeLogContinue = async (timeLog) => {
            const {ok, result} = await startTask(timeLog.taskId, currentWorkspaceId.value);
            await updateTimeLog(currentWorkspaceId.value, timeLog.id, {
                isCommitted: true,
                nextId: result.id,
            });
            if (ok) {
                setUncommittedLogModalVisibility(false);
                await getDailyTotal(currentWorkspaceId.value);
                await me(currentWorkspaceId.value);
                await getTasks(currentWorkspaceId.value);
                setNextLoggingTaskId(null);
            }
        };

        const onTimeLogDelete = async (timeLog) => {
            const {ok} = await deleteTimeLog(currentWorkspaceId.value, timeLog.id);
            if (ok) {
                setUncommittedLogModalVisibility(false);
                if (nextLoggingTaskId.value) {
                    await startTask(nextLoggingTaskId.value, currentWorkspaceId.value);
                }
                setNextLoggingTaskId(null);
                await getDailyTotal(currentWorkspaceId.value);
                await me(currentWorkspaceId.value);
                await getTasks(currentWorkspaceId.value);
                notification.success({
                    message: 'Запись времени удалена',
                });
            }
        };

        const layout = computed(() => route.meta?.layout || 'div');
        watch(() => route.path, () => {
            assignedToWorkspace.value = route.meta.assignedToWorkspace;
            if (assignedToWorkspace.value) {
                const workspaceId = route.params.id;
                if (workspaceId) {
                    setCurrentWorkspaceId(workspaceId, user.value.tz);
                } else {
                    router.push('/');
                }
            } else {
                localStorage.removeItem('workspaceId');
            }

        }, {deep: true});
        onErrorCaptured(e => {
            console.log('global err', e);
            console.log('stack', e.stack);
        });
        return {
            layout,
            globalClock,
            onTimeLogUpdate,
            onTimeLogContinue,
            onTimeLogDelete,
            currentWorkspaceId,
            uncommittedLog,
            showUncommittedLogModal,
            setUncommittedLogModalVisibility,
            nextLoggingTaskId,
            isRouteInit,
            assignedToWorkspace,
        };

    }
};
</script>
