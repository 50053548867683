import {apiRequest} from '@/modules/error_handler';
import billModule from '@/modules/bill';
import {ref} from 'vue';
import {base64ToArrayBuffer} from '@/modules/utils';

export function useBillV2(loading={value: false}) {
    const bill = ref({});
    const pagination = ref({
        limit: 50,
        offset: 0,
    });
    const bills = ref({
        list: [],
        next: '',
        prevPage: '',
        total: '',
        totalPages: '',
    });
    const summary = ref({
        paid: 0,
        total: 0
    });
    const tabs = ref([]);
    const filter = ref({
        serviceId: undefined,
    });
    const createBill = async (workspaceId, billData) => {
        return apiRequest(async() => {
            await billModule.createBill(workspaceId, billData);
        }, loading);
    };
    const getBillById = async(id, workspaceId) => {
        return apiRequest(async() => {
            bill.value = await billModule.getBillById(id, workspaceId);
            return bill.value;
        }, loading);
    };
    const getRawBill = async(id) => {
        return apiRequest(async() => {
            return billModule.getRawBill(id);
        }, loading);
    };
    const getSummary = async(workspaceId) => {
        return apiRequest(async() => {
            summary.value = await billModule.getSummary(workspaceId, filter.value);
            return summary.value;
        }, loading);
    };
    const getTabs = async(workspaceId) => {
        return apiRequest(async() => {
            tabs.value = await billModule.getTabs(workspaceId, filter.value);
            return tabs.value;
        }, loading);
    };
    const getDefaultNumber = async(workspaceId) => {
        return apiRequest(async() => {
            return billModule.getDefaultNumber(workspaceId);
        }, loading);
    };

    const updateBill = async(workspaceId, id, updateData) => {
        return apiRequest(async() => {
            return billModule.updateBill(workspaceId, id, updateData);
        });
    };
    const deleteBill = async(workspaceId, id) => {
        return apiRequest(async() => {
            return billModule.deleteBill(workspaceId, id);
        });
    };
    const getBills = async (workspaceId, meta={}) => {
        return apiRequest(async() => {
            const result = await billModule.getBills(workspaceId, {
                limit: pagination.value.limit,
                offset: pagination.value.offset,
            }, filter.value);
            if (meta.fromPagination) {
                bills.value.list = bills.value.list.concat(result.list);
                bills.value.next = result.next;
            } else {
                bills.value = result;
            }
            return bills.value;
        }, loading);
    };
    const setFilter = (filterData) => {
        filter.value = filterData;
    };
    const downloadBill = async (billId) => {
        const {ok, result} = await getRawBill(billId);
        if (ok) {
            const blob = new Blob([result], {type: 'application/pdf'});
            const objectUrl = URL.createObjectURL(blob);
            window.open(objectUrl);
        }
    };
    return {
        downloadBill,
        summary,
        pagination,
        bills,
        bill,
        tabs,
        filter,
        getTabs,
        getRawBill,
        createBill,
        getBillById,
        updateBill,
        deleteBill,
        getBills,
        setFilter,
        getSummary,
        getDefaultNumber,
    };
}