<template lang="pug">
.projects-table
    a-button(type="primary" @click="setModalVisibility(true)" v-if="!fromIntegration") Добавить проект
    custom-table(:columns="projectColumns" :data-source="projects.list" :onRowClick="redirectToCard")
        template(#title="{text, record}")
            .projects-table__title(v-if="record")
                string-inline-changer(
                    :str="record.title"
                    :func="async (title) => await onUpdate(record.id, 'title', title)"
                    placeholder="Наименование"
                )
        template(#slug="{text, record}")
            .projects-table__title(v-if="record")
                string-inline-changer(
                    :str="record.slug"
                    :func="async (slug) => await onUpdate(record.id, 'slug', slug)"
                    placeholder="Идентификатор"
                )
        template(#client="{text, record}")
            div(v-if="record")
                contacts-inline-changer(
                    :with-companies="true"
                    :contact="record.client"
                    :func="async (clientId) => await onUpdate(record.id, 'clientId', clientId)"
                )
        template(#action="{record}")
            a-dropdown(:trigger="['click']" @click.stop)
                .dots-wrapper
                    icon.cursor-pointer(type="Dots")
                template(#overlay)
                    a-menu
                        a-menu-item(key="0")
                            delete-with-confirm(:deleteFunc="() => onDelete(record.id)" btnText="Удалить")
                        a-menu-item(key="1")
                            div(@click="editProject(record)") Редактировать
    a-button.mt-3(@click="next" v-if="projects.next" :loading="loading") Показать еще
    a-modal(
        v-model:visible="showModal"
        title="Создать проект"
        :footer="null"
        @cancel="setModalVisibility(false)"
        :destroyOnClose="true"
    )
        create-project-form(
            :workspaceId="currentWorkspaceId"
            @ok="onCreate"
            @update="putUpdate"
            :mode="currentMode"
            :default-value="editingElement"
        )
</template>

<script>
import {projectColumns} from '../constants';
import {useProject} from '@/composition/project';
import {ref} from 'vue';
import {useWorkspace} from '@/composition/workspace';
import CreateProjectForm from '@/components/Projects/CreateProjectForm';
import EditableCell from '@/components/Generic/EditableCell';
import {useContact} from '@/composition/contact';
import CustomTable from '@/components/Generic/CustomTable';
import {useCreateOrUpdateModal} from '@/composition/create-update-modal';
import {useRouter} from 'vue-router';
import StringInlineChanger from '@/components/Generic/InlineChanger/StringInlineChanger';
import ContactsInlineChanger from '@/components/Contacts/InlineChanger';
export default {
    name: 'ProjectsTable',
    components: {ContactsInlineChanger, StringInlineChanger, CustomTable, EditableCell, CreateProjectForm},
    props: {
        fromIntegration: Boolean
    },
    async setup() {
        const {
            currentMode,
            editingValue: editingElement,
            showModal,
            setModalVisibility,
            edit
        } = useCreateOrUpdateModal();
        const loading = ref(false);
        const router = useRouter();
        const {getProjects, deleteProject, updateProject, projects, pagination, getClientsOptions, getClientText} = useProject(loading);
        const {clients, getClients} = useContact(loading);
        const {currentWorkspaceId} = useWorkspace(loading);
        await getProjects(currentWorkspaceId.value);
        await getClients(currentWorkspaceId.value, undefined, true);
        const onCreate = async () => {
            pagination.value.offset = 0;
            setModalVisibility(false);
            await getProjects(currentWorkspaceId.value);
        };
        const onDelete = async (id) => {
            pagination.value.offset = 0;
            await deleteProject(id, currentWorkspaceId.value);
            await getProjects(currentWorkspaceId.value);
        };
        const onUpdate = async (id, key, value) => {
            await updateProject(id, {[key]: value}, currentWorkspaceId.value);
            pagination.value.offset = 0;
            await getProjects(currentWorkspaceId.value);
        };
        const putUpdate = async (data) => {
            const {id, ...updateData} = data;
            await updateProject(id, updateData, currentWorkspaceId.value);
            pagination.value.offset = 0;
            await getProjects(currentWorkspaceId.value);
            setModalVisibility(false);
        };
        const onClientUpdate = async (id, data) => {
            if (!data) return;
            await putUpdate({
                id,
                clientId: data,
            });
        };
        const next = async () => {
            pagination.value.offset += pagination.value.limit;
            await getProjects(currentWorkspaceId.value, {fromPagination: true});
        };

        const editProject = (project) => {
            edit({
                ...project,
            });
        };
        const redirectToCard = (project) => {
            router.push(`/${currentWorkspaceId.value}/projects/${project.id}`);
        };
        return {
            redirectToCard,
            onClientUpdate,
            getClientsOptions,
            editProject,
            projects,
            projectColumns,
            loading,
            setModalVisibility,
            showModal,
            onCreate,
            currentWorkspaceId,
            next,
            onDelete,
            onUpdate,
            clients,
            getClientText,
            currentMode,
            editingElement,
            edit,
            putUpdate,
        };
    }
};
</script>

<style lang="scss" scoped>

</style>