<template lang="pug">
.time-logs-filter
    .time-logs-filter__row
        custom-select-v2(
            :modelValue="filter.wasEdited"
            :options="wasEditedOptions"
            @update:modelValue="onChange('wasEdited', $event)"
            placeholder="Тип лога"
            :style="{width: '100%'}"
        )
        custom-select-v2.ml-2(
            :modelValue="filter.workspaceMember"
            @update:modelValue="onChange('workspaceMember', $event)"
            v-if="!hide_workspace_members"
            placeholder="Исполнитель"
            :style="{width: '100%'}"
            :options="members.list"
            label="fullName"
            searchable
            @search="onMembersSearch"
        )
        custom-select-v2.ml-2(
            :modelValue="filter.task"
            @update:modelValue="onChange('task', $event)"
            placeholder="Задача"
            :style="{width: '100%'}"
            :options="tasksList.list"
            searchable
            @search="onTasksSearch"
            label="fullTitle"
        )
        custom-select-v2.ml-2(
            :modelValue="filter.service"
            @update:modelValue="onChange('service', $event)"
            placeholder="Услуга"
            :style="{width: '100%'}"
            :options="services.list"
            searchable
            label="fullTitle"
            @search="onServicesSearch"
        )
    .time-logs-filter__row
        simple-range-picker(
            :range="filter.range"
            @update:range="onChange('range', $event)"
        )
</template>

<script>
import WorkspaceMembersSelect from '@/components/Workspace/Member/Select';
import TasksSelect from '@/components/Tasks/Select';
import SelectSuspense from '@/components/Generic/SelectSuspense';
import ServicesSelect from '@/components/Services/Select';
import CustomSelect from '@/components/Generic/CustomSelect';
import {useAuth} from '@/composition/auth';
import {useRole} from '@/composition/role';
import {onMounted} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import CustomSelectV2 from '@/components/Generic/CustomSelectV2';
import {useWorkspace} from '@/composition/workspace';
import {debounce, isUndefined, omitBy} from '@/modules/utils';
import {useWorkspaceV2} from '@/composition/workspace-v2';
import {useKanban} from '@/composition/kanban';
import {useServiceV2} from '@/composition/service-v2';
import SimpleRangePicker from '@/components/Generic/SimpleRangePicker';
export default {
    name: 'TimeLogFilter',
    components: {
        SimpleRangePicker,
        CustomSelectV2, CustomSelect, ServicesSelect, SelectSuspense, TasksSelect, WorkspaceMembersSelect},
    props: {
        filter: Object,
    },
    emits: ['update:filter'],
    setup(props, {emit}) {
        const {user} = useAuth();
        const route = useRoute();
        const router = useRouter();
        const {getDefaults, sections} = useRole(user.value);
        const {hide_workspace_members} = getDefaults(sections.time_logs.name, sections.time_logs.create.name);
        const wasEditedOptions = [{id: 0, title: 'Авто'}, {id: 1, title: 'Ручное'}];
        const toQueryParams = (newFilter) => {
            const [from, to] = newFilter.range || [];
            router.push({
                query: omitBy({
                    from: from || undefined,
                    to: to || undefined,
                    serviceId: newFilter.service?.id,
                    workspaceMemberId: newFilter.workspaceMember?.id,
                    taskId: newFilter.task?.id,
                    wasEdited: newFilter.wasEdited?.id,
                    paymentStatus: newFilter?.paymentStatus?.id
                }, (val) => !isUndefined(val))
            });
        };
        const onChange = (key, value) => {
            const newFilter = {
                ...props.filter,
                [key]: value
            };
            toQueryParams(newFilter);
            emit('update:filter', newFilter);
        };
        const parseQuery = () => {
            const routeFilter = route.query;
            const filterData = {
                workspaceMember: routeFilter.workspaceMemberId ? {id: Number(routeFilter.workspaceMemberId)}: undefined,
                service: routeFilter.serviceId ? {id: Number(routeFilter.serviceId)}: undefined,
                task: routeFilter.taskId ? {id: Number(routeFilter.taskId)}: undefined,
                period: routeFilter.period ? routeFilter.period : undefined,
                range: routeFilter.from || routeFilter.to ? [routeFilter.from, routeFilter.to] : [],
                wasEdited: routeFilter.wasEdited ? wasEditedOptions.find(x => x.id === Number(routeFilter.wasEdited)) : undefined
            };
            if (Object.values(filterData).filter(Boolean).length) {
                emit('update:filter', filterData);
            }
        };
        onMounted(() => {
            parseQuery();
        });
        /*=====================MEMBERS AREA=======================*/
        const {currentWorkspaceId} = useWorkspace();
        const {members, getMembers, setFilter: setMembersFilter} = useWorkspaceV2();
        const onMembersSearch = (q) => {
            setMembersFilter({search: q});
            debounce(() => getMembers(currentWorkspaceId.value), 500)();
        };
        onMounted(async () => {
            await getMembers(currentWorkspaceId.value);
        });
        /*=====================TASKS AREA=======================*/
        const {getTasksList, tasksList} = useKanban();
        const onTasksSearch = (q) => {
            debounce(() => getTasksList(currentWorkspaceId.value, {search: q}), 500)();
        };
        onMounted(async () => {
            await getTasksList(currentWorkspaceId.value, {});
        });
        /*=====================SERVICES AREA=======================*/
        const {getServicesSimple, services, setFilter: setServicesFilter} = useServiceV2();
        const onServicesSearch = (q) => {
            setServicesFilter({search: q});
            debounce(() => getServicesSimple(currentWorkspaceId.value, {}), 800)();
        };
        onMounted(async () => {
            await getServicesSimple(currentWorkspaceId.value, {});
        });
        return {
            members,
            services,
            tasksList,
            wasEditedOptions,
            hide_workspace_members,
            onChange,
            onTasksSearch,
            onMembersSearch,
            onServicesSearch
        };
    }
};
</script>

<style lang="scss" scoped>
.time-logs-filter {
    &__row {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
    }
}
</style>
