<template lang="pug">
.transaction-filter
    .transaction-filter__row
        simple-range-picker.mr-3(
            v-model:range="filter.date"
            :clearable="false"
        )
        contacts-select(v-model="filter.contractorId" placeholder="Контрагент")
        a-select.mr-3(
            v-model:value="filter.bankAccountId"
            placeholder="Счет"
            show-search
            :filter-option="selectFilterFunc"
            style="width: 100%"
            allowClear
        )
            a-select-option(
                v-for="bankAccount in directories.bankAccounts.list"
                :key="bankAccount.id"
                :value="bankAccount.id"
                :label="bankAccount.title"
            ) {{ bankAccount.title }}
        a-select.mr-3(
            v-model:value="filter.operationId"
            placeholder="Операция"
            show-search
            :filter-option="selectFilterFunc"
            style="width: 100%"
            allowClear
        )
            a-select-option(
                v-for="billOperation in directories.billOperations.list"
                :key="billOperation.id"
                :value="billOperation.id"
                :label="billOperation.title"
            ) {{ billOperation.title }}
        a-select(
            v-model:value="filter.period"
            :options="periodsFormatted"
            allow-clear
            label-in-value
            style="width: 100%"
            placeholder="Выберите период"
            mode="multiple"
        )
    .transaction-filter__row.mt-3
        a-select(
            v-model:value="filter.isScheduled"
            allow-clear
            placeholder="План/Факт"
            style="width: 150px"
        )
            a-select-option(
                :value="false"
            ) Фактические
            a-select-option(
                :value="true"
            ) Плановые
</template>

<script>
import {computed, onMounted, reactive, ref, watch} from 'vue';
import {useDirectory} from '@/composition/directory';
import {arrayToString, isUndefined, omitBy, parseArrayFromString, selectFilterFunc} from '@/modules/utils';
import SelectSuspense from '@/components/Generic/SelectSuspense';
import ContactsSelect from '@/components/Contacts/Select/Contacts';
import {useRoute, useRouter} from 'vue-router';
import {useDate} from '@/composition/date';
import SimpleRangePicker from '@/components/Generic/SimpleRangePicker/index';
import moment from 'moment-timezone';

export default {
    name: 'TransactionFilter',
    components: {SimpleRangePicker, ContactsSelect, SelectSuspense},
    props: {
        billId: [Number, String],
        periods: {
            type: Array
        }
    },
    emits: ['filter'],
    setup(props, {emit}) {
        const loading = ref(false);
        const route = useRoute();
        const router = useRouter();
        const filter = reactive({
            contractorId: undefined,
            operationId: undefined,
            bankAccountId: undefined,
            date: [],
            billId: undefined,
            period: [],
            type: undefined,
            isScheduled: false
        });

        watch(filter, () => {
            setFilterToQuery(filter);
            emit('filter', filter);
        });
        const setFilterToQuery = filterData => {
            router.push({
                query: {
                    ...omitBy({
                        contractorId: filterData.contractorId,
                        operationId: filterData.operationId,
                        bankAccountId: filterData.bankAccountId,
                        dateFrom: filterData.date && filterData.date.length ? filterData.date[0] : undefined,
                        dateTo: filterData.date && filterData.date.length ? filterData.date[1] : undefined,
                        billId: filterData.billId,
                        period: filterData.period && filterData.period.length ? arrayToString(filterData.period, true, 'value') : undefined,
                        type: filterData.type,
                        isScheduled: filterData.isScheduled,
                    }, (val) => !isUndefined(val))
                }
            });
        };
        const {directories} = useDirectory(loading);
        const parseQuery = () => {
            const routeFilter = route.query;
            const filterData = {
                contractorId: routeFilter.contractorId ? Number(routeFilter.contractorId) : undefined,
                bankAccountId: routeFilter.bankAccountId ? Number(routeFilter.bankAccountId) : undefined,
                operationId: routeFilter.operationId ? Number(routeFilter.operationId) : undefined,
                period: routeFilter.period ? parseArrayFromString(routeFilter.period, true, 'value', String) : [],
                date: routeFilter.dateTo && routeFilter.dateFrom ? [routeFilter.dateFrom, routeFilter.dateTo] : [],
                type: routeFilter.type,
                isScheduled: routeFilter.isScheduled === 'true'
            };
            filter.type = filterData.type;
            filter.contractorId = filterData.contractorId;
            filter.period = filterData.period;
            filter.date = filterData.date && filterData.date.length ? filterData.date : filter.date;
            filter.operationId = filterData.operationId;
            filter.bankAccountId = filterData.bankAccountId;
            filter.isScheduled = filterData.isScheduled;
        };
        const periodsFormatted = computed(() => {
            return props.periods.map(x => ({value: x.id, label: x.title}));
        });
        onMounted(async () => {
            filter.billId = props.billId;
            parseQuery();
        });
        return {
            periodsFormatted,
            filter,
            directories,
            selectFilterFunc,
        };
    }
};
</script>

<style lang="scss" scoped>
.transaction-filter {
    &__row {
        display: flex;
        align-items: center;
    }
}
</style>
