<template lang="pug">
.salary-payments-list-member-item
    .flex.items-center.justify-between(style="width: 95.5%")
        .flex.items-center
            .salary-payments-list-member-item__name {{ memberSalary.fullName }} {{ totalByCurrency }} ({{ memberSalary.totalHours || 0 }} ч.)
            .salary-payments-list-member-item__progress.ml-3(v-if="memberSalary.total && memberSalary.paid")
                status-progress(
                    :percent="memberSalary.paidPercent"
                    :tooltip-text="`Оплачено ${memberSalary.paid} из ${memberSalary.total} руб.`"
                )
        .salary-payments-list-member-item__delete.cursor-pointer(@click="onMemberDeleted(memberSalary.id)")
            icon(type="Delete")
    .salary-payments-list-member-item__salary-positions
        custom-table(
            :columns="salaryPositionColumns"
            :data-source="memberSalary.positions"
        )
            template(#service="{record}")
                router-link(
                    v-if="record.service"
                    :to="`/${currentWorkspaceId}/services/${record.service.id}/payment-periods`"
                    target="_blank"
                ) {{ renderTitle(record) }}
                div(v-else) {{ renderTitle(record) }}
            template(#servicePaymentType="{record}")
                div {{ renderType(record) }}
            template(#hoursAmount="{record}")
                router-link(
                    v-if="record.service"
                    :to="`/${currentWorkspaceId}/reports/hourly/new?performers=${memberSalary.workspaceMemberId}&from=${rangeFromPeriod.from}&to=${rangeFromPeriod.to}`"
                    target="_blank"
                ) {{ renderHoursAmount(record) }}
                div(v-else) {{ renderHoursAmount(record) }}
            template(#hourlyRate="{record}")
                div {{ renderHourlyRate(record) }}
            template(#total="{record}")
                editable-cell(
                    :title="`${formatCurrency(record.total)} ${currencyMap[record.currency]}`"
                    :initial-value="record.total"
                    @update="(e) => onPositionFieldUpdated(record.id, memberSalary.id, 'total', e)"
                )
                status-progress(
                    v-if="record.paid && record.paidPercent && record.totalInRub"
                    :percent="record.paidPercent"
                    :tooltip-text="`Оплачено ${record.paid} из ${record.totalInRub} руб.`"
                )
            template(#actions="{record}")
                div.cursor-pointer(@click="onPositionDeleted(record.id, memberSalary.id)")
                    icon(type="Delete")
    a-button.mt-2(@click="showAddPositionModal(true)") Еще
a-modal(
    title="Добавить выплаты"
    :visible="showAddModal"
    :destroyOnClose="true"
    @cancel="showAddPositionModal(false)"
    :footer="null"
)
    create-position-form(
        :workspaceMember="{id: memberSalary.id, fullName: memberSalary.fullName}"
        :workspaceId="currentWorkspaceId"
        @create="onPositionAdded"
    )
a-divider
</template>

<script>
export default {
    name: 'SalaryPaymentsListMemberItem'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
import {salaryPositionColumns} from '../constants';
import {formatCurrency} from '../../../modules/utils';
import constants from '@/constants';
import {useCreateOrUpdateModal} from '../../../composition/create-update-modal';
import CreatePositionForm from './CreatePositionForm.vue';
import {useWorkspace} from '../../../composition/workspace';
import EditableCell from '@/components/Generic/EditableCell';
import {computed} from 'vue';
import {useDate} from '@/composition/date';
import CardTitle from '@/components/Generic/Typography/CardTitle';
import Big from 'big.js';
import StatusProgress from '@/components/Generic/StatusProgress';
/*===============INIT AREA===================*/
const props = defineProps({
    memberSalary: {
        type: Object,
    },
    from: {
        type: String
    },
    to: {
        type: String
    }
});
const emit = defineEmits(['position-deleted', 'member-deleted', 'positions-added', 'position-field-updated']);
const {setModalVisibility: showAddPositionModal, showModal: showAddModal} = useCreateOrUpdateModal();
const {currentWorkspaceId} = useWorkspace();
const {dateToMs} = useDate();
/*===============EVENTS HANDERS AREA===================*/
const onPositionDeleted = (id, workspaceMemberId) => {
    emit('position-deleted', {id, workspaceMemberId});
};
const onMemberDeleted = (id) => {
    emit('member-deleted', id);
};
const onPositionAdded = (positionsData) => {
    showAddPositionModal(false);
    emit('positions-added', positionsData);
};
const onPositionFieldUpdated = (id, workspaceMemberId, key, value) => {
    emit('position-field-updated', {
        id,
        workspaceMemberId,
        updateData: {
            [key]: value
        }
    });
};
/*===============RENDERERS AREA===================*/
const renderTitle = (record) => {
    switch (record.type) {
        case 'base':
            return record.base;
        case 'service':
            return record?.service?.title || 'Без услуги';
    }
};
const renderType = (record) => {
    return record?.service?.paymentType || '-';
};
const renderHoursAmount = (record) => {
    return record?.service?.hoursAmount || '-';
};
const renderHourlyRate = (record) => {
    return record?.service?.hourlyRate || '-';
};
const currencyMap = constants.currencyMap;
const rangeFromPeriod = computed(() => {
    return {from: dateToMs(props.from), to: dateToMs(props.to)};
});
const totalByCurrency = computed(() => {
    const currMap = {};
    const positions = props.memberSalary.positions || [];
    for (const x of positions) {
        currMap[x.currency] = Big(x.total).plus(currMap[x.currency] || 0);
    }
    return Object.keys(currMap).map(curr => `${formatCurrency(currMap[curr].toFixed(2))} ${currencyMap[curr]}`).join(', ');
});
</script>

<style lang="scss">
</style>