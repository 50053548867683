import {RegularTransactionForm} from '@/modules/transaction/form/regular-transaction.form';
import {CreateRegularTransactionDto} from '@/modules/transaction/dto/create-regular-transaction.dto';
import {UpdateRegularTransactionDto} from '@/modules/transaction/dto/update-regular-transaction.dto';
import {isUndefined, omitByTs} from '@/modules/utils/v2';

export class RegularTransactionMapper {
    static toCreateDto(form: RegularTransactionForm): CreateRegularTransactionDto {
        return {
            title: form.title!,
            amount: form.amount!,
            currency: form.currency!,
            contractorId: form.contractor?.id,
            operationId: form.operation?.id,
            fromAccountId: form.fromAccount!.id!,
            dayNumber: form.dayNumber!,
        };
    }
    static toUpdateDto(form: RegularTransactionForm): UpdateRegularTransactionDto {
        return omitByTs<UpdateRegularTransactionDto>({
            title: form.title,
            amount: form.amount,
            currency: form.currency,
            contractorId: form.contractor?.id,
            operationId: form.operation?.id,
            fromAccountId: form.fromAccount?.id,
            dayNumber: form.dayNumber,
        }, (value) => !isUndefined(value));
    }
}