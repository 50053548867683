<template lang="pug">
settings-directories-documents-layout
    async-page
        documents-types-table
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import SettingsDirectoriesDocumentsLayout from '@/pages/Settings/Directories/Documents/Layout';
import DocumentsTypesTable from '@/components/Directories/Documents/Type/Table';

export default {
    name: 'DocumentTypesPage',
    components: {
        DocumentsTypesTable,
        SettingsDirectoriesDocumentsLayout,
        AsyncPage
    }
};
</script>

<style scoped>

</style>