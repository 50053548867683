import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "service-payment-period-tabs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_tabs, {
      activeKey: $props.filter.paymentPeriodStatus,
      onChange: $setup.onChange
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_tab_pane, {
          key: $setup.ServicePaymentPeriodStatus.oncoming,
          tab: `${$setup.ServicePaymentPeriodStatusMap.oncoming} (${$props.counts.oncoming})`
        }, null, 8, ["tab"]),
        _createVNode(_component_a_tab_pane, {
          key: $setup.ServicePaymentPeriodStatus.active,
          tab: `${$setup.ServicePaymentPeriodStatusMap.active} (${$props.counts.active})`
        }, null, 8, ["tab"]),
        _createVNode(_component_a_tab_pane, {
          key: $setup.ServicePaymentPeriodStatus.finished,
          tab: `${$setup.ServicePaymentPeriodStatusMap.finished} (${$props.counts.finished})`
        }, null, 8, ["tab"]),
        _createVNode(_component_a_tab_pane, {
          key: $setup.ServicePaymentPeriodStatus.archive,
          tab: `${$setup.ServicePaymentPeriodStatusMap.archive} (${$props.counts.archive})`
        }, null, 8, ["tab"])
      ]),
      _: 1
    }, 8, ["activeKey"])
  ]))
}