<template lang="pug">
.finance-balance-company
    .finance-balance-company__header
        .finance-balance-company__summary
            .finance-balance-company__title {{ title }}
            .finance-balance-company__total-balance {{ formatCurrency(totalBalance) }} руб.
        finance-balance-company-bank-accounts-list(:bankAccounts="bankAccounts")
    finance-balance-company-chart(:balanceHistory="balanceHistory" :loading="loading")
</template>

<script>
export default {
    name: 'FinanceBalanceCompany'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
import FinanceBalanceCompanyBankAccountsList from '@/components/FinanceBalance/Company/BankAccountsList';
import FinanceBalanceCompanyChart from '@/components/FinanceBalance/Company/Chart';
import {formatCurrency} from '@/modules/utils';
/*===============INIT AREA===================*/
const props = defineProps({
    title: {
        type: String,
        required: true
    },
    totalBalance: {
        type: Number,
        required: true
    },
    loading: {
        type: Boolean,
        required: true
    },
    bankAccounts: {
        type: Array,
        required: true,
    },
    balanceHistory: {
        type: Array,
        required: true,
    }
});
</script>

<style lang="scss">
.finance-balance-company {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding: 20px 24px 8px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    &__summary {
        display: grid;
        grid-template-columns: 300px 300px;
        font-weight: bold;
    }
}
</style>