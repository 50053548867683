import {ref} from 'vue';
import moment from 'moment';
import {apiRequest} from '@/modules/error_handler';
import documentModule from '@/modules/document';

export function useDocumentV2(loading={value: false}, isLocal=false) {
    const pagination = ref({
        limit: 50,
        offset: 0,
    });
    const documents = ref({
        list: [],
        next: '',
        prevPage: '',
        total: '',
        totalPages: '',
    });
    const document = ref({});
    const tabs = ref([]);
    const filter = ref({
        contactGroupId: ''
    });
    const localDocuments = ref({
        list: [],
        next: '',
        prevPage: '',
        total: '',
        totalPages: '',
    });
    const localFilter = ref({
        contactGroupId: ''
    });
    const localPagination = ref({
        limit: 50,
        offset: 0,
    });
    const getDefaultNumber = async (workspaceId) => {
        return apiRequest(async() => {
            return documentModule.getDefaultNumber(workspaceId);
        }, loading);
    };
    const createDocument = async (workspaceId, documentData) => {
        return apiRequest(async() => {
            return documentModule.createDocument(workspaceId, documentData);
        }, loading);
    };
    const getDocumentById = async(workspaceId, id) => {
        return apiRequest(async() => {
            document.value = await documentModule.getDocumentById(workspaceId, id);
            return document.value;
        }, loading);
    };

    const updateDocument = async(workspaceId, id, updateData) => {
        return apiRequest(async() => {
            return documentModule.updateDocument(workspaceId, id, updateData);
        });
    };
    const deleteDocument = async(workspaceId, id) => {
        return apiRequest(async() => {
            return documentModule.deleteDocument(workspaceId, id);
        });
    };
    const getTemplate = async(id) => {
        return apiRequest(async() => {
            return documentModule.getTemplate(id);
        });
    };
    const getDocuments = async (workspaceId, meta={}) => {
        return apiRequest(async() => {
            const result = await documentModule.getDocuments(workspaceId, {
                limit: !isLocal ? pagination.value.limit : localPagination.value.limit,
                offset: !isLocal ? pagination.value.offset : localPagination.value.offset,
            }, !isLocal ? filter.value : localFilter.value);
            if (meta.fromPagination) {
                if (isLocal) {
                    localDocuments.value.list = localDocuments.value.list.concat(result.list);
                    localDocuments.value.next = result.next;
                } else {
                    documents.value.list = documents.value.list.concat(result.list);
                    documents.value.next = result.next;
                }

            } else {
                if (isLocal) {
                    localDocuments.value = result;
                } else {
                    documents.value = result;
                }
            }
            return !isLocal ? documents.value : localDocuments;
        }, loading);
    };
    const getTabs = async (workspaceId) => {
        return apiRequest(async() => {
            tabs.value = await documentModule.getTabs(workspaceId, filter.value);
            return tabs.value;
        }, loading);
    };
    const setFilter = (filterData) => {
        filter.value = filterData;
    };
    const setLocalFilter = (filterData) => {
        localFilter.value = filterData;
    };
    const isDocumentOutDated = doc => {
        return moment() > moment(doc.deadline);
    };
    const downloadTemplate = async (id) => {
        const {ok, result} = await getTemplate(id);
        if (ok) {
            const blob = new Blob([result], {type: 'application/pdf'});
            const blobUrl = URL.createObjectURL(blob);
            const link = window.document.createElement('a');
            link.href = blobUrl;
            link.download = `Act_${Date.now()}.pdf`;
            window.document.body.appendChild(link);
            link.dispatchEvent(
                new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window
                })
            );
            window.document.body.removeChild(link);
        }
    };
    return {
        tabs,
        filter,
        document,
        documents,
        pagination,
        localFilter,
        localDocuments,
        getTabs,
        setFilter,
        getTemplate,
        getDocuments,
        createDocument,
        updateDocument,
        setLocalFilter,
        deleteDocument,
        getDocumentById,
        downloadTemplate,
        getDefaultNumber,
        isDocumentOutDated,
    };
}