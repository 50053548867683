<template lang="pug">
.create-time-log-form
    input-with-error(:error="form.workspaceMemberId.error" v-if="!isFinish && !hide_workspace_members")
        template(#input)
            custom-select-v2(
                v-model="form.workspaceMemberId.value"
                placeholder="Исполнитель"
                :style="{width: '100%'}"
                :options="members.list"
                label="fullName"
                searchable
                @search="onMembersSearch"
                :disabled="disableEditing"
            )
    input-with-error(:error="form.taskId.error" v-if="!isFinish")
        template(#input)
            custom-select-v2(
                v-model="form.taskId.value"
                placeholder="Задача"
                :style="{width: '100%'}"
                :options="tasksList.list"
                searchable
                @search="onTasksSearch"
                :disabled="disableEditing"
                label="fullTitle"
            )
    card-title(size="small" v-if="!isFinish") Дата и время начала
    input-with-error(:error="form.startDate.error" v-if="!isFinish")
        template(#input)
            .flex.items-center
                a-date-picker(
                    v-model:value="form.startDate.value"
                    format="DD.MM.YYYY"
                    placeholder="Дата начала"
                    :disabled="disableEditing"
                )
                time-selector.ml-2(v-model:time="form.startTime.value" :disabled="disableEditing")
    card-title(size="small") Длительность
    time-selector(v-model:time="form.duration.value" :disabled="disableEditing")
        template(#hoursSuffix)
            .ml-2 ч.
        template(#minutesSuffix)
            .ml-2 мин.
        template(#secondsSuffix)
            .ml-2 сек.
    input-with-error(:error="form.comment.error")
        template(#input)
            a-textarea(v-model:value="form.comment.value" placeholder="Описание" style="width: 100%" :rows="4")
    div(v-if="mode==='edit' && !isFinish && defaultValue")
        card-title Финансы
        div(v-if="defaultValue?.financeData") Стоимость для исполнителя: {{ defaultValue?.financeData?.amount }} {{ currencyMap[defaultValue?.financeData?.currency] }}
        div Оплачено: {{ defaultValue?.isPaid ? 'Да' : 'Нет' }}
        div(v-if="defaultValue?.financeData") Дата оплаты: {{ formatDate(defaultValue?.financeData?.createdAt) }}
    .flex.items-center.mt-3
        a-button(
            @click="createTransactionWrapper"
            type="primary"
            :loading="loading"
            :disabled="disabled"
        ) {{ mode === 'edit' ?  isFinish ? 'Завершить' : 'Сохранить' : 'Создать' }}
        a-button.ml-3(
            @click="continueLog"
            :loading="loading"
            v-if="isFinish"
            type="text"
        ) Продолжить
        a-button.ml-3(
            @click="deleteLog"
            :loading="loading"
            v-if="isFinish"
            type="text"
            danger
        ) Удалить
</template>

<script>
import {computed, reactive, ref, onMounted} from 'vue';
import {useFormErrors} from '@/composition/errors';
import {notification} from 'ant-design-vue';
import {debounce, selectFilterFunc} from '@/modules/utils';
import {useTimelog} from '@/composition/timelog';
import moment from 'moment';
import TasksSelect from '@/components/Tasks/Select';
import SelectSuspense from '@/components/Generic/SelectSuspense';
import WorkspaceMembersSelect from '@/components/Workspace/Member/Select';
import TimeSelector from '@/components/Generic/TimeSelector';
import CardTitle from '@/components/Generic/Typography/CardTitle';
import {useDate} from '@/composition/date';
import {useAuth} from '@/composition/auth';
import {useRole} from '@/composition/role';
import constants from '@/constants';
import CustomSelectV2 from '@/components/Generic/CustomSelectV2';
import {useWorkspace} from '@/composition/workspace';
import {useWorkspaceV2} from '@/composition/workspace-v2';
import {useKanban} from '@/composition/kanban';
export default {
    name: 'CreateTimeLogForm',
    components: {
        CustomSelectV2,
        CardTitle,
        TimeSelector,
        TasksSelect,
        SelectSuspense,
        WorkspaceMembersSelect,
    },
    props: {
        workspaceId: [String, Number],
        mode: String,
        defaultValue: Object,
        isFinish: Boolean,
    },
    emits: ['ok', 'update', 'delete', 'continue'],
    setup(props, {emit}) {
        const loading = ref(false);
        const {user} = useAuth();
        const {formatDate} = useDate();
        const {sections, getDefaults} = useRole(user.value);
        const {workspaceMember, hide_workspace_members} = getDefaults(sections.time_logs.name, sections.time_logs.create.name);
        const form = reactive({
            workspaceMemberId: {
                value: props.defaultValue?.workspaceMember || workspaceMember,
                error: undefined
            },
            taskId: {
                value: props.defaultValue?.task,
                error: undefined
            },
            startDate: {
                value: props.defaultValue?.startDate,
                error: undefined
            },
            startTime: {
                value: props.defaultValue?.startTime,
                error: undefined
            },
            duration: {
                value: props.defaultValue?.duration,
                error: undefined
            },
            comment: {
                value: props.defaultValue?.comment,
                error: undefined
            },

        });
        const clearErrors = () => {
            form.workspaceMemberId.error = undefined;
            form.taskId.error = undefined;
            form.comment.error = undefined;
            form.startDate.error = undefined;
            form.startTime.error = undefined;
            form.duration.error = undefined;
        };
        const {createTimeLog} = useTimelog(loading);
        const {dateAndDurationToDates} = useDate();
        const {init} = useFormErrors();
        const wasChanged = computed(() => {
            const prevSeconds = props.defaultValue?.duration?.seconds;
            const prevMinutes = props.defaultValue?.duration?.minutes;
            const prevHours = props.defaultValue?.duration?.hours;
            const currSeconds = form.duration.value?.seconds;
            const currMinutes = form.duration.value?.minutes;
            const currHours = form.duration.value?.hours;
            return prevSeconds !== currSeconds || prevMinutes !== currMinutes || prevHours !== currHours;
        });
        const isLogWasEdited = (isFinish, wasChanged, defaultWasChanged, mode) => {
            if (isFinish) return wasChanged;
            if (mode === 'create') return true;
            if (mode === 'edit') {
                if (defaultWasChanged === true) return true;
                return wasChanged === true;
            }
        };
        const createTransactionWrapper = async () => {
            const [startDate, endDate] = dateAndDurationToDates(form.startDate.value, form.startTime.value, form.duration.value);
            const timeLogData = {
                workspaceMemberId: form.workspaceMemberId.value.id,
                taskId: form.taskId.value.id,
                startDate: startDate ? moment.utc(startDate).toISOString() : undefined,
                endDate: endDate ? moment.utc(endDate).toISOString() : undefined,
                comment: form.comment.value,
                workspaceId: props.workspaceId,
                wasEdited: isLogWasEdited(props.isFinish, wasChanged.value, props.defaultValue.wasEdited, props.mode),
            };
            if (props.mode === 'edit') {
                emit('update', {...timeLogData, id: props.defaultValue.id});
            } else {
                const {ok} = await init(form, async () => {
                    return createTimeLog(props.workspaceId, timeLogData);
                }, clearErrors);
                if (ok) {
                    notification.success({
                        description: 'Лог времени создан',
                        message: 'Успех!'
                    });
                    emit('ok');
                }
            }

        };
        const disabled = computed(() => {
            return !form.taskId.value || !form.workspaceMemberId.value || (!form.duration.value?.minutes && !form.duration.value?.hours && !form.duration.value?.seconds) || !form.startDate.value;
        });
        const disableEditing = computed(() => {
            return props.mode === 'edit' && !props.isFinish && props.defaultValue && props.defaultValue.isPaid;
        });
        const continueLog = () => {
            emit('continue', props.defaultValue);
        };
        const deleteLog = () => {
            emit('delete', props.defaultValue);
        };
        /*=====================MEMBERS AREA=======================*/
        const {currentWorkspaceId} = useWorkspace();
        const {members, getMembers, setFilter: setMembersFilter} = useWorkspaceV2();
        const onMembersSearch = (q) => {
            setMembersFilter({search: q});
            debounce(() => getMembers(currentWorkspaceId.value), 500)();
        };
        onMounted(async () => {
            await getMembers(currentWorkspaceId.value);
        });
        /*=====================TASKS AREA=======================*/
        const {getTasksList, tasksList} = useKanban();
        const onTasksSearch = (q) => {
            debounce(() => getTasksList(currentWorkspaceId.value, {search: q}), 500)();
        };
        onMounted(async () => {
            await getTasksList(currentWorkspaceId.value, {});
        });
        return {
            form,
            loading,
            members,
            disabled,
            tasksList,
            wasChanged,
            disableEditing,
            hide_workspace_members,
            currencyMap: constants.currencyMap,
            deleteLog,
            formatDate,
            continueLog,
            onTasksSearch,
            onMembersSearch,
            selectFilterFunc,
            createTransactionWrapper,
        };
    }
};
</script>

<style lang="scss" scoped>
.create-time-log-form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
}
</style>