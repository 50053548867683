<template lang="pug">
.add-bank-account-form
    input-with-error(:error="form.title.error")
        template(#input)
            a-input(placeholder="Наименование" v-model:value="form.title.value")
    input-with-error(:error="form.bankName.error")
        template(#input)
            a-input(placeholder="Наименование банка" v-model:value="form.bankName.value")
    input-with-error(:error="form.bankIdentifier.error")
        template(#input)
            a-input(placeholder="БИК" v-model:value="form.bankIdentifier.value")
    input-with-error(:error="form.bankNumber.error")
        template(#input)
            a-input(placeholder="Номер счета банка" v-model:value="form.bankNumber.value")
    input-with-error(:error="form.companyNumber.error")
        template(#input)
            a-input(placeholder="Расчетный счет компании" v-model:value="form.companyNumber.value")
    input-with-error(:error="form.tax.error")
        template(#input)
            a-input-number(placeholder="Комиссия, %" v-model:value="form.tax.value" :min="0" style="width:100%" :max="100")
    input-with-error(:error="form.currency.error")
        template(#input)
            currency-select(v-model="form.currency.value")
    input-with-error(:error="form.companyId.error")
        template(#input)
            a-select(
                v-model:value="form.companyId.value"
                style="width: 100%"
                placeholder="Компания"
                show-search
                :filter-option="selectFilterFunc"
            )
                a-select-option(
                    v-for="company in directories.companies.list"
                    :key="company.id"
                    :value="company.id"
                    :label="company.title"
                )
                    | {{ company.title }}
                a-select-option.cursor-pointer(disabled @click="() => setModalVisibility('companies', true)") Добавить
                template(#notFoundContent)
                    add-directory(:onClick="() => setModalVisibility('companies', true)")
    input-with-error(:error="form.paymentDetails.error")
        template(#input)
            a-textarea(v-model:value="form.paymentDetails.value" placeholder="Реквизиты")
    a-button.mt-5.mb-2(
        type="primary"
        block
        @click="createWrapper"
        :loading="loading"
        :disabled="disabled"
    ) {{ mode === 'edit' ? 'Сохранить' : 'Создать' }}
a-modal(
    v-model:visible="additionalModals[currentModal].showModal"
    :title="additionalModals?.[currentModal]?.title"
    :footer="null"
    @cancel="setModalVisibility(currentModal, false)"
    :destroyOnClose="true"
)
    component(
        v-if="currentModal"
        :is="additionalModals[currentModal].component"
        @ok="onDirectoryAdded"
        :directories="directories"
        :workspaceId="workspaceId"
    )

</template>

<script>
import {computed, reactive, ref} from 'vue';
import {useFormErrors} from '@/composition/errors';
import {notification} from 'ant-design-vue';
import {selectFilterFunc} from '@/modules/utils';
import {useCrud} from '@/composition/crud';
import CurrencySelect from '@/components/Generic/CurrencySelect';
import CreateCompanyForm from '@/components/Directories/Bills/Company/CreateForm';
import AddDirectory from '@/components/Generic/AddDirectory/AddDirectory';
export default {
    name: 'CreateBankAccountForm',
    components: {AddDirectory, CreateCompanyForm, CurrencySelect},
    props: {
        workspaceId: Number,
        directories: Object,
        defaultValue: Object,
        mode: String,
    },
    emits: ['ok', 'update', 'fetch-directories'],
    setup(props, {emit}) {
        const loading = ref(false);
        const currentModal = ref('companies');
        const additionalModals = reactive({
            companies: {
                showModal: false,
                component: 'create-company-form',
                title: 'Добавить компанию'
            },
        });
        const setModalVisibility = (type, bool) => {
            currentModal.value = type;
            additionalModals[type].showModal = bool;
            if (!bool) {
                currentModal.value = 'companies';
            }
        };
        const onDirectoryAdded = async () => {
            setModalVisibility(currentModal.value, false);
            emit('fetch-directories');
        };
        const form = reactive({
            title: {
                value: props.defaultValue?.title,
                error: ''
            },
            tax: {
                value: props.defaultValue?.tax,
                error: undefined
            },
            currency: {
                value: props.defaultValue?.currency,
                error: undefined
            },
            companyId: {
                value: props.defaultValue?.companyId,
                error: undefined
            },
            paymentDetails: {
                value: props.defaultValue?.paymentDetails,
                error: undefined
            },
            bankName: {
                value: props.defaultValue?.bankName,
                error: undefined,
            },
            bankIdentifier: {
                value: props.defaultValue?.bankIdentifier,
                error: undefined,
            },
            bankNumber: {
                value: props.defaultValue?.bankNumber,
                error: undefined,
            },
            companyNumber: {
                value: props.defaultValue?.companyNumber,
                error: undefined,
            },
        });
        const {createEntity} = useCrud('bank-accounts', loading);
        const clearErrors = () => {
            form.title.error = '';
            form.tax.error = undefined;
            form.currency.error = undefined;
            form.companyId.error = undefined;
            form.paymentDetails.error = undefined;
            form.bankName.error = undefined;
            form.bankIdentifier.error = undefined;
            form.bankNumber.error = undefined;
            form.companyNumber.error = undefined;
        };
        const {init} = useFormErrors();

        const createWrapper = async () => {
            const payload = {
                title: form.title.value,
                tax: form.tax.value,
                currency: form.currency.value,
                companyId: form.companyId.value,
                paymentDetails: form.paymentDetails.value,
                bankName: form.bankName.value,
                bankIdentifier: form.bankIdentifier.value,
                bankNumber: form.bankNumber.value,
                companyNumber: form.companyNumber.value,
            };
            if (props.mode === 'edit') {
                emit('update', {...payload, id: props.defaultValue.id});
            } else {
                const {ok} = await init(form, async () => {
                    return createEntity(payload, props.workspaceId);
                }, clearErrors);
                if (ok) {
                    notification.success({
                        description: 'Счет успешно создан',
                        message: 'Успех!'
                    });
                    emit('ok');
                }
            }

        };

        const disabled = computed(() => {
            return !form.title.value;
        });
        return {
            createWrapper,
            disabled,
            form,
            loading,
            selectFilterFunc,
            currentModal,
            additionalModals,
            setModalVisibility,
            onDirectoryAdded
        };
    }
};
</script>

<style lang="scss" scoped>
.add-bank-account-form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
}
</style>