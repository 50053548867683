<template lang="pug">
.tasks-status-inline-changer
    .tasks-status-inline-changer__title(@click.stop="setPopupVisibility(true)")
        slot(name="title")
            div {{ status ? status.title : 'Не указано' }}
    a-popover(
        :visible="showPopup"
        placement="bottomLeft"
    )
        template(#content)
            .tasks-status-inline-changer__popup
                custom-select-v2(
                    v-model="newStatus"
                    placeholder="Статус"
                    :style="{width: '100%'}"
                    :options="taskStatuses"
                    :loading="fetchLoading"
                )
                .tasks-status-inline-changer__popup-btns
                    a-button(@click="onSave" type="primary" :loading="loading") Сохранить
                    a-button.ml-3(@click="onDecline" type="danger") Отменить
</template>

<script>
export default {
    name: 'TasksStatusInlineChanger'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
/*===============INIT AREA===================*/
import {ref, onMounted, watch} from 'vue';
import CustomSelectV2 from '@/components/Generic/CustomSelectV2';
import {useServiceV2} from '@/composition/service-v2';
import {debounce} from '@/modules/utils';
import {useWorkspace} from '@/composition/workspace';
import {useLoading} from '@/composition/loading-state';
import {useWorkspaceV2} from '@/composition/workspace-v2';
import {useKanban} from '@/composition/kanban';

const props = defineProps({
    status: {
        type: Object,
    },
    func: {
        type: Function
    },
});
const emit = defineEmits(['done']);
const {loading, withLoader} = useLoading();
const {loading: fetchLoading, withLoader: wrapFetchAsync} = useLoading();
/*===============MEMBERS AREA===================*/
const newStatus = ref(props.status);
const {currentWorkspaceId} = useWorkspace();
const {getTaskStatuses, taskStatuses} = useKanban();

watch(() => props.task, () => {
    newStatus.value = props.status;
}, {deep: true});
const onSave = async () => {
    await withLoader(async () => {
        const performerId = newStatus.value ? newStatus.value.id : null;
        await props.func(performerId);
    });
    setPopupVisibility(false);
};
const onDecline = () => {
    newStatus.value = props.status;
    setPopupVisibility(false);
};
/*===============POPUP AREA===================*/
const showPopup = ref(false);
const setPopupVisibility = async (bool) => {
    showPopup.value = bool;
    if (bool) {
        await wrapFetchAsync(async () => {
            await getTaskStatuses(currentWorkspaceId.value);
        });
    }
};
</script>

<style lang="scss" scoped>
.tasks-status-inline-changer {
    &__title {
        min-height: 32px;
        min-width: 40px;
        display: flex;
        align-items: center;
        width: fit-content;
        & span {
            text-decoration: underline;
            text-decoration-style: dotted;
            text-decoration-thickness: from-font;
            cursor: url("~@/assets/img/edit.png") 4 12, auto;
            display: inline-flex;
            align-items: center;
        }
    }
    &__popup {
        width: 240px;
        &-btns {
            margin-top: 12px;
        }
    }
}
</style>