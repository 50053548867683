<template lang="pug">
.payment-methods-form
    a-select(
        v-model:value="paymentMethod"
        style="width: 100%"
        placeholder="Способ оплаты"
        show-search
        allowClear
        :filter-option="selectFilterFunc"
    )
        a-select-option(
            v-for="paymentMethodItem in paymentMethods"
            :key="paymentMethodItem.id"
            :value="paymentMethodItem.id"
            :label="paymentMethodItem.title"
        )
            | {{ paymentMethodItem.title }}
        a-select-option.cursor-pointer(disabled @click="() => $emit('open-modal', 'paymentMethods')") Добавить
        template(#notFoundContent)
            add-directory(:onClick="() => $emit('open-modal', 'paymentMethods')")
    a-textarea.mt-2(v-model:value="requisites" placeholder="Реквизиты" :auto-size="{ minRows: 2, maxRows: 5 }")
    a-button.mt-2(type="primary" @click="add" :disabled="disabled") {{ mode === 'edit' ? 'Сохранить' : 'Добавить' }}
</template>

<script>
import {computed, ref} from 'vue';
import {selectFilterFunc} from '@/modules/utils';
import AddDirectory from '@/components/Generic/AddDirectory/AddDirectory';
export default {
    name: 'PaymentMethodsForm',
    components: {AddDirectory},
    props: {
        paymentMethods: Array,
        defaultValue: Object,
        mode: String
    },
    emits: ['add', 'open-modal'],
    setup(props, {emit}) {
        const paymentMethod = ref(props.defaultValue.paymentMethodId);
        const requisites = ref(props.defaultValue.requisites);
        const add = () => {
            emit('add', {
                id: props.defaultValue.id || Symbol(Date.now()),
                paymentMethodId: paymentMethod.value,
                requisites: requisites.value
            });
        };
        const disabled = computed(() => {
            return !paymentMethod.value || !requisites.value;
        });
        return {
            paymentMethod,
            requisites,
            add,
            selectFilterFunc,
            disabled,
        };
    }
};
</script>

<style scoped>

</style>