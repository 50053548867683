import {computed, ref} from 'vue';
import {apiRequest} from '@/modules/error_handler';
import servicesModule from '@/modules/services';
import moment from 'moment';
import constants from '@/constants';
import {serviceDocumentStatus, serviceDocumentStatusMap} from '@/components/Services/constants';

const service = ref({
    performers: []
});
const serviceWorkStatuses = ref({
    list: [],
    total: 0,
    next: false,
});
const pagination = ref({
    limit: 10,
    offset: 0,
});
const services = ref({
    list: [],
    next: '',
    prevPage: '',
    total: '',
    totalPages: '',
});
const tabs = ref({
    groups: [],
    workStatuses: []
});
const filter = ref({
    search: '',
    clients: [],
    performers: [],
    directors: [],
    paymentStatuses: [],
    docStatuses: [],
    delays: [],
    deadline: undefined,
    serviceGroupId: undefined,
    workStatusId: undefined,
});
const summary = ref({
    estimation: {
        in: 0,
        out: 0,
        margin: {
            value: 0,
            percent: '0',
        },
    },
    fact: {
        in: 0,
        out: 0,
        margin: {
            value: 0,
            percent: '0',
        },
    }
});
export function useService(loading={value: false}) {

    const setFilter = (filterData) => {
        filter.value = filterData;
    };
    const clearService = () => {
        service.value = {
            performers: []
        };
    };
    const setServiceWorkStatuses = (workStatuses) => {
        serviceWorkStatuses.value = workStatuses;
    };
    const setArchive = async (workspaceId, serviceId, bool) => {
        return apiRequest(async () => {
            return servicesModule.setArchive(workspaceId, serviceId, bool);
        }, loading);
    };
    const getServices = async (workspaceId, meta={}, additionalFilter={}) => {
        return apiRequest(async() => {
            const result = await servicesModule.getServices(workspaceId, {
                limit: pagination.value.limit,
                offset: pagination.value.offset,
            }, {...filter.value, ...additionalFilter});
            if (meta.fromPagination) {
                services.value.list = services.value.list.concat(result.list);
                services.value.next = result.next;
            } else {
                services.value = result;
            }
            return services.value;
        }, loading);
    };
    const updateLocalService = (service={}) => {
        services.value.list = services.value.list.map(x => {
            if (x.id === service.id) {
                return {...x, ...service};
            }
            return x;
        });
    };
    const createService = async (service, workspaceId, tz) => {
        return apiRequest(async() => {
            return servicesModule.createService(service, workspaceId, tz);
        }, loading);
    };
    const finishService = async (finishData, workspaceId) => {
        return apiRequest(async() => {
            return servicesModule.finishService(finishData, workspaceId);
        }, loading);
    };
    const returnService = async (returnData, workspaceId) => {
        return apiRequest(async() => {
            return servicesModule.returnService(returnData, workspaceId);
        }, loading);
    };
    const createServicePerformer = async (servicePerformer) => {
        return apiRequest(async() => {
            return servicesModule.createServicePerformer(servicePerformer);
        }, loading);
    };
    const deleteServicePerformer = async (id, workspaceId) => {
        return apiRequest(async() => {
            return servicesModule.deleteServicePerformer(id, workspaceId);
        }, loading);
    };
    const updateServicePerformer = async (id, updateData, workspaceId) => {
        return apiRequest(async() => {
            return servicesModule.updateServicePerformer(id, updateData, workspaceId);
        }, loading);
    };
    const getService = async (id, workspaceId) => {
        return apiRequest(async() => {
            service.value = await servicesModule.getService(id, workspaceId);
            return service.value;
        }, loading);
    };
    const getSummary = async (workspaceId) => {
        return apiRequest(async() => {
            summary.value = await servicesModule.getSummary(workspaceId, filter.value);
            return summary.value;
        }, loading);
    };
    const getTabs = async (workspaceId) => {
        return apiRequest(async() => {
            tabs.value = await servicesModule.getTabs(workspaceId, filter.value);
            return tabs.value;
        }, loading);
    };
    const updateService = async (id, updateData, workspaceId, tz) => {
        return apiRequest(async() => {
            return servicesModule.updateService(id, updateData, workspaceId, tz);
        }, loading);
    };
    const deleteService = async (id, workspaceId) => {
        return apiRequest(async() => {
            return servicesModule.deleteService(id, workspaceId);
        }, loading);
    };
    const isServiceOutdated = (service) => {
        const mainCondition = moment.utc().endOf('day') > moment.utc(service.deadline).endOf('day');
        if (service.isFinished && !service.isOutdated) {
            return false;
        }
        return mainCondition;
    };
    const getIncomingPaymentStatus= (record) => {
        if (record.outdatedBills.in) {
            return {
                type: 'Error',
                text: constants.statusIconText.payment.error
            };
        }
        if (record.pendingBills.in) {
            return {
                type: 'InProgress',
                text: constants.statusIconText.payment.pending
            };
        }
        if (record.allBillsPaid.in) {
            return {
                type: 'Success',
                text: constants.statusIconText.payment.success
            };
        }
        return {};
    };
    const getOutPaymentStatus = (record) => {
        if (record.outdatedBills.out) {
            return {
                type: 'Error',
                text: constants.statusIconText.payment.error
            };
        }
        if (record.pendingBills.out) {
            return {
                type: 'InProgress',
                text: constants.statusIconText.payment.pending
            };
        }
        if (record.allBillsPaid.out) {
            return {
                type: 'Success',
                text: constants.statusIconText.payment.success
            };
        }
        return {};
    };
    const serviceDocStatus = (service) => {
        switch (service.documentStatus) {
            case serviceDocumentStatus.in_progress:
                return {
                    type: 'InProgress',
                    text: constants.statusIconText.documentStatus.in_progress
                };
            default:
                return {
                    type: 'Success',
                    text: constants.statusIconText.documentStatus.done
                };
        }
    };
    const servicePerformersDocStatus = (service) => {
        const isDocumentStatusInProgress = service.performers.some(x => x.documentStatus === serviceDocumentStatus.in_progress);
        if (isDocumentStatusInProgress) {
            return {
                type: 'InProgress',
                text: constants.statusIconText.documentStatus.in_progress
            };
        }
        return {
            type: 'Success',
            text: constants.statusIconText.documentStatus.done
        };
    };
    const getServiceDocStatusTotal = (service) => {
        if (service.documentStatus === serviceDocumentStatus.in_progress) {
            return serviceDocumentStatus.in_progress;
        }
        if (service.performers.some(x => x.documentStatus === serviceDocumentStatus.in_progress)) {
            return serviceDocumentStatus.in_progress;
        }
        return serviceDocumentStatus.done;
    };
    const getDocumentStatusOptions = () => {
        return Object.keys(serviceDocumentStatusMap).map(x => {
            return {
                id: x,
                title: serviceDocumentStatusMap[x]
            };
        });
    };
    const periods = ref([]);
    const mapPeriods = (periodsList) => {
        return periodsList.map(x => {
            return {id: x, title: moment(x).format('MMMM YYYY')};
        });
    };
    const getPeriods = async () => {
        return apiRequest(async () => {
            periods.value = mapPeriods(await servicesModule.getPeriods());
            return periods.value;
        }, loading);
    };

    return {
        periods,
        getPeriods,
        tabs,
        service: computed(() => service.value),
        services,
        getSummary,
        setArchive,
        getDocumentStatusOptions,
        getServiceDocStatusTotal,
        summary,
        pagination,
        getServices,
        getTabs,
        createService,
        getService,
        updateService,
        deleteService,
        finishService,
        returnService,
        updateLocalService,
        getIncomingPaymentStatus,
        getOutPaymentStatus,
        serviceDocStatus,
        servicePerformersDocStatus,
        filter,
        setFilter,
        isServiceOutdated,
        createServicePerformer,
        deleteServicePerformer,
        updateServicePerformer,
        clearService,
        setServiceWorkStatuses,
        serviceWorkStatuses,
    };
}
