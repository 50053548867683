import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e73b4df"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bread-crumbs" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.routes, (route, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "bread-crumbs__item",
        key: route.path
      }, [
        (index !== $props.routes.length - 1)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: $setup.getRoutePath(route.path)
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(route.title), 1)
              ]),
              _: 2
            }, 1032, ["to"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(route.title), 1))
      ]))
    }), 128))
  ]))
}