<template lang="pug">
.bill-services
    bill-service(
        v-for="service in services.value"
        :key="service.id"
        :billService="service"
        @update="onUpdate"
        @delete="onDelete"
        :directories="directories"
        @log-data="onLogData"
        :additionalServiceFilter="additionalServiceFilter"
    )
    a-button.mt-2(type="primary" @click="addNew") {{ services.value.length === 0 ? 'Добавить' : 'Еще' }}
</template>

<script>
import BillService from '@/components/Bills/Services/Service';
export default {
    name: 'BillServices',
    components: {BillService},
    props: {
        services: Object,
        directories: Object,
        additionalServiceFilter: Object
    },
    emits: ['update:services', 'log-data'],
    setup(props, {emit}) {
        const defaultItem = () => ({
            id: Symbol(Date.now()),
            items: [
                {
                    id: Symbol(Date.now()),
                    title: undefined,
                    amount: undefined,
                    unitId: undefined,
                    sum: undefined,
                }
            ],
            serviceId: undefined,
            paymentPeriod: undefined,
        });
        const onUpdate = (billService) => {
            emit('update:services', {
                value: props.services.value.map(x => {
                    if (x.id == billService.id) {
                        return {
                            ...x,
                            ...billService
                        };
                    }
                    return x;
                })
            });
        };
        const onDelete = (id) => {
            emit('update:services', {
                value: props.services.value.filter(x => x.id != id)
            });
        };
        const addNew = () => {
            emit('update:services', {
                value: [...props.services.value, defaultItem()]
            });
        };
        const onLogData = (filter) => {
            emit('log-data', filter);
        };
        return {
            addNew,
            onUpdate,
            onDelete,
            onLogData,
        };
    }
};
</script>

<style lang="scss" scoped>
.bill-services {
    border: 1px solid #e6e6e6;
    padding: 10px;
}
</style>
