<template lang="pug">
.service-performer
    .service-performer__item
        card-title.mb-2 Выбор пользователя
        a-select(
            v-model:value="form.workspaceMemberId"
            style="width: 100%"
            placeholder="Исполнитель"
            show-search
            :filter-option="false"
            @search="onMembersSearch"
            @change="fetchMemberData"
            allowClear
        )
            a-select-option(
                v-for="member in members.list"
                :key="member.id"
                :value="member.id"
                :label="member.fullName"
            )
                | {{ member.fullName }}
    a-button.mt-3(type="primary" @click="add" :disabled="disabled") {{ mode === 'edit' ? 'Сохранить' : 'Добавить' }}
</template>

<script>
import {computed, onMounted, reactive, watch} from 'vue';
import {performerPaymentType, performerPaymentTypeMap} from '@/components/Services/constants';
import {useWorkspace} from '@/composition/workspace';
import CurrencySelect from '@/components/Generic/CurrencySelect';
import PaymentDatesForm from '@/components/Workspace/InviteUserForm/PaymentDatesForm';
import CardTitle from '@/components/Generic/Typography/CardTitle';

export default {
    name: 'ServicePerformer',
    components: {CardTitle, PaymentDatesForm, CurrencySelect},
    props: {
        defaultValue: Object,
        mode: String,
        proceeds: Number,
        profit: Number,
    },
    emits: ['add'],
    setup(props, {emit}) {
        const form = reactive({
            id: props.defaultValue.id || Symbol(Date.now()),
            workspaceMemberId: props.defaultValue.workspaceMemberId,
        });
        const {getMembers, members, setFilter, currentWorkspaceId, getWorkspaceMemberById, workspaceMember} = useWorkspace();
        const onMembersSearch = async(searchStr) => {
            setFilter({search: searchStr});
            await getMembers(currentWorkspaceId.value);
        };
        const fetchMemberData = async (workspaceMemberId) => {
            await getWorkspaceMemberById(currentWorkspaceId.value, workspaceMemberId);
        };
        onMounted(async () => {
            await getMembers(currentWorkspaceId.value);
        });
        const add = () => {
            emit('add', {
                id: form.id,
                workspaceMemberId: form.workspaceMemberId,
            });
        };
        const disabled = computed(() => {
            return !form.workspaceMemberId;
        });
        return {
            add,
            onMembersSearch,
            form,
            performerPaymentType,
            performerPaymentTypeMap,
            members,
            fetchMemberData,
            disabled,
        };
    }
};
</script>

<style lang="scss" scoped>
.service-performer {
    &__item {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 16px;
    }

}
</style>
