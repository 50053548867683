<template lang="pug">
settings-directories-members-layout
    async-page
        special-marks-table
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import SpecialMarksTable from '@/components/Directories/Members/SpecialMarks/Table';
import SettingsDirectoriesMembersLayout from '@/pages/Settings/Directories/Members/Layout';
export default {
    name: 'SpecialMarks',
    components: {SettingsDirectoriesMembersLayout, SpecialMarksTable, AsyncPage}
};
</script>

<style scoped>

</style>