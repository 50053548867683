<template lang="pug">
.performer-inline-changer
    .performer-inline-changer__title(@click.stop="setPopupVisibility(true)")
        span(v-if="workspaceMember")
            user-avatar(:user="workspaceMember")
            .ml-2(v-if="showFullName") {{ workspaceMember.fullName }}
        span(v-else) Не указано
    a-popover(
        :visible="showPopup"
        placement="bottomLeft"
    )
        template(#content)
            .performer-inline-changer__popup
                custom-select-v2(
                    v-model="newPerformer"
                    placeholder="Исполнитель"
                    :style="{width: '100%'}"
                    :options="members.list"
                    searchable
                    label="fullName"
                    @search="onPerformersSearch"
                    :loading="fetchLoading"
                )
                .performer-inline-changer__popup-btns
                    a-button(@click="onSave" type="primary" :loading="loading") Сохранить
                    a-button.ml-3(@click="onDecline" type="danger") Отменить
</template>

<script>
export default {
    name: 'PerformerInlineChanger'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
/*===============INIT AREA===================*/
import {ref, onMounted, watch} from 'vue';
import CustomSelectV2 from '@/components/Generic/CustomSelectV2';
import {useServiceV2} from '@/composition/service-v2';
import {debounce} from '@/modules/utils';
import {useWorkspace} from '@/composition/workspace';
import {useLoading} from '@/composition/loading-state';
import {useWorkspaceV2} from '@/composition/workspace-v2';

const props = defineProps({
    workspaceMember: {
        type: Object,
    },
    func: {
        type: Function
    },
    showFullName: {
        type: Boolean
    }
});
const emit = defineEmits(['done']);
const {loading, withLoader} = useLoading();
const {loading: fetchLoading, withLoader: wrapFetchAsync} = useLoading();
/*===============MEMBERS AREA===================*/
const newPerformer = ref(props.workspaceMember);
const {currentWorkspaceId} = useWorkspace();
const {getMembers, members, setFilter: setMembersFilter} = useWorkspaceV2();

const onPerformersSearch = (q) => {
    setMembersFilter({search: q});
    debounce(async () => {
        await wrapFetchAsync(async () => {
            await getMembers(currentWorkspaceId.value, {});
        });
    }, 500)();
};
watch(() => props.workspaceMember, () => {
    newPerformer.value = props.workspaceMember;
}, {deep: true});
const onSave = async () => {
    await withLoader(async () => {
        const performerId = newPerformer.value ? newPerformer.value.id : null;
        await props.func(performerId);
    });
    setPopupVisibility(false);
};
const onDecline = () => {
    newPerformer.value = props.workspaceMember;
    setPopupVisibility(false);
};
/*===============POPUP AREA===================*/
const showPopup = ref(false);
const setPopupVisibility = async (bool) => {
    showPopup.value = bool;
    if (bool) {
        await wrapFetchAsync(async () => {
            await getMembers(currentWorkspaceId.value, {});
        });
    }
};
</script>

<style lang="scss" scoped>
.performer-inline-changer {
    &__title {
        min-height: 32px;
        display: flex;
        align-items: center;
        width: fit-content;
        & span {
            text-decoration: underline;
            text-decoration-style: dotted;
            text-decoration-thickness: from-font;
            cursor: url("~@/assets/img/edit.png") 4 12, auto;
            display: inline-flex;
            align-items: center;
        }
    }
    &__popup {
        width: 240px;
        &-btns {
            margin-top: 12px;
        }
    }
}
</style>