<template lang="pug">
.sidebar-route
    component(
        :is="!children ? 'router-link' : 'div'"
        :to="getActualRoute(path)"
        @mouseover="showChildren = true"
        @mouseleave="showChildren = false"
    )
        .sidebar-route__item
            .flex.items-center
                .sidebar__icon.mr-3(v-if="icon")
                    icon(:type="icon" :color="iconColor ? iconColor : ''")
                span(v-if="!isTiny") {{ title }}
                span(v-if="isTiny && !icon") {{ title }}
        transition(name="slide")
            .sidebar-route__children(v-if="children && showChildren")
                sidebar-route(
                    v-for="child in children"
                    :key="child.id"
                    :title="child.title"
                    :icon="child.icon"
                    :icon-color="child.iconColor"
                    :path="child.path"
                    :children="child.children"
                    :is-tiny="isTiny"
                )
</template>

<script>
export default {
    name: 'SidebarRoute'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
import {ref} from 'vue';
import {useWorkspace} from '@/composition/workspace';

/*===============INITIALIZATION AREA===================*/

const props = defineProps({
    path: {
        type: String,
    },
    icon: {
        type: String
    },
    iconColor: {
        type: String,
    },
    children: {
        type: Array,
    },
    title: {
        type: String
    },
    isTiny: {
        type: Boolean
    }
});
const {currentWorkspaceId} = useWorkspace();

const getActualRoute = path => {
    const actualPath = path.replace(':id', currentWorkspaceId.value);
    return actualPath;
};

const showChildren = ref(false);
</script>

<style lang="scss">
.sidebar-route {
    position: relative;
    &__item {
        display: block;
        padding: 9px 25px;
        color: #ffffff;
        font-weight: 500;
        transition: all 0.3s;
        &:hover {
            color: #ffffff;
            background: #2e313e;
        }
    }

    &__children {
        position: absolute;
        top: 0;
        right: -230px;
        width: 230px;
        background: #1f2533;
    }
}

.slide-enter-active {
    transition: all .15s ease;
}
.slide-leave-active {
    transition: all .15s ease;
}
.slide-enter-from, .slide-leave-to {
    transform: translateX(-10px);
    opacity: 0;
}

</style>