import {createRouter, createWebHistory} from 'vue-router';
import {notAuth, auth, logout} from '@/middleware';
import Login from '@/pages/Auth/Login';
import Dashboard from '@/pages/Dashboard';
import Register from '@/pages/Auth/Register';
import Workspaces from '@/pages/Workspaces';
import Profile from '@/pages/Profile/Profile';
import Logout from '@/pages/Auth/Logout';
import CreateWorkspace from '@/pages/CreateWorkspace';
import SelectWorkspace from '@/pages/SelectWorkspace';
import WorkspaceSettings from '@/pages/Settings/Workspace';
import AcceptInvitation from '@/pages/Workspace/AcceptInvitation';
import RestorePassword from '@/pages/Auth/RestorePassword';
import RestorePasswordRequest from '@/pages/Auth/RestorePasswordRequest';
import ChangePassword from '@/pages/Profile/ChangePassword';
import Tasks from '@/pages/Tasks/Tasks';
import TaskSettings from '@/pages/Settings/Tasks';
import NotificationsSettings from '@/pages/Settings/Notifications';
import Integrations from '@/pages/Settings/Integrations/Integrations';
import CreateIntegration from '@/pages/Settings/Integrations/CreateIntegration';
import IntegrationProjects from '@/pages/Settings/Integrations/Projects';
import IntegrationMembers from '@/pages/Settings/Integrations/Members';
import ProjectsPage from '@/pages/Projects/Projects';
import ServicesPage from '@/pages/Services/Services';
import Skills from '@/pages/Settings/Directories/Members/Skills';
import Roles from '@/pages/Settings/Directories/Members/Roles';
import Grades from '@/pages/Settings/Directories/Members/Grades';
import Groups from '@/pages/Settings/Directories/Members/Groups';
import SpecialMarks from '@/pages/Settings/Directories/Members/SpecialMarks';
import HrSources from '@/pages/Settings/Directories/Members/HrSources';
import PaymentMethods from '@/pages/Settings/Directories/Members/PaymentMethods';
import MembersPage from '@/pages/Members/Members';
import MemberCommonPage from '@/pages/Members/Member/Common';
import MemberDocumentsPage from '@/pages/Members/Member/Documents';
import ContactsPage from '@/pages/Contacts/Contacts';
import ContactCommonPage from '@/pages/Contacts/Contact/Common';
import ContactGroupsPage from '@/pages/Settings/Directories/Contacts/Groups';
import ContactSpecialMarkPage from '@/pages/Settings/Directories/Contacts/SpecialMark';
import ServiceSpecialMarksPage from '@/pages/Settings/Directories/Services/SpecialMark';
import ServiceGroupPage from '@/pages/Settings/Directories/Services/Group';
import ServiceWorkStatusPage from '@/pages/Settings/Directories/Services/WorkStatus';
import DocumentTypesPage from '@/pages/Settings/Directories/Documents/Types';
import DocumentTransportsPage from '@/pages/Settings/Directories/Documents/Transports';
import DocumentStatusesPage from '@/pages/Settings/Directories/Documents/Statuses';
import LeadSource from '@/pages/Settings/Directories/Contacts/LeadSource';
import Loyalty from '@/pages/Settings/Directories/Contacts/Loyalty';
import DocumentsPage from '@/pages/Documents/Documents';
import DocumentPage from '@/pages/Documents/Document';
import MyCompaniesPage from '@/pages/Settings/Companies/MyCompanies';
import BankAccountsPage from '@/pages/Settings/Companies/BankAccounts';
import BillStatusesPage from '@/pages/Settings/Directories/Bills/Statuses';
import OperationsPage from '@/pages/Settings/Directories/Others/Operations';
import ServiceSummaryPage from '@/pages/Services/Service/Summary';
import ServicePerformersPage from '@/pages/Services/Service/Performers';
import ServiceDocumentsPage from '@/pages/Services/Service/Documents';
import ServiceBillsPage from '@/pages/Services/Service/Bills';
import ServiceTransactionsPage from '@/pages/Services/Service/Transactions';
import ServiceTimeLogsPage from '@/pages/Services/Service/TimeLogs';
import TransactionsPage from '@/pages/Transactions/Transactions';
import TimeLogsPage from '@/pages/TimeLog';
import ProjectCommonDataPage from '@/pages/Projects/Project/Common';
import BillsPage from '@/pages/Bills/Bills';
import BillCommonPage from '@/pages/Bills/Bill/Common';
import BillTransactionsPage from '@/pages/Bills/Bill/Transactions';
import UnitsPage from '@/pages/Settings/Directories/Others/Units';
import CreateTaskPage from '@/pages/Tasks/CreateTask';
import TaskPage from '@/pages/Tasks/Task/Task';
import SavedReportsPage from '@/pages/Reports/SavedReports';
import SavedReportCardPage from '@/pages/Reports/SavedReportCard';
import PublicHourlyReportsPage from '@/pages/Public/Reports/HourlyReport';
import HourlyReportsPage from '@/pages/Reports/HourlyReports';
import SalaryListPage from '@/pages/SalaryList/SalaryListPage';
import FinanceBalancePage from '@/pages/FinanceBalance/FinanceBalancePage';
import IncomingsAndOutgoingsPage from '@/pages/IncomingsAndOutgoings/IncomingsAndOutgoingsPage';
import SalaryPaymentsListPage from '@/pages/SalaryPaymentsList/SalaryPaymentsListPage';
import ResourceManagementPage from '@/pages/ResourceManagement/ResourceManagementPage';
import CalendarPage from '@/pages/Calendar/CalendarPage';
import PaymentPeriodsPage from '@/pages/Services/Service/PaymentPeriods';
import ServicePaymentPeriodPage from '@/pages/Services/Service/PaymentPeriod';
import RegularTransactionsPage from '@/pages/RegularTransactions/RegularTransactionsPage';
import ProfitAndLossPage from '@/pages/ProfitAndLoss/ProfitAndLoss';
import ServicesProfitability from '@/pages/Services/ServicesProfitability';
import FundsFlowPage from '@/pages/FundsFlow/FundsFlow';
const routes = [
    {
        path: '/',
        redirect: '/select-workspace',
    },
    {
        path: '/:id/',
        redirect: to => {
            if (to.params.id) {
                return `/${to.params.id}/tasks`;
            } else {
                return '/select-workspace';
            }
        }
    },
    {
        component: async () => Dashboard,
        path: '/:id/dashboard',
        beforeEnter: auth,
        meta: {
            name: 'Сводка',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => TransactionsPage,
        path: '/:id/transactions',
        beforeEnter: auth,
        meta: {
            name: 'Транзакции',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => RegularTransactionsPage,
        path: '/:id/regular-transactions',
        beforeEnter: auth,
        meta: {
            name: 'Регулярные выплаты',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => ProfitAndLossPage,
        path: '/:id/profit-and-loss',
        beforeEnter: auth,
        meta: {
            name: 'Прибыль и убытки',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => ServicesProfitability,
        path: '/:id/services-profitability',
        beforeEnter: auth,
        meta: {
            name: 'Рентабельность услуг',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => SalaryListPage,
        path: '/:id/salary-list',
        beforeEnter: auth,
        meta: {
            name: 'Зарплатная ведомость',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => CalendarPage,
        path: '/:id/calendar',
        beforeEnter: auth,
        meta: {
            name: 'Календарь событий',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => SalaryPaymentsListPage,
        path: '/:id/salary-payments-list',
        beforeEnter: auth,
        meta: {
            name: 'Начисление зарплаты',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => ContactsPage,
        path: '/:id/contacts',
        beforeEnter: auth,
        meta: {
            name: 'Рабочее пространство / Контакты',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => FinanceBalancePage,
        path: '/:id/balance',
        beforeEnter: auth,
        meta: {
            name: 'Рабочее пространство / Финбаланс',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => ResourceManagementPage,
        path: '/:id/resource-management',
        beforeEnter: auth,
        meta: {
            name: 'Планирование ресурсов',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => ContactCommonPage,
        path: '/:id/contacts/:contactId/common',
        beforeEnter: auth,
        meta: {
            name: 'Рабочее пространство / Контакт',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => MemberCommonPage,
        path: '/:id/members/:workspaceMemberId/common',
        beforeEnter: auth,
        meta: {
            name: 'Пользователи / Пользователь',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => MemberDocumentsPage,
        path: '/:id/members/:workspaceMemberId/documents',
        beforeEnter: auth,
        meta: {
            name: 'Пользователи / Пользователь / Документы',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => ServiceSummaryPage,
        path: '/:id/services/:serviceId/summary',
        beforeEnter: auth,
        meta: {
            name: 'Услуги / Сводка',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => ServicePerformersPage,
        path: '/:id/services/:serviceId/performers',
        beforeEnter: auth,
        meta: {
            name: 'Услуги / Исполнители',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => PaymentPeriodsPage,
        path: '/:id/services/:serviceId/payment-periods',
        beforeEnter: auth,
        meta: {
            name: 'Услуги / Платежные периоды',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => ServicePaymentPeriodPage,
        path: '/:id/services/:serviceId/payment-periods/:paymentPeriodId',
        beforeEnter: auth,
        meta: {
            name: 'Услуги / Платежный период',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => ServiceDocumentsPage,
        path: '/:id/services/:serviceId/documents',
        beforeEnter: auth,
        meta: {
            name: 'Услуги / Документы',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => ServiceBillsPage,
        path: '/:id/services/:serviceId/bills',
        beforeEnter: auth,
        meta: {
            name: 'Услуги / Счета',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => ServiceTransactionsPage,
        path: '/:id/services/:serviceId/transactions',
        beforeEnter: auth,
        meta: {
            name: 'Услуги / Транзакции',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => ServiceTimeLogsPage,
        path: '/:id/services/:serviceId/time-logs',
        beforeEnter: auth,
        meta: {
            name: 'Услуги / Записи времени',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => MembersPage,
        path: '/:id/members',
        beforeEnter: auth,
        meta: {
            name: 'Пользователи',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => Workspaces,
        path: '/:id/workspaces',
        beforeEnter: auth,
        meta: {
            name: 'Рабочие пространства',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => Profile,
        path: '/:id/profile/profile',
        beforeEnter: auth,
        meta: {
            name: 'Профиль',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => Tasks,
        path: '/:id/tasks',
        beforeEnter: auth,
        meta: {
            name: 'Задачи',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => CreateTaskPage,
        path: '/:id/tasks/new',
        beforeEnter: auth,
        meta: {
            name: 'Создать задачу',
            assignedToWorkspace: true,
            layout: 'main-layout',
        }
    },
    {
        component: async () => TaskPage,
        path: '/:id/tasks/:taskId',
        beforeEnter: auth,
        meta: {
            name: 'Карточка задачи',
            assignedToWorkspace: true,
            layout: 'main-layout',
            backButton: true
        }
    },
    {
        component: async () => ProjectsPage,
        path: '/:id/projects',
        beforeEnter: auth,
        meta: {
            name: 'Проекты',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        path: '/:id/projects/:projectId',
        redirect: to => {
            if (to.params.id) {
                return `/${to.params.id}/projects/${to.params.projectId}/common`;
            } else {
                return '/select-workspace';
            }
        }
    },
    {
        component: async () => ProjectCommonDataPage,
        path: '/:id/projects/:projectId/common',
        beforeEnter: auth,
        meta: {
            name: 'Проекты / Общая информация',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => ServicesPage,
        path: '/:id/services',
        beforeEnter: auth,
        meta: {
            name: 'Услуги',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => TaskSettings,
        path: '/:id/settings/tasks',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Задачи',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => WorkspaceSettings,
        path: '/:id/settings/workspace',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Рабочее пространство',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => NotificationsSettings,
        path: '/:id/settings/notifications',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Уведомления',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => Integrations,
        path: '/:id/settings/integrations',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Интеграции',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => CreateIntegration,
        path: '/:id/settings/integrations/add-integration',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Добавление интеграции',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => IntegrationProjects,
        path: '/:id/settings/integrations/:integrationId/projects',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Страница интеграции / Проекты',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => IntegrationMembers,
        path: '/:id/settings/integrations/:integrationId/members',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Страница интеграции / Участники',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        path: '/:id/settings/directories',
        redirect: to => {
            if (to.params.id) {
                return `/${to.params.id}/settings/directories/members/skills`;
            } else {
                return '/select-workspace';
            }
        }
    },
    {
        path: '/:id/settings/directories/members',
        redirect: to => {
            if (to.params.id) {
                return `/${to.params.id}/settings/directories/members/skills`;
            } else {
                return '/select-workspace';
            }
        }
    },
    {
        path: '/:id/settings/directories/contacts',
        redirect: to => {
            if (to.params.id) {
                return `/${to.params.id}/settings/directories/contacts/groups`;
            } else {
                return '/select-workspace';
            }
        }
    },
    {
        path: '/:id/settings/directories/services',
        redirect: to => {
            if (to.params.id) {
                return `/${to.params.id}/settings/directories/services/special-marks`;
            } else {
                return '/select-workspace';
            }
        }
    },
    {
        path: '/:id/settings/directories/documents',
        redirect: to => {
            if (to.params.id) {
                return `/${to.params.id}/settings/directories/documents/types`;
            } else {
                return '/select-workspace';
            }
        }
    },
    {
        path: '/:id/settings/directories/others',
        redirect: to => {
            if (to.params.id) {
                return `/${to.params.id}/settings/directories/others/units`;
            } else {
                return '/select-workspace';
            }
        }
    },
    {
        component: async () => BankAccountsPage,
        path: '/:id/settings/companies/bank-accounts',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Счета',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => BillStatusesPage,
        path: '/:id/settings/directories/bills/statuses',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Справочники / Статусы счетов',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => UnitsPage,
        path: '/:id/settings/directories/others/units',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Справочники / Единицы измерения',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => OperationsPage,
        path: '/:id/settings/directories/others/operations',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Справочники / Статьи операций',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        path: '/:id/settings/companies',
        redirect: to => {
            if (to.params.id) {
                return `/${to.params.id}/settings/companies/my-companies`;
            } else {
                return '/select-workspace';
            }
        }
    },
    {
        component: async () => MyCompaniesPage,
        path: '/:id/settings/companies/my-companies',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Мои компании',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => ServiceSpecialMarksPage,
        path: '/:id/settings/directories/services/special-marks',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Справочники / Особые метки',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => ServiceGroupPage,
        path: '/:id/settings/directories/services/groups',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Справочники / Группы',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => ServiceWorkStatusPage,
        path: '/:id/settings/directories/services/work-statuses',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Справочники / Статусы',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => DocumentTypesPage,
        path: '/:id/settings/directories/documents/types',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Справочники / Типы документов',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => DocumentStatusesPage,
        path: '/:id/settings/directories/documents/statuses',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Справочники / Статусы документов',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => DocumentTransportsPage,
        path: '/:id/settings/directories/documents/transports',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Справочники / Способы обмена документов',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => ContactGroupsPage,
        path: '/:id/settings/directories/contacts/groups',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Справочники / Группы',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => ContactSpecialMarkPage,
        path: '/:id/settings/directories/contacts/special-marks',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Справочники / Особые метки',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => LeadSource,
        path: '/:id/settings/directories/contacts/lead-sources',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Справочники / Источник лида',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => Loyalty,
        path: '/:id/settings/directories/contacts/loyalty',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Справочники / Лояльность',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => Roles,
        path: '/:id/settings/directories/members/roles',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Справочники / Роли',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => Groups,
        path: '/:id/settings/directories/members/groups',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Справочники / Группы',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => SpecialMarks,
        path: '/:id/settings/directories/members/special-marks',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Справочники / Особые отметки',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => HrSources,
        path: '/:id/settings/directories/members/hr-sources',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Справочники / HR источники',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => PaymentMethods,
        path: '/:id/settings/directories/members/payment-methods',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Справочники / Способы оплаты',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => Groups,
        path: '/:id/settings/directories/members/groups',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Справочники / Группы',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => Grades,
        path: '/:id/settings/directories/members/grades',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Справочники / Грейды',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => Skills,
        path: '/:id/settings/directories/members/skills',
        beforeEnter: auth,
        meta: {
            name: 'Настройки рабочего пространства / Справочники / Роли',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => ChangePassword,
        path: '/:id/profile/change-password',
        beforeEnter: auth,
        meta: {
            name: 'Смена пароля',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => DocumentsPage,
        path: '/:id/documents',
        beforeEnter: auth,
        meta: {
            name: 'Документооборот',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => DocumentPage,
        path: '/:id/documents/:documentId',
        beforeEnter: auth,
        meta: {
            name: 'Документооборот / Документ',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => TimeLogsPage,
        path: '/:id/time-logs/',
        beforeEnter: auth,
        meta: {
            name: 'Записи времени',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => BillsPage,
        path: '/:id/bills/',
        beforeEnter: auth,
        meta: {
            name: 'Счета',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => BillCommonPage,
        path: '/:id/bills/:billId/common',
        beforeEnter: auth,
        meta: {
            name: 'Счет',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => BillTransactionsPage,
        path: '/:id/bills/:billId/transactions',
        beforeEnter: auth,
        meta: {
            name: 'Транзакции',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        path: '/:id/reports/hourly',
        redirect: to => {
            if (to.params.id) {
                return `/${to.params.id}/reports/hourly/new`;
            } else {
                return '/select-workspace';
            }
        }
    },
    {
        component: async () => SavedReportsPage,
        path: '/:id/reports/hourly/saved',
        beforeEnter: auth,
        meta: {
            name: 'Сохраненные отчеты',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => SavedReportCardPage,
        path: '/:id/reports/saved/:reportId',
        beforeEnter: auth,
        meta: {
            name: 'Сохраненный отчет',
            assignedToWorkspace: true,
            layout: 'main-layout',
            backButton: true
        }
    },
    {
        component: async () => HourlyReportsPage,
        path: '/:id/reports/hourly/new',
        beforeEnter: auth,
        meta: {
            name: 'Отчеты по таймингу',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => IncomingsAndOutgoingsPage,
        path: '/:id/reports/incomings-and-outgoings',
        beforeEnter: auth,
        meta: {
            name: 'Отчет о прибыли и убытках',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: async () => FundsFlowPage,
        path: '/:id/reports/funds-flow',
        beforeEnter: auth,
        meta: {
            name: 'Движение средств',
            assignedToWorkspace: true,
            layout: 'main-layout'
        }
    },
    {
        component: CreateWorkspace,
        path: '/create-workspace',
        beforeEnter: auth,
        meta: {
            name: 'Создание рабочего пространства',
            assignedToWorkspace: false
        }
    },
    {
        component: AcceptInvitation,
        path: '/accept-invite',
        meta: {
            name: 'Приглашение',
            assignedToWorkspace: false
        }
    },
    {
        component: SelectWorkspace,
        path: '/select-workspace',
        beforeEnter: auth,
        meta: {
            name: 'Выбор рабочего пространства',
            assignedToWorkspace: false
        }
    },
    {
        component: PublicHourlyReportsPage,
        path: '/public/reports/hourly/:uuid',
    },
    {
        component: Login,
        path: '/login',
        beforeEnter: notAuth
    },
    {
        component: RestorePasswordRequest,
        path: '/restore-password-request',
        beforeEnter: notAuth
    },
    {
        component: RestorePassword,
        path: '/auth/restore_password/:confirmationId',
        beforeEnter: notAuth
    },
    {
        component: Logout,
        path: '/logout',
        beforeEnter: logout
    },
    {
        component: Register,
        path: '/register',
        beforeEnter: notAuth
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/select-workspace'
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    mode: 'history'
});
export default router;
