import moment from 'moment';
import {useWorkspace} from '@/composition/workspace';
import constants from '@/constants';

export function useDate() {
    const defaultTz = constants.tz.default;
    const {currentWorkspaceTz} = useWorkspace();
    const toDateOnly = (date) => {
        if (!date) return null;
        return moment.utc(date).tz(currentWorkspaceTz.value || defaultTz).format('YYYY-MM-DD');
    };
    const formatDate = (date) => {
        if (!date) return;
        return moment.utc(date).tz(currentWorkspaceTz.value || defaultTz).format('DD.MM.YYYY');
    };
    const formatDateTime = (date) => {
        if (!date) return;
        return moment.utc(date).tz(currentWorkspaceTz.value || defaultTz).format('DD.MM.YYYY HH:mm');
    };
    const truncateDuration = (num) => {
        if (!num) return 0;
        return Number(num).toFixed(1);
    };
    const formatDateRange = (arr) => {
        return [formatDate(arr[0]), formatDate(arr[1])];
    };
    const dataRangeToMs = (arr) => {
        return [moment.utc(arr[0]).tz(currentWorkspaceTz.value || defaultTz).valueOf(), moment.utc(arr[1]).tz(currentWorkspaceTz.value || defaultTz).valueOf()];
    };
    const dateToMs = (date, withoutTz=false) => {
        if (withoutTz) {
            return moment(date).valueOf();
        }
        return moment.utc(date).tz(currentWorkspaceTz.value || defaultTz).valueOf();
    };
    const msToDate = (date) => {
        return moment.utc(date).tz(currentWorkspaceTz.value || defaultTz).toISOString();
    };
    const msToDateOnly = (date) => {
        return moment.utc(date).tz(currentWorkspaceTz.value || defaultTz).format('YYYY-MM-DD');
    };
    const dateRangeMsToDateObject = (arr) => {
        return [moment.utc(arr[0]).tz(currentWorkspaceTz.value || defaultTz).toISOString(), moment.utc(arr[1]).tz(currentWorkspaceTz.value || defaultTz).toISOString()];
    };
    const isOutdated = (dateString) => {
        return moment.utc().endOf('day').valueOf() > moment.utc(dateString).endOf('day').valueOf();
    };
    const dateAndDurationToDates = (startDate, startTime, duration) => {
        const startDateTime = moment(startDate).set('hours', startTime.hours || 0).set('minutes', startTime.minutes || 0).set('seconds', startTime.seconds || 0);
        const endDateTime = startDateTime.clone().add('hours', duration.hours || 0).add('minutes', duration.minutes || 0).add('seconds', duration.seconds || 0);
        return [startDateTime, endDateTime];
    };
    const getHoursAndMinutesFromMs = (ms) => {
        const seconds = Math.floor((ms / 1000) % 60);
        const minutes = Math.floor((ms / (1000 * 60)) % 60);
        const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
        return {
            seconds: Number(seconds),
            hours: Number(hours),
            minutes: Number(minutes),
        };
    };
    const getDateFromNow = (days=0) => {
        return moment.utc().tz(currentWorkspaceTz.value || defaultTz).add(days, 'day').startOf('day').toISOString(true);
    };
    const getDateRangeWithOffset = (from, to) => {
        const tz = currentWorkspaceTz.value || defaultTz;
        const utcOffset = moment.utc().tz(tz).format('Z');
        const fromResult = moment(from).startOf('day').tz(tz).toISOString(true);
        const toResult = moment(to).endOf('day').tz(tz).toISOString(true);
        return {
            from: fromResult.replace('Z', utcOffset),
            to: toResult.replace('Z', utcOffset),
        };
    };
    const getMonthFromNow = (monthsCountFromNow=0) => {
        return moment.utc().tz(currentWorkspaceTz.value || defaultTz).add(monthsCountFromNow, 'month');
    };
    const formatMonth = (dateStr) => {
        if (!dateStr) return null;
        return moment(dateStr).format('MMMM, YYYY');
    };
    const getEndOfMonth = (dateStr=moment().format('YYYY-MM-DD'), isUtc=false) => {
        if (isUtc) {
            return moment.utc(dateStr).endOf('month');
        }
        return moment.utc(dateStr).tz(currentWorkspaceTz.value || defaultTz).endOf('month');
    };
    const getStartOfMonth = (dateStr=moment().format('YYYY-MM-DD'), isUtc=false) => {
        if (isUtc) {
            return moment.utc(dateStr).startOf('month');
        }
        return moment.utc(dateStr).tz(currentWorkspaceTz.value || defaultTz).startOf('month');
    };
    const getCurrentYear = () => {
        return Number(moment.utc().tz(currentWorkspaceTz.value || defaultTz).format('YYYY'));
    };
    const getDateObjectFromMs = (ms) => {
        return moment.utc(ms);
    };
    const dateInstance = () => {
        return moment;
    };
    const toDateWithUtcOffset = (dateStr, {isStart=false, isEnd=false}) => {
        const base = moment.utc(dateStr);
        isStart && base.startOf('day');
        isEnd && base.endOf('day');
        const utcOffset = moment.utc().tz(currentWorkspaceTz.value || defaultTz).format('Z');
        return base.toISOString().replace('Z', utcOffset);
    };
    const timeObjectToString = ({hours, minutes, seconds}) => {
        if (!hours) hours = 0;
        if (!minutes) minutes = 0;
        if (!seconds) seconds = 0;
        const d = moment().set('hours', hours).set('minutes', minutes).set('seconds', seconds);
        return d.format('HH:mm:ss');
    };
    const timeStringToObject = (timeString) => {
        const [hours, minutes, seconds] = timeString.split(':');
        return {
            hours: Number(hours),
            minutes: Number(minutes),
            seconds: Number(seconds)
        };
    };
    const getMonths = (dateStr) => {
        const value = moment(dateStr);
        const current = value.clone();
        const localeData = value.localeData();
        const months = [];
        for (let i = 0; i < 12; i++) {
            current.month(i);
            months.push(localeData.monthsShort(current));
        }
        return months;
    };

    const getYears = () => {
        const value = moment();
        const year = value.year();
        const years = [];
        for (let i = year; i < year + 10; i += 1) {
            years.push(i);
        }
        return years;
    };
    const formatTime = (time) => {
        if (!time) return '';
        const date = moment().format('YYYY-MM-DD');
        return moment(date + ' ' + time).format('HH:mm');
    };
    const toFormat = (dateString, fromFormat, toFormat) => {
        return moment(dateString, fromFormat).format(toFormat);
    };
    const fromUtcToTz = (dateString) => {
        return moment(dateString).tz(currentWorkspaceTz.value || constants.tz.default);
    };
    const getDayOfMonth = (dayNumber) => {
        return moment().set('date', dayNumber).format('YYYY-MM-DD');
    };
    const getMonthRangeFromPeriod = (period) => {
        const base = moment(period);
        return [base.clone().startOf('month'), base.clone().endOf('month')].map(x => x.format('YYYY-MM-DD'));
    };
    const inFuture = (dateString) => {
        return moment.utc().valueOf() < moment.utc(dateString).valueOf();
    };
    return {
        getMonthRangeFromPeriod,
        getDayOfMonth,
        msToDateOnly,
        fromUtcToTz,
        toFormat,
        formatTime,
        getMonths,
        getYears,
        inFuture,
        toDateWithUtcOffset,
        dateInstance,
        getCurrentYear,
        getDateObjectFromMs,
        getEndOfMonth,
        getStartOfMonth,
        formatMonth,
        getMonthFromNow,
        getHoursAndMinutesFromMs,
        isOutdated,
        formatDate,
        formatDateRange,
        formatDateTime,
        truncateDuration,
        dataRangeToMs,
        dateRangeMsToDateObject,
        dateToMs,
        msToDate,
        dateAndDurationToDates,
        getDateFromNow,
        getDateRangeWithOffset,
        toDateOnly,
        timeObjectToString,
        timeStringToObject,
    };
}
