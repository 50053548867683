<template lang="pug">
.contact-person
    card-title Контактное лицо
    .editable-card__column.mt-2
        .flex.items-center Имя
        editable-cell(
            :initialValue="contact.contactPerson"
            @update="(e) => onUpdate('contactPerson', e)"
        )
    .editable-card__column.mt-2
        .flex.items-center Телефон
        editable-cell(
            :initialValue="contact.phone"
            @update="(e) => onUpdate('phone', e)"
        )
    .editable-card__column.mt-2
        .flex.items-center Skype
        editable-cell(
            :initialValue="contact.skype"
            @update="(e) => onUpdate('skype', e)"
        )
    .editable-card__column.mt-2
        .flex.items-center Telegram
        editable-cell(
            :initialValue="contact.telegram"
            @update="(e) => onUpdate('telegram', e)"
        )
</template>

<script>
import CardTitle from '@/components/Generic/Typography/CardTitle';
import EditableCell from '@/components/Generic/EditableCell';
export default {
    name: 'ContactPerson',
    components: {EditableCell, CardTitle},
    props: {
        contact: Object,
    },
    emits: ['update'],
    setup(props, {emit}) {
        const onUpdate = (key, value) => {
            emit('update', {[key]: value});
        };
        const getTelegramText = () => props.contact?.telegram || 'Не указано';
        const getSkypeText = () => props.contact?.skype || 'Не указано';
        const getPhoneText = () => props.contact?.phone || 'Не указано';
        return {
            onUpdate,
            getTelegramText,
            getSkypeText,
            getPhoneText,
        };
    }
};
</script>

<style scoped>

</style>