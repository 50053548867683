import {reactive, ref} from 'vue';
import {apiRequest} from '@/modules/error_handler';
import salaryModule from '@/modules/salary';
import Big from 'big.js';
import {toFixedNumber} from '@/modules/utils/v2.ts';

export function useSalary(loading={value: false}) {
    const salaryPaymentsPagination = ref({
        limit: 10,
        offset: 0,
    });
    const salaryListFilter = ref({
        year: undefined,
    });
    const salaryPaymentsListList = ref({
        list: [],
        next: false,
        total: 0,
    });
    const membersSalaryPositions = ref([]);
    const salaryStats = reactive({
        utilization: {
            planText: 'Утилизация план',
            factText: 'факт',
            diffText: 'разница',
            unitText: 'ч.',
            plan: 0,
            fact: 0,
            diff: 0,
        },
        plan: {
            planText: 'План',
            unitText: 'руб.',
            plan: 0,
        },
        finance: {
            planText: 'К оплате',
            factText: 'Оплачено',
            diffText: 'Остаток',
            unitText: 'руб.',
            plan: 0,
            fact: 0,
            diff: 0,
        }
    });
    const filter = ref({
        period: undefined,
        from: undefined,
        to: undefined,
    });

    const list = ref([]);
    const calculateSum = (salaries) => {
        let utilizationPlan = Big(0);
        let utilizationFact = Big(0);
        let utilizationDiff = Big(0);
        let financePlan = Big(0);
        let financeDataPlan = Big(0);
        let financeDataFact = Big(0);
        for (const salary of salaries) {
            utilizationPlan = Big(utilizationPlan).plus(salary.utilizationPlan);
            utilizationFact = Big(utilizationFact).plus(salary.utilizationFact);
            utilizationDiff = Big(utilizationDiff).plus(salary.utilizationDiff);
            financePlan = Big(financePlan).plus(salary.financePlan);
            financeDataFact = Big(financeDataFact).plus(salary.financeFact);
            financeDataPlan = Big(financeDataPlan).plus(Big(salary.balance));
        }
        const financeDataDiff = financeDataPlan.minus(financeDataFact);
        salaryStats.utilization.plan = toFixedNumber(utilizationPlan);
        salaryStats.utilization.fact = toFixedNumber(utilizationFact);
        salaryStats.utilization.diff = toFixedNumber(utilizationDiff);
        salaryStats.plan.plan = toFixedNumber(financePlan);
        salaryStats.finance.plan = toFixedNumber(financeDataPlan);
        salaryStats.finance.fact = toFixedNumber(financeDataFact);
        salaryStats.finance.diff = toFixedNumber(financeDataDiff);
    };
    const getList = async () => {
        return apiRequest(async () => {
            list.value = await salaryModule.getList(filter.value);
            calculateSum(list.value);
            return list.value;
        }, loading);
    };
    const updateSalary = async (id, updateData) => {
        return apiRequest(async () => {
            return salaryModule.updateSalary(id, updateData);
        }, loading);
    };

    const deleteSalary = async (id) => {
        return apiRequest(async () => {
            return salaryModule.deleteSalary(id);
        }, loading);
    };

    const createSalary = async (salaryPosition) => {
        return apiRequest(async () => {
            return salaryModule.createSalary(salaryPosition);
        }, loading);
    };


    const getStats = async () => {
        return apiRequest(async () => {
            const response = await salaryModule.getStats(filter.value);
            salaryStats.sum.value = response.sum.value;
            salaryStats.sum.isEstimated = response.sum.isEstimated;
            salaryStats.paid.value = response.paid.value;
            salaryStats.paid.isEstimated = response.paid.isEstimated;
            salaryStats.remainder.value= response.remainder.value;
            salaryStats.remainder.isEstimated= response.remainder.isEstimated;
            return response;
        }, loading);
    };
    const getMembersSalaryPositions = (workspaceMemberIds, from, to) => {
        return apiRequest(async() => {
            membersSalaryPositions.value = await salaryModule.getMembersSalaryPositions(workspaceMemberIds, from, to);
            return membersSalaryPositions.value;
        });
    };
    const createSalaryPaymentsList = (data) => {
        return apiRequest(async () => {
            return salaryModule.createSalaryPaymentsList(data);
        });
    };
    const updateSalaryPaymentsList = (id, data) => {
        return apiRequest(async () => {
            return salaryModule.updateSalaryPaymentsList(id, data);
        });
    };
    const getSalaryPaymentsListById = (id) => {
        return apiRequest(async () => {
            return salaryModule.getSalaryPaymentsListById(id);
        });
    };
    const deleteSalaryPaymentsList = (id) => {
        return apiRequest(async () => {
            return salaryModule.deleteSalaryPaymentsList(id);
        });
    };
    const getSalaryPaymentsListList = (meta={}) => {
        return apiRequest(async () => {
            const result = await salaryModule.getSalaryPaymentsListList({
                ...salaryPaymentsPagination.value,
                ...salaryListFilter.value,
            });
            if (meta.fromPagination) {
                salaryPaymentsListList.value.list = salaryPaymentsListList.value.list.concat(result.list);
                salaryPaymentsListList.value.next = result.next;
            } else {
                salaryPaymentsListList.value = result;
            }
            return salaryPaymentsListList.value;
        });
    };
    return {
        list,
        filter,
        salaryStats,
        salaryListFilter,
        membersSalaryPositions,
        salaryPaymentsListList,
        salaryPaymentsPagination,
        getList,
        createSalary,
        updateSalary,
        deleteSalary,
        getStats,
        createSalaryPaymentsList,
        updateSalaryPaymentsList,
        deleteSalaryPaymentsList,
        getSalaryPaymentsListById,
        getSalaryPaymentsListList,
        getMembersSalaryPositions,

    };
}