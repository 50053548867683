<template lang="pug">
.services-table
    .flex.items-center
        a-button(type="primary" @click="setModalVisibility(true)" v-if="!fromIntegration") Добавить услугу
        a-button.ml-3(type="primary" @click="setPeriodModalVisibility(true)" v-if="!fromIntegration") Добавить период
    service-filter.my-3(
        v-model:filter="filter"
        @update:filter="onFilterChanged"
        :paymentPeriodsCount="paymentPeriodsCount"
    )
    payment-period-create-form-finance-stats(
        :is-plan="false"
        :plan="stats.plan"
        :balance="stats.balance"
        :fact="stats.fact"
        v-if="filter.paymentPeriodStatus !== ServicePaymentPeriodStatus.archive"
        :statsLoading="statsLoading"
    )
    .services-table__scroll
        service-payment-period-table(
            :list="paymentPeriods.list"
            from-services
            :loading="loading"
            :statsLoading="tableStatsLoading"
        )
    a-button.mt-3(@click="next" v-if="paymentPeriods.next && !loading" :loading="paginationLoading") Показать еще
    a-modal(
        v-model:visible="showModal"
        title="Создать услугу"
        :footer="null"
        @cancel="setModalVisibility(false)"
        :destroyOnClose="true"
        width="80%"
    )
        create-service-form(
            :workspaceId="currentWorkspaceId"
            @create="onCreate"
            mode="create"
        )
    a-drawer(
        :visible="showPeriodModal"
        title="Добавить период"
        @close="setPeriodModalVisibility(false)"
        :closable="true"
        width="80%"
        :footer="null"
        :destroyOnClose="true"
    )
        service-payment-period-create-form(
            :loading="loading"
            :mode="currentPeriodMode"
            @create="onPeriodCreate"
            allow-select-service
        )
</template>

<script>
import {serviceDocumentStatusMap, servicePaymentType, servicePaymentTypeMap} from '../constants';
import {onMounted, toRaw, ref} from 'vue';
import CreateProjectForm from '@/components/Projects/CreateProjectForm';
import EditableCell from '@/components/Generic/EditableCell';
import {useService} from '@/composition/service';
import CreateServiceForm from '@/components/Services/CreateForm';
import {useDate} from '@/composition/date';
import ServiceFilter from '@/components/Services/Filter';
import CustomTable from '@/components/Generic/CustomTable';
import {useCreateOrUpdateModal} from '@/composition/create-update-modal';
import {useRoute, useRouter} from 'vue-router';
import {arrayToString, formatCurrency, getPercent, omitBy, parseArrayFromString} from '@/modules/utils';
import ServicesSummary from '@/components/Services/SummaryBlock';
import ServicesFinanceWidget from '@/components/Services/Widgets/FinanceWidget';
import ServiceChangeStatus from '@/components/Services/ChangeStatus';
import {useDirectory} from '@/composition/directory';
import StatusProgress from '@/components/Generic/StatusProgress';
import SelectSuspense from '@/components/Generic/SelectSuspense/index';
import {useProject} from '@/composition/project';
import {columns as periodColumns} from '../PaymentPeriod/constants';
import ServicePaymentPeriodTable from '@/components/Services/PaymentPeriod/Table/index';
import {ServicePaymentPeriodStatus} from '@/components/Services/PaymentPeriod/constants.ts';
import {useWorkspaceV2} from '@/composition/workspace-v2';
import {useLoading} from '@/composition/loading-state/index.ts';
import {useServicePaymentPeriod} from '@/composition/service-v2/service-payment-periods.ts';
import PaymentPeriodCreateFormFinanceStats from '@/components/Services/PaymentPeriod/CreateForm/FinanceStats';
import {ServicePaymentPeriodForm} from '@/modules/services/form/service-payment-period.form.ts';
import ServicePaymentPeriodCreateForm from '@/components/Services/PaymentPeriod/CreateForm';
import {useSocket} from '@/composition/socket';
export default {
    name: 'ServicesTable',
    components: {
        ServicePaymentPeriodCreateForm,
        PaymentPeriodCreateFormFinanceStats,
        ServicePaymentPeriodTable,
        SelectSuspense,
        StatusProgress,
        ServiceChangeStatus,
        ServicesFinanceWidget,
        ServicesSummary, CustomTable, ServiceFilter, CreateServiceForm, EditableCell, CreateProjectForm,
    },
    props: {
        fromIntegration: Boolean,
    },
    async setup() {
        /*===================SOCKET AREA=========================*/
        const {
            subscribe
        } = useSocket();
        subscribe('service_stats_created', (data) => {
            stats.fact = data.fact;
            stats.plan = data.plan;
            stats.balance = data.balance;
            statsLoading.value = false;
        });
        subscribe('service_data_created', (data) => {
            const currData = toRaw(paymentPeriods.value);
            paymentPeriods.value.list = currData.list.map(x => {
                const val = data.list.find(newItem => x.id === newItem.id);
                if (val) {
                    return {
                        ...x,
                        ...val,
                    };
                }
                return {
                    ...x,
                };
            });
            tableStatsLoading.value = false;
        });
        /*===================INIT AREA=========================*/
        const {loading, withLoader} = useLoading();
        const {loading: paginationLoading, withLoader: withPaginationLoader} = useLoading();
        const router = useRouter();
        const route = useRoute();
        const statsLoading  = ref(true);
        const tableStatsLoading = ref(true);

        /*===================SERVICE PAYMENT PERIOD AREA=========================*/
        const {
            stats,
            filter,
            getStats,
            setFilter,
            pagination,
            paymentPeriods,
            paymentPeriodsCount,
            getPaymentPeriods,
            createPaymentPeriod,
            getServicePaymentPeriodsCount,
        } = useServicePaymentPeriod(null);
        const {
            currentMode: currentPeriodMode,
            showModal: showPeriodModal,
            setModalVisibility: setPeriodModalVisibility,
        } = useCreateOrUpdateModal();
        const onPeriodCreate = async (servicePaymentPeriod) => {
            await withLoader(async () => {
                const {ok} = await createPaymentPeriod(servicePaymentPeriod);
                ok && await fetchData({fromPagination: false});
                ok && setPeriodModalVisibility(false);
            });
        };
        /*===================SERVICE CRUD AREA=========================*/
        const {
            currentMode,
            showModal,
            setModalVisibility,
        } = useCreateOrUpdateModal();
        const {
            createService,
        } = useService(loading);
        const {
            formatDate,
            dateToMs,
            formatDateTime,
            truncateDuration,
            msToDateOnly,
        } = useDate();
        const {currentWorkspaceId, getMembers} = useWorkspaceV2();
        const {getProjects} = useProject();
        const {directories, getDirectories} = useDirectory();

        const fetchData = async ({fromPagination = false}) => {
            const loadingFunc = fromPagination ? withPaginationLoader : withLoader;
            await loadingFunc(async () => {
                statsLoading.value = true;
                tableStatsLoading.value = true;
                await getPaymentPeriods(fromPagination);
                await getDirectories(currentWorkspaceId.value);
                await getMembers(currentWorkspaceId.value);
                await getProjects(currentWorkspaceId.value);
                await getServicePaymentPeriodsCount(currentWorkspaceId.value);
                await getStats();
            });
        };

        const onCreate = async ({service, done}) => {
            await withLoader(async () => {
                const {ok} = await createService(service, currentWorkspaceId.value);
                done();
                if (ok) {
                    pagination.offset = 0;
                    setModalVisibility(false);
                    await fetchData({fromPagination: false});
                }
            });
        };
        onMounted(async () => {
            await initTableFilter();
        });
        /*===================SERVICE FILTERING AND PAGINATION AREA=========================*/
        const initTableFilter = async () => {
            const routeFilter = route.query;
            const paymentPeriodStatus = routeFilter.paymentPeriodStatus ? routeFilter.paymentPeriodStatus : ServicePaymentPeriodStatus.active;
            const filterData = {
                contacts: routeFilter.contacts ? parseArrayFromString(routeFilter.contacts, true) : [],
                performers: routeFilter.performers ? parseArrayFromString(routeFilter.performers, true) : [],
                directors: routeFilter.directors ? parseArrayFromString(routeFilter.directors, true) : [],
                deadline: routeFilter.deadline ? msToDateOnly(Number(routeFilter.deadline)) : undefined,
                serviceGroup: routeFilter.serviceGroupId ? {id: routeFilter.serviceGroupId} : undefined,
                paymentPeriodStatus,
            };
            await onFilterChanged(filterData);
        };
        const next = async () => {
            pagination.offset += pagination.limit;
            await fetchData({fromPagination: true});
        };
        const onFilterChanged = async (filter, skipRouter) => {
            if (!skipRouter) {
                const routeQuery = omitBy({
                    contacts: filter.contacts.length ? arrayToString(filter.contacts, true) : undefined,
                    performers: filter.performers.length ? arrayToString(filter.performers, true) : undefined,
                    directors: filter.directors.length ? arrayToString(filter.directors, true) : undefined,
                    deadline: filter.deadline ? dateToMs(filter.deadline) : undefined,
                    serviceGroupId: filter.serviceGroup ? filter.serviceGroup.id : undefined,
                    workStatus: filter.workStatus ? filter.workStatus.id : undefined,
                    paymentPeriodStatus: filter.paymentPeriodStatus,
                }, (value) => value !== undefined);
                await router.push({
                    query: routeQuery,
                });
            }
            pagination.offset = 0;
            setFilter(filter);
            await fetchData({fromPagination: false});
        };

        /*===================RENDERERS AREA=========================*/
        const getServiceSummaryRoute = (id) => {
            return `/${currentWorkspaceId.value}/services/${id}/payment-periods`;
        };
        const redirectToService = (service) => {
            return router.push(getServiceSummaryRoute(service.id));
        };
        const getServiceTypeIcon = (service) => {
            switch (service.paymentType) {
                case servicePaymentType.fixed:
                    return 'PaymentTypeFixed';
                case servicePaymentType.hourly:
                    return 'PaymentTypeHourly';
                default:
                    return '';
            }
        };
        return {
            stats,
            statsLoading,
            paymentPeriods,
            tableStatsLoading,
            filter,
            loading,
            showModal,
            directories,
            currentMode,
            paginationLoading,
            paymentPeriodsCount,
            periodColumns,
            currentWorkspaceId,
            setModalVisibility,
            servicePaymentTypeMap,
            serviceDocumentStatusMap,
            ServicePaymentPeriodStatus,
            currentPeriodMode,
            showPeriodModal,
            setPeriodModalVisibility,
            next,
            onPeriodCreate,
            onCreate,
            fetchData,
            getPercent,
            formatDate,
            formatDateTime,
            truncateDuration,
            formatCurrency,
            onFilterChanged,
            redirectToService,
            getServiceTypeIcon,
            getServiceSummaryRoute,
        };
    },
};
</script>

<style lang="scss" scoped>
.services-table {
    &__scroll {
        //max-width: 1400px;
        //overflow-x: auto;
    }

    &__title {
        display: flex;
        align-items: center;
    }
}
</style>
