<template lang="pug">
.document-service-item
    a-input.mr-2(v-if="!hidePositions" :value="documentServiceItem.title" placeholder="Наименование" style="width: 20%" @change="onChange('title', $event.target.value)")
    a-input-number.mr-2(v-if="!hidePositions" :value="documentServiceItem.amount" placeholder="Кол-во" style="width: 20%" @change="onChange('amount', $event)")
    a-select.mr-2(
        v-if="!hidePositions"
        :value="documentServiceItem.unitId"
        placeholder="Ед. измерения"
        show-search
        :filter-option="selectFilterFunc"
        style="width: 20%"
        @change="onChange('unitId', $event)"
    )
        a-select-option(
            v-for="unit in directories.units.list"
            :key="unit.id"
            :value="unit.id"
            :label="unit.title"
        )
            | {{ unit.title }}
    a-input-number.mr-2(:value="documentServiceItem.sum" placeholder="Стоимость" style="width: 20%" @change="onChange('sum', $event)")
    div(style="width: 10%") Итого: {{ total }}
    div(@click="deleteItem" v-if="canDelete")
        icon(type="Delete")
</template>

<script>
import {computed} from 'vue';
import {selectFilterFunc} from '@/modules/utils';
export default {
    name: 'DocumentServiceItem',
    props: {
        documentServiceItem: Object,
        directories: Object,
        canDelete: {
            type: Boolean
        },
        hidePositions: Boolean,
    },
    emits: ['update', 'delete'],
    setup(props, {emit}) {
        const total = computed(() => (props.documentServiceItem.amount || 0) * (props.documentServiceItem.sum || 0));
        const onChange = (key, value) => {
            emit('update', {
                ...props.documentServiceItem,
                [key]: value
            });
        };
        const deleteItem = () => {
            emit('delete', props.documentServiceItem.id);
        };
        return {
            total,
            onChange,
            deleteItem,
            selectFilterFunc,
        };
    }
};
</script>

<style lang="scss" scoped>
.document-service-item {
    display: flex;
    align-items: center;
}
</style>