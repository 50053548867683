export const columns = [
    {
        title: 'Наименование',
        dataIndex: 'title',
        key: 'title',
        slots: {customRender: 'title'},
        width: '30%'
    },
    {
        title: 'Тип',
        dataIndex: 'type',
        key: 'type',
        slots: {customRender: 'type'},
        width: '30%'
    },
    {
        title: 'Действие',
        dataIndex: 'action',
        key: 'action',
        slots: {customRender: 'action'},
        width: '30%'
    },
    {
        title: '',
        slots: {customRender: 'table-action'},
    },
];


export const operationType = {
    operative: 'operative',
    finance: 'finance',
    investment: 'investment'
};
export const operationTypeMap = {
    operative: 'Операционный',
    finance: 'Финансовый',
    investment: 'Инвестиционный'
};

export const operationAction = {
    incoming: 'incoming',
    outgo: 'outgo',
    transfer: 'transfer',
    input: 'input',
    output: 'output',
};
export const operationActionMap = {
    incoming: 'Приход',
    outgo: 'Расход',
    transfer: 'Перевод',
    input: 'Ввод',
    output: 'Вывод',
};


