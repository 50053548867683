<template lang="pug">
.hourly-reports-filter
    .hourly-reports-filter__row
        workspace-members-select.mr-3(
            :modelValue="filter.performers"
            mode="multiple"
            placeholder="Исполнители"
            @update:modelValue="onChange('performers', $event)"
            v-if="!hide_workspace_members"
        )
        contacts-select-new.mr-3(
            v-if="!hide_clients"
            :modelValue="filter.contacts"
            type="clients"
            placeholder="Клиент"
            @update:modelValue="onChange('contacts', $event)"
            mode="multiple"
        )
        services-select-new.mr-3(
            :modelValue="filter.services"
            @update:modelValue="onChange('services', $event)"
            mode="multiple"
            default-first
        )
        simple-range-picker(
            :range="[filter.from, filter.to]"
            @update:range="onDateRangeChanged"
            with-utc-offset
        )
        a-button(type="primary" @click="createReport" :disabled="isDisabled" :loading="loading") Сформировать
        .ml-2.base-link(@click="saveReport" v-if="!fromCard && !hide_save") Сохранить
        .ml-2.base-link(@click="updateReport" v-if="fromCard && !hide_save") Сохранить
        .ml-2.base-link(@click="shareReport" v-if="fromCard && !hide_save")
            icon(type="Share")
    .hourly-reports-filter__row.mt-2
        .hourly-reports-filter__row__date(
            @click="setGroupFilter('serviceId')"
            :class="{'hourly-reports-filter__row__date--active': 'serviceId' === filter.chartGroupBy}"
        ) По услуге
        .hourly-reports-filter__row__date(
            @click="setGroupFilter('workspaceMemberId')"
            :class="{'hourly-reports-filter__row__date--active': 'workspaceMemberId' === filter.chartGroupBy}"
        ) По исполнителю
</template>

<script>
export default {
    name: 'HourlyReportsFilter'
};
</script>

<script setup>

/*======================IMPORT AREA=====================*/

import {computed, ref} from 'vue';
import WorkspaceMembersSelect from '@/components/Workspace/Member/Select';
import ContactsSelectNew from '@/components/Contacts/SelectNew';
import ServicesSelectNew from '@/components/Services/SelectNew';
import {useReport} from '@/composition/reports';
import {useWorkspace} from '@/composition/workspace';
import Icon from '@/components/Generic/Typography/Icon';
import {useAuth} from '@/composition/auth';
import {useRole} from '@/composition/role';
import SimpleRangePicker from '@/components/Generic/SimpleRangePicker';
import {useDate} from '@/composition/date';
import {useRouter} from 'vue-router';
import {arrayToString, isUndefined, omitBy} from '@/modules/utils';

/*======================INITIALIZATION AREA=====================*/
const router = useRouter();
const {getStartOfMonth, getEndOfMonth, toDateWithUtcOffset} = useDate();
const {filter, setFilter, setDateFilter} = useReport();
const {user} = useAuth();
const {sections, getDefaults} = useRole(user.value);
const {workspaceMember, hide_clients, hide_save, hide_workspace_members} = getDefaults(sections.reports.name, sections.reports.filters.name);
if (workspaceMember) {
    setFilter({...filter.value, performers: [workspaceMember]});
}
const emit = defineEmits(['start', 'save', 'update', 'share']);
const props = defineProps({
    loading: {
        type: Boolean
    },
    fromCard: {
        type: Boolean
    }
});

const setGroupFilter = (newGroup) => {
    onChange('chartGroupBy', newGroup);
};

/*=============DECLARING CHANGES AREA=============*/
const toQueryParams = (newFilter) => {
    router.push({
        query: omitBy({
            performers: newFilter.performers.length ? arrayToString(newFilter.performers, true) : undefined,
            services: newFilter.services.length ? arrayToString(newFilter.services, true) : undefined,
            contacts: newFilter.contacts.length ? arrayToString(newFilter.contacts, true) : undefined,
            from: newFilter.from ? newFilter.from.slice(0, 10) : undefined,
            to: newFilter.to ? newFilter.to.slice(0, 10) : undefined
        }, (val) => !isUndefined(val))
    });
};
const createReport = () => {
    toQueryParams(filter.value);
    emit('start');
};

const saveReport = () => {
    emit('save');
};

const updateReport = () => {
    emit('update');
};
const shareReport = () => {
    emit('share');
};

const onChange = (key, value) => {
    setFilter({
        ...filter.value,
        [key]: value
    });
};

const onDateRangeChanged = (range) => {
    const [from, to] = range;
    setFilter({
        ...filter.value,
        from,
        to,
    });
};


/*=============UI STATE COMPUTED AREA=============*/

const isDisabled = computed(() => !filter.value.from || !filter.value.to);

</script>

<style lang="scss" scoped>
.hourly-reports-filter {
    &__row {
        display: flex;
        align-items: center;
        &__date {
            font-size: 10px;
            color: #000c17;
            cursor: pointer;
            margin-right: 7px;
            white-space: nowrap;
            &--active {
                font-weight: bold;
            }
        }
    }
}
</style>
