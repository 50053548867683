import {Cast} from '@/modules/cast';
import {createTransactionDto} from '@/modules/transaction/dto/create-transaction.dto';
import {updateTransactionDto} from '@/modules/transaction/dto/update-transaction.dto';
import {isUndefined, omitBy} from '@/modules/utils';

const schema = {
    id: {
        type: 'number',
    },
    contractor: {
        type: 'object',
    },
    contractorId: {
        type: 'number',
    },
    billId: {
        type: 'number',
        nullable: true
    },
    salaryListId: {
        type: 'number',
        nullable: true
    },
    date: {
        type: 'string',
    },
    description: {
        type: 'string',
        nullable: true
    },
    fromAccountId: {
        type: 'number',
        nullable: true
    },
    fromAccount: {
        type: 'object',
        nullable: true
    },
    toAccountId: {
        type: 'number',
        nullable: true
    },
    toAccount: {
        type: 'object',
        nullable: true
    },
    relatedTransaction: {
        type: 'object',
        nullable: true
    },
    operation: {
        type: 'object',
        nullable: true
    },
    operationId: {
        type: 'number',
        nullable: true
    },
    services: {
        type: 'array',
    },
    positions: {
        type: 'array',
    },
    type: {
        type: 'string',
    },
    workspaceId: {
        type: 'number',
    },
    fromAmount: {
        type: 'number',
        nullable: true
    },
    toAmount: {
        type: 'number',
        nullable: true
    },
    amount: {
        type: 'number',
        nullable: true
    },
    period: {
        type: 'string',
    },
    isScheduled: {
        type: 'boolean',
    },
    isRepeatable: {
        type: 'boolean',
    },
    lastRepeatDate: {
        type: 'string',
        nullable: true,
    },
    periodicity: {
        type: 'string',
        nullable: true,
    },
    prevId: {
        type: 'number',
        nullable: true,
    },
};

export class Transaction extends Cast {
    constructor(transaction) {
        super();
        for (const key in schema) {
            Transaction.validate(key, schema[key], transaction[key]);
            this[key] = transaction[key];
        }
    }
    static validate(key, schemaElement, element) {
        return Cast.validate(Transaction.name, key, schemaElement, element);
    }
    toObject() {
        return super.toObject(schema);
    }
    static is(transaction) {
        return Cast.is(transaction, schema);
    }
    static createDto(transaction) {
        return createTransactionDto(transaction, Transaction.validate);
    }
    static updateDto(transaction) {
        return omitBy(updateTransactionDto(transaction, Transaction.validate), (value) => !isUndefined(value));
    }
}