import {httpInstance} from '@/modules/http_factory';
const getDirectories = async (workspaceId, folder) => {
    const response = await httpInstance(workspaceId).get('/directories', {
        params: {
            workspaceId,
            folder
        }
    });
    return response.data;
};

export default {
    getDirectories
};