<template lang="pug">
.bill-common-data
    .bill-common-data__content
        bill-main(:bill="bill" :directories="directories" @update="onUpdate")
        a-divider
        .flex.items-center.mb-3
            card-title.mr-3 Услуги
        a-card
            bill-services(v-model:services="localServices" :directories="directories" @log-data="setLogData")
            a-button(type="primary" @click="saveServices") Сохранить
    .bill-common-data__actions
        card-title Действия
        a-button(@click="downloadBill(bill.id)") Скачать счет
        delete-with-confirm.mt-2(:deleteFunc="() => deleteBillById()")
</template>

<script>
import {useRoute, useRouter} from 'vue-router';
import {ref} from 'vue';
import {useBill} from '@/composition/bill';
import {useDirectory} from '@/composition/directory';
import {useWorkspace} from '@/composition/workspace';
import DeleteWithConfirm from '@/components/Generic/DeleteWithConfirm';
import BillMain from '@/components/Bills/Common/Main';
import BillServices from '@/components/Bills/Services';
import CardTitle from '@/components/Generic/Typography/CardTitle';
import {useService} from '@/composition/service';
import {useDate} from '@/composition/date';
import {useTimelog} from '@/composition/timelog';
export default {
    name: 'BillCommonData',
    components: {
        CardTitle,
        BillServices,
        BillMain,
        DeleteWithConfirm,
    },
    async setup() {
        const loading = ref(false);
        const isServiceEditing = ref(false);
        const localServices = ref({
            value: []
        });
        const {getDateRangeWithOffset} = useDate();
        const route = useRoute();
        const router = useRouter();
        const {currentWorkspaceId, getMembers} = useWorkspace(loading);
        const {getBillById, bill, deleteBill, updateBill, getRawBill, downloadBill} = useBill(loading);
        const {getTimeLogsByServiceId} = useTimelog();
        const {getDirectories, directories} = useDirectory(loading);
        const {getServices} = useService(loading);
        await getBillById(route.params.billId, currentWorkspaceId.value);
        localServices.value.value = bill.value.services;
        await getDirectories(currentWorkspaceId.value, 'bills');
        await getServices(currentWorkspaceId.value);
        await getMembers(currentWorkspaceId.value);
        const onUpdate = async(data) => {
            await updateBill(currentWorkspaceId.value, route.params.billId, data);
            await getBillById(route.params.billId, currentWorkspaceId.value);
            localServices.value.value = bill.value.services;
        };
        const deleteBillById = async() => {
            await deleteBill(currentWorkspaceId.value, route.params.billId);
            await router.push(`/${currentWorkspaceId.value}/bills`);
        };
        const saveServices = async () => {
            await onUpdate({services: localServices.value.value});
            isServiceEditing.value = false;
        };
        const setLogData = async (filter) => {
            const {ok, result} = await getTimeLogsByServiceId(currentWorkspaceId.value, filter.serviceId, filter.paymentPeriodId, bill.value.type);
            if (ok && result.length) {
                localServices.value.value = localServices.value.value.map(x => {
                    if (x.serviceId === filter.serviceId) {
                        return {
                            ...x,
                            items: result
                        };
                    }
                    return x;
                });
            }
        };
        return {
            setLogData,
            bill,
            directories,
            localServices,
            isServiceEditing,
            downloadBill,
            onUpdate,
            saveServices,
            deleteBillById,
        };
    }
};
</script>

<style lang="scss" scoped>
.bill-common-data {
    display: grid;
    grid-template-columns: 1fr 400px;
    grid-gap: 20px;

    &__content {

    }

    &__actions {

    }
}
</style>