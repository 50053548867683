import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "resource-management-members-list" }
const _hoisted_2 = ["title"]
const _hoisted_3 = { class: "resource-management-members-list__member-name" }
const _hoisted_4 = { class: "resource-management-members-list__member-hours ml-3" }
const _hoisted_5 = { class: "resource-management-members-list__total" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_divider = _resolveComponent("a-divider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.workspaceMembers, (member) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "resource-management-members-list__member",
        key: member.id,
        title: member.fullName,
        style: _normalizeStyle({marginBottom: $props.rowsMap[member.id].length > 1 ? `${($props.rowsMap[member.id].length - 1) * 38}px`: ''})
      }, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(member.fullName), 1),
        _createElementVNode("div", _hoisted_4, _toDisplayString(member.factHoursAmount || 0) + " из " + _toDisplayString(member.hoursAmount) + " ч.", 1)
      ], 12, _hoisted_2))
    }), 128)),
    _createVNode(_component_a_divider),
    _createElementVNode("div", _hoisted_5, _toDisplayString($setup.totalHoursAmount) + " ч.", 1)
  ]))
}