import {ref, computed} from 'vue';
import {apiRequest} from '@/modules/error_handler';
import tasksModule from '@/modules/tasks';
import workspaceModule from '@/modules/workspace';
import {arrayToString, omitBy, parseArrayFromString, toLabelValue} from '@/modules/utils';
import {useDate} from '@/composition/date';

const tasks = ref({});
const pagination = ref({
    limit: 10,
    offset: 0
});
const tasksList = ref({
    list: [],
    next: undefined,
    total: 0
});
const archivedTasksList = ref({
    list: [],
    next: undefined,
    total: 0
});
const stats = ref({
    unallocatedActiveTasksCount: 0,
    unallocatedAllTasksCount: 0,
});
const task = ref({});
const filter = ref({});
const taskStatuses = ref([]);
const statusMapping = ref({});
const activeTask = ref({
    id: null,
    duration: 0
});
export function useKanban(loading={value: false}) {
    const {msToDate, dateToMs} = useDate();

    const getTasks = async (workspaceId) => {
        return apiRequest(async() => {
            const {statusMap, data} = await tasksModule.getTasks(workspaceId, filter.value);
            statusMapping.value = statusMap;
            tasks.value = data;
        }, loading);
    };
    const getStats = async (workspaceId) => {
        return apiRequest(async() => {
            const response = await tasksModule.getStats(workspaceId);
            stats.value = response;
        }, loading);
    };
    const getTask = async (taskId, workspaceId) => {
        return apiRequest(async() => {
            const response = await tasksModule.getTask(taskId, workspaceId);
            task.value = response;
            return response;
        }, loading);
    };
    const startTask = async (taskId, workspaceId) => {
        return apiRequest(async() => {
            const response = await tasksModule.startTask(taskId, workspaceId);
            workspaceModule.ping(workspaceId);
            activeTask.value = {id: taskId, duration: 0};
            return response;
        }, loading);
    };
    const stopTask = async (taskId, payload, workspaceId) => {
        return apiRequest(async() => {
            const response = await tasksModule.stopTask(taskId, payload, workspaceId);
            activeTask.value = {id: null, duration: 0};
            return response;
        }, loading);
    };
    const getTasksList = async (workspaceId, filter) => {
        return apiRequest(async() => {
            const tasksResult = await tasksModule.getTasksList(workspaceId, filter, pagination.value);
            tasksList.value = tasksResult;
        }, loading);
    };
    const getArchivedTasks = async (workspaceId, meta={}) => {
        return apiRequest(async() => {
            const result = await tasksModule.getArchivedTasks(workspaceId, {
                limit: pagination.value.limit,
                workspaceId,
                offset: pagination.value.offset,
                ...filter.value
            });
            if (meta.fromPagination) {
                archivedTasksList.value.list = archivedTasksList.value.list.concat(result.list);
                archivedTasksList.value.next = result.next;
            } else {
                archivedTasksList.value = result;
            }
            return archivedTasksList.value;
        }, loading);
    };
    const addTask = async(task) => {
        return apiRequest(async() => {
            return tasksModule.addTask(task);
        }, loading);
    };
    const updateTask = async(taskId, updateData) => {
        return apiRequest(async() => {
            return tasksModule.updateTask(taskId, updateData);
        }, loading);
    };
    const deleteTaskById = async(taskId, workspaceId) => {
        return apiRequest(async() => {
            return tasksModule.deleteTaskById(taskId, workspaceId);
        }, loading);
    };
    const updateOrders = async(ordersArr, workspaceId) => {
        return apiRequest(async() => {
            return tasksModule.updateOrders(ordersArr, workspaceId);
        });
    };
    const updateTaskStatusOrders = async(workspaceId, ordersArr) => {
        return apiRequest(async() => {
            return tasksModule.updateTaskStatusOrders(workspaceId, ordersArr);
        });
    };
    const getTaskStatuses = async(workspaceId) => {
        return apiRequest(async() => {
            const statuses = await tasksModule.getTaskStatuses(workspaceId);
            taskStatuses.value = statuses;
            return statuses;
        }, loading);
    };
    const updateTaskStatus = async(workspaceId, id, updateData) => {
        return apiRequest(async() => {
            return tasksModule.updateTaskStatus(workspaceId, id, updateData);
        }, loading);
    };
    const createTaskStatus = async(workspaceId, taskStatus) => {
        return apiRequest(async() => {
            return tasksModule.createTaskStatus(workspaceId, {workspaceId, ...taskStatus});
        }, loading);
    };
    const deleteTaskStatus = async(workspaceId, id) => {
        return apiRequest(async() => {
            return tasksModule.deleteTaskStatus(workspaceId, id);
        }, loading);
    };
    const toQueryParams = (filter={}) => {
        return omitBy({
            workspaceMembers: filter.workspaceMembers && filter.workspaceMembers.length ? arrayToString(filter.workspaceMembers, true) : undefined,
            taskId: filter?.task?.id,
            services: filter.services && filter.services.length ? arrayToString(filter.services, true) : undefined,
            deadline: filter.deadline ? dateToMs(filter.deadline) : undefined,
        }, (value) => value !== undefined);
    };
    const fromQueryParams = (routeQuery={}) => {
        return omitBy({
            workspaceMembers: routeQuery.workspaceMembers ? parseArrayFromString(routeQuery.workspaceMembers, true) : undefined,
            task: routeQuery.taskId ? {id: Number(routeQuery.taskId)} : undefined,
            services: routeQuery.services ? parseArrayFromString(routeQuery.services, true) : undefined,
            deadline: routeQuery.deadline ? msToDate(Number(routeQuery.deadline)) : undefined,
        }, (value) => value !== undefined);
    };
    const createTaskChecklistElement = async(workspaceId, checklistElement) => {
        return apiRequest(async() => {
            return tasksModule.createTaskChecklistElement(workspaceId, checklistElement);
        }, loading);
    };
    const updateTaskChecklistElement = async(workspaceId, checklistElementId, updateData) => {
        return apiRequest(async() => {
            return tasksModule.updateTaskChecklistElement(workspaceId, checklistElementId, updateData);
        }, loading);
    };
    const deleteTaskChecklistElement = async(workspaceId, checklistElementId) => {
        return apiRequest(async() => {
            return tasksModule.deleteTaskChecklistElement(workspaceId, checklistElementId);
        }, loading);
    };
    return {
        activeTask,
        startTask,
        stopTask,
        stats,
        getTask,
        getStats,
        task,
        getTasks,
        addTask,
        tasks,
        filter,
        tasksList,
        labeledTasksList: computed(() => {
            return toLabelValue(tasksList.value.list);
        }),
        pagination,
        statusMap: statusMapping,
        taskStatuses,
        getArchivedTasks,
        archivedTasksList,
        updateTask,
        updateOrders,
        deleteTaskById,
        getTaskStatuses,
        updateTaskStatus,
        updateTaskStatusOrders,
        createTaskStatus,
        deleteTaskStatus,
        getTasksList,
        toQueryParams,
        fromQueryParams,
        createTaskChecklistElement,
        updateTaskChecklistElement,
        deleteTaskChecklistElement,
    };
}
