<template lang="pug">
.flex.items-center.justify-between
    .flex.items-center
        img(src="getAvatar(workspaceMember)" v-if="getAvatar(workspaceMember)")
        gravatar(:str="renderTitle(workspaceMember)" v-else)
        .ml-3.font-bold(v-if="!hideTitle" :class="[theme === 'white' ? 'title-black' : 'title-white']") {{ renderTitle(workspaceMember) }}
    .status(v-if="!hideStatus") {{ renderStatus(user, workspaceMember) }}
</template>

<script>
export default {
    name: 'WorkspaceTitle',
    props: {
        workspaceMember: Object,
        user: Object,
        hideStatus: Boolean,
        hideTitle: Boolean,
        theme: {
            type: String,
            default: 'white'
        }
    },
    setup() {
        const renderTitle = (workspaceMember) => {
            return workspaceMember?.workspace?.title || 'Без названия';
        };
        const getAvatar = (workspaceMember) => {
            return workspaceMember?.workspace?.avatar;
        };
        const renderStatus = (user, workspaceMember) => {
            const userId = user.id;
            const workspaceOwnerId = workspaceMember?.workspace?.ownerId;
            if (userId === workspaceOwnerId) {
                return 'Вы владелец';
            }
            return 'Вы участник';
        };
        return {
            renderTitle,
            renderStatus,
            getAvatar,
        };
    }
};
</script>

<style lang="scss" scoped>
.status {
    font-size: 12px;
    color: #000000;
}
.title-black {
    color: #000000;
}
.title-white {
    color: #ffffff;
}
</style>