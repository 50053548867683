export const timeLogColumns = [
    {
        title: 'Дата создания',
        dataIndex: 'createdAt',
        key: 'createdAt',
        slots: {customRender: 'createdAt'},
        width: '10%'
    },
    {
        title: 'Длительность',
        dataIndex: 'durationHumanReadable',
        key: 'durationHumanReadable',
        width: '10%'
    },
    {
        title: 'Исполнитель',
        slots: {customRender: 'workspaceMember'},
        width: '8%'
    },
    {
        title: 'Задача',
        slots: {customRender: 'task'},
        width: '18%'
    },
    {
        title: 'Услуга',
        slots: {customRender: 'service'},
        width: '18%'
    },
    {
        title: 'Комментарий',
        dataIndex: 'comment',
        key: 'comment',
        width: '15%'

    },
    {
        title: 'Дата',
        dataIndex: 'startDate',
        key: 'startDate',
        slots: {customRender: 'startDate'},
        width: '10%'
    },
    {
        title: 'Тип',
        slots: {customRender: 'type'},
        width: '10%'
    },
    {
        title: '',
        slots: {customRender: 'action'},
        width: '1%'
    },
];
