import {createApp} from 'vue';
import Antd from 'ant-design-vue';
import {VueCookieNext} from 'vue-cookie-next';
import '@/assets/less/antd.less';
import '@/assets/scss/app.scss';
import App from './App.vue';
import router from './router';
import {useComponents} from '@/boot';
import VueClickAway from 'vue3-click-away';
import socketModule from '@/modules/socket';
import dialog from '@/plugins/dialog';

socketModule.init();
const app = createApp(App);
app.use(VueClickAway);
app.use(VueCookieNext);
app.use(router);
app.use(Antd);
app.use(dialog);
useComponents(app);
app.mount('#app');

