const sections = {
    tasks: {
        name: 'tasks',
        create: {
            name: 'create',
            default_me: true,
            hide_workspace_members: true,
        },
        filters: {
            name: 'filters',
            hide_workspace_members: true,
            hide_my_tasks: true,
            actual_services: true
        }
    },
    time_logs: {
        name: 'time_logs',
        create: {
            name: 'create',
            default_me: true,
            hide_workspace_members: true,
        }
    },
    reports: {
        name: 'reports',
        filters: {
            name: 'filters',
            default_me: true,
            hide_clients: true,
            hide_save: true,
            hide_workspace_members: true,
            hide_tabs: true
        }
    }
};

export function useRole(user) {
    const getRoutes = (routes, permissions = [], role) => {
        if (role?.key === 'admin') return routes;
        return routes.filter(x => {
            const condition = permissions.findIndex(permission => permission.section === x.section) !== -1;
            if (x.children) {
                x.children = x.children.filter(c => permissions.findIndex(permission => permission.section === c.section) !== -1);
            }
            return condition;
        });
    };
    const getDefaults = (section, action) => {
        switch (user?.role?.key) {
            case 'performer':
                const restrictions = {};
                const permissions = sections?.[section]?.[action];
                if (permissions?.default_me) {
                    restrictions['workspaceMember'] = {id: user.workspaceMemberId, fullName: user.fullName};
                }
                if (permissions?.hide_clients) {
                    restrictions['hide_clients'] = true;
                }
                if (permissions?.hide_save) {
                    restrictions['hide_save'] = true;
                }
                if (permissions?.hide_workspace_members) {
                    restrictions['hide_workspace_members'] = true;
                }
                if (permissions?.hide_tabs) {
                    restrictions['hide_tabs'] = true;
                }
                if (permissions?.hide_my_tasks) {
                    restrictions['hide_my_tasks'] = true;
                }
                if (permissions?.actual_services) {
                    restrictions['actual_services'] = true;
                }
                return restrictions;
        }
        return {};
    };
    return {
        getRoutes,
        sections,
        getDefaults,
    };
}
