<template lang="pug">
div(v-if="error") {{error}}
Suspense(v-else)
    template(#fallback)
        .loader
            icon(type="Loader")
    template(#default)
        slot
</template>

<script>
import {onErrorCaptured, ref} from 'vue';

export default {
    name: 'AsyncPage',
    setup() {
        const error = ref(null);
        onErrorCaptured(e => {
            error.value = e;
            return true;
        });
        return {error};
    }
};
</script>

<style lang="scss" scoped>
.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    height: 100%;
}
</style>