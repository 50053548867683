export const unpaidBillsColumns = [
    {
        title: 'Контрагент',
        slots: {customRender: 'customer'},
        width: '25%'
    },
    {
        title: 'Услуга',
        slots: {customRender: 'service'},
        width: '25%'
    },
    {
        title: 'Стоимость',
        slots: {customRender: 'cost'},
        width: '25%'
    },
    {
        title: 'Оплатить до',
        slots: {customRender: 'paymentDates'},
        width: '20%'
    },
];