<template lang="pug">
settings-directories-members-layout
    async-page
        groups-table
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import SettingsDirectoriesMembersLayout from '@/pages/Settings/Directories/Members/Layout';
import GroupsTable from '@/components/Directories/Members/Groups/Table';
export default {
    name: 'GroupsPage',
    components: {GroupsTable, SettingsDirectoriesMembersLayout, AsyncPage}
};
</script>

<style scoped>

</style>