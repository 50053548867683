import {httpInstance} from '@/modules/http_factory';
const getData = async (path, workspaceId, pagination, search) => {
    const response = await httpInstance(workspaceId).get(`/${path}`, {
        params: {
            ...pagination,
            workspaceId,
            search,
        }
    });
    return response.data;
};
const createEntity = async (path, project, workspaceId) => {
    const response = await httpInstance(workspaceId).post(`/${path}`, {
        ...project,
        workspaceId
    });
    return response.data;
};

const updateEntity = async (path, id, updateData, workspaceId) => {
    const response = await httpInstance(workspaceId).patch(`/${path}/${id}`, updateData);
    return response.data;
};
const updateOrders = async (path, orders, workspaceId) => {
    const response = await httpInstance(workspaceId).patch(`/${path}/orders`, {orders});
    return response.data;
};
const deleteEntity = async (path, id, workspaceId) => {
    const response = await httpInstance(workspaceId).delete(`/${path}/${id}`);
    return response.data;
};

export default {
    getData,
    createEntity,
    updateEntity,
    updateOrders,
    deleteEntity,
};