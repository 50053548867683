<template lang="pug">
async-page
    documents-table
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import DocumentsTable from '@/components/Documents/Table';
export default {
    name: 'DocumentsPage',
    components: {DocumentsTable, AsyncPage}
};
</script>

<style scoped>

</style>