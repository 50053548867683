<template lang="pug">
settings-layout
    async-page
        workspace-settings-card
</template>

<script>
import SettingsLayout from '@/pages/Settings/Layout';
import AsyncPage from '@/layouts/async-page';
import WorkspaceSettingsCard from '@/components/Workspace/Settings';
export default {
    name: 'WorkspaceSettings',
    components: {WorkspaceSettingsCard, AsyncPage, SettingsLayout},
};
</script>

<style scoped>

</style>