<template lang="pug">
hourly-reports-layout
    async-page
        hourly-reports
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import HourlyReports from '@/components/Analytics/HourlyReports';
import HourlyReportsLayout from '@/pages/Reports/Layout';
export default {
    name: 'ReportsPage',
    components: {HourlyReportsLayout, AsyncPage, HourlyReports}
};
</script>

<style scoped>

</style>