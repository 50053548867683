import {ref} from 'vue';
import {VueCookieNext} from 'vue-cookie-next';

import auth from '@/modules/auth';
import {apiRequest} from '@/modules/error_handler';
import socketModule from '@/modules/socket';
const userData = ref({});

export function useAuth(loading={value: false}) {

    const getUserByInvitationId = (invitationId) => {
        return apiRequest(async() => {
            return auth.getUserByInvitationId(invitationId);
        }, loading);
    };
    const acceptInvitation = (payload) => {
        return apiRequest(async() => {
            const result = await auth.acceptInvitation(payload);
            setAuth(result.data);
            return result;
        }, loading);
    };
    const setAuth = ({user, token, refreshToken}) => {
        userData.value = user;
        VueCookieNext.setCookie('token', token);
        socketModule.socketInstance().query.token = token;
        VueCookieNext.setCookie('refreshToken', refreshToken, {
            expire: '80d'
        });
        return userData.value;
    };
    const login = async (email, password) => {
        return apiRequest(async() => {
            const result = await auth.login(email, password);
            setAuth(result.data);
        }, loading);
    };
    const refresh = async () => {
        return apiRequest(async() => {
            const result = await auth.refresh(VueCookieNext.getCookie('refreshToken'));
            if (result.ok && result.token && result.refreshToken) {
                VueCookieNext.setCookie('token', result.token);
                VueCookieNext.setCookie('refreshToken', result.refreshToken, {
                    expire: '80d'
                });
            }
            return result;
        }, loading);
    };
    const register = async (registerData) => {
        return apiRequest(async() => {
            const result = await auth.register(registerData);
            setAuth(result.data);
        }, loading);
    };
    const me = async (workspaceId) => {
        if (Object.values(userData.value).length && userData.value.workspaceMemberId && userData.value.workspaceId == workspaceId) {
            return setAuth({
                user: userData.value,
                token: VueCookieNext.getCookie('token'),
                refreshToken: VueCookieNext.getCookie('refreshToken')
            });
        }
        loading.value = true;
        const result = await auth.getMe(workspaceId);
        loading.value = false;
        return setAuth({
            user: result.data,
            token: VueCookieNext.getCookie('token'),
            refreshToken: VueCookieNext.getCookie('refreshToken')
        });
    };
    const logout = () => {
        setAuth({user: {}, token: '', refreshToken: ''});
        localStorage.removeItem('workspaceId');
    };
    const restorePasswordRequest = (email) => {
        return apiRequest(async() => {
            return auth.restorePasswordRequest(email);
        }, loading);
    };
    const checkConfirmation = (confirmationId) => {
        return apiRequest(async() => {
            return auth.checkConfirmation(confirmationId);
        }, loading);
    };
    const restorePassword = (restorePasswordDto) => {
        return apiRequest(async() => {
            return auth.restorePassword(restorePasswordDto);
        }, loading);
    };
    const updateUserData = (updateData, workspaceId) => {
        return apiRequest(async() => {
            return auth.updateUserData(updateData, workspaceId);
        }, loading);
    };
    const changePassword = (oldPassword, password, checkPassword) => {
        return apiRequest(async() => {
            return auth.changePassword(oldPassword, password, checkPassword);
        }, loading);
    };
    return {
        refresh,
        login,
        register,
        me,
        logout,
        getUserByInvitationId,
        acceptInvitation,
        user: userData,
        restorePasswordRequest,
        checkConfirmation,
        restorePassword,
        updateUserData,
        changePassword
    };
}
