<template lang="pug">
settings-directories-members-layout
    async-page
        hr-sources-table
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import HrSourcesTable from '@/components/Directories/Members/HrSources/Table';
import SettingsDirectoriesMembersLayout from '@/pages/Settings/Directories/Members/Layout';
export default {
    name: 'HrSources',
    components: {SettingsDirectoriesMembersLayout, HrSourcesTable, AsyncPage}
};
</script>

<style scoped>

</style>