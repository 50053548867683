import {httpInstance} from '@/modules/http_factory';
import moment from 'moment';

const createWorkspace = async (title, description) => {
    return httpInstance().post('/workspaces', {
        title,
        description,
    });
};
const updateWorkspace = async (id, updateData) => {
    return httpInstance(id).patch(`/workspaces/${id}`, updateData);
};
const getWorkspaceById = async(id) => {
    const result = await httpInstance(id).get(`/workspaces/${id}`);
    return result.data;
};
const validateWorkspace = async (id) => {
    const response = await httpInstance(id).post('/workspaces/validate', {
        id
    });
    return response.data;
};
const getWorkspaceMemberById = async (workspaceId, workspaceMemberId) => {
    const response = await httpInstance(workspaceId).get(`/workspaces/members/${workspaceMemberId}`);
    return response.data;
};
const inviteUser = async (payload) => {
    const birthday = payload.birthday;
    const cooperationStartedAt = payload.cooperationStartedAt;
    const startWorkingAt = payload.startWorkingAt;
    const response = await httpInstance(payload.workspaceId).post('/workspaces/invite-user', {
        ...payload,
        birthday: birthday ? moment(birthday).toISOString() : undefined,
        cooperationStartedAt: cooperationStartedAt ? moment(cooperationStartedAt).toISOString() : undefined,
        startWorkingAt: startWorkingAt ? moment(startWorkingAt).toISOString() : undefined,
    });
    return response.data;
};

const updateWorkspaceMember = async(workspaceId, id, updateData) => {
    const response = await httpInstance(workspaceId).patch(`/workspaces/members/${id}`, updateData);
    return response.data;
};
const deleteWorkspaceMember = async(workspaceId, id) => {
    const response = await httpInstance(workspaceId).delete(`/workspaces/members/${id}`);
    return response.data;
};
const ping = async(workspaceId) => {
    const response = await httpInstance(workspaceId).post('/workspaces/ping');
    return response.data;
};
const formWorkspaceMembersFilter = (workspaceId, filter) => {
    return {
        workspaceId,
        gradeId: filter.gradeId,
        search: filter.search,
        status: filter.status && Array.isArray(filter.status) ? filter.status.join(',') : undefined,
        groups: filter.groups && Array.isArray(filter.groups) ? filter.groups.join(',') : undefined,
        rateFrom: filter.rate && filter.rate.from ? filter.rate.from : undefined,
        rateTo: filter.rate && filter.rate.to ? filter.rate.to : undefined,
        roleId: filter.roleId && filter.roleId !== 'all' ? filter.roleId : undefined,
        includeTasks: filter.includeTasks,
    };
};
const getWorkspaceMembers = async (workspaceId, pagination, filter={}) => {
    const response = await httpInstance(workspaceId).get('/workspaces/members', {
        params: {
            ...pagination,
            ...formWorkspaceMembersFilter(workspaceId, filter)
        }
    });
    return response.data;
};
const getWorkspaceMembersTabs = async (workspaceId, filter) => {
    const response = await httpInstance(workspaceId).get('/workspaces/tabs', {
        params: {
            ...formWorkspaceMembersFilter(workspaceId, filter)
        }
    });
    return response.data;
};
export default {
    ping,
    inviteUser,
    updateWorkspace,
    createWorkspace,
    getWorkspaceById,
    validateWorkspace,
    getWorkspaceMembers,
    deleteWorkspaceMember,
    updateWorkspaceMember,
    getWorkspaceMemberById,
    getWorkspaceMembersTabs
};