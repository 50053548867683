<template lang="pug">
.contacts-select.w-full
    a-select(
        :value="modelValue"
        style="width: 100%"
        :placeholder="placeholder"
        :filter-option="false"
        @change="onChange($event)"
        @search="onSearch($event)"
        show-search
        allow-clear
        :mode="mode"
        :disabled="disabled"
    )
        a-select-option(
            v-for="dataItem in dataList"
            :key="dataItem.id"
            :value="dataItem.id"
        )
            | {{ dataItem.title }}
</template>

<script>
import {useContact} from '@/composition/contact';
import {useWorkspace} from '@/composition/workspace';
import {computed, watch} from 'vue';
import {debounce} from '@/modules/utils';
export default {
    name: 'ContactsSelect',
    props: {
        modelValue: [Number, String],
        type: {
            type: String,
            validator: x => ['clients', 'contacts'].indexOf(x) !== -1
        },
        withCompanies: Boolean,
        placeholder: String,
        mode: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean
        }
    },
    emits: ['update:modelValue', 'fullObject'],
    setup(props, {emit}) {
        const {currentWorkspaceId} = useWorkspace();
        const {contacts, clients, getClients, getContacts, setFilter} = useContact();
        switch (props.type) {
            case 'clients':
                getClients(currentWorkspaceId.value, null, props.withCompanies);
                break;
            default:
                getContacts(currentWorkspaceId.value);
                break;
        }
        const dataList = computed(() => {
            switch (props.type) {
                case 'clients':
                    return clients.value.list;
                default:
                    return contacts.value.list;
            }
        });
        const onSearch = async (q) => {
            setFilter({search: q});
            switch (props.type) {
                case 'clients':
                    return debounce(() => getClients(currentWorkspaceId.value, q, props.withCompanies), 500)();
                default:
                    return debounce(() => getContacts(currentWorkspaceId.value, {}, props.additionalFilter || {}), 500)();
            }
        };
        const onChange = (data) => {
            emit('update:modelValue', data);
            emit('fullObject', dataList.value.find(x => x.id === data));
        };
        return {
            dataList,
            clients,
            onSearch,
            onChange,
        };
    }
};
</script>

<style scoped>

</style>