<template lang="pug">
.node-tree(:style="{marginLeft: depth ? '20px' : 0}")
    .flex.items-center.justify-between.mt-2.table-item(
        @click="showChildren = !showChildren"
        :class="{'cursor-pointer': node.children && node.children.length}"
    )
        .node-tree__title-wrapper
            .node-tree__collapse-icon(
                v-if="node.children && node.children.length"
            ) {{ showChildren ? '-' : '+' }}
            .node-tree__collapse-icon(v-else)
            .node-tree__title(:style="{fontWeight: !depth ? 'bold' : ''}")
                slot(name="title" :record="node")
                    div {{ node.title || 'Не указано' }}
        .node-tree {{ node.value }} {{ suffix }}
    .node-tree__children(v-show="showChildren")
        node-tree(
            v-for="elem in nodes"
            :node="elem"
            :nodes="elem.children"
            :depth="depth+1"
            :suffix="suffix"
        )
            template(#title="{record}")
                slot(name="title" :record="record")
                    div {{ elem.record || 'Не указано' }}
</template>

<script>
export default {
    name: 'NodeTree'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
import {ref} from 'vue';
/*===============INITIALIZE AREA===================*/
const props = defineProps({
    nodes: {
        type: Array,
    },
    node: {
        type: Object
    },
    depth: {
        type: Number
    },
    suffix: {
        type: String
    }
});
const showChildren = ref(false);
</script>

<style lang="scss">
.node-tree {
    &-children {
        display: flex;
        align-items: center;
    }
    &__collapse-icon {
        width: 12px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    &__title {
        &-wrapper {
            display: flex;
            align-items: center;
        }
    }
}
</style>
