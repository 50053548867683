<template lang="pug">
.tasks-select.w-full
    custom-select(
        :modelValue="modelValue"
        :title="placeholder"
        @update:modelValue="onChange($event)"
        :options="tasksList.list"
        :mode="mode"
        server-search
        @search="onSearch"
        :disabled="disabled"
        accessor="fullTitle"
    )
</template>

<script>
import {useWorkspace} from '@/composition/workspace';
import {debounce} from '@/modules/utils';
import {watch} from 'vue';
import {useKanban} from '@/composition/kanban';
import CustomSelect from '@/components/Generic/CustomSelect';
export default {
    name: 'TasksSelect',
    components: {
        CustomSelect
    },
    props: {
        modelValue: [Number, String],
        placeholder: {
            type: String,
            default: 'Задача'
        },
        mode: {
            type: String,
            default: 'single'
        },
        disabled: {
            type: Boolean
        }
    },
    emits: ['update:modelValue'],
    setup(props, {emit}) {
        const {currentWorkspaceId} = useWorkspace();
        const {tasksList, getTasksList} = useKanban();
        watch(() => props.modelValue, () => {
            if (props.modelValue) {
                onChange(props.modelValue);
            }
        });
        const onSearch = async (q) => {
            debounce(() => getTasksList(currentWorkspaceId.value, {search: q}), 500)();
        };
        const onChange = (id) => {
            emit('update:modelValue', id);
        };

        return {
            tasksList,
            onSearch,
            onChange,
        };
    }
};
</script>

<style scoped>

</style>