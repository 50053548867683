import {toClipboard} from '@soerenmartius/vue3-clipboard';
import {notification} from 'ant-design-vue';

export function useCopy() {
    const copy = (val, {message, description}={message: 'Успех!', description: 'Скопировано в буфер обмена'}) => {
        toClipboard(val);
        notification.success({
            message,
            description
        });
    };
    return {
        copy
    };
}