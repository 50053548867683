<template lang="pug">
.status-progress
    .status-progress__with-tooltip(
        v-if="tooltipText"
    )
        a-tooltip(
            placement="top"
        )
            a-progress(
                :percent="percent"
                :show-info="false"
                :style="style"
                :strokeColor="getStatusColor()"
            )

            template(#title)
                div {{ tooltipText }}
        .inline.ml-2(v-if="iconStatus !== 'Success'")
            icon.inline(:type="iconStatus" :tooltipText="iconText")
    .status-progress__without-tooltip(
        v-else
    )
        a-progress(
            :percent="percent"
            :show-info="false"
            :style="style"
            :strokeColor="getStatusColor()"
        )
        .inline.ml-2(v-if="iconStatus !== 'Success'")
            icon.inline(:type="iconStatus" :tooltipText="iconText")
</template>

<script>
export default {
    name: 'StatusProgress',
    props: {
        percent: {
            type: [Number, String],
            default: 0
        },
        style: {
            type: Object,
            default: () => ({
                width: '110px',
                display: 'block'
            })
        },
        iconStatus: {
            type: String,
        },
        tooltipText: {
            type: String,
        },
        iconText: {
            type: String,
        }
    },
    setup(props) {
        const getStatusColor = () => {
            if (props.percent == 0) return '#1c89fa';
            if (props.percent > 0 && props.percent < 100) return '#FAAD14';
            if (props.percent == 100) return '#28a745';
            if (props.percent > 100) return '#FF4D4F';
            return '#1c89fa';
        };
        return {
            getStatusColor
        };
    }
};
</script>

<style lang="scss" scoped>
.status-progress {
    width: 100%;
    &__with-tooltip {
        display: inline-flex;
        width: 100%;
    }
    &__without-tooltip {
        display: inline-flex;
        width: 100%;
    }
}
</style>
