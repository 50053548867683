import {ref, reactive} from 'vue';
import {apiRequest} from '@/modules/error_handler';
import financeModule from '@/modules/finance';
import {monthsLangMap, monthsMap} from '@/components/Analytics/IncomingsAndOutgoings/constants';

export function useFinance(loading={value: false}) {
    const stats = ref([]);
    const companies = ref([]);
    const chartData = ref({
        xaxis: [],
        series: []
    });
    const filter = reactive({
        year: null,
        groupBy: {
            value: 'month'
        },
        contactId: undefined,
        projectId: undefined,
        serviceId: undefined,
        workspaceMemberId: undefined,
        isScheduled: false,
    });
    const getChartData = async () => {
        return apiRequest(async () => {
            chartData.value = await financeModule.getChartData(filter);
            return chartData.value;
        }, loading);
    };
    const convertToChartData = (stats) => {
        const xaxis = Object.keys(monthsMap);
        const series = stats.filter(x => x.type === 'incoming' || x.type === 'outgo').map(x => {
            const data = xaxis.map(col => Math.abs(x[col].total));
            return {
                name: x.name,
                type: 'column',
                data
            };
        });
        const f = series[0].data;
        const s = series[1].data;
        const diff = f.map((num, index) => {
            return Math.abs(num - s[index]);
        });
        const diffSeries = {
            name: 'Разница',
            data: diff,
            type: 'line'
        };
        series.push(diffSeries);
        chartData.value = {
            xaxis: xaxis.map(x => monthsLangMap[x]),
            series
        };
    };
    const getStats = async () => {
        return apiRequest(async () => {
            stats.value = await financeModule.getStats(filter);
            convertToChartData(stats.value);
            return stats.value;
        }, loading);
    };
    const setFilter = (newData) => {
        filter.year = newData.year;
        filter.groupBy = newData.groupBy;
        filter.contactId = newData.contactId;
        filter.projectId = newData.projectId;
        filter.serviceId = newData.serviceId;
        filter.workspaceMemberId = newData.workspaceMemberId;
        filter.isScheduled = newData.isScheduled;
    };
    const getCompaniesBalances = async () => {
        return apiRequest(async () => {
            companies.value = await financeModule.getCompaniesBalances();
            return companies.value;
        }, loading);
    };
    const fundsFlow = ref([]);
    const getFundsFlow = async () => {
        fundsFlow.value = await financeModule.getFundsFlow(filter);
        return fundsFlow.value;
    };
    return {
        stats,
        filter,
        fundsFlow,
        companies,
        chartData,
        getChartData,
        getFundsFlow,
        getStats,
        setFilter,
        getCompaniesBalances,
    };
}
