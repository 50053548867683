<template lang="pug">
settings-directories-bills-layout
    async-page
        bill-status-table
</template>

<script>
import SettingsDirectoriesBillsLayout from '@/pages/Settings/Directories/Bills/Layout';
import AsyncPage from '@/layouts/async-page';
import BillStatusTable from '@/components/Directories/Bills/Status/Table';
export default {
    name: 'BillStatusesPage',
    components: {BillStatusTable, AsyncPage, SettingsDirectoriesBillsLayout}
};
</script>

<style scoped>

</style>