export const columns = [
    {
        title: 'Дата',
        dataIndex: 'date',
        key: 'date',
        slots: {customRender: 'date'},
        width: '10%'
    },
    {
        title: 'Стоимость',
        dataIndex: 'amount',
        key: 'amount',
        slots: {customRender: 'amount'},
        width: '10%'
    },
    {
        title: 'Статья',
        slots: {customRender: 'operation'},
        width: '15%'
    },
    {
        title: 'Назначение платежа',
        slots: {customRender: 'title'},
        width: '15%'
    },
    {
        title: 'Контрагент',
        slots: {customRender: 'contractor'},
        width: '25%'
    },
    {
        title: 'Счет',
        slots: {customRender: 'bankAccount'},
        width: '15%'
    },
    {
        title: '',
        slots: {customRender: 'actions'},
        width: '15%'
    },
];