<template lang="pug">
.member-common-data
    .member-common-data__content
        member-account(:member="workspaceMember" @update="onUpdate" :loading="loading")
        a-divider
        member-profile(:member="workspaceMember" @update="onUpdate" :loading="loading")
        a-divider
        member-role(:member="workspaceMember" :directories="directories" @update="onUpdate" :loading="loading")
        a-divider
        member-professional-data(:member="workspaceMember" :directories="directories" @update="onUpdate" :loading="loading")
        a-divider
        member-finance(:member="workspaceMember" @update="onUpdate" :loading="loading")
        a-divider
        member-payment-dates(:member="workspaceMember" @update="onUpdate" :loading="loading")
        a-divider
        member-payment-methods(:member="workspaceMember" :directories="directories" @update="onUpdate" :loading="loading")
        a-divider
        member-others-data(:member="workspaceMember" :directories="directories" @update="onUpdate" :loading="loading")
        a-divider
        member-system-data(:member="workspaceMember")
    .member-common-data__actions(v-if="!isMe()")
        card-title Действия
        a-popconfirm(
            v-if="workspaceMember.accessAllowed"
            title="Вы уверены, что хотите заблокировать пользователя?"
            ok-text="Да"
            cancel-text="Нет"
            @confirm="block"
        )
            a-button.block.mt-2 Заблокировать
        a-popconfirm(
            v-else
            title="Вы уверены, что хотите разблокировать пользователя?"
            ok-text="Да"
            cancel-text="Нет"
            @confirm="restore"
        )
            a-button.block.mt-2 Разблокировать
        a-popconfirm(
            title="Вы уверены, что хотите удалить пользователя?"
            ok-text="Да"
            cancel-text="Нет"
            @confirm="deleteFromWorkspace"
        )
            a-button.block.mt-2(type="danger") Удалить
</template>

<script>
import {useRoute, useRouter} from 'vue-router';
import {useWorkspace} from '@/composition/workspace';
import {ref} from 'vue';
import MemberAccount from '@/components/Workspace/Member/Common/Account';
import MemberProfile from '@/components/Workspace/Member/Common/Profile';
import MemberRole from '@/components/Workspace/Member/Common/Role';
import MemberProfessionalData from '@/components/Workspace/Member/Common/ProfessionalData';
import MemberFinance from '@/components/Workspace/Member/Common/Finance';
import MemberPaymentDates from '@/components/Workspace/Member/Common/PaymentDates';
import MemberWorkBasis from '@/components/Workspace/Member/Common/Documents';
import {useDirectory} from '@/composition/directory';
import MemberPaymentMethods from '@/components/Workspace/Member/Common/PaymentMethods';
import MemberOthersData from '@/components/Workspace/Member/Common/Others';
import {useAuth} from '@/composition/auth';
import MemberSystemData from '@/components/Workspace/Member/Common/SystemData';
import CardTitle from '@/components/Generic/Typography/CardTitle';
import MemberDocuments from '@/components/Workspace/Member/Common/Documents';

export default {
    name: 'MemberCommonData',
    components: {
        MemberDocuments,
        CardTitle,
        MemberSystemData,
        MemberOthersData,
        MemberPaymentMethods,
        MemberWorkBasis,
        MemberPaymentDates, MemberFinance, MemberProfessionalData, MemberRole, MemberProfile, MemberAccount
    },
    async setup() {
        const loading = ref(false);
        const route = useRoute();
        const router = useRouter();
        const {
            getWorkspaceMemberById,
            currentWorkspaceId,
            workspaceMember,
            updateWorkspaceMember,
            deleteWorkspaceMember,
        } = useWorkspace(loading);
        const workspaceMemberId = route.params.workspaceMemberId;
        const {user, me} = useAuth();
        const isMe = () => {
            return user.value.id === workspaceMember.value?.user?.id;
        };
        await getWorkspaceMemberById(currentWorkspaceId.value, workspaceMemberId);
        const {directories, getDirectories} = useDirectory(loading);
        await getDirectories(currentWorkspaceId.value, 'members');
        const onUpdate = async (updateData) => {
            loading.value = true;
            await updateWorkspaceMember(workspaceMemberId, updateData);
            await getWorkspaceMemberById(currentWorkspaceId.value, workspaceMemberId);
            if (isMe()) {
                await me(currentWorkspaceId.value);
            }
            loading.value = false;
        };
        const block = async () => {
            await onUpdate({accessAllowed: 0});
        };
        const restore = async () => {
            await onUpdate({accessAllowed: 1});
        };
        const deleteFromWorkspace = async () => {
            await deleteWorkspaceMember(workspaceMember.value.id);
            await router.push(`/${currentWorkspaceId.value}/members`);
        };
        return {
            workspaceMember,
            directories,
            onUpdate,
            loading,
            isMe,
            block,
            restore,
            deleteFromWorkspace,
        };
    }
};
</script>

<style lang="scss" scoped>
.member-common-data {
    display: grid;
    grid-template-columns: 1fr 400px;
    grid-gap: 20px;

    &__content {

    }

    &__actions {

    }
}
</style>