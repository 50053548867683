<template lang="pug">
.hourly-reports-layout
    a-tabs(v-model:activeKey="activeKey" @change="onChange" v-if="!hide_tabs")
        a-tab-pane(v-for="tab in tabs" :key="tab.key" :tab="tab.tab")
    slot
</template>

<script>
export default {
    name: 'HourlyReportsLayout'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/

import {ref, onMounted} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useWorkspace} from '@/composition/workspace';
import {useAuth} from '@/composition/auth';
import {useRole} from '@/composition/role';

/*===============INIT AREA===================*/
const router = useRouter();
const route = useRoute();
const {currentWorkspaceId} = useWorkspace();
const activeKey = ref(undefined);
const {user} = useAuth();
const {sections, getDefaults} = useRole(user.value);
const {hide_tabs} = getDefaults(sections.reports.name, sections.reports.filters.name);
const tabs = [
    {
        key: 'new',
        tab: 'Новый отчет'
    },
    {
        key: 'saved',
        tab: 'Сохраненные',
    }
];

/*===============CHANGES AREA===================*/

const onChange = (val) => {
    router.push(`/${currentWorkspaceId.value}/reports/hourly/${val}`);
};

/*===============HOOKS AREA===================*/

onMounted(async() => {
    const paths = route.path.split('/');
    activeKey.value = paths[paths.length - 1];
});
</script>

<style lang="scss">
</style>