<template lang="pug">
.member-system-data
    card-title Системная информация
    .editable-card__column.mt-2
        .flex.items-center Дата регистрации
        .flex.items-center {{ formatDate(member.createdAt) }}
    .editable-card__column.mt-2
        .flex.items-center Приглашен пользователем
        .flex.items-center {{ member.invitedBy ? member.invitedBy.fullName : 'Нет данных'}}
</template>

<script>
import {useDate} from '@/composition/date';

export default {
    name: 'MemberSystemData',
    props: {
        member: Object,
    },
    setup() {
        const {formatDate} = useDate();
        return {
            formatDate,
        };
    }
};
</script>

<style scoped>

</style>