<template lang="pug">
member-layout
    async-page
        member-common-data
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import MemberLayout from '@/pages/Members/Member/Layout';
import MemberCommonData from '@/components/Workspace/Member/Common';
export default {
    name: 'MemberCommon',
    components: {MemberCommonData, MemberLayout, AsyncPage}
};
</script>

<style scoped>

</style>