<template lang="pug">
.bill-main
    .editable-card__column.mt-2
        .flex.items-center Номер
        editable-cell(:initialValue="bill.number" @update="(e) => onUpdate('number', e)")
    .editable-card__column.mt-2
        .flex.items-center Тип
        div(:class="[bill.type === BillType.incoming ? 'success' : 'danger']") {{ BillTypeMap[bill.type] }}
    .editable-card__column.mt-2
        .flex.items-center Дата
        editable-cell(
            :initialValue="bill.date"
            @update="(e) => onUpdate('date', e)"
            emptyText="Не указано"
            type="date"
            :title="formatDate(bill.date)"
        )
    .editable-card__column.mt-2
        .flex.items-center Плательщик
        div {{ getTitle(bill, 'customer', ['title', 'fullName']) }}
    .editable-card__column.mt-2
        .flex.items-center Получатель
        div {{ getTitle(bill, 'performer', ['title', 'fullName']) }}
    .editable-card__column.mt-2
        .flex.items-center Банковский счет
        editable-cell(
            :initialValue="bill.bankAccountId"
            @update="(e) => onUpdate('bankAccountId', e)"
            emptyText="Не указано"
            type="select"
            :title="getTitle(bill, 'bankAccount')"
            :options="bankAccounts"
        )
    .editable-card__column.mt-2
        .flex.items-center Валюта
        div {{ currencyMap[bill.currency] }}
    .editable-card__column.mt-2
        .flex.items-center Оплатить до
        editable-cell(
            :initialValue="bill.payDateTo"
            @update="(e) => onUpdate('payDateTo', e)"
            emptyText="Не указано"
            type="date"
            :title="formatDate(bill.payDateTo)"
            :style="isOutdated(bill.payDateTo) ? {color: 'red'} : {}"
        )
    .editable-card__column.mt-2
        .flex.items-center Статус
        editable-cell(
            :initialValue="bill.statusId"
            @update="(e) => onUpdate('statusId', e)"
            emptyText="Не указано"
            type="select"
            :title="getTitle(bill, 'status')"
            :options="directories.billStatuses.list"
        )
    .editable-card__column.mt-2
        .flex.items-center Статус Оплаты
        editable-cell(
            :initialValue="bill.paymentStatusId"
            @update="(e) => onUpdate('paymentStatusId', e)"
            emptyText="Не указано"
            type="select"
            :title="getTitle(bill, 'paymentStatus')"
            :options="directories.billPaymentStatuses.list"
        )
    .editable-card__column.mt-2
        .flex.items-center Статус Просрочки
        div {{ getTitle(bill, 'dateStatus') }}
    .editable-card__column.mt-2
        .flex.items-center Ответственный
        editable-cell(
            :initialValue="bill.directorId"
            @update="(e) => onUpdate('directorId', e)"
            emptyText="Не указано"
            type="select"
            value-key="fullName"
            :title="getTitle(bill, 'director', 'fullName')"
            :options="members.list"
        )
</template>

<script>
import {computed} from 'vue';
import CardTitle from '@/components/Generic/Typography/CardTitle';
import EditableCell from '@/components/Generic/EditableCell';
import {useDate} from '@/composition/date';
import {getTitle} from '@/modules/utils';
import constants from '@/constants';
import {useDownload} from '@/composition/download';
import {useWorkspace} from '@/composition/workspace';
import Uploader from '@/components/Generic/Uploader';
import {BillType, BillTypeMap} from '@/components/Bills/constants';
export default {
    name: 'BillMain',
    components: {Uploader, EditableCell, CardTitle},
    props: {
        bill: Object,
        directories: Object,
    },
    emits: ['update'],
    setup(props, {emit}) {
        const {formatDate} = useDate();
        const {currentWorkspaceId, members} = useWorkspace();
        const bankAccounts = computed(() => {
            if (props.bill.type === BillType.incoming && props.bill.contact) {
                return props.directories.value.bankAccounts.list.filter(x => x.companyId === props.bill.contact.companyId);
            }
            return props.directories.bankAccounts.list;
        });
        const onUpdate = (key, value) => {
            const updateData = {[key]: value};
            if (key === 'bankAccountId') {
                const bankAccount = bankAccounts.value.find(x => x.id === value);
                if (bankAccount) {
                    updateData.currency = bankAccount.currency;
                }
            }
            emit('update', updateData);
        };
        const {download} = useDownload();
        const downloadWrapper = async(key) => {
            const {ok, result} = await download(currentWorkspaceId.value, key);
            if (ok) {
                window.open(result.url, '_blank');
            }
        };
        const onUpload = ({key}) => {
            onUpdate('documentUrl', key);
        };
        return {
            bankAccounts,
            currencyMap: constants.currencyMap,
            onUpdate,
            onUpload,
            getTitle,
            formatDate,
            downloadWrapper,
            members,
            BillTypeMap,
            BillType,
            ...useDate()
        };
    }
};
</script>

<style lang="scss" scoped>

</style>
