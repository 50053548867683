import {httpInstance} from '@/modules/http_factory';

const getMe = async(workspaceId) => {
    return httpInstance(workspaceId).get('/auth/me');
};

const login = async (email, password) => {
    return httpInstance().post('/auth/login', {
        username: email,
        password,
    });
};

const register = async (registerData) => {
    return httpInstance().post('/auth/signup', registerData);
};
const getUserByInvitationId = async (invitationId) => {
    const response = await httpInstance().get(`/workspaces/user/${invitationId}`);
    return response.data;
};
const acceptInvitation = async (payload) => {
    const {userId, ...data} = payload;
    return httpInstance().post(`/workspaces/user/${userId}`, data);
};
const restorePasswordRequest = async(email) => {
    const response = await httpInstance().post('/auth/restore-password-request', {email});
    return response.data;
};
const checkConfirmation = async(confirmationId) => {
    const response = await httpInstance().get(`/auth/check-confirmation/${confirmationId}`);
    return response.data;
};
const restorePassword = async(data) => {
    const response = await httpInstance().post('/auth/restore-password', data);
    return response.data;
};

const refresh = async(refreshToken) => {
    console.log('making refresh with token', refreshToken);
    const response = await httpInstance().post('/auth/refresh', {
        refreshToken
    });
    console.log('returned response from refresh', response.data);
    return response.data;
};
const updateUserData = async(updateData, workspaceId) => {
    const response = await httpInstance(workspaceId).patch('/workspaces/user', {
        workspaceId,
        ...updateData,
    });
    return response.data;
};
const changePassword = async(oldPassword, password, checkPassword) => {
    const response = await httpInstance().post('/auth/change-password', {
        oldPassword,
        password,
        checkPassword
    });
    return response.data;
};
export default {
    refresh,
    getMe,
    login,
    register,
    getUserByInvitationId,
    acceptInvitation,
    restorePasswordRequest,
    checkConfirmation,
    restorePassword,
    updateUserData,
    changePassword,
};
