<template lang="pug">
async-page
    projects-table
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import ProjectsTable from '@/components/Projects/Table';
export default {
    name: 'ProjectsPage',
    components: {ProjectsTable, AsyncPage}
};
</script>

<style scoped>

</style>