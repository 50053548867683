<template lang='pug'>
.incomings-and-outgoings-chart
    chart-wrapper(:loading='loading')
        column-chart(:data='chartData' type="line")
</template>

<script>
export default {
    name: 'IncomingsAndOutgoingsChart'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
import {ref} from 'vue';
import ChartWrapper from '@/components/Generic/Charts/ChartWrapper';
import ColumnChart from '@/components/Generic/Charts/ColumnChart';
/*===============INIT AREA===================*/
const props = defineProps({
    chartData: {
        type: Object,
    },
    loading: {
        type: Boolean
    }
});

</script>

<style lang='scss'>
</style>