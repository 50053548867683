<template lang="pug">
.projects-table
    a-button(type="primary" @click="setModalVisibility(true)" v-if="!fromIntegration") Добавить счет
    custom-table(
        :columns="columns"
        :data-source="data.list"
        draggable
        @sortOrderChanged="(e) => onSortOrderChanged(e, currentWorkspaceId)"
        :onRowClick="edit"
    )
        template(#title="{text, record}")
            .projects-table__title(v-if="record")
                editable-cell(:initialValue="record.title" @update="(e) => onUpdate(record.id, 'title', e)")
        template(#currency="{text, record}")
            div(v-if="record")
                editable-cell(
                    :initialValue="record.currency"
                    @update="(e) => onUpdate(record.id, 'currency', e)"
                    :title="getCurrencyText(record.currency)"
                    empty-text="Не указано"
                    :options="getCurrencyOptions()"
                    type="select"
                )
        template(#companyId="{text, record}")
            .projects-table__title(v-if="record")
                editable-cell(
                    :initialValue="record.companyId"
                    @update="(e) => onUpdate(record.id, 'companyId', e)"
                    empty-text="Не указано"
                    type="select"
                    :options="directories.companies.list"
                    :title="getTitle(record, 'company')"
                )
        template(#tax="{text, record}")
            .projects-table__title(v-if="record")
                editable-cell(
                    :initialValue="record.tax"
                    @update="(e) => onUpdate(record.id, 'tax', e)"
                    empty-text="Не указано"
                    type="number"
                )
        template(#action="{record}")
            a-dropdown(:trigger="['click']" @click.stop)
                .dots-wrapper
                    icon.cursor-pointer(type="Dots")
                template(#overlay)
                    a-menu
                        a-menu-item(key="0")
                            delete-with-confirm(:deleteFunc="() => onDelete(record.id)" btn-text="Удалить")
    a-button.mt-3(@click="next" v-if="data.next" :loading="loading") Показать еще
    a-modal(
        v-model:visible="showModal"
        title="Создать счет"
        :footer="null"
        @cancel="setModalVisibility(false)"
        :destroyOnClose="true"
    )
        create-bank-account-form(
            :workspaceId="currentWorkspaceId"
            @ok="onCreate"
            :directories="directories"
            :mode="currentMode"
            :defaultValue="editingValue"
            @update="onObjectUpdated"
            @fetchDirectories="onFetchDirectories"
        )
</template>

<script>
import {columns} from '../constants';
import {ref} from 'vue';
import {useWorkspace} from '@/composition/workspace';
import EditableCell from '@/components/Generic/EditableCell';
import {useCrud} from '@/composition/crud';
import CreateServiceSpecialMark from '@/components/Directories/Services/SpecialMarks/CreateForm';
import CreateDocumentStatusForm from '@/components/Directories/Documents/Statuses/CreateForm';
import CreateBankAccountForm from '@/components/Directories/Bills/BankAccount/CreateForm';
import {useDirectory} from '@/composition/directory';
import {getCurrencyOptions, getCurrencyText, getTitle} from '@/modules/utils';
import DeleteWithConfirm from '@/components/Generic/DeleteWithConfirm';
import CustomTable from '@/components/Generic/CustomTable';
import {useCreateOrUpdateModal} from '@/composition/create-update-modal';

export default {
    name: 'BankAccountTable',
    components: {
        CustomTable,
        DeleteWithConfirm,
        CreateBankAccountForm, CreateDocumentStatusForm, CreateServiceSpecialMark, EditableCell},
    props: {
        fromIntegration: Boolean
    },
    async setup() {
        const loading = ref(false);
        const {setModalVisibility, edit, editingValue, currentMode, showModal} = useCreateOrUpdateModal();
        const {getData, deleteEntity, updateEntity, data, pagination, onSortOrderChanged} = useCrud('bank-accounts');
        const {getDirectories, directories} = useDirectory(loading);
        const {currentWorkspaceId} = useWorkspace(loading);
        await getData(currentWorkspaceId.value);
        await getDirectories(currentWorkspaceId.value, 'bills');
        const onCreate = async () => {
            pagination.value.offset = 0;
            setModalVisibility(false);
            await getData(currentWorkspaceId.value);
        };
        const onDelete = async (id) => {
            pagination.value.offset = 0;
            await deleteEntity(id, currentWorkspaceId.value);
            await getData(currentWorkspaceId.value);
        };
        const onUpdate = async (id, key, value) => {
            await updateEntity(id, {[key]: value}, currentWorkspaceId.value);
            await getData(currentWorkspaceId.value);
        };
        const onObjectUpdated = async (payload) => {
            const {id, ...updateData} = payload;
            await updateEntity(id, updateData, currentWorkspaceId.value);
            await getData(currentWorkspaceId.value);
            setModalVisibility(false);
        };
        const onFetchDirectories = async () => {
            await getDirectories(currentWorkspaceId.value, 'bills');
        };
        const next = async () => {
            pagination.value.offset += pagination.value.limit;
            await getData(currentWorkspaceId.value, {fromPagination: true});
        };
        return {
            data,
            columns,
            loading,
            setModalVisibility,
            showModal,
            onCreate,
            currentWorkspaceId,
            next,
            onDelete,
            onUpdate,
            directories,
            getTitle,
            getCurrencyText,
            getCurrencyOptions,
            onSortOrderChanged,
            edit,
            currentMode,
            editingValue,
            onObjectUpdated,
            onFetchDirectories,
        };
    }
};
</script>

<style lang="scss" scoped>

</style>