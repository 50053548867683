<template lang="pug">
Suspense
    template(#fallback)
        a-select(style="width: 100%" :placeholder="placeholder")
    template(#default)
        slot
</template>

<script>
export default {
    name: 'SelectSuspense',
    props: {
        placeholder: {
            type: String,
            default: 'Loading'
        }
    }
};
</script>

<style scoped>

</style>
