import {ref} from 'vue';

export function useCreateOrUpdateModal() {
    const currentMode = ref('create');
    const editingValue = ref({});
    const showModal = ref(false);
    const setModalVisibility = bool => {
        showModal.value = bool;
        if (!bool) {
            currentMode.value = 'create';
            editingValue.value = {};
        }
    };
    const edit = (payload) => {
        currentMode.value = 'edit';
        editingValue.value = payload;
        setModalVisibility(true);
    };
    return {
        currentMode,
        editingValue,
        showModal,
        setModalVisibility,
        edit,
    };
}