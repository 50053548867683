import {reactive} from 'vue';
import {ProfitAndLossInterface} from '@/modules/profit-and-loss/entities/profit-and-loss.interface';
import {ProfitAndLossFilter} from '@/modules/profit-and-loss/filters/profit-and-loss.filter';
import {ApiRequestResultInterface} from '@/core/common/interface/api-request-result.interface';
import {apiRequest} from '@/modules/error_handler';
import profitAndLossModule from '@/modules/profit-and-loss';

export function useProfitAndLoss() {
    const profitAndLoss: ProfitAndLossInterface = reactive<ProfitAndLossInterface>({
        plan: {
            total: 0,
            expense: 0,
            tax: 0,
            taxPercent: 0,
            profit: 0,
            profitPercent: 0,
            performersExpense: 0,
        },
        fact: {
            total: 0,
            expense: 0,
            tax: 0,
            taxPercent: 0,
            profit: 0,
            profitPercent: 0,
            performersExpense: 0,
        },
        balance: {
            total: 0,
            expense: 0,
            tax: 0,
            taxPercent: 0,
            profit: 0,
            profitPercent: 0,
            performersExpense: 0,
        }
    });
    const filter: ProfitAndLossFilter = reactive<ProfitAndLossFilter>({
        period: null
    });
    const getProfitAndLoss = async (filter: ProfitAndLossFilter): Promise<ApiRequestResultInterface<ProfitAndLossInterface>> => {
        return apiRequest<ProfitAndLossInterface>(async () => {
            const response = await profitAndLossModule.getProfitAndLoss(filter);
            profitAndLoss.plan = response.plan;
            profitAndLoss.fact = response.fact;
            profitAndLoss.balance = response.balance;
            return response;
        });
    };
    const setFilter = (newFilter: ProfitAndLossFilter): void => {
        filter.period = newFilter.period;
    };
    return {
        filter,
        profitAndLoss,
        setFilter,
        getProfitAndLoss,
    };
}
