<template lang="pug">
.working-day-widget.widget-card
    .working-day-widget__settings(@click="setSettingsModalVisibility(true)")
        icon(type="Setting")
    card-title Рабочий график
    a-tabs(v-model:activeKey="selectedDay" @change="onSelectedDayChanged")
        a-tab-pane(v-for="(value, key) in days" :key="key" :tab="value.title")
    icon(type="Loader" v-if="loading")
    .working-day-widget__list(v-if="!loading")
        working-day-list-item(
            v-for="workingDayDataEl in workingDayData"
            :key="workingDayDataEl.id"
            :id="workingDayDataEl.id"
            :timing-in-seconds="workingDayDataEl.timingInSeconds"
            :active-task="workingDayDataEl.activeTask"
            :full-name="workingDayDataEl.fullName"
            :selected-day="selectedDay"
        )
a-modal(
    v-model:visible="showSettingsModal"
    title="Настройки виджета"
    :footer="null"
    @cancel="setSettingsModalVisibility(false)"
    :destroyOnClose="true"
)
    div
        card-title Список участников рабочего пространства
        div.my-3(v-for="(value, key) in widgetSettings")
            .flex.items-center
                a-checkbox(:checked="value" @change="onCheckboxChanged(key, $event)") {{ getMemberName(key) }}
        a-button(type="primary" @click="saveSettings") Сохранить
</template>

<script>
export default {
    name: 'WorkingDayWidget'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/

import {onBeforeUnmount, onMounted, ref} from 'vue';

import CardTitle from '@/components/Generic/Typography/CardTitle';
import {useWorkspace} from '@/composition/workspace';
import {useDashboard} from '@/composition/dashboard';
import WorkingDayListItem from '@/components/Dashboard/Widgets/WorkingDay/ListItem';
import Icon from '@/components/Generic/Typography/Icon';
import {WidgetKey} from '@/components/Dashboard/Widgets/constants';


/*===============INIT AREA===================*/
const timer = ref(null);
const loading = ref(false);
const {getWorkingDay, workingDayData, getDashboardSettings, updateDashboardSettings} = useDashboard(loading);
const {currentWorkspaceId, members} = useWorkspace(loading);
const days = {
    today: {
        key: 'today',
        title: 'Сегодня'
    },
    yesterday: {
        key: 'yesterday',
        title: 'Вчера'
    },
    currentWeek: {
        key: 'currentWeek',
        title: 'Текущая неделя'
    },
    currentMonth: {
        key: 'currentMonth',
        title: 'Текущий месяц'
    },
};

const selectedDay = ref(days.today.key);

/*===============FETCH DATA AREA===================*/

const fetchData = async (options={}) => {
    await getWorkingDay(currentWorkspaceId.value, selectedDay.value, options);
    if (!options.disableLoader) {
        await getSettings();
    }
};

/*===============SETTINGS AREA===================*/
const showSettingsModal = ref(false);
const widgetSettings = ref({});
const setSettingsModalVisibility = (bool) => {
    showSettingsModal.value = bool;
    if (bool) {
        getSettings();
    }
};
const getPatchedSettings = (settings) => {
    settings = settings || {};
    const defaults = {};
    for (const valueElement of members.value.list) {
        defaults[valueElement.id] = settings[valueElement.id] === undefined || !!settings[valueElement.id];
    }
    return defaults;
};
const getSettings = async () => {
    const {ok, result} = await getDashboardSettings(currentWorkspaceId.value, WidgetKey.working_day);
    if (ok) {
        widgetSettings.value = getPatchedSettings(result.settings);
    }
};
const updateSettings = async () => {
    await updateDashboardSettings(currentWorkspaceId.value, WidgetKey.working_day, widgetSettings.value);
    await getSettings();
    await fetchData();
};

const onCheckboxChanged = (id, e) => {
    widgetSettings.value[id] = e.target.checked;
};

const saveSettings = async () => {
    await updateSettings();
    setSettingsModalVisibility(false);
};
/*===============RENDERERS AREA===================*/
const getMemberName = (id) => {
    return members.value.list.find(x => x.id == id)?.fullName || 'Не указано';
};
/*===============HANDLERS AREA===================*/

const onSelectedDayChanged = async () => {
    await fetchData();
};

/*===============LIFECYCLE HOOKS AREA===================*/
onMounted(() => {
    timer.value = setInterval(() => {
        fetchData({disableLoader: true});
    }, 15000);
    fetchData();
});
onBeforeUnmount(() => {
    clearInterval(timer.value);
});
</script>

<style lang="scss">
.working-day-widget {
    width: 100%;
    height: auto;
    min-height: 580px;
    position: relative;
    &__settings {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
            transform: rotateZ(45deg);
        }
    }
}
</style>
