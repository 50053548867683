<template lang="pug">
.flex.items-center
    a(href="#" @click="goBack")
        icon(type="ArrowLeft")
</template>

<script>
import {useRouter} from 'vue-router';

export default {
    name: 'BackBtn',
    setup() {
        const router = useRouter();
        const goBack = (e) => {
            e.preventDefault();
            e.stopPropagation();
            router.back();
        };
        return {
            goBack
        };
    }
};
</script>

<style scoped>

</style>
