export const salaryListColumns = [
    {
        title: 'Наименование',
        dataIndex: 'title',
        key: 'title',
        slots: {customRender: 'title'},
        width: '20%'
    },
    {
        title: 'Тип оплаты',
        dataIndex: 'paymentType',
        key: 'paymentType',
        slots: {customRender: 'paymentType'},
        width: '8%'
    },
    {
        title: 'Ставка',
        dataIndex: 'rate',
        key: 'hourlyRate',
        slots: {customRender: 'rate'},
        width: '8%'
    },
    {
        title: 'Утилизация (план/факт/разница), ч.',
        dataIndex: 'utilization',
        key: 'utilization',
        slots: {customRender: 'utilization'},
        width: '20%'
    },
    {
        title: 'План, руб.',
        dataIndex: 'financePlan',
        key: 'financePlan',
        slots: {customRender: 'financePlan'},
        width: '12%'
    },
    {
        title: 'К оплате / Оплачено / Остаток, руб.',
        slots: {customRender: 'finance'},
        width: '20%'
    },
    {
        title: 'Cумма транзакций',
        slots: {customRender: 'actions'},
        width: '10%'
    },
];