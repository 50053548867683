<template lang="pug">
async-page
    time-logs-table
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import TimeLogsTable from '@/components/TimeLogs/Table';
export default {
    name: 'TimeLog',
    components: {TimeLogsTable, AsyncPage}
};
</script>

<style scoped>

</style>