<template lang="pug">
.main-layout.main-layout__collapsible
    the-sidebar
    .main-layout-content
        the-header
        slot
</template>

<script>
import TheSidebar from '@/components/Layouts/TheSidebar';
import TheHeader from '@/components/Layouts/TheHeader';
import {useLayout} from '@/composition/layout';
export default {
    name: 'MainLayout',
    components: {TheHeader, TheSidebar},
    setup() {
        const {sidebarWidth} = useLayout();
        return {
            sidebarWidth,
        };
    }
};
</script>

<style lang="scss">
.main-layout__collapsible {
    grid-template-columns:  v-bind(sidebarWidth) 1fr;
}
</style>