import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "calendar-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["CalendarEvents"], {
      "selected-day": $setup.selectedDay,
      onChange: $setup.onSelectedDayChanged,
      calendarSlots: $setup.calendarSlots.list,
      onEdit: $setup.onEditSlot,
      onFilter: $setup.onFilterChanged,
      filter: $setup.filter
    }, null, 8, ["selected-day", "calendarSlots", "filter"]),
    _createVNode(_component_a_drawer, {
      visible: $setup.showModal,
      title: "Добавить событие",
      onClose: _cache[0] || (_cache[0] = ($event: any) => ($setup.setModalVisibility(false))),
      closable: true,
      width: "40%",
      footer: null,
      destroyOnClose: true
    }, {
      default: _withCtx(() => [
        _createVNode($setup["CalendarSlotCreateForm"], {
          date: $setup.selectedDay,
          "default-value": $setup.editingValue,
          mode: $setup.currentMode,
          onCreate: $setup.onSlotCreated,
          onUpdate: $setup.onSlotUpdated,
          loading: $setup.loading,
          onDelete: $setup.onSlotDeleted
        }, null, 8, ["date", "default-value", "mode", "loading"])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}