import {httpInstance} from '@/modules/http_factory';
import axios from 'axios';
const getUploadUrl = async (extension, contentType, isPrivate) => {
    const response = await httpInstance().get('/cloud/upload-url', {
        params: {
            extension,
            contentType,
            isPrivate: isPrivate === true ? 1 : 0
        }
    });
    return response.data;
};

const getFileUrl = async (workspaceId, key) => {
    const response = await httpInstance(workspaceId).get('/cloud/file-url', {
        params: {
            key,
        }
    });
    return response.data;
};
const uploadFile = async (url, file, contentType, handler) => {
    const response = await axios.create({baseURL: url}).put(url, file, {
        headers: {
            'Content-Type': contentType
        },
        onUploadProgress: progressEvent => handler(progressEvent)
    });

    return response.data;
};

export default {
    getUploadUrl,
    getFileUrl,
    uploadFile,
};