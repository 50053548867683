<template lang="pug">
.flex.items-center
    a-input-number(
        :value="time.hours"
        placeholder="Часы"
        @change="onChange('hours', $event)"
        style="width: 100%"
        :max="23"
        :min="0"
        :disabled="disabled"
    )
    slot(name="hoursSuffix")
    a-input-number.ml-2(
        :value="time.minutes"
        placeholder="Минуты"
        @change="onChange('minutes', $event)"
        style="width: 100%"
        :max="59"
        :min="0"
        :disabled="disabled"
    )
    slot(name="minutesSuffix")
    a-input-number.ml-2(
        :value="time.seconds"
        placeholder="Секунды"
        @change="onChange('seconds', $event)"
        style="width: 100%"
        :max="59"
        :min="0"
        :disabled="disabled"
        v-if="!hideSeconds"
    )
    slot(name="secondsSuffix")
</template>

<script>
export default {
    name: 'TimeSelector'
};
</script>

<script setup>
/*=============DECLARING PROPS AREA=============*/

const props = defineProps({
    time: {
        type: Object,
        default: () => ({hours: undefined, minutes: undefined, seconds: undefined})
    },
    disabled: {
        type: Boolean
    },
    hideSeconds: {
        type: Boolean
    }
});

/*=============DECLARING EMITS AREA=============*/

const emit = defineEmits(['update:time']);

/*=============DECLARING CHANGES AREA=============*/
const onChange = (key, value) => {
    emit('update:time', {
        ...props.time,
        [key]: value
    });
};

</script>