<template lang="pug">
.transaction-period-select
    a-select(
        :value="period"
        @change="onChange"
        :labelInValue="true"
        :options="options"
        allow-clear
        style="width: 100%"
        placeholder="Период начисления"
        :disabled="disabled"
    )
</template>

<script>
export default {
    name: 'TransactionPeriodSelect'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
import {useDate} from '@/composition/date';

/*===============INIT AREA===================*/
const props = defineProps({
    period: {
        type: String,
    },
    disabled: {
        type: Boolean
    }
});
const emit = defineEmits(['update:period']);
const {getMonthFromNow} = useDate();
const options = [
    {
        label: getMonthFromNow().format('MMMM, YYYY'),
        value: getMonthFromNow().format('YYYY-MM-01')
    },
    {
        label: getMonthFromNow(-1).format('MMMM, YYYY'),
        value: getMonthFromNow(-1).format('YYYY-MM-01')
    },
    {
        label: getMonthFromNow(-2).format('MMMM, YYYY'),
        value: getMonthFromNow(-2).format('YYYY-MM-01')
    },
];
/*===============HANDLERS AREA===================*/
const onChange = (value) => {
    emit('update:period', value);
};
</script>

<style lang="scss">
</style>