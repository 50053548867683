<template lang="pug">
.modal-mask(v-if="visible")
    .modal-wrapper
        .modal-container
            div
                .modal-container__title {{ options.title }}
                .modal-container__description {{ options.description }}
                input.base-input(placeholder="Text" v-model="val" v-if="options.type === 'input'")
                .modal-container__radio-group(v-if="options.type === 'radio' && options.options && options.options.length")
                    .modal-container__radio(v-for="option in options.options")
                        input(
                            type="radio"
                            :id="option.id"
                            :value="option.id"
                            v-model="val"
                            name="dialog-radio-group"
                        )
                        label(:for="option.id" style="margin-left: 4px") {{ option.title }}
                .modal-container__actions
                    button.base-btn.base-btn--success(@click="ok") Да
                    button.base-btn.ml-2.base-btn--error(@click="cancel") Отмена
</template>

<script>
export default {
    name: 'Dialog'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
import {useDialog} from './dialog';

const {visible, show, ok, cancel, val, options} = useDialog();

</script>

<style lang="scss">
.modal-mask {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;
    overflow: auto;

}

.modal-wrapper {
    position: absolute;
    min-height: 100px;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    top: 5%;
    left: 0;
    right: 0;
    @media(max-width: 599px) {
        width: 98% !important;
        padding: 10px;
        left: 50%;
        margin-left: -49%;
    }

    .modal-close {
        position: absolute;
        right: -10px;
        top: -10px;
        cursor: pointer;

        img {
            width: 36px;
            height: 36px;
        }
    }
}

.modal-container {
    width: 639px;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 28px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
    position: relative;
    @media(max-width: 768px) {
        width: 96%;
    }
    &__actions {
        display: flex;
        align-items: center;
        margin-top: 12px;
        .base-btn {
            border: none;
            color: white;
            padding: 5px 12px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;
            margin: 4px 2px;
            cursor: pointer;
            &--success {
                background-color: #4CAF50;
            }
            &--error {
                background-color: #f44336;
            }
            &:hover {
                opacity: 0.6;
            }
        }
    }
    &__title {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 12px;
    }
    &__description {
        margin-bottom: 12px;
    }
    &__radio {
        margin-top: 8px;
        display: flex;
        align-items: center;
        label {
            cursor: pointer;
        }
    }

}


.modal-leave-active, .modal-enter {
    opacity: 0;
}

.modal-enter-from, .modal-leave-to {
    opacity: 0;
}
</style>