<template lang="pug">
.multiselect(@click.stop="openMultiselect" v-click-away="closeMultiselect" :class="{'disabled': disabled}")
    input.base-input(
        v-model="search"
        ref="searchInput"
        :placeholder="isSearching ? title : null"
        :disabled="disabled"
    )
    .multiselect-title(v-if="!isSearching")
        div {{ getTitle() }}
        .image-wrapper.cursor-pointer(
            :class="[show ? 'active' : '']"
            @mouseover="showClear=true"
            @mouseleave="showClear=false"
        )
            .ml-2(v-if="showClear" @click="clear")
                icon(type="Close")
            icon(type="ArrowDown" v-else)
    .multiselect-items(
        v-if="show && searchItems.length > 0"
        :style="{maxHeight: `${height}px`}"
    )
        .multiselect-item(
            v-if="firstItem"
            @click.stop="toggleSelect({id: firstItem.id})"
            :class="[ isItemSelected(firstItem.id) ? 'selected' : '']"
        )
            slot(name="first-item")
                .multiselect-item-title
        .multiselect-item(
            v-for="item in searchItems"
            :key="item.id"
            @click.stop="toggleSelect(item)"
            :class="[ isItemSelected(item.id) ? 'selected' : '']"
        )
            slot(name="title" :record="item")
                .multiselect-item-title {{ item[accessor]}}
            icon(type="Check" alt="" v-if="isItemSelected(item.id)")
    .multiselect-items(v-else-if="show && searchItems.length == 0")
        .empty-search {{ emptySearchProp || emptySearch }}
</template>
<script>
import {useSelect} from '@/composition/select';
import {onMounted, watch} from 'vue';
export default {
    name: 'CustomSelect',
    props: {
        options: Array,
        title: {
            type: String,
            default: 'Элемент'
        },
        modelValue: [Object, Array],
        accessor: {
            type: String,
            default: 'title'
        },
        emptySearchProp: {
            type: String,
        },
        mode: {
            type: String,
            default: 'single',
            validator: x => ['single', 'multiple'].indexOf(x) !== -1
        },
        serverSearch: Boolean,
        disabled: Boolean,
        height: {
            type: Number,
            default: 300
        },
        firstItem: {
            type: Object,
        }
    },
    emits: ['update:modelValue', 'blur', 'search'],
    setup(props, {emit, slots}) {
        const {
            show,
            counter,
            selected,
            search,
            isSearching,
            emptySearch,
            searchItems,
            openMultiselect,
            closeMultiselect,
            toggleSelect,
            isItemSelected,
            init,
            getTitle,
            clear,
            showClear,
            searchInput,
        } = useSelect(props.mode, props, emit);
        watch(() => [props.options, props.modelValue], () => {
            init();
        }, {deep: true});
        watch(() => search.value, () => {
            if (props.serverSearch) {
                emit('search', search.value);
            }
        });
        onMounted(() => {
            init();
        });
        const isSlotExists = (key) => {
            return !!slots[key];
        };
        return {
            searchInput,
            showClear,
            show,
            counter,
            selected,
            search,
            isSearching,
            emptySearch,
            searchItems,
            openMultiselect,
            closeMultiselect,
            isSlotExists,
            toggleSelect,
            isItemSelected,
            getTitle,
            clear,
        };
    }
};
</script>
<style lang="scss" scoped>
.multiselect {
    position: relative;
    color: rgba(0,0,0,0.87);
    width: 100%;
    font-size: 14px;
    &.disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
    &-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
        width: 100%;
        position: absolute;
        top: 7px;
        left: 1px;
    }
    &-input {
        height: 40px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        display: flex;
        align-items: center;
        transition: 0.3s;
        background: #fff;
        padding-left: 12px;
        color: rgba(0,0,0,0.87);
        outline: none;
        width: 100%;
        &:hover {
            border-color: #40a9ff;
        }
    }
    &-items {
        transform-origin: top;
        background: #fff;
        width: 100%;
        position: absolute;
        top: 40px;
        z-index: 9999;
        border-radius: 4px;
        overflow-y: auto;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        :first-child {
            border-radius: 4px 4px 0 0;
        }
        :last-child {
            border-radius: 0 0 4px 4px;
        }
    }
    &-item {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: rgba(0,0,0,0.87);
        cursor: pointer;
        transition: 0.3s;
        &:hover {
            background: #f8e5fe
        }
        &:active {
            background: #f8e5fe
        }
    }
}
.image-wrapper{
    transition: all 0.2s;
}
.active{
    transform: rotate(180deg);
}

.selected {
    background: #f4d7fb;
    // font-weight: bold;
}
.empty-search {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
}
.base-input {
    background: #F2F5F8;
    border-radius: 6px;
    font-family: Roboto;
    font-size: 16px;
    color: #242A2F;
    width: 100%;
    padding: 0 12px;
    outline: none;
    height: 34px;
    border: 1px solid transparent;
    &::placeholder {
        color: #bfbfbf;
        font-size: 14px;
    }
}
</style>
