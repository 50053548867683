<template lang="pug">
card-title Профиль
.invite-user-form.mt-2
    input-with-error(:error="avatar.error")
        template(#input)
            uploader(@upload="onUpload" :targetTypes="['jpg', 'jpeg', 'png']")
                a-button Загрузить фото
                a-avatar.ml-3(:src="`${mediaPrefix}/${avatar.value}`" :size="64")
    input-with-error(:error="telegram.error")
        template(#input)
            a-input(placeholder="Telegram" :value="telegram.value" @change="onChange('telegram', $event.target.value)")
    input-with-error(:error="phone.error")
        template(#input)
            a-input(placeholder="Телефон" :value="phone.value" @change="onChange('phone', $event.target.value)")
    input-with-error(:error="skype.error")
        template(#input)
            a-input(placeholder="Скайп" :value="skype.value" @change="onChange('skype', $event.target.value)")
</template>

<script>
import {selectFilterFunc} from '@/modules/utils';
import Uploader from '@/components/Generic/Uploader';

export default {
    name: 'ProfileForm',
    components: {Uploader},
    props: {
        avatar: Object,
        telegram: Object,
        phone: Object,
        skype: Object,
    },
    emits: [
        'update:avatar',
        'update:telegram',
        'update:phone',
        'update:skype'
    ],
    setup(props, {emit}) {
        const mediaPrefix = process.env.VUE_APP_MEDIA_PREFIX;
        const onChange = (key, value) => {
            emit(`update:${key}`, {value, error: undefined});
        };
        const onUpload = (data) => {
            onChange('avatar', data.key);
        };
        return {
            onChange,
            onUpload,
            selectFilterFunc,
            mediaPrefix,
        };
    }
};
</script>

<style scoped>

</style>