<template lang="pug">
project-layout
    async-page
        project-common-data
</template>

<script>
import ProjectLayout from '@/pages/Projects/Project/Layout';
import AsyncPage from '@/layouts/async-page';
import ProjectCommonData from '@/components/Projects/Common';
export default {
    name: 'ProjectCommonDataPage',
    components: {ProjectCommonData, AsyncPage, ProjectLayout}
};
</script>

<style scoped>

</style>