const columns = [
    {
        title: '',
        slots: {customRender: 'name'},
        width: '23%'
    },
    {
        title: 'Январь',
        isPeriod: true,
        slots: {customRender: 'january'},
        width: '10%'
    },
    {
        title: 'Февраль',
        isPeriod: true,
        slots: {customRender: 'february'},
        width: '10%'
    },
    {
        title: 'Март',
        isPeriod: true,
        slots: {customRender: 'march'},
        width: '10%'
    },
    {
        title: 'Апрель',
        isPeriod: true,
        slots: {customRender: 'april'},
        width: '10%'
    },
    {
        title: 'Май',
        isPeriod: true,
        slots: {customRender: 'may'},
        width: '10%'
    },
    {
        title: 'Июнь',
        isPeriod: true,
        slots: {customRender: 'june'},
        width: '10%'
    },
    {
        title: 'Июль',
        isPeriod: true,
        slots: {customRender: 'july'},
        width: '10%'
    },
    {
        title: 'Август',
        isPeriod: true,
        slots: {customRender: 'august'},
        width: '10%'
    },
    {
        title: 'Сентябрь',
        isPeriod: true,
        slots: {customRender: 'september'},
        width: '10%'
    },
    {
        title: 'Октябрь',
        isPeriod: true,
        slots: {customRender: 'october'},
        width: '10%'
    },
    {
        title: 'Ноябрь',
        isPeriod: true,
        slots: {customRender: 'november'},
        width: '10%'
    },
    {
        title: 'Декабрь',
        isPeriod: true,
        slots: {customRender: 'december'},
        width: '10%'
    },
    {
        title: 'Итого',
        slots: {customRender: 'sum'},
        width: '10%'
    }
];

const quarterColumns = [
    {
        title: '',
        slots: {customRender: 'name'},
        width: '25%'
    },
    {
        title: 'I',
        isPeriod: true,
        slots: {customRender: 'firstQuarter'},
        width: '20%'
    },
    {
        title: 'II',
        isPeriod: true,
        slots: {customRender: 'secondQuarter'},
        width: '20%'
    },
    {
        title: 'III',
        isPeriod: true,
        slots: {customRender: 'thirdQuarter'},
        width: '20%'
    },
    {
        title: 'IV',
        isPeriod: true,
        slots: {customRender: 'fourthQuarter'},
        width: '20%'
    },
    {
        title: 'Итого',
        slots: {customRender: 'sum'},
        width: '20%'
    }
];
export const getColumns = (groupBy) => {
    if (groupBy === 'month') {
        return columns;
    }
    return quarterColumns;
};

export const monthsMap = {
    january: 0,
    february: 1,
    march: 2,
    april: 3,
    may: 4,
    june: 5,
    july: 6,
    august: 7,
    september: 8,
    october: 9,
    november: 10,
    december: 11,
};

export const monthsLangMap = {
    january: 'Январь',
    february: 'Февраль',
    march: 'Март',
    april: 'Апрель',
    may: 'Май',
    june: 'Июнь',
    july: 'Июль',
    august: 'Август',
    september: 'Сентябрь',
    october: 'Октябрь',
    november: 'Ноябрь',
    december: 'Декабрь',
};

export const quartersMap = {
    firstQuarter: [0, 1, 2],
    secondQuarter: [3, 4, 5],
    thirdQuarter: [6, 7, 8],
    fourthQuarter: [9, 10, 11],
};