<template lang="pug">
async-page
    create-integration
</template>

<script>
import CreateIntegration from '@/components/Integrations/CreateIntegration';
import AsyncPage from '@/layouts/async-page';
import SettingsLayout from '@/pages/Settings/Layout';
export default {
    name: 'CreateIntegrationPage',
    components: {
        SettingsLayout,
        AsyncPage,
        CreateIntegration,
    }
};
</script>

<style scoped>

</style>