<template lang="pug">
async-page
    services-table
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import ServicesTable from '@/components/Services/Table';
export default {
    name: 'ServicesPage',
    components: {ServicesTable, AsyncPage}
};
</script>

<style scoped>

</style>
