<template lang="pug">
async-page
    transactions-table
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import TransactionsTable from '@/components/Transactions/Table';
export default {
    name: 'TransactionsPage',
    components: {TransactionsTable, AsyncPage}
};
</script>

<style scoped>

</style>