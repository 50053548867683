<template lang="pug">
service-layout
    async-page
        service-transactions-table
</template>

<script>
import ServiceLayout from '@/pages/Services/Service/Layout';
import AsyncPage from '@/layouts/async-page';
import ServiceTransactionsTable from '@/components/Services/Transactions/Table';
export default {
    name: 'ServiceTransactionsPage',
    components: {ServiceTransactionsTable, AsyncPage, ServiceLayout}
};
</script>

<style scoped>

</style>