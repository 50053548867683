export const columns = [
    {
        title: 'ФИО',
        dataIndex: 'fullName',
        key: 'fullName',
        slots: {customRender: 'fullName'},
        width: '15%'
    },
    {
        title: 'Группа',
        slots: {customRender: 'group'},
        width: '10%'

    },
    {
        title: 'План, ч/мес',
        dataIndex: 'hoursPayload',
        key: 'hoursPayload',
        width: '10%'
    },
    {
        title: 'Грейд',
        slots: {customRender: 'grade'},
        width: '10%'
    },
    {
        title: 'Ставка, ч',
        dataIndex: 'hourlyRate',
        key: 'hourlyRate',
        width: '10%'
    },
    {
        title: 'Навыки',
        slots: {customRender: 'skills'},
        width: '30%'
    },
    {
        title: 'Статус',
        slots: {customRender: 'status'},
        width: '10%'
    },
    {
        title: '',
        slots: {customRender: 'action'},
    },
];

export const memberStatus = {
    active: 'active',
    invited: 'invited',
    blocked: 'blocked'
};

export const memberStatusMap = {
    active: 'Активные',
    invited: 'Ожидание активации',
    blocked: 'Заблокированные'
};