export const columns = [
    {
        title: 'Клиент',
        key: 'client',
        slots: {customRender: 'client'},
        width: '15%',
    },
    {
        title: 'Тип оплаты',
        key: 'paymentType',
        slots: {customRender: 'paymentType'},
        width: '6%',
    },
    {
        title: 'Ставка в час',
        key: 'hourlyRate',
        slots: {customRender: 'hourlyRate'},
        width: '6%'
    },
    {
        title: 'Утилизация (план / факт / разница), ч',
        key: 'utilization',
        slots: {customRender: 'utilization'},
        width: '15%'
    },
    {
        title: 'План, руб.',
        key: 'financePlan',
        slots: {customRender: 'financePlan'},
        width: '10%'
    },
    {
        title: 'К оплате / Оплачено / Остаток, руб',
        key: 'finance',
        slots: {customRender: 'finance'},
        width: '15%'
    },
];