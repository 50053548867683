<template lang="pug">
.member-role
    card-title Роль
    editable-cell(
        :initialValue="member.roleId"
        @update="(e) => onUpdate('roleId', e)"
        :title="getRoleTitle()"
        :options="directories.roles.list"
        type="select"
    )
</template>

<script>
import CardTitle from '@/components/Generic/Typography/CardTitle';
import EditableCell from '@/components/Generic/EditableCell';
export default {
    name: 'MemberRole',
    components: {EditableCell, CardTitle},
    props: {
        member: Object,
        directories: Object
    },
    emits: ['update'],
    setup(props, {emit}) {
        const getRoleTitle = () => props.member?.role?.title || 'Не указано';
        const onUpdate = (key, value) => {
            emit('update', {[key]: value});
        };
        return {
            getRoleTitle,
            onUpdate
        };
    }
};
</script>

<style lang="scss" scoped>
.member-role {
    max-width: 600px;
}
</style>