import {ref} from 'vue';
export function useLoading() {
    const loading = ref<boolean>(false);
    const withLoader = async (asyncFunc: () => any): Promise<void> => {
        loading.value = true;
        await asyncFunc();
        loading.value = false;
    };
    return {
        loading,
        withLoader
    };
}