<template lang="pug">
card-title Основная информация
.create-form.mt-2
    input-with-error(:error="form.customerId.error")
        template(#input)
            a-select(
                v-model:value="form.customerId.value"
                placeholder="Плательщик"
                style="width:100%"
                allowClear
                show-search
                :filter-option="false"
                :label-in-value="false"
                :options="labeledCustomersList"
                @search="onCustomerSearch"
            )
    input-with-error(:error="form.performerId.error")
        template(#input)
            a-select(
                v-model:value="form.performerId.value"
                placeholder="Получатель"
                style="width:100%"
                allowClear
                show-search
                :filter-option="false"
                :label-in-value="false"
                :options="labeledPerformersList"
                @search="onPerformerSearch"
                @change="onPerformerChanged"
            )
    input-with-error(:error="form.bankAccountId.error" v-if="BillType.incoming === form.type.value")
        template(#input)
            custom-select(
                v-model="form.bankAccountId.value"
                title="Счет получателя"
                :options="bankAccounts"
                :disabled="!form.performerId.value"
            )
    input-with-error(:error="form.number.error")
        template(#input)
            a-input(placeholder="Номер счета" v-model:value="form.number.value" )
    input-with-error(:error="form.date.error")
        template(#input)
            a-date-picker(
                placeholder="Дата счета"
                v-model:value="form.date.value"
                format="DD.MM.YYYY"
                style="width: 100%"
            )
    input-with-error(:error="form.currency.error")
        template(#input)
            currency-select(v-model="form.currency.value" :disabled="true")
    input-with-error(:error="form.payDateTo.error")
        template(#input)
            a-date-picker(
                placeholder="Оплатить до"
                v-model:value="form.payDateTo.value"
                format="DD.MM.YYYY"
                style="width: 100%"
            )
    input-with-error(:error="form.directorId.error")
        template(#input)
            workspace-members-select(v-model="form.directorId.value" placeholder="Ответственный")
a-divider
div(v-if="form.customerId.value")
    card-title Услуги
    .create-form__services.mt-2
        input-with-error(:error="form.services.error")
            template(#input)
                bill-services(
                    v-model:services="form.services"
                    :directories="directories"
                    @log-data="setLogData"
                    :additionalServiceFilter="additionalServiceFilter"
                )

a-button.mt-3(@click="createBillWrapper" type="primary" :loading="loading" :disabled="disabled") {{ mode === 'edit' ? 'Сохранить' : 'Выставить счет' }}
</template>

<script>
import {computed, onMounted, reactive, ref, watch} from 'vue';
import {useFormErrors} from '@/composition/errors';
import {notification} from 'ant-design-vue';
import CardTitle from '@/components/Generic/Typography/CardTitle';
import {useDirectory} from '@/composition/directory';
import {selectFilterFunc, toLabelValue} from '@/modules/utils';
import {useWorkspace} from '@/composition/workspace';
import AddDirectory from '@/components/Generic/AddDirectory/AddDirectory';
import CreateContactSpecialMark from '@/components/Directories/Contacts/SpecialMarks/CreateForm';
import {useBill} from '@/composition/bill';
import InputWithError from '@/components/Generic/InputWithError';
import Uploader from '@/components/Generic/Uploader';
import CreateBillStatusForm from '@/components/Directories/Bills/Status/CreateForm';
import BillServices from '@/components/Bills/Services';
import CurrencySelect from '@/components/Generic/CurrencySelect';
import SelectSuspense from '@/components/Generic/SelectSuspense';
import ContactsSelect from '@/components/Contacts/Select/Contacts';
import {BillType, BillTypeMap} from '@/components/Bills/constants';
import CustomSelect from '@/components/Generic/CustomSelect';
import {useDate} from '@/composition/date';
import {useTimelog} from '@/composition/timelog';
import WorkspaceMembersSelect from '@/components/Workspace/Member/Select';
import {useAuth} from '@/composition/auth';
import {billForm} from '@/components/Bills/CreateForm/form';
import {useContactV2} from '@/composition/contact-v2';

export default {
    name: 'CreateBillForm',
    components: {
        WorkspaceMembersSelect,
        CustomSelect,
        ContactsSelect,
        SelectSuspense,
        CurrencySelect,
        BillServices,
        CreateBillStatusForm,
        Uploader,
        InputWithError,
        CreateContactSpecialMark,
        AddDirectory,
        CardTitle
    },
    props: {
        workspaceId: [String, Number],
        mode: String,
        defaultValue: Object,
        paymentPeriod: {
            type: Object,
            required: false
        }
    },
    emits: ['ok', 'update'],
    setup(props, {emit}) {
        const loading = ref(false);
        const {getDateFromNow, getDateRangeWithOffset} = useDate();
        const {getTimeLogsByServiceId} = useTimelog(loading);
        const {directories, getDirectories} = useDirectory(loading);
        const {
            clients: customers,
            getClients: getCustomers,
            getContactById,
        } = useContactV2(loading);
        const {
            companies: performers,
            getCompanies: getPerformers,
        } = useContactV2(loading);

        const labeledCustomersList = computed(() => {
            return toLabelValue(customers.value.list);
        });

        const onCustomerSearch = (q) => {
            getCustomers(props.workspaceId, q, false);
        };

        const labeledPerformersList = computed(() => {
            return toLabelValue(performers.value.list);
        });

        const onPerformerSearch = (q) => {
            getPerformers(props.workspaceId, q);
        };

        const {user} = useAuth();
        const workspaceMember = {
            id: user.value.workspaceMemberId,
            fullName: user.value.fullName
        };
        let form = reactive(billForm(props.defaultValue, workspaceMember, getDateFromNow));
        const resetForm = () => {
            const defaultForm = billForm(props.defaultValue, workspaceMember, getDateFromNow);
            for (const formKey in form) {
                if (formKey === 'type' || formKey === 'number') continue;
                form[formKey] = defaultForm[formKey];
            }
        };
        const clearErrors = () => {
            for (const formKey in form) {
                if (form[formKey]) {
                    form[formKey].error = undefined;
                }
            }
        };
        const {init} = useFormErrors();
        const {createBill, getDefaultNumber} = useBill(loading);
        const createBillWrapper = async () => {
            const contactData = {
                customerId: form.customerId.value,
                performerId: form.performerId.value,
                number: form.number.value,
                date: form.date.value,
                services: form.services.value.map(x => ({
                    serviceId: x.serviceId,
                    paymentPeriodId: x.paymentPeriod ? x.paymentPeriod.id : undefined,
                    items: x.items
                })),
                statusId: form.statusId.value,
                directorId: form.directorId.value?.id,
                type: form.type.value,
                bankAccountId: form.bankAccountId.value ? form.bankAccountId.value.id : undefined,
                generateDocument: form.generateDocument.value && form.type.value === BillType.incoming,
                payDateTo: form.payDateTo.value,
                currency: form.currency.value,
                documentUrl: form.documentUrl.value,
                workspaceId: props.workspaceId
            };
            if (props.mode === 'edit') {
                emit('update', {...contactData, id: props.defaultValue.id});
            } else {
                const {ok} = await init(form, async () => {
                    return createBill(props.workspaceId, contactData);
                }, clearErrors);
                if (ok) {
                    notification.success({
                        description: 'Счет создан',
                        message: 'Успех!'
                    });
                    emit('ok');
                }
            }
        };
        const disabled = computed(() => {
            return !form.number.value                   ||
                   !form.date.value                     ||
                   !form.customerId.value               ||
                   !(form.type.value === BillType.incoming
                       ? form.bankAccountId.value
                       : form.currency.value
                   )                                    ||
                   !form.performerId.value              ||
                   !form.payDateTo.value                ||
                   !form.type.value                     ||
                   !form.services.value.length          ||
                   !form.services.value?.[0]?.serviceId ||
                   form.services.value?.[0]?.items.some(x => !x.title || !x.amount || !x.unitId || !x.sum);
        });
        const bankAccounts = ref(directories.value.bankAccounts.list);
        const onPerformerChanged = async (contactId) => {
            if (!contactId) return false;
            const {ok, result: contact} = await getContactById(props.workspaceId, contactId);
            if (ok && contact) {
                bankAccounts.value = directories.value.bankAccounts.list.filter(x => x.companyId === contact.companyId);
                if (bankAccounts.value.length) {
                    form.bankAccountId.value = bankAccounts.value[0];
                } else {
                    form.bankAccountId.value = {};
                }
            }
            return true;
        };
        const additionalServiceFilter = computed(() => {
            const filter = {};
            if (form.customerId.value) {
                filter.clients = [form.customerId.value];
                return filter;
            }
            return filter;
        });
        const {getMembers, members, setFilter: setMemberFilter} = useWorkspace(loading);
        const onSearch = async(type, q) => {
            switch (type) {
                case 'member':
                    setMemberFilter({search: q});
                    return getMembers(props.workspaceId, {});
            }
        };
        onMounted(async () => {
            await getDirectories(props.workspaceId, 'contacts,bills');
            await getMembers(props.workspaceId);
            await getCustomers(props.workspaceId, undefined, false);
            await getPerformers(props.workspaceId);
            const {result} = await getDefaultNumber(props.workspaceId);
            if (result) {
                form.number.value = form.number.value || result;
            }
            if (props.mode !== 'edit') {
                const draftStatus = directories.value.billStatuses.list.find(x => x.key === 'draft');
                form.statusId.value = draftStatus?.id;
                const paymentPeriodContactId = props.paymentPeriod?.service?.contactId;
                if (paymentPeriodContactId) {
                    form.customerId.value = paymentPeriodContactId;
                    form.services.value = [{
                        id: Symbol(Date.now()),
                        items: [
                            {
                                id: Symbol(Date.now()),
                                title: undefined,
                                amount: undefined,
                                unitId: undefined,
                                sum: undefined,
                            }
                        ],
                        serviceId: props.paymentPeriod.service.id,
                        paymentPeriod: props.paymentPeriod,
                    }];
                    await setLogData({
                        from: props.paymentPeriod.from,
                        to: props.paymentPeriod.to,
                        serviceId: props.paymentPeriod.service.id,
                        paymentPeriodId: props.paymentPeriod.id,
                    });
                }
            }
        });
        const setLogData = async (filter) => {
            console.log('createForm', filter);
            const {ok, result} = await getTimeLogsByServiceId(props.workspaceId, filter.serviceId, filter.paymentPeriodId, form.type.value);
            if (ok && result.length) {
                form.services.value = form.services.value.map(x => {
                    if (x.serviceId === filter.serviceId) {
                        return {
                            ...x,
                            items: result
                        };
                    }
                    return x;
                });
            }
        };
        watch(() => form.bankAccountId.value, () => {
            const bankAccount = directories.value.bankAccounts.list.find(x => x.id === form.bankAccountId.value?.id);
            form.currency.value = bankAccount?.currency;
        });
        watch(() => form.type.value, () => {
            resetForm();
        });
        return {
            form,
            loading,
            members,
            disabled,
            BillType,
            BillTypeMap,
            directories,
            bankAccounts,
            labeledCustomersList,
            labeledPerformersList,
            additionalServiceFilter,
            onSearch,
            setLogData,
            selectFilterFunc,
            onCustomerSearch,
            onPerformerSearch,
            createBillWrapper,
            onPerformerChanged,
        };
    }
};
</script>

<style lang="scss" scoped>
.create-form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    &__services {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 15px;
    }
}
</style>
