export const columns = [
    {
        title: 'Дата',
        dataIndex: 'date',
        key: 'date',
        slots: {customRender: 'date'},
        width: '15%',
        showIn: ['*']
    },
    {
        title: 'Пользователь',
        dataIndex: 'workspaceMember',
        key: 'workspaceMember',
        slots: {customRender: 'workspaceMember'},
        width: '20%',
        showIn: ['service', 'task', 'days']
    },
    {
        title: 'Услуга',
        dataIndex: 'service',
        key: 'service',
        slots: {customRender: 'service'},
        width: '20%',
        showIn: ['performer', 'task', 'days']
    },
    {
        title: 'Задача',
        slots: {customRender: 'task'},
        width: '20%',
        showIn: ['performer', 'service', 'days']
    },
    {
        title: 'Комментарий',
        slots: {customRender: 'comment'},
        width: '25%',
        showIn: ['*']
    },
    {
        title: 'Длительность',
        slots: {customRender: 'duration'},
        width: '15%',
        showIn: ['*']
    },
];