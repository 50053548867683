import {arrayToStringTs, isUndefined, omitByTs} from '@/modules/utils/v2';
import {ServicePaymentPeriodForm} from '@/modules/services/form/service-payment-period.form';
import {CreateServicePaymentPeriodDto} from '@/modules/services/dto/create-service-payment-period.dto';
import {UpdateServicePaymentPeriodDto} from '@/modules/services/dto/update-service-payment-period.dto';
import {ServicePaymentPeriodFilter} from '@/modules/services/filters/service-payment-period.filter';
import {GetServicePaymentPeriodsDto} from '@/modules/services/dto/get-service-payment-periods.dto';
import {ServicePaymentPeriodStatus} from '@/components/Services/PaymentPeriod/constants';
import {ServicePaymentPeriodPerformerInterface} from '@/modules/services/entities/service-payment-period-performer.interface';
import {performerPaymentType} from '@/components/Services/constants';

export class ServicePaymentPeriodMapper {
    static toCreateDto(form: ServicePaymentPeriodForm): CreateServicePaymentPeriodDto {
        return {
            from: form.from || undefined,
            to: form.to || undefined,
            serviceId: form.serviceId || undefined,
            status: form.status || undefined,
            paymentType: form.paymentType || undefined,
            utilizationPlan: form.utilization,
            hourlyRate: form.hourlyRate || undefined,
            currency: form.currency || undefined,
            totalPlan: form.total || undefined,
            tax: form.tax,
            performers: form.performers?.map(x => ({
                paymentType: x.paymentType,
                utilizationPlan: x.utilization,
                hourlyRate: x.hourlyRate,
                percentRate: x.percentRate,
                currency: x.currency,
                totalPlan: x.total,
                workspaceMemberId: x.workspaceMember?.id,
            })) || [],
            expenses: form.expenses?.map(x => ({
                contactId: x.contact ? x.contact.id : null,
                title: x.title,
                amount: x.amount,
                currency: x.currency,
            })) || [],
            description: form.description,
        };
    }
    static toUpdateDto(form: ServicePaymentPeriodForm): UpdateServicePaymentPeriodDto {
        const updateDto: UpdateServicePaymentPeriodDto = omitByTs<UpdateServicePaymentPeriodDto>({
            from: form.from || undefined,
            to: form.to || undefined,
            status: form.status || undefined,
            paymentType: form.paymentType || undefined,
            utilizationPlan: form.status === ServicePaymentPeriodStatus.active ? form.utilization : undefined,
            utilizationFact: form.status !== ServicePaymentPeriodStatus.active ? form.utilization : undefined,
            hourlyRate: form.hourlyRate,
            currency: form.currency,
            tax: form.tax,
            totalPlan: form.status === ServicePaymentPeriodStatus.active ? form.total : undefined,
            totalFact: form.status !== ServicePaymentPeriodStatus.active ? form.total : undefined,
            performers: form.performers?.map(x => ({
                id: typeof x.id === 'symbol' ? undefined : x.id,
                paymentType: x.paymentType,
                utilizationPlan: form.status === ServicePaymentPeriodStatus.active ? x.utilization : undefined,
                utilizationFact: form.status !== ServicePaymentPeriodStatus.active ? x.utilization : undefined,
                hourlyRate: x.hourlyRate,
                percentRate: x.percentRate,
                currency: x.currency,
                totalPlan: form.status === ServicePaymentPeriodStatus.active ? x.total : undefined,
                totalFact: form.status !== ServicePaymentPeriodStatus.active ? x.total : undefined,
                workspaceMemberId: x.workspaceMember?.id,
            })) || undefined,
            expenses: form.expenses?.map(x => ({
                contactId: x.contact ? x.contact.id : null,
                title: x.title,
                amount: x.amount,
                currency: x.currency,
            })) || undefined,
            description: form.description
        }, (value: any) => !isUndefined(value));
        return updateDto;
    }

    static formFilter(servicePaymentPeriodFilter: ServicePaymentPeriodFilter): GetServicePaymentPeriodsDto {
        return {
            serviceId: servicePaymentPeriodFilter.serviceId,
            status: servicePaymentPeriodFilter.paymentPeriodStatus,
            search: servicePaymentPeriodFilter.search,
            performers: arrayToStringTs(servicePaymentPeriodFilter.performers, true),
            directors: arrayToStringTs(servicePaymentPeriodFilter.directors, true),
            deadline: servicePaymentPeriodFilter.deadline,
            from: servicePaymentPeriodFilter.from,
            contacts: arrayToStringTs(servicePaymentPeriodFilter.contacts, true),
            serviceGroupId: servicePaymentPeriodFilter.serviceGroup ? servicePaymentPeriodFilter.serviceGroup.id : undefined,
        };
    }
}
