<template lang="pug">
.services-summary
    services-finance-widget(
        :title="'Выручка'"
        :percent="getPercent(summary.fact.in, summary.estimation.in)"
        :total="summary.estimation.in"
        :paid="summary.fact.in"
        :percentOfExpectation="getPercentOfExpectation('in')"
        :isFinished="isFinished"
    )
    services-finance-widget(
        :title="'Расходы'"
        :percent="getPercent(summary.fact.out, summary.estimation.out)"
        :total="summary.estimation.out"
        :paid="summary.fact.out"
        :percentOfExpectation="getPercentOfExpectation('out')"
        :isFinished="isFinished"
    )
    services-finance-widget(
        :title="'Маржинальность'"
        :total="summary.estimation.margin.value"
        :totalSuffix="summary.estimation.margin.percent"
        :paid="summary.fact.margin.value"
        :percentOfExpectation="getPercentOfExpectation('margin')"
        :isFinished="isFinished"
        hide-remainder
        hide-paid
    )
</template>

<script>
import CardTitle from '@/components/Generic/Typography/CardTitle';
import {getPercent} from '@/modules/utils';
import ServicesFinanceWidget from '@/components/Services/Widgets/FinanceWidget';
export default {
    name: 'ServicesSummary',
    components: {ServicesFinanceWidget, CardTitle},
    props: {
        summary: {
            type: Object,
            default: () => ({estimation: 0, fact: 0})
        },
        isFinished: {
            type: Boolean,
            default: false,
        }
    },
    setup(props) {
        const getPercentOfExpectation = (type) => {
            switch (type) {
                case 'in':
                    return;
                case 'out':
                    return Number(100 * ((props?.summary?.fact?.out || 0) - (props?.summary?.estimation?.out || 0)) / (props?.summary?.estimation?.out || 1)).toFixed(0);
                case 'margin':
                    return Number(props?.summary?.fact?.margin?.percent || 0) - Number(props?.summary?.estimation?.margin?.percent || 0);
            }
        };
        return {
            getPercent,
            getPercentOfExpectation,
        };
    }
};
</script>

<style lang="scss" scoped>
.services-summary {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 15px;
    border: 1px solid #e6e6e6;
    &__column {
        width: 300px;
    }
}
</style>
