<template lang="pug">
settings-layout
    div
</template>

<script>
import SettingsLayout from '@/pages/Settings/Layout';
export default {
    name: 'NotificationsSettings',
    components: {SettingsLayout}
};
</script>

<style scoped>

</style>