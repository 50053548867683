import {ref} from 'vue';

import {apiRequest} from '@/modules/error_handler';
import dashboardModule from '@/modules/dashboard';

export function useDashboard(loading = {value: false}) {
    const workingDayData = ref([]);
    const unpaidBillsData = ref({
        amount: 0,
        count: 0,
        list: [],
    });
    const getWorkingDay = async (workspaceId, dayKey, options) => {
        const disableLoader = options && options.disableLoader;
        return apiRequest(async () => {
            workingDayData.value = await dashboardModule.getWorkingDay(workspaceId, dayKey);
            return workingDayData.value;
        }, disableLoader ? {value: false} : loading);
    };
    const getUnpaidBills = async (workspaceId,) => {
        return apiRequest(async () => {
            unpaidBillsData.value = await dashboardModule.getUnpaidBills(workspaceId);
            return unpaidBillsData.value;
        }, loading);
    };
    const updateDashboardSettings = async (workspaceId, widgetKey, settings) => {
        return apiRequest(async () => {
            return dashboardModule.updateDashboardSettings(workspaceId, widgetKey, settings);
        });
    };
    const getDashboardSettings = async (workspaceId, widgetKey) => {
        return apiRequest(async () => {
            return dashboardModule.getDashboardSettings(workspaceId, widgetKey);
        });
    };
    return {
        getWorkingDay,
        workingDayData,
        unpaidBillsData,
        getUnpaidBills,
        updateDashboardSettings,
        getDashboardSettings,
    };
}