<template lang="pug">
.salary-payments-list-create-position-form
    card-title {{ props.workspaceMember.fullName }}
    div
        .salary-payments-list-create-position-form__item.mt-3(
            v-for="position in data"
            :key="position.id"
        )
            a-radio-group(v-model:value="position.type")
                a-radio(value="service") Оплата по услуге
                a-radio(value="base") Другое
            .salary-payments-list-create-position-form__row.mt-2
                a-select.mr-3(
                    v-if="position.type === 'service'"
                    v-model:value="position.service"
                    label-in-value
                    :options="labeledServices"
                    placeholder="Услуга"
                    style="width: 220px"
                    :filter-option="false"
                    @search="onSearch($event)"
                    show-search
                    allow-clear
                )
                a-input.mr-3(v-model:value="position.base" placeholder="Основание выплаты" v-else style="width: 100%")
                a-input-number.mr-3(v-model:value="position.total" style="width: 100%" placeholder="Стоимость")
                currency-select(v-model="position.currency" style="width: 60px")
        a-button.mt-2(@click="addAnother" size="small") Еще
    a-button.mt-3(@click="create" type="primary" :disabled="disabled") Добавить
</template>

<script>
export default {
    name: 'SalaryPaymentsListCreatePositionForm'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
import {onMounted, ref, computed} from 'vue';
import CurrencySelect from '../../Generic/CurrencySelect/index.vue';
import CardTitle from '@/components/Generic/Typography/CardTitle';
import {useServiceV2} from '@/composition/service-v2';
import {fromLabelValue, toLabelValue} from '@/modules/utils';
/*===============INIT AREA===================*/
const props = defineProps({
    workspaceMember: {
        type: Object,
    },
    workspaceId: {
        type: [Number, String],
    }
});
const emit = defineEmits(['create']);
const {services, getServices, setFilter, getService} = useServiceV2();
const defaultItem = () => ({id: Symbol(Date.now()), type: 'service', total: null, currency: 'rub', service: undefined, base: undefined});
const data = ref([defaultItem('service')]);

/*===============HANDLERS AREA===================*/
const addAnother = () => {
    data.value = [...data.value, defaultItem()];
};
const create = async () => {
    const positions = await Promise.all(data.value.map(async x => {
        if (x.service) {
            const {ok, result: service} = await getService(x.service.value, props.workspaceId);
            if (ok && service) {
                const servicePerformer = service.performers.find(x => x.workspaceMemberId === props.workspaceMember.id);
                x.service = {
                    id: service.id,
                    type: service.type,
                    title: service.title,
                    hourlyRate: servicePerformer?.hourlyRate,
                };
            } else {
                x.service = fromLabelValue(x.service);
            }
        }
        return x;
    }));
    emit('create', {workspaceMemberId: props.workspaceMember.id, positions});
};
/*===============FORMATTERS AREA===================*/
const labeledServices = computed(() =>
    toLabelValue(services.value.list)
        .filter(x => data.value.findIndex(r => r?.service?.value === x.value) === -1)
);
/*===============COMPUTED AREA===================*/
const disabled = computed(() => {
    return data.value.some(x => {
        return !x.total || !x.currency || !(x.base || x.service);
    });
});
/*===============FETCH DATA AREA===================*/
const baseFilter = {performers: [{id: props.workspaceMember.id}]};
const onSearch = (q) => {
    setFilter({...baseFilter, search: q});
    fetchData();
};
const fetchData = async () => {
    await getServices(props.workspaceId);
};
onMounted(async() => {
    setFilter({...baseFilter});
    await fetchData();
});
</script>

<style scoped lang="scss">
.salary-payments-list-create-position-form {
    &__row {
        display: flex;
        align-items: center;
    }
}
</style>