export const columns = [
    {
        title: 'Наименование',
        dataIndex: 'title',
        key: 'title',
        slots: {customRender: 'title'},
        width: '90%'
    },
    {
        title: '',
        slots: {customRender: 'action'},
    },
];

export const defaultRole = {
    admin: 'admin'
};