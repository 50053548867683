<template lang="pug">
.page.workspace-wrapper
    a-tabs(v-model:activeKey="activeKey" @change="onChange")
        a-tab-pane(v-for="tab in tabs" :key="tab.key" :tab="tab.tab")
    slot
    .mt-3(v-for="(value, key) in steps")
        .font-bold(v-if="!value") {{ stepsMessageMap[key] }}
    a-button.my-3(
        @click="startSyncWrapper"
        type="primary"
        :loading="loading"
        :disabled="syncDisabled"
    ) Синхронизировать
    div {{ integrationProgress }}
</template>

<script>
import {computed, onMounted, ref, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useWorkspace} from '@/composition/workspace';
import AsyncPage from '@/layouts/async-page';
import {useIntegration} from '@/composition/integration';
import {useCopy} from '@/composition/copy';
import {useSocket} from '@/composition/socket';

export default {
    name: 'IntegrationLayout',
    components: {AsyncPage},
    setup() {
        const integrationProgress = ref('');
        const integrationProgressPercent = ref(0);
        const loading = ref(false);
        const activeKey = ref(undefined);
        const {currentWorkspaceId} = useWorkspace();
        const {copy} = useCopy();
        const {startSync, getIntegration, integration, getSteps, steps} = useIntegration(loading);
        const {subscribe} = useSocket();
        const router = useRouter();
        const route = useRoute();
        const tabs = [
            {
                key: 'projects',
                tab: 'Проекты'
            },
            {
                key: 'members',
                tab: 'Пользователи'
            },
        ];
        const stepsMessageMap = {
            users: 'Свяжите пользователей',
            projects: 'Свяжите проекты',
            statuses: 'Свяжите статусы проектов'
        };
        const onChange = (val) => {
            router.push(`/${currentWorkspaceId.value}/settings/integrations/${route.params.integrationId}/${val}`);
        };
        const syncDisabled = computed(() => {
            return !steps.value.users || !steps.value.projects || !steps.value.statuses;
        });
        const startSyncWrapper = async() => {
            await startSync(currentWorkspaceId.value, Number(route.params.integrationId));
            integrationProgress.value = '';
        };
        watch(steps.value, (v) => {
            console.log(v);
        });
        onMounted(async() => {
            const paths = route.path.split('/');
            activeKey.value = paths[paths.length - 1];
            await getIntegration(currentWorkspaceId.value, route.params.integrationId);
            await getSteps(currentWorkspaceId.value, route.params.integrationId);
            subscribe('integration_progress', (data) => {
                integrationProgress.value = data.message;
            });
        });
        return {
            activeKey,
            onChange,
            tabs,
            startSyncWrapper,
            workspaceId: currentWorkspaceId.value,
            integrationId: Number(route.params.integrationId),
            loading,
            integration,
            copy,
            steps,
            syncDisabled,
            stepsMessageMap,
            integrationProgress,
            integrationProgressPercent,
        };
    }
};
</script>

<style scoped>

</style>