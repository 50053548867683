<template lang="pug">
router-link(:to="renderActualRoute()")
    a-button(type="primary") Добавить интеграцию
        template(#icon)
            icon.inline(type="Plus")
.integrations-list
    router-link.integrations-list__item.mt-3(
        v-for="integration in list"
        :key="integration.id"
        :to="renderIntegrationRoute(integration.id)"
    )
        .flex.items-center.justify-between.w-full
            div {{ integration.host }}
            .integrations-list__delete
                icon(type="Delete")
</template>

<script>
import {useWorkspace} from '@/composition/workspace';
import {useIntegration} from '@/composition/integration';
import {ref} from 'vue';
export default {
    name: 'IntegrationsList',
    async setup() {
        const loading = ref(false);
        const {currentWorkspaceId} = useWorkspace();
        const renderActualRoute = () => {
            return `/${currentWorkspaceId.value}/settings/integrations/add-integration`;
        };
        const renderIntegrationRoute = (integrationId) => {
            return `/${currentWorkspaceId.value}/settings/integrations/${integrationId}/projects`;
        };
        const {list, getIntegrationsList} = useIntegration(loading);
        await getIntegrationsList(currentWorkspaceId.value);

        return {
            renderActualRoute,
            renderIntegrationRoute,
            list
        };
    }
};
</script>

<style lang="scss" scoped>
.integrations-list {
    width: 400px;
    &__item {
        padding: 0 8px;
        background-color: #fff;
        border-radius: 8px;
        border: 1px solid #e6e6e6;
        cursor: pointer;
        display: flex;
        align-items: center;
        min-height: 20px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-size: 16px;
        font-weight: 500;
        color: #1f2533;
        height: 42px;
    }
}
</style>