<template lang="pug">
settings-directories-companies-layout
    async-page
        company-table
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import SettingsDirectoriesCompaniesLayout from '@/pages/Settings/Companies/Layout';
import CompanyTable from '@/components/Directories/Bills/Company/Table';
export default {
    name: 'MyCompaniesPage',
    components: {CompanyTable, SettingsDirectoriesCompaniesLayout, AsyncPage}
};
</script>

<style scoped>

</style>
