export const columns = [
    {
        title: 'Контрагент',
        key: 'contact',
        slots: {customRender: 'contact'},
        width: '10%',
    },
    {
        title: 'Назначение',
        key: 'title',
        width: '6%',
    },
    {
        title: 'Стоимость',
        key: 'amount',
        slots: {customRender: 'amount'},
        width: '6%',
        summarize: true,
    },
    // {
    //     title: 'Финансы (план / факт / разница), руб',
    //     key: 'finance',
    //     slots: {customRender: 'finance'},
    //     width: '15%'
    // },
];