<template lang="pug">
.integration-auth
    card-title Авторизация
    .integration-auth__form
        input-with-error(:error="form.accessToken.error")
            template(#label)
                .input__label Персональный токен
            template(#input)
                a-input(placeholder="Персональный токен" v-model:value="form.accessToken.value")
        input-with-error.mt-3(:error="form.host.error")
            template(#label)
                .input__label Хост
            template(#input)
                a-input(placeholder="Хост" v-model:value="form.host.value")
        a-button.mt-3(@click="next" :loading="loading") Далее
</template>

<script>
import CardTitle from '@/components/Generic/Typography/CardTitle';
import {reactive, ref} from 'vue';
import {useIntegration} from '@/composition/integration';
import {useWorkspace} from '@/composition/workspace';
import {useFormErrors} from '@/composition/errors';
import router from '@/router';
export default {
    name: 'IntegrationAuth',
    components: {CardTitle},
    props: {
        provider: String
    },
    setup(props) {
        const loading = ref(false);
        const {authorize} = useIntegration(loading);
        const {currentWorkspaceId} = useWorkspace(loading);
        const form = reactive({
            accessToken: {
                value: '',
                error: ''
            },
            host: {
                value: '',
                error: ''
            }
        });
        const clearErrors = () => {
            form.accessToken.error = '';
            form.host.error = '';
        };
        const {init} = useFormErrors();
        const next = async () => {
            const {ok, result} = await init(form, async () => {
                const payload = {
                    accessToken: form.accessToken.value,
                    host: form.host.value,
                    provider: props.provider
                };
                return authorize(currentWorkspaceId.value, payload);
            }, clearErrors);
            if (ok) {
                await router.push(`/${currentWorkspaceId.value}/settings/integrations/${result.id}/projects`);
            }
        };
        return {
            form,
            loading,
            next
        };
    }
};
</script>

<style lang="scss" scoped>
.integration-auth {
    &__form {
        width: 400px;
        margin: 20px 0;
    }
}
</style>