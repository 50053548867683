import {useAuth} from '@/composition/auth';
import moment from 'moment-timezone';
import constants from '@/constants';
import {useTimelog} from '@/composition/timelog';

export async function logout(from, to, next) {
    const {logout} = useAuth();
    logout();
    next('/login');
}

function validateWorkspace(id, me) {
    return {
        ok: id == me.workspaceId,
    };
}

export async function auth (to, from, next){
    try {
        const {me} = useAuth();
        const {getDailyTotal} = useTimelog();
        const user = await me(to.params.id);
        moment.tz.setDefault(user.tz || constants.tz.default);
        moment.locale('ru');
        if (to.params.id) {
            const {ok} = validateWorkspace(to.params.id, user);
            if (!ok) {
                next('/select-workspace');
            }
            getDailyTotal(to.params.id);
        }
        if (user) {
            if (user.workspaces && user.workspaces.length) {
                return next();
            } else {
                if (to.path === '/create-workspace' || from.path === '/create-workspace') return next();
                return next('/create-workspace');
            }
        } else if (from.path !== '/login' && to.path !== '/login') {
            return next('/login');
        }
    } catch (e) {
        console.log(e);
        return next('/login');
    }

}

export async function notAuth(to, from, next) {
    try {
        const {me} = useAuth();
        // if (to.path === '/login' && from.path === '/') return next();
        const user = await me();
        if (user) {
            return next('/');
        } else {
            return next();
        }
    } catch (e) {
        console.log(e);
        console.log(to);
        return next();
    }

}
