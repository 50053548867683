<template lang="pug">
.tasks-select.w-full
    a-select(
        :value="modelValue"
        style="width: 100%"
        :placeholder="placeholder"
        :filter-option="false"
        @change="onChange($event)"
        show-search
        allow-clear
        :mode="mode"
        @blur="onBlur"
    )
        a-select-option(
            v-for="status in taskStatuses"
            :key="status.id"
            :value="status.id"
        )
            | {{ status.title }}
</template>

<script>
import {watch} from 'vue';
import {useKanban} from '@/composition/kanban';
export default {
    name: 'TaskStatusesSelect',
    props: {
        modelValue: [Number, String],
        placeholder: {
            type: String,
            default: 'Статус'
        },
        mode: {
            type: String,
            default: ''
        }
    },
    emits: ['update:modelValue', 'blur'],
    setup(props, {emit}) {
        const {taskStatuses} = useKanban();
        watch(() => props.modelValue, () => {
            if (props.modelValue) {
                onChange(props.modelValue);
            }
        });
        const onChange = (id) => {
            emit('update:modelValue', id);
        };
        const onBlur = (e) => {
            emit('blur', e);
        };

        return {
            taskStatuses,
            onBlur,
            onChange,
        };
    }
};
</script>

<style scoped>

</style>