<template lang="pug">
.finance-balance-company-bank-accounts-list
    .finance-balance-company-bank-accounts-list__item(
        v-for="bankAccount in bankAccounts"
        :key="bankAccount.id"
    )
        .finance-balance-company-bank-accounts-list__item-title {{ bankAccount.title }}
        .finance-balance-company-bank-accounts-list__item-total-balance {{ formatCurrency(bankAccount.totalBalance) }} руб.
</template>

<script>
export default {
    name: 'FinanceBalanceCompanyBankAccountsList'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
import {formatCurrency} from '@/modules/utils';
/*===============INIT AREA===================*/
const props = defineProps({
    bankAccounts: {
        type: Array,
        required: true
    }
});
</script>

<style lang="scss">

.finance-balance-company-bank-accounts-list {
    &__item {
        display: grid;
        grid-template-columns: 300px 300px;
    }
}
</style>