<template lang="pug">
.card-title(:class="[size ? size : '']")
    slot
</template>

<script>
export default {
    name: 'CardTitle',
    props: {
        size: String,
    }
};
</script>

<style lang="scss" scoped>
.card-title {
    color: rgba(0,0,0,.85);
    font-size: 18px;
    font-weight: bold;
    &.small {
        font-size: 14px;
    }
}
</style>