<template lang="pug">
async-page
    contacts-table
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import ContactsTable from '@/components/Contacts/Table';
export default {
    name: 'ContactsPage',
    components: {ContactsTable, AsyncPage}
};
</script>

<style scoped>

</style>