<template lang="pug">
.document-card
    .document-card__content
        card-title {{ getCardTitle() }}
        .editable-card__column.mt-3
            .flex.items-center Тип
            div {{ getTitle(document, 'documentType') }}
            .flex.items-center Номер
            editable-cell(
                :initialValue="document.number"
                @update="(e) => onUpdate('number', e)"
                emptyText="Не указано"
            )
            .flex.items-center Дата
            editable-cell(
                :initialValue="document.date"
                @update="(e) => onDateUpdate('date', e)"
                emptyText="Не указано"
                type="date"
                :title="formatDate(document.date)"
            )
            .flex.items-center Заказчик
            div {{ getTitle(document, 'customer', ['title']) }}
            .flex.items-center Исполнитель
            div {{ getTitle(document, 'performer', ['title']) }}
            .flex.items-center Статус
            editable-cell(
                :initialValue="document.statusId"
                @update="(e) => onUpdate('statusId', e)"
                emptyText="Не указано"
                type="select"
                :options="directories.documentStatuses.list"
                :title="getTitle(document, 'documentStatus')"
            )
            .flex.items-center Способ обмена
            editable-cell(
                :initialValue="document.transportId"
                @update="(e) => onUpdate('transportId', e)"
                emptyText="Не указано"
                type="select"
                :options="directories.documentTransports.list"
                :title="getTitle(document, 'documentTransport')"
            )
            .flex.items-center Срок исполнения
            editable-cell(
                :initialValue="document.deadline"
                @update="(e) => onDateUpdate('deadline', e)"
                emptyText="Не указано"
                type="date"
                :title="formatDate(document.deadline)"
                :className="isDocumentOutDated(document) ? 'danger' : ''"
            )
            .flex.items-center Ответственный
            editable-cell(
                :initialValue="document.directorId"
                @update="(e) => onUpdate('directorId', e)"
                emptyText="Не указано"
                type="select"
                :options="members.list"
                valueKey="fullName"
                :title="getTitle(document, 'director', 'fullName')"
            )
        .editable-card__column
            .flex.items-center Дата создания
            .flex.items-center {{ formatDate(document.createdAt) }}
            .flex.items-center Создан пользователем
            .flex.items-center {{ getTitle(document, 'createdBy', 'fullName') }}
        .flex.items-center.mb-3
            card-title.mr-3 Услуги
        a-card
            document-services(v-model:services="localServices" :directories="directories" @log-data="setLogData")
            a-button(type="primary" @click="saveServices") Сохранить
    .document-card__actions
        card-title Действия
        a-button(
            @click="downloadWrapper(document, document.documentUrl)"
            v-if="document.documentUrl || document.sourceType === DocumentSourceType.create_new"
        ) Скачать документ
        a-popconfirm(
            v-if="!document.isArchive"
            title="Вы уверены, что хотите архивировать документ?"
            ok-text="Да"
            cancel-text="Нет"
            @confirm="onUpdate('isArchive', true)"
        )
            a-button.block.mt-2 Архивировать
        a-popconfirm(
            v-else
            title="Вы уверены, что хотите вернуть документ в актуальное?"
            ok-text="Да"
            cancel-text="Нет"
            @confirm="onUpdate('isArchive', false)"
        )
            a-button.block.mt-2 Вернуть в актуальное
        a-popconfirm(
            title="Вы уверены, что хотите удалить контакт?"
            ok-text="Да"
            cancel-text="Нет"
            @confirm="deleteDocumentById"
        )
            a-button.block.mt-2(type="danger") Удалить
</template>

<script>
import CardTitle from '@/components/Generic/Typography/CardTitle';
import {ref} from 'vue';
import {useDocument} from '@/composition/document';
import {useWorkspace} from '@/composition/workspace';
import {useRoute, useRouter} from 'vue-router';
import {useDirectory} from '@/composition/directory';
import EditableCell from '@/components/Generic/EditableCell';
import {getTitle} from '@/modules/utils';
import {useService} from '@/composition/service';
import {useDownload} from '@/composition/download';
import {useDate} from '@/composition/date';
import constants from '@/constants';
import Uploader from '@/components/Generic/Uploader';
import {DocumentSourceType} from '@/components/Documents/constants';
import DocumentServices from '@/components/Documents/Services';
import {BillType} from '@/components/Bills/constants';
import {useTimelog} from '@/composition/timelog';
import moment from 'moment';
export default {
    name: 'DocumentCard',
    components: {DocumentServices, Uploader, EditableCell, CardTitle},
    async setup() {
        const loading = ref(false);
        const localServices = ref({
            value: []
        });
        const route = useRoute();
        const router = useRouter();
        const {currentWorkspaceId, getMembers, members} = useWorkspace(loading);
        const {document, getDocumentById, deleteDocument, updateDocument, isDocumentOutDated, downloadTemplate} = useDocument(loading);
        const {directories, getDirectories} = useDirectory(loading);
        const {services, getServices} = useService(loading);
        const {download} = useDownload(loading);
        const {formatDate, getDateRangeWithOffset} = useDate();
        const {getTimeLogsByServiceId} = useTimelog();
        const downloadWrapper = async(doc, key) => {
            if (doc.sourceType === DocumentSourceType.create_new) {
                await downloadTemplate(doc.id);
            } else {
                const {ok, result} = await download(currentWorkspaceId.value, key);
                if (ok) {
                    window.open(result.url, '_blank');
                }
            }
        };
        const onUpdate = async (key, value) => {
            await updateDocument(currentWorkspaceId.value, document.value.id, {[key]: value});
            await getDocumentById(currentWorkspaceId.value, route.params.documentId);
        };
        const onDateUpdate = async (key, value) => {
            const date = moment(value).format('YYYY-MM-DD');
            await onUpdate(key, date);
        };
        const onUpload = async (data) => {
            await onUpdate('documentUrl', data.key);
        };
        const deleteDocumentById = async () => {
            await deleteDocument(currentWorkspaceId.value, document.value.id);
            await router.push(`/${currentWorkspaceId.value}/documents`);
        };
        const getCurrencyOptions = () => {
            return Object.keys(constants.currency).map(x => ({
                id: x,
                title: constants.currencyMap[x]
            }));
        };
        const getCardTitle = () => {
            let title = '';
            if (document.value?.documentType?.title) {
                title += document.value?.documentType?.title;
            }
            if (document.value.number) {
                title += ` ${document.value.number}`;
            }
            if (document.value.date) {
                title += ` от ${formatDate(document.value.date)}`;
            }
            return title;
        };
        const getServicesTitle = () => {
            return document.value.services.map(x => x?.service?.title).join(',');
        };
        const getServicesIds = () => {
            return document.value.services.map(x => x?.service?.id);
        };
        const setLogData = async (filter) => {
            const {ok, result} = await getTimeLogsByServiceId(currentWorkspaceId.value, filter.serviceId, filter.paymentPeriodId, document.value.type);
            if (ok && result.length) {
                localServices.value.value = localServices.value.value.map(x => {
                    if (x.serviceId === filter.serviceId) {
                        return {
                            ...x,
                            items: result
                        };
                    }
                    return x;
                });
            }
        };
        const saveServices = async () => {
            await onUpdate('services', localServices.value.value);
        };
        await getDocumentById(currentWorkspaceId.value, route.params.documentId);
        await getDirectories(currentWorkspaceId.value, 'documents,bills');
        await getServices(currentWorkspaceId.value);
        await getMembers(currentWorkspaceId.value);
        localServices.value.value = document.value.services;
        return {
            members,
            document,
            services,
            directories,
            DocumentSourceType,
            currencyMap: constants.currencyMap,
            localServices,
            getTitle,
            onUpload,
            onUpdate,
            formatDate,
            setLogData,
            saveServices,
            onDateUpdate,
            getCardTitle,
            getServicesIds,
            downloadWrapper,
            getServicesTitle,
            deleteDocumentById,
            getCurrencyOptions,
            isDocumentOutDated,
        };
    }
};
</script>

<style lang="scss" scoped>
.document-card {
    display: grid;
    grid-template-columns: 1fr 400px;
    grid-gap: 20px;
}
</style>