<template lang="pug">
.salary-list-table
    custom-table(
        :columns="salaryListColumns"
        :data-source="data"
        :loading="loading"
        :collapse="true"
        :defaultChildrenOpen="false"
        :rowClass="(record) => record.financeDiff <= 0 ? 'row-success' : ''"
    )
        template(#children="{record}")
            custom-table(
                :columns="salaryListColumns"
                :data-source="record.positions"
                :loading="loading"
                :hide-columns="true"
                :rowClass="(record) => record.financeDiff <= 0 ? 'row-success' : ''"
            )
                template(#title="{record}")
                    router-link(
                        :to="`/${record.workspaceId}/services/${record.serviceId}/payment-periods/${record.paymentPeriodId}`"
                        v-if="record.service"
                    ) {{ record.service.title }} ({{ record.paymentPeriod.title }})
                    div(v-else) {{ record.title || '-' }}
                template(#paymentType="{record}")
                    div {{ record?.paymentType ? performerPaymentTypeMap[record.paymentType] : '-' }}
                template(#rate="{record}")
                    div(v-if="record?.paymentType === performerPaymentType.hourly && record?.hourlyRate") {{ record.hourlyRate }} {{ currencyMap[record.currency] }} в час
                    div(v-else-if="record?.paymentType === performerPaymentType.profit_percent || record?.paymentType === performerPaymentType.earning_percent") {{ record.percentRate || 0 }} %
                    div(v-else) -
                template(#utilization="{record}")
                    .inline-flex {{ record.utilizationPlan || 0 }}
                    .inline-flex.mx-1 /
                    .inline-flex.base-link(
                        @click="openInNewTab(`/${workspaceId}/reports/hourly/new?performers=${record.workspaceMemberId}&from=${rangeFromPeriod.from}&to=${rangeFromPeriod.to}&services=${record.serviceId ? record.serviceId : -1}`)"
                        v-if="record.utilizationFact > 0"
                    ) {{ record.utilizationFact || 0 }}
                    .inline-flex(v-else) {{ record.utilizationFact || 0 }}
                    .inline-flex.mx-1 /
                    .inline-flex {{ record.utilizationDiff }}
                template(#financePlan="{record}")
                    .inline-flex(v-if="record.paymentType !== servicePaymentType.fixed") {{ formatCurrency(record.financePlan) }}
                    number-inline-changer.inline-flex(
                        v-else
                        :number="record.financePlan"
                        :func="async(financePlan) => await onUpdate(record.id, 'planBalance', financePlan)"
                        placeholder="План"
                    )
                template(#finance="{record}")
                    .inline-flex
                    .inline-flex(v-if="record.paymentType !== servicePaymentType.fixed") {{ formatCurrency(record.balance) }}
                    number-inline-changer.inline-flex(
                        v-else
                        :number="record.balance"
                        :func="async(balance) => await onUpdate(record.id, 'balance', balance)"
                        placeholder="Баланс"
                    )
                    .inline-flex.mx-1 /
                    .inline-flex.base-link.base-link(@click="openInNewTab(`/${workspaceId}/transactions?contractorId=${record?.contact?.id}&period=${period}`)") {{ formatCurrency(record.financeFact || 0) }}
                    .inline-flex.mx-1 /
                    .inline-flex {{ formatCurrency(record.financeDiff || 0) }}
                template(#actions="{record}")
                    delete-with-confirm(
                        :delete-func="() => onDelete(record.id)"
                        v-if="!record.service && record.paymentType !== servicePaymentType.hourly"
                    )
        template(#title="{record}")
            .flex.items-center
                router-link(:to="`/${workspaceId}/members/${record.id}/common`") {{ record.workspaceMember.fullName }}
                .cursor-pointer.ml-2(@click.stop.prevent="addNewPosition(record.workspaceMember)")
                    icon(type="Plus")
        template(#utilization="{record}")
            .inline-flex {{ record.utilizationPlan || 0 }}
            .inline-flex.mx-1 /
            .inline-flex.base-link(
                @click="openInNewTab(`/${workspaceId}/reports/hourly/new?performers=${record.id}&from=${rangeFromPeriod.from}&to=${rangeFromPeriod.to}`)"
                v-if="record.utilizationFact > 0"
            ) {{ record.utilizationFact || 0 }}
            .inline-flex(v-else) {{ record.utilizationFact || 0 }}
            .inline-flex.mx-1 /
            .inline-flex {{ record.utilizationDiff }}
        template(#financePlan="{record}")
            div {{ formatCurrency(record.financePlan) }}
        template(#finance="{record}")
            .inline-flex {{ formatCurrency(record.balance || 0) }}
            .inline-flex.mx-1 /
            .inline-flex.base-link(@click="openInNewTab(`/${workspaceId}/transactions?contractorId=${record?.contact?.id}&period=${period}`)") {{ formatCurrency(record.financeFact || 0) }}
            .inline-flex.mx-1 /
            .inline-flex {{ formatCurrency(record.financeDiff || 0) }}
        template(#actions="{record}")
            .flex.items-center.justify-between
                div.whitespace-nowrap.base-link(
                    @click.stop.prevent="showAllTransactionsList(record)"
                ) {{ formatCurrency(record.totalAmountByTransactions) }} руб.
                .cursor-pointer.ml-2(@click.stop.prevent="addNewTransaction(record, true)")
                    icon(type="Plus")
a-drawer(
    :visible="showModal"
    title="Добавить позицию"
    @close="setModalVisibility(false)"
    :closable="true"
    width="40%"
    :footer="null"
    :destroyOnClose="true"
)
    salary-position-form(
        @create="onPositionCreated"
        :workspace-member="workspaceMember"
        :loading="addNewPositionLoading"
    )
a-drawer(
    :visible="showTransactionModal"
    :title="currentTransactionMode === 'create' ? 'Добавить транзакцию' : 'Редактировать'"
    @close="setTransactionModalVisibility(false)"
    :closable="true"
    width="80%"
    :footer="null"
    :destroyOnClose="true"
)
    create-transaction-form(
        :workspaceId="currentWorkspaceId"
        @ok="onTransactionCreated"
        @update="onTransactionUpdate"
        :default-value="editingTransaction"
        :mode="currentTransactionMode"
    )
a-drawer(
    :visible="showTransactionsListModal"
    title="Список транзакций"
    @close="onTransactionsListClosed"
    :closable="true"
    width="100%"
    :footer="null"
    :destroyOnClose="true"
)
    transactions-list(
        :transactions="selectedSalary.transactions"
        :salary="selectedSalary"
        @edit="onTransactionEdit"
        @fact="onTransactionToFact"
        @confirm="onTransactionConfirm"
    )
</template>

<script>

export default {
    name: 'SalaryListTable',
};
</script>

<script setup>
/*===============IMPORT AREA===================*/

import {salaryListColumns} from '../constants';
import CustomTable from '@/components/Generic/CustomTable';
import {formatCurrency} from '@/modules/utils';
import {computed, ref, onMounted} from 'vue';
import {useDate} from '@/composition/date';
import {servicePaymentType, servicePaymentTypeMap, performerPaymentTypeMap, performerPaymentType} from '@/components/Services/constants';
import constants from '@/constants';
import NumberInlineChanger from '@/components/Generic/InlineChanger/NumberInlineChanger';
import {useCreateOrUpdateModal} from '@/composition/create-update-modal';
import SalaryPositionForm from '@/components/SalaryList/PositionForm';
import {useLoading} from '@/composition/loading-state/index.ts';
import DeleteWithConfirm from '@/components/Generic/DeleteWithConfirm';
import {useWorkspace} from '@/composition/workspace';
import {useDirectory} from '@/composition/directory';
import {transactionType} from '@/components/Transactions/constants';
import CreateTransactionForm from '@/components/Transactions/CreateForm';
import TransactionsList from '@/components/Transactions/List/index';
import {useTransaction} from '@/composition/transaction';

/*===============INIT AREA===================*/
const currencyMap = constants.currencyMap;
const props = defineProps({
    data: {
        type: Array,
        required: true,
    },
    workspaceId: {
        type: Number,
        required: true
    },
    loading: {
        type: Boolean
    },
    period: {
        type: String
    }
});
const emit = defineEmits(['update', 'create', 'delete', 'fetch']);
const {getEndOfMonth, getStartOfMonth, dateToMs, dateInstance} = useDate();

/*===============ROUTING AREA===================*/
const openInNewTab = (route) => {
    window.open(route, '_blank');
};
/*===============HANDLERS AREA===================*/
const onUpdate = async (id, key, value) => {
    return new Promise((resolve) => {
        emit('update', {
            payload: {
                id,
                [key]: value
            },
            done: () => {
                resolve();
            }
        });
    });
};
const onDelete = (id) => {
    emit('delete', {
        id,
        done: () => {
            return false;
        }
    });
};
/*===============FILTERS AREA===================*/

const deadlineFromPeriod = computed(() => {
    return dateInstance()(getEndOfMonth(props.period)).valueOf();
});

const rangeFromPeriod = computed(() => {
    return {from: props.period, to: dateInstance()(props.period).endOf('month').format('YYYY-MM-DD')};
});
/*===============CREATE NEW POSITION AREA===================*/
const {loading: addNewPositionLoading} = useLoading();
const {setModalVisibility, showModal} = useCreateOrUpdateModal();
const workspaceMember = ref(null);
const addNewPosition = (member) => {
    workspaceMember.value = member;
    setModalVisibility(true);
};
const onPositionCreated = async (salaryPosition) => {
    addNewPositionLoading.value = true;
    emit('create', {
        salaryPosition,
        done: () => {
            workspaceMember.value = null;
            setModalVisibility(false);
            addNewPositionLoading.value = false;
        }
    });
};
/*===============TRANSACTIONS AREA===================*/
const {currentWorkspaceId} = useWorkspace();
const {getDirectories, directories} = useDirectory();
const {updateTransaction} = useTransaction();
const {
    setModalVisibility: setTransactionModalVisibility,
    showModal: showTransactionModal,
    currentMode: currentTransactionMode,
    edit: onTransactionEdit,
    editingValue: editingTransaction
} = useCreateOrUpdateModal();
const onTransactionCreated = () => {
    setTransactionModalVisibility(false);
    editingTransaction.value = {};
    emit('fetch');
};
const addNewTransaction = (record, isScheduled) => {
    const operation = directories.value.billOperations.list.find((x) => x.key === 'salary');
    const bankAccount = directories.value.bankAccounts.list.find((x) => x.currency === 'rub');
    const services = record.positions.filter(x => !!x.service);
    const positions = record.positions.filter(x => !x.service);
    editingTransaction.value = {
        contractorId: record?.contact?.id,
        type: transactionType.withdrawal,
        isScheduled: isScheduled,
        date: rangeFromPeriod.value.to,
        operationId: operation?.id,
        fromAccountId: bankAccount?.id,
        period: props.period,
        services: services.map(x => ({
            id: x.id,
            serviceId: x.serviceId,
            amount: isScheduled ? x.financePlan : x.financeDiff,
            paymentPeriod: x.paymentPeriod
        })),
        positions: positions.map(x => ({
            id: x.id,
            title: x.title,
            amount: isScheduled ? x.financePlan : x.financeDiff,
            currency: x.currency,
            salaryId: x.id,
        })),
    };
    setTransactionModalVisibility(true);
};

const onTransactionUpdate = async (transaction) => {
    const {id, ...updateData} = transaction;
    const {ok, result} = await updateTransaction(currentWorkspaceId.value, id, updateData);
    if (ok) {
        selectedSalary.value.transactions = selectedSalary.value.transactions.map(x => {
            if (x.id === id) {
                return result;
            }
            return x;
        });
        setTransactionModalVisibility(false);
        emit('fetch');
    }
};
const onTransactionToFact = async ({salary, transaction}) => {
    const services = salary.positions.filter(x => !!x.service);
    const positions = salary.positions.filter(x => !x.service);
    editingTransaction.value = {
        ...transaction,
        services: services.map(x => ({
            id: x.id,
            serviceId: x.serviceId,
            amount: x.financeDiff,
            paymentPeriod: x.paymentPeriod
        })),
        positions: positions.map(x => ({
            id: x.id,
            title: x.title,
            amount: x.financeDiff,
            currency: x.currency,
            salaryId: x.id,
        })),
        isScheduled: false,
    };
    currentTransactionMode.value = 'edit';
    setTransactionModalVisibility(true);
    return transaction;
};
const onTransactionConfirm = async (transaction) => {
    await onTransactionUpdate({
        ...transaction,
        isScheduled: false
    });
    return transaction;
};
onMounted(async () => {
    await getDirectories(currentWorkspaceId.value, 'bills');
});
/*====================SHOW TRANSACTIONS LIST=================*/
const {
    setModalVisibility: setTransactionsListModalVisibility,
    showModal: showTransactionsListModal,
    editingValue: selectedSalary
} = useCreateOrUpdateModal();

const onTransactionsListClosed = () => {
    setTransactionsListModalVisibility(false);
    selectedSalary.value = {};
};
const showAllTransactionsList = (salary) => {
    selectedSalary.value = salary;
    setTransactionsListModalVisibility(true);
};
</script>

<style lang="scss">
.salary-list-table {
    .paid {
        background: #B9E3AF;
    }
}
</style>
