import {ref} from 'vue';
import {apiRequest} from '@/modules/error_handler';
import crudModule from '@/modules/crud';

export function useCrud(name, loading = {value: false}) {
    const pagination = ref({
        limit: 50,
        offset: 0,
    });
    const data = ref({
        list: [],
        next: '',
        prevPage: '',
        total: '',
        totalPages: '',
    });
    const getData = async (workspaceId, meta = {}, search) => {
        return apiRequest(async () => {
            const result = await crudModule.getData(name, workspaceId, {
                limit: pagination.value.limit,
                offset: pagination.value.offset,
            }, search);
            if (meta.fromPagination) {
                data.value.list = data.value.list.concat(result.list);
                data.value.next = result.next;
            } else {
                data.value = result;
            }
            return data.value;
        }, loading);
    };
    const updateLocal = (project = {}) => {
        data.value.list = data.value.list.map(x => {
            if (x.id === project.id) {
                return {...x, ...project};
            }
            return x;
        });
    };
    const updateOrders = async (orders, workspaceId) => {
        return apiRequest(async () => {
            return crudModule.updateOrders(name, orders, workspaceId);
        }, loading);
    };
    const createEntity = async (data, workspaceId) => {
        return apiRequest(async () => {
            return crudModule.createEntity(name, data, workspaceId);
        }, loading);
    };
    const updateEntity = async (id, updateData, workspaceId) => {
        return apiRequest(async () => {
            return crudModule.updateEntity(name, id, updateData, workspaceId);
        }, loading);
    };
    const deleteEntity = async (id, workspaceId) => {
        return apiRequest(async () => {
            return crudModule.deleteEntity(name, id, workspaceId);
        }, loading);
    };
    const onSortOrderChanged = async (e, workspaceId) => {
        if (e.moved) {
            const oldIndex = e.moved.oldIndex;
            const newIndex = e.moved.newIndex;
            let affectedRowsOrders;
            if (oldIndex < newIndex) {
                const affectedRows = data.value.list.slice(oldIndex, newIndex).sort((a, b) => a.order - b.order);
                affectedRowsOrders = affectedRows.map(x => ({id: x.id, order: x.order + 1, title: x.title}));
                const neighbor = affectedRowsOrders[0];
                affectedRowsOrders.push({
                    id: e.moved.element.id,
                    order: neighbor.order - 1
                });
            } else if (oldIndex > newIndex) {
                const affectedRows = data.value.list.slice(newIndex + 1, oldIndex + 1).sort((a, b) => b.order - a.order);
                affectedRowsOrders = affectedRows.map(x => ({id: x.id, order: x.order - 1, title: x.title}));
                const neighbor = affectedRowsOrders[0];
                affectedRowsOrders.push({
                    id: e.moved.element.id,
                    order: neighbor.order + 1
                });
            }
            await updateOrders(affectedRowsOrders, workspaceId);
            await getData(workspaceId);
        }
    };
    return {
        data,
        pagination,
        createEntity,
        getData,
        updateEntity,
        updateOrders,
        deleteEntity,
        updateLocal,
        onSortOrderChanged,
    };
}