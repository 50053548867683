<template lang="pug">
.stripes-spinner(:style="{width: width ? width : '100%', height: height}" v-if="loading" :class="classes")
.fallback(v-else)
    slot
</template>

<script>
export default {
    name: 'ConditionalSkeleton',
    props: {
        width: String,
        height: String,
        loading: Boolean,
        classes: {
            type: String,
            default: 'my-1'
        },
    }
};
</script>

<style lang="scss" scoped>
.stripes-spinner {
  background-color: #DDDBDD;
  overflow: hidden;
  position: relative;
  transition: all 0.3s;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
            90deg,
            rgba(#fff, 0) 0,
            rgba(#fff, 0.2) 20%,
            rgba(#fff, 0.5) 60%,
            rgba(#fff, 0)
    );
    animation: shimmer 5s infinite;
    content: '';
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

</style>
