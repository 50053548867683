<template lang="pug">
transactions-table(:billId="route.params.billId")
</template>

<script>
import TransactionsTable from '@/components/Transactions/Table';
import {useRoute} from 'vue-router';
import {onBeforeUnmount} from 'vue';
import {useTransaction} from '@/composition/transaction';
export default {
    name: 'BillTransactionsTable',
    components: {TransactionsTable},
    setup() {
        const route = useRoute();
        const {setFilter} = useTransaction();
        onBeforeUnmount(() => {
            setFilter({});
        });
        return {
            route
        };
    }
};
</script>

<style scoped>

</style>