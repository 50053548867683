<template lang="pug">
card-title Прочее
.invite-user-form.mt-2
    input-with-error(:error="specialMarkId.error")
        template(#input)
            a-select(
                :value="specialMarkId.value"
                style="width: 100%"
                placeholder="Особая метка"
                show-search
                allowClear
                :filter-option="selectFilterFunc"
                @change="onChange('specialMarkId', $event)"
            )
                a-select-option(
                    v-for="specialMark in directories.specialMarks.list"
                    :key="specialMark.id"
                    :value="specialMark.id"
                    :label="specialMark.title"
                )
                    | {{ specialMark.title }}
                a-select-option.cursor-pointer(disabled @click="() => $emit('open-modal', 'specialMarks')") Добавить
                template(#notFoundContent)
                    add-directory(:onClick="() => $emit('open-modal', 'specialMarks')")
    input-with-error(:error="mark.error")
        template(#input)
            a-textarea(placeholder="Примечание" :value="mark.value" :auto-size="{ minRows: 2, maxRows: 5 }" @change="onChange('mark', $event.target.value)")
    input-with-error(:error="hrSourceId.error")
        template(#input)
            a-select(
                :value="hrSourceId.value"
                style="width: 100%"
                placeholder="HR источник"
                show-search
                :filter-option="selectFilterFunc"
                @change="onChange('hrSourceId', $event)"
            )
                a-select-option(
                    v-for="hrSource in directories.hrSources.list"
                    :key="hrSource.id"
                    :value="hrSource.id"
                    :label="hrSource.title"
                )
                    | {{ hrSource.title }}
                a-select-option.cursor-pointer(disabled @click="() => $emit('open-modal', 'hrSources')") Добавить
                template(#notFoundContent)
                    add-directory(:onClick="() => $emit('open-modal', 'hrSources')")
</template>

<script>
import {selectFilterFunc} from '@/modules/utils';
import AddDirectory from '@/components/Generic/AddDirectory/AddDirectory';

export default {
    name: 'OthersForm',
    components: {AddDirectory},
    props: {
        hrSourceId: Object,
        mark: Object,
        specialMarkId: Object,
        directories: Object,
    },
    emits: [
        'update:hrSourceId',
        'update:mark',
        'update:specialMarkId',
        'open-modal'
    ],
    setup(props, {emit}) {
        const onChange = (key, value) => {
            emit(`update:${key}`, {value, error: undefined});
        };
        return {
            onChange,
            selectFilterFunc
        };
    }
};
</script>

<style scoped>

</style>