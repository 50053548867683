<template lang="pug">
.bank-account-balances
    .bank-account-balances__item
        div Счет
        div Баланс
    .bank-account-balances__item(v-for="balance in balances" :key="balance.id")
        div {{ balance.title }}
        div(:class="Number(balance.balance || 0) > 0 ? 'success' : 'danger'") {{ formatCurrency(balance.balance || 0) }} {{ constants.currencyMap.rub }}
</template>

<script>
import {useTransaction} from '@/composition/transaction';
import {useWorkspace} from '@/composition/workspace';
import constants from '@/constants';
import {formatCurrency} from '@/modules/utils';
import {watch, ref} from 'vue';
export default {
    name: 'BankAccountBalances',
    props: {
        update: {
            type: Boolean
        }
    },
    async setup(props) {
        const {currentWorkspaceId} = useWorkspace();
        const {getBalances} = useTransaction();
        const balances = ref({});
        const fetchData = async () => {
            const {result} = await getBalances(currentWorkspaceId.value);
            balances.value = result;
        };
        watch(() => props.update, () => {
            if (props.update) {
                fetchData();
            }
        });
        await fetchData();
        return {
            balances,
            constants,
            formatCurrency
        };
    }
};
</script>

<style lang="scss" scoped>
.bank-account-balances {
    width: 100%;
    &__item {
        display: grid;
        grid-template-columns: 0.5fr 1.5fr;
        grid-column-gap: 16px;
        align-items: center;
        padding: 3px;
        font-weight: 700;
    }
}
</style>