<template lang="pug">
.add-service-form
    card-title Основная информация
    input-with-error(:error="form.title.error")
        template(#input)
            a-input(placeholder="Наименование" v-model:value="form.title.value")
    .flex.items-center
        input-with-error.mr-2(:error="form.projectId.error")
            template(#input)
                custom-select-v2(
                    v-model="form.projectId.value"
                    placeholder="Проект"
                    :style="{width: '100%'}"
                    :options="projects.list"
                    searchable
                    @search="onProjectSearch"
                )
        input-with-error(:error="form.contactId.error")
            template(#input)
                custom-select-v2(
                    v-model="form.contactId.value"
                    placeholder="Клиент"
                    :style="{width: '100%'}"
                    :disabled="true"
                )
    input-with-error.mt-2(:error="form.documentFlow.error")
        template(#input)
            a-checkbox(v-model:checked="form.documentFlow.value") Требуется документооборот
    div(v-if="mode === 'create' && form.contactId.value")
        service-payment-period-create-form(
            @update:form="onPaymentPeriodChanged"
            mode="create"
            :contact="contact"
            immediate-update
        )
    div
        card-title.mt-2 Прочее
        input-with-error.mt-2(:error="form.specialMarkId.error")
            template(#input)
                a-select(
                    v-model:value="form.specialMarkId.value"
                    style="width: 100%"
                    placeholder="Особая метка"
                    show-search
                    :filter-option="selectFilterFunc"
                )
                    a-select-option(
                        v-for="specialMark in directories.serviceSpecialMarks.list"
                        :key="specialMark.id"
                        :value="specialMark.id"
                        :label="specialMark.title"
                    )
                        | {{ specialMark.title }}
                    a-select-option.cursor-pointer(disabled @click="() => setModalVisibility('serviceSpecialMarks', true)") Добавить
                    template(#notFoundContent)
                        add-directory(:onClick="() => setModalVisibility('serviceSpecialMarks', true)")
        input-with-error.mt-2(:error="form.groupId.error")
            template(#input)
                a-select(
                    v-model:value="form.groupId.value"
                    style="width: 100%"
                    placeholder="Группа"
                    show-search
                    :filter-option="selectFilterFunc"
                )
                    a-select-option(
                        v-for="group in directories.serviceGroups.list"
                        :key="group.id"
                        :value="group.id"
                        :label="group.title"
                    )
                        | {{ group.title }}
                    a-select-option.cursor-pointer(disabled @click="() => setModalVisibility('serviceGroups', true)") Добавить
                    template(#notFoundContent)
                        add-directory(:onClick="() => setModalVisibility('serviceGroups', true)")
        input-with-error.mt-2(:error="form.directorId.error")
            template(#input)
                a-select(
                    v-model:value="form.directorId.value"
                    style="width: 100%"
                    placeholder="Ответственный"
                    show-search
                    :filter-option="selectFilterFunc"
                )
                    a-select-option(
                        v-for="member in members.list"
                        :key="member.id"
                        :value="member.id"
                        :label="member.fullName"
                    )
                        | {{ member.fullName }}
    a-button.mt-5.mb-2(
        type="primary"
        block
        @click="createServiceWrapper"
        :loading="loading"
        :disabled="disabled"
    ) {{ mode === 'edit' ? 'Сохранить' : 'Создать' }}
    a-modal(
        v-model:visible="additionalModals[currentModal].showModal"
        :title="additionalModals?.[currentModal]?.title"
        :footer="null"
        @cancel="setModalVisibility(currentModal, false)"
        :destroyOnClose="true"
    )
        component(
            v-if="currentModal"
            :is="additionalModals[currentModal].component"
            @ok="onDirectoryAdded"
            :directories="directories"
            :workspaceId="currentWorkspaceId"
        )
</template>

<script>
import {computed, onMounted, reactive, ref, watch} from 'vue';
import {useFormErrors} from '@/composition/errors';
import {notification} from 'ant-design-vue';
import {debounce, selectFilterFunc, truncateNumber} from '@/modules/utils';
import {useService} from '@/composition/service';
import ServicePerformersList from '@/components/Services/Performers/List';
import CardTitle from '@/components/Generic/Typography/CardTitle';
import InputWithError from '@/components/Generic/InputWithError';
import DocumentForm from '@/components/Services/DocumentForm';
import {servicePaymentType, servicePaymentTypeMap} from '@/components/Services/constants';
import CurrencySelect from '@/components/Generic/CurrencySelect';
import PaymentDates from '@/components/Services/PaymentDates';
import {useDirectory} from '@/composition/directory';
import {useProject} from '@/composition/project';
import {useConvert} from '@/composition/convert';
import AddDirectory from '@/components/Generic/AddDirectory/AddDirectory';
import CreateBankAccountForm from '@/components/Directories/Bills/BankAccount/CreateForm';
import CreateServiceSpecialMark from '@/components/Directories/Services/SpecialMarks/CreateForm';
import CreateServiceGroup from '@/components/Directories/Services/Group/CreateForm';
import CreateServiceWorkStatus from '@/components/Directories/Services/WorkStatus/CreateForm';
import ProjectsSelect from '@/components/Projects/Select';
import {useWorkspace} from '@/composition/workspace';
import {useContact} from '@/composition/contact';
import moment from 'moment-timezone';
import {useContactV2} from '@/composition/contact-v2';
import {useProjectV2} from '@/composition/project-v2';
import CustomSelectV2 from '@/components/Generic/CustomSelectV2';
import {useWorkspaceV2} from '@/composition/workspace-v2';
import ServicePaymentPeriodCreateForm from '@/components/Services/PaymentPeriod/CreateForm/index';
import {ServicePaymentPeriodMapper} from '@/modules/services/mappers/service-payment-period.mapper.ts';
export default {
    name: 'CreateServiceForm',
    components: {
        ServicePaymentPeriodCreateForm,
        CustomSelectV2,
        CreateServiceWorkStatus,
        CreateServiceGroup,
        CreateServiceSpecialMark,
        CreateBankAccountForm,
        AddDirectory,
        ProjectsSelect,
        PaymentDates, CurrencySelect, DocumentForm, InputWithError, CardTitle, ServicePerformersList},
    props: {
        defaultValue: {
            type: Object,
            default: () => ({})
        },
        mode: String,
    },
    emits: ['create', 'update'],
    setup(props, {emit}) {
        /*==============WORKSPACE AREA=========================*/
        const loading = ref(false);
        const isPaymentPeriodDone = ref(false);
        const {currentWorkspaceId} = useWorkspace();

        /*==============MEMBERS AREA=========================*/
        const {members, getMembers, setFilter: setMembersFilter} = useWorkspaceV2();
        const onMembersSearch = (q) => {
            setMembersFilter({search: q});
            debounce(() => getMembers(currentWorkspaceId.value), 500)();
        };
        onMounted(async () => {
            await getMembers(currentWorkspaceId.value);
        });
        /*==============DIRECTORIES MODALS AREA=========================*/
        const currentModal = ref('bankAccounts');
        const additionalModals = reactive({
            bankAccounts: {
                showModal: false,
                component: 'create-bank-account-form',
                title: 'Добавить счет'
            },
            serviceSpecialMarks: {
                showModal: false,
                component: 'create-service-special-mark',
                title: 'Добавить особую метку'
            },
            serviceGroups: {
                showModal: false,
                component: 'create-service-group',
                title: 'Добавить группу'
            },
        });
        const setModalVisibility = (type, bool) => {
            currentModal.value = type;
            additionalModals[type].showModal = bool;
            if (!bool) {
                currentModal.value = 'bankAccounts';
            }
        };

        const onDirectoryAdded = async () => {
            setModalVisibility(currentModal.value, false);
            await getDirectories(props.workspaceId, 'service,bills');
        };

        /*==============FORM AREA=========================*/
        const form = reactive({
            title: {
                value: props.defaultValue.title,
                error: ''
            },
            isMonthly: {
                value: props.defaultValue.isMonthly || false,
                error: ''
            },
            repeatDay: {
                value: props.defaultValue.repeatDay,
                error: ''
            },
            contactId: {
                value: props.defaultValue.contactId,
                error: ''
            },
            startDate: {
                value: props.defaultValue.startDate,
                error: ''
            },
            deadline: {
                value: props.defaultValue.deadline,
                error: ''
            },
            performers: {
                value: props.defaultValue.performers || [],
                error: ''
            },
            projectId: {
                value: props.defaultValue.projectId,
                error: undefined,
            },
            clientId: {
                value: props.defaultValue.clientId,
                error: undefined,
            },
            documentFlow: {
                value: props.defaultValue.documentFlow,
                error: undefined,
            },
            documents: {
                value: props.defaultValue.documents || [],
                error: undefined,
            },
            paymentType: {
                value: props.defaultValue.paymentType,
                error: undefined
            },
            hourlyRate: {
                value: props.defaultValue.hourlyRate,
                error: undefined
            },
            totalCost: {
                value: props.defaultValue.totalCost,
                error: undefined
            },
            currency: {
                value: props.defaultValue.currency,
                error: undefined
            },
            hoursPayload: {
                value: props.defaultValue.hoursPayload,
                error: undefined
            },
            bankAccountId: {
                value: props.defaultValue.bankAccountId,
                error: undefined
            },
            paymentDates: {
                value: props.defaultValue.paymentDates || [],
                error: undefined,
            },
            specialMarkId: {
                value: props.defaultValue.specialMarkId,
                error: undefined,
            },
            directorId: {
                value: props.defaultValue.directorId,
                error: undefined,
            },
            groupId: {
                value: props.defaultValue.groupId,
                error: undefined,
            },
            paymentPeriod: {
                value: props.defaultValue.paymentPeriod || {},
                error: undefined
            }
        });
        const onPaymentPeriodChanged = (paymentPeriod) => {
            form.paymentPeriod.value = paymentPeriod;
            isPaymentPeriodDone.value = (function() {
                if (!paymentPeriod.from || !paymentPeriod.to) return false;
                if (!paymentPeriod.paymentType) return false;
                if (paymentPeriod.performers?.length && paymentPeriod.performers.some(x => !x.paymentType || !x.workspaceMember)) return false;
                return !(paymentPeriod.expenses?.length && paymentPeriod.expenses.some(x => !x.title || !x.amount || !x.currency));
            }());
        };
        const createServiceWrapper = async () => {
            const serviceData = {
                title: form.title.value,
                contactId: form.contactId.value?.id,
                startDate: form.startDate.value,
                deadline: form.deadline.value,
                performers: form.performers.value,
                projectId: form.projectId.value?.id,
                clientId: form.clientId.value,
                documentFlow: form.documentFlow.value,
                documents: form.documents.value,
                paymentType: form.paymentType.value,
                hourlyRate: form.hourlyRate.value,
                currency: form.currency.value,
                hoursPayload: form.hoursPayload.value,
                bankAccountId: form.bankAccountId.value,
                paymentDates: form.paymentDates.value,
                specialMarkId: form.specialMarkId.value,
                directorId: form.directorId.value,
                totalCost: form.totalCost.value,
                groupId: form.groupId.value,
                paymentPeriod: ServicePaymentPeriodMapper.toCreateDto(form.paymentPeriod.value),
            };
            if (props.mode === 'edit') {
                delete serviceData.paymentPeriod;
                emit('update', {...serviceData, id: props.defaultValue.id});
            } else {
                loading.value = true;
                emit('create', {
                    service: serviceData,
                    done: () => {
                        loading.value = false;
                    }
                });
            }
        };

        /*====================DIRECTORIES AREA=====================*/
        const {directories, getDirectories} = useDirectory(loading);
        onMounted(async() => {
            await getDirectories(currentWorkspaceId.value, 'service,bills');
        });


        /*====================BUTTON STATE AREA=====================*/
        const disabled = computed(() => {
            return !form.title.value || props.mode === 'create' ? !isPaymentPeriodDone.value : false;
        });

        /*======================PROJECTS AREA=======================*/
        const {getProjects, projects} = useProjectV2();
        const onProjectSearch = (q) => {
            debounce(async () => {
                await getProjects(currentWorkspaceId.value, {}, q);
            }, 500)();
        };
        const contact = ref({});
        watch(() => form.projectId.value, () => {
            const project = projects.value.list.find(x => x.id === form.projectId.value?.id);
            contact.value = project?.client || {};
            form.contactId.value = project?.client;
        });
        onMounted(async () => {
            await getProjects(currentWorkspaceId.value, {});
        });
        return {
            form,
            members,
            loading,
            contact,
            disabled,
            projects,
            directories,
            currentModal,
            additionalModals,
            currentWorkspaceId,
            servicePaymentType,
            servicePaymentTypeMap,
            truncateNumber,
            onMembersSearch,
            onProjectSearch,
            selectFilterFunc,
            onDirectoryAdded,
            setModalVisibility,
            onPaymentPeriodChanged,
            createServiceWrapper,
        };
    }
};
</script>

<style lang="scss" scoped>
.add-service-form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    &__pairs {
        height: 50px;
    }
}
</style>
