<template lang="pug">
a-empty.add-directory
    template(#description)
        .add-directory
            span Не найдено
            a(href="" @click.stop.prevent="onClick") добавить?
</template>

<script>
export default {
    name: 'AddDirectory',
    props: {
        onClick: {
            type: Function,
            default: () => ({})
        }
    }
};
</script>

<style lang="scss" scoped>
.add-directory {
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
</style>