import {httpInstance} from '@/modules/http_factory';
import {ListOf} from '@/core/common/interface/list-of.interface';
import {TimeLineInterface} from '@/modules/resource-management/entities/time-line.interface';
import {TimeLineWorkspaceMemberInterface} from '@/modules/resource-management/entities/time-line-workspace-member.interface';
import {TimeLineFormInterface} from '@/modules/resource-management/form/time-line-form.interface';
import {TimeLineMapper} from '@/modules/resource-management/mappers/calendar-slot.mapper';
import {OkResponse} from '@/core/common/interface/ok-response.interface';
import {TimeLineSettingsInterface} from '@/modules/resource-management/filters/time-line-settings.interface';

const getTimeLines = async (params: TimeLineSettingsInterface): Promise<ListOf<TimeLineInterface>> => {
    const response = await httpInstance().get('time-lines', {
        params: TimeLineMapper.formFilter(params),
    });
    return {
        list: response.data.list,
        next: response.data.next,
        total: response.data.total,
    };
};
const getTimeLineMembers = async (params: TimeLineSettingsInterface): Promise<TimeLineWorkspaceMemberInterface[]> => {
    const response = await httpInstance().get('time-lines/members', {
        params: TimeLineMapper.formFilter(params)
    });
    return response.data;
};

const createTimeLine = async (params: TimeLineFormInterface): Promise<TimeLineInterface> => {
    const response = await httpInstance().post('time-lines', TimeLineMapper.toCreateDto(params));
    return response.data;
};

const updateTimeLine = async (id: number, params: TimeLineFormInterface): Promise<TimeLineInterface> => {
    const response = await httpInstance().patch(`time-lines/${id}`, TimeLineMapper.toUpdateDto(params));
    return response.data;
};

const deleteTimeLine = async (id: number): Promise<OkResponse> => {
    const response = await httpInstance().delete(`time-lines/${id}`);
    return response.data;
};

const getTimeLine = async (id: number): Promise<TimeLineInterface> => {
    const response = await httpInstance().get(`time-lines/${id}`);
    return response.data;
};

export default {
    getTimeLine,
    getTimeLines,
    createTimeLine,
    updateTimeLine,
    deleteTimeLine,
    getTimeLineMembers,
};