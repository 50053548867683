<template lang="pug">
.create-integration
    a-select.mb-3(:value="integration.provider" @change="onProviderChanged" style="width:100%" placeholder="Провайдер")
        a-select-option(v-for="provider in providers" :key="provider") {{ provider }}
    integration-auth(:provider="integration.provider" v-if="integration.provider")
</template>

<script>
import IntegrationAuth from './Auth';
import {useIntegration} from '@/composition/integration';
import {providers} from '@/components/Integrations/CreateIntegration/types';
export default {
    name: 'CreateIntegration',
    components: {
        IntegrationAuth,
    },
    setup() {
        const {
            integration,
            setIntegration,
        } = useIntegration();
        const onProviderChanged = (provider) => {
            setIntegration({provider});
        };
        return {
            integration,
            setIntegration,
            onProviderChanged,
            providers,
        };
    }
};
</script>

<style lang="scss" scoped>
.create-integration {
    max-width: 400px;
}
</style>