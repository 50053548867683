<template lang="pug">
.input-with-error(:class="[error ? 'input--error' : '']")
    slot(name="label" )
    slot(name="input")
    .input-with-error__message(v-if="error") {{ error }}
</template>

<script>
export default {
    name: 'InputWithError',
    props: {
        error: String,
    }
};
</script>

<style lang="scss" scoped>
.input-with-error {
    width: 100%;
    &__message {
        font-size: 10px;
        color: rgba(240,3,50,.73)
    }
}
</style>