import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "service-payment-period-performers-list" }
const _hoisted_2 = { class: "performers-list__item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.performers, (performer) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode($setup["ServicePaymentPeriodPerformersListItem"], {
          class: "mt-2",
          key: performer.id,
          item: performer,
          onChange: (e) => $setup.onItemChanged(e, performer),
          onDelete: _cache[0] || (_cache[0] = (e) => $setup.onItemDelete(e)),
          "is-plan": $props.isPlan
        }, null, 8, ["item", "onChange", "is-plan"])
      ]))
    }), 256))
  ]))
}