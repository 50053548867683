<template lang="pug">
.salary-payments-list-create-form
    .salary-payments-list-create-form__members
        card-title(size="small") Получатели
        a-select(
            mode="multiple"
            :label-in-value="true"
            v-model:value="form.members.value"
            :options="labeledMembers"
            style="width: 100%"
            placeholder="Получатели"
        )
    .salary-payment-list-crete-form__dates.mt-2
        div
            card-title(size="small") Период расчета
            .flex.items-center
                date-only-picker(
                    v-model:date="form.dateFrom.value"
                    placeholder="от"
                )
                date-only-picker.ml-2(
                    v-model:date="form.dateTo.value"
                    placeholder="до"
                )
        .ml-3
            card-title(size="small") Период начисления
            transaction-period-select(v-model:period="form.period.value")
        .ml-3
            card-title(size="small") Оплатить до
            date-only-picker(
                v-model:date="form.payDateTo.value"
                placeholder="оплатить до"
            )
    a-button.mt-2(@click="createList" type="primary" :disabled="disabledAction" :loading="actionLoading") Сформировать расчетный лист
    a-divider
    salary-payments-list-members(
        :list-members="form.listMembers.value"
        @positions-added="onPositionsAdded"
        @position-deleted="onPositionDeleted"
        @member-deleted="onMemberDeleted"
        @position-field-updated="onPositionFieldUpdated"
        :from="dateFromStr"
        :to="dateToStr"
    )
    a-divider
    a-button.mt-2(@click="() => onFormDone(form)" type="primary" :loading="loading" :disabled="disabled") Сохранить
</template>

<script>
export default {
    name: 'SalaryPaymentsListCreateForm'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/

import {computed, onMounted, reactive, ref} from 'vue';
import {getForm} from '@/components/SalaryPaymentsList/constants';
import {useDate} from '@/composition/date';
import TransactionPeriodSelect from '@/components/Transactions/CreateForm/PeriodSelect';
import CardTitle from '@/components/Generic/Typography/CardTitle';
import {sleep} from '@/modules/utils';
import SalaryPaymentsListMembers from '@/components/SalaryPaymentsList/Members';
import {toLabelValue} from '@/modules/utils';
import {useWorkspace} from '@/composition/workspace';
import {useSalary} from '@/composition/salary-list';
import DateOnlyPicker from '@/components/Generic/DateOnlyPicker';
/*===============INIT AREA===================*/
const {getMembers, members, currentWorkspaceId} = useWorkspace();
const actionLoading = ref(false);
const props = defineProps({
    defaultValue: {
        type: Object,
    },
    mode: {
        type: String
    },
    workspaceId: {
        type: Number,
    },
    loading: {
        type: Boolean
    }
});

const emit = defineEmits(['create', 'update']);

const {getMonthFromNow, getDateFromNow, toDateWithUtcOffset, dateInstance} = useDate();
const {getMembersSalaryPositions} = useSalary();
const form = reactive(getForm(props.defaultValue, getMonthFromNow, getDateFromNow));
/*===============ACTIONS AREA===================*/
const createList = async () => {
    actionLoading.value = true;
    const from = toDateWithUtcOffset(form.dateFrom.value, {isStart: true});
    const to = toDateWithUtcOffset(form.dateTo.value, {isEnd: true});
    const {result: positions} = await getMembersSalaryPositions(form.members.value.map(x => x.value), from, to);
    form.listMembers.value = positions;
    actionLoading.value = false;
};
const onPositionsAdded = (data) => {
    form.listMembers.value = form.listMembers.value.map(x => {
        if (x.id === data.workspaceMemberId) {
            x.positions = x.positions.concat(data.positions.map(r => ({...r})));
        }
        return x;
    });
};
const onPositionDeleted = ({id, workspaceMemberId}) => {
    form.listMembers.value = form.listMembers.value.map(x => {
        if (x.id === workspaceMemberId) {
            x.positions = x.positions.filter(sp => sp.id !== id);
        }
        return x;
    });
};
const onMemberDeleted = (id) => {
    form.members.value = form.members.value.filter(x => x.value !== id);
    form.listMembers.value = form.listMembers.value.filter(x => x.id !== id);
};
const onPositionFieldUpdated = (payload) => {
    form.listMembers.value = form.listMembers.value.map(x => {
        if (x.id === payload.workspaceMemberId) {
            x.positions = x.positions.map(sp => {
                if (sp.id === payload.id) {
                    sp = {
                        ...sp,
                        ...payload.updateData
                    };
                }
                return sp;
            });
            return x;
        }
        return x;
    });
};
/*===============COMPUTED AREA===================*/
const dateFromStr = computed(() => {
    return dateInstance()(form.dateFrom.value).format('YYYY-MM-DD');
});
const dateToStr = computed(() => {
    return dateInstance()(form.dateTo.value).format('YYYY-MM-DD');
});
const labeledMembers = computed(() => {
    return toLabelValue(members.value.list, 'id', 'fullName');
});
const disabledAction = computed(() => {
    return !form.members.value.length || !form.dateFrom.value || !form.dateTo.value || !form.period.value || !form.payDateTo.value;
});
const disabled = computed(() => {
    return (
        !form.members.value.length ||
        !form.listMembers.value.length ||
        disabledAction.value ||
        !form.listMembers.value.every(x => x.positions.length)
    );
});
/*===============FORM METHODS AREA===================*/
const clearErrors = (formData) => {
    for (const formDataKey in formData) {
        formData[formDataKey].error = undefined;
    }
    return true;
};
const onFormDone = (formData) => {
    console.log(formData.dateFrom.value);
    const payload = {
        members: formData.members.value,
        dateFrom: toDateWithUtcOffset(formData.dateFrom.value,{isStart: true}),
        dateTo: toDateWithUtcOffset(formData.dateTo.value,{isEnd: true}),
        period: formData.period.value.value,
        payDateTo: toDateWithUtcOffset(form.payDateTo.value, {isEnd: true}),
        listMembers: formData.listMembers.value,
    };
    if (props.mode === 'edit') {
        emit('update', {...payload, id: props.defaultValue.id});
    } else {
        emit('create', payload);
    }
};

/*=======================FETCH DATA AREA=======================*/
const fetchData = async () => {
    await getMembers(currentWorkspaceId.value);
};
onMounted(async () => {
    await fetchData();
});
</script>

<style scoped lang="scss">
.salary-payment-list-crete-form {
    &__dates {
        display: flex;
        align-items: center;
    }
}
</style>