<template lang="pug">
bills-table(:serviceId="route.params.serviceId")
</template>

<script>
import BillsTable from '@/components/Bills/Table';
import {useRoute} from 'vue-router';
export default {
    name: 'ServiceBills',
    components: {BillsTable},
    setup() {
        const route = useRoute();
        return {
            route
        };
    }
};
</script>

<style scoped>

</style>