<template lang="pug">
.service-performers-table
    custom-table(:columns="servicePerformersColumns" :dataSource="service.performers" :loading="loading")
        template(#performer="{record}")
            div(v-if="record") {{ record?.workspaceMember?.fullName }}
</template>

<script>
import {performerPaymentTypeMap, serviceDocumentStatusMap, servicePerformersColumns} from '../../constants';
import {ref} from 'vue';
import {useWorkspace} from '@/composition/workspace';
import CreateProjectForm from '@/components/Projects/CreateProjectForm';
import EditableCell from '@/components/Generic/EditableCell';
import {useService} from '@/composition/service';
import CreateServiceForm from '@/components/Services/CreateForm';
import {useDate} from '@/composition/date';
import ServiceFilter from '@/components/Services/Filter';
import {useRoute} from 'vue-router';
import moment from 'moment';
import ServicePerformer from '@/components/Services/Performers/List/Item';
import workspaceMemberConstants from '@/components/Workspace/InviteUserForm/constants';
import CustomTable from '@/components/Generic/CustomTable';
import {useCreateOrUpdateModal} from '@/composition/create-update-modal';
import {formatCurrency, getPercent} from '@/modules/utils';
import StatusProgress from '@/components/Generic/StatusProgress';
export default {
    name: 'ServicePerformersTable',
    components: {
        CustomTable,
        ServicePerformer,
        ServiceFilter,
        CreateServiceForm,
        EditableCell,
        CreateProjectForm,
        StatusProgress
    },
    async setup() {
        const {
            currentMode,
            editingValue: editingElement,
            showModal,
            setModalVisibility,
            edit
        } = useCreateOrUpdateModal();
        const loading = ref(false);
        const route = useRoute();
        const {
            service,
            getService,
            createServicePerformer,
            deleteServicePerformer,
            updateServicePerformer,
            getDocumentStatusOptions
        } = useService(loading);
        const {currentWorkspaceId} = useWorkspace(loading);
        const {formatDate} = useDate();
        const onCreate = async (data) => {
            if (currentMode.value === 'create') {
                await createServicePerformer({
                    workspaceId: currentWorkspaceId.value,
                    serviceId: route.params.serviceId,
                    ...data
                });
            }
            await getService(route.params.serviceId, currentWorkspaceId.value);
            setModalVisibility(false);
        };
        const onDelete = async (id) => {
            await deleteServicePerformer(id, currentWorkspaceId.value);
            await getService(route.params.serviceId, currentWorkspaceId.value);
        };
        const onUpdate = async (id, updateData, workspaceId) => {
            await updateServicePerformer(id, updateData, workspaceId);
            await getService(route.params.serviceId, currentWorkspaceId.value);
        };
        const editPerformer = (performer) => {
            edit({
                ...performer,
                fullName: performer?.workspaceMember?.fullName || performer.fullName,
                paymentDates: {
                    dayNumberOne: performer.paymentDayNumberOne,
                    dayNumberTwo: performer.paymentDayNumberTwo,
                    count: performer.paymentCount,
                }
            });
        };
        const renderPaymentDates = (performer) => {
            let str = '';
            if (performer.paymentDayNumberOne) {
                const firstDate = moment().set('date', performer.paymentDayNumberOne);
                str += formatDate(firstDate);
            }
            if (performer.paymentDayNumberTwo && performer.paymentCount === workspaceMemberConstants.month.twice) {
                str += `, ${formatDate(moment().set('date', performer.paymentDayNumberTwo))}`;
            }
            return str;
        };
        const updatePaymentAgreement = async (performerId, e) => {
            const isPaymentAgreed = e.target.checked;
            await updateServicePerformer(performerId, {isPaymentAgreed}, currentWorkspaceId.value);
            await getService(route.params.serviceId, currentWorkspaceId.value);
        };
        return {
            service,
            renderPaymentDates,
            updatePaymentAgreement,
            servicePerformersColumns,
            loading,
            setModalVisibility,
            showModal,
            onCreate,
            onDelete,
            onUpdate,
            getDocumentStatusOptions,
            currentWorkspaceId,
            performerPaymentTypeMap,
            serviceDocumentStatusMap,
            formatDate,
            formatCurrency,
            currentMode,
            editingElement,
            editPerformer,
            getPercent,
            ...useDate(),
        };
    }
};
</script>

<style lang="scss" scoped>

</style>
