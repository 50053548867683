<template lang="pug">
.workspace-members-checklist(v-if="!templateLoading")
    card-title Список участников рабочего пространства
    .workspace-members-checklist__list
        div.my-3(v-for="(value, key) in widgetSettings")
            .flex.items-center
                a-checkbox(:checked="value" @change="onCheckboxChanged(key, $event)") {{ getMemberName(key) }}
    a-button(type="primary" @click="saveSettings" :loading="loading") Сохранить
.workspace-members-checklist(v-else)
    icon(type="Loader")
</template>

<script>
export default {
    name: 'WorkspaceMembersChecklist'
};
</script>

<script setup>

/*===============IMPORT AREA===================*/
import {onMounted, ref} from 'vue';
import {useWidget} from '@/composition/widget';
import {useWorkspace} from '@/composition/workspace';
import Icon from '@/components/Generic/Typography/Icon';

/*===============INIT AREA===================*/

const props = defineProps({
    widgetType: {
        type: String,
    },
});
const emit = defineEmits(['done']);
const loading = ref(false);
const templateLoading = ref(false);
const {getSettings, updateSettings, widgetSettings} = useWidget(props.widgetType, loading);
const {members, getMembers, currentWorkspaceId} = useWorkspace();

/*===============FETCH DATS AREA===================*/

const fetchData = async () => {
    await getMembers(currentWorkspaceId.value);
    await getSettings(members.value.list);
};

/*===============RENDERERS AREA===================*/

const getMemberName = (id) => {
    return members.value.list.find(x => x.id == id)?.fullName || 'Не указано';
};
/*===============HANDLE CHANGES AREA===================*/
const onCheckboxChanged = (id, e) => {
    widgetSettings.value[id] = e.target.checked;
};
const saveSettings = async () => {
    await updateSettings(widgetSettings.value);
    emit('done');
};

/*===============HOOKS AREA===================*/

onMounted(async () => {
    templateLoading.value = true;
    await fetchData();
    templateLoading.value = false;
});
</script>

<style lang="scss">
</style>