<template lang="pug">
.document-service
    .document-service__service
        services-select(
            :modelValue="documentService.serviceId"
            @update:modelValue="onChange('serviceId', $event)"
            :additionalFilter="additionalServiceFilter"
        )
        .ml-3(@click="deleteItem")
            icon(type="Delete")
        .flex.items-center.ml-3(v-if="documentService.serviceId")
            custom-select-v2(
                placeholder="Период не выбран"
                :modelValue="documentService.paymentPeriod"
                @update:modelValue="onChange('paymentPeriod', $event)"
                :options="paymentPeriods"
                :style="{width: '230px'}"
                :clearable="true"
            )
    document-service-item.mt-3.ml-3(
        v-for="item in documentService.items"
        :key="item.id"
        :documentServiceItem="item"
        @update="onUpdate"
        @delete="onDelete"
        :directories="directories"
        :canDelete="documentService.items.length > 1"
        :hidePositions="hidePositions"
    )
    .flex.items-center(v-if="!hidePositions")
        a-button.mt-3.ml-3(type="primary" @click="addNew") {{ documentService.items.length === 0 ? 'Добавить' : 'Еще' }}
        .ml-3 Итого: {{ total }}
    a-divider
</template>

<script>
import {useService} from '@/composition/service';
import {ref, computed, onMounted} from 'vue';
import {useWorkspace} from '@/composition/workspace';
import DocumentServiceItem from '@/components/Documents/Services/ServiceItem';
import Icon from '@/components/Generic/Typography/Icon';
import ServicesSelect from '@/components/Services/Select';
import {servicePaymentType} from '@/components/Services/constants';
import CustomSelectV2 from '@/components/Generic/CustomSelectV2/index';
export default {
    name: 'DocumentService',
    components: {CustomSelectV2, Icon, DocumentServiceItem, ServicesSelect},
    props: {
        performerId: Number,
        documentService: Object,
        directories: Object,
        additionalServiceFilter: Object,
        hidePositions: Boolean
    },
    emits: ['update', 'delete', 'log-data'],
    setup(props, {emit}) {
        const {currentWorkspaceId} = useWorkspace();
        const {getServices, services, setFilter: setServiceFilter, getService} = useService();
        const paymentPeriods = ref([]);
        const defaultItem = () => ({
            id: Symbol(Date.now()),
            title: undefined,
            amount: props.hidePositions ? 1 : undefined,
            unitId: undefined,
            sum: undefined,
        });
        const onServiceChanged = async (id) => {
            if (!id) return;
            const {ok, result: service} = await getService(id, currentWorkspaceId.value);
            if (ok && service) {
                paymentPeriods.value = service.paymentPeriods;
            }
        };
        const onPaymentPeriodChanged = (paymentPeriod) => {
            if (!paymentPeriod) return false;
            setLogData(paymentPeriod.id);
            return true;
        };
        const onChange = (key, value) => {
            if (key === 'serviceId') {
                onServiceChanged(value);
                emit('update', {
                    ...props.documentService,
                    serviceId: value,
                    paymentPeriod: null,
                });
                return true;
            }
            if (key === 'paymentPeriod') {
                onPaymentPeriodChanged(value);
            }
            emit('update', {
                ...props.documentService,
                [key]: value,
            });
            return true;
        };
        const onUpdate = (documentServiceItem) => {
            const documentService = props.documentService;
            const documentServiceItems = documentService.items || [];
            onChange('items', documentServiceItems.map(x => {
                if (x.id == documentServiceItem.id) {
                    return {
                        ...x,
                        ...documentServiceItem
                    };
                }
                return x;
            }));
        };
        const deleteItem = () => {
            emit('delete', props.documentService.id);
        };
        const onDelete = (id) => {
            onChange('items', props.documentService.items.filter(x => x.id != id));
        };
        const onSearch = async (q) => {
            setServiceFilter({search: q});
            await getServices(currentWorkspaceId.value);
        };
        const addNew = () => {
            onChange('items', [...props.documentService.items, defaultItem()]);
        };

        const setLogData = (paymentPeriodId) => {
            props.documentService.serviceId && emit('log-data', {
                paymentPeriodId,
                serviceId: props.documentService.serviceId
            });
        };
        const total = computed(() => {
            return props.documentService.items.reduce((prev, curr) => {
                return prev + (curr.amount || 0) * (curr.sum || 0);
            }, 0);
        });
        onMounted(() => {
            onServiceChanged(props.documentService?.serviceId);
        });
        return {
            addNew,
            total,
            onDelete,
            onSearch,
            services,
            onChange,
            onUpdate,
            deleteItem,
            setLogData,
            servicePaymentType,
            paymentPeriods,
            onPaymentPeriodChanged,
        };
    }
};
</script>

<style lang="scss" scoped>
.document-service__service {
    display: flex;
    align-items: center;
}
</style>
