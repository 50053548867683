<template lang="pug">
.bill-service-item
    .bill-service-item__row
        a-input.mr-2(
            :value="billServiceItem.title"
            placeholder="Наименование"
            style="width: 100%"
            @change="onChange('title', $event.target.value)"
        )
    .bill-service-item__row
        a-input-number.mr-2(:value="billServiceItem.amount" placeholder="Кол-во" style="width: 20%" @change="onChange('amount', $event)")
        a-select.mr-2(
            :value="billServiceItem.unitId"
            placeholder="Ед. измерения"
            show-search
            :filter-option="selectFilterFunc"
            style="width: 30%"
            @change="onChange('unitId', $event)"
        )
            a-select-option(
                v-for="unit in directories.units.list"
                :key="unit.id"
                :value="unit.id"
                :label="unit.title"
            )
                | {{ unit.title }}
        a-input-number.mr-2(:value="billServiceItem.sum" placeholder="Стоимость" style="width: 30%" @change="onChange('sum', $event)")
        div(style="width: 30%;white-space:nowrap") Итого: {{ total }}
        div(@click="deleteItem" v-if="canDelete")
            icon(type="Delete")
</template>

<script>
import {computed} from 'vue';

export default {
    name: 'BillServiceItem',
    props: {
        billServiceItem: Object,
        directories: Object,
        canDelete: {
            type: Boolean
        }
    },
    emits: ['update', 'delete'],
    setup(props, {emit}) {
        const total = computed(() => (props.billServiceItem.amount || 0) * (props.billServiceItem.sum || 0));
        const onChange = (key, value) => {
            emit('update', {
                ...props.billServiceItem,
                [key]: value
            });
        };
        const deleteItem = () => {
            emit('delete', props.billServiceItem.id);
        };
        return {
            total,
            onChange,
            deleteItem,
        };
    }
};
</script>

<style lang="scss" scoped>
.bill-service-item {
    &__row {
        margin-bottom: 12px;
        display: flex;
        align-items: center;
    }
}
</style>