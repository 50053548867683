import {apiRequest} from '@/modules/error_handler';
import transactionModule from '@/modules/transaction';
import {ref} from 'vue';
const pagination = ref({
    limit: 50,
    offset: 0,
});
const transactions = ref({
    list: [],
    next: '',
    prevPage: '',
    total: '',
    totalPages: '',
});
const balanceHistory = ref([]);
const transaction = ref({});
const filter = ref({});
export function useTransaction(loading={value: false}) {
    const transactionStats = ref({
        in: 0,
        out: 0,
        diff: 0,
    });
    const createTransaction = async (workspaceId, transactionData) => {
        return apiRequest(async() => {
            await transactionModule.createTransaction(workspaceId, transactionData);
        }, loading);
    };
    const getTransactionById = async(workspaceId, id) => {
        return apiRequest(async() => {
            transaction.value = await transactionModule.getTransactionById(workspaceId, id);
            return transaction.value;
        }, loading);
    };
    const getBalances = async(workspaceId) => {
        return apiRequest(async() => {
            return transactionModule.getBalances(workspaceId);
        }, loading);
    };
    const getTransactionStats = async(workspaceId) => {
        return apiRequest(async() => {
            transactionStats.value = await transactionModule.getTransactionStats(workspaceId, filter.value);
            return transactionStats.value;
        }, loading);
    };
    const getBalanceHistory = async (workspaceId) => {
        return apiRequest(async() => {
            const response = await transactionModule.getBalanceHistory(workspaceId, filter.value);
            balanceHistory.value = response.map(x => ({
                id: x.id,
                date: x.date,
                value: x.balance,
            }));
            return balanceHistory.value;
        }, loading);
    };

    const updateTransaction = async(workspaceId, id, updateData) => {
        return apiRequest(async() => {
            return transactionModule.updateTransaction(workspaceId, id, updateData);
        });
    };
    const deleteTransaction = async(workspaceId, id) => {
        return apiRequest(async() => {
            return transactionModule.deleteTransaction(workspaceId, id);
        });
    };
    const deleteServiceTransaction = async(workspaceId, id) => {
        return apiRequest(async() => {
            return transactionModule.deleteServiceTransaction(workspaceId, id);
        });
    };
    const getTransactions = async (workspaceId, meta={}, tz) => {
        return apiRequest(async() => {
            const result = await transactionModule.getTransactions(workspaceId, {
                limit: pagination.value.limit,
                offset: pagination.value.offset,
            }, filter.value, tz);
            if (meta.fromPagination) {
                transactions.value.list = transactions.value.list.concat(result.list);
                transactions.value.next = result.next;
            } else {
                transactions.value = result;
            }
            return transactions.value;
        }, !meta.fromPagination ? loading : {value: false});
    };
    const getServiceTransactions = async (workspaceId, serviceId, meta={}) => {
        return apiRequest(async() => {
            const result = await transactionModule.getServiceTransactions(workspaceId, serviceId, {
                limit: pagination.value.limit,
                offset: pagination.value.offset,
            }, filter.value);
            if (meta.fromPagination) {
                transactions.value.list = transactions.value.list.concat(result.list);
                transactions.value.next = result.next;
            } else {
                transactions.value = result;
            }
            return transactions.value;
        }, loading);
    };
    const setFilter = (filterData) => {
        filter.value = filterData;
    };
    const updateOneLocal = (updatedTransaction) => {
        transactions.value.list = transactions.value.list.map(x => {
            if (x.id === updatedTransaction.id) {
                return updatedTransaction;
            }
            return x;
        });
    };

    const updateRepeatableTransactions = async(id, updateData) => {
        return apiRequest(async() => {
            return transactionModule.updateRepeatableTransactions(id, updateData);
        });
    };
    const deleteRepeatableTransactions = async (id, type) => {
        return apiRequest(async() => {
            return transactionModule.deleteRepeatableTransactions(id, type);
        });
    };
    return {
        pagination,
        filter,
        transactions,
        balanceHistory,
        transactionStats,
        updateOneLocal,
        createTransaction,
        getBalanceHistory,
        getTransactionById,
        updateTransaction,
        deleteTransaction,
        getTransactions,
        setFilter,
        getBalances,
        getServiceTransactions,
        deleteServiceTransaction,
        getTransactionStats,
        updateRepeatableTransactions,
        deleteRepeatableTransactions,
    };
}
