<template lang="pug">
.contacts
    a-input.mr-3(
        placeholder="Поиск по названию контакта, ФИО контактного лица"
        v-model:value="filter.search"
    )
    a-select.mr-3(
        v-model:value="filter.status"
        placeholder="Статус"
        style="width: 10%"
        allowClear
    )
        a-select-option(
            :key="contactStatus.active"
            :value="contactStatus.active"
        )
            | {{ contactStatusMap.active }}
        a-select-option(
            :key="contactStatus.archived"
            :value="contactStatus.archived"
        )
            | {{ contactStatusMap.archived }}
a-tabs(v-model:activeKey="filter.contactGroupId")
    a-tab-pane(v-for="tab in tabs" :key="tab.key" :tab="`${tab.title} (${tab.count})`")
</template>

<script>
import {onMounted, reactive, watch} from 'vue';
import {selectFilterFunc} from '@/modules/utils';
import {contactStatus, contactStatusMap} from '@/components/Contacts/constants';
import {useRoute, useRouter} from 'vue-router';

export default {
    name: 'ContactsFilter',
    props: {
        tabs: {
            type: Array,
            default: () => ([])
        }
    },
    emits: ['filter'],
    async setup(props, {emit}) {
        const router = useRouter();
        const route = useRoute();
        const filter = reactive({
            search: '',
            status: undefined,
            contactGroupId: undefined
        });
        watch(filter, () => {
            router.push({
                query: {
                    contactGroupId: filter.contactGroupId
                }
            });
            emit('filter', filter);
        });
        onMounted(() => {
            const contactGroupId = route.query.contactGroupId;
            filter.status = contactStatus.active;
            filter.contactGroupId = contactGroupId && contactGroupId !== 'all' ? Number(contactGroupId) : 'all';
        });
        return {
            filter,
            contactStatus,
            contactStatusMap,
            selectFilterFunc
        };
    }
};
</script>

<style lang="scss" scoped>
.contacts {
    display: flex;
    align-items: center;
}
</style>