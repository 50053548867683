<template lang="pug">
profile-layout
    uploader(@upload="uploadPhoto" :targetTypes="['png', 'jpg', 'jpeg']")
        user-avatar(:user="user" showName)
    .profile-form
        input-with-error.mt-3(:error="form.email.error")
            template(#label)
                .input__label Email
            template(#input)
                a-input(placeholder="Email" v-model:value="form.email.value" disabled)
        input-with-error.mt-3(:error="form.fullName.error")
            template(#label)
                .input__label ФИО
            template(#input)
                a-input(placeholder="ФИО" v-model:value="form.fullName.value")
        a-button.mt-5.mb-2(
            type="primary"
            @click="updateUserDataWrapper"
            :loading="loading"
            :disabled="disabled"
        ) Сохранить
</template>

<script>
import ProfileLayout from '@/pages/Profile/Layout';
import {useAuth} from '@/composition/auth';
import {computed, reactive, ref} from 'vue';
import {useFormErrors} from '@/composition/errors';
import {notification} from 'ant-design-vue';
import {useWorkspace} from '@/composition/workspace';
import Uploader from '@/components/Generic/Uploader';

export default {
    name: 'Profile',
    components: {Uploader, ProfileLayout},
    setup() {
        const loading = ref(false);
        const {user, updateUserData, me} = useAuth(loading);
        const form = reactive({
            email: {
                value: user.value.email || '',
                error: ''
            },
            fullName: {
                value: user.value.fullName || '',
                error: ''
            },
        });
        const clearErrors = () => {
            form.email.error = '';
            form.fullName.error = '';
        };
        const {init} = useFormErrors();
        const {currentWorkspaceId} = useWorkspace(loading);
        const updateUserDataWrapper = async () => {
            const {ok} = await init(form, async () => {
                return updateUserData({
                    fullName: form.fullName.value,
                }, currentWorkspaceId.value);
            }, clearErrors);
            if (ok) {
                await me(currentWorkspaceId.value);
                notification.success({
                    message: 'Успех!',
                    description: 'Данные успешно сохранены!'
                });
            }
        };
        const disabled = computed(() => {
            return !form.email.value;
        });
        const uploadPhoto = async (data) => {
            const {ok} = await updateUserData({
                avatar: data.key
            }, currentWorkspaceId.value);
            if (ok) {
                await me(currentWorkspaceId.value);
                notification.success({
                    message: 'Успех!',
                    description: 'Данные успешно сохранены!'
                });
            }
        };
        return {
            uploadPhoto,
            user,
            form,
            disabled,
            loading,
            updateUserDataWrapper,
        };
    }
};
</script>

<style lang="scss" scoped>
.profile {
    &-form {
        margin-top: 20px;
        max-width: 400px;
    }
}
</style>