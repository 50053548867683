<template lang="pug">
settings-layout
    async-page
        integrations-list
</template>

<script>
import IntegrationsList from '@/components/Integrations/List';
import AsyncPage from '@/layouts/async-page';
import SettingsLayout from '@/pages/Settings/Layout';
export default {
    name: 'Integrations',
    components: {SettingsLayout, AsyncPage, IntegrationsList}
};
</script>

<style scoped>

</style>