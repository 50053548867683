<template lang="pug">
.contact-common-data
    .contact-common-data__content
        contact-about(:contact="contact" :directories="directories" @update="onUpdate")
        a-divider
        contact-person(:contact="contact" @update="onUpdate" v-if="contact.type === contactsType.entity")
        a-divider(v-if="contact.type === contactsType.entity")
        div(v-if="showMarketingFields")
            contact-finance(:contact="contact" @update="onUpdate")
            a-divider
            contact-other(:contact="contact" :members="members" :directories="directories" @update="onUpdate")
    .contact-common-data__actions
        card-title Действия
        a-popconfirm(
            v-if="!contact.isArchive"
            title="Вы уверены, что хотите архивировать контакт?"
            ok-text="Да"
            cancel-text="Нет"
            @confirm="onUpdate({isArchive: true})"
        )
            a-button.block.mt-2 Архивировать
        a-popconfirm(
            v-else
            title="Вы уверены, что хотите вернуть контакт в актуальное?"
            ok-text="Да"
            cancel-text="Нет"
            @confirm="onUpdate({isArchive: false})"
        )
            a-button.block.mt-2 Вернуть в актуальное
        a-popconfirm(
            title="Вы уверены, что хотите удалить контакт?"
            ok-text="Да"
            cancel-text="Нет"
            @confirm="deleteContactById"
        )
            a-button.block.mt-2(type="danger") Удалить
</template>

<script>
import ContactAbout from '@/components/Contacts/Common/About';
import {useRoute, useRouter} from 'vue-router';
import {computed, ref} from 'vue';
import {useContact} from '@/composition/contact';
import {useDirectory} from '@/composition/directory';
import {useWorkspace} from '@/composition/workspace';
import ContactPerson from '@/components/Contacts/Common/ContactPerson';
import ContactFinance from '@/components/Contacts/Common/Finance';
import ContactOther from '@/components/Contacts/Common/Other';
import {contactsType} from '@/components/Contacts/constants';
export default {
    name: 'ContactCommonData',
    components: {ContactOther, ContactFinance, ContactPerson, ContactAbout},
    async setup() {
        const loading = ref(false);
        const route = useRoute();
        const router = useRouter();
        const {currentWorkspaceId, getMembers, members} = useWorkspace(loading);
        const {getContactById, contact, updateContact, showMarketingFields, deleteContact} = useContact(loading);
        const {getDirectories, directories} = useDirectory(loading);
        await getContactById(currentWorkspaceId.value, route.params.contactId);
        await getMembers(currentWorkspaceId.value);
        await getDirectories(currentWorkspaceId.value, 'contacts');
        const onUpdate = async(data) => {
            await updateContact(currentWorkspaceId.value, route.params.contactId, data);
            await getContactById(currentWorkspaceId.value, route.params.contactId);
        };
        const deleteContactById = async() => {
            await deleteContact(currentWorkspaceId.value, route.params.contactId);
            await router.push(`/${currentWorkspaceId.value}/contacts`);
        };
        return {
            contact,
            directories,
            onUpdate,
            members,
            contactsType,
            showMarketingFields: computed(() => showMarketingFields(contact.value.contactGroupId, directories)),
            deleteContactById,
        };
    }
};
</script>

<style lang="scss" scoped>
.contact-common-data {
    display: grid;
    grid-template-columns: 1fr 400px;
    grid-gap: 20px;

    &__content {

    }

    &__actions {

     }
}
</style>