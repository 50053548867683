<template lang="pug">
.salary-list-page
    salary-list-filter(v-model:filter="filter" @update:filter="onFilterChanged" @fetch="() => fetchData()")
    .salary-list-page__widgets
        salary-list-widget(
            :fact="salaryStats.utilization.fact"
            :diff="salaryStats.utilization.diff"
            :plan="salaryStats.utilization.plan"
            :plan-text="salaryStats.utilization.planText"
            :fact-text="salaryStats.utilization.factText"
            :diff-text="salaryStats.utilization.diffText"
            :unit-text="salaryStats.utilization.unitText"
        )
        salary-list-widget(
            :plan="salaryStats.plan.plan"
            :plan-text="salaryStats.plan.planText"
            :unit-text="salaryStats.plan.unitText"
        )
        salary-list-widget(
            :fact="salaryStats.finance.fact"
            :diff="salaryStats.finance.diff"
            :plan="salaryStats.finance.plan"
            :plan-text="salaryStats.finance.planText"
            :fact-text="salaryStats.finance.factText"
            :diff-text="salaryStats.finance.diffText"
            :unit-text="salaryStats.finance.unitText"
        )
    salary-list-table.mt-3(
        :data="list"
        :workspace-id="currentWorkspaceId"
        :loading="listLoading"
        :period="filter?.period"
        @update="onUpdate"
        @create="onCreate"
        @delete="onDelete"
        @fetch="() => fetchData()"
    )
</template>

<script>
export default {
    name: 'SalaryListPage'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
import SalaryListFilter from '@/components/SalaryList/Filter';
import {onMounted, reactive, ref, watch} from 'vue';
import SalaryListWidget from '@/components/SalaryList/Widget';
import SalaryListTable from '@/components/SalaryList/Table';
import {useSalary} from '@/composition/salary-list';
import {useWorkspace} from '@/composition/workspace';
import {useRoute, useRouter} from 'vue-router';
import {useDate} from '@/composition/date';
import {useService} from '@/composition/service';

/*===============INIT AREA===================*/

const statsLoading = ref(false);
const listLoading = ref(false);
const router = useRouter();
const route = useRoute();
const {getMonthFromNow, formatMonth, getMonthRangeFromPeriod} = useDate();
const {getList, getStats, list, filter, salaryStats, updateSalary, createSalary, deleteSalary} = useSalary();
const {getPeriods, periods} = useService();
const {currentWorkspaceId} = useWorkspace();

/*===============FILTERS AREA===================*/
const setToQueryParams = (period) => {
    router.push({
        query: {
            period
        }
    });
};
const setQueryParamsToFilter = () => {
    const routeFilter = route.query;
    if (routeFilter && routeFilter.period) {
        filter.value = {
            ...filter.value,
            period: routeFilter.period
        };
    }
};
/*===============HANDLERS AREA===================*/
const onUpdate = async ({payload, done}) => {
    const {id, ...updateData} = payload;
    const {ok, result} = await updateSalary(id, updateData);
    done();
    if (ok && result) {
        await fetchData(false, true);
    }
};
const onDelete = async ({id, done}) => {
    const {ok, result} = await deleteSalary(id);
    done();
    if (ok && result) {
        await fetchData(false, true);
    }
};
const onCreate = async ({salaryPosition, done}) => {
    const {ok, result} = await createSalary({
        ...salaryPosition,
        period: filter.value.period,
    });
    done();
    if (ok && result) {
        await fetchData(false, true);
    }
};
/*===============FETCH DATA AREA===================*/
const fetchData = async (init=false, disableLoader=false) => {
    await getPeriods();
    const currentPeriod = getMonthFromNow().format('YYYY-MM-01');
    if (currentPeriod && init && !route.query?.period) {
        filter.value.period = currentPeriod;
        setToQueryParams(currentPeriod);
    }
    !disableLoader && (listLoading.value = true);
    await getList();
    !disableLoader && (listLoading.value = false);
};
const setDates = (targetPeriod) => {
    const [from, to] = getMonthRangeFromPeriod(targetPeriod);
    if (from === filter.value.from && to === filter.value.to) return;
    filter.value.from = from;
    filter.value.to = to;
};
/*===============WATCHERS AREA===================*/
watch(() => filter.value.period, () => {
    setToQueryParams(filter.value.period);
    setDates(filter.value.period);
});
const onFilterChanged = async (newFilter) => {
    await fetchData();
};
/*===============HOOKS AREA===================*/
onMounted(async () => {
    setQueryParamsToFilter();
    await fetchData(true);

});
</script>

<style lang="scss">
.salary-list-page {
    &__widgets {
        margin-top: 24px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 16px;
    }
}
</style>