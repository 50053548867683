<template lang="pug">
card-title Финансы
.invite-user-form.mt-2
    .flex
        input-with-error(:error="hourlyRate.error")
            template(#input)
                a-input-number(placeholder="Часовая ставка" :value="hourlyRate.value" :min="0" style="width: 100%" @change="onChange('hourlyRate', $event)")
        input-with-error.ml-3(:error="currency.error")
            template(#input)
                currency-select(:value="currency.value"  @change="onChange('currency', $event)")
</template>

<script>
import CurrencySelect from '@/components/Generic/CurrencySelect';
export default {
    name: 'FinanceForm',
    components: {CurrencySelect},
    props: {
        hourlyRate: Object,
        currency: Object,
    },
    emits: ['update:hourlyRate', 'update:currency'],
    setup(props, {emit}) {
        const onChange = (key, value) => {
            emit(`update:${key}`, {value, error: undefined});
        };
        return {
            onChange
        };
    }
};
</script>

<style scoped>

</style>