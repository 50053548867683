import date_utils from './date_utils';
import {$, animateSVG, createSVG} from './svg_utils';

export default class Ghost {
    constructor(gantt, position) {
        this.set_defaults(gantt, position);
        this.draw();
    }

    set_defaults(gantt, position) {
        this.position = position;
        this.action_completed = false;
        this.gantt = gantt;
        this.x = this.calculate_x();
        this.index = this.position.index;
        this.height = gantt.options.bar_height;
        this.corner_radius = gantt.options.bar_corner_radius;
        this.y = this.calculate_y();
        this.duration =
            date_utils.diff(this.position.end, this.position.start, 'hour') /
            this.gantt.options.step;

        this.width = 1;
    }
    calculate_y() {
        return (
            this.gantt.options.header_height +
            this.gantt.options.padding +
            this.index * (this.height + this.gantt.options.padding)
        );
    }
    calculate_x() {
        const {step, column_width} = this.gantt.options;
        const start = this.position.start;
        const gantt_start = this.gantt.gantt_start;

        const diff = date_utils.diff(start, gantt_start, 'hour');
        let x = diff / step * column_width;

        if (this.gantt.view_is('Month')) {
            const diff = date_utils.diff(start, gantt_start, 'day');
            x = diff * column_width / 30;
        }
        return x;
    }
    draw() {
        this.draw_ghost();
    }

    draw_ghost() {
        this.$ghost = createSVG('rect', {
            x: this.x,
            y: this.y,
            width: this.width,
            height: this.height,
            rx: this.corner_radius,
            ry: this.corner_radius,
            class: 'ghost-bar',
            append_to: this.gantt.$svg
        });

        animateSVG(this.$ghost, 'width', 0, this.width);
    }


    update_ghost_position({start = null, end=null}) {
        const ghost = this.$ghost;
        if (start && end) {
            // console.log(start, end);
            this.position.start = start;
            this.position.end = end;
            this.duration =
                date_utils.diff(this.position.end, this.position.start, 'hour') /
                this.gantt.options.step;
            this.width = this.gantt.options.column_width * this.duration;
            this.update_attr(ghost, 'width', this.width);
        }
    }

    set_action_completed() {
        this.action_completed = true;
        setTimeout(() => (this.action_completed = false), 1000);
    }

    update_attr(element, attr, value) {
        value = +value;
        if (!isNaN(value)) {
            element.setAttribute(attr, value);
        }
        return element;
    }
    destroy() {
        this.$ghost.remove();
    }
}


