<template lang="pug">
.contacts
        contacts-select.mr-3(
            v-model="filter.contractorId"
            placeholder="Поиск по названию контрагента"
        )
        custom-select.mr-3(
            v-model="filter.paymentStatuses"
            title="Статус оплаты счета"
            :options="directories.billPaymentStatuses.list"
            mode="multiple"
        )
        a-select.mr-3(
            v-model:value="filter.dateStatuses"
            placeholder="Статус просрочки счета"
            style="width: 100%"
            allowClear
            mode="multiple"
        )
            a-select-option(
                v-for="billStatus in directories.billDateStatuses.list"
                :key="billStatus.id"
                :value="billStatus.id"
            ) {{ billStatus.title }}
        a-select.mr-3(
            v-model:value="filter.statuses"
            placeholder="Статус счета"
            style="width: 100%"
            allowClear
            mode="multiple"
        )
            a-select-option(
                v-for="billStatus in directories.billStatuses.list"
                :key="billStatus.id"
                :value="billStatus.id"
            ) {{ billStatus.title }}
a-tabs(v-model:activeKey="filter.contactGroupId")
    a-tab-pane(
        v-for="tab in tabs"
        :key="tab.key"
        :tab="`${tab.title} (${tab.count})`"
    )
</template>

<script>
import {onMounted, reactive, watch} from 'vue';
import {selectFilterFunc} from '@/modules/utils';
import {contactStatus, contactStatusMap} from '@/components/Contacts/constants';
import {useRoute, useRouter} from 'vue-router';
import SelectSuspense from '@/components/Generic/SelectSuspense';
import ContactsSelect from '@/components/Contacts/Select/Contacts';
import CustomSelect from '@/components/Generic/CustomSelect';
import {BillPaymentStatusKey} from '@/components/Bills/constants';

export default {
    name: 'BillsFilter',
    components: {ContactsSelect, SelectSuspense, CustomSelect},
    props: {
        tabs: {
            type: Array,
            default: () => ([])
        },
        directories: Object,
        serviceId: Number,
    },
    emits: ['filter'],
    async setup(props, {emit}) {
        const router = useRouter();
        const route = useRoute();
        const filter = reactive({
            contactGroupId: undefined,
            contractorId: undefined,
            state: 'active',
            statuses: [],
            paymentStatuses: [],
            serviceId: props.serviceId,
        });
        const processDefaultFilter = (paymentBillStatuses) => {
            if (!paymentBillStatuses || !Array.isArray(paymentBillStatuses)) return false;
            const notPaidStatus = paymentBillStatuses.find(x => x.key === BillPaymentStatusKey.not_paid);
            const partiallyPaidStatus = paymentBillStatuses.find(x => x.key === BillPaymentStatusKey.partially_paid);
            if (notPaidStatus && partiallyPaidStatus) {
                filter.paymentStatuses = [partiallyPaidStatus, notPaidStatus];
                return true;
            }
            return false;
        };
        watch(filter, () => {
            router.push({
                query: {
                    contactGroupId: filter.contactGroupId
                }
            });
            emit('filter', filter);
        });
        onMounted(() => {
            const contactGroupId = route.query.contactGroupId;
            filter.contactGroupId = contactGroupId && contactGroupId !== 'all' ? Number(contactGroupId) : 'all';
            processDefaultFilter(props.directories.billPaymentStatuses.list);
        });
        return {
            filter,
            contactStatus,
            contactStatusMap,
            selectFilterFunc,
        };
    }
};
</script>

<style lang="scss" scoped>
.contacts {
    display: flex;
    align-items: center;
}
</style>
