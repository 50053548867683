<template lang="pug">
.incomings-and-outgoings-filter
    .incomings-and-outgoings-filter__row
        a-input-number(
            :min="2020"
            :max="2100"
            :value="filter.year"
            @change="(e) => onChange('year', e)"
            placeholder="Год"
            style="width: 50%"
        )
        a-select.ml-2(
            :value="filter.groupBy"
            :label-in-value="true"
            :options="groupOptions"
            @change="(e) => onChange('groupBy', e)"
            style="width: 220px"
            placeholder="Группировка"
        )
        a-select.ml-2(
            :value="filter.contactId"
            placeholder="Клиент"
            style="width:100%"
            allowClear
            show-search
            :filter-option="false"
            :label-in-value="false"
            :options="labeledContactsList"
            @search="onContactsSearch"
            @change="(e) => onChange('contactId', e)"
        )
        a-select.ml-2(
            :value="filter.projectId"
            placeholder="Проект"
            style="width:100%"
            :disabled="true"
            allowClear
            show-search
            :filter-option="false"
            :label-in-value="false"
            :options="labeledProjectsList"
            @search="onProjectSearch"
            @change="(e) => onChange('projectId', e)"
        )
        a-select.ml-2(
            :value="filter.serviceId"
            placeholder="Услуга"
            style="width:100%"
            :disabled="true"
            allowClear
            show-search
            :filter-option="false"
            :label-in-value="false"
            :options="labeledServicesList"
            @search="onServiceSearch"
            @change="(e) => onChange('serviceId', e)"
        )
        a-select.mx-2(
            :value="filter.workspaceMemberId"
            placeholder="Исполнитель"
            style="width:100%"
            allowClear
            show-search
            :filter-option="false"
            :label-in-value="false"
            :options="labeledMembersList"
            @search="onMemberSearch"
            @change="(e) => onChange('workspaceMemberId', e)"
        )
    .incomings-and-outgoings-filter__row
        a-checkbox(
            :checked="filter.isScheduled"
            @change="(e) => onChange('isScheduled', e.target.checked)"
        ) План
</template>

<script>
import {useContactV2} from '@/composition/contact-v2';
import {toLabelValue} from '@/modules/utils';
import {useWorkspace} from '@/composition/workspace';
import {onMounted, computed} from 'vue';
import {useProject} from '@/composition/project';
import {useService} from '@/composition/service';
import {useServiceV2} from '@/composition/service-v2';
import CustomSelectV2 from '@/components/Generic/CustomSelectV2';

export default {
    name: 'IncomingsAndOutgoingsFilter',
    components: {CustomSelectV2},
    props: {
        filter: Object,
    },
    emits: ['update:filter'],
    setup(props, {emit}) {
        const {currentWorkspaceId} = useWorkspace();
        const onChange = (key, value) => {
            emit('update:filter', {
                ...props.filter,
                [key]: value
            });
        };
        const groupOptions = [
            {value: 'month', label: 'По месяцам'},
            {value: 'quarter', label: 'По кварталам'},
        ];
        /*==================CONTACTS AREA========================*/
        const {
            clients,
            getClients,
        } = useContactV2();
        const labeledContactsList = computed(() => {
            return toLabelValue(clients.value.list);
        });
        const onContactsSearch = (q) => {
            getClients(currentWorkspaceId.value, q, false);
        };
        onMounted(async () => {
            await getClients(currentWorkspaceId.value, undefined, false);
        });
        /*==================PROJECTS AREA========================*/
        const {
            projects,
            getProjects,
        } = useProject();
        const labeledProjectsList = computed(() => {
            return toLabelValue(projects.value.list);
        });
        const onProjectSearch = (q) => {
            getProjects(currentWorkspaceId.value, {}, q);
        };
        onMounted(async () => {
            await getProjects(currentWorkspaceId.value, {}, undefined);
        });
        /*=================SERVICES AREA========================*/
        const {
            services,
            getServices,
            setFilter: setServiceFilter,
        } = useServiceV2();
        const labeledServicesList = computed(() => {
            return toLabelValue(services.value.list, 'id', 'fullTitle');
        });
        const onServiceSearch = (q) => {
            setServiceFilter({search: q});
            getServices(currentWorkspaceId.value);
        };
        onMounted(async () => {
            setServiceFilter({});
            await getServices(currentWorkspaceId.value);
        });
        /*=================WORKSPACE MEMBERS AREA========================*/
        const {getMembers, members, setFilter: setMembersFilter} = useWorkspace();
        const labeledMembersList = computed(() => {
            return toLabelValue(members.value.list, 'id', 'fullName');
        });
        const onMemberSearch = (q) => {
            setMembersFilter({search: q});
            getMembers(currentWorkspaceId.value);
        };
        onMounted(async () => {
            setMembersFilter({});
            await getMembers(currentWorkspaceId.value);
        });
        return {
            groupOptions,
            labeledMembersList,
            labeledContactsList,
            labeledProjectsList,
            labeledServicesList,
            onChange,
            onMemberSearch,
            onProjectSearch,
            onServiceSearch,
            onContactsSearch,
        };
    }
};
</script>

<style lang="scss" scoped>
.incomings-and-outgoings-filter {
    &__row {
        display: flex;
        margin-bottom: 12px;
    }
}
</style>
