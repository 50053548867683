<template lang="pug">
service-layout
    async-page
        service-summary-data
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import ServiceLayout from '@/pages/Services/Service/Layout';
import ServiceSummaryData from '@/components/Services/Summary';
export default {
    name: 'ServiceSummary',
    components: {ServiceSummaryData, ServiceLayout, AsyncPage}
};
</script>

<style scoped>

</style>