<template lang="pug">
.contact-other
    card-title Прочее
    .editable-card__column.mt-2
        .flex.items-center Особая метка
        editable-cell(
            :initialValue="contact.contactSpecialMarkId"
            @update="(e) => onUpdate('contactSpecialMarkId', e)"
            :title="getSpecialMarkText()"
            type="select"
            :options="directories.contactSpecialMarks.list"
        )
    .editable-card__column.mt-2
        .flex.items-center Примечание
        editable-cell(
            :initialValue="contact.mark"
            @update="(e) => onUpdate('mark', e)"
            :title="getMarkText()"
            type="textarea"
        )
    .editable-card__column.mt-2
        .flex.items-center Лояльность
        editable-cell(
            :initialValue="contact.loyaltyId"
            @update="(e) => onUpdate('loyaltyId', e)"
            :title="getLoyaltyText()"
            type="select"
            :options="directories.loyalty.list"
        )
    .editable-card__column.mt-2
        .flex.items-center Сейлз
        editable-cell(
            :initialValue="contact.salesId"
            @update="(e) => onUpdate('salesId', e)"
            :title="getSalesText()"
            type="select"
            :options="getSalesOptions()"
        )
    .editable-card__column.mt-2
        .flex.items-center Источник лида
        editable-cell(
            :initialValue="contact.leadSourceId"
            @update="(e) => onUpdate('leadSourceId', e)"
            :title="getLeadSourceText()"
            type="select"
            :options="directories.leadSources.list"
        )
</template>

<script>
import EditableCell from '@/components/Generic/EditableCell';
export default {
    name: 'ContactOther',
    components: {EditableCell},
    props: {
        contact: Object,
        directories: Object,
        members: Object
    },
    emits: ['update'],
    setup(props, {emit}) {
        const onUpdate = (key, value) => {
            emit('update', {[key]: value});
        };
        const getMarkText = () => {
            return props.contact?.mark || 'Не указано';
        };
        const getLoyaltyText = () => {
            return props.contact?.loyalty?.title || 'Не указано';
        };
        const getSalesText = () => {
            return props.contact?.sales?.fullName || 'Не указано';
        };
        const getLeadSourceText = () => {
            return props.contact?.leadSource?.title || 'Не указано';
        };
        const getSalesOptions = () => {
            return props.members?.list.map(x => {
                return {
                    id: x?.id,
                    title: x?.fullName
                };
            });
        };
        const getSpecialMarkText = () => {
            return props.contact?.contactSpecialMark?.title || 'Не указано';
        };
        return {
            onUpdate,
            getMarkText,
            getLoyaltyText,
            getSalesText,
            getLeadSourceText,
            getSalesOptions,
            getSpecialMarkText,
        };
    }
};
</script>

<style scoped>

</style>