<template lang="pug">
.services-select.w-full
    custom-select(
        :modelValue="modelValue"
        :title="placeholder"
        @update:modelValue="onChange($event)"
        :options="services"
        :mode="mode"
        server-search
        @search="onSearch"
        @blur="onBlur"
        accessor="fullTitle"
    )
</template>

<script>
import {useWorkspace} from '@/composition/workspace';
import {debounce} from '@/modules/utils';
import {computed, watch} from 'vue';
import {useService} from '@/composition/service';
import CustomSelect from '@/components/Generic/CustomSelect';
export default {
    name: 'ServicesSelectNew',
    components: {CustomSelect},
    props: {
        modelValue: [Number, String],
        placeholder: {
            type: String,
            default: 'Услуга'
        },
        mode: {
            type: String,
            default: ''
        },
        defaultFirst: {
            type: Boolean,
            default: false,
        }
    },
    emits: ['update:modelValue', 'blur'],
    setup(props, {emit}) {
        const {currentWorkspaceId} = useWorkspace();
        const {services, getServices, setFilter} = useService();
        watch(() => props.modelValue, () => {
            if (props.modelValue) {
                onChange(props.modelValue);
            }
        });
        const onSearch = async (q) => {
            setFilter({search: q});
            debounce(() => getServices(currentWorkspaceId.value), 500)();
        };
        const onChange = (id) => {
            emit('update:modelValue', id);
        };
        const onBlur = (e) => {
            emit('blur', e);
        };

        return {
            services: computed(() => {
                if (props.defaultFirst) {
                    return [
                        {id: -1, fullTitle: 'Без услуги'},
                        ...services.value.list
                    ];
                }
                return services.value.list;
            }),
            onBlur,
            onSearch,
            onChange,
        };
    }
};
</script>

<style scoped>

</style>