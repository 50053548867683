<template lang="pug">
card-title Роль
.invite-user-form.mt-2
    input-with-error(:error="roleId.error")
        template(#input)
            a-select(
                :value="roleId.value"
                style="width: 100%"
                placeholder="Роль"
                show-search
                :filter-option="selectFilterFunc"
                @change="onChange('roleId', $event)"
                allowClear
            )
                a-select-option(
                    v-for="role in directories.roles.list"
                    :key="role.id"
                    :value="role.id"
                    :label="role.title"
                )
                    | {{ role.title }}
                a-select-option.cursor-pointer(disabled @click="() => $emit('open-modal', 'roles')") Добавить
                template(#notFoundContent)
                    add-directory(:onClick="() => $emit('open-modal', 'roles')")
</template>

<script>
import {selectFilterFunc} from '@/modules/utils';
import AddDirectory from '@/components/Generic/AddDirectory/AddDirectory';

export default {
    name: 'RoleForm',
    components: {AddDirectory},
    props: {
        roleId: Object,
        directories: Object,
    },
    emits: ['update:roleId', 'open-modal'],
    setup(props, {emit}) {
        const onChange = (key, value) => {
            emit(`update:${key}`, {value, error: undefined});
        };
        return {
            onChange,
            selectFilterFunc
        };
    }
};
</script>

<style scoped>

</style>