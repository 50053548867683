<template lang="pug">
card-title Способы оплаты
.invite-user-form.mt-2
    div(v-for="paymentMethodEl in paymentMethods.value" :key="paymentMethodEl.id")
        .flex.align-items.justify-between
            .cursor-pointer(@click="edit(paymentMethodEl)") {{ renderPaymentMethod(paymentMethodEl) }}
            .cursor-pointer(@click="deletePaymentMethodFromArr(paymentMethodEl.id)")
                icon(type="Delete")
    div
        a-button.mt-2(@click="setPaymentMethodModalVisibility(true)" type="primary") {{ paymentMethods.value.length ? 'Еще' : 'Добавить' }}
a-modal(
    v-model:visible="showAddPaymentMethodModal"
    title="Добавить способ оплаты"
    :footer="null"
    @cancel="setPaymentMethodModalVisibility(false)"
    width="30%"
    :style="{top: '20px'}"
    :destroy-on-close="true"
)
    create-payment-method-form(
        :paymentMethods="directories.paymentMethods.list"
        @add="onPaymentMethodAdded"
        :mode="currentMode"
        :default-value="editingElement"
        @openModal="(type) => $emit('open-modal', type)"
    )
</template>

<script>
import CreatePaymentMethodForm from './CreatePaymentMethodsForm';
import {useCreateOrUpdateModal} from '@/composition/create-update-modal';
import {firstChars} from '@/modules/utils';

export default {
    name: 'PaymentMethodsForm',
    components: {CreatePaymentMethodForm},
    props: {
        directories: Object,
        paymentMethods: Object,
    },
    emits: ['update:paymentMethods', 'open-modal'],
    setup(props, {emit}) {
        const {
            currentMode,
            editingValue: editingElement,
            showModal: showAddPaymentMethodModal,
            setModalVisibility: setPaymentMethodModalVisibility,
            edit
        } = useCreateOrUpdateModal();
        const onPaymentMethodAdded = (paymentData) => {
            if (currentMode.value === 'edit') {
                emit('update:paymentMethods', {
                    error: undefined,
                    value: props.paymentMethods.value.map(x => {
                        if (x.id == paymentData.id) {
                            return paymentData;
                        }
                        return x;
                    })
                });
            } else {
                emit('update:paymentMethods', {
                    error: undefined,
                    value: [...props.paymentMethods.value, paymentData]
                });
            }

            setPaymentMethodModalVisibility(false);
        };
        const deletePaymentMethodFromArr = (id) => {
            const payload =  props.paymentMethods.value.filter(x => x.id != id);
            emit('update:paymentMethods', {
                error: undefined,
                value: payload
            });
        };
        const renderPaymentMethod = (paymentMethod) => {
            const paymentMethodData = props.directories.paymentMethods.list.find(x => paymentMethod.paymentMethodId === x.id);
            if (!paymentMethodData) {
                return 'Не указано';
            }
            if (!paymentMethod.requisites) {
                return paymentMethodData.title;
            }
            return `${paymentMethodData.title} (${firstChars(10, paymentMethod.requisites)})`;
        };
        return {
            showAddPaymentMethodModal,
            setPaymentMethodModalVisibility,
            onPaymentMethodAdded,
            deletePaymentMethodFromArr,
            edit,
            currentMode,
            editingElement,
            renderPaymentMethod,
        };
    }
};
</script>

<style scoped>

</style>