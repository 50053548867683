<template lang="pug">
.projects-select.w-full
    a-select(
        :value="modelValue"
        style="width: 100%"
        :placeholder="placeholder"
        :filter-option="false"
        @change="onChange($event)"
        @search="onSearch($event)"
        show-search
        allow-clear
        :mode="mode"
        @blur="onBlur"
    )
        a-select-option(
            v-for="project in projects.list"
            :key="project.id"
            :value="project.id"
        )
            | {{ project.title }}
</template>

<script>
import {useWorkspace} from '@/composition/workspace';
import {debounce} from '@/modules/utils';
import {watch} from 'vue';
import {useProject} from '@/composition/project';
export default {
    name: 'ProjectsSelect',
    props: {
        modelValue: [Number, String],
        placeholder: {
            type: String,
            default: 'Проект'
        },
        mode: {
            type: String,
            default: ''
        }
    },
    emits: ['update:modelValue', 'blur'],
    setup(props, {emit}) {
        const {currentWorkspaceId} = useWorkspace();
        const {projects, getProjects} = useProject();
        watch(() => props.modelValue, () => {
            if (props.modelValue) {
                onChange(props.modelValue);
            }
        });
        const onSearch = async (q) => {
            debounce(() => getProjects(currentWorkspaceId.value, {}, q), 500)();
        };
        const onChange = (id) => {
            emit('update:modelValue', id);
        };
        const onBlur = (e) => {
            emit('blur', e);
        };

        return {
            projects,
            onBlur,
            onSearch,
            onChange,
        };
    }
};
</script>

<style scoped>

</style>