<template lang="pug">
card-title Периодичность выплат
.invite-user-form.mt-2
    .flex
        a-select.mr-3(
            :value="paymentDates.value.count"
            placeholder="Периодичность выплат"
            style="width: 100%"
            allowClear
            @change="onChange('count', $event)"
        )
            a-select-option(:value="constants.month.once") Раз в месяц
            a-select-option(:value="constants.month.twice") Два раза в месяц
        a-input-number.mr-3(
            :value="paymentDates.value.dayNumberOne"
            v-if="paymentDates.value.count === constants.month.once || paymentDates.value.count === constants.month.twice"
            :min="0"
            :max="31"
            placeholder="Календарный день"
            style="width: 100%"
            @change="onChange('dayNumberOne', $event)"
        )
        a-input-number.mr-3(
            :value="paymentDates.value.dayNumberTwo"
            v-if="paymentDates.value.count === constants.month.twice"
            :min="0"
            :max="31"
            placeholder="Календарный день"
            style="width: 100%"
            @change="onChange('dayNumberTwo', $event)"
        )
</template>

<script>

import constants from '@/components/Workspace/InviteUserForm/constants';

export default {
    name: 'PaymentDatesForm',
    props: {
        paymentDates: Object
    },
    emits: [
        'update:paymentDates'
    ],
    setup(props, {emit}) {
        const onChange = (key, value) => {
            const payload = {
                count: props.paymentDates.value.count,
                dayNumberOne: props.paymentDates.value.dayNumberOne,
                dayNumberTwo: props.paymentDates.value.dayNumberTwo,
                [key]: value,
            };
            emit('update:paymentDates', {
                error: undefined,
                value: payload
            });
        };
        return {
            onChange,
            constants
        };
    }

};
</script>

<style scoped>

</style>