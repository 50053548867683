<template lang="pug">
.hourly-report-page
    async-page
        hourly-reports(:uuid="route.params.uuid")
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import HourlyReports from '@/components/Analytics/HourlyReports';
import {useRoute} from 'vue-router';
export default {
    name: 'HourlyReportPage',
    components: {AsyncPage, HourlyReports},
    setup() {
        const route = useRoute();
        return {
            route,
        };
    }
};
</script>

<style lang="scss" scoped>
.hourly-report-page {
    padding: 16px;
}
</style>