<template lang="pug">
.members-select.w-full
    custom-select(
        :modelValue="modelValue"
        :title="placeholder"
        @update:modelValue="onChange($event)"
        :options="members.list"
        :mode="mode"
        server-search
        @search="onSearch"
        accessor="fullName"
        @blur="onBlur"
        :height="options.height"
        :first-item="firstItem"
        :disabled="disabled"
    )
        template(#first-item)
            slot(name="first-item")
        template(#title="{record}")
            slot(name="title" :record="record")
</template>

<script>
import {useWorkspace} from '@/composition/workspace';
import {debounce} from '@/modules/utils';
import {useDate} from '@/composition/date';
import {watch} from 'vue';
import CustomSelect from '@/components/Generic/CustomSelect';
export default {
    name: 'WorkspaceMembersSelect',
    components: {
        CustomSelect,
    },
    props: {
        modelValue: [Object, Array],
        placeholder: {
            type: String,
            default: 'Пользователи'
        },
        mode: {
            type: String,
            default: 'single'
        },
        options: {
            type: Object,
            default: () => ({})
        },
        firstItem: {
            type: Object,
        },
        disabled: {
            type: Boolean
        }
    },
    emits: ['update:modelValue', 'blur'],
    setup(props, {emit, slots}) {
        const {currentWorkspaceId, getMembers, members, setFilter} = useWorkspace();
        const {formatDate} = useDate();
        watch(() => props.modelValue, () => {
            if (props.modelValue) {
                onChange(props.modelValue);
            }
        });
        const onSearch = async (q) => {
            setFilter({search: q});
            debounce(() => getMembers(currentWorkspaceId.value), 500)();
        };
        const onChange = (id) => {
            emit('update:modelValue', id);
        };
        const onBlur = (e) => {
            emit('blur', e);
        };
        const isSlotExists = (key) => {
            return !!slots[key];
        };

        return {
            members,
            onBlur,
            onSearch,
            onChange,
            formatDate,
            isSlotExists,
        };
    }
};
</script>

<style scoped>

</style>