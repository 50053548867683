<template lang="pug">
settings-directories-members-layout
    async-page
        roles-table
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import RolesTable from '@/components/Directories/Members/Roles/Table';
import SettingsDirectoriesMembersLayout from '@/pages/Settings/Directories/Members/Layout';
export default {
    name: 'Roles',
    components: {SettingsDirectoriesMembersLayout, RolesTable, AsyncPage}
};
</script>

<style scoped>

</style>