<template lang="pug">
markdown.markdown(:source="source || ''" :plugins="markdownPlugins" ref="markdownRef" :html="true")
</template>

<script>
export default {
    name: 'CustomMarkdown'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
import MarkdownItTaskList from 'markdown-it-task-lists';
import Markdown from 'vue3-markdown-it';
import 'highlight.js/styles/monokai.css';
import {ref, onBeforeUnmount, onMounted} from 'vue';

/*===============INIT AREA===================*/
const markdownRef = ref(null);
const checkLists = ref([]);
const props = defineProps({
    source: {
        type: String,
    },
});
const emit = defineEmits(['update']);
const markdownPlugins = [
    {plugin: MarkdownItTaskList, options: {label: true, enabled: true}}
];

/*===============LIFECYCLE HOOKS AREA===================*/

</script>

<style lang="scss">
</style>