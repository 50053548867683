<template lang="pug">
.add-task-form
    input-with-error(:error="form.title.error")
        template(#input)
            a-input(placeholder="Наименование" v-model:value="form.title.value")
    input-with-error(:error="form.description.error")
        template(#input)
            a-textarea.mt-3(placeholder="Описание" v-model:value="form.description.value")
    input-with-error(:error="form.workspaceMemberId.error" v-if="!hide_workspace_members")
        template(#input)
            custom-select-v2.mt-3(
                v-model="form.workspaceMemberId.value"
                placeholder="Исполнитель"
                :style="{width: '100%'}"
                :options="members.list"
                label="fullName"
                searchable
                @search="onMembersSearch"
            )
    input-with-error(:error="form.statusId.error")
        template(#input)
            custom-select-v2.mt-3(
                v-model="form.statusId.value"
                placeholder="Статус"
                :style="{width: '100%'}"
                :options="taskStatuses"
            )
    input-with-error(:error="form.serviceId.error")
        template(#input)
            select-suspense
                custom-select-v2.mt-3(
                    v-model="form.serviceId.value"
                    placeholder="Услуга не задана"
                    :style="{width: '100%'}"
                    :options="services.list"
                    searchable
                    @search="onServiceSearch"
                    v-if="serviceMode === 'select'"
                    label="fullTitle"
                )
                services-radio-group.mt-3(
                    v-model="form.serviceId.value"
                    v-if="serviceMode === 'radio'"
                    :services="services.list"
                )
    .mt-2(v-if="isProjectWithIntegration")
        a-checkbox(v-model:checked="form.enableSync.value") Синхронизировать с внешним таскменеджером
    input-with-error(:error="form.timing.error")
        template(#input)
            a-input-number.mt-3(
                placeholder="Эстимейт не задан, ч."
                v-model:value="form.timing.value"
                style="width: 100%"
            )
    input-with-error(:error="form.deadline.error")
        template(#input)
            a-date-picker.mt-3(v-model:value="form.deadline.value" placeholder="Дедлайн не задан" style="width: 100%" format="DD.MM.YYYY")
    a-button.mt-5.mb-2(
        type="primary"
        block
        @click="createTaskWrapper"
        :loading="loading"
        :disabled="disabled"
    ) {{ mode === 'edit' ? 'Редактировать' : 'Создать' }}
</template>

<script>
import {computed, reactive, ref, onMounted} from 'vue';
import {useFormErrors} from '@/composition/errors';
import {notification} from 'ant-design-vue';
import {useKanban} from '@/composition/kanban';
import {debounce, selectFilterFunc} from '@/modules/utils';
import InputWithError from '@/components/Generic/InputWithError';
import ServicesSelect from '@/components/Services/Select';
import SelectSuspense from '@/components/Generic/SelectSuspense';
import ProjectsSelect from '@/components/Projects/Select';
import WorkspaceMembersSelect from '@/components/Workspace/Member/Select';
import TaskStatusSelect from '@/components/Tasks/StatusSelect';
import {useRole} from '@/composition/role';
import {useAuth} from '@/composition/auth';
import {useService} from '@/composition/service';
import ServicesRadioGroup from '@/components/Services/RadioGroup';
import {useWorkspace} from '@/composition/workspace';
import {useWorkspaceV2} from '@/composition/workspace-v2';
import {useServiceV2} from '@/composition/service-v2';
import CustomSelectV2 from '@/components/Generic/CustomSelectV2';
export default {
    name: 'AddTaskForm',
    components: {
        CustomSelectV2,
        ServicesRadioGroup,
        SelectSuspense,
        ServicesSelect,
        InputWithError,
        ProjectsSelect,
        WorkspaceMembersSelect,
        TaskStatusSelect,
    },
    props: {
        workspaceId: Number,
        status: Object,
        defaultValue: Object,
        mode: String,
    },
    emits: ['ok', 'update'],
    setup(props, {emit}) {
        const loading = ref(false);
        const serviceMode = ref(null);
        const {user} = useAuth();
        const {sections, getDefaults} = useRole(user.value);
        const {workspaceMember, hide_workspace_members} = getDefaults(sections.tasks.name, sections.tasks.create.name);
        const {services: initialServices} = useService();
        if (initialServices.value.total < 5) {
            serviceMode.value = 'radio';
        } else {
            serviceMode.value = 'select';
        }
        const form = reactive({
            title: {
                value: props.defaultValue.title,
                error: ''
            },
            description: {
                value: props.defaultValue.description,
                error: ''
            },
            deadline: {
                value: props.defaultValue.deadline,
                error: ''
            },
            workspaceMemberId: {
                value: props.defaultValue.workspaceMember || workspaceMember,
                error: ''
            },
            timing: {
                value: props.defaultValue.timing,
                error: ''
            },
            serviceId: {
                value: props.defaultValue.serviceId,
                error: ''
            },
            statusId: {
                value: props.status,
                error: ''
            },
            enableSync: {
                value: !props.disableSync,
                error: ''
            }
        });
        const {addTask, taskStatuses, getTaskStatuses} = useKanban(loading);
        const clearErrors = () => {
            form.title.error = '';
            form.description.error = '';
            form.workspaceMemberId.error = '';
            form.serviceId.error = '';
            form.deadline.error = '';
            form.timing.error = '';
            form.statusId.error = '';
            form.enableSync.error = '';
        };
        const {init} = useFormErrors();

        const createTaskWrapper = async () => {
            const taskData = {
                title: form.title.value,
                description: form.description.value,
                serviceId: form.serviceId.value?.id,
                deadline: form.deadline.value,
                workspaceId: props.workspaceId,
                statusId: form.statusId.value?.id,
                workspaceMemberId: form.workspaceMemberId.value?.id,
                timing: form.timing.value,
                disableSync: !form.enableSync.value,
            };
            if (props.mode === 'edit') {
                emit('update', taskData);
            } else {
                const {ok} = await init(form, async () => {
                    return addTask(taskData);
                }, clearErrors);
                if (ok) {
                    notification.success({
                        description: 'Задача успешно создана',
                        message: 'Успех!'
                    });
                    emit('ok');
                }
            }

        };
        const disabled = computed(() => {
            return !form.title.value || !form.statusId.value;
        });
        const isProjectWithIntegration = computed(() => {
            const service = services.value.list.find(x => x.id === form.serviceId.value);
            const project = service?.project;
            return !!project?.integrationProject?.id;
        });
        /*=====================MEMBERS AREA=======================*/
        const {currentWorkspaceId} = useWorkspace();
        const {members, getMembers, setFilter: setMembersFilter} = useWorkspaceV2();
        const onMembersSearch = (q) => {
            setMembersFilter({search: q});
            debounce(() => getMembers(currentWorkspaceId.value), 500)();
        };
        onMounted(async () => {
            await getMembers(currentWorkspaceId.value);
        });
        /*===============SERVICES AREA===================*/
        const {
            services,
            getServices,
            setFilter: setServiceFilter,
        } = useServiceV2();
        const onServiceSearch = (q) => {
            setServiceFilter({search: q});
            getServices(currentWorkspaceId.value);
        };
        onMounted(async () => {
            setServiceFilter({});
            await getServices(currentWorkspaceId.value);
        });
        /*===============TASK STATUSES AREA===================*/
        onMounted(async () => {
            await getTaskStatuses(currentWorkspaceId.value);
        });
        return {
            services,
            onServiceSearch,
            members,
            onMembersSearch,
            isProjectWithIntegration,
            createTaskWrapper,
            disabled,
            form,
            loading,
            selectFilterFunc,
            hide_workspace_members,
            serviceMode,
            initialServices,
            taskStatuses,
        };
    }
};
</script>

<style lang="scss" scoped>
.add-task-form {

}
</style>
