<template lang="pug">
.table(:style="{width}")
    .table-header(v-if="!hideColumns")
        .table-header-item(v-for="column in columns" :style="{width: column.width ? column.width : '180px'}")
            .table-header-item-title.font-bold {{ column.title }}
    .table-body(v-if="!draggable && dataSource.length && !loading")
        details(v-for="item in dataSource")
            summary.table-item(
                :key="item.id"
                @click="(e) => onClickRow(e, item)"
                :class="[onRowClick ? 'cursor-pointer' : '', rowClass ? rowClass(item) : '', !isSlotEmpty('children') ? 'with-children' : '' ]"
            )
                .table-item-row-prefix(:style="{...rowPrefixStyle}")
                    slot(name="rowPrefix" :record="item")
                //.table-item-row-collapse(v-if="collapse" @click.stop.prevent="onCollapseClick(item)") +
                .table-item-row(
                    v-for="column in columns"
                    :style="{width: column.width ? column.width : '180px'}"
                )
                    .table-item-row-text(v-if="column.slots?.customRender")
                        slot(:name="column.slots?.customRender" :record="item")
                    .table-item-row-text(v-else) {{ item[column.dataIndex] }}
            slot(name="children" :record="item")
                custom-table(
                    :columns="childrenColumns"
                    :data-source="item[childrenKey]"
                    v-if="showChildren"
                    :hide-columns="hideChildrenColumns"
                    :hide-if-empty="hideChildrenIfEmpty"
                )
                    template(
                        v-for="column in childrenColumns"
                        v-slot:[column.slots.customRender]="{record}"
                    )
                        slot(:name="column.slots?.customRender" :record="record")
    draggable(
        v-else-if="draggable && dataSource.length && !loading"
        :list="dataSource"
        :group="`table_${Date.now()}`"
        item-key="id"
        class="table-body"
        handle=".draggable-field"
        @change="(e) => onSortOrderChanged(e)"
    )
        template(#item="{element}")
            .table-item(
                @click="(e) => onClickRow(e, element)"
                :class="[onRowClick ? 'cursor-pointer' : '', rowClass ? rowClass(element) : '' ]"
            )
                .draggable-field.cursor-move.mr-3
                    icon(type="Drag")
                .table-item-row(
                    v-for="column in columns"
                    :style="{width: column.width ? column.width : '180px'}"
                )
                    .table-item-row-text(v-if="column.slots?.customRender && !isSlotEmpty(column.slots?.customRender)")
                        slot(:name="column.slots?.customRender" :record="element")
                    .table-item-row-text(v-else) {{ element[column.dataIndex] }}
    .table-not-found(v-if="loading")
        icon(type="Loader")
    .table-not-found(v-if="!dataSource.length && !loading && !hideIfEmpty")
        div Не найдено
</template>

<script>
import draggable from 'vuedraggable';
import Icon from '@/components/Generic/Typography/Icon';
import {ref} from 'vue';
export default {
    name: 'CustomTable',
    components: {
        Icon,
        draggable
    },
    props: {
        dataSource: Array,
        columns: Array,
        loading: Boolean,
        onRowClick: {
            type: Function,
        },
        draggable: Boolean,
        rowPrefixStyle: {
            type: Object,
            default: () => ({
                left: '-10px',
            })
        },
        width: {
            type: String,
            default: '100%'
        },
        rowClass: {
            type: Function,
        },
        collapse: {
            type: Boolean
        },
        childrenKey: {
            type: String,
        },
        childrenColumns: {
            type: Array,
        },
        hideChildrenColumns: {
            type: Boolean,
        },
        hideChildrenIfEmpty: {
            type: Boolean,
        },
        hideIfEmpty: {
            type: Boolean,
        },
        hideColumns: {
            type: Boolean
        },
        defaultChildrenOpen: {
            type: Boolean
        }
    },
    emits: ['sort-order-changed'],
    setup(props, {emit, slots}) {
        const onSortOrderChanged = (e) => {
            emit('sort-order-changed', e);
        };
        const showChildren = ref(props.defaultChildrenOpen);
        const selectedRows = ref({});
        const onClickRow = (e, item) => {
            if (props.onRowClick) {
                e.stopPropagation();
                e.preventDefault();
                props.onRowClick(item);
            }
        };
        const isSlotEmpty = (key) => {
            if (!slots[key]) return true;
            return false;
        };
        const onCollapseClick = (row) => {
            if (row.id === selectedRows.value.id) {
                showChildren.value = !showChildren.value;
            } else {
                showChildren.value = true;
                selectedRows.value = row;
            }
        };
        return {
            showChildren,
            selectedRows,
            onClickRow,
            onSortOrderChanged,
            isSlotEmpty,
            onCollapseClick
        };
    }
};
</script>
<style lang="scss" scoped>

</style>
