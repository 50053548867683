<template lang="pug">
settings-directories-documents-layout
    async-page
        documents-transports-table
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import SettingsDirectoriesDocumentsLayout from '@/pages/Settings/Directories/Documents/Layout';
import DocumentsTransportsTable from '@/components/Directories/Documents/Transport/Table';

export default {
    name: 'DocumentTransportsPage',
    components: {
        DocumentsTransportsTable,
        SettingsDirectoriesDocumentsLayout,
        AsyncPage
    }
};
</script>

<style scoped>

</style>