export const columns = [
    {
        title: 'Дата',
        key: 'date',
        slots: {customRender: 'date'},
        width: '20%',
    },
    {
        title: 'Стоимость, руб.',
        key: 'amount',
        slots: {customRender: 'amount'},
        width: '33%',
        summarize: true,
    },
    {
        title: 'Операция',
        key: 'operation',
        slots: {customRender: 'operation'},
        width: '20%'
    },
    {
        title: 'Счет',
        key: 'bankAccount',
        slots: {customRender: 'bankAccount'},
        width: '20%'
    },
    {
        title: '',
        key: 'actions',
        slots: {customRender: 'actions'},
        width: '30%'
    },
];
