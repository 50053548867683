<template lang="pug">
card-title Аккаунт
.invite-user-form.mt-2
    input-with-error(:error="fullName.error")
        template(#input)
            a-input(placeholder="ФИО" :value="fullName.value" @change="onChange('fullName', $event.target.value)")
    input-with-error(:error="email.error")
        template(#input)
            a-input(placeholder="Email" :value="email.value" @change="onChange('email', $event.target.value)" :disabled="email.disabled")
</template>

<script>
export default {
    name: 'AccountForm',
    props: {
        fullName: Object,
        email: Object
    },
    emits: ['update:fullName', 'update:email'],
    setup(props, {emit}) {
        const onChange = (key, value) => {
            emit(`update:${key}`, {value, error: undefined});
        };
        return {
            onChange
        };
    }
};
</script>

<style scoped>

</style>