import {httpInstance} from '@/modules/http_factory';

const updateSettings = async (widgetKey, settings) => {
    const response = await httpInstance().post('/widgets/settings', {
        widgetKey, settings
    });
    return response.data;
};
const getSettings = async (widgetKey) => {
    const response = await httpInstance().get('/widgets/settings', {
        params: {widgetKey}
    });
    return response.data;
};

export default {
    getSettings,
    updateSettings
};
