<template lang="pug">
div
    .cursor-pointer(
        :class="{danger: isOutdated(task.deadline)}"
        @click="changeMode('edit')"
        v-if="mode === 'read'"
    ) {{ task.deadline ? formatDate(task.deadline): 'Не указано' }}
    a-date-picker(
        placeholder="Дедлайн"
        v-model:value="task.deadline"
        format="DD.MM.YYYY"
        v-if="mode === 'edit'"
        @change="update"
    )
</template>

<script>
import {ref} from 'vue';
import {useDate} from '@/composition/date';
export default {
    name: 'TaskDeadlineChanger',
    props: {
        task: Object,
    },
    emits: ['update'],
    setup(props, {emit}) {
        const mode = ref('read');
        const changeMode = (newMode) => {
            mode.value = newMode;
        };
        const update = (newDeadline) => {
            emit('update', newDeadline);
            changeMode('read');
        };
        return {
            mode,
            changeMode,
            update,
            ...useDate()
        };
    }
};
</script>

<style scoped>

</style>