import PieChart from './pie-chart.svg';
import Users from './users.svg';
import Logout from './logout.svg';
import Ok from './ok.svg';
import Finger from './finger.svg';
import Minus from './minus.svg';
import AddUser from './add-user.svg';
import Loader from './loader.svg';
import Document from './document.svg';
import Calendar from './calendar.svg';
import Plus from './plus.svg';
import Settings from './settings.svg';
import Setting from './setting.svg';
import Delete from './delete.svg';
import ExternalLink from './external-link.svg';
import Dots from './dots.svg';
import Edit from './edit.svg';
import Dollar from './dollar.svg';
import Drag from './drag.svg';
import Error from './error.svg';
import InProgress from './in-progress.svg';
import PaymentTypeFixed from './payment-type-fix.svg';
import PaymentTypeHourly from './payment-type-hourly.svg';
import Success from './success.svg';
import ArrowDown from './arrow-down.svg';
import Up from './up.svg';
import Down from './down.svg';
import EditField from './edit-field.svg';
import ArrowLeft from './arrow-left.svg';
import Close from './close.svg';
import Check from './check.svg';
import Share from './share.svg';
import Play from './play.svg';
import Stop from './stop.svg';
import Pulse from './pulse.svg';
import Collapse from './collapse.svg';
export default {
    Share,
    EditField,
    Edit,
    PieChart,
    Users,
    Logout,
    Ok,
    Finger,
    Minus,
    AddUser,
    Loader,
    Document,
    Calendar,
    Plus,
    Settings,
    Delete,
    ExternalLink,
    Dots,
    Dollar,
    Drag,
    Error,
    InProgress,
    PaymentTypeFixed,
    PaymentTypeHourly,
    Success,
    ArrowDown,
    Up,
    Down,
    ArrowLeft,
    Close,
    Check,
    Play,
    Stop,
    Pulse,
    Setting,
    Collapse
};
