<template lang="pug">
async-page
    task-card
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import TaskCard from '@/components/Tasks/TaskCard';
export default {
    name: 'TaskPage',
    components: {
        AsyncPage,
        TaskCard,
    }
};
</script>

<style scoped>

</style>