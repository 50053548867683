const cols = [
    {
        title: 'Период',
        slots: {customRender: 'dates'},
        width: '15%'
    },
    {
        title: 'Доход',
        slots: {customRender: 'in'},
        width: '7%'
    },
    {
        title: 'Расход',
        slots: {customRender: 'out'},
        width: '7%'
    },
    {
        title: 'Прибыль',
        slots: {customRender: 'profit'},
        width: '9%'
    },
    {
        title: 'Утилизация',
        slots: {customRender: 'hours'},
        width: '7%'
    },
    {
        title: 'К оплате / оплачено / остаток',
        slots: {customRender: 'finance'},
        width: '20%'
    },
    {
        title: 'Баланс',
        slots: {customRender: 'balance'},
        width: '7%'
    },
    {
        title: 'Счета',
        slots: {customRender: 'bills'},
        width: '8%'
    },
    {
        title: 'Акты в-ых работ',
        slots: {customRender: 'documents'},
        width: '8%'
    },
    {
        title: 'Действия',
        slots: {customRender: 'actions'},
        width: '7%'
    },
];
export const columns = (options: any={}) => {
    if (options.withService) {
        return [
            ...cols,
        ].slice(0, -1);
    }
    return cols;
};

export enum ServicePaymentPeriodStatus {
    active = 'active',
    oncoming = 'oncoming',
    finished = 'finished',
    archive = 'archive',
}

export enum ServicePaymentPeriodStatusMap {
    oncoming = 'Предстоящие',
    active = 'Актуальные',
    finished = 'Завершенные',
    archive = 'Архив',
}
