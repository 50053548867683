<template lang="pug">
async-page
    restore-password
</template>

<script>

import AsyncPage from '@/layouts/async-page';
import RestorePassword from '@/components/Auth/RestorePassword';
export default {
    name: 'RestorePasswordPage',
    components: {RestorePassword, AsyncPage},
};
</script>
