
import {httpInstance} from '@/modules/http_factory';
import {ListOf} from '@/core/common/interface/list-of.interface';
import {RegularTransactionInterface} from '@/modules/transaction/entities/regular-transaction.interface';
import {RegularTransactionForm} from '@/modules/transaction/form/regular-transaction.form';
import {OkResponse} from '@/core/common/interface/ok-response.interface';
import {PaginationInterface} from '@/core/common/interface/pagination.interface';
import {RegularTransactionMapper} from '@/modules/transaction/mappers/regular-transaction.mapper';

const getRegularTransactions = async(pagination: PaginationInterface): Promise<ListOf<RegularTransactionInterface>> => {
    const response = await httpInstance().get('regular-transactions', {
        params: pagination,
    });
    return response.data;
};

const createRegularTransaction = async(params: RegularTransactionForm): Promise<RegularTransactionInterface> => {
    const response = await httpInstance().post('regular-transactions', RegularTransactionMapper.toCreateDto(params));
    return response.data;
};

const updateRegularTransaction = async(id: number, params: RegularTransactionForm): Promise<RegularTransactionInterface> => {
    const response = await httpInstance().patch(`regular-transactions/${id}`, RegularTransactionMapper.toUpdateDto(params));
    return response.data;
};

const deleteRegularTransaction = async(id: number): Promise<OkResponse> => {
    const response = await httpInstance().delete(`regular-transactions/${id}`);
    return response.data;
};

const getRegularTransaction = async(id: number): Promise<RegularTransactionInterface> => {
    const response = await httpInstance().get(`regular-transactions/${id}`);
    return response.data;
};

const getSummary = async(): Promise<{total: number}> => {
    const response = await httpInstance().get('regular-transactions/summary');
    return response.data;
};

export default {
    getSummary,
    getRegularTransaction,
    getRegularTransactions,
    createRegularTransaction,
    updateRegularTransaction,
    deleteRegularTransaction,
};