<template lang="pug">
transition(name="sidebar-fade")
    .sidebar
        .sidebar__wrapper
            .sidebar__collapse-wrapper
                .sidebar__collapse.cursor-pointer(@click="toggleCollapsed" :class="{rotate: collapsed}")
                    icon(type="Collapse")
            .sidebar__logo
                router-link.header-workspace.flex.items-center(:to="renderWorkspacePath()")
                    workspace-title(
                        :workspaceMember="currentWorkspace"
                        :user="user"
                        hideStatus
                        theme="dark"
                        :hide-title="collapsed"
                    )
            .sidebar-routes
                sidebar-route(
                    v-for="route in routes"
                    :key="route.id"
                    :title="route.title"
                    :icon="route.icon"
                    :icon-color="route.iconColor"
                    :path="route.path"
                    :children="route.children"
                    :is-tiny="collapsed"
                )
</template>

<script>
import Logo from '@/assets/img/logo.svg';
import {useWorkspace} from '@/composition/workspace';
import {computed} from 'vue';
import {useAuth} from '@/composition/auth';
import WorkspaceTitle from '@/components/Generic/Typography/WorkspaceTitle';
import SidebarRoute from '@/components/Layouts/TheSidebar/SidebarRoute';
import {useRole} from '@/composition/role';
import {useLayout} from '@/composition/layout';
import Icon from '@/components/Generic/Typography/Icon';
export default {
    name: 'TheSidebar',
    components: {
        Icon,
        SidebarRoute,
        Logo,
        WorkspaceTitle
    },
    setup() {
        const {collapsed, sidebarWidth, toggleCollapsed} = useLayout();
        const {user} = useAuth();
        const {getRoutes} = useRole();
        const allRoutes = [
            {id: 1, title: 'Сводка', path: '/:id/dashboard', icon: 'PieChart', section: 'dashboard'},
            {
                id: 17,
                title: 'Планирование',
                section: 'planning',
                icon: 'PieChart',
                path: '/:id/profit-and-loss',
                children: [
                    // {id: 17.1, title: 'Прибыль и убытки', path: '/:id/profit-and-loss', section: 'finance'},
                    // {id: 17.2, title: 'Рентабельность услуг', path: '/:id/services-profitability', section: 'services_all'},
                    {id: 17.3, title: 'Ресурсы и утилизация времени', path: '/:id/resource-management', section: 'users'},
                ]
            },
            {id: 2, title: 'Задачи', path: '/:id/tasks', icon: 'Document', section: 'tasks'},
            {id: 3, title: 'Записи времени', path: '/:id/time-logs', icon: 'Calendar', iconColor: 'white', section: 'time_logs'},
            {id: 4, title: 'Проекты', path: '/:id/projects', icon: 'Document', section: 'projects'},
            {id: 5, title: 'Услуги', path: '/:id/services', icon: 'Document', section: 'services_all'},
            {id: 6, title: 'Пользователи', path: '/:id/members', icon: 'Users', section: 'users'},
            {id: 7, title: 'Контакты', path: '/:id/contacts', icon: 'Users', section: 'contacts'},
            {
                id: 8,
                title: 'Финансы',
                section: 'transactions_all',
                path: '/:id/transactions',
                icon: 'Dollar',
                children: [
                    {
                        id: 8.1, title: 'Транзакции', path: '/:id/transactions', section: 'transactions_all'
                    },
                    // {
                    //     id: 8.3,
                    //     title: 'Выплаты исполнителям',
                    //     path: '/:id/salary-list',
                    //     section: 'transactions_all'
                    // },
                ]
            },
            {id: 17, title: 'Календарь событий', path: '/:id/calendar', icon: 'Calendar', iconColor: 'white', section: 'calendar'},
            {id: 12, title: 'Счета', path: '/:id/bills', icon: 'Dollar', section: 'bills'},
            {id: 13, title: 'Документы', path: '/:id/documents', icon: 'Settings', section: 'documents'},
            {
                id: 14,
                title: 'Отчеты',
                path: '/:id/reports',
                icon: 'PieChart',
                section: 'reports',
                children: [
                    {
                        id: 14.1,
                        title: 'По таймингу',
                        section: 'reports',
                        path: '/:id/reports/hourly',
                    },
                    {
                        id: 14.2,
                        section: 'finance',
                        title: 'Прибыль и убытки',
                        path: '/:id/reports/incomings-and-outgoings',
                    },
                    {
                        id: 14.3,
                        section: 'finance',
                        title: 'Движение средств',
                        path: '/:id/reports/funds-flow',
                    },
                    {id: 14.3, title: 'Финбаланс', path: '/:id/balance', section: 'finance'},
                ]
            },
            {id: 15, title: 'Настройки', path: '/:id/settings/tasks', icon: 'Settings', section: 'settings'},
        ];
        const routes = computed(() => {
            return getRoutes(allRoutes, user.value.permissions, user.value.role);
        });
        const {currentWorkspaceId} = useWorkspace();
        const workspaces = computed(() => user.value.workspaces);
        const currentWorkspace = computed(() => {
            return workspaces.value && workspaces.value.find(x => x.workspace.id == currentWorkspaceId.value);
        });
        const renderWorkspacePath = () => {
            return `/${currentWorkspaceId.value}/dashboard`;
        };
        return {
            routes,
            currentWorkspace,
            renderWorkspacePath,
            user,
            collapsed,
            sidebarWidth,
            toggleCollapsed,
        };
    }
};
</script>

<style lang="scss">
.sidebar {
    z-index: 2;
    transition: all 0.2s ease-in-out;
    &__wrapper {
        min-height: 100vh;
        height: 100%;
        background: #1f2533;
        position: fixed;
        width: v-bind(sidebarWidth);
    }
    &__logo {
        padding: 25px 0 0 25px;
    }
    &__icon {
        width: 30px;
        display: table;
    }
    &-routes {
        margin-top: 20px;
    }
    &-timing {
        font-size: 20px;
        color: #ffffff;
        font-weight: 500;
        padding: 12px 25px;
    }
    .router-link-active {
        .sidebar-route__item {
            color: #ffffff;
            background: #414455;
        }
    }
    &__collapse {
        position: absolute;
        top: 0;
        right: 0;
        padding: 2px;
        transition: transform .3s;
        transform: rotate(180deg);
        &-wrapper {
            position: relative;
        }
        &.rotate {
            transform: rotate(0deg);
        }
    }
}
.sidebar-fade-enter-active, .sidebar-fade-leave-active {
    transition: width 1s;
}
.sidebar-fade-enter, .sidebar-fade-leave-to{
    width:0;
}
</style>
