<template lang="pug">
.dashboard-card
    working-day-widget
    unpaid-bills-widget
</template>

<script>
export default {
    name: 'DashboardCard'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/

import WorkingDayWidget from '@/components/Dashboard/Widgets/WorkingDay';
import UnpaidBillsWidget from '@/components/Dashboard/Widgets/UnpaidBills';
import {useWorkspace} from '@/composition/workspace';

/*===============INIT AREA===================*/
const {getMembers, currentWorkspaceId} = useWorkspace();
await getMembers(currentWorkspaceId.value);
</script>

<style lang="scss">
.dashboard-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px;
}
</style>