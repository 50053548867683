import {reactive, ref} from 'vue';
import calendarModule from '@/modules/calendar';
import {useDate} from '@/composition/date';
import {CalendarSlotInterface} from '@/modules/calendar/entities/calendar-slot.interface';
import {ListOf} from '@/core/common/interface/list-of.interface';
import {ApiRequestResultInterface} from '@/core/common/interface/api-request-result.interface';
import {apiRequest} from '../../modules/error_handler';
import {OkResponse} from '@/core/common/interface/ok-response.interface';
import {CalendarSlotFormInterface} from '@/modules/calendar/form/calendar-slot-form.interface';
import {CalendarFilterInterface} from '@/modules/calendar/filters/calendar-filter.interface';

export function useCalendar() {
    const {getStartOfMonth} = useDate();

    const calendarSlots = ref<ListOf<CalendarSlotInterface>>({
        list: [],
        next: false,
        total: 0
    });
    const filter = reactive<CalendarFilterInterface>({
        mode: 'month',
        date: getStartOfMonth().format('YYYY-MM-DD'),
        types: [] as string[]
    });
    const createSlot = async (slotData: CalendarSlotFormInterface): Promise<ApiRequestResultInterface<CalendarSlotInterface>> => {
        return apiRequest<CalendarSlotInterface>(async () => {
            return calendarModule.createCalendarSlot(slotData);
        });
    };
    const updateSlot = async (id: number, updateData: CalendarSlotFormInterface): Promise<ApiRequestResultInterface<CalendarSlotInterface>> => {
        return apiRequest<CalendarSlotInterface>(async () => {
            return calendarModule.updateCalendarSlot(id, updateData);
        });
    };
    const deleteSlot = async (id: number): Promise<ApiRequestResultInterface<OkResponse>> => {
        return apiRequest<OkResponse>(async () => {
            return calendarModule.deleteCalendarSlot(id);
        });
    };
    const getSlots = async (filter: CalendarFilterInterface): Promise<ApiRequestResultInterface<ListOf<CalendarSlotInterface>>> => {
        return apiRequest<ListOf<CalendarSlotInterface>>(async () => {
            calendarSlots.value = await calendarModule.getCalendarSlots(filter);
            return calendarSlots.value;
        });
    };
    return {
        filter,
        calendarSlots,
        getSlots,
        createSlot,
        updateSlot,
        deleteSlot,
    };
}
