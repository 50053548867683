import moment from 'moment-timezone';

import {httpInstance} from '@/modules/http_factory';
import {arrayToString, isPrimitiveArray} from '@/modules/utils';
import constants from '@/constants';


const formFilter = (workspaceId, filter) => {
    return {
        workspaceId,
        contacts: filter.clients && filter.clients.length ? arrayToString(filter.clients, !isPrimitiveArray(filter.clients)) : undefined,
        directors: filter.directors && filter.directors.length ? arrayToString(filter.directors, true) : undefined,
        performers: filter.performers && filter.performers.length ? arrayToString(filter.performers, true) : undefined,
        search: filter.search,
        statuses: filter.statuses && filter.statuses.length ? filter.statuses.join(',') : undefined,
        docStatuses: filter.docStatuses && filter.docStatuses.length ? filter.docStatuses.join(',') : undefined,
        serviceGroupId: filter.serviceGroupId && filter.serviceGroupId !== 'all' ? filter.serviceGroupId : undefined,
        workStatusId: filter.workStatus && filter.workStatus.id !== 'all' ? filter.workStatus.id : undefined,
        isArchive: filter.isArchive,
        isFinished: filter.isFinished,
        onlyActual: filter.onlyActual,
        notArchived: filter.notArchived,
        deadline: filter.deadline ? moment.utc(filter.deadline).endOf('day').toISOString() : undefined,
        paymentPeriodStatus: filter.paymentPeriodStatus,
    };
};
const getServices = async (workspaceId, pagination, filter) => {
    console.log('hard');
    const response = await httpInstance(workspaceId).get('/services', {
        params: {
            ...pagination,
            ...formFilter(workspaceId, filter)
        }
    });
    return response.data;
};

const getServicesSimple = async (workspaceId, pagination, filter) => {
    console.log('simple');
    const response = await httpInstance(workspaceId).get('/services/simple', {
        params: {
            ...pagination,
            ...formFilter(workspaceId, filter)
        }
    });
    return response.data;
};

const getSummary = async (workspaceId, filter) => {
    const response = await httpInstance(workspaceId).get('/services/summary', {
        params: {
            ...formFilter(workspaceId, filter)
        }
    });
    return response.data;
};
const getTabs = async (workspaceId, filter) => {
    const response = await httpInstance(workspaceId).get('/services/tabs', {
        params: {
            ...formFilter(workspaceId, filter)
        }
    });
    return response.data;
};
const createService = async (service, workspaceId, tz=constants.tz.default) => {
    const response = await httpInstance(workspaceId).post('/services', {
        ...service,
        workspaceId
    });
    return response.data;
};
const finishService = async (finishData, workspaceId) => {
    const response = await httpInstance(workspaceId).post('/services/finish', {
        id: finishData.id,
        totalCost: finishData.totalCost,
        performers: finishData.performers && Array.isArray(finishData.performers) ? finishData.performers.map(x => ({id: x.id, totalCost: x.totalCost})) : undefined
    });
    return response.data;
};
const returnService = async (returnData, workspaceId) => {
    return updateService(returnData.serviceId, {
        workStatusId: returnData.workStatusId,
    }, workspaceId);
};
const createServicePerformer = async (servicePerformer) => {
    const response = await httpInstance(servicePerformer.workspaceId).post('/services/performers', {
        serviceId: servicePerformer.serviceId,
        workspaceMemberId: servicePerformer.workspaceMemberId,
        workspaceId: Number(servicePerformer.workspaceId),
    });
    return response.data;
};
const deleteServicePerformer = async (id, workspaceId) => {
    const response = await httpInstance(workspaceId).delete(`/services/performers/${id}`, {});
    return response.data;
};
const updateServicePerformer = async (id, updateData, workspaceId) => {
    const response = await httpInstance(workspaceId).patch(`/services/performers/${id}`, {
        currency: updateData.currency,
        hourlyRate: updateData.hourlyRate,
        hoursAmount: updateData.hoursAmount,
        paymentDayNumberOne: updateData?.paymentDates?.dayNumberOne,
        paymentDayNumberTwo: updateData?.paymentDates?.dayNumberTwo,
        paymentCount: updateData?.paymentDates?.count,
        paymentType: updateData.paymentType,
        percentRate: updateData.percentRate,
        totalCost: updateData.totalCost,
        isPaymentAgreed: updateData.isPaymentAgreed,
        documentStatus: updateData.documentStatus
    });
    return response.data;
};
const getService = async (id, workspaceId) => {
    const response = await httpInstance(workspaceId).get(`/services/${id}`);
    return response.data;
};
const updateService = async (id, updateData, workspaceId, tz=constants.tz.default) => {
    const utcOffset = moment.utc().tz(tz).format('Z');
    const startDate = updateData.startDate ? moment(updateData.startDate).startOf('day').tz(tz).toISOString(true) : null;
    const deadline = updateData.deadline ? moment(updateData.deadline).endOf('day').tz(tz).toISOString(true) : null;
    const response = await httpInstance(workspaceId).patch(`/services/${id}`, {
        ...updateData,
        startDate: startDate ? startDate.replace('Z', utcOffset) : undefined,
        deadline: deadline ? deadline.replace('Z', utcOffset) : undefined,
    });
    return response.data;
};
const deleteService = async (id, workspaceId) => {
    const response = await httpInstance(workspaceId).delete(`/services/${id}`);
    return response.data;
};

const setArchive = async (workspaceId, serviceId, bool) => {
    const response = await httpInstance(workspaceId).post(`/services/${serviceId}/archive`, {
        isArchive: bool,
    });
    return response.data;
};
const getPeriods = async() => {
    const response = await httpInstance().get('/services/periods');
    return response.data;
};

export default {
    getTabs,
    getSummary,
    setArchive,
    getServices,
    createServicePerformer,
    createService,
    finishService,
    returnService,
    getService,
    updateService,
    deleteService,
    deleteServicePerformer,
    updateServicePerformer,
    getPeriods,
    getServicesSimple,
};
