<template lang="pug">
a.incomings-and-outgoings-values-list__item(
    v-if="total"
    :class="[getClass(total)]"
    :href="getTransactionsUrl(subCategoryId)"
    target="_blank"
) {{ formatCurrency(total) }} {{ currencyMap[unit] || unit }}
.incomings-and-outgoings-values-list__item(
    v-else
    :class="[getClass(total)]"
) {{ formatCurrency(total) }} {{ currencyMap[unit] || unit }}

</template>

<script>
import {useDate} from '@/composition/date';
import {monthsMap, quartersMap} from '@/components/Analytics/IncomingsAndOutgoings/constants';
import {formatCurrency, isNullOrUndefined} from '@/modules/utils';
import constants from '@/constants';
const typeMap = {
    outgo: 'withdrawal',
    incoming: 'incoming'
};
export default {
    name: 'IncomingsAndOutgoingsValueLink',
    props: {
        total: {
            type: Number
        },
        unit: {
            type: String
        },
        month: {
            type: String,
        },
        quarter: {
            type: String,
        },
        type: {
            type: String,
        },
        year: {
            type: Number,
        },
        workspaceId: {
            type: [Number, String],
        },
        subCategoryId: {
            type: Number,
        },

    },
    setup(props) {
        const {dateInstance} = useDate();
        const getClass = (value) => {
            if (value === 0 || value === null) return '';
            if (value > 0) return 'success';
            return 'danger';
        };
        const getTransactionsUrl = (subCategoryId) => {
            let period;
            if (props.month !== null) {
                const base = dateInstance().utc().set('month', monthsMap[props.month]).set('year', props.year);
                period = base.format('YYYY-MM-01');
            }
            if (props.quarter !== null) {
                const periods = quartersMap[props.quarter].map(x => {
                    return dateInstance()().set('month', x).set('year', props.year).format('YYYY-MM-01');
                });
                period = periods.join(',');
            }
            if (props.year && isNullOrUndefined(props.month) && isNullOrUndefined(props.quarter)) {
                const periods = Object.values(monthsMap).map(x => {
                    return dateInstance()().set('month', x).set('year', props.year).format('YYYY-MM-01');
                });
                period = periods.join(',');
            }
            let url = `/${props.workspaceId}/transactions?period=${period}`;
            if (subCategoryId) {
                url += `&operationId=${subCategoryId}`;
            }
            if (props.type === 'outgo' || props.type === 'incoming') {

                url += `&type=${typeMap[props.type]}`;
            }
            return url;
        };
        return {
            getClass,
            formatCurrency,
            getTransactionsUrl,
            currencyMap: constants.currencyMap
        };
    }
};
</script>

<style lang="scss" scoped>
.incomings-and-outgoings-values-list__item {
    margin-top: 8px;
    white-space: nowrap;
    display: block;
}
</style>