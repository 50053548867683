<template lang="pug">
.member-work-basis
    member-documents-form(v-model:documents="documents" :directories="directories")
</template>

<script>
import {ref, watch} from 'vue';
import MemberDocumentsForm from '@/components/Workspace/InviteUserForm/MemberDocumentsForm';

export default {
    name: 'MemberDocuments',
    components: {MemberDocumentsForm},
    props: {
        member: Object,
        directories: Object
    },
    emits: ['update'],
    setup(props, {emit}) {
        const documents = ref({
            value: props.member?.documents.map(x => ({
                ...x.document || {}
            }))
        });
        watch(documents, () => {
            emit('update', {
                documents: documents.value.value,
            });
        });
        return {
            documents
        };
    }
};
</script>

<style lang="scss" scoped>
.member-work-basis {
    max-width: 500px;
}
</style>