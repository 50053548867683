import {apiRequest} from '@/modules/error_handler';
import uploaderModule from '@/modules/uploader';
export function useDownload(loading={value: false}) {
    const download = async(workspaceId, key) => {
        return apiRequest(async() => {
            return uploaderModule.getFileUrl(workspaceId, key);
        }, loading);
    };
    return {
        download
    };
}
