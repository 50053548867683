<template lang="pug">
settings-directories-contacts-layout
    async-page
        loyalty-table
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import LoyaltyTable from '@/components/Directories/Contacts/Loyalty/Table';
import SettingsDirectoriesContactsLayout from '@/pages/Settings/Directories/Contacts/Layout';
export default {
    name: 'LoyaltyPage',
    components: {SettingsDirectoriesContactsLayout, LoyaltyTable, AsyncPage}
};
</script>

<style scoped>

</style>