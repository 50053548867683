<template lang="pug">
.service-summary-data(v-if="service.id")
    .widget-card.select-none
        .widget-card__text Бюджет
        .flex.items-center.justify-between
            .widget-card__num.widget-card__num--black.whitespace-nowrap {{ !service.isPaymentAgreed ? '~' : ''}} {{ formatCurrency(service.paymentData.incoming) }} руб.
            .cursor-pointer.whitespace-nowrap.mr-2(
                :class="[service.isPaymentAgreed ? 'success' : 'danger']"
                @click="updatePaymentAgreement(!service.isPaymentAgreed)"
            ) {{ service.isPaymentAgreed ? 'Согласовано' : 'Не согласовано' }}
        status-progress(
            :percent="getPercent(service.factPaymentData.incoming, service.paymentData.incoming)"
            :style="{width: '100%'}"
        )
        .widget-card__text Выплачено: {{ formatCurrency(service.factPaymentData.incoming) }} руб.
        .widget-card__text Остаток: {{ formatCurrency(service.paymentData.incoming - service.factPaymentData.incoming) }} руб.
        .widget-card__text(v-if="service.hourlyRate") Часовая ставка: {{ service.hourlyRate }} {{ constants.currencyMap[service.currency] }}
        .widget-card__text(v-if="service.hoursPayload") Количество часов: {{ service.hoursPayload }} ч.
    .widget-card.select-none
        .widget-card__text Себестоимость
        .flex.items-center.justify-between
            .widget-card__num.widget-card__num--black.whitespace-nowrap {{ !service.isExpensesPaymentsAgreed ? '~' : ''}} {{ formatCurrency(service.paymentData.outgoing) }} руб.
            router-link.whitespace-nowrap.mr-2(
                :class="[service.isExpensesPaymentsAgreed ? 'success' : 'danger']"
                :to="getPerformersRoute()"
            ) {{ service.isExpensesPaymentsAgreed ? 'Согласовано' : 'Не согласовано' }}
        status-progress(
            :percent="getPercent(service.factPaymentData.outgoing, service.paymentData.outgoing)"
            :style="{width: '100%'}"
        )
        .widget-card__text Выплачено: {{ formatCurrency(service.factPaymentData.outgoing) }} руб.
        .widget-card__text Остаток: {{ formatCurrency(service.paymentData.outgoing - service.factPaymentData.outgoing) }} руб.
    .widget-card.select-none
        .widget-card__text Маржинальность
        .widget-card__num.widget-card__num--black.whitespace-nowrap {{ !service.isPaymentAgreed || !service.isExpensesPaymentsAgreed ? '~' : '' }} {{ formatCurrency(service.paymentData.margin) }} руб. {{service.paymentData.margin > 0 ? `(${service.paymentData.marginPercent}%)` : ''}}
        a-progress(
            :percent="service.paymentData.marginPercent"
            :show-info="false"
            :stroke-color="{'0%': '#FAAD14','100%': '#28a745'}"
            type="circle"
        )
    .widget-card.select-none
        card-title Тайминг и финансы
        card-title(size="small") Тайминг
        status-progress(
            :tooltipText="`${service.budgetExpenditure.loggedTiming} из ${service.budgetExpenditure.timingByPerformers} ч.`"
            v-if="service.budgetExpenditure.timingByPerformers"
            :percent="getPercent(service.budgetExpenditure.loggedTiming, service.budgetExpenditure.timingByPerformers)"
            :style="{width: '100%'}"
        )
        .widget-card__text Залогировано: {{ service.budgetExpenditure.loggedTiming }} ч.
        .widget-card__text(v-if="service.budgetExpenditure.timingByPerformers") Остаток: {{ service.budgetExpenditure.timingByPerformers - service.budgetExpenditure.loggedTiming }} ч.
        card-title.mt-2(size="small") Фактический расход бюджета
        status-progress(
            :percent="getPercent(service.budgetExpenditure.finance, service.paymentData.outgoing)"
            :style="{width: '100%'}"
            :tooltipText="`${service.budgetExpenditure.finance} из ${service.paymentData.outgoing} руб.`"
        )
        .widget-card__text Расход: {{ formatCurrency(service.budgetExpenditure.finance) }} руб.
        .widget-card__text Остаток: {{ formatCurrency(service.paymentData.outgoing - service.budgetExpenditure.finance) }} руб.
    .widget-card.select-none
        .widget-card__text Счета с клиентом
        .flex.items-center.my-3
            icon.inline(:type="getIncomingPaymentStatus(service).type" size="large")
            .widget-card__subtitle.ml-3 {{ getIncomingPaymentStatus(service).text }}
        .widget-card__text Счета с исполнителями
        .flex.items-center.my-3
            icon.inline(:type="getOutPaymentStatus(service).type" size="large")
            .widget-card__subtitle.ml-3 {{ getOutPaymentStatus(service).text }}
    .widget-card.select-none
        .widget-card__text Документооборот с клиентом
        .flex.items-center.my-3.cursor-pointer(@click="showEditDocStatus=true" v-if="!showEditDocStatus")
            icon.inline(:type="serviceDocStatus(service).type" size="large")
            .widget-card__subtitle.ml-3 {{ serviceDocStatus(service).text }}
        a-select.my-3(
            :value="service.documentStatus"
            @change="changeDocumentStatus"
            @blur="showEditDocStatus=false"
            style="width: 180px"
            v-else
        )
            a-select-option(
                v-for="status in getDocumentStatusOptions()"
                :key="status.id"
                :value="status.id"
            ) {{ status.title }}
        .widget-card__text Документооборот с исполнителями
        .flex.items-center.my-3
            icon.inline(:type="servicePerformersDocStatus(service).type" size="large")
            .widget-card__subtitle.ml-3 {{ servicePerformersDocStatus(service).text }}
icon(type="Loader" v-else)
</template>

<script>
import CardTitle from '@/components/Generic/Typography/CardTitle';
import StatusProgress from '@/components/Generic/StatusProgress';
import {useService} from '@/composition/service';
import {useDate} from '@/composition/date';
import {formatCurrency, getPercent} from '@/modules/utils';
import {ref} from 'vue';
import constants from '@/constants';
export default {
    name: 'ServiceSummaryData',
    components: {
        CardTitle,
        StatusProgress,
    },
    setup() {
        const {
            service,
            updateService,
            getService,
            getIncomingPaymentStatus,
            getOutPaymentStatus,
            serviceDocStatus,
            servicePerformersDocStatus,
            getDocumentStatusOptions,
        } = useService();
        const showEditDocStatus = ref(false);
        const updatePaymentAgreement = async (isPaymentAgreed) => {
            await updateService(service.value.id, {isPaymentAgreed}, service.value.workspaceId);
            await getService(service.value.id, service.value.workspaceId);
        };
        const changeDocumentStatus = async (documentStatus) => {
            await updateService(service.value.id, {documentStatus}, service.value.workspaceId);
            await getService(service.value.id, service.value.workspaceId);
            showEditDocStatus.value = false;
        };
        const getPerformersRoute = () => {
            return `/${service.value.workspaceId}/services/${service.value.id}/performers`;
        };
        return {
            constants,
            service,
            showEditDocStatus,
            getPercent,
            formatCurrency,
            serviceDocStatus,
            getPerformersRoute,
            getOutPaymentStatus,
            changeDocumentStatus,
            updatePaymentAgreement,
            getDocumentStatusOptions,
            getIncomingPaymentStatus,
            servicePerformersDocStatus,
            ...useDate()
        };
    }
};
</script>

<style lang="scss" scoped>
.service-summary-data {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(371px, 1fr));
    grid-gap: 16px;
    &__progress {
        max-width: 120px;
    }
    &__stat-item {

    }
    &__stat-value {
        display: flex;
        align-items: center;
        margin-top: 12px;
    }
    &__block {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 16px;
    }
}
</style>
