<template lang="pug">
.bills-table
    a-button.mb-2(type="primary" @click="setModalVisibility(true)") Добавить
    bills-filter(@filter="onFilterChanged" :tabs="tabs" :directories="directories" :serviceId="serviceId")
    bills-summary(:summary="summary")
    custom-table(
        :columns="columns"
        :data-source="bills.list"
        :onRowClick="(record) => editBill(record)"
    )
        template(#rowPrefix="{record}")
            a-tooltip
                template(#title)
                    div {{ record?.status?.title }}
                .color-circle(:style="{background: record?.status?.color}")
        template(#id="{record}")
            div {{ record.id }}
        template(#number="{record}")
            editable-cell(
                :initialValue="record.number"
                @update="(e) => onUpdate(record.id, 'number', e)"
                :title="record.number"
            )
        template(#cost="{record}")
            div
                div {{ formatCurrency(record.paid) || 0 }} из {{ formatCurrency(record.cost) || 0 }} руб. (Остаток {{ formatCurrency((record.cost || 0) - (record.paid || 0)) }} )
                a-progress(:percent="getPercent(record.paid, record.cost)")
        template(#date="{record}")
            div
                editable-cell(
                    :initialValue="record.date"
                    @update="(e) => onUpdate(record.id, 'date', e)"
                    emptyText="Не указано"
                    type="date"
                    :title="formatDate(record.date)"
                )
        template(#customer="{record}")
            div {{ getTitle(record, 'customer', ['title', 'fullName']) }}
        template(#performer="{record}")
            div {{ getTitle(record, 'performer', ['title', 'fullName']) }}
        template(#status="{record}")
            editable-cell(
                :initialValue="record.statusId"
                empty-text="Не указано"
                @update="(e) => onUpdate(record.id, 'statusId', e)"
                type="select"
                :title="record?.status?.title"
                :options="directories.billStatuses.list"
            )
        template(#services="{record}")
            div {{ getServicesText(record) }}
        template(#pdf="{record}")
            a-button(@click.stop="() => downloadBill(record.id)") Скачать
        template(#paymentDates="{record}")
            editable-cell(
                :initialValue="record.payDateTo"
                @update="(e) => onUpdate(record.id, 'payDateTo', e)"
                emptyText="Не указано"
                type="date"
                :title="formatDate(record.payDateTo)"
                :style="isOutdated(record.payDateTo) && record.paymentStatus?.key !== 'full_paid' ? {color: 'red'} : {}"
            )
        template(#action="{record}")
            a-dropdown(:trigger="['click']" @click.stop)
                .dots-wrapper
                    icon.cursor-pointer(type="Dots")
                template(#overlay)
                    a-menu
                        a-menu-item(key="0")
                            div(@click="duplicateBill(record)") Дублировать
                        a-menu-item(key="1")
                            div(@click="editBill(record)") Редактировать
                        a-menu-item(key="2")
                            delete-with-confirm(:deleteFunc="() => onDelete(record.id)" btnText="Удалить")
        template(#transactions="{record}")
            .flex.items-center.justify-between
                div.whitespace-nowrap.base-link(
                    @click.stop.prevent="showAllTransactionsList(record)"
                ) {{ formatCurrency(record.paidWithPlanned) }} руб.
                .cursor-pointer.ml-2(@click.stop.prevent="addNewTransaction(record, false)")
                    icon(type="Plus")
    a-button.mt-3(@click="next" v-if="bills.next" :loading="loading") Показать еще
    a-modal(
        v-model:visible="showModal"
        :title="currentMode === 'edit' ? 'Редактировать счет' : 'Добавить счет'"
        :footer="null"
        @cancel="setModalVisibility(false)"
        :destroyOnClose="true"
        style="top: 0"
        width="50%"
    )
        create-bill-form(
            :workspaceId="currentWorkspaceId"
            @ok="onCreate"
            @update="putUpdate"
            :mode="currentMode"
            :default-value="editingElement"
        )
    a-drawer(
        :visible="showTransactionModal"
        :title="currentTransactionMode === 'create' ? 'Добавить транзакцию' : 'Редактировать'"
        @close="setTransactionModalVisibility(false)"
        :closable="true"
        width="80%"
        :footer="null"
        :destroyOnClose="true"
    )
        create-transaction-form(
            :workspaceId="currentWorkspaceId"
            @ok="onTransactionCreated"
            @update="onTransactionUpdate"
            :default-value="editingTransaction"
            :mode="currentTransactionMode"
        )
    a-drawer(
        :visible="showTransactionsListModal"
        title="Список транзакций"
        @close="onTransactionsListClosed"
        :closable="true"
        width="100%"
        :footer="null"
        :destroyOnClose="true"
    )
        transactions-list(
            :transactions="selectedBill.transactions"
            :bill="selectedBill"
            @edit="onTransactionEdit"
            @confirm="onTransactionConfirm"
        )
</template>

<script>
import {BillPaymentStatusKey, columns} from '../constants';
import {ref} from 'vue';
import {useWorkspace} from '@/composition/workspace';
import EditableCell from '@/components/Generic/EditableCell';
import Icon from '@/components/Generic/Typography/Icon';
import CustomTable from '@/components/Generic/CustomTable';
import {useCreateOrUpdateModal} from '@/composition/create-update-modal';
import {useRouter} from 'vue-router';
import CreateBillForm from '@/components/Bills/CreateForm';
import {useBill} from '@/composition/bill';
import {useDate} from '@/composition/date';
import {formatCurrency, getPercent, getTitle} from '@/modules/utils';
import DeleteWithConfirm from '@/components/Generic/DeleteWithConfirm';
import constants from '@/constants';
import BillsFilter from '@/components/Bills/Filter';
import BillsSummary from '@/components/Bills/Summary';
import {useDirectory} from '@/composition/directory';
import {useService} from '@/composition/service';
import CreateTransactionForm from '@/components/Transactions/CreateForm/index';
import {useTransaction} from '@/composition/transaction';
import {transactionType} from '@/components/Transactions/constants';
import {toFixedNumber} from '@/modules/utils/v2.ts';
import Big from 'big.js';
import TransactionsList from '@/components/Transactions/List/index';
export default {
    name: 'BillsTable',
    components: {
        TransactionsList,
        CreateTransactionForm,
        BillsSummary,
        BillsFilter,
        DeleteWithConfirm,
        CreateBillForm,
        CustomTable,
        Icon,
        EditableCell
    },
    props: {
        serviceId: Number,
    },
    async setup() {
        const loading = ref(false);
        const {
            currentMode,
            editingValue: editingElement,
            showModal,
            setModalVisibility,
            edit
        } = useCreateOrUpdateModal();
        const {currentWorkspaceId} = useWorkspace(loading);
        const router = useRouter();
        const {downloadBill, getBills, deleteBill, updateBill, pagination, setFilter, bills, bill, getSummary, summary, tabs, getTabs} = useBill(loading);
        const {directories, getDirectories} = useDirectory(loading);
        const {getServices, setFilter: setServiceFilter} = useService(loading);
        bill.value = {};
        setServiceFilter({});
        await getServices(currentWorkspaceId.value);
        await getDirectories(currentWorkspaceId.value);
        const onDelete = async (id) => {
            pagination.value.offset = 0;
            await deleteBill(currentWorkspaceId.value, id);
            await fetchData(false);
        };
        const onUpdate = async (id, key, value) => {
            const {ok} = await updateBill(currentWorkspaceId.value, id, {[key]: value});
            if (ok) {
                pagination.value.offset = 0;
                await fetchData(false);
            }
        };
        const editBill = bill => {
            edit({
                ...bill,
                bankAccountId: bill.bankAccount
            });
        };
        const duplicateBill = bill => {
            editingElement.value = {
                ...bill,
                bankAccountId: bill.bankAccount,
                number: null,
            };
            setModalVisibility(true);
        };
        const putUpdate = async (data) => {
            const {id, ...updateData} = data;
            await updateBill(currentWorkspaceId.value, id, updateData);
            setModalVisibility(false);
            await fetchData(false);
        };
        const next = async () => {
            pagination.value.offset += pagination.value.limit;
            await fetchData(true);
        };
        const onFilterChanged = async(filter) => {
            pagination.value.offset = 0;
            setFilter(filter);
            await fetchData(false);
        };
        const onCreate = async() => {
            pagination.value.offset = 0;
            await fetchData(false);
            setModalVisibility(false);
        };
        const getServicesText = (bill) => {
            return bill?.services.map(billService => billService?.title).join(', ');
        };
        const getCardLink = (id) => {
            return `/${currentWorkspaceId.value}/bills/${id}/common`;
        };
        const redirectToCard = (item) => {
            router.push(getCardLink(item.id));
        };
        const fetchData = async (fromPagination=false) => {
            await getBills(currentWorkspaceId.value, {fromPagination});
            await getTabs(currentWorkspaceId.value);
            await getSummary(currentWorkspaceId.value);
        };

        /*===============TRANSACTIONS AREA===================*/
        const {dateInstance} = useDate();
        const {updateTransaction} = useTransaction();
        const {
            setModalVisibility: setTransactionModalVisibility,
            showModal: showTransactionModal,
            currentMode: currentTransactionMode,
            edit: onTransactionEdit,
            editingValue: editingTransaction
        } = useCreateOrUpdateModal();
        const onTransactionCreated = () => {
            setTransactionModalVisibility(false);
            editingTransaction.value = {};
            fetchData(false);
        };
        const addNewTransaction = (record, isScheduled) => {
            const operation = directories.value.billOperations.list.find((x) => x.key === 'service_completion');
            const services = record.services;
            const date = record.date;
            const period = dateInstance()(date).startOf('month').format('YYYY-MM-01');
            editingTransaction.value = {
                contractorId: record?.customerId,
                billId: record.id,
                type: transactionType.incoming,
                isScheduled: isScheduled,
                date: date,
                operationId: operation?.id,
                toAccountId: record.bankAccountId,
                period: period,
                services: services.map(x => {
                    return {
                        id: x.id || Symbol(Date.now()),
                        serviceId: x.serviceId,
                        amount: toFixedNumber(x.items.reduce((prev, curr) => {
                            return prev.plus(Big(Big(curr.sum || 0).times(curr.amount || 0)));
                        }, Big(0))),
                        paymentPeriod: x.paymentPeriod
                    };
                }),
            };
            setTransactionModalVisibility(true);
        };

        const onTransactionUpdate = async (transaction) => {
            const {id, ...updateData} = transaction;
            const {ok, result} = await updateTransaction(currentWorkspaceId.value, id, updateData);
            if (ok) {
                selectedBill.value.transactions = selectedBill.value.transactions.map(x => {
                    if (x.id === id) {
                        return result;
                    }
                    return x;
                });
                setTransactionModalVisibility(false);
                fetchData(false);
            }
        };
        const onTransactionConfirm = async (transaction) => {
            await onTransactionUpdate({
                ...transaction,
                isScheduled: false
            });
            return transaction;
        };
        /*====================SHOW TRANSACTIONS LIST=================*/
        const {
            setModalVisibility: setTransactionsListModalVisibility,
            showModal: showTransactionsListModal,
            editingValue: selectedBill
        } = useCreateOrUpdateModal();

        const onTransactionsListClosed = () => {
            setTransactionsListModalVisibility(false);
            selectedBill.value = {};
        };
        const showAllTransactionsList = (bill) => {
            selectedBill.value = bill;
            setTransactionsListModalVisibility(true);
        };
        return {
            duplicateBill,
            showTransactionModal,
            currentTransactionMode,
            onTransactionEdit,
            editingTransaction,
            onTransactionCreated,
            addNewTransaction,
            onTransactionUpdate,
            onTransactionConfirm,
            onTransactionsListClosed,
            showAllTransactionsList,
            setTransactionsListModalVisibility,
            setTransactionModalVisibility,
            showTransactionsListModal,
            selectedBill,
            downloadBill,
            getCardLink,
            tabs,
            bills,
            summary,
            columns,
            loading,
            showModal,
            currentMode,
            directories,
            currencyMap: constants.currencyMap,
            editingElement,
            edit,
            next,
            editBill,
            onCreate,
            getTitle,
            onUpdate,
            onDelete,
            putUpdate,
            formatCurrency,
            getPercent,
            redirectToCard,
            getServicesText,
            onFilterChanged,
            setModalVisibility,
            currentWorkspaceId,
            ...useDate()
        };
    }
};
</script>

<style lang="scss" scoped>
.color-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #e6e6e6;
}
</style>
