<template lang="pug">
.members-list-item
    .flex.items-center.justify-between
        user-avatar(:user="member" placement="top" showName v-if="member" :is-me="isMe()")
        a-tooltip(placement="top")
            template(#title)
                span {{ getMemberStatusText() }}
            icon.ml-3(:type="getMemberStatus()")
    slot(name="footer")
</template>

<script>
import {useAuth} from '@/composition/auth';

export default {
    name: 'MembersListItem',
    props: {
        member: Object
    },
    setup(props) {
        const {user} = useAuth();
        const isMe = () => {
            return user.value.id === props.member?.user?.id;
        };
        const getMemberStatusText = () => {
            if (!props.member.inviteAccepted) {
                return 'Участник еще не принял приглашение';
            }
            if (props.member.accessAllowed) {
                return 'Ок';
            }
            return 'Доступ запрещен';
        };
        const getMemberStatus = () => {
            if (!props.member.inviteAccepted) {
                // ожидаем входа участника
                return 'Finger';
            }
            if (props.member.accessAllowed) {
                return 'Ok';
            }
            return 'Minus';
        };
        return {
            getMemberStatusText,
            getMemberStatus,
            isMe
        };
    }
};
</script>

<style scoped>

</style>