<template lang="pug">
.services-inline-changer
    .services-inline-changer__title(@click.stop="setPopupVisibility(true)")
        span {{ contact?.title || 'Не указано' }}
    a-popover(
        :visible="showPopup"
        placement="bottomLeft"
    )
        template(#content)
            .services-inline-changer__popup
                custom-select-v2(
                    v-model="newContact"
                    placeholder="Контакт"
                    :style="{width: '100%'}"
                    :options="clients.list"
                    searchable
                    @search="onContactsSearch"
                    :loading="fetchLoading"
                )
                .services-inline-changer__popup-btns
                    a-button(@click="onSave" type="primary" :loading="loading") Сохранить
                    a-button.ml-3(@click="onDecline") Отменить
</template>

<script>
export default {
    name: 'ContactsInlineChanger'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
/*===============INIT AREA===================*/
import {ref, onMounted, watch} from 'vue';
import CustomSelectV2 from '@/components/Generic/CustomSelectV2';
import {useServiceV2} from '@/composition/service-v2';
import {debounce} from '@/modules/utils';
import {useWorkspace} from '@/composition/workspace';
import {useLoading} from '@/composition/loading-state';
import {useContactV2} from '@/composition/contact-v2';

const props = defineProps({
    contact: {
        type: Object,
    },
    func: {
        type: Function
    },
    withCompanies: {
        type: Boolean,
        default: false
    }
});
const emit = defineEmits(['done']);
const {loading, withLoader} = useLoading();
const {loading: fetchLoading, withLoader: wrapFetchAsync} = useLoading();
/*===============SERVICE AREA===================*/
const newContact = ref(props.contact);
const {currentWorkspaceId} = useWorkspace();
const {getClients, clients} = useContactV2();
const onContactsSearch = (q) => {
    debounce(async () => {
        await wrapFetchAsync(async () => {
            await getClients(currentWorkspaceId.value, q, props.withCompanies);
        });
    }, 500)();
};
watch(() => props.contact, () => {
    newContact.value = props.contact;
}, {deep: true});
const onSave = async () => {
    await withLoader(async () => {
        const contactId = newContact.value ? newContact.value.id : null;
        await props.func(contactId);
    });
    setPopupVisibility(false);
};
const onDecline = () => {
    newContact.value = props.contact;
    setPopupVisibility(false);
};
/*===============POPUP AREA===================*/
const showPopup = ref(false);
const setPopupVisibility = async (bool) => {
    showPopup.value = bool;
    if (bool) {
        await wrapFetchAsync(async () => {
            await getClients(currentWorkspaceId.value, undefined, props.withCompanies);
        });
    }
};
</script>

<style lang="scss" scoped>
.services-inline-changer {
    &__title {
        min-height: 32px;
        min-width: 40px;
        display: flex;
        align-items: center;
        width: fit-content;
        & span {
            text-decoration: underline;
            text-decoration-style: dotted;
            text-decoration-thickness: from-font;
            cursor: url("~@/assets/img/edit.png") 4 12, auto;
        }
    }
    &__popup {
        width: 240px;
        &-btns {
            margin-top: 12px;
        }
    }
}
</style>
