<template lang="pug">
contact-layout
    async-page
        contact-common-data
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import ContactLayout from '@/pages/Contacts/Contact/Layout';
import ContactCommonData from '@/components/Contacts/Common';
export default {
    name: 'ContactCommonPage',
    components: {ContactCommonData, ContactLayout, AsyncPage}
};
</script>

<style scoped>

</style>