import {apiRequest} from '@/modules/error_handler';
import uploaderModule from '@/modules/uploader';
import mime from 'mime-types';

export function useUploader(loading={value: false}) {
    const upload = async(file, targetTypes, uploadProgressHandler, isPrivate) => {
        return apiRequest(async() => {
            const extension = file.name.split('.').pop();
            if (targetTypes) {
                if (targetTypes.findIndex(x => x === extension) === -1) {
                    throw new Error('Неверный формат');
                }
            }
            const contentType = file.type ? file.type : mime.lookup(file.name);
            if (!contentType || !contentType.trim()) {
                throw new Error('Неверный формат');
            }
            const {Key, UploadURL} = await uploaderModule.getUploadUrl(extension, contentType, isPrivate);
            await uploaderModule.uploadFile(UploadURL, file, contentType, uploadProgressHandler);
            return Key;
        }, loading);
    };
    return {
        upload
    };
}