import {formatCurrency, truncateText} from '@/modules/utils';
import './items-per-service.scss';

export default {
    name: 'ItemsPerService',
    props: {
        services: {
            type: Array
        },
        positions: {
            type: Array
        },
        currency: {
            type: String,
        },
        workspaceId: {
            type: Number
        }
    },
    render(props) {
        const renderers = (props.services || []).concat((props.positions || []));
        return renderers.map(row => {
            const title = row.service ? `${truncateText(row.service?.fullTitle, 80)} ${row?.paymentPeriod?.title || ''}` : row.title;
            return (
                <div className={'items-per-service'}>
                    <div className={'items-per-service__amount'}>{formatCurrency(row.amount)} {props.currency}</div>
                    {row.service ? <router-link
                        className={'items-per-service__title'}
                        to={`/${row.service.workspaceId}/services/${row?.service?.id}/payment-periods`}
                        title={title}
                    >
                        {title}
                    </router-link> : <div>{truncateText(title, 80)}</div>}
                </div>
            );
        });
    }
};