<template lang="pug">
.project-common-data
    .project-common-data__content
        .editable-card__column
            .flex.items-center Наименование
            string-inline-changer(
                :str="project.title"
                :func="async (title) => await putUpdate({id: project.id, title})"
                placeholder="Наименование"
            )
        .editable-card__column
            div Клиент
            contacts-inline-changer(
                :with-companies="true"
                :contact="project.client"
                :func="async (clientId) => await putUpdate({id: project.id, clientId})"
            )
        .editable-card__column
            .flex.items-center Идентификатор
            string-inline-changer(
                :str="project.slug"
                :func="async (slug) => await putUpdate({id: project.id, slug})"
                placeholder="Идентификатор"
            )
    .project-common-data__actions
        a-button(type="primary" @click="editProject(project)") Редактировать
    a-modal(
        v-model:visible="showModal"
        title="Создать проект"
        :footer="null"
        @cancel="setModalVisibility(false)"
        :destroyOnClose="true"
    )
        create-project-form(
            :workspaceId="currentWorkspaceId"
            @update="putUpdate"
            :mode="currentMode"
            :default-value="editingElement"
        )
</template>

<script>
import EditableCell from '@/components/Generic/EditableCell';
import {useProject} from '@/composition/project';
import {ref} from 'vue';
import {useRoute} from 'vue-router';
import {useWorkspace} from '@/composition/workspace';
import {useCreateOrUpdateModal} from '@/composition/create-update-modal';
import {useContact} from '@/composition/contact';
import CreateProjectForm from '@/components/Projects/CreateProjectForm';
import ContactsInlineChanger from '@/components/Contacts/InlineChanger';
import StringInlineChanger from '@/components/Generic/InlineChanger/StringInlineChanger';
export default {
    name: 'ProjectCommonData',
    components: {StringInlineChanger, ContactsInlineChanger, CreateProjectForm, EditableCell},
    async setup() {
        const loading = ref(false);
        const {
            currentMode,
            editingValue: editingElement,
            showModal,
            setModalVisibility,
            edit
        } = useCreateOrUpdateModal();
        const route = useRoute();
        const {project, getProject, getClientText, getClientsOptions, updateProject} = useProject(loading);
        const {currentWorkspaceId} = useWorkspace();
        const {getClients, clients} = useContact(loading);

        await getClients(currentWorkspaceId.value, undefined, true);
        await getProject(route.params.projectId, currentWorkspaceId.value);

        const putUpdate = async (data) => {
            const {id: _, ...updateData} = data;
            await updateProject(route.params.projectId, updateData, currentWorkspaceId.value);
            await getProject(route.params.projectId, currentWorkspaceId.value);
            setModalVisibility(false);
        };
        const onClientUpdate = async (data) => {
            await putUpdate(data);
        };
        const editProject = (project) => {
            edit({
                ...project,
            });
        };
        return {
            project,
            clients,
            showModal,
            currentMode,
            editingElement,
            edit,
            putUpdate,
            editProject,
            getClientText,
            onClientUpdate,
            getClientsOptions,
            currentWorkspaceId,
            setModalVisibility,
        };
    }
};
</script>

<style lang="scss" scoped>
.project-common-data {
    display: grid;
    grid-template-columns: 1fr 400px;
    grid-gap: 20px;

    &__content {

    }

    &__actions {

    }
}
</style>