<template lang="pug">
.projects-table
    a-button(type="primary" @click="setModalVisibility(true)" v-if="!fromIntegration") Добавить статус
    custom-table(:columns="columns" :data-source="data.list" draggable @sortOrderChanged="(e) => onSortOrderChanged(e, currentWorkspaceId)")
        template(#title="{text, record}")
            .projects-table__title(v-if="record")
                editable-cell(:initialValue="record.title" @update="(e) => onUpdate(record.id, 'title', e)")
        template(#action="{record}")
            a-dropdown(:trigger="['click']" @click.stop)
                .dots-wrapper
                    icon.cursor-pointer(type="Dots")
                template(#overlay)
                    a-menu
                        a-menu-item(key="0")
                            delete-with-confirm(:deleteFunc="() => onDelete(record.id)"  btn-text="Удалить")
    a-button.mt-3(@click="next" v-if="data.next" :loading="loading") Показать еще
    a-modal(
        v-model:visible="showModal"
        title="Создать статус"
        :footer="null"
        @cancel="setModalVisibility(false)"
        :destroyOnClose="true"
    )
        create-bill-status-form(:workspaceId="currentWorkspaceId" @ok="onCreate")
</template>

<script>
import {columns} from '../constants';
import {ref} from 'vue';
import {useWorkspace} from '@/composition/workspace';
import EditableCell from '@/components/Generic/EditableCell';
import {useCrud} from '@/composition/crud';
import CreateBillStatusForm from '@/components/Directories/Bills/Status/CreateForm';
export default {
    name: 'BillStatusTable',
    components: {CreateBillStatusForm, EditableCell},
    props: {
        fromIntegration: Boolean
    },
    async setup() {
        const loading = ref(false);
        const showModal = ref(false);
        const {getData, deleteEntity, updateEntity, updateLocal, data, pagination, onSortOrderChanged} = useCrud('bill-statuses');
        const {currentWorkspaceId} = useWorkspace(loading);
        const setModalVisibility = bool => {
            showModal.value = bool;
        };
        await getData(currentWorkspaceId.value);
        const onCreate = async () => {
            pagination.value.offset = 0;
            setModalVisibility(false);
            await getData(currentWorkspaceId.value);
        };
        const onDelete = async (id) => {
            pagination.value.offset = 0;
            await deleteEntity(id, currentWorkspaceId.value);
            await getData(currentWorkspaceId.value);
        };
        const onUpdate = async (id, key, value) => {
            const {ok, result} = await updateEntity(id, {[key]: value}, currentWorkspaceId.value);
            if (ok && result) {
                updateLocal(result);
            }
        };
        const next = async () => {
            pagination.value.offset += pagination.value.limit;
            await getData(currentWorkspaceId.value, {fromPagination: true});
        };
        return {
            data,
            columns,
            loading,
            setModalVisibility,
            showModal,
            onCreate,
            currentWorkspaceId,
            next,
            onDelete,
            onUpdate,
            onSortOrderChanged,
        };
    }
};
</script>

<style lang="scss" scoped>

</style>