import {ref} from 'vue';
const collapsed = ref(localStorage.getItem('collapsed') === 'true');
const sidebarWidth = ref(collapsed.value ? '80px' : '230px');
export function useLayout() {
    const toggleCollapsed = () => {
        collapsed.value = !collapsed.value;
        localStorage.setItem('collapsed', collapsed.value);
        sidebarWidth.value = collapsed.value ? '80px' : '230px';
    };
    return {
        sidebarWidth,
        collapsed,
        toggleCollapsed
    };
}