<template lang="pug">
.page.workspace-wrapper
    a-tabs(v-model:activeKey="activeKey" @change="onChange")
        a-tab-pane(v-for="tab in tabs" :key="tab.key" :tab="tab.tab")
    slot
</template>

<script>
import {onMounted, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useWorkspace} from '@/composition/workspace';

export default {
    name: 'SettingsLayout',
    setup() {
        const activeKey = ref(undefined);
        const {currentWorkspaceId, getWorkspaceById} = useWorkspace();
        const router = useRouter();
        const route = useRoute();
        const tabs = [
            {
                key: 'workspace',
                tab: 'Основное'
            },
            {
                key: 'companies',
                tab: 'Компании и счета'
            },
            {
                key: 'tasks',
                tab: 'Задачи'
            },
            {
                key: 'notifications',
                tab: 'Уведомления'
            },
            {
                key: 'integrations',
                tab: 'Интеграции'
            },
            {
                key: 'directories',
                tab: 'Справочники'
            },
        ];
        const onChange = (val) => {
            router.push(`/${currentWorkspaceId.value}/settings/${val}`);
        };
        onMounted(async () => {
            tabs.forEach(x => {

                if (route.path.indexOf(x.key) !== -1) {
                    activeKey.value = x.key;
                }
            });
            await getWorkspaceById(route.params.id);
        });
        return {
            activeKey,
            onChange,
            tabs,
        };
    }
};
</script>

<style scoped>

</style>
