export const columns = [
    {
        title: 'Исполнитель',
        key: 'performer',
        slots: {customRender: 'performer'},
        width: '15%',
    },
    {
        title: 'Тип оплаты',
        key: 'paymentType',
        slots: {customRender: 'paymentType'},
        width: '6%',
    },
    {
        title: 'Ставка',
        key: 'rate',
        slots: {customRender: 'rate'},
        width: '6%'
    },
    {
        title: 'Утилизация (план / факт / разница), ч',
        key: 'utilization',
        slots: {customRender: 'utilization'},
        width: '15%',
        summarizeSlot: 'utilizationSummary'
    },
    {
        title: 'План, руб.',
        key: 'financePlan',
        slots: {customRender: 'financePlan'},
        width: '10%',
        summarize: true,
    },
    {
        title: 'К оплате / Оплачено / Остаток, руб',
        key: 'finance',
        slots: {customRender: 'finance'},
        width: '15%',
        summarizeSlot: 'financeSummary'
    },
];
