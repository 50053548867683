<template lang="pug">
settings-directories-contacts-layout
    async-page
        contact-special-mark-table
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import SettingsDirectoriesContactsLayout from '@/pages/Settings/Directories/Contacts/Layout';
import ContactSpecialMarkTable from '@/components/Directories/Contacts/SpecialMarks/Table';
export default {
    name: 'ContactSpecialMarkPage',
    components: {ContactSpecialMarkTable, SettingsDirectoriesContactsLayout, AsyncPage}
};
</script>

<style scoped>

</style>