import {apiRequest} from '@/modules/error_handler';
import contactModule from '@/modules/contact';
import {ref} from 'vue';
import {defaultContactGroup} from '@/components/Directories/Contacts/Groups/constants';
const pagination = ref({
    limit: 100,
    offset: 0,
});
const contacts = ref({
    list: [],
    next: '',
    prevPage: '',
    total: '',
    totalPages: '',
});
const clients = ref({
    list: [],
    next: '',
    prevPage: '',
    total: '',
    totalPages: '',
});
const tabs = ref([]);
const contact = ref({});
const filter = ref({});
export function useContact(loading={value: false}, hideMembers=false, hideCompanies=false) {
    const createContact = async (workspaceId, contactData) => {
        return apiRequest(async() => {
            await contactModule.createContact(workspaceId, contactData);
        }, loading);
    };
    const getContactById = async(workspaceId, id) => {
        return apiRequest(async() => {
            contact.value = await contactModule.getContactById(workspaceId, id);
            return contact.value;
        }, loading);
    };

    const updateContact = async(workspaceId, id, updateData) => {
        return apiRequest(async() => {
            return contactModule.updateContact(workspaceId, id, updateData);
        });
    };
    const deleteContact = async(workspaceId, id) => {
        return apiRequest(async() => {
            return contactModule.deleteContact(workspaceId, id);
        });
    };
    const getContacts = async (workspaceId, meta={}, additionalFilter={}) => {
        return apiRequest(async() => {
            const result = await contactModule.getContacts(workspaceId, {
                limit: pagination.value.limit,
                offset: pagination.value.offset,
            }, {...filter.value, hideMembers, hideCompanies, ...additionalFilter});
            if (meta.fromPagination) {
                contacts.value.list = contacts.value.list.concat(result.list);
                contacts.value.next = result.next;
            } else {
                contacts.value = result;
            }
            return contacts.value;
        }, loading);
    };
    const getClients = async(workspaceId, search, withCompanies) => {
        return apiRequest(async() => {
            clients.value = await contactModule.getClients(workspaceId, search, withCompanies);
            return clients.value;
        });
    };
    const getTabs = async(workspaceId) => {
        return apiRequest(async() => {
            tabs.value = await contactModule.getTabs(workspaceId, {...filter.value, hideMembers, hideCompanies});
            return tabs.value;
        });
    };
    const setFilter = (filterData) => {
        filter.value = filterData;
    };
    const showMarketingFields = (contactGroupId, directories) => {
        const selectedContact = directories.value.contactGroups.list.find(x => x.id === contactGroupId);
        if (!selectedContact) return false;
        if (selectedContact.key === defaultContactGroup.client || selectedContact.key === defaultContactGroup.lead) {
            return true;
        }
        return false;
    };
    return {
        tabs,
        filter,
        clients,
        contact,
        contacts,
        pagination,
        getTabs,
        setFilter,
        getClients,
        getContacts,
        updateContact,
        deleteContact,
        createContact,
        getContactById,
        showMarketingFields,
    };
}