import {httpInstance} from '@/modules/http_factory';
import {CalendarSlotInterface} from '@/modules/calendar/entities/calendar-slot.interface';
import {OkResponse} from '@/core/common/interface/ok-response.interface';
import {ListOf} from '@/core/common/interface/list-of.interface';
import {CalendarSlotMapper} from '@/modules/calendar/mappers/calendar-slot.mapper';
import {CalendarFilterInterface} from '@/modules/calendar/filters/calendar-filter.interface';
import {CalendarSlotFormInterface} from '@/modules/calendar/form/calendar-slot-form.interface';

const getCalendarSlots = async(params: CalendarFilterInterface): Promise<ListOf<CalendarSlotInterface>> => {
    const response = await httpInstance().get('calendar-slots', {
        params: CalendarSlotMapper.formFilter(params)
    });
    return response.data;
};

const createCalendarSlot = async(params: CalendarSlotFormInterface): Promise<CalendarSlotInterface> => {
    const response = await httpInstance().post('calendar-slots', CalendarSlotMapper.toCreateDto(params));
    return response.data;
};

const updateCalendarSlot = async(id: number, params: CalendarSlotFormInterface): Promise<CalendarSlotInterface> => {
    const response = await httpInstance().patch(`calendar-slots/${id}`, CalendarSlotMapper.toUpdateDto(params));
    return response.data;
};

const deleteCalendarSlot = async(id: number): Promise<OkResponse> => {
    const response = await httpInstance().delete(`calendar-slots/${id}`);
    return response.data;
};

const getCalendarSlot = async(id: number): Promise<CalendarSlotInterface> => {
    const response = await httpInstance().get(`calendar-slots/${id}`);
    return response.data;
};

export default {
    getCalendarSlots,
    createCalendarSlot,
    updateCalendarSlot,
    deleteCalendarSlot,
    getCalendarSlot,
};