<template lang="pug">
a-avatar(:src="url") {{ str }}
</template>

<script>
import gravatar from 'gravatar';
export default {
    name: 'Gravatar',
    props: {
        str: String
    },
    setup(props) {
        const url = gravatar.url(props.str, {d: 'retro'});
        return {
            url
        };
    }
};
</script>

<style scoped>

</style>