<template lang="pug">
settings-directories-contacts-layout
    async-page
        lead-sources-table
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import LeadSourcesTable from '@/components/Directories/Contacts/LeadSources/Table';
import SettingsDirectoriesContactsLayout from '@/pages/Settings/Directories/Contacts/Layout';
export default {
    name: 'LeadSourcePage',
    components: {SettingsDirectoriesContactsLayout, LeadSourcesTable, AsyncPage}
};
</script>

<style scoped>

</style>