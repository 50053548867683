import {ref} from 'vue';
import {apiRequest} from '@/modules/error_handler';
import projectsModule from '@/modules/projects';
const pagination = ref({
    limit: 50,
    offset: 0,
});
const projects = ref({
    list: [],
    next: '',
    prevPage: '',
    total: '',
    totalPages: '',
});
const project = ref({
    title: undefined,
    clientId: undefined,
    contactModel: undefined,
});
export function useProject(loading={value: false}) {

    const getProjects = async (workspaceId, meta={}, search) => {
        return apiRequest(async() => {
            const result = await projectsModule.getProjects(workspaceId, {
                limit: pagination.value.limit,
                offset: pagination.value.offset,
            }, search);
            if (meta.fromPagination) {
                projects.value.list = projects.value.list.concat(result.list);
                projects.value.next = result.next;
            } else {
                projects.value = result;
            }
            return projects.value;
        }, loading);
    };
    const updateLocalProject = (project={}) => {
        projects.value.list = projects.value.list.map(x => {
            if (x.id === project.id) {
                return {...x, ...project};
            }
            return x;
        });
    };
    const createProject = async (project, workspaceId) => {
        return apiRequest(async() => {
            return projectsModule.createProject(project, workspaceId);
        }, loading);
    };
    const getProject = async (id, workspaceId) => {
        return apiRequest(async() => {
            project.value = await projectsModule.getProject(id, workspaceId);
            return project.value;
        }, loading);
    };
    const updateProject = async (id, updateData, workspaceId) => {
        return apiRequest(async() => {
            return projectsModule.updateProject(id, updateData, workspaceId);
        }, loading);
    };
    const deleteProject = async (id, workspaceId) => {
        return apiRequest(async() => {
            return projectsModule.deleteProject(id, workspaceId);
        }, loading);
    };
    const getClientText = (record) => {
        return record.client?.title;
    };
    const getClientsOptions = (clientsList) => {
        return clientsList.map(client => ({
            id: `${client.id}_${client.contactModel}`,
            title: client.title
        }));
    };
    return {
        project,
        projects,
        pagination,
        getProject,
        getProjects,
        createProject,
        updateProject,
        deleteProject,
        getClientText,
        getClientsOptions,
        updateLocalProject,
    };
}