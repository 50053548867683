<template lang="pug">
.hourly-reports-tables
    .hourly-reports-tables__groups
        excluding-select.mr-4(
            style="width: 50%"
            placeholder="Группировка"
            :model-value="currentGroup"
            :options="Object.values(groups)"
            @update:modelValue="changeCurrentGroup"
        )
        .hourly-reports-tables__groups-default
            .hourly-reports-tables__groups-default-element.mr-4(
                v-for="(value, key) in fastGroups"
                @click="changeCurrentGroup(value, key)"
                :class="{'hourly-reports-tables__groups-default--active': activeFastGroup === key}"
            ) {{ groups[key].title }}
    node-tree(
        :node="node"
        v-for="node in tables"
        :nodes="node.children"
        :depth="0"
        suffix="ч."
    )
        template(#title="{record}")
            .flex.items-center
                div {{ record.title || 'Не указано'}}
                a.ml-3(v-if="record.type === 'taskId' && !uuid" :href="getTaskCardLink(record)" target="_blank")
                    icon(type="ExternalLink")
                span.ml-3.cursor-pointer(v-if="!record.type && !uuid" @click="editTimeLog(record)")
                    icon(type="Edit")
    a-modal(
        v-model:visible="showTimeLogModal"
        title="Добавить лог времени"
        :footer="null"
        @cancel="setTimeLogModalVisibility(false)"
        :destroyOnClose="true"
    )
        create-time-log-form(
            :workspaceId="currentWorkspaceId"
            @update="onTimeLogUpdate"
            mode="edit"
            :default-value="editingTimeLog"
        )
</template>

<script>
import Icon from '@/components/Generic/Typography/Icon/index';
export default {
    name: 'HourlyReportsTables',
    components: {Icon},
};
</script>
<script setup>
/*======================IMPORT AREA=====================*/
import {ref} from 'vue';
import CardTitle from '@/components/Generic/Typography/CardTitle';
import CustomTable from '@/components/Generic/CustomTable';
import {columns} from '@/components/Analytics/HourlyReports/constants';
import {useDate} from '@/composition/date';
import {useReport} from '@/composition/reports';
import {getTitle} from '@/modules/utils';
import ExcludingSelect from '@/components/Generic/ExcludingSelect';
import NodeTree from '@/components/Generic/NodeTree';
import {useWorkspace} from '@/composition/workspace';
import CreateTimeLogForm from '@/components/TimeLogs/CreateForm';
import {useTimelog} from '@/composition/timelog';
import {useCreateOrUpdateModal} from '@/composition/create-update-modal';
import moment from 'moment-timezone';
import constants from '@/constants';

/*======================INITIALIZATION AREA=====================*/
const props = defineProps({
    currentGroup: {
        type: Array,
    },
    groups: {
        type: Object,
    },
    uuid: {
        type: String
    }
});
const emit = defineEmits(['update:currentGroup']);

const {formatDate, getHoursAndMinutesFromMs} = useDate();

const {currentWorkspaceId, currentWorkspaceTz} = useWorkspace();
const defaultTz = constants.tz.default;
const {tables, fastGroups, activeFastGroup} = useReport();

/*======================GROUPS METHODS AREA=====================*/

const changeCurrentGroup = (newArr, key) => {
    emit('update:currentGroup', newArr);
    activeFastGroup.value = key;
};

/*======================TABLES AREA=====================*/

const getColumns = () => {
    return columns.filter(x => {
        const showInAllCases = x.showIn.findIndex(el => el === '*') !== -1;
        if (showInAllCases) return true;
        const showInCurrentGroup = x.showIn.findIndex(el => el === props.currentGroup.key) !== -1;
        if (showInCurrentGroup) return true;
        return false;
    });
};

const getTaskCardLink = (record) => {
    return `/${currentWorkspaceId.value}/tasks/${record.id}`;
};
/*======================TIME LOGS AREA=====================*/
const {
    showModal: showTimeLogModal,
    setModalVisibility: setTimeLogModalVisibility,
    editingValue: editingTimeLog,
} = useCreateOrUpdateModal();

const {updateTimeLog, getTimeLogById} = useTimelog();
const onTimeLogUpdate = async (updateData) => {
    const {id, ...payload} = updateData;
    await updateTimeLog(currentWorkspaceId.value, id, payload);
    emit('update:currentGroup', props.currentGroup);
    setTimeLogModalVisibility(false);
};
const editTimeLog = async (timeLog) => {
    const {ok, result} = await getTimeLogById(currentWorkspaceId.value, timeLog.id);
    if (ok && result) {
        editingTimeLog.value = {
            ...result,
            startDate: moment.utc(result.startDate).tz(currentWorkspaceTz.value || defaultTz),
            startTime: {
                hours: moment.utc(result.startDate).tz(currentWorkspaceTz.value || defaultTz).get('hours'),
                minutes: moment.utc(result.startDate).tz(currentWorkspaceTz.value || defaultTz).get('minutes'),
                seconds: moment.utc(result.startDate).tz(currentWorkspaceTz.value || defaultTz).get('seconds'),
            },
            duration: getHoursAndMinutesFromMs(result.durationInMs)
        };
        setTimeLogModalVisibility(true);
    }
};
</script>
<style lang="scss" scoped>
.hourly-reports-tables {
    &__groups-default {
        width: 50%;
        display: flex;
        align-items: center;
        background-color: #ffffff;
        padding: 10px 12px;
        &--active {
            font-weight: bold;
        }
        &-element {
            cursor: pointer;
            &:hover {
                opacity: 0.8;
            }
            &:focus {
                font-weight: bold;
            }
        }
    }
    &__groups {
        display: flex;
        align-items: center;
    }
}
</style>
