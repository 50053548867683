<template lang="pug">
.common-title
    slot
</template>

<script>
export default {
    name: 'TypographyTitle'
};
</script>

<style lang="scss" scoped>
.common-title {
    color: #3c495a;
    font-size: 32px;
    line-height: 1.3;
}
</style>