<template lang="pug">
.finance-balance-company-chart
    chart-wrapper(:loading="loading")
        line-chart(:data="chartData" series-name="Баланс")
</template>

<script>
export default {
    name: 'FinanceBalanceCompanyChart'
};
</script>

<script setup>
/*===============IMPORT AREA===================*/
/*===============INIT AREA===================*/
import ChartWrapper from '@/components/Generic/Charts/ChartWrapper';
import LineChart from '@/components/Generic/Charts/LineChart';
import {computed} from 'vue';

const props = defineProps({
    loading: {
        type: Boolean,
        required: true,
    },
    balanceHistory: {
        type: Array,
        required: true
    }
});
const chartData = computed(() => {
    return props.balanceHistory.map(x => ({
        id: x.id,
        date: x.date,
        value: x.balance
    }));
});
</script>

<style scoped lang="scss">
.finance-balance-company-chart {
    width: 98%;
}
</style>