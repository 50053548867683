<template lang="pug">
.contact-finance
    card-title Финансы
    .editable-card__column.mt-2
        .flex.items-center Часовая ставка
        editable-cell(
            :initialValue="contact.hourlyRate"
            @update="(e) => onUpdate('hourlyRate', e)"
            :title="getHourlyRateText()"
            type="number"
        )
    .editable-card__column.mt-2
        .flex.items-center Валюта
        editable-cell(
            :initialValue="contact.currency"
            @update="(e) => onUpdate('currency', e)"
            :title="getCurrencyText()"
            :options="getCurrencyOptions()"
            type="select"
        )
</template>

<script>
import constants from '@/constants';
import EditableCell from '@/components/Generic/EditableCell';

export default {
    name: 'ContactFinance',
    components: {EditableCell},
    props: {
        contact: Object,
    },
    emits: ['update'],
    setup(props, {emit}) {
        const getCurrencyText = () => {
            return constants.currencyMap[props.contact?.currency] || 'Не указано';
        };
        const getHourlyRateText = () => {
            return props.contact?.hourlyRate || 'Не указано';
        };
        const getCurrencyOptions = () => {
            return Object.keys(constants.currency).map(x => ({
                id: x,
                title: constants.currencyMap[x]
            }));
        };
        const onUpdate = (key, value) => {
            emit('update', {[key]: value});
        };
        return {
            getCurrencyText,
            getHourlyRateText,
            getCurrencyOptions,
            onUpdate,
        };
    }
};
</script>

<style scoped>

</style>