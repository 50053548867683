<template lang="pug">
.page.workspace-page
</template>

<script>

import PageTitle from '@/components/Generic/PageTitle';

export default {
    name: 'Workspace',
    components: {PageTitle},
};
</script>

<style scoped>

</style>