import {httpInstance} from '@/modules/http_factory';
import {OkResponse} from '@/core/common/interface/ok-response.interface';
import {ListOf} from '@/core/common/interface/list-of.interface';
import {ServicePaymentPeriodInterface} from '@/modules/services/entities/service-payment-period.interface';
import {ServicePaymentPeriodForm} from '@/modules/services/form/service-payment-period.form';
import {ServicePaymentPeriodMapper} from '@/modules/services/mappers/service-payment-period.mapper';
import {ServicePaymentPeriodFilter} from '@/modules/services/filters/service-payment-period.filter';
import {PaginationInterface} from '@/core/common/interface/pagination.interface';
import {ServicePaymentPeriodsCountInterface} from '@/modules/services/interface/service-payment-periods-count.interface';
import {ServicePaymentPeriodFinanceStatsInterface} from '@/modules/services/interface/service-payment-period-finance-stat-item.interface';

const getServicePaymentPeriods = async (params: ServicePaymentPeriodFilter, pagination: PaginationInterface): Promise<ListOf<ServicePaymentPeriodInterface>> => {
    const response = await httpInstance().get('service-payment-periods', {
        params: {
            ...ServicePaymentPeriodMapper.formFilter(params),
            ...pagination
        }
    });
    return response.data;
};

const getServicePaymentPeriodsCount = async (params: ServicePaymentPeriodFilter): Promise<ServicePaymentPeriodsCountInterface> => {
    const response = await httpInstance().get('service-payment-periods/count', {
        params: {
            ...ServicePaymentPeriodMapper.formFilter(params),
        }
    });
    return response.data;
};
const getStats = async (params: ServicePaymentPeriodFilter): Promise<ServicePaymentPeriodFinanceStatsInterface> => {
    const response = await httpInstance().get('service-payment-periods/stats', {
        params: {
            ...ServicePaymentPeriodMapper.formFilter(params),
        }
    });
    return response.data;
};

const createServicePaymentPeriod = async (params: ServicePaymentPeriodForm): Promise<ServicePaymentPeriodInterface> => {
    const response = await httpInstance().post('service-payment-periods', ServicePaymentPeriodMapper.toCreateDto(params));
    return response.data;
};

const updateServicePaymentPeriod = async (id: number, params: ServicePaymentPeriodForm): Promise<ServicePaymentPeriodInterface> => {
    const response = await httpInstance().patch(`service-payment-periods/${id}`, ServicePaymentPeriodMapper.toUpdateDto(params));
    return response.data;
};

const deleteServicePaymentPeriod = async (id: number): Promise<OkResponse> => {
    const response = await httpInstance().delete(`service-payment-periods/${id}`);
    return response.data;
};

const getServicePaymentPeriod = async (id: number): Promise<ServicePaymentPeriodInterface> => {
    const response = await httpInstance().get(`service-payment-periods/${id}`);
    return response.data;
};

export default {
    getStats,
    getServicePaymentPeriodsCount,
    getServicePaymentPeriods,
    createServicePaymentPeriod,
    updateServicePaymentPeriod,
    deleteServicePaymentPeriod,
    getServicePaymentPeriod,
};
