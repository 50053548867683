<template lang="pug">
integration-layout
    async-page
        integration-projects
</template>

<script>
import IntegrationLayout from '@/pages/Settings/Integrations/Layout';
import AsyncPage from '@/layouts/async-page';
import IntegrationProjects from '@/components/Integrations/ProjectsList';
export default {
    name: 'IntegrationProjectsPage',
    components: {IntegrationProjects, AsyncPage, IntegrationLayout}
};
</script>

<style scoped>

</style>