<template lang="pug">
async-page
    document-card
</template>

<script>
import AsyncPage from '@/layouts/async-page';
import DocumentCard from '@/components/Documents/Card';
export default {
    name: 'DocumentPage',
    components: {DocumentCard, AsyncPage}
};
</script>

<style scoped>

</style>